import React, { useState } from 'react';
import OrderReturnPage from './components/OrderReturnPage';
import { returnOrderStatus } from '../../../constants/orderStatus';
import { useSelector } from 'react-redux';

const Requested = () => {
  const isAdmin = useSelector(
    (state) => state?.user?.preferences?.isAdmin || false
  );
  const bulkActionOptions = [
    {
      label: 'Accept',
      value: 'accept',
    },
  ];
  const defaultConfirmationProps = {
    qcCheck: [
      {
        type: 'dropdown',
        title: '3PL QC Check',
        options: [
          { label: 'Yes', value: 'yes' },
          { label: 'No', value: 'no' },
        ],
        value: 'no',
      },
    ],
  };

  const [confirmationModalProps, setConfirmationModalProps] = useState([]);
  return (
    <>
      <OrderReturnPage
        type={returnOrderStatus.REQUESETED}
        hideBulkActions={!isAdmin}
        bulkActionOptions={isAdmin && bulkActionOptions}
        defaultConfirmationProps={defaultConfirmationProps}
        confirmationModalProps={confirmationModalProps}
        setConfirmationModalProps={setConfirmationModalProps}
      />
    </>
  );
};

export default Requested;
