import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { productListColumns, productsOptions } from './utils';
import { useDispatch, useSelector } from 'react-redux';
import { updateSpinnerState } from '../../../actions/spinner';
import { updateToastInfo } from '../../../actions/settings';
import { apiCall } from '../../../middlewares/api';
import { apiCallConsts } from '../../../constants/apiCallConsts';
import NewTable from '../../../components/common/NewTable';
import {
  faArrowRotateLeft,
  faBroom,
  faCheck,
  faExclamationCircle,
  faRotateRight,
  faSliders,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Button, Form } from '@themesberg/react-bootstrap';
import ExcelDownloadReport from '../../../components/common/excel/download';
import { debounce } from '../../../utils/debounce';
import AsyncDropdown from '../../../components/AsyncDropdown';
import EditableDropDown from '../../../components/common/EditableDropdown';
import { arrayToDropdownOptions } from '../../../utils/sms/arrayToDropdownOptions';
import RejectModal from '../interBranchTransfers/components/RejectModal';
import InventoryModal from '../inventory/components/InvenoryModal';
import {
  setProductByVanNoInStore,
  setProductsMapInStore,
} from '../../../actions/oms/allProducts';
import { setPageNumberInStore } from '../../../actions/tablePage';
import CardDropdown from '../inventory/components/CardDropDown';
import SummaryModal from './components/SummaryModal';

const ProductsList = () => {
  const { t } = useTranslation();
  const productsMap = useSelector(
    (state) => state?.allProducts?.productsMap || {}
  );
  const companies = useSelector((state) => state?.allProducts?.companies || []);
  const categories = useSelector(
    (state) => state?.allProducts?.categories || []
  );
  const [products, setProducts] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);
  const [selectedVanNo, setSelectedVanNo] = useState(null);
  const [selectedVanNos, setSelectedVanNos] = useState([]);
  const [vanNo, setVanNo] = useState([]);
  const [name, setName] = useState([]);
  const [category, setCategory] = useState([]);
  const [brand, setBrand] = useState([]);
  const [vanNoOptions, setVanNoOptions] = useState([]);
  const [nameOptions, setNameOptions] = useState([]);
  const [updateModal, setUpdateModal] = useState(false);
  const [summaryModalData, setSummaryModalData] = useState([]);
  const [summaryModal, setSummaryModal] = useState(false);
  const [modalType, setModalType] = useState('');
  const [showUpdateInventoryModal, setShowUpdateInventoryModal] =
    useState(false);
  const [confirmationModalProps, setConfirmationModalProps] = useState([]);
  const [flag, setFlag] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const filterObj = [
    { label: 'VAN NO', value: vanNo, func: setVanNo },
    {
      label: 'PRODUCT NAME',
      value: name,
      func: setName,
    },
    { label: 'CATEGORY', value: category, func: setCategory },
    { label: 'BRAND', value: brand, func: setBrand },
  ];

  const handleReset = () => {
    setVanNo([]);
    setName([]);
    setCategory([]);
    setBrand([]);
    setFilteredData(products);
    setFlag((flag) => !flag);
  };

  const fetchOptions = debounce(async (inputValue, options) => {
    if (inputValue.length > 2) {
      try {
        const filteredOptions = options?.filter((product) =>
          product.value.toLowerCase().includes(inputValue.toLowerCase())
        );
        return filteredOptions;
      } catch (e) {
        console.log(e);
      }
    }
  });

  const onRowSelect = (selected, objectId) => {
    if (selected) {
      setSelectedVanNos((prev) => {
        const selectedItem = products?.find(
          (item) => item?.objectId === objectId
        );
        return [...prev, selectedItem];
      });
    } else {
      setSelectedVanNos((prev) =>
        prev.filter((item) => item?.objectId !== objectId)
      );
    }
    setProducts((prev) =>
      prev?.map((item) =>
        item?.objectId === objectId ? { ...item, isSelected: selected } : item
      )
    );
  };

  const fetchProducts = async () => {
    try {
      dispatch(updateSpinnerState(true));
      const productsMap = await apiCall(
        apiCallConsts.GET_METHOD,
        'internal/feeds/products?source=tekno'
      );
      // console.log(productsMap);
      dispatch(setProductsMapInStore(productsMap));
      setProducts(Object.values(productsMap));
      setFilteredData(Object.values(productsMap));
      setVanNoOptions(arrayToDropdownOptions(Object.keys(productsMap)));
      setNameOptions(
        arrayToDropdownOptions(
          Object.values(productsMap).map(
            (product) => product?.productTitle || ''
          )
        )
      );
      dispatch(updateSpinnerState(false));
    } catch (e) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: 'Failed!',
          message: t(e.message),
        })
      );
    }
  };

  const onActionClick = (option, vanNo) => {
    if (option === productsOptions.markEndlessAisle) {
      // handle endless aisle
      setModalType(option);
      setConfirmationModalProps([
        {
          title: 'Delivery Days',
          type: 'number',
          value: '',
        },
      ]);
      setSelectedVanNo(vanNo);
      setShowUpdateInventoryModal(true);
    } else if (option === productsOptions.updateSafetyStock) {
      // handle update
      setSelectedVanNo(vanNo);
      setUpdateModal(true);
    } else if (option === productsOptions.updateInventory) {
      setSelectedVanNo(vanNo);
      dispatch(setProductByVanNoInStore(vanNo));
      navigate(`/oms/products/updateInventory`);
    } else {
      dispatch(setProductByVanNoInStore(vanNo));
      navigate(`/oms/products/${vanNo}`);
    }
  };

  const filterSubmit = () => {
    dispatch(setPageNumberInStore({ id: 'productsList', page: 1 }));
    const filteredData = products
      .filter((product) => {
        if (!vanNo[0]) return true;
        return product.vanNo === vanNo[0];
      })
      .filter((product) => {
        if (!brand[0]) return true;
        return product.brand === brand[0];
      })
      .filter((product) => {
        if (!category[0]) return true;
        return product.l3 === category[0];
      })
      .filter((product) => {
        if (!name[0]) return true;
        return product.productTitle === name[0];
      });
    setFilteredData(filteredData);
  };

  const handleEmpty = async (fun) => {
    console.log(fun);
    fun([]);
    filterSubmit();
    setFlag((flag) => !flag);
  };

  const handleSubmit = () => {
    if (
      confirmationModalProps[0].length === 0 ||
      !confirmationModalProps[0].value
    ) {
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: 'failed!',
          message: 'Please enter value before submitting',
        })
      );
      return;
    }
    if (modalType === productsOptions.markEndlessAisle) {
      handleModalSubmit();
    } else {
      handleUpdateInventorySubmit();
    }
    setModalType('');
  };

  const handleModalSubmit = async () => {
    let data;
    if (selectedVanNos.length > 0) {
      data = selectedVanNos.map((van) => ({
        sku: van.vanNo,
        isEndlessAisle: true,
        edd: parseInt(confirmationModalProps[0].value),
      }));
    } else {
      data = [
        {
          sku: selectedVanNo,
          isEndlessAisle: true,
          edd: parseInt(confirmationModalProps[0].value),
        },
      ];
    }

    try {
      dispatch(updateSpinnerState(true));
      const response = await apiCall(
        apiCallConsts.POST_METHOD,
        'products/update',
        { data }
      );
      setShowUpdateInventoryModal(false);
      setConfirmationModalProps([]);
      if (selectedVanNos.length > 0) {
        setSelectedVanNos([]);
      } else {
        setSelectedVanNo(null);
      }
      dispatch(
        updateToastInfo({
          show: true,
          type: 'success',
          title: '',
          message: 'Product Updated Successfully!',
        })
      );
      dispatch(updateSpinnerState(false));
      await fetchProducts();
    } catch (e) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: 'Failed!',
          message: t(e.message),
        })
      );
    }
  };

  const handleSafetySubmit = async (data) => {
    try {
      dispatch(updateSpinnerState(true));
      const response = await apiCall(
        apiCallConsts.GET_METHOD,
        apiCallConsts.INVENTORY_URL,
        { vanNo: selectedVanNo, isAdmin: true }
      );
      console.log(productsMap[selectedVanNo]);

      let modalData = [
        {
          available: response?.country || 0,
          safetyStockLimit: productsMap[selectedVanNo]?.safetyStockLimit || 0,
          change:
            data?.safetyStockLimit -
            productsMap[selectedVanNo]?.safetyStockLimit,
          newSafetyStockLimit: data?.safetyStockLimit || 0,
        },
      ];
      // console.log(modalData);

      setSummaryModalData(modalData);
      setUpdateModal(false);
      setSummaryModal(true);
      // console.log(response);
    } catch (e) {
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: 'Failed!',
          message: t(e.message),
        })
      );
    } finally {
      dispatch(updateSpinnerState(false));
    }
  };

  const handleUpdateSubmit = async () => {
    try {
      const apiData = {
        data: [
          {
            sku: selectedVanNo,
            safetyStockLimit: summaryModalData[0]?.newSafetyStockLimit,
          },
        ],
      };
      // console.log(apiData);

      dispatch(updateSpinnerState(true));
      await apiCall(apiCallConsts.POST_METHOD, 'products/update', apiData);
      const updatedMap = Object.keys(productsMap).reduce((acc, key) => {
        acc[key] =
          key === selectedVanNo
            ? {
                ...productsMap[key],
                safetyStockLimit: summaryModalData[0]?.newSafetyStockLimit || 0,
              }
            : { ...productsMap[key] };
        return acc;
      }, {});
      setProducts(Object.values(updatedMap));
      setFilteredData(Object.values(updatedMap));
      setSummaryModal(false);
      setSummaryModalData([]);
      setSelectedVanNo(null);
      dispatch(setProductsMapInStore(updatedMap));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'success',
          title: '',
          message: 'SafteyStock Updated Successfully!',
        })
      );
      dispatch(updateSpinnerState(false));
      // await fetchProducts();
    } catch (e) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: 'Failed!',
          message: t(e.message),
        })
      );
    }
  };

  const handleUpdateInventorySubmit = async () => {
    try {
      const data = {
        inventories: [
          {
            ean: selectedVanNo,
            fcId: 'VSO' || '',
            quantity: Number(confirmationModalProps[0]?.value || ''),
            movementType: 'RTW',
            transactionType: 'Overwrite',
          },
        ],
      };
      dispatch(updateSpinnerState(true));
      const response = await apiCall(
        apiCallConsts.POST_METHOD,
        'inventory/update',
        data
      );
      console.log(response);

      setShowUpdateInventoryModal(false);
      setSelectedVanNo(null);
      setConfirmationModalProps([]);
      dispatch(
        updateToastInfo({
          show: true,
          type: 'success',
          title: '',
          message: 'Product Updated Successfully!',
        })
      );
      dispatch(updateSpinnerState(false));
      // await fetchProducts();
    } catch (e) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: 'Failed!',
          message: t(e.message),
        })
      );
    }
  };

  const handleCardChange = (e) => {
    setModalType(productsOptions.markEndlessAisle);
    setConfirmationModalProps([
      {
        title: 'Delivery Days',
        type: 'number',
        value: '',
      },
    ]);
    setShowUpdateInventoryModal(true);
  };

  // console.log(selectedVanNos);
  useEffect(() => {
    if (Object.keys(productsMap).length === 0) {
      fetchProducts();
    } else {
      setProducts(Object.values(productsMap));
      setFilteredData(Object.values(productsMap));
      setVanNoOptions(arrayToDropdownOptions(Object.keys(productsMap)));
      setNameOptions(
        arrayToDropdownOptions(
          Object.values(productsMap).map(
            (product) => product?.productTitle || ''
          )
        )
      );
    }
  }, []);

  return (
    <>
      {/* <fieldset disabled={true}> */}
      <div className="fw-medium fs-4 my-2">{t('Products List')}</div>
      <div className="mt-2 d-flex justify-content-between">
        <div>
          {selectedVanNos.length > 0 && (
            <CardDropdown
              placeholder={t('select Action')}
              options={[
                {
                  label: 'Mark Selected as Endless Aisle',
                  value: 'markEndlessAisle',
                },
              ]}
              width={'20rem'}
              // value={cardFilters.store}
              onChange={handleCardChange}
            />
          )}
        </div>
        <div className="d-flex gap-3">
          <Button
            variant="white"
            size="md"
            className="btn-export"
            onClick={() => setOpenFilter(!openFilter)}
          >
            <FontAwesomeIcon icon={faSliders} />
            &nbsp;&nbsp;{t('Filter')}
          </Button>
          <Button
            variant="white"
            size="md"
            className="btn-export"
            onClick={fetchProducts}
          >
            <FontAwesomeIcon icon={faRotateRight} />
            &nbsp;&nbsp; {t('Refresh')}
          </Button>
          <ExcelDownloadReport data={products || []} fileName="products" />
        </div>
      </div>
      <>
        <div
          className="table-settings mt-2 mb-4"
          style={{
            background: '#EAEDF2',
            maxHeight: openFilter ? '700px' : '0px',
            overflow: openFilter ? '' : 'hidden', // Ensure content is hidden when collapsed
            transition: 'max-height 300ms ease-in-out', // Smooth transition when toggling
          }}
        >
          <div style={{ gap: 8, display: 'flex' }}>
            <Form.Group
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(3, 1fr)', // Three columns
                gap: '20px', // Gap between elements
                width: '100%',
                backgroundColor: 'white',
                padding: 20,
              }}
            >
              <div>
                <Form.Label>VAN NO.</Form.Label>
                <AsyncDropdown
                  onChange={(option) => {
                    dispatch(
                      setPageNumberInStore({ id: 'productsList', page: 1 })
                    );
                    setVanNo([option.value]);
                  }}
                  style={{
                    height: 38,
                    width: '100%',
                  }}
                  value={vanNo[0]}
                  disabled={false}
                  fetchOptions={(e) => fetchOptions(e, vanNoOptions)}
                  placeholder={t('Select vanNo')}
                />
              </div>

              <div>
                <Form.Label>PRODUCT NAME</Form.Label>
                <AsyncDropdown
                  onChange={(option) => {
                    dispatch(
                      setPageNumberInStore({ id: 'productsList', page: 1 })
                    );
                    setName([option.value]);
                  }}
                  style={{
                    height: 38,
                    width: '100%',
                  }}
                  value={name}
                  disabled={false}
                  fetchOptions={(e) => fetchOptions(e, nameOptions)}
                  placeholder={t('Select field to Search by')}
                />
              </div>

              <div>
                <Form.Label>CATEGORY</Form.Label>
                <div>
                  <EditableDropDown
                    id={'category'}
                    onChange={(e) => {
                      dispatch(
                        setPageNumberInStore({ id: 'productsList', page: 1 })
                      );
                      setCategory([e.value]);
                    }}
                    style={{
                      height: 38,
                      width: '100%',
                      // backgroundColor: 'light-gray',
                    }}
                    value={category}
                    dropdownBackgroundColor={'light'}
                    options={arrayToDropdownOptions(categories)}
                    placeholder={'Select Category'}
                  />
                </div>
              </div>

              <div>
                <Form.Label>BRAND</Form.Label>
                <EditableDropDown
                  id={'brand'}
                  onChange={(e) => {
                    dispatch(
                      setPageNumberInStore({ id: 'productsList', page: 1 })
                    );
                    setBrand([e.value]);
                  }}
                  style={{
                    height: 38,
                    width: '100%',
                  }}
                  dropdownBackgroundColor={'light'}
                  value={brand}
                  options={arrayToDropdownOptions(companies)}
                  placeholder={'Select Brand'}
                />
              </div>

              <div
                style={{
                  gridColumn: 'span 3', // Span across all columns
                  display: 'flex',
                  justifyContent: 'flex-end',
                  gap: '10px',
                }}
              >
                <Button
                  variant="white"
                  className="fa-export"
                  onClick={handleReset}
                  style={{
                    width: '150px',
                    height: '38px',
                    borderRadius: 0,
                    background: '#EAEDF2',
                  }}
                >
                  <FontAwesomeIcon icon={faArrowRotateLeft} />
                  &nbsp;&nbsp;{t('Reset')}
                </Button>

                <Button
                  variant="white"
                  className="fa-export"
                  onClick={filterSubmit}
                  style={{
                    width: '150px',
                    height: '38px',
                    borderRadius: 0,
                    border: '1px solid #262B40',
                  }}
                >
                  <FontAwesomeIcon icon={faSliders} />
                  &nbsp;&nbsp;{t('Apply Filter')}
                </Button>
              </div>
            </Form.Group>
          </div>
        </div>
        {!openFilter &&
          (vanNo != '' || name != '' || category != '' || brand != '') && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div
                className="gap-2"
                style={{
                  display: 'flex',
                  width: '100%',
                  background: '#F5F8FB',
                  padding: 20,
                }}
              >
                {filterObj.map((each) => {
                  if (each.value != '') {
                    return (
                      <div>
                        <Form.Label style={{ color: '#262B4099' }}>
                          {each.label}
                        </Form.Label>
                        <Badge
                          variant="light"
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: '188px',
                            height: '41px',
                            background: 'white',
                            borderRadius: '2px',
                            cursor: 'pointer',
                            color: '#262B40',
                            fontSize: '14px',
                            fontWeight: '400',
                            lineHeight: '16.41px',
                          }}
                        >
                          {each.value}
                          <span
                            onClick={() => handleEmpty(each.func)}
                            style={{
                              color: '#262B40',
                              fontSize: '14px',
                              fontWeight: '400',
                              lineHeight: '16.41px',
                            }}
                          >
                            &times;
                          </span>
                        </Badge>
                      </div>
                    );
                  }
                })}
              </div>
              <Button
                variant="primary"
                className="fa-export"
                onClick={handleReset}
                style={{
                  width: '150px',
                  height: '38px',
                  borderRadius: 0,
                }}
              >
                <FontAwesomeIcon icon={faBroom} />
                &nbsp;&nbsp;{t('Clear')}
              </Button>
            </div>
          )}
      </>
      <NewTable
        id="productsList"
        data={filteredData || []}
        columns={productListColumns(onActionClick, onRowSelect)}
      />
      {updateModal && (
        <InventoryModal
          showModal={updateModal}
          closeModal={() => setUpdateModal(false)}
          modalTitile={'Updating Safety Stock Limit'}
          handleSubmit={handleSafetySubmit}
          type={'safetyStockLimit'}
        />
      )}

      {showUpdateInventoryModal && (
        <RejectModal
          icon={
            modalType === productsOptions.updateInventory
              ? faExclamationCircle
              : faCheck
          }
          showModal={showUpdateInventoryModal}
          closeModal={() => setShowUpdateInventoryModal(false)}
          modalTitle={'Mark EndlessAisle'}
          modalSubTitle={
            'Please enter no of days it will take to delivery the product?'
          }
          confirmActionName={'Confirm'}
          cancelActionName={'Cancel'}
          confirmationModalProps={confirmationModalProps}
          setConfirmationModalProps={setConfirmationModalProps}
          handleSubmit={handleSubmit}
        />
      )}

      {summaryModal && (
        <SummaryModal
          showModal={summaryModal}
          closeModal={() => setSummaryModal(false)}
          modalTitile={'Safety Stock Limit Update Summary'}
          data={summaryModalData || []}
          columns={[
            {
              dataField: 'available',
              text: 'Current Stock',
            },
            {
              dataField: 'safetyStockLimit',
              text: 'Current Safety Stock',
            },
            {
              dataField: 'newSafetyStockLimit',
              text: 'New Safety Stock',
            },
            {
              dataField: 'change',
              text: 'Change in Safety Stock',
            },
          ]}
          handleSubmit={handleUpdateSubmit}
          isFooterRequired={false}
          summaryTitle="Impact"
          summaryMessage="Updating the safety stock at the product level will override the previous individual store-level safety stock values, ensuring a uniform safety stock across all locations. An additional 20 units are needed to maintain product serviceability."
        />
      )}

      {/* </fieldset> */}
    </>
  );
};

export default ProductsList;
