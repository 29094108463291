import React, { useEffect, useState } from 'react';
import Parse from 'parse';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCaretDown,
  faDownload,
  faLanguage,
  faMobileAlt,
  faSearch,
  faServer,
  faSignOutAlt,
} from '@fortawesome/free-solid-svg-icons';
import {
  Row,
  Col,
  Nav,
  Form,
  Image,
  Navbar,
  Dropdown,
  Container,
  ListGroup,
  InputGroup,
  Button,
} from '@themesberg/react-bootstrap';

import NOTIFICATIONS_DATA from '../data/notifications';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../router/routes';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomerAvatarName } from '../utils/string';
import { useTranslation } from 'react-i18next';
import { applicationList, applicationHelp } from '../constants/applications';
import { resetStore } from '../actions/user';
import { returnCustomerAddressString } from '../utils/table';
import CustomNotification from '../pages/oms/orders/components/NotificationBell';
import { updateSpinnerState } from '../actions/spinner';
import DownloadModal from './common/DownloadModal';

const languagesSupported = [
  {
    label: 'English',
    code: 'en',
  },
  {
    label: 'हिंदी',
    code: 'hi',
  },
  {
    label: 'मराठी',
    code: 'mr',
  },
  {
    label: 'ગુજરાતી',
    code: 'gu',
  },
  {
    label: 'తెలుగు',
    code: 'te',
  },
];

export default (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const mobileAppLoginCode = useSelector(
    (state) => state.user.config.mobileAppLoginCode || ''
  );
  const [currentLanguage, setCurrentLanguage] = useState({});

  const { i18n, t } = useTranslation();
  const [notifications, setNotifications] = useState(NOTIFICATIONS_DATA);
  const areNotificationsRead = notifications.reduce(
    (acc, notif) => acc && notif.read,
    true
  );
  const userName = useSelector((state) => state?.user?.user?.username || '');
  const warehouseName = useSelector(
    (state) => state?.user?.warehouse?.warehouseName || ''
  );
  const applicationArray = useSelector(
    (state) => state?.user?.preferences?.applications || ['dms']
  );
  const [currentApplication, setCurrentApplication] = useState({
    value: applicationArray[0],
  });
  const [showLoader, setShowLoader] = useState(false);
  const [showDownload, setShowDownload] = useState(false);
  const isSuperAdmin = useSelector(
    (state) => state?.user?.preferences?.isSuperAdmin || false
  );
  const isAdmin = useSelector(
    (state) => state?.user?.preferences?.isAdmin || false
  );
  const roles = useSelector((state) => state?.user?.preferences?.roles || []);
  const downloadLink = useSelector(
    (state) => state.user.config?.mobileApkUrl || ''
  );

  useEffect(() => {
    getLanguageFromLocalStorage();
    getApplicationFromLocalStorage();
  }, []);

  const getApplicationFromLocalStorage = async () => {
    const application = localStorage.getItem('vsApplication');
    if (application) {
      setCurrentApplication(JSON.parse(application));
    }
  };

  const getLanguageFromLocalStorage = async () => {
    const language = localStorage.getItem('vsLanguage');
    if (language?.length > 0) {
      setCurrentLanguage(JSON.parse(language));
    }
  };
  const markNotificationsAsRead = () => {
    setTimeout(() => {
      setNotifications(notifications.map((n) => ({ ...n, read: true })));
    }, 300);
  };

  const handleDownloadClick = () => {
    setShowDownload((showDownload) => !showDownload);
  };
  const handleLogoutClick = async () => {
    dispatch(updateSpinnerState(true));
    await Parse.User.logOut();
    dispatch(resetStore());
    localStorage.removeItem('vsApplication');
    navigate(Routes.Login.path);
    dispatch(updateSpinnerState(false));
  };

  const Notification = (props) => {
    const { link, sender, image, time, message, read = false } = props;
    const readClassName = read ? '' : 'text-danger';

    return (
      <ListGroup.Item action href={link} className="border-bottom border-light">
        <Row className="align-items-center">
          <Col className="col-auto">
            <Image
              src={image}
              className="user-avatar lg-avatar rounded-circle"
            />
          </Col>
          <Col className="ps-0 ms--2">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h4 className="h6 mb-0 text-small">{sender}</h4>
              </div>
              <div className="text-end">
                <small className={readClassName}>{time}</small>
              </div>
            </div>
            <p className="font-small mt-1 mb-0">{message}</p>
          </Col>
        </Row>
      </ListGroup.Item>
    );
  };

  return (
    // <StyledLoader
    // active={showLoader}
    // spinner>
    <Navbar variant="dark" expanded className="ps-0 pe-2 pb-0">
      <Container fluid className="px-0">
        <div className="d-flex justify-content-between w-100">
          <div className="d-flex align-items-center">
            <div className="logincode">
              {t('Login Code')}: {mobileAppLoginCode}
            </div>
          </div>
          <Nav className="align-items-center">
            {applicationArray.length < 2 && !isAdmin ? (
              <h6 style={{ marginRight: '20px' }}>
                {applicationHelp[applicationArray[0]]}
              </h6>
            ) : (
              <Dropdown as={Nav.Item} className="mx-2">
                <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                  <div className="media d-flex align-items-center">
                    <div className="media-body text-dark align-items-center d-lg-block">
                      <span className="mb-0 fw-bold appl-dpdwn">
                        <FontAwesomeIcon icon={faServer} size="lg" />
                        &nbsp;&nbsp;
                        {t(
                          currentApplication?.label ||
                            applicationHelp[applicationArray[0]] ||
                            'Delivery Management'
                        )}
                        &nbsp;
                        <FontAwesomeIcon icon={faCaretDown} />
                      </span>
                    </div>
                  </div>
                </Dropdown.Toggle>

                <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
                  {applicationList.map((appl) => {
                    if (applicationArray.includes(appl.value) || isAdmin) {
                      return (
                        <Dropdown.Item
                          onClick={() => {
                            setCurrentApplication(appl);
                            localStorage.setItem(
                              'vsApplication',
                              JSON.stringify(appl)
                            );
                            window.dispatchEvent(new Event('vsApplication'));
                          }}
                          className="fw-bold appl-dpdwn"
                        >
                          {t(appl.label)}
                        </Dropdown.Item>
                      );
                    }
                  })}
                </Dropdown.Menu>
              </Dropdown>
            )}

            <div className="d-none d-lg-block">
              <Dropdown
                as={Nav.Item}
                style={{
                  border: '2px solid black',
                  borderRadius: 14,
                  padding: '2px 7px',
                  marginRight: 10,
                }}
              >
                <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                  <div className="media d-flex align-items-center">
                    <div className="media-body text-dark align-items-center">
                      <span className="mb-0 fw-bold">
                        <FontAwesomeIcon icon={faLanguage} size="lg" />
                        &nbsp;&nbsp;{currentLanguage.label} &nbsp;
                        <FontAwesomeIcon icon={faCaretDown} />
                      </span>
                    </div>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
                  {languagesSupported.map((language) => {
                    return (
                      <Dropdown.Item
                        key={language.code}
                        onClick={() => {
                          i18n.changeLanguage(language.code);
                          setCurrentLanguage(language);
                          localStorage.setItem(
                            'vsLanguage',
                            JSON.stringify(language)
                          );
                        }}
                        className="fw-bold"
                      >
                        {language.label}
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </div>

            {(isAdmin ||
              roles?.includes('adminOMS') ||
              roles?.includes('inchargeOMS')) && (
              <div className="m-2">
                <CustomNotification />
              </div>
            )}

            <div className="m-2 me-3">
              <FontAwesomeIcon
                size="xl"
                icon={faMobileAlt}
                color="grey"
                onClick={handleDownloadClick}
              />
            </div>
            <DownloadModal
              showModal={showDownload}
              closeModal={handleDownloadClick}
              downloadLink={downloadLink}
            />

            {/* <Dropdown as={Nav.Item} onToggle={markNotificationsAsRead} >
            <Dropdown.Toggle as={Nav.Link} className="text-dark icon-notifications me-lg-3">
            <span className="icon icon-sm">
            <FontAwesomeIcon icon={faBell} className="bell-shake" />
            {areNotificationsRead ? null : <span className="icon-badge rounded-circle unread-notifications" />}
            </span>
            </Dropdown.Toggle>
            <Dropdown.Menu className="dashboard-dropdown notifications-dropdown dropdown-menu-lg dropdown-menu-center mt-2 py-0">
            <ListGroup className="list-group-flush">
            <Nav.Link href="#" className="text-center text-primary fw-bold border-bottom border-light py-3">
            Notifications
            </Nav.Link>

            {notifications.map(n => <Notification key={`notification-${n.id}`} {...n} />)}

            <Dropdown.Item className="text-center text-primary fw-bold py-3">
            View all
            </Dropdown.Item>
            </ListGroup>
            </Dropdown.Menu>
            </Dropdown> */}

            <Dropdown as={Nav.Item}>
              <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                <div className="media d-flex align-items-center">
                  <div className="user-avatar md-avatar rounded-circle bg-secondary">
                    <span>{getCustomerAvatarName(userName)}</span>
                  </div>
                  {/* <Image src={Profile3} className="user-avatar md-avatar rounded-circle" /> */}
                  <div className="media-body ms-2 text-dark align-items-center d-none d-lg-block">
                    <span className="mb-0 font-small fw-bold">
                      {returnCustomerAddressString(
                        userName,
                        '',
                        100,
                        15,
                        'bottom'
                      )}
                    </span>
                    <span className="mb-0 font-small fw-bold">
                      {returnCustomerAddressString(
                        warehouseName,
                        '',
                        100,
                        15,
                        'bottom'
                      )}
                    </span>
                  </div>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
                {/* <Dropdown.Item className="fw-bold">
                <FontAwesomeIcon icon={faUserCircle} className="me-2" /> My Profile
                </Dropdown.Item>
                <Dropdown.Item className="fw-bold">
                <FontAwesomeIcon icon={faCog} className="me-2" /> Settings
                </Dropdown.Item>
                <Dropdown.Item className="fw-bold">
                <FontAwesomeIcon icon={faEnvelopeOpen} className="me-2" /> Messages
                </Dropdown.Item>
                <Dropdown.Item className="fw-bold">
                <FontAwesomeIcon icon={faUserShield} className="me-2" /> Support
                </Dropdown.Item> */}

                {/* <Dropdown.Divider /> */}

                <Dropdown.Item className="fw-bold" onClick={handleLogoutClick}>
                  <FontAwesomeIcon
                    icon={faSignOutAlt}
                    className="text-danger me-2"
                  />
                  Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </div>
      </Container>
    </Navbar>
  );
};
