/* eslint-disable no-unused-expressions */
import React, { useState } from 'react';
import BreadCrumbComponent from '../../../components/common/Breadcrumb';
import { Routes } from '../../../router/routes';
import { useTranslation } from 'react-i18next';
import FileUploadButton from '../../../components/common/fileUpload';
import {
  convertExcelDataToJSONRows,
  convertExcelDateToComputerDate,
} from '../../../utils/excel';
import PurchaseTargetTableItems from './purchaseTargetTable';
import { Button } from '@themesberg/react-bootstrap';
import { faDownload, faUserAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { warehouseTargetSampleFile } from '../../../utils/constants';
import TargetAttendanceHandler from './targetAttendanceHandler';
import { useDispatch, useSelector } from 'react-redux';
import { updateSpinnerState } from '../../../actions/spinner';
import { getWMSUsersList } from '../../../parse-functions/wms/teamlead';
import { returnLeadsBasedOnCategory } from '../../../utils/wms/purchaseTarget';
import { updateToastInfo } from '../../../actions/settings';
import { allocateWarehouseTargetToTeamleads } from '../../../parse-functions/wms/target';
import AchievementModal from '../../../components/common/achievementModal';
import ConfirmationModal from '../../../components/common/confirmationModal';
import { apiCall } from '../../../middlewares/api';
import { apiCallConsts } from '../../../constants/apiCallConsts';
import { orderStatus } from '../../../constants/orderStatus';

const WMSPurchaseTarget = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isDataUploaded, setIsDataUploaded] = useState(false);
  const [uploadedData, setUploadedData] = useState([]);
  const [showTargetAttendanceModal, setShowTargetAttendanceModal] =
    useState(false);
  const [teamLeadList, setTeamLeadList] = useState({});
  const [selectedTeamLeadList, setSelectedTeamLeadList] = useState({});
  const storeName = useSelector(
    (state) => state.user.preferences.storeName || ''
  );
  const [showAchievementModal, setShowAchievementModal] = useState(false);
  const [targetAssignedResponse, setTargetAssignedResponse] = useState({});
  const [showPendingModal, setShowPendingModal] = useState(false);

  const breadCrumbItems = [
    {
      title: t('WMS'),
      link: Routes.WMS.Target.Assign.path,
    },
    {
      title: t('Purchase Target'),
      link: Routes.WMS.Target.Assign.path,
    },
  ];

  const storeCode = useSelector(
    (state) => state.user.preferences.storeName || ''
  );

  const fileUploadComplete = (fileData) => {
    const firstSNoIndex = fileData?.rows?.findIndex((arr) =>
      arr?.includes('SNo')
    );
    const allColumns = fileData?.rows[firstSNoIndex];
    const allRows = fileData?.rows.slice(
      firstSNoIndex + 1,
      fileData?.rows.length
    );
    let uploadedData = convertExcelDataToJSONRows(allColumns, allRows);
    // convert date to ecel Readable Date
    uploadedData?.forEach((order) => {
      order['Delivery Date'] = convertExcelDateToComputerDate(
        order['Delivery Date'],
        true
      );
      order['Date'] = convertExcelDateToComputerDate(order['Date'], true);
    });
    setUploadedData(uploadedData);
    setIsDataUploaded(true);
  };
  const handleAssignToScanClick = async () => {
    try {
      dispatch(updateSpinnerState(true));
      // 1. Check if the attendance details are fetched
      const teamLeads = await getWMSUsersList({
        storeName,
        status: 'active',
      });
      // 2. Get all team leads as per category
      const categoryBasedLeads = returnLeadsBasedOnCategory(teamLeads);
      // 3. Ask for attendance confirmation
      setShowTargetAttendanceModal(true);
      setTeamLeadList(categoryBasedLeads);
      dispatch(updateSpinnerState(false));
    } catch (e) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('Error in fetching Teamlead'),
          message: t(e.message || 'Contact Support'),
        })
      );
    }
  };

  const handleOmsData = async () => {
    dispatch(updateSpinnerState(true));
    console.log('clicked');
    const res = await apiCall(
      apiCallConsts.GET_METHOD,
      `/internal/order?storeCode=${storeCode}&status=Assigned&isDMSCall=true`
    );

    console.log(res[0]);
    const newData = res.map((each, index) => {
      return {
        Area: '',
        Branch: each?.storeCode || '',
        Brand: each?.brand || '',
        Customer: each?.customerName || '',
        'Customer Code': each?.customerCode || '',
        'Customer Mobile No': each?.mobileNumber || '',
        Date: each?.createdAt?.iso,
        'Delivery Date': each?.expectedDeliveryDate,
        'From Branch': each?.storeCode,
        'Item Van No': parseInt(each?.wingsLineNo),
        'Pending Item No': each?.pendingItemLineNo || '',
        'Pending Quantity': each?.quantity,
        'Pin Code': each?.pincode,
        Product: each?.productTitle,
        'Product Category': each?.category,
        'Product Code': '',
        Remarks: 'BY Customer',
        SNo: index,
        Time: '',
        'VA Nnumber': parseInt(each?.vanNo),
        'Voucher No': each?.orderNo,
        Source: 'Online',
        OmsItemLineNo: each?.objectId,
      };
    });
    setIsDataUploaded(true);

    setUploadedData(newData);
    dispatch(updateSpinnerState(false));
  };
  const handleChangeTeamLeadSelection = (chosenItem, category, selected) => {
    const updatedTeamLeadList = JSON.parse(
      JSON.stringify(selectedTeamLeadList)
    );
    if (!updatedTeamLeadList[category]) {
      updatedTeamLeadList[category] = [];
    }
    if (selected) {
      const allSelectedIds = updatedTeamLeadList[category]?.map(
        (leads) => leads.id
      );
      if (allSelectedIds.indexOf(chosenItem.id) < 0) {
        updatedTeamLeadList[category].push(chosenItem);
      }
    } else {
      updatedTeamLeadList[category] = updatedTeamLeadList[category].filter(
        (teamLeadId) => teamLeadId.id !== chosenItem.id
      );
    }
    setSelectedTeamLeadList(updatedTeamLeadList);
  };

  const allocateTargetConfirm = async () => {
    try {
      dispatch(updateSpinnerState(true));
      const targetAssignedResponse = await allocateWarehouseTargetToTeamleads({
        teamLeadList: selectedTeamLeadList,
        uploadedData: JSON.parse(JSON.stringify(uploadedData)),
        storeName,
      });

      setTargetAssignedResponse(targetAssignedResponse);
      if (targetAssignedResponse?.pendingItemsTotal > 0) {
        setShowPendingModal(true);
        dispatch(updateSpinnerState(false));
        return;
      }
      dispatch(
        updateToastInfo({
          show: true,
          type: 'success',
          title: t('Assignment Complete'),
          message: t('All Target Assigned to available users'),
        })
      );
      dispatch(updateSpinnerState(false));
      setShowTargetAttendanceModal(false);
      setShowPendingModal(false);
      setUploadedData([]);
      setIsDataUploaded(false);
      setShowAchievementModal(true);
    } catch (e) {
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('Error in Assigning Target'),
          message: t(e.message || 'Contact Support'),
        })
      );
      dispatch(updateSpinnerState(false));
    }
  };

  const showTable = isDataUploaded && uploadedData?.length > 0;
  return (
    <>
      <div className="mb-4 d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-2">
        <div className="d-block mb-4 mb-md-0">
          <BreadCrumbComponent items={breadCrumbItems} />
          <h4>{t('Assign Target')}</h4>
          <p className="mb-0">
            {t('Use this page to assign target to team leads to scan')}
          </p>
        </div>
        {showTable && (
          <div>
            <Button variant="primary" onClick={handleAssignToScanClick}>
              <FontAwesomeIcon icon={faUserAlt} className="me-1" /> Assign to
              Scan
            </Button>
          </div>
        )}
      </div>
      {!showTable && (
        <div
          style={{
            display: 'flex',
            height: 500,
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '20px',

            backgroundColor: '#f9f9f9',
          }}
        >
          {/* Left Half - File Upload */}
          <div
            style={{
              flex: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              padding: '10px',
            }}
          >
            <FileUploadButton
              buttonText={'Upload File to Scan'}
              onFileUpload={fileUploadComplete}
              style={{
                backgroundColor: '#007bff',
                color: '#fff',
                border: 'none',
                padding: '10px 20px',
                borderRadius: '5px',
                cursor: 'pointer',
              }}
            />
            <div style={{ color: '#888', margin: '10px 0' }}>or</div>
            <div
              style={{
                cursor: 'pointer',
                textDecoration: 'underline',
                color: '#007bff',
              }}
              onClick={() => {
                window.open(warehouseTargetSampleFile, '_blank');
              }}
            >
              Download Template from here
            </div>
          </div>

          {/* Center Divider with Vertical Line and 'or' */}
          <div
            style={{
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              margin: '0 20px',
            }}
          >
            <span
              style={{
                backgroundColor: '#f9f9f9',
                padding: '0 10px',
                color: '#888',
                fontSize: '18px',
                fontWeight: 'bold',
              }}
            >
              or
            </span>
          </div>

          {/* Right Half - Other Options */}
          <div
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '10px',
            }}
          >
            <Button onClick={handleOmsData}>
              <FontAwesomeIcon icon={faDownload} /> &nbsp;&nbsp;Website Orders
              to Scan
            </Button>
          </div>
        </div>
      )}
      {showTable && <PurchaseTargetTableItems tableData={uploadedData} />}
      <TargetAttendanceHandler
        selectedTeamLeadList={selectedTeamLeadList}
        allocateTargetConfirm={allocateTargetConfirm}
        handleChangeTeamLeadSelection={handleChangeTeamLeadSelection}
        teamLeadList={teamLeadList}
        show={showTargetAttendanceModal}
        handleClose={() => {
          setShowTargetAttendanceModal(false);
        }}
      />
      <AchievementModal
        show={showAchievementModal}
        handleClose={() => {
          setShowAchievementModal(false);
        }}
        buttonText={t('Close')}
        topTitle={t('')}
        mainHeaderText={t('Target Assigned Successfully')}
        subText={`Targets Assigned: ${targetAssignedResponse.itemsAssigned}`}
      />
      <ConfirmationModal
        showModal={showPendingModal}
        closeModal={() => {
          setShowPendingModal(false);
          allocateTargetConfirm(false, false);
        }}
        modalBody={`${targetAssignedResponse.pendingItemsTotal} pending items. Do you want to assign?`}
        modalTitle={`Pending Items`}
        onConfirm={() => allocateTargetConfirm(false, true)}
      />
    </>
  );
};
export default WMSPurchaseTarget;
