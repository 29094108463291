import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Card,
  Image,
  Button,
  Container,
} from "@themesberg/react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Routes } from "../../router/routes";
import NotFoundImage from "../../assets/img/illustrations/404.svg";
import { useSelector } from "react-redux";

export default () => {
  const navigate = useNavigate();
  const preferences = useSelector((state) => state?.user?.preferences || {});
  const handleClick = () => {
    if (preferences.applications?.[0] === "sms") {
      return navigate(Routes.SMS.Target.ActiveSchemes.path);
    }
    return navigate(Routes.HomePage.path);
  };
  return (
    <main>
      <section className="vh-100 d-flex align-items-center justify-content-center">
        <Container>
          <Row>
            <Col
              xs={12}
              className="text-center d-flex align-items-center justify-content-center"
            >
              <div>
                <Card.Link as={Link}>
                  <Image src={NotFoundImage} className="img-fluid w-75" />
                </Card.Link>
                <h1 className="text-primary mt-5">
                  Page not <span className="fw-bolder">found</span>
                </h1>
                <p className="lead my-4">
                  Oops! Looks like you followed a bad link. If you think this is
                  a problem with us, please tell us.
                </p>
                <Button
                  variant="primary"
                  className="animate-hover"
                  onClick={handleClick}
                >
                  <FontAwesomeIcon
                    icon={faChevronLeft}
                    className="animate-left-3 me-3 ms-2"
                  />
                  Go back home
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
