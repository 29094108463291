import Parse from "parse";
import React, { useState } from "react";
import ParcelStatusPage from "../../../components/common/parcelStatusPages";
import { returnTeamLeadTableColumns } from "../../../utils/wms/teamlead";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Routes } from "../../../router/routes";
import { updateSpinnerState } from "../../../actions/spinner";
import { updateToastInfo } from "../../../actions/settings";
import { getWMSUsersList } from "../../../parse-functions/wms/teamlead";
import { setWMSUserAsSelected, setWMSUserListInStore } from "../../../actions/wms/teamlead";
import ConfirmationModal from "../../../components/common/confirmationModal";
import TeamLeadUpdateModal from "./editModal";
import { updateDMSUserInfo } from "../../../parse-functions/users";

const TeamLeadList = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const storeName = useSelector((state) => state?.user?.preferences?.storeName || "");
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showUserUpdateModal, setShowUserUpdateModal] = useState(false);
    const wmsUsersList = useSelector((state) => state?.teamLeads?.usersList || {});
    const selectedUsers = Object.values(wmsUsersList)?.filter(user => user?.isSelected === true);
    const isSuperAdmin = useSelector((state) => state?.user?.preferences?.isSuperAdmin || false);
    const [selectedUserToUpdate, setSelectedUserToUpdate] = useState({});

    const breadCrumbItems = [{
        title: t("WMS"),
        link: Routes.TeamLead.List.path
    }, {
        title: t("User List"),
        link: Routes.TeamLead.List.path
    }];
    const getAvailableWMSUsersList = async () => {
        dispatch(updateSpinnerState(true));
        try {
            const wmsUsers = await getWMSUsersList({
                isSuperAdmin,
                storeName
            });
            dispatch(setWMSUserListInStore(wmsUsers));
            dispatch(updateSpinnerState(false));
            dispatch(updateToastInfo({
                show: true,
                type: 'success',
                title: t('WMS Team Lead List fetched'),
                message: t("All the Users that are Team Lead in WMS")
            }))

        } catch (e) {
            dispatch(updateSpinnerState(false));
            dispatch(updateToastInfo({
                show: true,
                type: 'danger',
                title: t('Error'),
                message: t(e.message)
            }))
        }
    };
    const onRefreshClick = () => {
        getAvailableWMSUsersList();
    }
    const onRowSelect = (selected, objectId) => {
        dispatch(setWMSUserAsSelected({ objectId, selected }));
    }
    const handleShowDeleteModal = () => {
        setShowDeleteModal(true);
    }
    const handleEditTeamLeadModal = () => {
        setShowUserUpdateModal(true);
    };
    const handleSelectedRowsUpdate = (actionId) => {
        switch (actionId) {
            case "delete": {
                handleShowDeleteModal()
                break;
            }
            case "edit": {
                if (selectedUsers?.length !== 1) {
                    dispatch(updateToastInfo({
                        show: true,
                        type: 'danger',
                        title: t("Only 1 Selection allowed"),
                        message: t("Please select only 1 team lead for editing")
                    }))
                } else {
                    // get selected User data
                    if (selectedUsers?.length > 0) {
                        setSelectedUserToUpdate(selectedUsers[0]);
                        handleEditTeamLeadModal();
                    } else {
                        dispatch(updateToastInfo({
                            show: true,
                            type: 'danger',
                            title: t("No user found"),
                            message: t("No User found to update details")
                        }))
                    }
                }
                break;
            }
            default: {
                break;
            }
        }
        console.log("showing items");
        console.log(actionId);
    }
    const handleOnConfirmClick = async (data) => {
        try {
            dispatch(updateSpinnerState(true));
            await updateDMSUserInfo({ data });
            onRefreshClick();
            dispatch(updateSpinnerState(false));
            dispatch(updateToastInfo({
                show: true,
                type: "success",
                title: "User Update Successful",
                message: ""
            }));
            setShowDeleteModal(false);
            setShowUserUpdateModal(false);
        } catch (e) {
            dispatch(updateSpinnerState(false));
            dispatch(updateToastInfo({
                show: true,
                type: "danger",
                title: "WMS User Update Failed",
                message: "Please contact support for help"
            }))
        }
    };
    const handleUpdateTeamLeadConfirm = (userObj) => {
        try {
            const userItemData = [{
                objectId: userObj.objectId,
                isDeleted: false,
                className: "teamleads",
                dataFields: {
                    category: userObj.category,
                    inchargeName: userObj.inchargeName,
                    phoneNumber: userObj.phoneNumber,
                    actionLog: {
                        action: "updated",
                        user: Parse.User.current().getUsername(),
                        time: new Date().getTime()
                    }
                }
            }];
            handleOnConfirmClick(userItemData);
        } catch(e) {
            dispatch(updateSpinnerState(false));
            dispatch(updateToastInfo({
                show: true,
                type: "danger",
                title: t("Teamlead Update failed")
            }))
        }
    }
    const handleDeleteModalOnConfirmClick = () => {
        try {
            dispatch(updateSpinnerState(true));
            const userItemData = [];
            // eslint-disable-next-line no-unused-expressions
            selectedUsers?.forEach(userItem => {
                const data = {};
                data.objectId = userItem.objectId;
                data.isDeleted = true;
                data.className = "teamleads";
                data.dataFields = {
                    status: "deleted",
                    actionLog: {
                        action: "deleted",
                        user: Parse.User.current().getUsername(),
                        time: new Date().getTime()
                    }
                };
                userItemData.push(data);
            });
            handleOnConfirmClick(userItemData);
        } catch (e) {
            dispatch(updateToastInfo({
                show: true,
                type: "danger",
                title: t("Teamlead delete failed")
            }))
            dispatch(updateSpinnerState(false));
        }
    };
    return (
        <>
            <ParcelStatusPage
                pageType={"inchargeList"}
                showDateFilters={false}
                tableData={wmsUsersList}
                tableColumns={returnTeamLeadTableColumns(onRowSelect)}
                handleRefresh={onRefreshClick}
                pageTitle={t("WMS Team Lead List")}
                breadCrumbItems={breadCrumbItems}
                pageSubTitle={t("All the Team leads who have access to the platform")}
                searchPlaceHolder={t("Search By Name, Warehouse etc")}
                enableBulkActions={selectedUsers?.length > 0}
                bulkActionOptions={[{
                    label: "Edit",
                    value: "edit"
                }, {
                    label: "Delete / Remove",
                    value: "delete"
                }]}
                handleItemBulkUpdate={handleSelectedRowsUpdate}
            />
            <ConfirmationModal
                showModal={showDeleteModal}
                onConfirm={handleDeleteModalOnConfirmClick}
                closeModal={() => {
                    setShowDeleteModal(false);
                }}
                modalBody={t("Are you sure you want to delete this user")}
                modalTitle={t("Delete User")}
            />
            <TeamLeadUpdateModal
                show={showUserUpdateModal}
                onConfirm={handleUpdateTeamLeadConfirm}
                user={selectedUserToUpdate}
            />
        </>
    )
}
export default TeamLeadList;