export const newSchemeSchema = [
  [
    {
      title: 'Scheme Info',
      type: 'header',
    },
    {
      title: 'Company',
      isMultiSelect: false,
      type: 'select',
      id: 'company',
      options: [],
      required: true,
    },
    {
      title: 'Scheme Circular No.',
      id: 'schemeCircularNo',
      type: 'text',
      required: false,
    },
    {
      title: 'Scheme Circular Date',
      id: 'schemeCircularDate',
      type: 'date',
      required: true,
    },
    {
      title: 'Regions',
      id: 'regions',
      type: 'select',
      isMultiSelect: true,
      required: true,
      options: [],
    },
    {
      title: 'Valid From',
      id: 'validFrom',
      type: 'date',
      required: true,
    },
    {
      title: 'Valid Till',
      id: 'validTill',
      type: 'date',
      required: true,
    },
  ],
  [
    {
      title: 'Scheme Type',
      id: 'type',
      type: 'select',
      options: [
        { label: 'Sell-in', value: 'Sell-in' },
        { label: 'Sell-out', value: 'Sell-out' },
      ],
      required: true,
    },
    {
      title: 'Remarks',
      id: 'remarks',
      type: 'textarea',
      required: false,
      rows: 5,
    },
    {
      title: 'Upload Excel',
      id: 'uploadExcel',
      type: 'switch',
      options: [
        {
          label: 'Yes',
          value: true,
        },
        {
          label: 'No',
          value: false,
        },
      ],
    },
    {
      title: 'Create Scheme',
      type: 'button',
    },
    {
      title: 'Cancel',
      type: 'button',
    },
  ],
  [
    {
      title: 'Category',
      isMultiSelect: true,
      id: 'category',
      type: 'select',
      options: [],
      required: true,
    },

    {
      title: 'Sub-Category',
      isMultiSelect: true,
      id: 'subCategory',
      type: 'select',
      options: [],
      required: false,
    },
    {
      title: 'Product',
      id: 'products',
      isMultiSelect: true,
      type: 'select',
      options: [],
      required: true,
    },
    {
      title: 'Valuation Type',
      id: 'valuationType',
      type: 'select',
      options: [
        { label: 'Percentage', value: 'Percentage' },
        { label: 'Value', value: 'Value' },
      ],
      required: true,
    },
    {
      title: 'Payout (% or Per Unit)',
      id: 'payout',
      type: 'number',
      required: true,
    },

    {
      title: 'Payout Basis #',
      id: 'payoutBasis',
      type: 'select',
      options: [
        { label: 'DP', value: 'DP' },
        { label: 'DP(Basic)', value: 'DP(Basic)' },
        { label: 'Net of Margin', value: 'Net of Margin' },
        { label: 'NLC(Basic)', value: 'NLC(Basic)' },
      ],
      required: true,
    },
  ],
];

export const schemeToggle = [
  {
    title: 'Category',
    isMultiSelect: true,
    id: 'category',
    type: 'select',
    options: [],
    required: true,
  },

  {
    title: 'Sub-Category',
    isMultiSelect: true,
    id: 'subCategory',
    type: 'select',
    options: [],
    required: true,
  },
  {
    title: 'Product',
    id: 'products',
    isMultiSelect: true,
    type: 'select',
    options: [],
    required: true,
  },
  {
    title: 'Valuation Type',
    id: 'valuationType',
    type: 'select',
    options: [
      { label: 'Percentage', value: 'Percentage' },
      { label: 'Value', value: 'Value' },
    ],
    required: true,
  },
  {
    title: 'Payout (% or Per Unit)',
    id: 'payout',
    type: 'number',
    required: true,
  },

  {
    title: 'Payout Basis #',
    id: 'payoutBasis',
    type: 'select',
    options: [
      { label: 'DP', value: 'DP' },
      { label: 'DP(Basic)', value: 'DP(Basic)' },
      { label: 'Net of Margin', value: 'Net of Margin' },
      { label: 'NLC(Basic)', value: 'NLC(Basic)' },
    ],
    required: true,
  },
];
