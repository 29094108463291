import {
  faBarcode,
  faCheck,
  faClock,
  faList,
  faQrcode,
  faStore,
  faTrash,
  faUserAlt,
  faUserTie,
} from '@fortawesome/free-solid-svg-icons';
import logo from '../../assets/img/brand/vijay-sales-logo.svg';
import { Routes as AppRoutes } from '../../router/routes';

const navigationConfig = {
  Dashboard: {
    title: 'Dashboard',
    link: AppRoutes.WMS.Target.Assign.path,
    image: logo,
  },
  Assign: {
    title: 'Allocate Target',
    link: AppRoutes.WMS.Target.Assign.path,
    icon: faBarcode,
  },
  Assigned: {
    title: 'Assigned',
    link: AppRoutes.WMS.Target.Assigned.path,
    icon: faUserAlt,
  },
  Scanned: {
    title: 'Scanned',
    link: AppRoutes.WMS.Target.Scanned.path,
    icon: faQrcode,
  },
  Seen: {
    title: 'Seen / Completed',
    link: AppRoutes.WMS.Target.Completed.path,
    icon: faCheck,
  },
  Cancelled: {
    title: 'Cancelled',
    link: AppRoutes.WMS.Target.Cancelled.path,
    icon: faTrash,
  },
  // Pending: {
  //   title: 'Pending',
  //   link: AppRoutes.WMS.Target.Pending.path,
  //   icon: faClock,
  // },
  Users: {
    title: 'Users',
    eventKey: 'add-users/',
    icon: faUserTie,
    children: [
      {
        title: 'Team Lead List',
        link: AppRoutes.TeamLead.List.path,
        icon: faList,
      },
      {
        title: 'Add Team Lead',
        link: AppRoutes.TeamLead.Add.path,
        icon: faStore,
      },
    ],
  },
};

export default navigationConfig;
