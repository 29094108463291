import Parse from 'parse';

export const getDMSUsersList = () => {
  return Parse.Cloud.run('getDMSUsersList', {});
};
export const updateDMSUserInfo = (data) => {
  return Parse.Cloud.run('updateDMSUserInfo', data);
};

export const addNewRegionUser = (data) => {
  return Parse.Cloud.run('addNewRegionUser', data);
};
