import { Form } from '@themesberg/react-bootstrap';
import React from 'react';
import EditableDropDown from './EditableDropdown';
import { useTranslation } from 'react-i18next';

const RejectDropdown = (props) => {
  const { options, setReason, setRemarks, reason, remarks, position } = props;
  const { t } = useTranslation();

  const newOptions = [...options, { label: 'Other', value: 'Other' }];

  const containerStyle = {
    display: position !== 'bottom' && 'flex',
    flexDirection: position === 'bottom' ? 'column' : 'row',
    alignItems: position === 'bottom' ? 'flex-start' : 'center',
  };

  const dropdownStyle = {
    flex: '1 1 auto',
    marginRight: position === 'bottom' ? '0' : '5px',
  };

  const remarksStyle = {
    flex: '1 1 50%',
    marginTop: position === 'bottom' ? '10px' : '0',
    marginLeft: position === 'bottom' ? '0' : '5px',
  };

  return (
    <div style={containerStyle}>
      <div style={dropdownStyle}>
        <Form.Group>
          {t(`Reject :`)}
          <EditableDropDown
            id={'Reject'}
            onChange={(e) => setReason([e.value])}
            value={reason}
            options={newOptions}
            placeholder={'Select a reason'}
          />
        </Form.Group>
      </div>
      {reason[0] === 'Other' && (
        <div style={remarksStyle}>
          <Form.Group style={{ flex: 1 }}>
            <Form.Label>{t(`Remarks :`)}</Form.Label>
            <Form.Control
              id={'Remarks'}
              name={'Remarks'}
              value={remarks}
              onChange={(e) => setRemarks(e.target.value)}
              minLength={3}
              rows={2}
              as="textarea"
              isInvalid={false}
              required={true}
            />
          </Form.Group>
        </div>
      )}
    </div>
  );
};

export default RejectDropdown;
