import React from "react";
import { Form, Row, Col } from "@themesberg/react-bootstrap";
import { useTranslation } from "react-i18next";

const TimePicker = ({ formErrors, onFormInputChange }) => {
  const { t } = useTranslation();

  return (
    <>
      <Form.Group className="mb-3">
        <Row>
          <Col>
            <Form.Label>{t("Start Time")}</Form.Label>
            <Form.Control
              required
              type="time"
              isInvalid={formErrors["startTime"]?.length}
              onChange={(event) => {
                onFormInputChange("startTime", event.target.value);
              }}
            />
            {formErrors["startTime"]?.length && (
              <Form.Control.Feedback type="invalid">
                {t("Please enter time.")}
              </Form.Control.Feedback>
            )}
          </Col>
          <Col>
            <Form.Label>{t("End Time")}</Form.Label>
            <Form.Control
              required
              type="time"
              isInvalid={formErrors["endTime"]?.length}
              onChange={(event) => {
                onFormInputChange("endTime", event.target.value);
              }}
            />
            {formErrors["endTime"]?.length && (
              <Form.Control.Feedback type="invalid">
                {t("Please enter time.")}
              </Form.Control.Feedback>
            )}
          </Col>
        </Row>
      </Form.Group>
    </>
  );
};

export default TimePicker;
