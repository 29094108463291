import React from 'react';
import navigationConfig from './smsNavigationConfig';
import RenderNavItems from '../common/renderNavItems';
import { useSelector } from 'react-redux';
import {
  faUserTie,
  faListUl,
  faPlus,
  faHourglass,
  faCheckDouble,
  faBan,
  faLock,
  faTags,
  faAdd,
} from '@fortawesome/free-solid-svg-icons';
import { Routes as AppRoutes } from '../../router/routes';
import logo from '../../assets/img/brand/vijay-sales-logo.svg';
import { faProductHunt } from '@fortawesome/free-brands-svg-icons';

const SchemeSidebarOptions = () => {
  let config = navigationConfig;
  const configData = useSelector((state) => state.user.config || {});
  if (configData.showSchemeRedesign) {
    config = {
      Dashboard: {
        title: 'Dashboard',
        link: AppRoutes.SMS.Target.SchemeDashboard.path,
        image: logo,
      },
      Schemes: {
        title: 'Scheme',
        eventKey: 'scheme/',
        icon: faTags,
        children: [
          {
            title: 'Add a Scheme',
            icon: faPlus,
            link: AppRoutes.SMS.Target.RedesignAddScheme.path,
          },
          {
            title: 'Draft',
            icon: faHourglass,
            link: AppRoutes.SMS.Target.RedesignDraftSchemes.path,
          },
          {
            title: 'Active',
            icon: faCheckDouble,
            link: AppRoutes.SMS.Target.RedesignActiveSchemes.path,
          },
          {
            title: 'Cancelled',
            icon: faBan,
            link: AppRoutes.SMS.Target.RedesignRejectedSchemes.path,
          },
          {
            title: 'Closed',
            icon: faLock,
            link: AppRoutes.SMS.Target.RedesignClosedSchemes.path,
          },
        ],
      },
      Users: {
        title: 'Users',
        eventKey: 'addUsers/',
        icon: faUserTie,
        children: [
          {
            title: 'Add a User',
            icon: faUserTie,
            link: AppRoutes.SMS.Target.AddUser.path,
          },
          {
            title: 'User List',
            icon: faListUl,
            link: AppRoutes.SMS.Target.SMSUserList.path,
          },
        ],
      },
      Products: {
        title: 'Products',
        icon: faProductHunt,
        eventKey: 'products/',
        children: [
          {
            title: 'Add Product',
            icon: faAdd,
            link: AppRoutes.SMS.Target.AddSchemeProduct.path,
          },
        ],
      },
    };
  }
  return <RenderNavItems config={config} />;
};
export default SchemeSidebarOptions;
