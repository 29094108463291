
import React, { useState } from "react";
import Pagination from "react-bootstrap-table2-paginator";
import * as Paginator from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BreadCrumbComponent from "../../components/common/Breadcrumb";
import { Button, ButtonGroup, Card, Col, Row } from "@themesberg/react-bootstrap";
import Table from "react-bootstrap-table-next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { Routes } from "../../router/routes";
import { customSizePerPage, customTotal } from "../../components/common/parcelStatusPages";
import EmptyTable from "../../components/common/EmptyTableView";
import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { updateToastInfo } from "../../actions/settings";
import { updateSpinnerState } from "../../actions/spinner";
import DateRangeSelector from "../../components/common/DateRangeSelector";
import { setFiltersInStore } from "../../actions/filters";
import { returnEndOfDay, returnStartOfDay } from "../../utils/datetime";
import { returnRowBGColor } from "../../utils/parcels";
import { getOrdersFromPendingTable } from "../../parse-functions/orders";
import { priorityDeliveryTableColumns } from "../../utils/priorityDelivery";
import ExcelDownloadReport from "../../components/common/excel/download";

const PriorityDeliveryComponent = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const pageType = "priority-delivery";
    const [parcels, setParcels] = useState([]);
    const filtersValue = useSelector((state) => state.filters?.[pageType]);
    const zone = useSelector((state) => state?.user?.warehouse?.zone || "")

    const breadCrumbItems = [{
        title: t("Analysis"),
        link: Routes.Analysis.DeliveryData.path
    }, {
        title: t("Priority Delivery"),
        link: ""
    }];

    const onDateSelected = (startDate, endDate) => {
        dispatch(setFiltersInStore({
            pageType,
            data: {
                startDate: new Date(startDate),
                endDate: new Date(endDate)
            }
        }))
        getAvailableParcels(startDate, endDate);
    }
    const handleParcelSearch = () => {
        const currentStartDate = filtersValue.startDate ? new Date(filtersValue.startDate) : returnStartOfDay(new Date());
        const currentEndDate = filtersValue.endDate ? new Date(filtersValue.endDate) : returnEndOfDay(new Date());
        getAvailableParcels(currentStartDate, currentEndDate);
    }
    const getAvailableParcels = async (startDate, endDate) => {
        dispatch(updateSpinnerState(true));
        try {
            const filters = {
                zone,
                startDate: new Date(startDate),
                endDate: new Date(endDate),
                fieldsToMatch: {
                    priorityDelivery: true
                }
            };
            const response = await getOrdersFromPendingTable(filters);
            setParcels(response);
            dispatch(updateSpinnerState(false));
            dispatch(updateToastInfo({
                show: true,
                type: "success",
                title: t("Orders fetched"),
                message: t("You can check the orders for each type and status below")
            }))
        } catch (e) {
            dispatch(updateToastInfo({
                show: true,
                type: "danger",
                title: t("Fetching Orders failed"),
                message: t(e.message)
            }))
            dispatch(updateSpinnerState(false));
        }
    }
    return (
        <div>
            <ToolkitProvider
                exportCSV
                keyField="id"
                search={true}
                sort
                columns={priorityDeliveryTableColumns()}
                data={parcels}
                wrapperClasses="table-responsive"
            >
                {({ baseProps, searchProps, csvProps }) => (
                    <Paginator.PaginationProvider pagination={
                        Pagination({
                            custom: true,
                            showTotal: true,
                            alwaysShowAllBtns: true,
                            totalSize: parcels?.length,
                            paginationTotalRenderer: customTotal,
                            sizePerPageRenderer: customSizePerPage
                        })
                    }>
                        {({ paginationProps, paginationTableProps }) => (
                            <>
                                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                                    <div className="d-block mb-4 mb-md-0">
                                        <BreadCrumbComponent
                                            items={breadCrumbItems}
                                        />
                                        <h4>{t("Priority Delivery")}</h4>
                                        <p className="mb-0">{t("These are all the orders that are tagged for priority")}</p>
                                    </div>
                                    <div className="btn-toolbar mb-2 mb-md-0">
                                        <ButtonGroup>
                                            <Button
                                                onClick={() => {
                                                    handleParcelSearch();
                                                }}
                                                variant="outline-primary"
                                                size="sm">
                                                <FontAwesomeIcon icon={faRefresh} />&nbsp;&nbsp;{t("Refresh")}
                                            </Button>
                                            <ExcelDownloadReport
                                                data={parcels || []}
                                                fileName={"priority-delivery.csv"}
                                            />
                                        </ButtonGroup>
                                    </div>
                                </div>
                                <Row>
                                    <Col md={6}>
                                        <DateRangeSelector onSubmit={onDateSelected} filtersValue={filtersValue} />
                                    </Col>
                                </Row>
                                <Card>
                                    <div className="table-responsive py-2">
                                        <Card.Header>
                                            <Row className="d-flex justify-content-between">
                                                <Col xs={4} md={4} className="d-flex justify-content-md-end py-1">
                                                    <Search.SearchBar {...searchProps} placeholder={t("Search any value in table here")} />
                                                    <Search.ClearSearchButton { ...searchProps } />
                                                </Col>
                                            </Row>
                                        </Card.Header>

                                        <Table
                                            rowClasses={(row) => {
                                                const rowBGColor = returnRowBGColor(row?.dashboardStatus || row?.mobileStatus || row?.status);
                                                return `${rowBGColor} border-bottom`;
                                            }}
                                            noDataIndication={<EmptyTable />}
                                            {...baseProps}
                                            {...paginationTableProps}
                                            bodyClasses="border-0"
                                            classes="table-flush dataTable-table"
                                        />

                                        <Card.Footer>
                                            <Row>
                                                <Col xs={12} md={4} className="py-1">
                                                    <Paginator.SizePerPageDropdownStandalone {...paginationProps} paginationSize={5} sizePerPageList={[{ text: "5", value: 5 }]} />
                                                </Col>
                                                <Col xs={12} md={4} className="d-flex align-items-center py-1">
                                                    <Paginator.PaginationTotalStandalone {...paginationProps} />
                                                </Col>
                                                <Col xs={12} md={4} className="d-flex justify-content-md-end align-items-center mb-0 py-1">
                                                    <Paginator.PaginationListStandalone {...paginationProps} />
                                                </Col>
                                            </Row>
                                        </Card.Footer>
                                    </div>
                                </Card>
                            </>
                        )}
                    </Paginator.PaginationProvider>
                )}
            </ToolkitProvider>
        </div>
    )
}
export default PriorityDeliveryComponent;