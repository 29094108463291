import { Badge } from '@themesberg/react-bootstrap';
import React from 'react';
import { getTripBadgeStatus } from '../../utils/trips';
import { getTableRowCheckBox } from '../../utils/table';

export const returnDMSUserListTableColumns = (onRowSelect, type) => {
  const columns = [
    {
      dataField: 'objectId',
      text: 'Select',
      formatter: (cell, row) => {
        return getTableRowCheckBox(onRowSelect, cell, row?.isSelected, false);
      },
    },
    {
      dataField: 'name',
      sort: true,
      text: 'Name',
      formatter: (cell, row) => {
        return (
          <div className="d-block">
            <span className="fw-bold">{cell}</span>
            {/* <div className="fw-normal">{row?.vendorCode}</div> */}
          </div>
        );
      },
    },
    {
      dataField: 'storeName',
      text: 'Store Name',
      formatter: (cell, row) => {
        return (
          <div className="d-block">
            <span className="fw-bold">{cell}</span>
          </div>
        );
      },
    },
    {
      dataField: 'emailId',
      text: 'Email ID',
      formatter: (cell, row) => {
        return (
          <div className="d-block">
            <span className="fw-bold">{cell}</span>
          </div>
        );
      },
    },
    {
      dataField: 'phoneNumber',
      text: 'Phone Number',
      formatter: (cell, row) => {
        return (
          <div className="d-block">
            <span className="fw-bold">{cell}</span>
          </div>
        );
      },
    },
    {
      dataField: 'status',
      text: 'Status',
      formatter: (cell, row) => {
        return (
          <div className="d-block">
            <div className="fw-bold">
              <span>
                &nbsp;&nbsp;
                <Badge
                  bg={getTripBadgeStatus(cell ? cell : 'active')}
                  className="badge-lg"
                >
                  {cell?.toUpperCase() || 'ACTIVE'}
                </Badge>
                &nbsp;&nbsp;
              </span>
            </div>
          </div>
        );
      },
    },
  ];

  if (type !== 'oms') {
    columns.splice(5, 0, {
      dataField: 'type',
      text: 'User Type',
      formatter: (cell, row) => {
        return (
          <div className="d-block">
            <span className="fw-bold">{cell}</span>
          </div>
        );
      },
    });
  }

  return columns;
};
