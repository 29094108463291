import React from 'react';
import { getTableRowCheckBox } from '../../utils/table';
import { returnUserReadableDateTime } from '../../utils/datetime';

export const addSecurityGuardSchema = [
  [
    {
      title: 'Security User Info',
      type: 'header',
    },
    {
      title: 'Guard Name',
      type: 'text',
      id: 'name',
      required: true,
    },
    {
      title: 'Phone Number',
      id: 'phoneNumber',
      type: 'number',
      required: true,
    },
    {
      title: 'Store Name',
      id: 'storeName',
      type: 'select',
      options: [],
      required: true,
    },
  ],
  [
    {
      title: 'Login Info',
      type: 'header',
    },
    {
      title: 'UserName',
      id: 'username',
      type: 'text',
      required: true,
    },
    {
      title: 'Password',
      id: 'password',
      type: 'text',
      required: true,
    },
    {
      title: 'Repeat Password',
      id: 'repeatPassword',
      type: 'text',
      required: true,
    },
    {
      title: 'Create user',
      type: 'button',
    },
    {
      title: 'Cancel',
      type: 'button',
    },
  ],
];

export const editSecurityGuardSchema = [
  [
    {
      title: 'Security User Info',
      type: 'header',
    },
    {
      title: 'Guard Name',
      type: 'text',
      id: 'name',
      required: true,
    },
    {
      title: 'Phone Number',
      id: 'phoneNumber',
      type: 'number',
      required: true,
    },
    {
      title: 'Store Name',
      id: 'storeName',
      type: 'select',
      options: [],
      required: true,
    },
    {
      title: 'update user',
      type: 'button',
    },
  ],
];

export const vehicleColums = (onRowSelect) => {
  const columns = [
    {
      dataField: 'objectId',
      text: 'select',
      formatter: (cell, row) => {
        return getTableRowCheckBox(onRowSelect, cell, row?.isSelected, false);
      },
    },
    {
      dataField: 'driverName',
      text: 'DriverName',
    },
    {
      dataField: 'contactNumber',
      text: 'PhoneNumber',
    },
    {
      dataField: 'vendorName',
      text: 'vendorName',
    },
    {
      dataField: 'entryTime',
      text: 'entryTime',
      formatter: (cell, row) => {
        return returnUserReadableDateTime(cell);
      },
    },
    {
      dataField: 'vehicleNumber',
      text: 'vehicleNumber',
    },
    {
      dataField: 'vehicleType',
      text: 'vehicleType',
    },
  ];
  return columns;
};
