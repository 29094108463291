import {
    MARK_PARCEL_AS_DISABLED_IN_STATE,
    SET_PARCELS_FOR_STAGES_IN_STORE,
    SET_PARCEL_INFO_IN_STORE_FOR_STAGE
} from "./types"

export const markParcelAsDisabled = (pageName, parcelId) => {
    return {
        type: MARK_PARCEL_AS_DISABLED_IN_STATE,
        data: {
            pageName,
            parcelId
        }
    }
}
export const setParcelsForStagesInStore = (stage, parcels) => {
    return {
        type: SET_PARCELS_FOR_STAGES_IN_STORE,
        data: {
            stage,
            parcels
        }
    }
}

export const updateParcelObjectWithData = (stage, parcelInfo) => {
    return {
        type: SET_PARCEL_INFO_IN_STORE_FOR_STAGE,
        data: {
            stage,
            parcelInfo
        }
    }
}