import Parse from "parse";
import React, { useEffect, useState } from "react";
import ParcelStatusPage from "../../components/common/parcelStatusPages";
import { useDispatch, useSelector } from "react-redux";
import { Routes } from "../../router/routes";
import { useTranslation } from "react-i18next";
import Joi from "joi";
import { convertExcelDataToJSONForLoaders } from "../../utils/excel";
import { returnValidationErrorMessages } from "../../utils/validation";
import UploadDisplayMissingBranchInfoModal from "../../components/planning/uploadDisplayMissingBranchInfoModal";
import { updateSpinnerState } from "../../actions/spinner";
import { getLoadersListForStore, updateLoadersInfo, uploadLoadersDataToServer } from "../../parse-functions/loaders";
import { updateToastInfo } from "../../actions/settings";
import { markLoaderAsSelected, setLoadersListInStore } from "../../actions/loaders";
import { returnLoadersListTableColumns } from "./loadersUtils";
import { loaderMasterSampleFile } from "../../utils/constants";
import ConfirmationModal from "../../components/common/confirmationModal";
import LoaderUpdateModal from "./LoaderUpdateModal";

const loadersListValidationSchema = Joi.array().items(Joi.object({
    "agency": Joi.string().required('Please add Agency Code'),
    "loaderCode": Joi.any().required("Please add Code"),
    "designation": Joi.string().allow(''),
    "mobileNo": Joi.any().required(),
    "weeklyOff": Joi.string().allow(''),
    "shift": Joi.string().allow(''),
    "name": Joi.string().required(),
    "job": Joi.string().allow(''),
    "present/absent": Joi.any().allow(''),
    "branch": Joi.any().allow(''),
    "education": Joi.any().allow(''),
    "deliveryArea": Joi.any().allow(''),
    "residence": Joi.any().allow(''),
    "department": Joi.any().allow(''),
    "serialNo": Joi.any().allow(''),
    "zone": Joi.string().allow('')
}));

const LoadersListAndUpload = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const pageStatus = "loadersList";

    const breadCrumbItems = [{
        title: t("Loaders"),
        link: Routes.Loader.List.path
    }, {
        title: t("List"),
        link: Routes.Loader.List.path
    }];
    const [activateMode, setActivateMode] = useState("disable");
    const [showUploadErrorModal, setShowUploadErrorModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showLoaderUpdateModal, setShowLoaderUpdateModal] = useState(false);
    const zone = useSelector((state) => state?.user?.warehouse?.zone || "");
    const loadersList = useSelector((state) => state?.loaders?.loadersList || []);
    const [uploadErrors, setUploadErrors] = useState({});
    const storeCode = useSelector((state) => state?.user?.warehouse?.warehouseCode || "");
    const warehouse = useSelector((state) => state?.user?.warehouse || {});
    const loadersSelected = loadersList?.filter(loader => loader?.isSelected === true);

    useEffect(() => {
        if (loadersList?.length === 0 && storeCode) {
            handleGetLoadersForStore();
        }
    }, [storeCode]);

    const handleGetLoadersForStore = async () => {
        try {
            dispatch(updateSpinnerState(true));
            const loadersList = await getLoadersListForStore({
                zone: warehouse?.zone,
                status: "active",
                getDeleted: true
            });
            dispatch(setLoadersListInStore(loadersList));
            dispatch(updateToastInfo({
                show: true,
                type: "success",
                title: "Loaders List fetched",
                message: "Loaders List has been refetched from the server"
            }))
            dispatch(updateSpinnerState(false));
        } catch (e) {
            dispatch(updateSpinnerState(false));
        }
    }
    const onRefreshClick = () => {
        handleGetLoadersForStore();
    };
    const handleFileUpload = async (fileContents) => {
        const {
            rows
        } = fileContents;
        const headerRow = rows[0];
        const loaderData = rows.slice(1, rows?.length);
        const filteredData = loaderData?.filter((row) => row?.length > 0);
        const loaderDataInJSON = convertExcelDataToJSONForLoaders(headerRow, filteredData, zone);
        const { error: formErrors = {}, value: formValue } = loadersListValidationSchema.validate(loaderDataInJSON, {
            abortEarly: false
        });
        const {
            details = {}
        } = formErrors;
        if (details?.length > 0) {
            const validationErrorMessages = returnValidationErrorMessages(details);
            setUploadErrors(validationErrorMessages);
            setShowUploadErrorModal(true);
        } else {
            dispatch(updateSpinnerState(true));
            try {
                await uploadLoadersDataToServer({
                    data: loaderDataInJSON,
                    storeCode
                });
                dispatch(updateSpinnerState(false));
                dispatch(updateToastInfo({
                    show: true,
                    type: "success",
                    title: "Loaders Updated",
                    message: "We have updated the unique loaders that were found in the sheet"
                }))
                // now after the list is updated, make a fetch call
                handleGetLoadersForStore();
            } catch (e) {
                dispatch(updateSpinnerState(false));
                dispatch(updateToastInfo({
                    show: true,
                    type: "danger",
                    title: "Loaders Update Failed",
                    message: "Please contact support for help"
                }))
            }
        }
    }
    const onRowSelect = (selected, objectId) => {
        dispatch(markLoaderAsSelected(objectId, selected));
    }
    const handleShowDeleteModal = () => {
        setShowDeleteModal(true);
    }
    const handleOnConfirmClick = async (data) => {
        try {
            dispatch(updateSpinnerState(true));
            await updateLoadersInfo({ data });
            handleGetLoadersForStore();
            dispatch(updateSpinnerState(false));
            dispatch(updateToastInfo({
                show: true,
                type: "success",
                title: "Loaders Delete Successful",
                message: ""
            }));
            setShowDeleteModal(false);
            setShowLoaderUpdateModal(false);
        } catch (e) {
            dispatch(updateSpinnerState(false));
            dispatch(updateToastInfo({
                show: true,
                type: "danger",
                title: "Loaders Update Failed",
                message: "Please contact support for help"
            }))
        }
    };

    const handleShowLoaderUpdateModal = () => {
        setShowLoaderUpdateModal(true);
    };

    const handleSelectedRowsUpdate = (actionId) => {
        if (loadersSelected?.length < 1) {
            dispatch(updateToastInfo({
                show: true,
                type: "danger",
                title: t("Loader Not Selected"),
                message: t("Please select a loader from the list before applying operation")
            }))
            return;
        }
        if (loadersSelected?.length > 0) {
            if (loadersSelected?.length > 1 && actionId === "edit") {
                dispatch(updateToastInfo({
                    show: true,
                    type: "danger",
                    title: t("Max 1 Loader to Select"),
                    message: t("Please select maximum of 1 loader for editing")
                }))
                return;
            }
            // we can do edit and delete here
            switch (actionId) {
                case "delete": {
                    setActivateMode("disable");
                    handleShowDeleteModal();
                    break;
                }
                case "enable": {
                    setActivateMode("enable")
                    handleShowDeleteModal();
                    break;
                }
                case "edit": {
                    handleShowLoaderUpdateModal();
                    break;
                }
                default: {
                    break;
                }
            }
        }
    }
    const handleLoaderUpdateInfo = (loaderObject) => {
        const loadersData = [{
            objectId: loaderObject.objectId,
            dataFields: {
                mobileNo: loaderObject.mobileNo,
                agency: loaderObject.agency,
                name: loaderObject.name,
                storeCode: loaderObject.storeCode,
                actionLog: {
                    action: "updated",
                    user: Parse.User.current().getUsername(),
                    time: new Date().getTime()
                }
            }
        }];
        handleOnConfirmClick(loadersData);
    }
    const handleModalOnConfirmClick = () => {
        const loadersData = [];
        // eslint-disable-next-line no-unused-expressions
        loadersSelected?.forEach(loader => {
            const data = {};
            data.objectId = loader.objectId;
            data.dataFields = {
                status: activateMode === "disable" ? "deleted" : "active",
                actionLog: {
                    action: "deleted",
                    user: Parse.User.current().getUsername(),
                    time: new Date().getTime()
                }
            };
            loadersData.push(data);
        });
        handleOnConfirmClick(loadersData);
    }
    return (
        <>
            <ParcelStatusPage
                onFileUpload={handleFileUpload}
                showFileUploadSection
                sampleFileUrl={loaderMasterSampleFile}
                pageType={pageStatus}
                showDateFilters={false}
                tableData={loadersList}
                tableColumns={returnLoadersListTableColumns(onRowSelect)}
                handleRefresh={onRefreshClick}
                pageTitle={t("Vehicle Loaders")}
                breadCrumbItems={breadCrumbItems}
                pageSubTitle={t("All the vendors who are associated with us and provide us with vehicles")}
                searchPlaceHolder={t("Search By Any Value from Table")}
                enableBulkActions={loadersSelected?.length > 0}
                bulkActionOptions={[{
                    label: "Edit",
                    value: "edit"
                }, {
                    label: "Delete / Remove",
                    value: "delete"
                }, {
                    label: "Enable",
                    value: "enable"
                }]}
                handleItemBulkUpdate={handleSelectedRowsUpdate}
            />
            <UploadDisplayMissingBranchInfoModal
                show={showUploadErrorModal}
                handleClose={() => {
                    setShowUploadErrorModal(false);
                }}
                buttonText={"Acknowledge"}
                mainHeaderText={"Missing Data in Uploaded File"}
                missingBranchNames={Object.values(uploadErrors)}
            />
            <ConfirmationModal
                showModal={showDeleteModal}
                onConfirm={handleModalOnConfirmClick}
                closeModal={() => {
                    setShowDeleteModal(false);
                }}
                modalBody={t(`Are you sure you want to ${activateMode} these loaders`)}
                modalTitle={t(`${activateMode?.toUpperCase()} Loaders`)}
            />
            <LoaderUpdateModal
                closeModal={() => {
                    setShowLoaderUpdateModal(false);
                }}
                loader={loadersSelected?.[0] || {}}
                showModal={showLoaderUpdateModal}
                onConfirm={handleLoaderUpdateInfo}
            />
        </>
    );
}
export default LoadersListAndUpload;