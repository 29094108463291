import React from "react";
import { Button, Modal } from "@themesberg/react-bootstrap";
import { generateRandomId } from "../../../../utils/string";
import { useTranslation } from "react-i18next";
import FormComponent from "../FormComponent";
import { updateSpinnerState } from "../../../../actions/spinner";
import { useDispatch, useSelector } from "react-redux";
import { updateToastInfo } from "../../../../actions/settings";
import { addDebitnote } from "../../../../parse-functions/sms/addDebitnote";

export const AddDebitnoteModal = (props) => {
  const { showModal, closeModal, schemeId, type, setNoteAdded } = props;
  const userName = useSelector((state) => state.user.user.username || {});

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleClick = async (data, callback) => {
    dispatch(updateSpinnerState(true));
    try {
      const allData = {
        ...data,
        userName,
        schemeId,
      };
      await addDebitnote(allData);
      setNoteAdded((prev) => !prev);
      closeModal();
      dispatch(
        updateToastInfo({
          show: true,
          type: "success",
          title: t("Note added"),
          message: t("Note added successfully"),
        })
      );
      callback(null);
    } catch (e) {
      console.log(e.message);
      dispatch(
        updateToastInfo({
          show: true,
          type: "danger",
          title: t("Add Note Failed"),
          message: t(`Failed to add note`),
        })
      );
      callback(e.message);
    }
    dispatch(updateSpinnerState(false));
  };

  const schema = [
    [
      {
        title: "Type",
        type: "select",
        id: "type",
        options:
          type === "debit"
            ? [
                {
                  label: "debit note",
                  value: "Debit note",
                },
              ]
            : [
                {
                  label: "credit note",
                  value: "Credit note",
                },
              ],
      },
      {
        title: "Date of Issue",
        type: "date",
        id: "date",
        required: true,
      },
      {
        title: "Amount",
        type: "number",
        id: "amount",
        required: true,
      },
      {
        title: "Note No.",
        type: "number",
        id: "noteNo",
        required: true,
      },
      {
        title: "Remarks",
        type: "textarea",
        id: "remarks",
        required: true,
      },
      {
        title: "Submit",
        type: "button",
      },
    ],
  ];

  return (
    <>
      <Modal
        id={generateRandomId()}
        as={Modal.Dialog}
        centered
        show={showModal}
        onHide={closeModal}
        size="lg"
      >
        <Modal.Header>
          <Modal.Title className="h6">
            {type === "credit"
              ? t(`Add Supplier Credit note`)
              : t(`Add Debit note`)}
          </Modal.Title>
          <Button variant="close" aria-label="Close" onClick={closeModal} />
        </Modal.Header>
        <Modal.Body>
          <FormComponent schema={schema} savefunction={handleClick} />
        </Modal.Body>
      </Modal>
    </>
  );
};
