import React, { useEffect, useState } from 'react';
import ParcelStatusPage from '../../../components/common/parcelStatusPages';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Routes } from '../../../router/routes';
import { storesListTableColumns } from './utils';
import { updateToastInfo } from '../../../actions/settings';
import EditStoreModal from './components/EditStoreModal';
import { updateSpinnerState } from '../../../actions/spinner';
import { apiCall } from '../../../middlewares/api';
import { useFetchStores } from '../custom-hooks/useFetchStores';
import { setStoresInStore } from '../../../actions/oms/stores';

const StoresList = () => {
  const pageType = 'storesList';
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const storeList = useSelector((state) => state?.stores?.storeList || []);
  const [allWarehouses, setAllWarehouses] = useState(storeList);
  const [selectedWarehouseId, setSelectedWarehouseId] = useState({});
  const [selectedWarehouseData, setSelectedWarehouseData] = useState({});
  const [showEditStoreModal, setShowEditStoreModal] = useState(false);
  const zone = useSelector((state) => state?.user?.preferences?.zone) || '';
  const roles = useSelector((state) => state?.user?.preferences?.roles || []);

  useEffect(() => {
    const fetchData = async () => {
      // const data = await fetchWarehousesInZone("");
      try {
        dispatch(updateSpinnerState(true));
        const data = await apiCall(
          'get',
          `internal/store/all?region=${zone}`,
          {}
        );
        setAllWarehouses(data.length ? data : []);
        dispatch(setStoresInStore(data));
        dispatch(updateSpinnerState(false));
      } catch (error) {
        dispatch(updateSpinnerState(false));
        dispatch(
          updateToastInfo({
            show: true,
            type: 'danger',
            title: t('failed to fetch'),
            message: t(`${error.message}`),
          })
        );
      }
    };
    // console.log(allWarehouses);
    if (storeList?.length === 0) {
      fetchData();
    }
  }, [roles, zone]);

  const breadCrumbItems = [
    {
      title: t('stores'),
      link: Routes.Warehouse.Add.path,
    },
    {
      title: t('list'),
      link: Routes.OMS.Store.Stores.path,
    },
  ];

  const onRowSelect = (isSelected, objectId) => {
    setAllWarehouses((prev) => {
      return prev.map((warehouse) => {
        if (warehouse.objectId === objectId) {
          return { ...warehouse, isSelected };
        }
        return warehouse;
      });
    });

    setSelectedWarehouseId((prev) => {
      if (!isSelected) {
        const { [objectId]: _, ...newState } = prev;
        return newState;
      }

      return {
        ...prev,
        [objectId]: isSelected,
      };
    });
  };

  const handleItemBulkUpdate = (actionId) => {
    if (Object.keys(selectedWarehouseId).length === 1) {
      const found = allWarehouses.find(
        (warehouse) =>
          warehouse.objectId === Object.keys(selectedWarehouseId)[0]
      );
      setSelectedWarehouseData(found);
      switch (actionId) {
        case 'edit': {
          setShowEditStoreModal(true);
          break;
        }
        default: {
          dispatch(
            updateToastInfo({
              show: true,
              type: 'danger',
              title: t('Select an Action'),
              message: t('Please select an action from the dropdown'),
            })
          );
          break;
        }
      }
    } else {
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('Only 1 item allowed'),
          message: t('Please select only one item'),
        })
      );
    }
  };

  const handleshowEditStoreModall = () => {
    setShowEditStoreModal(false);
  };

  const handleRefresh = () => {
    dispatch(updateSpinnerState(true));
    const fetchData = async () => {
      const data = await apiCall(
        'get',
        `internal/store/all?region=${zone}`,
        {}
      );
      setAllWarehouses(data.length ? data : []);
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'success',
          title: t('wareshouses refreshed successfully'),
          message: t('warehouses updated successfully'),
        })
      );
    };
    fetchData();
  };

  const handleConfirmClick = async (data) => {
    handleshowEditStoreModall();
    setSelectedWarehouseId({});
    try {
      const { latitude, longitude, onBoardedOn, ...rest } = data;
      const allData = {
        ...rest,
        latitude: latitude ? parseInt(latitude) : parseInt(''),
        longitude: longitude ? parseInt(longitude) : parseInt(''),
        onBoardedOn: onBoardedOn[0],
      };
      const id = Object.keys(selectedWarehouseId)[0];
      const { savedWarehouse } = await apiCall(
        'put',
        `internal/store/${id}`,
        allData
      );
      if (savedWarehouse?.objectId) {
        dispatch(updateSpinnerState(true));
        const data = await apiCall('get', 'internal/store/all', {});
        setAllWarehouses(data.length ? data : []);
        dispatch(updateSpinnerState(false));
        dispatch(
          updateToastInfo({
            show: true,
            type: 'success',
            title: t('Warehouse Edited'),
            message: t(`warehouse edited successfully`),
          })
        );
      }
      // callback(null);
    } catch (e) {
      console.log(e.message);
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('Warehouse Edit Failed'),
          message: t(`${e.message}`),
        })
      );
      // callback(e.message);
    }
  };

  return (
    <>
      <ParcelStatusPage
        pageType={pageType}
        tableData={allWarehouses}
        tableColumns={storesListTableColumns(onRowSelect)}
        pageTitle={t('Stores List')}
        breadCrumbItems={breadCrumbItems}
        handleRefresh={handleRefresh}
        pageSubTitle={t('All the stores Information that are available.')}
        handleItemBulkUpdate={handleItemBulkUpdate}
        bulkActionOptions={[{ label: 'Edit', value: 'edit' }]}
        enableBulkActions={
          Object.keys(selectedWarehouseId)?.length > 0 &&
          Object.keys(allWarehouses)?.length > 0
        }
        showDateFilters={false}
      />

      <EditStoreModal
        showModal={showEditStoreModal}
        closeModal={handleshowEditStoreModall}
        handleConfirmClick={handleConfirmClick}
        selectedWarehouseId={selectedWarehouseId}
        selectedWarehouseData={selectedWarehouseData}
      />
    </>
  );
};

export default StoresList;
