import { faInbox } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Toast } from "@themesberg/react-bootstrap";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateToastInfo } from "../../actions/settings";

const ToastMessage = (props) => {
    const toastInfo = useSelector((state) => state.settings.toast || {});
    const [showToast, setShowToast] = useState(false);
    const [toastData, setToastData] = useState({});
    useEffect(() => {
        setShowToast(toastInfo.show || false);
        setToastData(toastInfo);
    }, [toastInfo]);
    const dispatch = useDispatch()
    const [isVisible, setIsVisible] = useState(showToast);
    useEffect(() => {
        setIsVisible(showToast)
    }, [showToast]);

    const handleClose = () => {
        setIsVisible(false);
        dispatch(updateToastInfo({
            show: false,
            type: null,
            title: '',
            message: ''
        }));
    }
    return (
        <Toast style={{
            position: "fixed",
            right: 10,
            top: 10,
            zIndex: 10500
        }}
        autohide
        delay={10000}
        show={isVisible}
        onClose={handleClose}
        className={`bg-${toastInfo.type} text-white my-3`}>
            <Toast.Header className="text-primary" closeButton={false}>
                <FontAwesomeIcon icon={faInbox} />
                <strong className="me-auto ms-2">{toastInfo.title}</strong>
                <small>{"now"}</small>
                <Button variant="close" size="xs" onClick={handleClose} />
            </Toast.Header>
            <Toast.Body>
                {toastInfo.message}
            </Toast.Body>
        </Toast>
    )
}
export default ToastMessage