import React from "react";
import WarehouseTargetPage from "../purchase/assigned";
import { Routes } from "../../../router/routes";
import { useTranslation } from "react-i18next";

const PendingTargets = () => {
  const { t } = useTranslation();
  const status = "pending";
  const breadCrumbItems = [
    {
      title: t("WMS"),
      link: Routes.WMS.Target.Pending,
    },
    {
      title: t("Targets"),
      link: Routes.WMS.Target.Pending,
    },
    {
      title: t(status),
    },
  ];
  return (
    <WarehouseTargetPage
      pageName={"pendingTargets"}
      status={status}
      bulkActionOptions={[
        {
          label: "Assign",
          value: "reassign",
        },
      ]}
      pageTitle={"Pending Targets"}
      pageSubTitle={"All the Scan target Pending Items are listed here."}
      breadCrumbItems={breadCrumbItems}
    />
  );
};
export default PendingTargets;
