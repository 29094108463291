import React from "react";
import FormComponent from "../FormComponent";
import { useDispatch } from "react-redux";
import {
  addNewAccountsUser,
} from "../../../../parse-functions/sms/addUsers";
import { updateToastInfo } from "../../../../actions/settings";
import { useTranslation } from "react-i18next";
import { updateSpinnerState } from "../../../../actions/spinner";
import { accountsUserSchema } from "../../schema/accountsUser";
import { editAccountsUserSchema } from "../../schema/userEdit";

const AddAccountsUser = ({ editData, handleSave }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const schema = editData ? editAccountsUserSchema : accountsUserSchema;

  

  const saveAccountsUser = async (formData, callback) => {
    try {
      dispatch(updateSpinnerState(true));
      if (editData) {
        handleSave(formData);
      } else {
        await addNewAccountsUser({ ...formData, applications: ["sms"] });
      }
      dispatch(
        updateToastInfo({
          show: true,
          type: "success",
          title: editData ? t("User Edited") : t("User Added"),
          message: editData
            ? t(`${formData.name} updated successfully`)
            : t(`${formData.userName} added successfully`),
        })
      );
      callback(null);
    } catch (error) {
      const { message } = error;
      dispatch(
        updateToastInfo({
          show: true,
          type: "danger",
          title: editData ? t("User Edit failed") : t("User Add Failed"),
          message: editData
            ? t(`${formData.name} failed to edit`)
            : t(`${formData.userName} failed to add`),
        })
      );
      callback(message);
    }
    dispatch(updateSpinnerState(false));
  };

  return (
    <div>
      <div>
        <FormComponent
          schema={schema}
          savefunction={saveAccountsUser}
          editData={editData}
        />
      </div>
    </div>
  );
};

export default AddAccountsUser;
