import { Button, Modal } from "@themesberg/react-bootstrap";
import React from "react";
import { useTranslation } from "react-i18next";
import { generateRandomId } from "../../utils/string";
import LoaderUpdateForm from "./LoaderUpdateForm";

const LoaderUpdateModal = (props) => {
    const {t} = useTranslation();
    const {
        showModal,
        closeModal,
        onConfirm,
        loader
    } = props;
    return (
        <Modal id={generateRandomId()} size="lg" show={showModal} onHide={closeModal}>
            <Modal.Header>
                <Modal.Title className="h6">{t(`Update ${loader?.name || "loader details"}`)}</Modal.Title>
                <Button variant="close" aria-label="Close" onClick={closeModal} />
            </Modal.Header>
            <Modal.Body>
                <LoaderUpdateForm closeModal={closeModal} loader={loader} onConfirm={onConfirm} />
            </Modal.Body>
        </Modal>
    )
}
export default LoaderUpdateModal;