import { Button, Modal } from '@themesberg/react-bootstrap';
import React from 'react';
import { generateRandomId } from '../../../../utils/string';
import TableOnly from '../../../scheme-management/Components/IndividualScheme.js/TableOnly';
import { useTranslation } from 'react-i18next';

const SummaryModal = ({
  showModal,
  closeModal,
  modalTitile,
  data = [],
  columns,
  summaryTitle = '',
  summaryMessage = '',
  handleSubmit,
  isFooterRequired = true,
  size = 'xl',
}) => {
  // console.log(data);

  const { t } = useTranslation();
  return (
    <Modal
      id={generateRandomId()}
      as={Modal.Dialog}
      centered
      show={showModal}
      onHide={closeModal}
      size={size}
    >
      <Modal.Header>
        <Modal.Title className="h6">{t(modalTitile)}</Modal.Title>
        <Button variant="close" aria-label="Close" onClick={closeModal} />
      </Modal.Header>
      <Modal.Body>
        {/* <div className="d-flex flex-wrap h-50">
          <ul>
            {data.length > 0 &&
              data?.map((item, index) => <li key={index}>{item}</li>)}
          </ul>
        </div> */}
        <TableOnly
          id="SummaryModal"
          data={data}
          columns={columns}
          isFooterRequired={isFooterRequired}
        />

        {(summaryTitle || summaryMessage) && (
          <div className="bg-light rounded-0  my-2 p-3">
            {summaryTitle && <p className="fw-medium fs-6">{summaryTitle}</p>}
            {summaryMessage && (
              <text className="fs-6 text-wrap">{summaryMessage}</text>
            )}
          </div>
        )}
      </Modal.Body>
      <Modal.Footer className="d-flex gap-5 justify-content-end">
        <Button
          variant="link"
          className="text-gray ms-auto"
          onClick={closeModal}
        >
          {t('Cancel')}
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          {t('Submit')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SummaryModal;
