import {
    SET_SELECTED_PARCEL_STATE_WHILE_RECEIVING,
    SET_TEMPOSHEET_RECEIVE_PARCELS_IN_STORE,
    SET_CURRENT_RECEIVE_TEMPOSHEET_PARCELS_AS_RECEIVED
} from "../actions/types";
import { convertArrayToObj } from "../utils/json";

const INITIAL_STATE = {
    receiveList: []
};
const temposheetReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_TEMPOSHEET_RECEIVE_PARCELS_IN_STORE: {
            return {
                ...state,
                receiveList: action.data
            }
        }
        case SET_SELECTED_PARCEL_STATE_WHILE_RECEIVING: {
            const {
                value,
                objectId
            } = action.data;
            const allParcels = JSON.parse(JSON.stringify(state.receiveList || []));
            // eslint-disable-next-line no-unused-expressions
            allParcels?.forEach(parcel => {
                if (parcel.objectId === objectId || objectId === "selectAll") {
                    parcel.isSelected = value
                }
            });
            return {
                ...state,
                receiveList: allParcels
            }
        }
        case SET_CURRENT_RECEIVE_TEMPOSHEET_PARCELS_AS_RECEIVED: {
            const updatedParcelsList = action.data;
            const allParcels = JSON.parse(JSON.stringify(state.receiveList || []));
            const updatedParcelMap = convertArrayToObj(updatedParcelsList, "objectId");
            const allParcelsMap = convertArrayToObj(allParcels, "objectId");
            const finalMap = {
                ...allParcelsMap,
                ...updatedParcelMap
            };
            return {
                ...state,
                receiveList: Object.values(finalMap)
            }
        }
        default: return state;
    }
};

export default temposheetReducer;