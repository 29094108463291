import { Button, Col, Form, Modal, Row } from "@themesberg/react-bootstrap";
import Joi from "joi";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { generateRandomId } from "../../utils/string";
import DropdownComponent from "../../components/common/Dropdown";
import { updateSpinnerState } from "../../actions/spinner";
import { getTripInfoByTemposheetNumber, updateTemposheetWithNewLoaderInfo } from "../../parse-functions/trips";
import { updateToastInfo } from "../../actions/settings";
import { maxLoaders } from "../../constants/dispatch";
import { returnDropdownOptionsForLoadersList } from "../../utils/loaders";
import { returnValidationErrorMessages } from "../../utils/validation";

const loaderUpdateSchema = Joi.object({
    loaderName1: Joi.string().required(),
    loaderName2: Joi.string().required(),
    loaderName3: Joi.string().allow(''),
    loaderName4: Joi.string().allow(''),
    loaderName5: Joi.string().allow(''),
    loaderNumber1: Joi.string().required(),
    loaderNumber2: Joi.string().required(),
    loaderNumber3: Joi.string().allow(''),
    loaderNumber4: Joi.string().allow(''),
    loaderNumber5: Joi.string().allow(''),
})
const ChangeLoaderInfoModal = (props) => {
    const {
        showModal,
        closeModal
    } = props;
    const dispatch = useDispatch();
    const loadersList = useSelector((state) => state?.loaders?.loadersList || []);
    const [temposheetNumber, setTemposheetNumber] = useState("");
    const [isTemposheetNumberValid, setIsTemposheetNumberValid] = useState(true);
    const [temposheetNumberError, setTemposheetNumberError] = useState("Please enter a valid temposheet number");
    const [tripData, setTripData] = useState({});
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [showFormFields, setShowFormFields] = useState(false);
    const [loadersListOptions, setLoadersListOptions] = useState([]);
    const [formDataErrors, setFormDataErrors] = useState({});

    const { t } = useTranslation();

    const [formData, setFormData] = useState({
        loaderName1: '',
        loaderName2: '',
        loaderName3: '',
        loaderName4: '',
        loaderName5: '',
        loaderNumber1: '',
        loaderNumber2: '',
        loaderNumber3: '',
        loaderNumber4: '',
        loaderNumber5: ''
    });

    useEffect(() => {
        if (loadersList?.length > 0) {
            setLoadersListOptions(returnDropdownOptionsForLoadersList(loadersList));
        }
    }, [loadersList]);

    const handleConfirmClick = () => {
        const { error: formErrors = {}, value: formValue } = loaderUpdateSchema.validate(formData, {
            abortEarly: false
        });
        const {
            details = {}
        } = formErrors;
        if (details?.length > 0) {
            const validationErrorMessages = returnValidationErrorMessages(details);
            setFormDataErrors(validationErrorMessages);
        } else {
            setFormDataErrors({});
            updateTripInfoWithNewLoaders(formData);
        }
    }
    const updateTripInfoWithNewLoaders = async (data) => {
        try {
            setDisableSubmit(true);
            dispatch(updateSpinnerState(true));
            await updateTemposheetWithNewLoaderInfo({
                data,
                temposheetNumber
            });
            dispatch(updateSpinnerState(false));
            dispatch(updateToastInfo({
                show: true,
                type: "success",
                message: "Temposheet Loaders Updated",
                title: "Trip and its parcel updated with loaders info"
            }))
        } catch(e) {
            dispatch(updateSpinnerState(false));
        }
    };
    const handleGetTemposheetInformation = async () => {
        try {
            dispatch(updateSpinnerState(true));
            const data = await getTripInfoByTemposheetNumber(temposheetNumber, false);
            const {
                tripInfo
            } = data;
            if (tripInfo?.status === "pending") {
                setTripData(tripInfo);
                setShowFormFields(true);
            } else if (tripInfo?.status !== "pending") {
                dispatch(updateToastInfo({
                    show: true,
                    type: "danger",
                    message: "Incorrect Trip State",
                    title: "Loader update is allowed for dispatched trips only"
                }))
            } else {
                dispatch(updateToastInfo({
                    show: true,
                    type: "danger",
                    message: "User has not enabled the option to change loaders for this trip",
                    title: "Not allowed to change Loader for this trip"
                }))
            }
            dispatch(updateSpinnerState(false));
        } catch (e) {
            dispatch(updateSpinnerState(false));
            dispatch(updateToastInfo({
                show: true,
                type: "danger",
                message: "Please contact support if this issue persists",
                title: e?.error || "Unable to get TS info"
            }))
        }
    };
    const onFormInputChange = (fieldName, value) => {
        setFormData(oldFormData => ({
            ...oldFormData,
            [fieldName]: value
        }));
    };
    const returnLoaderFields = () => {
        const loaderInfoFields = [];
        for (let i = 0; i < maxLoaders; i += 1) {
            loaderInfoFields.push(
                <Row className="my-2">
                    <DropdownComponent
                        onChange={((option) => {
                            onFormInputChange(`loaderName${i + 1}`, option?.value?.name);
                            onFormInputChange(`loaderNumber${i + 1}`, option?.value?.mobileNo?.toString());
                        })}
                        options={loadersListOptions}
                        placeholder={`Select Loader ${i + 1}`}
                    />
                </Row>
            )
        }
        return loaderInfoFields;
    }
    return (
        <Modal id={generateRandomId()} as={Modal.Dialog} size="lg" centered show={showModal} onHide={closeModal}>
            <Modal.Header>
                <Modal.Title className="h6">{t("Change Loader Info")}</Modal.Title>
                <Button variant="close" aria-label="Close" onClick={closeModal} />
            </Modal.Header>
            <Modal.Body>
                <Form.Group className="mb-4">
                    <Row>
                        <Col>
                            <Form.Control
                                disabled={showFormFields}
                                required
                                type="text"
                                placeholder="Enter Temposheet Number"
                                onChange={(event) => {
                                    setTemposheetNumber(event?.target?.value);
                                }}
                            />
                        </Col>
                        <Col>
                            <Button disabled={showFormFields || disableSubmit} variant="primary" onClick={handleGetTemposheetInformation}>Get Temposheet Info</Button>
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group className="mb-4">
                    {showFormFields && (
                        <>
                            <Form.Label>{t("Select New Loaders")}</Form.Label>
                            {returnLoaderFields()}
                        </>
                    )}
                    {Object.keys(formDataErrors)?.length > 0 && (
                        <>
                            {Object.values(formDataErrors)?.map(item => {
                                return (
                                    <div
                                        style={{
                                            fontSize: 12,
                                            color: "red"
                                        }}
                                        type="invalid">
                                            {t(item)}
                                    </div>
                                )
                            })}
                        </>

                    )}
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    disabled={!showFormFields}
                    variant="secondary"
                    onClick={handleConfirmClick}
                    >
                    {t("Update Info")}
                </Button>
                <Button variant="link" className="text-gray ms-auto" onClick={closeModal}>
                    {t("Cancel")}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
export default ChangeLoaderInfoModal;