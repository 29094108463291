import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { markParcelAsDisabled, setParcelsForStagesInStore } from "../../actions/parcels";
import { updateToastInfo } from "../../actions/settings";
import { updateSpinnerState } from "../../actions/spinner";
import ParcelStatusPage from "../../components/common/parcelStatusPages";
import RescheduleModal from "../../components/common/RescheduleModal";
import { createReturnOKRescheduledParcel, getParcelsByStatusAndSubStatus } from "../../parse-functions/parcels";
import { Routes } from "../../router/routes";
import { convertArrayToObj } from "../../utils/json";
import { returnOKTableColumns } from "./returnsUtils";
import { setFiltersInStore } from "../../actions/filters";
import { returnEndOfDay, returnStartOfDay } from "../../utils/datetime";

const ReturnOK = () => {
    const pageStatus = "returnok";
    const { t } = useTranslation();

    const breadCrumbItems = [{
        title: t("Returns"),
        link: Routes.Returns.ReturnOK.path
    }, {
        title: t("Return OK"),
        link: Routes.Returns.ReturnOK.path
    }];
    const dispatch = useDispatch();

    const [selectedParcelId, setSelectedParcelId] = useState(null);
    const storeCode = useSelector((state) => state?.user?.warehouse?.warehouseCode || "");
    const parcels = useSelector((state) => state?.parcels?.[pageStatus] || {});
    const filtersValue = useSelector((state) => state.filters?.[pageStatus]);
    const [showRescheduleModal, setShowRescheduleModal] = useState(false);
    const zone = useSelector((state) => state?.user?.warehouse?.zone || "");

    const handleFiltersSubmit = (startDate, endDate) => {
        dispatch(setFiltersInStore({
            pageType: pageStatus,
            data: {
                startDate: new Date(startDate),
                endDate: new Date(endDate)
            }
        }))
        getAvailableParcels(startDate, endDate);
    };

    const getAvailableParcels = async (startDate, endDate) => {
        dispatch(updateSpinnerState(true));
        try {
            const filters = {
                subStatus: [pageStatus],
                storeCode,
                isRescheduled: "false",
                startDate: new Date(startDate),
                endDate: new Date(endDate),
                zone,
            };
            const parcels = await getParcelsByStatusAndSubStatus(filters);
            dispatch(setParcelsForStagesInStore(pageStatus, convertArrayToObj(JSON.parse(JSON.stringify(parcels)), "objectId")))
            dispatch(updateSpinnerState(false));
            dispatch(updateToastInfo({
                show: true,
                type: 'success',
                title: t('Orders fetched'),
                message: t("You can reschedule these orders for future delivery")
            }))

        } catch (e) {
            dispatch(updateSpinnerState(false));
            dispatch(updateToastInfo({
                show: true,
                type: 'danger',
                title: t('Error'),
                message: t(e.message)
            }))
        }
    }
    const onRowSelect = (value, objectId) => {
        if (selectedParcelId) {
            dispatch(updateToastInfo({
                show: true,
                type: 'danger',
                title: t('Only 1 item allowed'),
                message: t("Please deselect other item before choosing this")
            }))
        } else {
            if (value) {
                setSelectedParcelId(objectId);
            } else {
                setSelectedParcelId(null);
            }
        }
    }
    const onRefreshClick = () => {
        const currentStartDate = filtersValue.startDate ? new Date(filtersValue.startDate) : returnStartOfDay(new Date());
        const currentEndDate = filtersValue.endDate ? new Date(filtersValue.endDate) : returnEndOfDay(new Date());
        getAvailableParcels(currentStartDate, currentEndDate)
    }
    const handleShowRescheduleModal = () => {
        setShowRescheduleModal(false);
    }
    const onRescheduleConfirm = async (data) => {
        dispatch(updateSpinnerState(true));
        try {
            // check here for more info
            await createReturnOKRescheduledParcel(data);
            dispatch(markParcelAsDisabled(pageStatus, selectedParcelId));
            if (data?.extraParcelIds && Object.keys(data?.extraParcelIds)?.length > 0) {
                // eslint-disable-next-line no-unused-expressions
                Object.keys(data?.extraParcelIds)?.forEach(extraId => {
                    if (data?.extraParcelIds?.[extraId]) {
                        dispatch(markParcelAsDisabled(pageStatus, extraId));
                    }
                });
            }

            setShowRescheduleModal(false);
            dispatch(updateToastInfo({
                show: true,
                type: 'success',
                title: t('Parcel Rescheduled'),
                message: t("This parcel has been rescheduled for the chosen date")
            }))
            dispatch(updateSpinnerState(false));

        } catch (e) {
            dispatch(updateSpinnerState(false));
        }
    };
    const handleItemBulkUpdate = (actionId) => {
        if (selectedParcelId) {
            switch (actionId) {
                case "reschedule": {
                    setShowRescheduleModal(true);
                    break;
                }
                default: {
                    dispatch(updateToastInfo({
                        show: true,
                        type: 'danger',
                        title: t('Select an Action'),
                        message: t("Please select an action from the dropdown")
                    }))
                    break;
                }
            }
        } else {
            dispatch(updateToastInfo({
                show: true,
                type: 'danger',
                title: t('Select Item'),
                message: t("Please select an item to take action")
            }))
        }
    }
    return (
        <>
            <ParcelStatusPage
                pageType={pageStatus}
                filtersValue={filtersValue}
                filterSubmit={handleFiltersSubmit}
                tableData={parcels}
                tableColumns={returnOKTableColumns(onRowSelect)}
                handleRefresh={onRefreshClick}
                pageTitle={t("Return OK")}
                pageStatus={"returnok"}
                breadCrumbItems={breadCrumbItems}
                pageSubTitle={t("All the parcels of your warehouse that have been marked as Return OK")}
                searchPlaceHolder={t("Search by Invoice No. in Database")}
                handleItemBulkUpdate={handleItemBulkUpdate}
                bulkActionOptions={[{
                    label: "Reschedule",
                    value: "reschedule"
                }]}
                enableBulkActions={selectedParcelId && Object.keys(parcels)?.length > 0}
            />
            <RescheduleModal
                disableTargetDropdown
                targetDropdownOptions={[]}
                selectedParcelId={selectedParcelId}
                pageStatus={pageStatus}
                showModal={showRescheduleModal}
                closeModal={handleShowRescheduleModal}
                onConfirm={onRescheduleConfirm} />
        </>
    )
}
export default ReturnOK;