/* eslint-disable no-loop-func */

import { Card, Col, Row } from "@themesberg/react-bootstrap";
import React from "react";
import { useTranslation } from "react-i18next";
import EmptyTable from "../../components/common/EmptyTableView";
import Table from "react-bootstrap-table-next";
import Pagination from "react-bootstrap-table2-paginator";
import * as Paginator from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { customSizePerPage, customTotal } from "../../components/common/parcelStatusPages";
import { returnRowBGColor } from "../../utils/parcels";

const TripsTableData = (props) => {
    const { t } = useTranslation();
    const {
        columns,
        data,
        onRowClick
    } = props;
    return (
        <ToolkitProvider
            exportCSV
            keyField="id"
            search={true}
            sort
            columns={columns}
            data={Object.values(data)}
            wrapperClasses="table-responsive"
        >
            {({ baseProps, searchProps, csvProps }) => (
                <Paginator.PaginationProvider pagination={
                    Pagination({
                        custom: true,
                        showTotal: true,
                        alwaysShowAllBtns: true,
                        totalSize: data?.length,
                        paginationTotalRenderer: customTotal,
                        sizePerPageRenderer: customSizePerPage
                    })
                }>
                    {({ paginationProps, paginationTableProps }) => (
                        <>
                            <Card>
                                <div className="table-responsive py-2">
                                    <Card.Header>
                                        <Row className="d-flex justify-content-between">
                                            <Col xs={12} md={4} className="d-flex justify-content-md-end py-1">
                                                <Search.SearchBar {...searchProps} placeholder={t("Search TempoSheet or Vehicle Number here")} />
                                                <Search.ClearSearchButton { ...searchProps } />
                                            </Col>
                                        </Row>
                                    </Card.Header>

                                    <Table
                                        rowEvents={{
                                            onClick: (e, row, index) => {
                                                onRowClick(row?.tripNumber, row?.objectId);
                                            }
                                        }}
                                        rowClasses={(row) => {
                                            const rowBGColor = returnRowBGColor(row?.status);
                                            return `${rowBGColor} border-bottom cursor-hand`;
                                        }}
                                        noDataIndication={<EmptyTable />}
                                        {...baseProps}
                                        {...paginationTableProps}
                                        bodyClasses="border-0"
                                        classes="table-flush dataTable-table"
                                    />
                                    <Card.Footer>
                                        <Row>
                                            <Col xs={12} md={4} className="py-1">
                                                <Paginator.SizePerPageDropdownStandalone {...paginationProps} />
                                            </Col>
                                            <Col xs={12} md={4} className="d-flex align-items-center py-1">
                                                <Paginator.PaginationTotalStandalone {...paginationProps} />
                                            </Col>
                                            <Col xs={12} md={4} className="d-flex justify-content-md-end align-items-center mb-0 py-1">
                                                <Paginator.PaginationListStandalone {...paginationProps} />
                                            </Col>
                                        </Row>
                                    </Card.Footer>
                                </div>
                            </Card>
                        </>
                    )}
                </Paginator.PaginationProvider>
            )}
        </ToolkitProvider>

    )
}
export default TripsTableData