import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';

const CardDropdown = (props) => {
  const { t } = useTranslation();
  const {
    onChange,
    options,
    placeholder = 'Select an Option',
    disabled = false,
    width,
    value,
  } = props;

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: 'none',
      boxShadow: 'none',
      minHeight: 'unset',
      cursor: disabled ? 'not-allowed' : 'pointer',
      width: width,
      backgroundColor: disabled ? '#fff' : '#fff',
      color: disabled ? '#6c757d' : '#495057',
      // minWidth: width,
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '0px 8px',
    }),
    placeholder: (provided) => ({
      ...provided,
      margin: 0,
      color: disabled ? '#6c757d' : provided.color,
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: 0,
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    singleValue: (provided) => ({
      ...provided,
      margin: 0,
      color: disabled ? '#6c757d' : provided.color,
    }),
    menu: (provided) => ({
      ...provided,
      marginTop: 0, // Remove top margin from the dropdown menu
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Add a slight shadow to the dropdown
      borderRadius: '4px', // Rounded corners for the dropdown
      border: '1px solid #FFFFFF', // Border for the dropdown
    }),
    option: (provided, state) => ({
      ...provided,
      padding: '10px 12px', // Add padding to the options to make them look like in the screenshot
      color: state.isSelected ? '#fff' : '#495057',
      backgroundColor: state.isSelected ? '#61DAFB' : '#FFFFFF', // Highlight selected option
      '&:hover': {
        backgroundColor: state.isSelected ? '#61DAFB' : '#f8f9fa', // Change background on hover
      },
      textAlign: 'left', // Align text to the left
      fontWeight: state.isSelected ? 'bold' : 'normal', // Bold for selected option
    }),
  };

  const selectValue =
    value && typeof value === 'string'
      ? options.find(
          (option) => option.value === value || option.label === value
        )
      : value;

  return (
    <div style={{ width: '100%' }}>
      <Select
        isDisabled={disabled}
        onChange={onChange}
        options={options}
        placeholder={t(placeholder)}
        styles={customStyles}
        value={selectValue}
      />
    </div>
  );
};

export default CardDropdown;
