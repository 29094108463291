import React, { useEffect } from 'react';
import BreadCrumbTitle from '../common/BreadCrumbTitle';
import { useDispatch, useSelector } from 'react-redux';
import { updateSpinnerState } from '../../../../actions/spinner';
import { updateToastInfo } from '../../../../actions/settings';
import { getSchemeDashboardData } from '../../../../parse-functions/sms/scheme';
import { setSchemesDataInStore } from '../../../../actions/sms/schemes';
import { Card, Col, Row } from '@themesberg/react-bootstrap';

const SchemeDashboard = () => {
  const schemeCounts = useSelector(
    (state) => state?.schemes?.schemeCounts || {}
  );
  const isAdmin = useSelector(
    (state) => state?.user?.preferences?.isAdmin || false
  );
  const isBrandManager = useSelector(
    (state) => state?.user?.preferences?.isBrandManager || false
  );

  const isBrandVendor = useSelector(
    (state) => state?.user?.preferences?.isBrandVendor || false
  );

  const isAccountManager = useSelector(
    (state) => state?.user?.preferences?.isAccountManager || false
  );
  const dispatch = useDispatch();

  const role = isAdmin
    ? 'isAdmin'
    : isBrandManager
    ? 'isBrandManager'
    : isBrandVendor
    ? 'isBrandVendor'
    : isAccountManager
    ? 'isAccountManager'
    : 'Unknown';

  const fetchSchemeCount = async () => {
    try {
      dispatch(updateSpinnerState(true));
      const schemeData = await getSchemeDashboardData({ role });
      console.log(schemeData);
      dispatch(
        setSchemesDataInStore({ type: 'schemeCounts', data: schemeData })
      );
      dispatch(updateSpinnerState(false));
    } catch (error) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: '',
          message: 'Fetch Failed!',
        })
      );
    }
  };

  useEffect(() => {
    if (role !== 'Unknown' && Object.keys(schemeCounts).length === 0) {
      fetchSchemeCount();
    }
  }, [role]);
  return (
    <div>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <BreadCrumbTitle breadcrumbs={[{ title: 'SMS Dashboard' }]} />
        </div>
      </div>
      <div style={{ width: '50%' }}>
        <Row>
          {Object.keys(schemeCounts).map((status, index) => (
            <React.Fragment key={index}>
              {/* Ensure a new row starts after every two columns */}
              {index % 2 === 0 && index !== 0 && <div className="w-100" />}
              <Col md={6} className="mb-4">
                <Card border="light" className="shadow-sm">
                  <Card.Body>
                    <Card.Title>
                      {status[0].toUpperCase() + status.slice(1)} Schemes
                    </Card.Title>
                    <h3>{schemeCounts[status]}</h3>
                    in this month
                  </Card.Body>
                </Card>
              </Col>
            </React.Fragment>
          ))}
        </Row>
      </div>
    </div>
  );
};

export default SchemeDashboard;
