import React, { useEffect, useState, useCallback } from "react";
import { Button, Card, Modal } from "@themesberg/react-bootstrap";
import { useTranslation } from "react-i18next";
import { generateRandomId } from "../../../../utils/string";
import EmptyTable from "../../../../components/common/EmptyTableView";
import {
  addNewProductsToSchemeColumns,
  generateTableRow,
} from "../../../../utils/sms/BulkAddSchemeColumns";

const DifferenceProductsModal = (props) => {
  const { showModal, closeModal, data, handleSave, setNewProducts } = props;
  const { t } = useTranslation();
  const onRowSelect = useCallback((selected, objectId) => {
    setNewProducts((prev) =>
      prev.map((item) =>
        item.objectId === objectId ? { ...item, isSelected: selected } : item
      )
    );
  }, []);

  const onInputChange = (e, row, column) => {
    const value = e.target?.value || [e.value];
    setNewProducts((prev) =>
      prev.map((item) =>
        item.objectId === row.objectId ? { ...item, [column]: value } : item
      )
    );
  };
  return (
    <div>
      <Modal
        id={generateRandomId()}
        as={Modal.Dialog}
        centered
        show={showModal}
        onHide={closeModal}
        size="xl"
      >
        <Modal.Header>
          <Modal.Title className="h6">{t(`All New Products`)}</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={closeModal} />
        </Modal.Header>
        <Modal.Body>
          <Card>
            <Card.Header>
              {t(`Select Products to add to the current scheme`)}
            </Card.Header>
            <div className="table-responsive py-2">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>{t("Select")}</th>
                    <th>{t("Product Name")}</th>
                    <th>{t("Van No.")}</th>
                    <th>{t("Valuation Type")}</th>
                    <th>{t("Payout")}</th>
                    <th>{t("Payout Basis")}</th>
                  </tr>
                </thead>
                <tbody>
                  {generateTableRow(data, onRowSelect, onInputChange)}
                </tbody>
              </table>
            </div>
          </Card>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleSave}>{t(`Confirm`)}</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DifferenceProductsModal;
