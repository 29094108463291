import {
    REMOVE_SELECTED_TRIP_FROM_TEMPORARY_LIST,
    SET_ACTIVE_TRIPS_IN_STORE,
    SET_SELECTED_TRIP_IN_STORE,
    SET_TEMPORARY_TRIPS_IN_STORE,
    SET_TEMPORARY_TRIP_AS_DISPATCHED,
    SET_CURRENT_ACTIVE_TRIP_ITEMS,
    SET_DATA_FOR_SELECTED_PARCELS_IN_ACTIVE_TRIP,
    UPDATE_CURRENT_TRIP_PARCELS_IN_STORE,
    UPDATE_VEHICLE_NUMBER_FOR_TEMPORARY_TRIP,
    RESET_STORE
} from "../actions/types";

const INITIAL_STATE = {
    temporaryTrips: {},
    selectedTrip: null,
    activeTrips: {}
};
const tripsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_TEMPORARY_TRIPS_IN_STORE: {
            return {
                ...state,
                temporaryTrips: action.data
            }
        }
        case SET_SELECTED_TRIP_IN_STORE: {
            return {
                ...state,
                selectedTrip: action.data
            }
        }
        case SET_TEMPORARY_TRIP_AS_DISPATCHED: {
            const {
                selectedTrip,
                temporaryTrips
            } = state;
            const updatedTrips = JSON.parse(JSON.stringify(temporaryTrips))
            updatedTrips[selectedTrip].status = "pending";
            updatedTrips[selectedTrip].dispatchData = action.data;
            return {
                ...state,
                temporaryTrips: updatedTrips
            }
        }
        case REMOVE_SELECTED_TRIP_FROM_TEMPORARY_LIST: {
            const {
                temporaryTrips,
                selectedTrip
            } = state;
            const updatedTrips = Object.assign({}, temporaryTrips);
            delete updatedTrips[selectedTrip]
            return {
                ...state,
                temporaryTrips: updatedTrips
            }
        }
        case SET_ACTIVE_TRIPS_IN_STORE: {
            return {
                ...state,
                activeTrips: action.data
            }
        }
        case SET_CURRENT_ACTIVE_TRIP_ITEMS: {
            const {
                activeTrips,
                selectedTrip
            } = state;
            const currentActiveTripDetails = Object.assign({}, activeTrips);
            currentActiveTripDetails[selectedTrip].parcels = action.data;
            return {
                ...state,
                activeTrips: currentActiveTripDetails
            }
        }
        case SET_DATA_FOR_SELECTED_PARCELS_IN_ACTIVE_TRIP: {
            const {
                activeTrips,
                selectedTrip
            } = state;
            const currentActiveTripDetails = JSON.parse(JSON.stringify(activeTrips));
            const oldParcels = currentActiveTripDetails[selectedTrip].parcels;
            
            const newParcels = {};
            // eslint-disable-next-line no-unused-expressions
            Object.keys(oldParcels)?.forEach(parcelId => {
                let newparcel = JSON.parse(JSON.stringify(oldParcels[parcelId]));
                if (oldParcels[parcelId].isSelected) {
                    newparcel = {
                        ...newparcel,
                        ...action.data,
                        isSelected: false
                    }
                }
                newParcels[parcelId] = newparcel;
            });
            currentActiveTripDetails[selectedTrip].parcels = newParcels;
            return {
                ...state,
                activeTrips: currentActiveTripDetails
            }
        }
        case UPDATE_CURRENT_TRIP_PARCELS_IN_STORE: {
            const {
                activeTrips,
                selectedTrip
            } = state;
            const currentActiveTripDetails = Object.assign({}, activeTrips);
            const oldParcels = currentActiveTripDetails[selectedTrip].parcels;
            // eslint-disable-next-line no-unused-expressions
            Object.keys(oldParcels)?.forEach((parcelId) => {
                if (action.data[parcelId]) {
                    oldParcels[parcelId] = {
                        ...oldParcels[parcelId],
                        ...action.data[parcelId],
                        returnScannedAt: action.data[parcelId].returnScannedAt,
                        returnScannedBy: action.data[parcelId].returnScannedBy
                    }
                }
            });
            currentActiveTripDetails[selectedTrip].parcels = oldParcels;
            return {
                ...state,
                activeTrips: currentActiveTripDetails
            }
        }
        case UPDATE_VEHICLE_NUMBER_FOR_TEMPORARY_TRIP: {
            const {
                temporaryTrips,
                selectedTrip
            } = state;
            const updatedTrips = Object.assign({}, temporaryTrips);
            updatedTrips[selectedTrip] = {
                ...updatedTrips[selectedTrip],
                ...action.data
            }
            return {
                ...state,
                temporaryTrips: updatedTrips
            }
        }
        case RESET_STORE: {
            return INITIAL_STATE;
        }
        default: return state;
    }
};

export default tripsReducer;