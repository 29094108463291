import {
    RESET_STORE,
    SET_WMS_USERS_LIST_IN_STORE,
    SET_WMS_USER_LIST_AS_SELECTED
} from "../../actions/types";

const INITIAL_STATE = {
    usersList: []
};
const teamLeads = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_WMS_USERS_LIST_IN_STORE: {
            return {
                ...state,
                usersList: action.data
            }
        }
        case SET_WMS_USER_LIST_AS_SELECTED: {
            const {
                objectId,
                selected
            } = action.data;
            const wmsTeamLeadsList = JSON.parse(JSON.stringify(state.usersList));
            // eslint-disable-next-line no-unused-expressions
            wmsTeamLeadsList?.forEach(user => {
                if (user?.objectId === objectId) {
                    user.isSelected = selected
                }
            });
            return {
                ...state,
                usersList: wmsTeamLeadsList
            }
        }
        case RESET_STORE: {
            return INITIAL_STATE
        }
        default: return state;
    }
};

export default teamLeads;