import { ADD_BRANCH_BREAKUP_REQUISITION_DATA_IN_STORE, SET_REQUISITIONS_IN_STORE, SET_SELECTED_REQUISITION_IN_STORE } from "./types"

export const setSentRequisitionsInStore = (data) => {
    return {
        type: SET_REQUISITIONS_IN_STORE,
        data: {
            pageType: "sent",
            data
        }
    }
}
export const setReceivedRequisitionsInStore = (data, pageType) => {
    return {
        type: SET_REQUISITIONS_IN_STORE,
        data: {
            pageType,
            data
        }
    }
}
export const setSelectedRequisitionInStore = (data) => {
    return {
        type: SET_SELECTED_REQUISITION_IN_STORE,
        data
    }
}

export const addBranchBreakupRequisitionData = (data) => {
    return {
        type: ADD_BRANCH_BREAKUP_REQUISITION_DATA_IN_STORE,
        data
    }
}