import React from "react";
import Pagination from "react-bootstrap-table2-paginator";
import * as Paginator from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import Table from "react-bootstrap-table-next";
import EmptyTable from "../../../components/common/EmptyTableView";
import { customSizePerPage, customTotal } from "../../../components/common/parcelStatusPages";
import { Card, Col, Row } from "@themesberg/react-bootstrap";
import { returnRowBGColor } from "../../../utils/parcels";
import { useTranslation } from "react-i18next";
import { returnPurchaseTargetTableColumns } from "../../../utils/wms/purchaseTarget";

const PurchaseTargetTableItems = (props) => {
    const {
        tableData
    } = props;
    const { t } = useTranslation();
    const tableColumns = returnPurchaseTargetTableColumns();
    return (
        <ToolkitProvider
            exportCSV
            keyField="id"
            search={true}
            sort
            columns={tableColumns}
            data={tableData}
            wrapperClasses="table-responsive"
        >
            {({ baseProps, searchProps, csvProps }) => (
                <Paginator.PaginationProvider pagination={
                    Pagination({
                        custom: true,
                        showTotal: true,
                        alwaysShowAllBtns: true,
                        totalSize: tableData?.length,
                        paginationTotalRenderer: customTotal,
                        sizePerPageRenderer: customSizePerPage
                    })
                }>
                    {({ paginationProps, paginationTableProps }) => (
                        <>
                            <Card>
                                <div className="table-responsive py-2">
                                    <Card.Header>
                                        <Row className="d-flex justify-content-between">

                                            <Col xs={12} md={4} className="d-flex justify-content-md-end py-1">
                                                <Search.SearchBar {...searchProps} placeholder={t("Search any value in table here")} />
                                                <Search.ClearSearchButton {...searchProps} />
                                            </Col>
                                        </Row>
                                    </Card.Header>

                                    <Table
                                        rowClasses={(row) => {
                                            const rowBGColor = returnRowBGColor(row?.dashboardStatus || row?.mobileStatus || row?.status);
                                            return `${rowBGColor} border-bottom`;
                                        }}
                                        noDataIndication={<EmptyTable />}
                                        {...baseProps}
                                        {...paginationTableProps}
                                        bodyClasses="border-0"
                                        classes="table-flush dataTable-table"
                                    />

                                    <Card.Footer>
                                        <Row>
                                            <Col xs={12} md={4} className="py-1">
                                                <Paginator.SizePerPageDropdownStandalone {...paginationProps} />
                                            </Col>
                                            <Col xs={12} md={4} className="d-flex align-items-center py-1">
                                                <Paginator.PaginationTotalStandalone {...paginationProps} />
                                            </Col>
                                            <Col xs={12} md={4} className="d-flex justify-content-md-end align-items-center mb-0 py-1">
                                                <Paginator.PaginationListStandalone {...paginationProps} />
                                            </Col>
                                        </Row>
                                    </Card.Footer>
                                </div>
                            </Card>
                        </>
                    )}
                </Paginator.PaginationProvider>
            )}
        </ToolkitProvider>
    )
}
export default PurchaseTargetTableItems