import React from 'react';
import { useNavigate } from 'react-router-dom';

const BreadCrumbTitle = ({ breadcrumbs }) => {
  const navigate = useNavigate();
  return (
    <div style={{ fontSize: '14px', color: '#777' }}>
      {breadcrumbs.map((item, index) => (
        <span key={index} style={{ display: 'inline-block' }}>
          {index !== breadcrumbs.length - 1 ? (
            <span
              style={{
                color: '#444444',
                fontWeight: '400',
                fontSize: '24px',
                lineHeight: '28.13px',
                textDecoration: 'None',
                opacity: '60%',
                cursor: item.link && 'pointer',
              }}
              onClick={() => navigate(item.link ? item.link : '')}
            >
              {item.title}
            </span>
          ) : (
            <span
              style={{
                color: '#444444',
                fontWeight: '500',
                fontSize: '24px',
                lineHeight: '28.13px',
              }}
            >
              {item.title}
            </span>
          )}
          {index < breadcrumbs.length - 1 && (
            <span
              style={{
                margin: '0 10px',
                color: '#999',
                fontWeight: '500',
                fontSize: '24px',
                lineHeight: '28.13px',
                fontWeight: '500',
              }}
            >
              &gt;
            </span>
          )}
        </span>
      ))}
    </div>
  );
};

export default BreadCrumbTitle;
