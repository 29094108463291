export default {
    translations: {
        "Username cannot be empty": "Username cannot be empty",
        "Save Temporary Trip": "Save Temporary Trip",
        "View Packing": "View Packing",
        "Orders Count": "Orders Count",
        "Locations": "Locations",
        "Temporary Trip Created Successfully": "Temporary Trip Created Successfully",
        "Please visit the trip page to take more action on this trip": "Please visit the trip page to take more action on this trip",
        "Temporary Trip Creation failed": "Temporary Trip Creation failed",
        "Please try again or contact support": "Please try again or contact support",
        "Add New Warehouse": "Add New Warehouse",
        "Use this page to add a new warehouse": "Use this page to add a new warehouse",
        "Warehouse Name": "Warehouse Name",
        "Warehouse Code": "Warehouse Code",
        "Warehouse Short Code": "Warehouse Short Code",
        "Address": "Address",
        "Pincode": "Pincode",
        "zone": "zone",
        "UserName": "UserName",
        "Password": "Password",
        "Repeat Password": "Repeat Password",
        "Create User": "Create User",
        "Cancel": "Cancel",
        "Add Users": "Add Users",
        "Add Warehouse": "Add Warehouse",
        "Add Incharge": "Add Incharge",
        "Add New Incharge": "Add New Incharge",
        "Use this page to add a new incharge": "Use this page to add a new incharge",
        "Incharge Name": "Incharge Name",
        "Phone Number": "Phone Number",
        "Email Id": "Email Id",
        "Select Application": "Select Application",
        "Warehouse / Store Name": "Warehouse / Store Name",
        "Incharge Info": "Incharge Info",
        "Login Info": "Login Info",
        "Item Removed": "Item Removed",
        "Parcel Removed from trip successfully": "Parcel Removed from trip successfully",
        "Removal Failed": "Removal Failed",
        "Parcel removal failed, Please try later": "Parcel removal failed, Please try later",
        "Scan, Print EWB & Dispatch your vehicle from here": "Scan, Print EWB & Dispatch your vehicle from here",
        "Enter Serial No to Scan": "Enter Serial No to Scan",
        "શું તમે ખરેખર દૂર કરવા માંગો છો": "શું તમે ખરેખર દૂર કરવા માંગો છો",
        "Remove Orders": "Remove Orders",
        "Confirm": "Confirm",
        "Drop File here": "Drop File here",
        "Select Display Sheet": "Select Display Sheet",
        "Please enter valid InvoiceId [X-Q-SI X] [RC/X/X-X/X] [PC/X/X-X/X] [X-S-SI X]": "Please enter valid InvoiceId [X-Q-SI X] [RC/X/X-X/X] [PC/X/X-X/X] [X-S-SI X]",
        "Vehicle Number is mandatory": "Vehicle Number is mandatory",
        "Vehicle Area is mandatory": "Vehicle Area is mandatory",
        "Please Select an option": "Please Select an option",
        "Please Enter Valid Vehicle Number": "Please Enter Valid Vehicle Number",
        "Please select an option": "Please select an option",
        "Create Temporary trip": "Create Temporary trip",
        "Vehicle Number": "Vehicle Number",
        "Vehicle Area": "Vehicle Area",
        "Save Temporary": "Save Temporary",
        "Height": "Height",
        "Length": "Length",
        "Breadth": "Breadth",
        "Incharge Added": "Incharge Added",
        "incharge added successfully": "incharge added successfully",
        "incharge failed to add": "incharge failed to add",
        "Incharge Add Failed": "Incharge Add Failed",
        "Please enter Incharge name": "Please enter Incharge name",
        "Delivery Management": "Delivery Management",
        "Warehouse Management": "Warehouse Management",
        "Finance": "Finance",
        "Omni Channel": "Omni Channel",
        "Product Management": "Product Management",
        "Select Applications to give access": "Select Applications to give access",
        "All your temporary trips are listed here.": "All your temporary trips are listed here.",
        "Previous": "Previous",
        "Next": "Next",
        "Dashboard": "Dashboard",
        "Overview": "Overview",
        "Dispatch": "Dispatch",
        "Temporary Trips": "Temporary Trips",
        "Trips List": "Trips List",
        "Requisitions": "Requisitions",
        "Trips": "Trips",
        "Parcels": "Parcels",
        "Vehicles": "Vehicles",
        "Live": "Live",
        "Plan Vehicles": "Plan Vehicles",
        "Prepare temporary trip sheet here": "Prepare temporary trip sheet here",
        "Enter / Scan Invoice Number": "Enter / Scan Invoice Number",
        "Warehouse Info": "Warehouse Info",
        "Please select a zone": "Please select a zone",
        "Active Trips": "Active Trips",
        "All the trips of your warehouse are listed here.": "All the trips of your warehouse are listed here.",
        "New Trip": "New Trip",
        "Share": "Share",
        "Export": "Export",
        "Trip Number": "Trip Number",
        "VEHICLE NUMBER": "VEHICLE NUMBER",
        "VEHICLE AREA": "VEHICLE AREA",
        "ने निर्मित": "ने निर्मित",
        "DATE": "DATE",
        "STATUS": "STATUS",
        "Pending": "Pending",
        "Scan, Print EWB & Dispatch your vehicle from here.": "Scan, Print EWB & Dispatch your vehicle from here.",
        "Print Sheet": "Print Sheet",
        "Print EWB": "Print EWB",
        "CUSTOMER": "CUSTOMER",
        "INVOICE NO": "INVOICE NO",
        "ADDRESS": "ADDRESS",
        "SERIAL NO": "SERIAL NO",
        "PRODUCT": "PRODUCT",
        "ACTION": "ACTION",
        "Remove": "Remove",
        "Loader Name 1": "Loader Name 1",
        "Loader Number 1": "Loader Number 1",
        "Loader Name 2": "Loader Name 2",
        "Loader Number 2": "Loader Number 2",
        "Loader Name 3": "Loader Name 3",
        "Loader Number 3": "Loader Number 3",
        "Loader Name 4": "Loader Name 4",
        "Loader Number 4": "Loader Number 4",
        "Loader Name 5": "Loader Name 5",
        "Loader Number 5": "Loader Number 5",
    }
}