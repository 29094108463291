import React, { useState } from "react";
import { Button, Form, Modal } from "@themesberg/react-bootstrap";
import { generateRandomId } from "../../utils/string";
import { useTranslation } from "react-i18next";
import DropdownComponent from "../../components/common/Dropdown";
import { returnDeliveryDataRescheduleOptions } from "../../utils/delivery-data";

const DeliveryDataRescheduleModal = (props) => {
    const { t} = useTranslation();
    const {
        showModal,
        closeModal,
        onSubmit
    } = props;
    const [formErrors, setFormErrors] = useState({});
    const [selectedOption, setSelectedOption] = useState({});
    const handleConfirmClick = () => {
        if (Object.keys(selectedOption)?.length === 0) {
            setFormErrors({
                "subStatus": "Please select an option"
            })
        } else {
            onSubmit(selectedOption);
        }
    }
    const handleCloseModal = () => {
        setSelectedOption({});
        closeModal();
    }
    return (
        <Modal id={generateRandomId()} as={Modal.Dialog} centered show={showModal} onHide={handleCloseModal}>
            <Modal.Header>
                <Modal.Title className="h6">{t("Reschedule Parcel")}</Modal.Title>
                <Button variant="close" aria-label="Close" onClick={handleCloseModal} />
            </Modal.Header>
            <Modal.Body>
                <Form.Group className="mb-4">
                    <Form.Label className={formErrors["subStatus"]?.length > 0 ? "text-danger" : ""}>{t("Choose Reschedule Type")}</Form.Label>
                    <DropdownComponent
                        onChange={(option) => {
                            setSelectedOption(option.value);
                        }}
                        options={returnDeliveryDataRescheduleOptions()}
                        placeholder={t("Select a status")}
                    />
                </Form.Group>
                {formErrors["subStatus"]?.length > 0 && (
                    <Form.Control.Feedback type="invalid">{formErrors["subStatus"]}</Form.Control.Feedback>
                )}
                
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleConfirmClick}>
                    {t("Rechedule")}
                </Button>
                <Button variant="link" className="text-gray ms-auto" onClick={handleCloseModal}>
                    {t("Cancel")}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
export default DeliveryDataRescheduleModal;