import logo from '../../assets/img/brand/vijay-sales-logo.svg';
import { faIndianRupeeSign } from '@fortawesome/free-solid-svg-icons';
import { Routes } from '../../routes';
import { Routes as AppRoutes } from '../../router/routes';

const navigationConfig = {
  Dashboard: {
    title: 'Dashboard',
    link: Routes.Presentation.path,
    image: logo,
  },
  Pricing: {
    title: 'Product',
    icon: faIndianRupeeSign,
    link: AppRoutes.PricingTracker.Home.path,
  },
};

export default navigationConfig;
