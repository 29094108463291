import { warehousesToHideFromList } from './constants';

export const returnWarehouseDropdownOptions = (
  warehouses,
  addAll = false,
  zone = ''
) => {
  //   console.log(warehouses);
  let allWarehouseList = Object.values(warehouses)
    .filter((each) => {
      //   console.log(each?.zone);
      if (zone) {
        return zone === each?.zone;
      } else {
        return true;
      }
    })
    .map((warehouse) => {
      return {
        label: warehouse?.warehouseName,
        value: warehouse?.warehouseCode,
        data: warehouse,
      };
    });
  if (addAll) {
    allWarehouseList = [
      {
        label: 'All',
        value: 'all',
      },
    ].concat(allWarehouseList);
  }

  //   console.log(allWarehouseList);
  return allWarehouseList;
};
export const removeUnusedWarehousesFromList = (warehousesArray) => {
  return warehousesArray.filter(
    (warehouse) => warehousesToHideFromList.indexOf(warehouse.warehouseName) < 0
  );
};
