import { faBarcode, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Col, Form, InputGroup, Row } from "@themesberg/react-bootstrap";
import React, { useState } from "react";
import Table from "react-bootstrap-table-next";
import Pagination from "react-bootstrap-table2-paginator";
import * as Paginator from "react-bootstrap-table2-paginator";
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BreadCrumbComponent from "../../components/common/Breadcrumb";
import { Routes } from "../../router/routes";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { updateSpinnerState } from "../../actions/spinner";
import { updateToastInfo } from "../../actions/settings";
import { getTripInfoByTemposheetNumber } from "../../parse-functions/trips";
import { allowTemposheetReceive } from "../../utils/trips";
import { customSizePerPage, customTotal } from "../../components/common/parcelStatusPages";
import EmptyTable from "../../components/common/EmptyTableView";
import { temposheetReceiveTableColumns } from "../../utils/temposheet";
import { handleParcelBuybackReceive, handleParcelReturnScan, receiveTemposheetOrStock } from "../../parse-functions/parcels";
import { returnRowBGColor } from "../../utils/parcels";
import toastr from "../../components/common/toastr";
import { setCurrentReceiveTemposheetParcelsAsReceived, setSelectedParelStateInStore, setTemposheetReceiveParcelsInStore } from "../../actions/temposheet";
import DropdownComponent from "../../components/common/Dropdown";

const ReceiveTemposheet = (props) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const warehouse = useSelector((state) => state?.user?.warehouse || "");
    const [temposheetNumber, setTemposheetNumber] = useState("");
    const [returnItemScanNo, setReturnItemScanNo] = useState("");
    const [isAllSelected, setIsAllSelected] = useState(false);
    const temposheetParcels = useSelector((state) => state.temposheet.receiveList || []);
    const [selectedItemOption, setSelectedItemOptionHandler] = useState({});

    const disableReturnMarkButton = temposheetParcels?.filter(item => item.isSelected)?.length < 1;
    const breadCrumbItems = [{
        title: t("Temposheet"),
        link: Routes.Trips.TripsList.path
    }, {
        title: t("Receive Temposheet"),
        link: ""
    }];

    const getTemposheetDetails = async (temposheetNo) => {
        try {
            dispatch(updateSpinnerState(true));
            const temposheetInfo = await getTripInfoByTemposheetNumber(temposheetNo, true);
            if (temposheetInfo) {
                const {
                    parcels,
                    tripInfo
                } = temposheetInfo;
                // check if this is own temposheet or not
                const allowReceive = allowTemposheetReceive(tripInfo, warehouse);
                if (true) {
                    dispatch(setTemposheetReceiveParcelsInStore(parcels));
                    dispatch(updateToastInfo({
                        show: true,
                        type: "success",
                        title: t("Parcels Details fetched"),
                        message: t("Please scan the parcels that you would like to mark as received")
                    }))
                } else {
                    dispatch(updateToastInfo({
                        show: true,
                        type: "danger",
                        title: t("Own Temposheet"),
                        message: t("Can't receive temposheet generated from your own warehouse")
                    }))
                }
            } else {
                dispatch(updateToastInfo({
                    show: true,
                    type: "danger",
                    title: t("No Data found"),
                    message: t("No Temposheet Info found for this given trip")
                }))
            }
            dispatch(updateSpinnerState(false));
        } catch (e) {
            dispatch(updateToastInfo({
                show: true,
                type: "danger",
                title: t("Fetching Temposheet Details Failed"),
                message: t(e.message)
            }))
            dispatch(updateSpinnerState(false));
        }
    }
    const onRowSelect = (value, objectId) => {
        if (objectId === "selectAll") {
            setIsAllSelected(value);
        }
        dispatch(setSelectedParelStateInStore({
            value,
            objectId
        }));
    }

    const handleItemScan = async (val, parcelIds) => {
        try {
            // check if the parcel is already updated with something else
            let selectedParcels = temposheetParcels?.filter(parcel => parcelIds?.indexOf(parcel.objectId) > -1);
            // now check for parcels by matching the serial Number being selected
            if (val?.length > 0) {
                const serialNoMatchedParcels = temposheetParcels?.filter(parcel => parcel?.serialNo?.toLowerCase() === val?.toLowerCase());
                if (serialNoMatchedParcels?.length > 0) {
                    selectedParcels = selectedParcels.concat(serialNoMatchedParcels);
                }
            }
            // divide the parcels in dropatHub and other status parcels.
            const dropAtHubParcels = [];
            const otherParcels = [];
            // eslint-disable-next-line no-unused-expressions
            selectedParcels?.forEach((parcel) => {
                const status = parcel.dashboardStatus || parcel.mobileSubStatus || parcel.status;
                if (status === "pending") {
                    dropAtHubParcels.push(parcel.objectId);
                } else {
                    otherParcels.push(parcel.objectId);
                }
            });
            if (dropAtHubParcels?.length > 0) {
                const receivedParcel = await receiveTemposheetOrStock({
                    tripNumber: temposheetNumber,
                    serialNo: val,
                    parcelIds: dropAtHubParcels,
                    warehouseCode: warehouse?.warehouseCode
                });
                dispatch(setCurrentReceiveTemposheetParcelsAsReceived(receivedParcel?.parcels));
            }
            if (otherParcels?.length > 0) {
                const scannedItem = await handleParcelReturnScan({
                    tripNumber: temposheetNumber,
                    serialNo: val,
                    parcelIds: otherParcels,
                    warehouseCode: warehouse?.warehouseCode,
                    shortCode: warehouse?.shortCode
                });
                const {
                    parcels
                } = scannedItem;
                dispatch(setCurrentReceiveTemposheetParcelsAsReceived(parcels));
            }
            setReturnItemScanNo('');
            if (dropAtHubParcels?.length > 0 || otherParcels?.length > 0) {
                toastr.success('Item Receive Scan Completed');
            } else {
                toastr.error("Item Scan Failed");
            }
            dispatch(updateSpinnerState(false));
        } catch (e) {
            setReturnItemScanNo('');
            toastr.error("Item Scan Failed");
            dispatch(updateSpinnerState(false));
        }
    }
    const handleItemScanWithoutSerial = () => {
        const selectedItems = temposheetParcels?.filter(parcel => parcel.isSelected);
        const parcelIds = selectedItems.map(item => item.objectId);
        handleItemScan("", parcelIds);
    }
    const handleSelectedOptionsChange = (option) => {
        setSelectedItemOptionHandler(option);
    }
    const handleParcelBuyBack = async () => {
        const selectedItems = temposheetParcels?.filter(parcel => parcel.isSelected);
        const parcelIds = selectedItems.map(item => item.objectId);
        console.log("parcel Items");
        console.log(parcelIds);
        dispatch(updateSpinnerState(true));
        try {

            const scannedItem = await handleParcelBuybackReceive({
                tripNumber: temposheetNumber,
                parcelIds,
                warehouseCode: warehouse?.warehouseCode,
                shortCode: warehouse?.shortCode
            });
            const {
                parcels
            } = scannedItem;
            dispatch(setCurrentReceiveTemposheetParcelsAsReceived(parcels));
            dispatch(updateSpinnerState(false));
            toastr.success("Buyback Received Successfully");
        } catch(e) {
            dispatch(updateSpinnerState(false));
            toastr.error(e?.message || "Buyback received failed. Please try again");
        }
        // handleItemScan("", parcelIds);
    }
    const handleSelectedItemOption = () => {
        if (Object.keys(selectedItemOption)?.length > 0) {
            switch(selectedItemOption.value) {
                case "receiveProduct": {
                    handleItemScanWithoutSerial();
                    break;
                }
                case "receiveBuyback": {
                    handleParcelBuyBack();
                    break;
                }
                default: {
                    toastr.error("Please select an option");
                    break;
                }
            }
        } else {
            toastr.error("Please select an option");
        }
    }
    const alreadyReceivedParcels = temposheetParcels?.filter((parcel) => parcel?.receivedAt);
    return (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-md-0">
                    <BreadCrumbComponent items={breadCrumbItems} />
                    <h4>{"Receive Temposheet"}</h4>
                    <p className="mb-0">{t("Use this to receive temposheet items. Items without -GT in invoice number will be automatically available for delivery again.")}</p>
                </div>
            </div>
            <div className="table-settings mb-4">
                <Row className="justify-content-between align-items-center">
                    <Col xs={8} md={6} lg={3} xl={4}>
                        <Row className="d-flex">
                            <Col xs={8} md={8} lg={8} xl={8}>
                                <InputGroup>
                                    <InputGroup.Text>
                                        <FontAwesomeIcon icon={faSearch} />
                                    </InputGroup.Text>
                                    <Form.Control
                                        onChange={(event) => {
                                            setTemposheetNumber(event.target.value)
                                        }}
                                        onKeyDown={(event) => {
                                            if (event.nativeEvent.code === 'Enter') {
                                                getTemposheetDetails(event.target.value)
                                            }
                                        }}
                                        type="text"
                                        placeholder={t("Enter TS Number to Receive")}
                                    />
                                </InputGroup>
                            </Col>
                            <Col xs={4} md={4} lg={4} xl={4}>
                                <Button variant="primary" type="button" onClick={() => {
                                    getTemposheetDetails(temposheetNumber)
                                }} >
                                    <FontAwesomeIcon icon={faSearch} />&nbsp;&nbsp;{t("Search")}
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            <ToolkitProvider
                exportCSV
                keyField="objectId"
                search={true}
                sort
                columns={temposheetReceiveTableColumns(onRowSelect, isAllSelected)}
                // data={sortArrayOfJSONByKeyName(temposheetParcels, "updatedAt")?.reverse()}
                data={temposheetParcels}
                wrapperClasses="table-responsive"
            >
                {({ baseProps, searchProps, csvProps }) => (
                    <Paginator.PaginationProvider pagination={
                        Pagination({
                            custom: true,
                            showTotal: true,
                            alwaysShowAllBtns: true,
                            totalSize: temposheetParcels?.length,
                            paginationTotalRenderer: customTotal,
                            sizePerPage: 1000,
                            sizePerPageRenderer: customSizePerPage
                        })
                    }>
                        {({ paginationProps, paginationTableProps }) => (
                            <>
                                <Card>
                                    <div className="table-responsive py-2">
                                        <Card.Header>
                                            <Row className="d-flex justify-content-between">
                                                <Col xs={6} md={4} className="d-flex py-1">
                                                    <Row className="d-flex w-100">
                                                        <Row>
                                                            <Col>
                                                                <DropdownComponent
                                                                    disabled={false}
                                                                    onChange={handleSelectedOptionsChange}
                                                                    options={[{
                                                                        label: "Receive Product",
                                                                        value: "receiveProduct"
                                                                    }, {
                                                                        label: "Receive Buyback",
                                                                        value: "receiveBuyback"
                                                                    }]}

                                                                />
                                                            </Col>
                                                            <Col>
                                                                <Button variant="primary" type="button" onClick={handleSelectedItemOption} disabled={disableReturnMarkButton}>
                                                                    <FontAwesomeIcon icon={faBarcode} />&nbsp;&nbsp;{t("Receive")}
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </Row>
                                                </Col>
                                                <Col
                                                    xs={4}
                                                    md={4}
                                                    className="d-flex align-items-center justify-content-between"
                                                    style={{
                                                        fontSize: 18,
                                                        fontWeight: 700
                                                    }}>
                                                    {alreadyReceivedParcels?.length > 0 && (
                                                        <div>Received items : {alreadyReceivedParcels?.length}</div>
                                                    )}
                                                    {alreadyReceivedParcels?.length > 0 && (
                                                        <div>Remaining items : {temposheetParcels?.length - alreadyReceivedParcels?.length}</div>
                                                    )}
                                                </Col>
                                                <Col xs={12} md={4} className="d-flex justify-content-md-end py-1">
                                                    <Row>
                                                        <Col xs={12} md={7}>
                                                            <InputGroup>
                                                                <InputGroup.Text>
                                                                    <FontAwesomeIcon icon={faSearch} />
                                                                </InputGroup.Text>
                                                                <Form.Control
                                                                    value={returnItemScanNo}
                                                                    onChange={(event) => {
                                                                        setReturnItemScanNo(event.target.value)
                                                                    }}
                                                                    onKeyDown={(event) => {
                                                                        if (event.nativeEvent.code === 'Enter') {
                                                                            handleItemScan(event.target.value)
                                                                        }
                                                                    }}
                                                                    type="text"
                                                                    placeholder={t("Enter Serial no. to receive")}
                                                                />
                                                            </InputGroup>
                                                        </Col>
                                                        <Col xs={12} md={5}>
                                                            <Button
                                                                disabled={returnItemScanNo?.length < 1}
                                                                variant="primary"
                                                                type="button"
                                                                onClick={() => {
                                                                    handleItemScan(returnItemScanNo);
                                                                }} >
                                                                <FontAwesomeIcon icon={faBarcode} />&nbsp;&nbsp;{t("Receive")}
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Card.Header>

                                        <Table
                                            keyField={"objectId"}
                                            rowClasses={(row) => {
                                                const rowBGColor = returnRowBGColor(row?.dashboardStatus || row?.mobileStatus || row?.status);
                                                return `${rowBGColor} border-bottom`;
                                            }}
                                            noDataIndication={<EmptyTable />}
                                            {...baseProps}
                                            {...paginationTableProps}
                                            bodyClasses="border-0"
                                            classes="table-flush dataTable-table"
                                        />

                                        <Card.Footer>
                                            <Row>
                                                <Col xs={12} md={4} className="py-1">
                                                    <Paginator.SizePerPageDropdownStandalone {...paginationProps} />
                                                </Col>
                                                <Col xs={12} md={4} className="d-flex align-items-center py-1">
                                                    <Paginator.PaginationTotalStandalone {...paginationProps} />
                                                </Col>
                                                <Col xs={12} md={4} className="d-flex justify-content-md-end align-items-center mb-0 py-1">
                                                    <Paginator.PaginationListStandalone {...paginationProps} />
                                                </Col>
                                            </Row>
                                        </Card.Footer>
                                    </div>
                                </Card>
                            </>
                        )}
                    </Paginator.PaginationProvider>
                )}
            </ToolkitProvider>
        </>
    )
};
export default ReceiveTemposheet;