import React from "react";
import { Button, Modal } from "@themesberg/react-bootstrap";
import { useTranslation } from "react-i18next";
import { generateRandomId } from "../../../utils/string";
import FormComponent from "../../scheme-management/Components/FormComponent";
import { addDeliverySchema } from "./utils";

const Add3plModal = (props) => {
  const { showModal, closeModal, handleSubmit } = props;
  const { t } = useTranslation();

  return (
    <>
      <Modal
        id={generateRandomId()}
        as={Modal.Dialog}
        centered
        show={showModal}
        onHide={closeModal}
        size="lg"
      >
        <Modal.Header>
          <Modal.Title className="h6">{t("Add New Partner")}</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={closeModal} />
        </Modal.Header>
        <Modal.Body>
          <FormComponent
            schema={addDeliverySchema}
            savefunction={handleSubmit}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="link"
            className="text-gray ms-auto"
            onClick={closeModal}
          >
            {t("Cancel")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Add3plModal;
