import React, { useEffect, useState } from 'react';
import RenderNavItems from '../common/renderNavItems';
import { useDispatch, useSelector } from 'react-redux';
import { apiCall } from '../../middlewares/api';
import { apiCallConsts } from '../../constants/apiCallConsts';
import { updateToastInfo } from '../../actions/settings';
import { useTranslation } from 'react-i18next';
import initialNavigationConfig from './omsNavgationConfig';

const OMSSidebarOptions = () => {
  const [navigationConfig, setNavigationConfig] = useState(
    initialNavigationConfig
  );
  const storeName = useSelector(
    (state) => state?.user?.preferences?.storeName || ''
  );
  const zone = useSelector((state) => state?.user?.preferences?.zone || '');
  const isAdmin = useSelector(
    (state) => state?.user?.preferences?.isAdmin || false
  );
  const permissions = useSelector((state) => state?.user?.permissions || {});

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const fetchOrderCounts = async () => {
    try {
      const result = await apiCall(
        apiCallConsts.GET_METHOD,
        `${apiCallConsts.ORDER_DETAILS_URL}orderCounts?storeCode=${
          isAdmin ? '' : storeName
        }&zone=${zone}`
      );

      updateNavigationConfig(result);
    } catch (error) {
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('Error!'),
          message: error.message,
        })
      );
    }
  };

  const updateNavigationConfig = (counts) => {
    const updatedConfig = { ...initialNavigationConfig };

    // Update the Orders section
    if (updatedConfig.Orders) {
      updatedConfig.Orders.badgeText = counts.All;

      // Update children counts
      updatedConfig.Orders.children = updatedConfig.Orders.children.map(
        (child) => {
          const title = child.title;

          let countKey = title;
          if (title === 'QC Passed') countKey = 'Awaiting Invoice';
          if (counts[countKey] !== undefined) {
            return { ...child, badgeText: counts[countKey] };
          }

          return child;
        }
      );
    }

    setNavigationConfig(updatedConfig);
  };

  useEffect(() => {
    if (
      !storeName ||
      initialNavigationConfig.Orders.children.some((child) =>
        Object.keys(permissions).includes(child.link)
      )
    ) {
      fetchOrderCounts();
    }
  }, [storeName, permissions]);

  return (
    <>
      <RenderNavItems config={navigationConfig} />
    </>
  );
};

export default OMSSidebarOptions;
