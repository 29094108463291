import React, { useEffect, useState, useRef } from 'react';
import FormComponent from '../FormComponent';
import BreadCrumbComponent from '../../../../components/common/Breadcrumb';
import { useTranslation } from 'react-i18next';
import { updateSpinnerState } from '../../../../actions/spinner';
import { useDispatch, useSelector } from 'react-redux';
import {
  addNewScheme,
  getAllCompaniesCategoriesSubCategoriesData,
} from '../../../../parse-functions/sms/scheme';
import { updateToastInfo } from '../../../../actions/settings';
import { newSchemeSchema } from '../../schema/newScheme';
import arrayToBreadCrumbs from '../../../../utils/sms/arrayToBreadCrumbs';
import { arrayToDropdownOptions } from '../../../../utils/sms/arrayToDropdownOptions';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../../../../router/routes';
import ExcelProductsUpload from '../common/ExcelProductsUpload';
import { transformFormData } from '../../../../utils/sms/convertProductsToDropdownOptions';
import {
  setExcelProductUploadDataInStore,
  setShowMismatchProductsModal,
} from '../../../../actions/sms/productsExcelUpload';

const AddScheme = ({ editData, handleSave }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAdmin = useSelector(
    (state) => state?.user?.preferences?.isAdmin || false
  );
  const [companies, setCompanies] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [schema, setSchema] = useState(newSchemeSchema);
  const [storedSchema, setStoredSchema] = useState(null);
  const products = useSelector((state) => state?.products?.productsList || {});
  const uploadedData = useSelector(
    (state) => state?.productsExcelUpload?.uploadedData || []
  );
  const mismatchedData = useSelector(
    (state) => state?.productsExcelUpload?.mismatchedData || []
  );
  const userInfo = useSelector((state) => state?.user?.userInfo);
  const breadCrumbItems = arrayToBreadCrumbs([['Scheme'], ['Add New Scheme']]);
  const configData = useSelector((state) => state.user.config || {});

  const [formProps, setFormProps] = useState({
    type: editData?.type || [],
    cappingType: editData?.cappingType || [],
    uploadExcel: editData?.uploadExcel || false,
    company: '',
    category: '',
  });
  const previousCompany = useRef(formProps.company);

  useEffect(() => {
    // Update schema when dependencies change
    setSchema((prevSchema) =>
      prevSchema.map((section) =>
        section.map((field) => {
          if (field.title === 'Company') {
            if (isAdmin) {
              field.options = companies;
            } else {
              const company = userInfo?.company?.filter(
                (item) => item !== 'All'
              );
              field.options = arrayToDropdownOptions(company || []);
            }
          }
          if (field.title === 'Category') {
            if (isAdmin) {
              field.options =
                categories.length > 0
                  ? [{ label: 'All', value: 'All' }, ...categories]
                  : [];
            } else {
              const category = userInfo?.productCategory?.filter(
                (item) => item !== 'All'
              );
              field.options = arrayToDropdownOptions(category || []);
            }
          }
          if (field.title === 'Sub-Category') {
            field.options = [{ label: 'All', value: 'All' }, ...subCategories];
          }
          if (field.title === 'Regions') {
            field.options = [
              { label: 'All', value: 'All' },
              ...arrayToDropdownOptions(Object.keys(configData.zones || {})),
            ];
          }
          return field;
        })
      )
    );
  }, [companies, categories, subCategories, userInfo, isAdmin, configData]);

  useEffect(() => {
    if (editData) {
      setSchema((prevSchema) =>
        prevSchema
          .map((section) => section.filter((item) => item.title !== 'Cancel'))
          .map((section) =>
            section.map((field) => {
              if (field.title === 'Create Scheme') {
                field.title = 'Update Scheme';
              }
              if (field.id === 'uploadExcel') {
                field.disabled = true;
              }
              return field;
            })
          )
      );
    } else {
      setSchema((prevSchema) =>
        prevSchema.map((section) =>
          section.map((field) => {
            if (field.title === 'Update Scheme') {
              field.title = 'Create Scheme';
            }
            if (field.id === 'uploadExcel') {
              field.disabled = false;
            }
            return field;
          })
        )
      );
    }
  }, [editData]);

  useEffect(() => {
    if (formProps.uploadExcel) {
      setSchema((prevSchema) => {
        if (!storedSchema) {
          setStoredSchema(prevSchema);
        }
        return prevSchema.slice(0, 2);
      });
    } else {
      if (storedSchema) {
        setSchema(storedSchema);
        setStoredSchema(null);
      }
    }
  }, [formProps.uploadExcel]);

  const saveScheme = async (formData, callback) => {
    try {
      dispatch(updateSpinnerState(true));
      if (formProps.uploadExcel && mismatchedData.length > 0) {
        dispatch(setShowMismatchProductsModal(true));
        dispatch(updateSpinnerState(false));
        return;
      }
      if (formProps.uploadExcel && uploadedData.length === 0) {
        dispatch(
          updateToastInfo({
            show: true,
            type: 'danger',
            title: editData
              ? t('Scheme edit failed')
              : t('Scheme creation Failed'),
            message: !editData && t(`Upload Excel file with data!`),
          })
        );
        dispatch(updateSpinnerState(false));
        return;
      }
      formData = transformFormData({
        formData,
        formProps,
        categories,
        subCategories,
        products,
        uploadedData,
        configData,
        isAdmin,
        userInfo,
      });
      if (editData) {
        handleSave(formData);
      } else {
        await addNewScheme(formData);
      }
      dispatch(
        updateToastInfo({
          show: true,
          type: 'success',
          title: editData ? t('User Edited') : t('Scheme Created'),
          message: editData
            ? t('Scheme Updated successfully!')
            : t(`New Scheme created successfully! Waiting for Approval.`),
        })
      );
      callback(null);
      dispatch(updateSpinnerState(false));
      dispatch(setExcelProductUploadDataInStore([]));
      navigate(Routes.SMS.Target.DraftSchemes.path);
    } catch (error) {
      const { message } = error;
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: editData
            ? t('Scheme edit failed')
            : t('Scheme creation Failed'),
          message:
            !editData &&
            t(`New Scheme failed to create. Please contact support.`),
        })
      );
      callback(message);
    }
    dispatch(updateSpinnerState(false));
  };

  useEffect(() => {
    if (formProps.type) {
      setSchema((prevSchema) => {
        let newField;
        if (formProps?.type?.[0] === 'Sell-in') {
          newField = {
            title: 'Sell In Type',
            id: 'sellIntype',
            type: 'select',
            options: [
              { label: 'On-Invoice', value: 'On-Invoice' },
              { label: 'Off-Invoice', value: 'Off-Invoice' },
            ],
            required: true,
          };
        } else if (formProps?.type?.[0] === 'Sell-out') {
          newField = {
            title: 'Capping Type',
            id: 'cappingType',
            type: 'select',
            options: [
              { label: 'Revenue', value: 'Revenue' },
              { label: 'Quantity', value: 'Quantity' },
            ],
            required: false,
          };
        }
        return prevSchema?.map((section, index) => {
          if (index === 1 && newField) {
            const updatedSection = section.filter(
              (field) =>
                field.id !== 'cappingAmount' &&
                field.id !== 'cappingType' &&
                field.id !== 'sellIntype'
            );
            return [updatedSection[0], newField, ...updatedSection.slice(1)];
          }
          return section;
        });
      });
    }
  }, [formProps?.type]);

  useEffect(() => {
    if (formProps.cappingType.length === 1) {
      const cappingAmountObject = {
        title:
          formProps.cappingType[0] === 'Revenue'
            ? 'Capping Amount'
            : 'Capping Quantity',
        id: 'cappingAmount',
        type: 'number',
        required: formProps.type[0] === 'Sell-out',
      };

      setSchema((prevSchema) => {
        return prevSchema.map((section, index) => {
          if (index === 1) {
            const updatedSection = section.filter(
              (item) => item.id !== 'cappingAmount'
            );
            return [
              updatedSection[0],
              updatedSection[1],
              cappingAmountObject,
              ...updatedSection.slice(2),
            ];
          }
          return section;
        });
      });
    }
  }, [formProps.cappingType, formProps.type]);

  useEffect(() => {
    const fetchData = async () => {
      dispatch(updateSpinnerState(true));
      if (!formProps.company && isAdmin) {
        const data = await getAllCompaniesCategoriesSubCategoriesData({
          isCompany: true,
        });
        setCompanies(
          data.map((item) => {
            return { label: item.companyName, value: item.companyName };
          })
        );
        dispatch(updateSpinnerState(false));
        return;
      }
      if (formProps.company && formProps.category?.length > 0) {
        const data = await getAllCompaniesCategoriesSubCategoriesData({
          isSubCategory: true,
          company: formProps.company[0],
          category: formProps.category,
        });
        setSubCategories(
          data.map((item) => {
            return {
              label: item.productSubCategory1,
              value: item.productSubCategory1,
            };
          })
        );
        dispatch(updateSpinnerState(false));
        return;
      }
      dispatch(updateSpinnerState(false));
    };
    fetchData();
  }, [formProps.category]);

  useEffect(() => {
    const fetchData = async () => {
      dispatch(updateSpinnerState(true));
      if (
        formProps.company &&
        isAdmin &&
        (!previousCompany.current ||
          previousCompany.current[0] !== formProps.company[0])
      ) {
        const data = await getAllCompaniesCategoriesSubCategoriesData({
          isCategory: true,
          company: formProps.company[0],
        });
        setCategories(
          data.map((item) => {
            return { label: item.categoryName, value: item.categoryName };
          })
        );
        setSubCategories([]);
        previousCompany.current = formProps.company;
      }
      dispatch(updateSpinnerState(false));
    };
    fetchData();
  }, [formProps.company]);

  return (
    <div className="mt-0">
      {!editData && (
        <div className="mb-4 d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-2">
          <div className="d-block mb-4 mb-md-0">
            <BreadCrumbComponent items={breadCrumbItems} />
            <h4>{'Add New Scheme'}</h4>
            <p className="mb-0">{'You can add a new scheme here'}</p>
          </div>
        </div>
      )}
      {(Object.keys(userInfo).length > 0 || isAdmin) && (
        <FormComponent
          schema={schema}
          savefunction={saveScheme}
          editData={editData}
          formProps={formProps}
          setFormProps={setFormProps}
          CustomComponent={
            <ExcelProductsUpload categories={categories} edit={editData} />
          }
        />
      )}
    </div>
  );
};

export default AddScheme;
