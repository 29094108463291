import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ParcelStatusPage from '../../../components/common/parcelStatusPages';
import { useTranslation } from 'react-i18next';
import { updateSpinnerState } from '../../../actions/spinner';
import { updateToastInfo } from '../../../actions/settings';
import {
  getTargetsForTeamLeadsInWarehouse,
  updateWarehouseTargetData,
} from '../../../parse-functions/wms/target';
import {
  getWMSTargetTableColumns,
  returnUpdatedWarehouseTargetInfo,
} from './utils';
import TargetChangeTeamLeadModal from './targetChangeTeamLeadModal';
import { getWMSUsersList } from '../../../parse-functions/wms/teamlead';
import { returnLeadsBasedOnCategory } from '../../../utils/wms/purchaseTarget';
import {
  markTargetItemsAsCancelled,
  setSelectedTargetListInStore,
  setTargetsListInStore,
  updateWarehouseTargetItemsInStore,
} from '../../../actions/wms/targets';

const WarehouseTargetPage = (props) => {
  const {
    pageName,
    status,
    bulkActionOptions = [],
    pageTitle,
    pageSubTitle,
    breadCrumbItems,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const storeName = useSelector(
    (state) => state.user.preferences.storeName || ''
  );
  const [queryData, setQueryData] = useState({});

  const [teamLeadList, setTeamLeadList] = useState([]);
  const [showTargetChangeTeamLeadModal, setShowTargetChangeTeamLeadModal] =
    useState(false);
  const selectedTargetItems = useSelector(
    (state) => state.warehouseTargets?.[`selected${pageName}`] || []
  );
  const enableBulkActions = selectedTargetItems?.length > 0;
  const tableData = useSelector(
    (state) => state?.warehouseTargets?.[pageName] || []
  );
  const [selectedCategory, setSelectedCategory] = useState('');
  const [allTeamleads, setAllTeamleads] = useState([]);

  const handleFiltersSubmit = async (startDate, endDate) => {
    try {
      const queryData = {
        startDate,
        endDate,
        status,
        storeName,
      };
      setQueryData(queryData);
      dispatch(updateSpinnerState(true));
      const assignedTargets = await getTargetsForTeamLeadsInWarehouse(
        queryData
      );
      dispatch(
        setTargetsListInStore({
          pageName,
          list: assignedTargets,
        })
      );
      dispatch(updateSpinnerState(false));
    } catch (e) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: 'Assigned Target fetch failed',
          message: t(e.message || 'Assigned Target Fetching Failed'),
        })
      );
    }
  };
  const onRefreshClick = () => {
    handleFiltersSubmit(queryData.startDate, queryData.endDate);
  };

  useEffect(() => {
    const fetchTeamLeads = async () => {
      try {
        dispatch(updateSpinnerState(true));
        const teamLeads = await getWMSUsersList({
          storeName,
          status: 'active',
        });
        dispatch(updateSpinnerState(false));
        setAllTeamleads(teamLeads);
      } catch (error) {
        dispatch(updateSpinnerState(false));
        dispatch(
          updateToastInfo({
            show: true,
            type: 'danger',
            title: 'failed',
            message: t(error.message || 'Teamleads Fetching Failed'),
          })
        );
      }
    };
    fetchTeamLeads();
  }, []);

  const onRowSelect = (value, id) => {
    // first set this in the selectedItems Array in the redux store
    dispatch(setSelectedTargetListInStore({ id, value, pageName }));
  };
  const handleItemBulkUpdate = async (action) => {
    const selectedCategories = tableData
      ?.filter((order) => selectedTargetItems.indexOf(order.objectId) > -1)
      ?.map((order) => order.productCategory);
    const uniqueCategories = {};
    selectedCategories.forEach((categoryName) => {
      uniqueCategories[categoryName] = 1;
    });
    if (Object.keys(uniqueCategories)?.length > 1) {
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('Multiple Category Items'),
          message: t('Please choose products from a single category'),
        })
      );
      return;
    }
    setSelectedCategory(selectedCategories[0]);
    switch (action) {
      case 'reassign': {
        // if (Object.keys(teamLeadList)?.length === 0) {
        //   dispatch(updateSpinnerState(true));
        //   const teamLeads = await getWMSUsersList({
        //     storeName,
        //     status: 'active',
        //   });
        const categoryBasedLeads = returnLeadsBasedOnCategory(allTeamleads);
        // setTeamLeadList(categoryBasedLeads[selectedCategories[0]]);
        let list = categoryBasedLeads.hasOwnProperty(selectedCategories[0])
          ? categoryBasedLeads[selectedCategories[0]]
          : categoryBasedLeads['smallAppliances'];
        setTeamLeadList(list);
        setShowTargetChangeTeamLeadModal(true);
        // dispatch(updateSpinnerState(false));
        // }
        break;
      }
      case 'seen':
      case 'cancel': {
        if (selectedTargetItems?.length > 0) {
          dispatch(updateSpinnerState(true));
          const updateData = selectedTargetItems?.map((selectedObjectId) => {
            return {
              objectId: selectedObjectId,
              dataToChange: [
                {
                  status: action,
                },
              ],
            };
          });
          await updateWarehouseTargetData({
            items: updateData,
          });
          dispatch(
            markTargetItemsAsCancelled({
              selectedTargetItems,
              pageName,
            })
          );
          dispatch(updateSpinnerState(false));
          dispatch(
            updateToastInfo({
              show: true,
              type: 'success',
              title: t('Updated'),
              message: t(`Target Items Updated as ${action}`),
            })
          );
        } else {
          dispatch(updateSpinnerState(false));
          dispatch(
            updateToastInfo({
              show: true,
              type: 'danger',
              title: t('Select atleast one item to continue'),
              message: t(''),
            })
          );
        }
        break;
      }
      default: {
      }
    }
  };
  const onTeamLeadSelected = async (selectedOption) => {
    try {
      // get all selected targets, push this option for reassign
      dispatch(updateSpinnerState(true));
      const updateData = returnUpdatedWarehouseTargetInfo(
        selectedOption,
        selectedTargetItems
      );
      await updateWarehouseTargetData({
        items: updateData,
      });
      dispatch(
        updateWarehouseTargetItemsInStore({
          data: updateData,
          pageName,
        })
      );
      selectedTargetItems.forEach((id) =>
        dispatch(setSelectedTargetListInStore({ id, value: false, pageName }))
      );
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'success',
          title: t('Updated'),
          message: t('Target Items Updated'),
        })
      );
      setShowTargetChangeTeamLeadModal(false);
    } catch (e) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('Failed to reassign'),
          message: t(e?.message || 'Contact Support'),
        })
      );
    }
  };
  return (
    <>
      <ParcelStatusPage
        pageType={pageName}
        filterSubmit={handleFiltersSubmit}
        tableData={tableData || []}
        tableColumns={getWMSTargetTableColumns(onRowSelect)}
        handleRefresh={onRefreshClick}
        pageTitle={t(pageTitle)}
        breadCrumbItems={breadCrumbItems}
        pageSubTitle={t(pageSubTitle)}
        searchPlaceHolder={t('Search by Invoice No. in Database')}
        enableBulkActions={enableBulkActions && bulkActionOptions?.length > 0}
        bulkActionOptions={bulkActionOptions}
        hideBulkActions={bulkActionOptions?.length === 0}
        handleItemBulkUpdate={handleItemBulkUpdate}
      />
      <TargetChangeTeamLeadModal
        allocateTargetConfirm={onTeamLeadSelected}
        teamLeadList={teamLeadList}
        show={showTargetChangeTeamLeadModal}
        handleClose={() => {
          setShowTargetChangeTeamLeadModal(false);
        }}
      />
    </>
  );
};
export default WarehouseTargetPage;
