import React from 'react';
import { returnCustomerAddressString } from '../table';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@themesberg/react-bootstrap';

export const returnProductInfo = (cell, type, handMoreProductsInfo, row) => {
  const maxRows = 2;
  const visibleRows = cell.slice(0, maxRows);
  const remainingRows = cell.length - maxRows;

  return (
    <div className="d-block">
      {visibleRows.map((data, index) => (
        <div key={index}>
          {returnCustomerAddressString(data, null, 200, 30, 'top')}
        </div>
      ))}
      {remainingRows > 0 && (
        <div
          className="d-block fw-bold "
          style={
            row
              ? {
                  color: 'blue',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }
              : {}
          }
          onClick={() => row && handMoreProductsInfo(row.objectId)}
        >{`...${remainingRows} more ${type}`}</div>
      )}
    </div>
  );
};

export const returnAttachmentsInfo = (cell, row) => {
  if (!cell || cell.length === 0) {
    return <div>No attachments</div>;
  }

  return (
    <div className="d-block">
      {cell.map((attachment, index) => (
        <div key={index}>
          <a href={attachment?.url} target="_blank" rel="noopener noreferrer">
            {attachment?.filename ?? 'Unnamed attachment'}
          </a>
        </div>
      ))}
    </div>
  );
};

export const viewProductInfo = ({ cell, handleMoreProductsClick, row }) => {
  const maxRows = 2;
  const visibleRows = cell.slice(0, maxRows);

  return (
    <div className="d-block">
      {visibleRows.map((data, index) => (
        <div key={index}>
          {returnCustomerAddressString(data.productName, null, 200, 30, 'top')}
        </div>
      ))}

      <Button
        size="sm"
        variant="light"
        onClick={() => {
          handleMoreProductsClick(row?.objectId);
        }}
      >
        View Product Details &nbsp;&nbsp;
        <FontAwesomeIcon icon={faChevronRight} />
      </Button>
    </div>
  );
};
