import { Button, Modal } from '@themesberg/react-bootstrap';
import React, { useEffect, useState } from 'react';
import { generateRandomId } from '../../../../utils/string';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faStopwatch } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import DropdownComponent from '../../../../components/common/Dropdown';
import { returnCompaniesDropdownList } from '../../../../utils/products';
import useGetCompanyAndCategory from '../../../scheme-management/custom-hooks/useGetCompanyAndCategory';
import { updateSpinnerState } from '../../../../actions/spinner';
import { updateToastInfo } from '../../../../actions/settings';
import { apiCall } from '../../../../middlewares/api';
import { apiCallConsts } from '../../../../constants/apiCallConsts';

const ManualSyncModal = ({
  showModal,
  closeModal,
  data,
  selectedType,
  setSelectedType,
  selectedBrands,
  setSelectedBrands,
  setIsApiCalled,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const companies = useSelector((state) => state?.products?.companies || {});

  useGetCompanyAndCategory();

  const handleApiCall = async (type) => {
    if (
      type === 'full' &&
      data?.updateType === 'Full Manual Update' &&
      data?.status === 'started'
    ) {
      return;
    }
    try {
      const allBrands = [
        ...new Set(
          Object.values(companies)
            .filter((brand) => brand.name !== '')
            .map((brand) => brand.name)
        ),
      ];
      let data;
      if (type === 'full') {
        data = { brand: allBrands, isFullSync: true };
      } else {
        data = { brand: [...new Set(selectedBrands)], isFullSync: false };
      }
      dispatch(updateSpinnerState(true));
      const response = await apiCall(
        apiCallConsts.POST_METHOD,
        '/internal/inventory/brandUpdate',
        data
      );
      setIsApiCalled(true);
      dispatch(updateSpinnerState(false));
    } catch (error) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: 'failed',
          message: error.message,
        })
      );
    }
  };

  const handleClose = () => {
    closeModal();
  };

  return (
    <Modal
      id={generateRandomId()}
      as={Modal.Dialog}
      centered
      show={showModal}
      onHide={handleClose}
      size="lg"
    >
      <Modal.Body className="m-1 p-4">
        {selectedType === null && (
          <>
            <div className="d-flex flex-column gap-3">
              <div className="d-flex justify-content-between align-content-center mb-4">
                <div style={{ marginLeft: '35%' }} className="fs-4">
                  {t('Sync Inventory Data')}
                </div>{' '}
                <Button
                  variant="close"
                  aria-label="Close"
                  onClick={closeModal}
                />
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center gap-3">
                <div>
                  {t(
                    'Please select the type of inventory sync you would like to perform'
                  )}
                </div>
                <div className="d-flex gap-5">
                  <Button
                    variant="light"
                    onClick={() => {
                      setSelectedType('fullSync');
                      handleApiCall('full');
                    }}
                  >
                    {t('Full Inventory Sync')}
                  </Button>
                  <Button
                    variant="light"
                    onClick={() => setSelectedType('brandSync')}
                  >
                    {t('Brand-Specific Sync')}
                  </Button>
                </div>
              </div>
            </div>
          </>
        )}

        {selectedType === 'fullSync' && (
          <div className="d-flex flex-column justify-content-center align-content-center text-center gap-3">
            <FontAwesomeIcon size="xl" icon={faStopwatch} />
            <div className="fs-4">
              {t(
                `${
                  selectedBrands.length > 0
                    ? 'Brand Specific'
                    : 'Full Inventory Sync'
                } In Progress`
              )}
            </div>
            <div className="fs-5">
              {t(
                'We’ve received your request and will process it within the next 10 mins.'
              )}
            </div>
          </div>
        )}
        {selectedType === 'brandSync' && (
          <div className="mx-5">
            <div className="d-flex justify-content-end">
              <Button
                variant="close"
                aria-label="Close"
                onClick={handleClose}
              />
            </div>
            <div className="d-flex flex-column justify-content-center align-content-center text-center gap-3">
              <div className="fs-4">{t('Select a Brand for Sync')}</div>
              <div className="fs-5">
                {t(
                  'Please choose a brand from the list below to sync its inventory data.'
                )}
              </div>
              <div>
                <DropdownComponent
                  onChange={(option) => {
                    setSelectedBrands(option.map((o) => o.value));
                  }}
                  width={'100%'}
                  isMultiSelect={true}
                  disabled={false}
                  options={returnCompaniesDropdownList(companies)}
                  placeholder={t('Choose a Brand')}
                />
              </div>
              <div className="d-flex justify-content-end">
                <Button
                  variant="primary"
                  onClick={() => {
                    setSelectedType('fullSync');
                    handleApiCall('partial');
                  }}
                >
                  <FontAwesomeIcon icon={faDownload} />
                  &nbsp;&nbsp;{t('Fetch Data')}
                </Button>
              </div>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ManualSyncModal;
