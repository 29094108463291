import React, { useState } from "react";
import Datetime from "react-datetime";
import { faCalendarAlt, faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Form,
  InputGroup,
  Row,
  Col,
  Button,
} from "@themesberg/react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  returnEndOfDay,
  returnStartOfDay,
  returnUserReadableDate,
} from "../../utils/datetime";

const DateRangeSelector = (props) => {
  const {
    filtersValue = {},
    onSubmit,
    showSubmit = true,
    onChangeHandler,
  } = props;
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState(
    filtersValue.startDate
      ? new Date(filtersValue.startDate)
      : returnStartOfDay(new Date())
  );
  const [endDate, setEndDate] = useState(
    filtersValue.endDate
      ? new Date(filtersValue.endDate)
      : returnEndOfDay(new Date())
  );
  const [formErrors, setFormErrors] = useState({});
  const handleSubmit = () => {
    const formErrors = {
      startDate: startDate ? "" : t("Please Select Start Date"),
      endDate: endDate ? "" : t("Please Select End Date"),
    };
    if (startDate > endDate) {
      formErrors.endDate = t("End Date cant be less than Start Date");
    }
    setFormErrors(formErrors);
    if (
      formErrors?.startDate?.length === 0 &&
      formErrors?.endDate?.length === 0
    ) {
      // if we reached here, that means form is fine. pass this value to the parent
      onSubmit(startDate, endDate);
    }
  };

  const triggerOnChangeHandler = () => {
    if (startDate > endDate) {
      formErrors.endDate = t("End Date cant be less than Start Date");
      setFormErrors(formErrors);
      return;
    }
    if (onChangeHandler) {
      onChangeHandler(startDate, endDate);
    }
  };
  return (
    <Form.Group className="mb-3 w-100">
      <Row className="d-flex justify-content-end">
        <Col xs={5} md={4}>
          <Datetime
            timeFormat={false}
            closeOnSelect={true}
            onChange={(startDate) => {
              setStartDate(returnStartOfDay(startDate));
              triggerOnChangeHandler();
            }}
            renderInput={(props, openCalendar) => (
              <InputGroup>
                <InputGroup.Text>
                  <FontAwesomeIcon
                    icon={faCalendarAlt}
                    className="icon icon-xs"
                  />
                </InputGroup.Text>
                <Form.Control
                  isInvalid={formErrors["startDate"]?.length > 0}
                  required
                  type="text"
                  value={returnUserReadableDate(startDate)}
                  placeholder="Select start: dd/mm/yyyy"
                  onFocus={openCalendar}
                />
                {formErrors["startDate"]?.length > 0 && (
                  <Form.Control.Feedback type="invalid">
                    {formErrors["startDate"]}
                  </Form.Control.Feedback>
                )}
              </InputGroup>
            )}
          />
        </Col>
        <Col xs={5} md={4}>
          <Datetime
            timeFormat={false}
            closeOnSelect={true}
            onChange={(endDate) => {
              setEndDate(returnEndOfDay(endDate));
              triggerOnChangeHandler();
            }}
            renderInput={(props, openCalendar) => (
              <InputGroup>
                <InputGroup.Text>
                  <FontAwesomeIcon
                    icon={faCalendarAlt}
                    className="icon icon-xs"
                  />
                </InputGroup.Text>
                <Form.Control
                  isInvalid={formErrors["endDate"]?.length > 0}
                  required
                  type="text"
                  value={returnUserReadableDate(endDate)}
                  placeholder="Select end: dd/mm/yyyy"
                  onFocus={openCalendar}
                />
                {formErrors["endDate"]?.length > 0 && (
                  <Form.Control.Feedback type="invalid">
                    {formErrors["endDate"]}
                  </Form.Control.Feedback>
                )}
              </InputGroup>
            )}
          />
        </Col>
        {showSubmit && (
          <Col xs={5} md={4}>
            <Button variant="primary" onClick={handleSubmit}>
              <FontAwesomeIcon icon={faFilter} />
              &nbsp;&nbsp; {t("Filter")}
            </Button>
          </Col>
        )}
      </Row>
    </Form.Group>
  );
};
export default DateRangeSelector;
