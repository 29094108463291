import React from 'react';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import Table from 'react-bootstrap-table-next';
import { Card } from '@themesberg/react-bootstrap';
import EmptyTable from '../../../../components/common/EmptyTableView';
import { generateRandomId } from '../../../../utils/string';

const NoPaginationTable = (props) => {
  const { data, columns, handleRowClick } = props;
  return (
    <ToolkitProvider
      exportCSV
      keyField="id"
      sort
      columns={columns}
      data={data || []}
      wrapperClasses="table-responsive"
    >
      {({ baseProps }) => (
        <Card>
          <div className="table-responsive py-2">
            <Table
              id={generateRandomId()}
              noDataIndication={<EmptyTable />}
              bodyClasses="border-0"
              classes="table-flush dataTable-table"
              {...baseProps}
              {...(handleRowClick && {
                rowEvents: {
                  onClick: (e, row, index) => {
                    handleRowClick(row);
                  },
                },
              })}
            />
          </div>
        </Card>
      )}
    </ToolkitProvider>
  );
};

export default NoPaginationTable;
