import React, { useState } from 'react';
import { generateRandomId } from '../../../utils/string';
import { Button, Form, Modal } from '@themesberg/react-bootstrap';
import { useTranslation } from 'react-i18next';
import { isValidVehicleNumber } from '../../../utils/vehicles';
import { getVehicleDetailsByNumber } from '../../../parse-functions/vehicles';

const EntryModal = (props) => {
  const { showModal, closeModal, handleSubmit, zone } = props;
  const { t } = useTranslation();
  const [modalValue, setModalValue] = useState({ vehicleNo: '' });
  const [errors, setErrors] = useState({});
  const [vehicleData, setVehicleData] = useState({});
  const [driverDetails, setDriverDetails] = useState({});

  const handleChange = (event) => {
    setModalValue({ vehicleNo: event?.target?.value });
    setErrors({});
  };

  const handleSubmitClick = async (e) => {
    e.preventDefault();
    const fieldName = Object.keys(modalValue)[0];
    let value = modalValue[fieldName];
    if (value !== 0 && !modalValue[fieldName]) {
      setErrors({ [fieldName]: 'please enter vehicle No' });
      return;
    }

    try {
      isValidVehicleNumber(value);
    } catch (error) {
      setErrors({ [fieldName]: 'Vehicle Number format is incorrect' });
      return;
    }
    const data = { vehicleNumber: value, zone };

    try {
      const res = await getVehicleDetailsByNumber(data);
      setDriverDetails({
        driverName: res?.driverName,
        contactNumber: res?.contactNumber,
      });
      setVehicleData(res);
    } catch (error) {
      setVehicleData({});
      setErrors({ [fieldName]: 'Enter Valid Vehicle Number' });
      return;
    }

    setErrors({});
    setModalValue({ [fieldName]: '' });
  };

  const handleSave = (e) => {
    e.preventDefault();
    if (driverDetails?.driverName.length === 0) {
      setErrors((prev) => {
        return {
          ...prev,
          driverName: 'Value must not be empty',
        };
      });
    }
    if (!driverDetails?.contactNumber) {
      setErrors((prev) => {
        return {
          ...prev,
          contactNumber: 'Value must not be empty',
        };
      });
      return;
    }
    handleSubmit({ ...vehicleData, ...driverDetails });
    setVehicleData({});
    setDriverDetails({});
  };

  return (
    <>
      <Modal
        id={generateRandomId()}
        as={Modal.Dialog}
        centered
        show={showModal}
        onHide={closeModal}
        size="lg"
      >
        <Modal.Header>
          <Modal.Title className="h6">{t('Registration Entry')}</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={closeModal} />
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmitClick}>
            <Form.Group className="mb-3">
              <Form.Label>{t('Enter Vehicle No')}</Form.Label>
              <div className="d-flex gap-5">
                <Form.Control
                  required
                  type="text"
                  isInvalid={!!errors[Object.keys(modalValue)[0]]}
                  onChange={handleChange}
                />
                <Button variant="primary" type="submit">
                  {t('Submit')}
                </Button>
              </div>
              <Form.Control.Feedback
                type="invalid"
                style={{ display: 'block' }}
              >
                {errors[Object.keys(modalValue)[0]]}
              </Form.Control.Feedback>
            </Form.Group>
          </Form>

          {Object.keys(vehicleData).length > 0 && (
            <>
              <div className="d-flex justify-content-evenly">
                <div>
                  Vendor Name : <br />
                  <span className="fw-bold"> {vehicleData?.vendorName}</span>
                </div>
                <div>
                  Vehicle Type : <br />
                  <span className="fw-bold">{vehicleData?.vehicleType}</span>
                </div>
              </div>
              <Form.Group>
                {t(`Driver Name : `)}
                <Form.Control
                  value={driverDetails?.driverName}
                  isInvalid={!!errors['driverName']}
                  onChange={(e) => {
                    const { value } = e.target;
                    setDriverDetails((prev) => {
                      return {
                        ...prev,
                        driverName: value,
                      };
                    });
                    setErrors((prev) => {
                      return {
                        ...prev,
                        driverName: null,
                      };
                    });
                  }}
                  type={'text'}
                />
                <Form.Control.Feedback type="invalid">
                  {errors['driverName']}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                {t(`Phone Number : `)}
                <Form.Control
                  value={driverDetails?.contactNumber}
                  isInvalid={!!errors['contactNumber']}
                  onChange={(e) => {
                    const { value } = e.target;
                    setDriverDetails((prev) => {
                      return {
                        ...prev,
                        contactNumber: value,
                      };
                    });
                    setErrors((prev) => {
                      return {
                        ...prev,
                        contactNumber: null,
                      };
                    });
                  }}
                  type={'number'}
                />
                <Form.Control.Feedback type="invalid">
                  {errors['contactNumber']}
                </Form.Control.Feedback>
              </Form.Group>
            </>
          )}
        </Modal.Body>

        <Modal.Footer>
          {Object.keys(vehicleData).length > 0 && (
            <Button variant="primary" type="submit" onClick={handleSave}>
              {t('Update/Register Entry')}
            </Button>
          )}
          <Button
            variant="link"
            className="text-gray ms-auto"
            onClick={closeModal}
          >
            {t('Cancel')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EntryModal;
