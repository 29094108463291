import Parse from "parse";

export const generateEWBFromData = (data) => {
    return Parse.Cloud.run("generateEWBFromData", data);
}

export const getEWBInfoByTripNumber = (tripNumber) => {
    return Parse.Cloud.run("getEWBInfoByTripNumber", {
        tripNumber
    })
}

export const generateConsolidatedEWB = (data) => {
    return Parse.Cloud.run("generateConsolidatedEWB", data);
}

export const createEWBForFailedItems = (data) => {
    return Parse.Cloud.run("createEWBForFailedItems", data)
}
export const getConsolidatedEWBInfoById = (data) => {
    return Parse.Cloud.run("getConsolidatedEWBInfoById", data);
}