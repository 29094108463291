import { Badge } from "@themesberg/react-bootstrap";
import React from "react";
import { getTripBadgeStatus } from "../../utils/trips";
import { orderStatusMap } from "../../utils/constants";
import { getTableRowCheckBox } from "../../utils/table";

export const returnVehicleListTableColumns = (onRowSelect) => {
    return ([
        {
            dataField: "objectId", text: "Select", formatter: (cell, row) => {
                return getTableRowCheckBox(onRowSelect, cell, row?.isSelected, false)
            }
        },
        {
            dataField: "vehicleNumber", sort: true, text: "Vehicle Info", formatter: (cell, row) => {
                return (
                    <div className="d-block">
                        <span className="fw-bold">{cell}</span>
                        {/* <div className="fw-normal">{row?.vendorCode}</div> */}
                    </div>
                )
            }
        },
        {
            dataField: "driverName", text: "Driver Info", formatter: (cell, row) => {
                return (
                    <div className="d-block">
                        <span className="fw-bold">{cell}</span>
                        <div className="fw-normal">{row?.contactNumber}</div>
                    </div>
                )
            }
        },
        {
            dataField: "vendorName", text: "Vendor Name", formatter: (cell, row) => {
                return (
                    <div className="d-block">
                        <span className="fw-bold">{cell}</span>
                        <div className="fw-normal">{row?.vehicleType}</div>
                    </div>
                )
            }
        },{
            dataField: "paymentMode", text: "Payment Mode", formatter: (cell, row) => {
                return (
                    <div className="d-block">
                        <span className="fw-bold">{cell ? orderStatusMap[cell]: ""}</span>
                    </div>
                )
            }
        },
        {
            dataField: "status", text: "Status", formatter: (cell, row) => {
                return (
                    <div className="d-block">
                        <div className="fw-bold">
                            <span>&nbsp;&nbsp;<Badge bg={getTripBadgeStatus(cell)} className="badge-lg">{cell}</Badge> &nbsp;&nbsp;</span>
                        </div>
                        <span className="fw-normal">{row?.tripInfo?.vehicleNumber}</span>
                    </div>
                )
            }
        }
    ])
}

export const returnVehiclePaymentModeOptions = () => {
    return [{
        label: "Monthly Fixed",
        value: "monthlyFixed"
    },
    {
        value: 'fixedPerDelivery',
        label: 'Fixed Per Delivery'
    },
    {
        value: 'extraVehicle',
        label: 'Extra Vehicle'
    },
    {
        value: 'extraPerDelivery',
        label: 'Extra Per Delivery'
    }]
}