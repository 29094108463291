import React from 'react';
import AsyncSelect from 'react-select/async';
import { useTranslation } from 'react-i18next';
import { customDropDownStyles } from './common/Dropdown';

const AsyncDropdown = (props) => {
  const { t } = useTranslation();
  const {
    onChange,
    placeholder = 'Select an Option',
    isMultiSelect = false,
    disabled = false,
    width,
    fetchOptions,
    isLoading,
  } = props;

  const loadOptions = async (inputValue, callback) => {
    try {
      const response = await fetchOptions(inputValue);
      callback(response);
    } catch (error) {
      console.error('Error loading options:', error);
      callback([]);
    }
  };

  return (
    <AsyncSelect
      isDisabled={disabled}
      onChange={onChange}
      cacheOptions
      loadOptions={loadOptions}
      defaultOptions
      placeholder={t(placeholder)}
      styles={customDropDownStyles(width)}
      isMulti={isMultiSelect}
      isLoading={isLoading}
    />
  );
};

export default AsyncDropdown;
