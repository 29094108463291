import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CollapsableNavItem from '../sidebar/collapsibleNavItem';
import NavItem from '../sidebar/NavItem';

const RenderNavItems = ({ config }) => {
  const { t } = useTranslation();
  const isAdmin = useSelector(
    (state) => state?.user?.preferences?.isAdmin || false
  );
  const permissions = useSelector((state) => state?.user?.permissions || {});
  const roles = useSelector((state) => state?.user?.preferences?.roles || []);

  const hasPermission = (link) => {
    if (isAdmin || roles.includes('admin') || roles.includes('superAdmin')) {
      return true;
    }
    return permissions.hasOwnProperty(link);
  };

  return Object.keys(config).map((key) => {
    const item = config[key];
    if (item.children) {
      const children = item.children.filter((child) =>
        hasPermission(child.link)
      );
      if (children.length > 0) {
        return (
          <CollapsableNavItem
            key={key}
            eventKey={item.eventKey}
            title={t(item.title)}
            icon={item.icon}
            badgeText={item.badgeText}
          >
            {children.map((child) => (
              <NavItem
                key={child.title}
                title={t(child.title)}
                link={child.link}
                {...(child.image
                  ? { image: child.image }
                  : { icon: child.icon })}
                badgeText={child.badgeText}
              />
            ))}
          </CollapsableNavItem>
        );
      }
    } else if (hasPermission(item.link)) {
      return (
        <NavItem
          key={key}
          title={t(item.title)}
          link={item.link}
          {...(item.image ? { image: item.image } : { icon: item.icon })}
        />
      );
    }
    return null;
  });
};

export default RenderNavItems;
