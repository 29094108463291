import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal } from "@themesberg/react-bootstrap";
import AddDeliveryAgent from "./AddDeliveryAgent";
import { generateRandomId } from "../../../utils/string";

const DeliveryAgentUpdateModal = (props) => {
  const { t } = useTranslation();
  const { showModal, closeModal, onConfirm, user } = props;
  return (
    <Modal
      id={generateRandomId()}
      size="lg"
      show={showModal}
      onHide={closeModal}
    >
      <Modal.Header>
        <Modal.Title className="h6">
          {t(`Update ${user?.name || "user details"}`)}
        </Modal.Title>
        <Button variant="close" aria-label="Close" onClick={closeModal} />
      </Modal.Header>
      <Modal.Body>
        <AddDeliveryAgent edit editData={user} handleEditSave={onConfirm} />
      </Modal.Body>
    </Modal>
  );
};
export default DeliveryAgentUpdateModal;
