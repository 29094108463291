import React from 'react';
import { Dropdown } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';

const ActionButtonWithIcons = ({ options, onActionClick }) => {
  return (
    <Dropdown>
      <Dropdown.Toggle
        id="dropdown-custom-components"
        variant="light"
        className="shadow-none rounded-0 py-0 px-1"
      >
        <FontAwesomeIcon icon={faEllipsisV} />
      </Dropdown.Toggle>

      <Dropdown.Menu align={'start'}>
        {options?.map((option) => (
          <Dropdown.Item
            key={option.label}
            onClick={() => onActionClick(option.label)}
          >
            <FontAwesomeIcon width={'1rem'} icon={option.icon} /> &nbsp;&nbsp;
            {option.label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ActionButtonWithIcons;
