import React from 'react';
import { Button, Modal } from '@themesberg/react-bootstrap';
import { generateRandomId } from '../../../../utils/string';
import { useTranslation } from 'react-i18next';
import TableOnly from './TableOnly';

const PurchaseDataModal = (props) => {
  const {
    showModal,
    closeModal,
    data,
    columns,
    modalTitle = `All Orders Data`,
    showRowBgColor = false,
  } = props;
  const { t } = useTranslation();
  return (
    <div>
      <>
        <Modal
          id={generateRandomId()}
          as={Modal.Dialog}
          centered
          show={showModal}
          onHide={closeModal}
          size="xl"
        >
          <Modal.Header>
            <Modal.Title className="h6">{t(modalTitle)}</Modal.Title>
            <Button variant="close" aria-label="Close" onClick={closeModal} />
          </Modal.Header>
          <Modal.Body>
            <TableOnly
              id="purchaseDataModal"
              data={data}
              columns={columns}
              showRowBgColor={showRowBgColor}
            />
          </Modal.Body>
        </Modal>
      </>
    </div>
  );
};

export default PurchaseDataModal;
