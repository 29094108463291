import React from "react";
import { Button, Table } from "@themesberg/react-bootstrap";

const PendingOrderTableSummary = (props) => {
    const {
        branchData = {},
        showBranchDetails
    } = props;
    return (
        <Table>
            <thead className="thead-light">
                <tr>
                    <th className="border-0">Branch Name</th>
                    <th className="border-0">Pending</th>
                    <th className="border-0">Failed</th>
                    <th className="border-0">Defective</th>
                    <th className="border-0"></th>
                </tr>
            </thead>
            <tbody className="border-0">
                {Object.keys(branchData)?.map(branchName => {
                    return (
                        <tr>
                            <td className="border-0">{branchName}</td>
                            <td className="border-0 fw-bold">{branchData?.[branchName]?.pending?.length}</td>
                            <td className="border-0">
                                <span className="fw-bold">{branchData?.[branchName]?.returnok?.length}</span>
                            </td>
                            <td className="border-0">
                                <span className="fw-bold">{branchData?.[branchName]?.defectivepicked?.length}</span>
                            </td>
                            <td className="border-0">
                                <Button
                                    onClick={() => {
                                        showBranchDetails(branchName)
                                    }}
                                    variant="outline-info"
                                    size="sm"
                                    className="m-1">{"Show Details"}</Button>
                            </td>
                        </tr>
                    )
                })}
            </tbody>
        </Table>
    )
}
export default PendingOrderTableSummary;