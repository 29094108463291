import React, { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { updateToastInfo } from "../../actions/settings";
import { updateSpinnerState } from "../../actions/spinner";
import ashokaLogo from "../../assets/img/ewb/ashoka-logo.png";
import gstLogo from "../../assets/img/ewb/gstlogo.png";
import { getConsolidatedEWBInfoById } from "../../parse-functions/ewaybill";
import { Modal } from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileInvoice } from "@fortawesome/free-solid-svg-icons";

const PrintEWayBill = () => {
    const { objectId } = useParams();
    const dispatch = useDispatch();
    const [ewbInfo, setEWBInfo] = useState({});
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const { t } = useTranslation();

    useEffect(() => {
        getEWBDataToPrint();
    }, []);

    const getEWBDataToPrint = async () => {
        dispatch(updateSpinnerState(true));
        try {
            // get the print info from the table for this parcel
            const ewbInfo = await getConsolidatedEWBInfoById({ objectId });
            setEWBInfo(ewbInfo);
            dispatch(updateSpinnerState(false));
            setPrintAttributesToPage();
        } catch (e) {
            dispatch(updateSpinnerState(false));
            setErrorMessage("Unable to Print EWB. Please retry");
            setShowErrorModal(true);
            dispatch(updateToastInfo({
                show: true,
                type: 'danger',
                title: 'Failed to get Updated EWB',
                message: t("Failed to get EWB data from server. Please try again or contact support")
            }));
        }
    }

    const setPrintAttributesToPage = () => {
        const css = '@page { size: portrait; margin: 0mm 0mm 0mm 0mm; font-size: 12px; color: #000000 !important; }';
        const head = document.head || document.getElementsByTagName('head')[0];
        const style = document.createElement('style');
        style.type = 'text/css';
        style.media = 'print';

        if (style.styleSheet) {
            style.styleSheet.cssText = css;
        } else {
            style.appendChild(document.createTextNode(css));
        }
        head.appendChild(style);
        window.print();
    }
    const returnVehicleNumber = () => {
        if (ewbInfo?.items?.length < 1) {
            return "-"
        }
        if (ewbInfo?.items?.[0]?.ewbInfo?.vehicleNo) {
            return ewbInfo?.items?.[0]?.ewbInfo?.vehicleNo
        }
        if (ewbInfo?.items?.length > 1 && ewbInfo?.items?.[1]?.ewbInfo?.vehicleNo) {
            return ewbInfo?.items?.[1]?.ewbInfo?.vehicleNo
        }
        return "-"
    }
    let itemNumberToPrint = 0;
    return (
        <>
            <div className="d-flex" style={{
                color: '#000000',
                fontSize: 12
            }}>
                <div className="position-relative w-100 p-4 p-md-5 card border-0">
                    <div className="d-flex row border-bottom border-dark pb-4 pb-md-4 mb-4 mb-md-5">
                        <div className="col-2 d-flex justify-content-start">
                            <img alt="Ashoka Logo" src={ashokaLogo} width="100" height="200" className="image-md" />
                        </div>
                        <div className="col-8 d-flex justify-content-start" style={{
                            flexDirection: "column",
                            alignItems: "center"
                        }}>
                            <div className="d-flex row">
                                <h4>E-WAY BILL SYSTEM</h4>
                            </div>
                            <div className="d-flex row">
                                <div className="simple-list list-group">
                                    <div
                                        className="fw-normal list-group-item"
                                        style={{
                                            paddingTop: 0,
                                            paddingBottom: 0
                                        }}>
                                        Consolidated E-Way Bill
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-2 d-flex justify-content-start">
                            <img alt="GST Logo" src={gstLogo} width="200" height="200" className="image-md" />
                        </div>
                    </div>
                    <div className="d-flex w-100 mb-4">
                        <div style={{
                            fontWeight: 700
                        }}>1. Consolidated E-Way Bill Details</div>
                    </div>
                    <div className="d-flex w-100 row">
                        <div className="col-6">
                            <div className="row">
                                <div className="col-8">Consolidated E-Way Bill No</div>
                                <div className="col-4">{ewbInfo?.info?.data?.cEwbNo}</div>
                            </div>
                            <div className="row">
                                <div className="col-8">Date</div>
                                <div className="col-4">{ewbInfo?.info?.data?.cEwbDate}</div>
                            </div>
                            <div className="row">
                                <div className="col-8">Vehicle No.</div>
                                <div className="col-4">{returnVehicleNumber()}</div>
                            </div>
                        </div>
                        <div className="col-6 d-flex justify-content-end">
                            <QRCode
                                size={256}
                                style={{
                                    height: "auto",
                                    maxWidth: "25%",
                                    width: "25%"
                                }}
                                viewBox={`0 0 256 256`}
                                value={`${ewbInfo?.info?.data?.cEwbNo}/${ewbInfo?.info?.data?.cEwbDate}`} />

                        </div>
                    </div>
                    <div className="d-flex w-100 row">
                        <div className="col-4">From</div>
                        <div className="col-8">{ewbInfo?.info?.warehouseInfo?.address || "-"}</div>
                    </div>
                    <div className="d-flex w-100 row">
                        <div className="col-4">Mode</div>
                        <div className="col-8">Road</div>
                    </div>
                    <div className="border-bottom border-dark pb-4 pb-md-4 mb-4 mb-md-5" />
                    <div className="d-flex w-100 mb-4">
                        <div style={{
                            fontWeight: 700
                        }}>2. Item Details</div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="table-responsive">
                                <table className="mb-0 table">
                                    <thead className="bg-light border-top">
                                        <tr>
                                            <th className="border-0">S No.</th>
                                            <th className="border-0">EWB No. & Date</th>
                                            <th className="border-0">Doc No & Date</th>
                                            <th className="border-0">Value</th>
                                            <th className="border-0">To</th>
                                            <th className="border-0">Valid Till Date</th>
                                        </tr>
                                    </thead>
                                    <tbody className="border-0">
                                        {ewbInfo.items?.map((ewbItem) => {
                                            if (ewbItem.invoiceNo === "consolidated") {
                                                return null
                                            }
                                            if (!ewbItem?.data?.ewayBillNo) {
                                                return null;
                                            }
                                            itemNumberToPrint += 1;
                                            return (
                                                <tr>
                                                    <td className="border-0">
                                                        <div className="d-block">
                                                            <span className="fw-normal">{itemNumberToPrint}</span>
                                                        </div>
                                                    </td>
                                                    <td className="border-0">
                                                        <div className="d-block">
                                                            <span className="fw-bold">{ewbItem?.data?.ewayBillNo || "-"}</span><br />
                                                            <span className="fw-normal">{ewbItem?.data?.ewayBillDate || "-"}</span>
                                                        </div>
                                                    </td>
                                                    <td className="border-0">
                                                        <div className="d-block">
                                                            <span className="fw-bold">{ewbItem?.invoiceNo || "-"}</span><br />
                                                            <span className="fw-normal">{ewbItem?.ewbInfo?.docDate || "-"}</span>
                                                        </div>
                                                    </td>
                                                    <td className="border-0">
                                                        <span className="fw-bold">{ewbItem?.ewbInfo?.totInvValue || "-"}</span>
                                                    </td>
                                                    <td className="border-0">
                                                        <div className="d-block">
                                                            <span className="fw-bold">{ewbItem?.ewbInfo?.toPlace || "-"}</span><br />
                                                            <span className="fw-normal">{ewbItem?.ewbInfo?.toPincode || "-"}</span>
                                                        </div>
                                                    </td>
                                                    <td className="border-0">{ewbItem?.data?.validUpto || "-"}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal centered show={showErrorModal} className="modal-tertiary order-already-scheduled-modal">
                <Modal.Body className="pt-0">
                    <div className="py-3 px-5 text-center">
                        <span className="modal-icon display-1">
                            <FontAwesomeIcon icon={faFileInvoice} className="icon icon-xl text-white" />
                        </span>

                        <Modal.Title className="text-white mb-3">
                            <h3>{errorMessage}</h3>
                        </Modal.Title>
                        <p className="mb-4 text-white">
                        </p>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default PrintEWayBill;