import { Button, Col, Form, InputGroup, Row } from "@themesberg/react-bootstrap";
import React, { useState } from "react";
import BreadCrumbComponent from "../../../components/common/Breadcrumb";
import { useTranslation } from "react-i18next";
import Datetime from "react-datetime";
import { Routes } from "../../../router/routes";
import { useDispatch, useSelector } from "react-redux";
import { updateSpinnerState } from "../../../actions/spinner";
import { getTripInfoByTemposheetNumber, updateTemposheetDataForTrip } from "../../../parse-functions/trips";
import { updateToastInfo } from "../../../actions/settings";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faClock, faEdit, faPeopleCarryBox, faSave, faTruck } from "@fortawesome/free-solid-svg-icons";
import { returnUserReadableDate } from "../../../utils/datetime";
import { getVehiclesListInZone } from "../../../parse-functions/vehicles";
import { setVehiclesListInStore } from "../../../actions/drivers";
import { convertArrayToObj } from "../../../utils/json";
import DropdownComponent from "../../../components/common/Dropdown";
import { returnVehicleDropdownOptions } from "../../../utils/vehicles";

const UpdateTemposheetDateAndVehicle = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [temposheetNumber, setTemposheetNumber] = useState("");
    const [showFormFields, setShowFormFields] = useState(false);
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [tripData, setTripData] = useState({});
    const vehiclesList = useSelector((state) => state?.drivers?.driversList || {});
    const zone = useSelector((state) => state?.user?.warehouse?.zone || "");
    const [updateMode, setUpdateMode] = useState(null);
    const [newVehicleDetails, setNewVehicleDetails] = useState({});
    const [newInvoiceDate, setNewInvoiceDate] = useState(null);
    const [formErrors, setFormErrors] = useState({});
    const isAdmin = useSelector((state) => state?.user?.preferences?.isAdmin || false);

    const breadCrumbItems = [{
        title: t("Admin"),
        link: Routes.Admin.Temposheet.path
    }, {
        title: t("Temposheet Update")
    }];
    const handleGetTemposheetInformation = async () => {
        try {
            dispatch(updateSpinnerState(true));
            const data = await getTripInfoByTemposheetNumber(temposheetNumber, false);
            const {
                tripInfo
            } = data;
            setTripData(tripInfo);
            console.log(tripInfo);
            dispatch(updateSpinnerState(false));
            setShowFormFields(true);
            setDisableSubmit(true);
        } catch (e) {
            dispatch(updateSpinnerState(false));
            dispatch(updateToastInfo({
                show: true,
                type: "danger",
                message: e?.error || "Please contact support if this issue persists",
                title: "Unable to get TS info"
            }))
        }
    };
    const handleAllowChangeVehicleNumber = async () => {
        try {
            // if there are no vehicles list available, fetch them first
            if (Object.keys(vehiclesList)?.length === 0) {
                dispatch(updateSpinnerState(true));
                const filters = {
                    zone,
                    status: "active",
                    getAllVehicles: isAdmin
                };
                const vehicles = await getVehiclesListInZone(filters);
                dispatch(setVehiclesListInStore(convertArrayToObj(JSON.parse(JSON.stringify(vehicles)), "objectId")))
                dispatch(updateSpinnerState(false));
            }
            // now allow showing the interface for the 
            setUpdateMode("vehicleNumber");
        } catch (e) {
            dispatch(updateSpinnerState(false));
            dispatch(updateToastInfo({
                show: true,
                type: "danger",
                title: t("Error fetching Temposheet list"),
                message: e.error || t("Please contact support if this issue persists")
            }))
        }
    };
    const handleTemposheetUpdate = async (data, mode) => {
        try {
            dispatch(updateSpinnerState(true));
            await updateTemposheetDataForTrip({
                data,
                mode,
                temposheetNumber
            })
            dispatch(updateSpinnerState(false));
            dispatch(updateToastInfo({
                show: true,
                type: "success",
                title: t("Temposheet Updated"),
                message: t("Temposheet details have been updated successfully")
            }))
        } catch (e) {
            dispatch(updateSpinnerState(false));
            dispatch(updateToastInfo({
                show: true,
                type: "danger",
                title: t("Error in updating temposheet"),
                message: t(e.message) || t("Please select an option from one of the given options")
            }))
        }
    }
    const handleNewInformationSubmit = () => {
        switch (updateMode) {
            case "vehicleNumber": {
                handleTemposheetUpdate(newVehicleDetails, "vehicleNumber");
                break;
            }
            case "temposheetDate": {
                handleTemposheetUpdate({
                    date: newInvoiceDate || new Date()
                }, "temposheetDate");
                break;
            }
            default: {
                dispatch(updateToastInfo({
                    show: true,
                    type: "danger",
                    title: t("No Option to update Selected"),
                    message: t("Please select an option from one of the given options")
                }))
                break;
            }
        }
    }
    if (!isAdmin) {
        return (
            <div>Unauthorized access</div>
        )
    }
    return (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-md-0">
                    <BreadCrumbComponent
                        items={breadCrumbItems}
                    />
                    <h4>{"Update Temposheet Info"}</h4>
                    <p className="mb-0">{"You can update Temposheet related info from here"}</p>
                </div>
            </div>
            <Form.Group className="mb-4">
                <Row>
                    <Col>
                        <Form.Control
                            disabled={showFormFields || disableSubmit}
                            required
                            type="text"
                            placeholder="Enter Temposheet Number"
                            onKeyDown={(event) => {
                                if (event.nativeEvent.code === "Enter") {
                                    handleGetTemposheetInformation();
                                }
                            }}
                            onChange={(event) => {
                                setTemposheetNumber(event?.target?.value);
                            }}
                        />
                    </Col>
                    <Col>
                        <Button disabled={showFormFields || disableSubmit} variant="primary" onClick={handleGetTemposheetInformation}>
                            <FontAwesomeIcon icon={faEdit} />&nbsp;&nbsp;{t("Get Temposheet Info")}
                        </Button>
                    </Col>
                </Row>
            </Form.Group>
            {(showFormFields && Object.keys(tripData)?.length > 0) && (
                <>
                    <Row className="mt-4 pt-4">
                        <Col>
                            <h4>Old Temposheet Info</h4>
                            <Row className="mt-2 mb-2 align-items-center">
                                <Col>Vehicle Number:&nbsp;&nbsp;{tripData?.vehicleNumber}</Col>
                                <Col>
                                    <Button
                                        onClick={handleAllowChangeVehicleNumber}
                                        className="d-block w-75"
                                        variant="primary">
                                        <FontAwesomeIcon
                                            icon={faTruck} />&nbsp;&nbsp;Change Vehicle Number
                                    </Button>
                                </Col>
                            </Row>
                            <Row className="mt-2 mb-2 align-items-center">
                                <Col>Allow Loader Info: &nbsp;&nbsp;{(tripData?.allowLoaderUpdate || false).toString().toUpperCase()}</Col>
                                <Col>
                                    <Button
                                        disabled={tripData?.allowLoaderUpdate}
                                        className="d-block w-75"
                                        variant="primary">
                                        <FontAwesomeIcon icon={faPeopleCarryBox} />&nbsp;&nbsp;Allow Loader Update
                                    </Button>
                                </Col>
                            </Row>
                            <Row className="mt-2 mb-2 align-items-center">
                                <Col>Temposheet Date:&nbsp;&nbsp;{tripData?.dispatchedAt ? returnUserReadableDate(tripData?.dispatchedAt) : "-"}</Col>
                                <Col>
                                    <Button
                                        onClick={() => {
                                            setUpdateMode("temposheetDate");
                                        }}
                                        className="d-block w-75"
                                        variant="primary">
                                        <FontAwesomeIcon icon={faClock} />&nbsp;&nbsp;Change Date
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                        <Col>

                            {(updateMode === "vehicleNumber") && (
                                <>
                                    <h4>New Temposheet Info</h4>
                                    <Row>
                                        <Form.Label>{t("New Vehicle Number")}</Form.Label>
                                        <DropdownComponent
                                            onChange={(option) => {
                                                setNewVehicleDetails(option.value);
                                            }}
                                            options={returnVehicleDropdownOptions(vehiclesList, [])}
                                            placeholder={t("Please Select a Vehicle")}
                                        />
                                    </Row>
                                </>
                            )}
                            {(updateMode === "temposheetDate") && (
                                <>
                                    <h4>New Temposheet Info</h4>
                                    <Form.Label>{t("Invoice Date")}</Form.Label>
                                    <Datetime
                                        timeFormat={false}
                                        closeOnSelect={true}
                                        onChange={(value) => {
                                            setNewInvoiceDate(new Date(value));
                                        }}
                                        renderInput={(props, openCalendar) => (
                                            <InputGroup>
                                                <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} className="icon icon-xs" /></InputGroup.Text>
                                                <Form.Control
                                                    // isInvalid={formErrors?.["invoiceDate"]?.length > 0}
                                                    required
                                                    type="text"
                                                    value={returnUserReadableDate(newInvoiceDate || new Date())}
                                                    placeholder="Select end: dd/mm/yyyy"
                                                    onFocus={openCalendar} />
                                                {formErrors?.["newInvoiceDate"]?.length > 0 && (
                                                    <Form.Control.Feedback type="invalid">{formErrors["newInvoiceDate"]}</Form.Control.Feedback>
                                                )}
                                            </InputGroup>
                                        )}
                                    />
                                </>
                            )}
                            {(updateMode) && (
                                <Button
                                className="mt-4"
                                    onClick={handleNewInformationSubmit}
                                    variant="primary">
                                    <FontAwesomeIcon icon={faSave} />&nbsp;&nbsp;Update New Info
                                </Button>
                            )}

                        </Col>
                    </Row>

                </>
            )}

        </>
    )
}
export default UpdateTemposheetDateAndVehicle