import React from "react";
import { isThisACOutdoor } from "../../utils/parcels";
import { getTableRowCheckBox, returnCustomerAddressString, returnParcelBranchInfo, returnParcelProductInformation, returnParcelStatusInfo, returnParcelVehicleTripInfo } from "../../utils/table";

export const modelChangePendingTableColumns = (onRowSelect) => {
    return ([
        {
            dataField: "objectId", text: "Select", formatter: (cell, row) => {
                const isDisabled = isThisACOutdoor(row);
                return getTableRowCheckBox(onRowSelect, cell, row?.isSelected, isDisabled)
            }
        },
        {
            dataField: "customerName", sort: true, text: "Customer Info", formatter: (cell, row) => {
                return returnCustomerAddressString(cell, row?.mobileNo, 200, 30);
            }
        },
        {
            dataField: "mobileNo", sort: true, hidden: true ,text: "Customer Contact Number", formatter: (cell, row) => {
                return <span className="fw-bold">{cell}</span>
            }
        },
        {
            dataField: "shippingAddress", sort: true, text: "Customer Address", formatter: (cell, row) => {
                const customerAddress = `${row?.shippingAddress1}${row?.shippingAddress2}${row?.shippingAddress3}`;
                return returnCustomerAddressString(customerAddress, row?.shippingPincode, 200, 30);
            }
        },
        {
            dataField: "invoiceNo", text: "Invoice Details", formatter: (cell, row) => {
                return (
                    <div className="d-block">
                        <span className="fw-bold">{cell}</span>
                        <div className="fw-normal">{new Date(row?.date).toLocaleDateString()}</div>
                    </div>
                )
            }
        },
        {
            dataField: "serialNo", text: "Product Info", formatter: (cell, row) => {
                return returnParcelProductInformation(row);
            }
        },
        {
            dataField: "status", text: "status", formatter: (cell, row) => {
                return returnParcelStatusInfo(row);
            }
        },{
            dataField: "closedBy", text: "Changed By", formatter: (cell, row) => {
                return <>{cell}</>;
            }
        },
        {
            dataField: "branch", text: "Branch Info", formatter: (cell, row) => {
                return returnParcelBranchInfo(row);
            }
        },
        {
            dataField: "tripInfo.vehicleNumber", text: "Trip Info", formatter: (cell, row) => {
                return returnParcelVehicleTripInfo(row);
            }
        }
    ])
}