import {
    RESET_STORE,
    SET_DMS_USERS_LIST_IN_STORE,
    SET_DMS_USER_LIST_AS_SELECTED
} from "../actions/types";

const INITIAL_STATE = {
    usersList: {}
};
const dmsUsersList = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_DMS_USERS_LIST_IN_STORE: {
            return {
                ...state,
                usersList: action.data
            }
        }
        case SET_DMS_USER_LIST_AS_SELECTED: {
            const {
                objectId,
                selected
            } = action.data;
            const dmsUsersList = JSON.parse(JSON.stringify(state.usersList));
            dmsUsersList[objectId] = {
                ...dmsUsersList[objectId],
                isSelected: selected
            }
            return {
                ...state,
                usersList: dmsUsersList
            }
        }
        case RESET_STORE: {
            return INITIAL_STATE
        }
        default: return state;
    }
};

export default dmsUsersList;