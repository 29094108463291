import React from "react";
import { getTableRowCheckBox, returnBuybackScanInformation, returnCustomerAddressString, returnLoaderInfoFromDispatchData, returnParcelProductInformation, returnParcelReturnReceivedInfo, returnParcelReturnScanInformation, returnParcelVehicleTripInfo, returnProductBuybackInformation, returnProductDispatchScanInformation, returntripClosingInfo } from "../../utils/table";
import { Badge, Button } from "@themesberg/react-bootstrap";
import { getTripBadgeStatus } from "../../utils/trips";
import { orderStatusMap } from "../../utils/constants";
import { returnUserReadableDateTime } from "../../utils/datetime";

export const returnPercentage = (items, total) => {
    if (!items || !total) {
        return 0
    }
    return Number((Number(items) / Number(total)) * 100).toFixed(2);
}
export const deliveryDataTableColumns = (onRowSelect, handleShowPODImagesModal) => {
    return ([
        {
            dataField: "objectId", text: "Select", formatter: (cell, row) => {
                if (row.dashboardStatus === "delivered" || (!row.dashboardStatus && row.mobileStatus === "delivered")) {
                    return getTableRowCheckBox(onRowSelect, cell, row?.isSelected, false)
                }
                return null;
            }
        },
        {
            dataField: "customerName", sort: true, text: "Customer Info", formatter: (cell, row) => {
                return (
                    <div>
                        <div>{returnCustomerAddressString(cell, row?.mobileNo, 100, 30)}</div>
                        <div>{row?.customerCode ? `Code : ${row?.customerCode}` : ""}</div>
                    </div>
                )
            }
        },
        {
            dataField: "customerAddress", sort: true, text: "Delivery Location", formatter: (cell, row) => {
                const customerAddress = `${row?.shippingAddress1 || ""} ${row?.shippingAddress2 || ""} ${row?.shippingAddress3 || ""}`;
                return returnCustomerAddressString(customerAddress, row.mobileNo || row.shippingMobileNo, 300, 35)
            }

        },
        {
            dataField: "invoiceNo", text: "Invoice Details", formatter: (cell, row) => {
                return (
                    <div className="d-block">
                        <span className="fw-bold">{cell}</span>
                        <div className="fw-normal">{new Date(row?.date).toLocaleDateString()}</div>
                        <div>{row?.challanNumber ? `Challan : ${row?.challanNumber}` : ""}</div>
                    </div>
                )
            }
        },
        {
            dataField: "serialNo", text: "Product Info", formatter: (cell, row) => {
                return returnParcelProductInformation(row);
            }
        },
        {
            dataField: "status", text: "Current Status", formatter: (cell, row) => {
                const status = row?.dashboardStatus || row?.mobileStatus || row?.status;
                const subStatus = row?.dashboardSubStatus || row?.mobileSubStatus;
                return (
                    <div className="d-block">
                        <div className="fw-normal">
                            <Badge bg={getTripBadgeStatus(status)} className="badge-lg">{orderStatusMap[status]}</Badge>&nbsp;{subStatus ? <Badge bg={getTripBadgeStatus(subStatus)} className="badge-lg">{orderStatusMap[subStatus]}</Badge> : ""}
                        </div>
                        <div className="fw-bold">{orderStatusMap[row?.failureReason]}</div>
                        <div className="fw-bold">{row?.remarks ? `Remarks: ${row?.remarks}` : ""}</div>
                        {(row?.priorityDelivery) && (
                            <><Badge bg={"success"} className="badge-lg">{"Priority Delivery"}</Badge></>
                        )}
                    </div>
                )
            }
        },
        {
            dataField: "Scan Info", text: "Scan Info", formatter: (cell, row) => {
                return returnProductDispatchScanInformation(row);
            }
        },
        {
            dataField: "branch", text: "Branch Info", formatter: (cell, row) => {
                return (
                    <div className="d-block">
                        <div className="fw-bold">{row?.branch}</div>
                        <div className="fw-bold">{row?.targetBranch ? `Target: ${row?.targetBranch}` : ""}</div>
                    </div>
                );
            }
        },
        {
            dataField: "podImages", text: "POD / Sign", formatter: (cell, row) => {
                let imgArray = [];
                if (row?.podImages?.length > 0) {
                    imgArray = [...imgArray, ...row?.podImages];
                }
                if (row?.customerSignature) {
                    imgArray = [...imgArray, row?.customerSignature];
                }
                return (<Button
                    disabled={!imgArray?.length}
                    onClick={() => {
                        handleShowPODImagesModal(imgArray, row?.parcelUpdateLocation);
                    }}
                    variant="outline-info"
                    size="sm"
                    className="m-1">{`${imgArray?.length || 0} Images`}</Button>)
            }
        },
        {
            dataField: "tripInfo.vehicleNumber", text: "Trip Info", formatter: (cell, row) => {
                return returnParcelVehicleTripInfo(row);
            }
        }, {
            dataField: "dispatchData.vehicleNumber", text: "Driver Info", formatter: (cell, row) => {
                return (
                    <div className="d-block">
                        <div className="fw-bold">
                            <span>{row?.dispatchData?.vehicleDetails?.driverName}</span>
                        </div>
                        <div>
                            <span className="fw-normal">{row?.dispatchData?.vehicleDetails?.mobileNumber || row?.dispatchData?.vehicleDetails?.contactNumber}</span>
                        </div>
                    </div>
                )
            }
        }, {
            dataField: "receivedScanInfo", text: "Return Info", formatter: (cell, row) => {
                return returnParcelReturnScanInformation(row);
            }
        }, {
            dataField: "buybackReceivedInfo", text: "BuyBack Received Info", formatter: (cell, row) => {
                return returnBuybackScanInformation(row);
            }
        },{
            dataField: "buybackInfo", text: "Buyback Info", formatter: (cell, row) => {
                return returnProductBuybackInformation(row);
            }
        }, {
            dataField: "parcelReturnReceiveScanInfo", text: "Received Info", formatter: (cell, row) => {
                return returnParcelReturnReceivedInfo(row);
            }
        }, {
            dataField: "dispatchData", text: "Loaders", formatter: (cell, row) => {
                return returnLoaderInfoFromDispatchData(row);
            }
        }
    ])
};

export const deliveryAnalysisTableColumns = (handleShowPODImagesModal) => {
    return ([
        {
            dataField: "customerName", sort: true, text: "Customer Info", formatter: (cell, row) => {
                return (
                    <div>
                        <div>{returnCustomerAddressString(cell, row?.mobileNo, 100, 30)}</div>
                        <div>{row?.customerCode ? `Code : ${row?.customerCode}` : ""}</div>
                    </div>
                )
            }
        },
        {
            dataField: "customerAddress", sort: true, text: "Delivery Location", formatter: (cell, row) => {
                const customerAddress = `${row?.shippingAddress1 || ""} ${row?.shippingAddress2 || ""} ${row?.shippingAddress3 || ""}`;
                return returnCustomerAddressString(customerAddress, row?.shippingPincode, 200, 35)
            }
        },
        {
            dataField: "invoiceNo", text: "Invoice Details", formatter: (cell, row) => {
                return (
                    <div className="d-block">
                        <span className="fw-bold">{cell}</span>
                        <div className="fw-normal">{new Date(row?.date).toLocaleDateString()}</div>
                        <div>{row?.challanNumber ? `Challan : ${row?.challanNumber}` : ""}</div>
                    </div>
                )
            }
        },
        {
            dataField: "serialNo", text: "Product Info", formatter: (cell, row) => {
                return returnParcelProductInformation(row)
            }
        },
        {
            dataField: "Scan Info", text: "Scan Info", formatter: (cell, row) => {
                return returnProductDispatchScanInformation(row);
            }
        },{
            dataField: "Delivered At", text: "Delivered At", formatter: (cell, row) => {
                return row?.parcelUpdatedAt ? returnUserReadableDateTime(row?.parcelUpdatedAt) : "-";
            }
        },
        {
            dataField: "status", text: "Current Status", formatter: (cell, row) => {
                const status = row?.dashboardStatus || row?.mobileStatus || row?.status;
                const subStatus = row?.dashboardSubStatus || row?.mobileSubStatus;
                return (
                    <div className="d-block">
                        <div className="fw-normal">
                            <Badge bg={getTripBadgeStatus(status)} className="badge-lg">{orderStatusMap[status]}</Badge>&nbsp;{subStatus ? `${orderStatusMap[subStatus]}` : ""}
                        </div>
                        <div className="fw-bold">{orderStatusMap[row?.failureReason]}</div>
                        <div className="fw-bold">{row?.remarks ? `Remarks: ${row?.remarks}` : ""}</div>
                        {(row?.priorityDelivery) && (
                            <><Badge bg={"success"} className="badge-lg">{"Priority Delivery"}</Badge></>
                        )}
                    </div>
                )
            }
        },
        {
            dataField: "status", text: "Branch Info", formatter: (cell, row) => {
                return (
                    <div className="d-block">
                        <div className="fw-bold">{row?.branch}</div>
                        <div className="fw-bold">{row?.targetBranch ? `Target: ${row?.targetBranch}` : ""}</div>
                    </div>
                );
            }
        },
        {
            dataField: "tripInfo.vehicleNumber", text: "Trip Info", formatter: (cell, row) => {
                return returnParcelVehicleTripInfo(row);
            }
        }, {
            dataField: "tripInfo.closedAt", text: "Trip Close", formatter: (cell, row) => {
                return returntripClosingInfo(row);
            }
        }, {
            dataField: "podImages", text: "POD / Sign", formatter: (cell, row) => {
                let imgArray = [];
                if (row?.podImages?.length > 0) {
                    imgArray = [...imgArray, ...row?.podImages];
                }
                if (row?.customerSignature) {
                    imgArray = [...imgArray, row?.customerSignature];
                }
                return (<Button
                    disabled={!imgArray?.length}
                    onClick={() => {
                        handleShowPODImagesModal(imgArray, row?.parcelUpdateLocation);
                    }}
                    variant="outline-info"
                    size="sm"
                    className="m-1">{`${imgArray?.length || 0} Images`}</Button>)
            }
        }, {
            dataField: "receivedScanInfo", text: "Return Info", formatter: (cell, row) => {
                return returnParcelReturnReceivedInfo(row);
            }
        }, {
            dataField: "buybackScanInfo", text: "Buyback Scan Info", formatter: (cell, row) => {
                return returnBuybackScanInformation(row);
            }
        }, {
            dataField: "returnParcelInfo", text: "Received Info", formatter: (cell, row) => {
                return returnParcelReturnScanInformation(row);
            }
        }, {
            dataField: "dispatchData", text: "Delivery Boys", formatter: (cell, row) => {
                return returnLoaderInfoFromDispatchData(row);
            }
        }
    ])
};

export const returnDeliveryExecutiveTableColumns = (handleShowPODImagesModal) => {
    return ([
        {
            dataField: "customerName", sort: true, text: "Customer Info", formatter: (cell, row) => {
                return (
                    <div>
                        <div>{returnCustomerAddressString(cell, row?.mobileNo, 100, 30)}</div>
                        <div>{row?.customerCode ? `Code : ${row?.customerCode}` : ""}</div>
                    </div>
                )
            }
        },
        {
            dataField: "customerAddress", sort: true, text: "Delivery Location", formatter: (cell, row) => {
                const customerAddress = `${row?.shippingAddress1 || ""} ${row?.shippingAddress2 || ""} ${row?.shippingAddress3 || ""}`;
                return returnCustomerAddressString(customerAddress, row?.shippingPincode, 200, 35)
            }
        },
        {
            dataField: "status", text: "Branch Info", formatter: (cell, row) => {
                return (
                    <div className="d-block">
                        <div className="fw-bold">{row?.branch}</div>
                        <div className="fw-bold">{row?.targetBranch ? `Target: ${row?.targetBranch}` : ""}</div>
                    </div>
                );
            }
        },
        {
            dataField: "deliveryWarehouse", text: "Delivery Warehouse"
        },
        {
            dataField: "invoiceNo", text: "Invoice Details", formatter: (cell, row) => {
                return (
                    <div className="d-block">
                        <span className="fw-bold">{cell}</span>
                        <div className="fw-normal">{new Date(row?.date).toLocaleDateString()}</div>
                        <div>{row?.challanNumber ? `Challan : ${row?.challanNumber}` : ""}</div>
                    </div>
                )
            }
        },
        {
            dataField: "serialNo", text: "Product Info", formatter: (cell, row) => {
                return returnParcelProductInformation(row)
            }
        },
        {
            dataField: "status", text: "Current Status", formatter: (cell, row) => {
                const status = row?.dashboardStatus || row?.mobileStatus || row?.status;
                const subStatus = row?.dashboardSubStatus || row?.mobileSubStatus;
                return (
                    <div className="d-block">
                        <div className="fw-normal">
                            <Badge bg={getTripBadgeStatus(status)} className="badge-lg">{orderStatusMap[status]}</Badge>&nbsp;{subStatus ? `${orderStatusMap[subStatus]}` : ""}
                        </div>
                        <div className="fw-bold">{orderStatusMap[row?.failureReason]}</div>
                        <div className="fw-bold">{row?.remarks ? `Remarks: ${row?.remarks}` : ""}</div>
                        {(row?.priorityDelivery) && (
                            <><Badge bg={"success"} className="badge-lg">{"Priority Delivery"}</Badge></>
                        )}
                    </div>
                )
            }
        }
    ])
};


