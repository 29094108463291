import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { updateSpinnerState } from '../../../actions/spinner';
import { apiCall } from '../../../middlewares/api';
import { apiCallConsts } from '../../../constants/apiCallConsts';
import NewTable from '../../../components/common/NewTable';
import {
  endlessAisleProcessingColumns,
  endlessOptions,
  endlessStatus,
} from './utils';
import { updateToastInfo } from '../../../actions/settings';
import DataModal from './components/DataModal';
import { useNavigate } from 'react-router-dom';
import ExcelDownloadReport from '../../../components/common/excel/download';
import { Button } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotateRight, faSliders } from '@fortawesome/free-solid-svg-icons';
import FilterOrders from '../orders/FIlterOrders';
import { orderStatus } from '../../../constants/orderStatus';

const EndlessPending = () => {
  const { t } = useTranslation();
  const type = 'Pending';
  const storeName = useSelector(
    (state) => state?.user?.preferences?.storeName || ''
  );
  const roles = useSelector((state) => state?.user?.preferences?.roles || []);
  const zone = useSelector((state) => state?.user?.preferences?.zone || '');
  const [ordersData, setOrdersData] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);
  const [showDataModal, setShowDataModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [confirmationModalProps, setConfirmationModalProps] = useState([]);
  const [selectedOrderId, setSelectedOrderId] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const messageDisplayer = (type, title, message) => {
    dispatch(
      updateToastInfo({
        show: true,
        type: type,
        title: t(title),
        message: t(message),
      })
    );
  };

  const fetchOrders = async () => {
    try {
      dispatch(updateSpinnerState(true));
      const res = await apiCall(
        apiCallConsts.GET_METHOD,
        `/internal/order?storeCode=${storeName}&status=${type}&region=${zone}&isEndlessAisle=true`
      );
      setOrdersData(res);
      dispatch(updateSpinnerState(false));
      messageDisplayer('success', '', 'Orders fetched successfully');
    } catch (error) {
      dispatch(updateSpinnerState(false));
      messageDisplayer('danger', 'Failed to fetch Orders', error.message);
    }
  };

  const onActionClick = (option, objectId) => {
    setSelectedOrderId(objectId);
    if (endlessOptions.poRaised === option) {
      setModalTitle(endlessOptions.poRaised);
      setConfirmationModalProps([
        {
          title: 'PO Number',
          type: 'text',
          value: '',
        },
        {
          title: 'PO Date',
          type: 'date',
          value: '',
        },
        {
          title: 'Vendor/Supplier Name',
          type: 'text',
          value: '',
        },
      ]);
      setShowDataModal(true);
    } else if (option === orderStatus.cancelled) {
      setModalTitle(orderStatus.cancelled);
      setConfirmationModalProps([
        {
          type: 'text',
          title: 'Remarks',
          value: '',
        },
      ]);
      setShowDataModal(true);
    } else {
      navigate(`/oms/orders/${objectId}`);
    }

    console.log(option, objectId);
  };

  const handleUpdate = async (data) => {
    try {
      dispatch(updateSpinnerState(true));
      await apiCall(apiCallConsts.PUT_METHOD, 'internal/order/update', data);
      messageDisplayer('success', '', 'order updated successfully!');
      await fetchOrders();
      setSelectedOrderId('');
      setShowDataModal(false);
      setConfirmationModalProps([]);
      dispatch(updateSpinnerState(false));
    } catch (error) {
      dispatch(updateSpinnerState(false));
      messageDisplayer('danger', '', error.message);
    }
  };

  const handleModalSubmit = () => {
    if (confirmationModalProps.some((each) => !each.value)) {
      messageDisplayer(
        'danger',
        '',
        'Please fill all details before submitting'
      );
      return;
    }
    const data = {
      id: [selectedOrderId],
      status: endlessOptions.poRaised,
    };
    switch (modalTitle) {
      case endlessOptions.poRaised:
        data.poNumber = confirmationModalProps[0].value;
        data.poDate = confirmationModalProps[1].value;
        data.poSupplierName = confirmationModalProps[2].value;
        data.status = endlessStatus.poRaised;
        break;
      case orderStatus.cancelled:
        data.remarks = confirmationModalProps[0].value;
        data.status = orderStatus.cancelled;
        break;
    }
    handleUpdate(data);
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  return (
    <>
      <div className="fw-medium fs-4 my-2">{t('Endless Aisle List')}</div>
      <div className="fw-medium fs-6 my-2">
        {t('Endless Aisle Pending Orders')}
      </div>
      <div className="d-flex mb-2 justify-content-end gap-3">
        <Button
          variant="white"
          size="md"
          className="btn-export"
          onClick={() => setOpenFilter(!openFilter)}
        >
          <FontAwesomeIcon icon={faSliders} />
          {/* <Icon
            inline={true}
            icon={'pepicons-print:sliders'}
            width={'1.25rem'}
          /> */}
          &nbsp;&nbsp;{t('Filter')}
        </Button>
        <Button
          variant="white"
          size="md"
          className="btn-export"
          onClick={fetchOrders}
        >
          <FontAwesomeIcon icon={faRotateRight} />
          {/* <InlineIcon
            icon={'lucide:rotate-cw'}
            width="1.25rem"
            height="1.25rem"
          /> */}
          &nbsp;&nbsp; {t('Refresh')}
        </Button>
        <ExcelDownloadReport
          data={ordersData || []}
          fileName="Endless Aisle orders"
        />
      </div>
      <FilterOrders
        data={ordersData}
        setData={setOrdersData}
        openFilter={openFilter}
      />
      <NewTable
        data={ordersData || []}
        columns={endlessAisleProcessingColumns(
          onActionClick,
          type,
          roles.includes('admin') || roles.includes('superAdmin')
        )}
      />

      {showDataModal && (
        <DataModal
          showModal={showDataModal}
          closeModal={() => setShowDataModal(false)}
          modalTitle={modalTitle}
          confirmationModalProps={confirmationModalProps}
          setConfirmationModalProps={setConfirmationModalProps}
          handleSubmit={handleModalSubmit}
        />
      )}
    </>
  );
};

export default EndlessPending;
