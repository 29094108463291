export const styles = {
  filters: {
    width: '371.24px',
    heigth: '78.09px',
  },

  filterTitle: {
    fontWeight: 500,
    fontSize: '17.96px',
    lineHeight: '21.05px',
  },

  filterInputs: {
    height: '45.91px',
    width: '371.24px',
    backgroundColor: '#EAEDF2;',
  },
  statsCard: {
    height: '100%',
    borderRadius: '8px',
    border: '1px solid #E5E7EB',
    backgroundColor: '#FFFFFF',
  },
  seperator: {
    width: '90%',
    border: '0.99px solid #EAEDF2',
    margin: '0px 12px',
  },
};
