import React from "react";
import { Button, Modal } from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

const UploadDisplayMissingBranchInfoModal = (props) => {
    const { t } = useTranslation();
    const {
        show,
        handleClose,
        buttonText,
        mainHeaderText,
        missingBranchNames
    } = props;
    return (
        <Modal centered show={show} onHide={handleClose} className="modal-tertiary order-already-scheduled-modal">
            <Modal.Body className="pt-0">
                <div className="py-3 px-5 text-center">
                    <span className="modal-icon display-1">
                        <FontAwesomeIcon icon={faWarning} className="icon icon-xl text-white" />
                    </span>

                    <Modal.Title className="text-white mb-3">
                        <h3>{mainHeaderText}</h3>
                    </Modal.Title>
                    <p className="mb-4 text-white">
                        {missingBranchNames?.map(branchName => {
                            return (
                                <div>
                                    <span>{branchName}</span>
                                </div>
                            )
                        })}
                    </p>
                    <p>{t("Please contact support to get this data mismatch resolved")}</p>
                </div>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center pt-2 pb-2">
                <Button variant="white" size="sm" className="text-tertiary" onClick={handleClose}>
                    {buttonText}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
export default UploadDisplayMissingBranchInfoModal;