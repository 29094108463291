import React, { useEffect, useState } from 'react';
import OrderListPage from '../../../components/common/OrderListPage';
import { useDispatch } from 'react-redux';
import { updateToastInfo } from '../../../actions/settings';
import { updateSpinnerState } from '../../../actions/spinner';
import { apiCall } from '../../../middlewares/api';
import { apiCallConsts } from '../../../constants/apiCallConsts';
import { orderStatus } from '../../../constants/orderStatus';
import { HyperLocalDeliveryColumns } from '../orders/utils';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import arrayToBreadCrumbs from '../../../utils/sms/arrayToBreadCrumbs';

const GetHyperLocalDelivery = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [ordersData, setOrdersData] = useState([]);
  const [selectedOrders, setSelectedOrders] = useState([]);

  const breadCrumbItems = arrayToBreadCrumbs([
    [t('Delivery Agent')],
    [t('HyperLocal Delivery')],
  ]);

  const messageDisplayer = (type, title, message) => {
    dispatch(
      updateToastInfo({
        show: true,
        type: type,
        title: t(title),
        message: t(message),
      })
    );
  };

  const onRowSelect = (selected, objectId) => {
    if (selected) {
      setSelectedOrders((prev) => {
        const selectedItem = ordersData.find(
          (item) => item.objectId === objectId
        );
        return [...prev, selectedItem];
      });
    } else {
      setSelectedOrders((prev) =>
        prev.filter((item) => item.objectId !== objectId)
      );
    }
    setOrdersData((prev) =>
      prev.map((item) =>
        item.objectId === objectId ? { ...item, isSelected: selected } : item
      )
    );
  };

  const formatOrdersData = (data) => {
    return data.map((order) => ({
      ...order,
      createdAt: new Date(order.createdAt).toLocaleString(),
      updatedAt: new Date(order.updatedAt).toLocaleString(),
      // Ensure vanNo is an array for proper formatting
      vanNo: Array.isArray(order.vanNo) ? order.vanNo : [order.vanNo],
    }));
  };

  const fetchOrders = async () => {
    try {
      dispatch(updateSpinnerState(true));
      const res = await apiCall(
        apiCallConsts.GET_METHOD,
        `/internal/order?isHyperLocal=true`
      );
      const formattedData = formatOrdersData(res);
      setOrdersData(res);
      dispatch(updateSpinnerState(false));
      messageDisplayer('success', '', 'Orders fetched successfully');
    } catch (error) {
      dispatch(updateSpinnerState(false));
      messageDisplayer('danger', 'Failed to fetch Orders', error.message);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  return (
    <div>
      <OrderListPage
        pageType={`HyperLocalDelivery`}
        showDateFilters={false}
        tableData={ordersData}
        tableColumns={HyperLocalDeliveryColumns(onRowSelect, navigate)}
        handleRefresh={fetchOrders}
        pageTitle={t(`Hyperlocal Deliveries`)}
        breadCrumbItems={breadCrumbItems}
      />
    </div>
  );
};

export default GetHyperLocalDelivery;
