import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { markParcelAsDisabled, setParcelsForStagesInStore } from "../../actions/parcels";
import { updateToastInfo } from "../../actions/settings";
import { updateSpinnerState } from "../../actions/spinner";
import DefectivePickupRescheduleModal from "../../components/common/defectivePickupPendingSchedule";
import ParcelStatusPage from "../../components/common/parcelStatusPages";
import { createDefectivePickupPendingParcel, getParcelsByStatusAndSubStatus } from "../../parse-functions/parcels";
import { Routes } from "../../router/routes";
import { convertArrayToObj } from "../../utils/json";
import { returnWarehouseDropdownOptions } from "../../utils/warehouse";
import { setFiltersInStore } from "../../actions/filters";
import { returnEndOfDay, returnStartOfDay } from "../../utils/datetime";
import { defectivePickupPendingTableColumns } from "./utils";

const PickupPendingList = () => {
    const pageStatus = "replacementpending";
    const { t } = useTranslation();

    const breadCrumbItems = [{
        title: t("Defective - Pickup Pending"),
        link: Routes.Defective.PickupPending.path
    }];
    const dispatch = useDispatch();

    const [selectedParcelId, setSelectedParcelId] = useState(null);
    const storeCode = useSelector((state) => state?.user?.warehouse?.warehouseCode || "");
    const filtersValue = useSelector((state) => state.filters?.[pageStatus]);
    const parcels = useSelector((state) => state?.parcels?.[pageStatus] || {});
    const [showRescheduleModal, setShowRescheduleModal] = useState(false);
    const warehouse = useSelector((state) => state?.user?.warehouse || {});
    const otherWarehouses = useSelector((state) => state?.user?.otherWarehousesInZone || {});
    const zone = useSelector((state) => state?.user?.warehouse?.zone || "");
    
    const handleFiltersSubmit = (startDate, endDate) => {
        dispatch(setFiltersInStore({
            pageType: pageStatus,
            data: {
              startDate: new Date(startDate),
              endDate: new Date(endDate)
            }
          }))
        getAvailableParcels(startDate, endDate);
    };

    const getAvailableParcels = async (startDate, endDate) => {
        dispatch(updateSpinnerState(true));
        try {
            const filters = {
                subStatus: [pageStatus],
                storeCode,
                startDate: new Date(startDate),
                endDate: new Date(endDate),
                zone
            };
            const parcels = await getParcelsByStatusAndSubStatus(filters);
            dispatch(setParcelsForStagesInStore(pageStatus, convertArrayToObj(JSON.parse(JSON.stringify(parcels)), "objectId")))
            dispatch(updateSpinnerState(false));
            dispatch(updateToastInfo({
                show: true,
                type: 'success',
                title: t('Orders fetched'),
                message: t("You can schedule your parcels here for which replacement is pending")
            }))

        } catch (e) {
            dispatch(updateSpinnerState(false));
            dispatch(updateToastInfo({
                show: true,
                type: 'danger',
                title: t('Error'),
                message: t(e.message)
            }))
        }
    }
    const onRowSelect = (value, objectId) => {
        if (selectedParcelId) {
            dispatch(updateToastInfo({
                show: true,
                type: 'danger',
                title: t('Only 1 item allowed'),
                message: t("Please deselect other item before choosing this")
            }))
        } else {
            if (value) {
                setSelectedParcelId(objectId);
            } else {
                setSelectedParcelId(null);
            }
        }
    }
    const onRefreshClick = () => {
        const currentStartDate = filtersValue.startDate ? new Date(filtersValue.startDate): returnStartOfDay(new Date());
        const currentEndDate = filtersValue.endDate ? new Date(filtersValue.endDate) : returnEndOfDay(new Date());
        getAvailableParcels(currentStartDate, currentEndDate)
    }
    const handleShowRescheduleModal = () => {
        setShowRescheduleModal(false);
    }
    const onRescheduleConfirm = async (data) => {
        dispatch(updateSpinnerState(true));
        try {
            const formData = {
                ...data,
                warehouse,
                parcelObjectId: selectedParcelId,
                newDate: new Date()
            }
            await createDefectivePickupPendingParcel(formData)
            dispatch(markParcelAsDisabled(pageStatus, selectedParcelId));
            setShowRescheduleModal(false);
            dispatch(updateToastInfo({
                show: true,
                type: 'success',
                title: t('Defective Pickup Scheduled'),
                message: t("This parcel's Replacement has been rescheduled for the chosen date")
            }))
            dispatch(updateSpinnerState(false));

        } catch (e) {
            dispatch(updateSpinnerState(false));
        }
    };
    const handleItemBulkUpdate = (actionId) => {
        if (selectedParcelId) {
            switch (actionId) {
                case "schedule": {
                    setShowRescheduleModal(true);
                    break;
                }
                default: {
                    dispatch(updateToastInfo({
                        show: true,
                        type: 'danger',
                        title: t('Select an Action'),
                        message: t("Please select an action from the dropdown")
                    }))
                    break;
                }
            }
        } else {
            dispatch(updateToastInfo({
                show: true,
                type: 'danger',
                title: t('Select Item'),
                message: t("Please select an item to take action")
            }))
        }
    }
    return (
        <>
            <ParcelStatusPage
                pageType={pageStatus}
                filtersValue={filtersValue}
                filterSubmit={handleFiltersSubmit}
                tableData={parcels}
                tableColumns={defectivePickupPendingTableColumns(onRowSelect)}
                handleRefresh={onRefreshClick}
                pageTitle={t("Defective Pickup Pending")}
                breadCrumbItems={breadCrumbItems}
                pageSubTitle={t("All the parcels for which the pickup is still pending can be rescheduled here")}
                searchPlaceHolder={t("Search by Invoice No. in Database")}
                handleItemBulkUpdate={handleItemBulkUpdate}
                bulkActionOptions={[{
                    label: "Schedule",
                    value: "schedule"
                }]}
                enableBulkActions={selectedParcelId && Object.keys(parcels)?.length > 0}
            />
            <DefectivePickupRescheduleModal
                disableTargetDropdown={false}
                targetDropdownOptions={returnWarehouseDropdownOptions(otherWarehouses)}
                selectedParcelId={selectedParcelId}
                pageStatus={pageStatus}
                showModal={showRescheduleModal}
                closeModal={handleShowRescheduleModal}
                onConfirm={onRescheduleConfirm} />
        </>
    )
}
export default PickupPendingList;