import Parse from "parse";

export const getVehiclesListInZone = (filters) => {
    return Parse.Cloud.run("getVehiclesListInZone", filters);
}

export const addNewDriverVehicle = (data) => {
    return Parse.Cloud.run("addNewDriverVehicle", data);
}

export const getVehicleDetailsByNumber = (data) => {
    return Parse.Cloud.run("getVehicleDetailsByNumber", data);
}
export const updateVehiclesInfo = (data) => {
    return Parse.Cloud.run("updateVehiclesInfo", data);
}