import React, { useEffect, useState } from 'react';
import Pagination from 'react-bootstrap-table2-paginator';
import * as Paginator from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  Col,
  Form,
  Row,
} from '@themesberg/react-bootstrap';
import Table from 'react-bootstrap-table-next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { orderTableColumns, ordersDataColumns, searchByFields } from './utils';
import {
  customSizePerPage,
  customTotal,
} from '../../../components/common/parcelStatusPages';
import BreadCrumbComponent from '../../../components/common/Breadcrumb';
import arrayToBreadCrumbs from '../../../utils/sms/arrayToBreadCrumbs';
import {
  faArrowRotateLeft,
  faBroom,
  faFilter,
  faRefresh,
  faSliders,
} from '@fortawesome/free-solid-svg-icons';
import ExcelDownloadReport from '../../../components/common/excel/download';
import DropdownComponent from '../../../components/common/Dropdown';
import { returnRowBGColor } from '../../../utils/parcels';
import EmptyTable from '../../../components/common/EmptyTableView';
import { useDispatch, useSelector } from 'react-redux';
import { updateSpinnerState } from '../../../actions/spinner';
import { updateToastInfo } from '../../../actions/settings';
import { apiCall } from '../../../middlewares/api';
import { apiCallConsts } from '../../../constants/apiCallConsts';
import { useNavigate } from 'react-router-dom';
import { returnWarehouseDropdownOptions } from '../../../utils/warehouse';
import EditableDropDown from '../../../components/common/EditableDropdown';
import { arrayToDropdownOptions } from '../../../utils/sms/arrayToDropdownOptions';
import AsyncDropdown from '../../../components/AsyncDropdown';
import {
  setCompanyAndCategoryDataInStore,
  // setProductListDropdownInStore,
} from '../../../actions/products';
import {
  returnCategoriesDropdownList,
  returnCompaniesDropdownList,
} from '../../../utils/products';
import { getAllCompaniesAndCategoriesData } from '../../../parse-functions/products';
import { setStoresInStore } from '../../../actions/oms/stores';
import { setProductListDropdownInStore } from '../../../actions/oms/allProducts';
import { useFetchOMSProducts } from '../custom-hooks/useFetchOMSProducts';

const AllOrders = () => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [searchByFieldName, setSearchByFieldName] = useState('');
  const breadCrumbItems = arrayToBreadCrumbs([['orders'], ['data']]);
  const [isLoadingOptions, setIsLoadingOptions] = useState(false);
  const productList = useSelector(
    (state) => state?.allProducts?.productsListDropdown || {}
  );
  const productsListAsOptions = arrayToDropdownOptions(
    Object.keys(productList)
  );
  const [searchValue, setSearchValue] = useState('');
  const [isValidInput, setIsValidInput] = useState(true);
  const zones = useSelector((state) => state?.user?.config?.zones || {});
  const companies = useSelector((state) => state?.allProducts?.companies || {});
  const categories = useSelector(
    (state) => state?.allProducts?.categories || {}
  );
  const storeList = useSelector((state) => state?.stores?.storeList || []);
  const [region, setRegion] = useState('');
  const [categoryName, setCategoryName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [vanNo, setVanNo] = useState('');
  const [city, setCity] = useState('');
  const [contactNo, setContactNo] = useState('');
  const [invoiceNo, setInvoiceNo] = useState('');
  const [serialNo, setSerialNo] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [email, setEmail] = useState('');
  const [awbNumber, setAwbNumber] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [openFilter, setOpenFilter] = useState(false);
  const [flag, setFlag] = useState(false);

  const zoneDropDownOptions = (zones = {}) => {
    const returnVal = [];
    Object.keys(zones).forEach((zone) => {
      returnVal.push({ label: zone, value: zone });
    });
    return returnVal;
  };
  const [searchData, setSearchData] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useFetchOMSProducts();
  useEffect(() => {
    // getAllCompaniesAndCategories();
    // handleSearchClicked();
    const getProductsList = async () => {
      try {
        dispatch(updateSpinnerState(true));
        const products = await apiCall(
          apiCallConsts.GET_METHOD,
          '/internal/serviceability/products'
        );
        dispatch(setProductListDropdownInStore(products));
        dispatch(updateSpinnerState(false));
      } catch (error) {
        dispatch(updateSpinnerState(false));
        dispatch(
          updateToastInfo({
            show: true,
            type: 'danger',
            title: t('Failed Getting Products data'),
            message: t(error.message),
          })
        );
      }
    };
    if (Object.keys(productList).length === 0) {
      getProductsList();
    }
  }, []);

  useEffect(() => {
    dispatch(updateSpinnerState(true));
    const data = async () => {
      await handleSearchClicked();
    };
    data();
    dispatch(updateSpinnerState(false));
  }, [flag]);

  const getAllCompaniesAndCategories = async () => {
    if (
      Object.keys(companies).length > 0 &&
      Object.keys(categories).length > 0
    ) {
      return;
    }
    dispatch(updateSpinnerState(true));
    try {
      const data = await getAllCompaniesAndCategoriesData();
      dispatch(setCompanyAndCategoryDataInStore(data));
      dispatch(updateSpinnerState(false));
    } catch (e) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('Failed Getting Company & Category data'),
          message: t(
            'Please refresh the page to try getting this data again or contact support'
          ),
        })
      );
    }
  };
  const handleSearchClicked = async () => {
    setIsValidInput(true);
    try {
      dispatch(updateSpinnerState(true));
      const res = await apiCall(
        apiCallConsts.GET_METHOD,
        'internal/order/filter',
        {
          vanNo,
          orderNo,
          city,
          categoryName,
          companyName,
          startDate,
          endDate,
          status,
          region,
          storeCode,
          contactNo,
          invoiceNo,
          serialNo,
          customerName,
          email,
          awbNumber,
        }
      );

      setData(res);
      dispatch(
        updateToastInfo({
          show: true,
          type: 'success',
          title: t(''),
          message: t('orders fetched successfully!'),
        })
      );
      dispatch(updateSpinnerState(false));
    } catch (error) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('Failed to Fetch The Data!'),
          message: t(error.message),
        })
      );
    }
  };

  const debounce = (func, delay = 500) => {
    let timeoutId;
    return (...args) => {
      return new Promise((resolve) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          resolve(func(...args));
        }, delay);
      });
    };
  };

  const handleFilter = () => {
    setOpenFilter((each) => !each);
  };
  const handleReset = () => {
    setVanNo((each) => '');
    setRegion((each) => '');
    setCategoryName((each) => '');
    setCompanyName((each) => '');
    setCity((each) => '');
    setStartDate((each) => '');
    setEndDate((each) => '');
    setStatus((each) => '');
    setStoreCode((each) => '');
    setOrderNo((each) => '');
    setContactNo((each) => '');
    setInvoiceNo((each) => '');
    setSerialNo((each) => '');
    setCustomerName((each) => '');
    setEmail((each) => '');
    setAwbNumber((each) => '');

    setFlag((flag) => !flag);
  };

  const fetchOptions = debounce(async (inputValue) => {
    setIsLoadingOptions(true);
    try {
      const filteredOptions = productsListAsOptions.filter((product) =>
        product.value.toLowerCase().includes(inputValue.toLowerCase())
      );
      return filteredOptions;
    } finally {
      setIsLoadingOptions(false);
    }
  });

  const [storeCode, setStoreCode] = useState([]);
  const [status, setStatus] = useState([]);
  const [options, setOptions] = useState(storeList);
  const [orderNo, setOrderNo] = useState([]);
  const [statusArray, setStatusArray] = useState([
    { label: 'Assigned', value: 'Assigned' },
    { label: 'Accepted', value: 'Accepted' },
    { label: 'Awaiting Invoice', value: 'Awaiting Invoice' },
    { label: 'Invoiced', value: 'Invoiced' },
    { label: 'Ready For Pickup', value: 'Ready For Pickup' },
    { label: 'Picked Up', value: 'Picked Up' },
    { label: 'Delivered', value: 'Delivered' },
  ]);

  useEffect(() => {
    const fetchStore = async () => {
      try {
        dispatch(updateSpinnerState(true));
        const data = await apiCall('get', 'internal/store/all', {});
        dispatch(setStoresInStore(data));
        console.log(returnWarehouseDropdownOptions(data, false));
        setOptions(returnWarehouseDropdownOptions(data, false));
        dispatch(updateSpinnerState(false));
      } catch (error) {
        dispatch(updateSpinnerState(false));
        dispatch(
          updateToastInfo({
            show: true,
            type: 'danger',
            title: t('failed to fetch'),
            message: t(`${error.message}`),
          })
        );
      }
    };
    if (storeList.length === 0) {
      fetchStore();
    }
  }, []);

  const filterObj = [
    { label: 'VAN NO', value: vanNo, func: setVanNo },
    { label: 'ORDER NO', value: orderNo, func: setOrderNo },
    {
      label: 'PRODUCT NAME',
      value: vanNo,
      func: setVanNo,
    },
    { label: 'PRODUCT CATEGORY', value: categoryName, func: setCategoryName },
    { label: 'COMPANY', value: companyName, func: setCompanyName },
    {
      label: 'REGION',
      value: region,
      func: setRegion,
    },
    {
      label: 'CITY',
      value: city,
      func: setCity,
    },
    {
      label: 'STATUS',
      value: status,
      func: setStatus,
    },
    {
      label: 'PHONE NO',
      value: contactNo,
      func: setContactNo,
    },
    { label: 'INVOICE NO', value: invoiceNo, func: setInvoiceNo },
    { label: 'SERIAL NO', value: serialNo, func: setSerialNo },
    {
      label: 'CUSTOMER NAME',
      value: customerName,
      func: setCustomerName,
    },
    {
      label: 'EMAIL',
      value: email,
      func: setEmail,
    },
    {
      label: 'AWB NUMBER',
      value: awbNumber,
      func: setAwbNumber,
    },
    { label: 'STORE NAME', value: storeCode, func: setStoreCode },
    { label: 'START DATE', value: startDate, func: setStartDate },
    { label: 'END DATE', value: endDate, func: setEndDate },
  ];
  const handleEmpty = async (fun) => {
    fun(() => '');
    setFlag((flag) => !flag);
  };

  return (
    <ToolkitProvider
      exportCSV
      keyField="allOrders"
      search={true}
      sort
      columns={ordersDataColumns(navigate)}
      data={data}
      wrapperClasses="table-responsive"
    >
      {({ baseProps, searchProps, csvProps }) => (
        <Paginator.PaginationProvider
          pagination={Pagination({
            custom: true,
            showTotal: true,
            alwaysShowAllBtns: true,
            totalSize: data?.length,
            paginationTotalRenderer: customTotal,
            sizePerPageRenderer: customSizePerPage,
          })}
        >
          {({ paginationProps, paginationTableProps }) => (
            <>
              <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-md-0">
                  <BreadCrumbComponent items={breadCrumbItems} />
                  <h4>{t('Orders Data')}</h4>
                  <p className="mb-0">
                    {t('Search for Orders By all Filters')}
                  </p>
                </div>
                <div className="btn-toolbar mb-2 mb-md-0">
                  <ButtonGroup className="gap-2">
                    <Button
                      variant={openFilter ? 'primary' : 'white'}
                      onClick={() => {
                        handleFilter();
                      }}
                      style={{
                        width: '150px',
                        height: '38px',
                        borderRadius: 0,
                      }}
                      size="sm"
                    >
                      <FontAwesomeIcon icon={faSliders} />
                      &nbsp;&nbsp;{t('Filter')}
                    </Button>
                    <Button
                      variant="white"
                      onClick={() => {
                        handleSearchClicked();
                      }}
                      style={{
                        width: '150px',
                        height: '38px',
                        borderRadius: 0,
                      }}
                      size="sm"
                    >
                      <FontAwesomeIcon icon={faRefresh} />
                      &nbsp;&nbsp;{t('Refresh')}
                    </Button>
                    <ExcelDownloadReport
                      data={data || []}
                      className="btn-export"
                      fileName={'download-data.csv'}
                    />
                  </ButtonGroup>
                </div>
              </div>
              <>
                <div
                  className="table-settings mt-2 mb-4"
                  style={{
                    background: '#EAEDF2',
                    maxHeight: openFilter ? '700px' : '0px',
                    overflow: openFilter ? '' : 'hidden', // Ensure content is hidden when collapsed
                    transition: 'max-height 0.7s ease-in-out', // Smooth transition when toggling
                  }}
                >
                  <div style={{ gap: 8, display: 'flex' }}>
                    <Form.Group
                      style={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(3, 1fr)', // Three columns
                        gap: '20px', // Gap between elements
                        width: '100%',
                        backgroundColor: 'white',
                        padding: 20,
                      }}
                    >
                      <div>
                        <Form.Label>VAN NO.</Form.Label>
                        <Form.Control
                          style={{
                            height: 38,
                            width: '100%',
                          }}
                          isInvalid={!isValidInput}
                          required
                          type="text"
                          value={vanNo}
                          onChange={(event) => {
                            setVanNo(event.target.value);
                          }}
                          placeholder="Enter the VAN no."
                        />
                      </div>

                      <div>
                        <Form.Label>PHONE NO</Form.Label>
                        <Form.Control
                          style={{
                            height: 38,
                            width: '100%',
                          }}
                          isInvalid={!isValidInput}
                          required
                          type="text"
                          value={contactNo}
                          onChange={(event) => {
                            setContactNo(event.target.value);
                          }}
                          placeholder="Enter the Phone Number"
                        />
                      </div>

                      <div>
                        <Form.Label>INVOICE NO</Form.Label>
                        <Form.Control
                          style={{
                            height: 38,
                            width: '100%',
                          }}
                          isInvalid={!isValidInput}
                          required
                          type="text"
                          value={invoiceNo}
                          onChange={(event) => {
                            setInvoiceNo(event.target.value);
                          }}
                          placeholder="Enter the Invoice No"
                        />
                      </div>
                      <div>
                        <Form.Label>SERIAL NO</Form.Label>
                        <Form.Control
                          style={{
                            height: 38,
                            width: '100%',
                          }}
                          isInvalid={!isValidInput}
                          required
                          type="text"
                          value={serialNo}
                          onChange={(event) => {
                            setSerialNo(event.target.value);
                          }}
                          placeholder="Enter serial No"
                        />
                      </div>
                      <div>
                        <Form.Label>CUSTOMER NAME</Form.Label>
                        <Form.Control
                          style={{
                            height: 38,
                            width: '100%',
                          }}
                          isInvalid={!isValidInput}
                          required
                          type="text"
                          value={customerName}
                          onChange={(event) => {
                            setCustomerName(event.target.value);
                          }}
                          placeholder="Enter the Customer Name"
                        />
                      </div>
                      <div>
                        <Form.Label>EMAIL</Form.Label>
                        <Form.Control
                          style={{
                            height: 38,
                            width: '100%',
                          }}
                          isInvalid={!isValidInput}
                          required
                          type="text"
                          value={email}
                          onChange={(event) => {
                            setEmail(event.target.value);
                          }}
                          placeholder="Enter the Customer Email"
                        />
                      </div>
                      <div>
                        <Form.Label>AWB NUMBER</Form.Label>
                        <Form.Control
                          style={{
                            height: 38,
                            width: '100%',
                          }}
                          isInvalid={!isValidInput}
                          required
                          type="text"
                          value={awbNumber}
                          onChange={(event) => {
                            setAwbNumber(event.target.value);
                          }}
                          placeholder="Enter the AWB Number"
                        />
                      </div>

                      <div>
                        <Form.Label>ORDER NO</Form.Label>
                        <Form.Control
                          style={{
                            height: 38,
                            width: '100%',
                          }}
                          isInvalid={!isValidInput}
                          required
                          type="text"
                          value={orderNo}
                          onChange={(event) => {
                            setOrderNo(event.target.value);
                          }}
                          placeholder="Enter the Order no."
                        />
                      </div>

                      <div>
                        <Form.Label>PRODUCT NAME</Form.Label>
                        <AsyncDropdown
                          onChange={(option) => setVanNo(option.value)}
                          style={{
                            height: 38,
                            width: '100%',
                          }}
                          disabled={false}
                          fetchOptions={fetchOptions}
                          placeholder={t('Select field to Search by')}
                          isLoading={isLoadingOptions}
                        />
                      </div>

                      <div>
                        <Form.Label>STORE NAME</Form.Label>
                        <EditableDropDown
                          id={'StoreName'}
                          onChange={(e) => setStoreCode(e.value)}
                          style={{
                            height: 38,
                            width: '100%',
                          }}
                          value={storeCode}
                          options={options}
                          placeholder={'Enter the Store Name'}
                        />
                      </div>

                      <div>
                        <Form.Label>PRODUCT CATEGORY</Form.Label>
                        <EditableDropDown
                          id={'ProductCategory'}
                          onChange={(e) => setCategoryName(e.value)}
                          style={{
                            height: 38,
                            width: '100%',
                          }}
                          value={categoryName}
                          options={arrayToDropdownOptions(categories)}
                          placeholder={'Enter the Product Category'}
                        />
                      </div>

                      <div>
                        <Form.Label>COMPANY</Form.Label>
                        <EditableDropDown
                          id={'Company'}
                          onChange={(e) => setCompanyName(e.value)}
                          style={{
                            height: 38,
                            width: '100%',
                          }}
                          value={companyName}
                          options={arrayToDropdownOptions(companies)}
                          placeholder={'Enter the Company name'}
                        />
                      </div>

                      <div>
                        <Form.Label>CURRENT STATUS</Form.Label>
                        <EditableDropDown
                          id={'CurrentStatus'}
                          onChange={(e) => setStatus(e.value)}
                          style={{
                            height: 38,
                            width: '100%',
                          }}
                          value={status}
                          options={statusArray}
                          placeholder={'Choose the Current Status'}
                        />
                      </div>

                      <div>
                        <Form.Label>REGION</Form.Label>
                        <EditableDropDown
                          id={'Region'}
                          onChange={(e) => setRegion(e.value)}
                          style={{
                            height: 38,
                            width: '100%',
                          }}
                          value={region}
                          options={zoneDropDownOptions(zones)}
                          placeholder={'Choose the Region'}
                        />
                      </div>

                      {/* <div>
                        <Form.Label>CITY</Form.Label>
                        <EditableDropDown
                          id={'City'}
                          onChange={(e) => setCity(e.value)}
                          style={{
                            height: 38,
                            width: '100%',
                          }}
                          value={city}
                          options={statusArray}
                          placeholder={'Choose the City'}
                        />
                      </div> */}

                      <div>
                        <Form.Label>START DATE</Form.Label>
                        <Form.Control
                          style={{
                            height: 38,
                            width: '100%',
                          }}
                          isInvalid={!isValidInput}
                          required
                          type="date"
                          value={startDate}
                          defaultValue={null}
                          onChange={(event) => {
                            setIsValidInput(true);
                            setStartDate(event.target.value);
                          }}
                          placeholder="Start Date"
                        />
                      </div>

                      <div>
                        <Form.Label>END DATE</Form.Label>
                        <Form.Control
                          style={{
                            height: 38,
                            width: '100%',
                          }}
                          isInvalid={!isValidInput}
                          required
                          value={endDate}
                          type="date"
                          defaultValue={searchData}
                          onChange={(event) => {
                            setEndDate(event.target.value);
                          }}
                          placeholder="End Date"
                        />
                      </div>

                      <div
                        style={{
                          gridColumn: 'span 3', // Span across all columns
                          display: 'flex',
                          justifyContent: 'flex-end',
                          gap: '10px',
                        }}
                      >
                        <Button
                          variant="white"
                          className="fa-export"
                          onClick={handleReset}
                          style={{
                            width: '150px',
                            height: '38px',
                            borderRadius: 0,
                            background: '#EAEDF2',
                          }}
                        >
                          <FontAwesomeIcon icon={faArrowRotateLeft} />
                          &nbsp;&nbsp;{t('Reset')}
                        </Button>

                        <Button
                          variant="white"
                          className="fa-export"
                          onClick={handleSearchClicked}
                          style={{
                            width: '150px',
                            height: '38px',
                            borderRadius: 0,
                            border: '1px solid #262B40',
                          }}
                        >
                          <FontAwesomeIcon icon={faSliders} />
                          &nbsp;&nbsp;{t('Apply Filter')}
                        </Button>
                      </div>
                    </Form.Group>
                  </div>
                </div>
                {!openFilter &&
                  (orderNo != '' ||
                    vanNo != '' ||
                    storeCode != '' ||
                    startDate != '' ||
                    endDate != '' ||
                    status != '' ||
                    categoryName != '' ||
                    companyName != '' ||
                    city != '' ||
                    contactNo != '' ||
                    serialNo != '' ||
                    customerName != '' ||
                    email != '' ||
                    awbNumber != '' ||
                    region != '') && (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <div
                        className="gap-2"
                        style={{
                          display: 'flex',

                          width: '100%',
                          background: '#F5F8FB',

                          padding: 20,
                        }}
                      >
                        {filterObj.map((each) => {
                          if (each.value != '') {
                            return (
                              <div>
                                <Form.Label style={{ color: '#262B4099' }}>
                                  {each.label}
                                </Form.Label>
                                <Badge
                                  variant="light"
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    width: '188px',
                                    height: '41px',
                                    background: 'white',
                                    borderRadius: '2px',
                                    cursor: 'pointer',
                                    color: '#262B40',
                                    fontSize: '14px',
                                    fontWeight: '400',
                                    lineHeight: '16.41px',
                                  }}
                                >
                                  {each.value}
                                  <span
                                    onClick={() => {
                                      handleEmpty(each.func);
                                    }}
                                    style={{
                                      color: '#262B40',
                                      fontSize: '14px',
                                      fontWeight: '400',
                                      lineHeight: '16.41px',
                                    }}
                                  >
                                    &times;
                                  </span>
                                </Badge>
                              </div>
                            );
                          }
                        })}
                      </div>
                      <Button
                        variant="primary"
                        className="fa-export"
                        onClick={() => {
                          handleReset();
                        }}
                        style={{
                          width: '150px',
                          height: '38px',
                          borderRadius: 0,
                        }}
                      >
                        <FontAwesomeIcon icon={faBroom} />
                        &nbsp;&nbsp;{t('Clear')}
                      </Button>
                    </div>
                  )}
              </>
              <Card>
                <div className="table-responsive py-2">
                  <Card.Header
                    style={{
                      padding: '8px 12px',
                    }}
                  >
                    <Row className="d-flex justify-content-end">
                      <Col
                        xs={12}
                        md={4}
                        className="d-flex justify-content-md-end"
                      >
                        <Search.SearchBar
                          {...searchProps}
                          placeholder={t('Search any value in table here')}
                        />
                        <Search.ClearSearchButton {...searchProps} />
                      </Col>
                    </Row>
                  </Card.Header>
                  <Table
                    noDataIndication={<EmptyTable />}
                    {...baseProps}
                    {...paginationTableProps}
                    bodyClasses="border-0"
                    classes="table-flush dataTable-table"
                  />

                  <Card.Footer>
                    <Row>
                      <Col xs={12} md={4} className="py-1">
                        <Paginator.SizePerPageDropdownStandalone
                          {...paginationProps}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        className="d-flex align-items-center py-1"
                      >
                        <Paginator.PaginationTotalStandalone
                          {...paginationProps}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        className="d-flex justify-content-md-end align-items-center mb-0 py-1"
                      >
                        <Paginator.PaginationListStandalone
                          {...paginationProps}
                        />
                      </Col>
                    </Row>
                  </Card.Footer>
                </div>
              </Card>
            </>
          )}
        </Paginator.PaginationProvider>
      )}
    </ToolkitProvider>
  );
};

export default AllOrders;
