import { Button, Form, Modal } from '@themesberg/react-bootstrap';
import React, { useState } from 'react';
import { generateRandomId } from '../../../utils/string';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { StyledSearchBar } from '../../trips/styles';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AddGSTModal = ({
  showModal,
  closeModal,
  modalTitle,
  value,
  setValue,
  modalSubTitle,
  handleSubmit,
}) => {
  const { t } = useTranslation();
  // const [value, setValue] = useState('');
  const [errors, setErrors] = useState({});

  const handleAdd = () => {
    if (value.length != 15) {
      setErrors({ ['value']: 'Please enter a valid 15-digit GST Number.' });
      return;
    }
    handleSubmit(value);
  };
  return (
    <Modal
      id={generateRandomId()}
      as={Modal.Dialog}
      centered
      show={showModal}
      onHide={closeModal}
      size="lg"
    >
      <Modal.Body>
        <div className="mx-5">
          <div className="d-flex justify-content-end">
            <Button variant="close" aria-label="Close" onClick={closeModal} />
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center text-center gap-3">
            <div className="fs-4">{t(modalTitle)}</div>
            {modalSubTitle && <div className="fs-5">{t(modalSubTitle)}</div>}
            <div className="d-flex flex-column gap-3">
              <div>
                <Form.Control
                  required
                  type="text"
                  isInvalid={!!errors['value']}
                  onChange={(e) => {
                    setValue(e?.target?.value);
                    setErrors({});
                  }}
                />
                {errors['value'] && (
                  <Form.Control.Feedback type="invalid" color="#E91E23;">
                    {errors['value']}
                  </Form.Control.Feedback>
                )}
              </div>
              <div className="d-flex justify-content-end">
                <Button variant="primary" onClick={handleAdd}>
                  <FontAwesomeIcon icon={faPlus} />
                  &nbsp;&nbsp;{t('Add')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddGSTModal;
