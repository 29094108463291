import Parse from 'parse';

export const addNewDeliveryAgent = async (data) => {
  return Parse.Cloud.run('addNewDeliveryAgent', data);
};

export const getDeliveryAgents = async () => {
  return Parse.Cloud.run('getDeliveryAgents');
};

export const updateDeliveryAgentInfo = async (data) => {
  return Parse.Cloud.run('updateDeliveryAgentInfo', data);
};

export const addDeliveryVendor = async (data) => {
  return Parse.Cloud.run('addDeliveryVendor', data);
};

export const getVendorAndZone = async () => {
  return Parse.Cloud.run('getVendorAndZone');
};
export const getOMSUsers = async () => {
  return Parse.Cloud.run('getOMSUsersList');
};

export const getDeliveryAgentsRegionUsers = async (region, storeCode) => {
  return Parse.Cloud.run('getDeliveryAgentsRegionUsers', { region, storeCode });
};
