import React from "react";
import { returnCustomerAddressString, returnParcelProductInformation } from "./table";
import { Badge } from "@themesberg/react-bootstrap";
import { getTripBadgeStatus } from "./trips";
import { returnUserReadableDate } from "./datetime";

export const branchPickupTableColumns = () => {
    return ([
        {
            dataField: "customerName", sort: true, text: "Customer Info", formatter: (cell, row) => {
                return returnCustomerAddressString(cell, row?.mobileNo, 100, 30);
            }
        },
        {
            dataField: "customerAddress", sort: true, text: "Delivery Location", formatter: (cell, row) => {
                const customerAddress = `${row?.shippingAddress1 || ""} ${row?.shippingAddress2 || ""} ${row?.shippingAddress3 || ""}`;
                return returnCustomerAddressString(customerAddress, row?.shippingPincode, 200, 35)
            }
        },
        {
            dataField: "invoiceNo", text: "Invoice Details", formatter: (cell, row) => {
                return (
                    <div className="d-block">
                        <span className="fw-bold">{cell}</span>
                        <div className="fw-normal">{new Date(row?.date).toLocaleDateString()}</div>
                    </div>
                )
            }
        },
        {
            dataField: "serialNo", text: "Product Info", formatter: (cell, row) => {
                return returnParcelProductInformation(row);
            }
        },
        {
            dataField: "status", text: "Current Status", formatter: (cell, row) => {
                const status = row?.dashboardStatus || row?.mobileStatus || row?.status;
                return (
                    <div className="d-block">
                        <div className="fw-normal">
                            <Badge bg={getTripBadgeStatus(status)} className="badge-lg">{status}</Badge>
                        </div>
                    </div>
                )
            }
        },
        {
            dataField: "status", text: "Branch Info", formatter: (cell, row) => {
                return (
                    <div className="d-block">
                        <div className="fw-bold">{row?.branch}</div>
                        <div className="fw-bold">{row?.targetBranch ? `Target: ${row?.targetBranch}` : ""}</div>
                    </div>
                );
            }
        }, {
            dataField: "pickUpLocation", text: "Pickup Location", formatter: (cell) => {
                return (
                    <div className="d-block">
                        <div className="fw-bold">{cell}</div>
                    </div>
                );
            }
        }, {
            dataField: "deliveryWarehouse", text: "Delivery Warehouse", formatter: (cell) => {
                return (
                    <div className="d-block">
                        <div className="fw-bold">{cell}</div>
                    </div>
                );
            }
        }, {
            dataField: "estimatedDeliveryDate", text: "Estimated Delivery Date", formatter: (cell) => {
                return (
                    <div className="d-block">
                        <div className="fw-bold">{cell ? returnUserReadableDate(cell) : "-"}</div>
                    </div>
                );
            }
        }
    ])
};