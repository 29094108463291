import { Button, Form, Modal, ModalBody } from '@themesberg/react-bootstrap';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generateRandomId } from '../../../../utils/string';
import { useDispatch, useSelector } from 'react-redux';
import { updateToastInfo } from '../../../../actions/settings';
import { updateSpinnerState } from '../../../../actions/spinner';
import { getProductDataByVANNo } from '../../../../parse-functions/products';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRefresh } from '@fortawesome/free-solid-svg-icons';
import EditableDropDown from '../../../../components/common/EditableDropdown';
import { returnWarehouseDropdownOptions } from '../../../../utils/warehouse';
import { apiCall } from '../../../../middlewares/api';
import { setStoresInStore } from '../../../../actions/oms/stores';

const ReplacementModal = (props) => {
  const { showModal, onConfirm, closeModal, selectedOrders, modalBody, type } =
    props;
  const { t } = useTranslation();
  const storeList = useSelector((state) => state?.stores?.storeList || []);

  const [selectedOption, setSelectedOption] = useState('Same');
  const [vanNo, setVanNo] = useState('');
  const [storeCode, setStoreCode] = useState('');
  const [data, setData] = useState('');
  const [storeOptions, setStoreOptions] = useState(
    returnWarehouseDropdownOptions(storeList, false)
  );
  const [enableButton, setEnableButton] = useState(true);
  const dispatch = useDispatch();

  const handleOptionChange = (event) => {
    const { value } = event.target;
    setSelectedOption(value);
    value === 'Same' ? setEnableButton(true) : setEnableButton(false);
  };

  const handleSearchClick = () => {
    setEnableButton(true);
  };

  const handleVanNoChange = (event) => {
    setVanNo(event.target.value);
    setEnableButton(false);
  };

  const handleStoreChange = (e) => {
    setStoreCode([e.value]);
    setEnableButton(true);
  };

  const handleOnConfirm = () => {
    if (type === 'item') {
      const resultProduct =
        selectedOption === 'Same'
          ? {
              vanNo: selectedOrders[0]?.vanNo,
              productTitle: selectedOrders[0]?.productTitle,
            }
          : {
              vanNo: data?.vanNo,
              productTitle: data?.productName,
            };
      onConfirm({ data: resultProduct });
    } else if (type === 'store') {
      const resultStoreCode =
        selectedOption === 'Same' ? selectedOrders[0]?.storeCode : storeCode[0];
      onConfirm({ data: { storeCode: resultStoreCode } });
    }
  };

  const fetchProduct = async () => {
    if (vanNo.length !== 6) {
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('Please enter vanno'),
          message: t('vanno should be equal to six digits'),
        })
      );
      return;
    }
    try {
      dispatch(updateSpinnerState(true));
      const { product } = await getProductDataByVANNo({ vanNo });
      setData(product);
      handleSearchClick();
      dispatch(updateSpinnerState(false));
    } catch (error) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('Failed to fetch Orders'),
          message: t(error.message),
        })
      );
    }
  };

  useEffect(() => {
    const fetchStore = async () => {
      try {
        dispatch(updateSpinnerState(true));
        const data = await apiCall('get', 'internal/store/all', {});
        dispatch(setStoresInStore(data));
        setStoreOptions(returnWarehouseDropdownOptions(data, false));
        dispatch(updateSpinnerState(false));
      } catch (error) {
        dispatch(updateSpinnerState(false));
        dispatch(
          updateToastInfo({
            show: true,
            type: 'danger',
            title: t('failed to fetch'),
            message: t(`${error.message}`),
          })
        );
      }
    };
    if (storeList.length === 0) {
      fetchStore();
    }
  }, []);

  return (
    <Modal
      id={generateRandomId()}
      as={Modal.Dialog}
      centered
      show={showModal}
      onHide={closeModal}
    >
      <Modal.Header>
        <Modal.Title className="h6">{t('Replacement Request')}</Modal.Title>
        <Button variant="close" aria-label="Close" onClick={closeModal} />
      </Modal.Header>
      <Modal.Body>
        {t(`${modalBody}`)}
        <br />
        <br />
        <Form.Group className="d-flex gap-3 justify-content-around">
          <label className="d-flex gap-1">
            <input
              type="radio"
              value="Same"
              checked={selectedOption === 'Same'}
              onChange={handleOptionChange}
            />
            {`Same ${type}`}
          </label>
          <label className="d-flex gap-1">
            <input
              type="radio"
              value="Different"
              checked={selectedOption === 'Different'}
              onChange={handleOptionChange}
            />
            {`Different ${type}`}
          </label>
        </Form.Group>
        {selectedOption === 'Different' &&
          (type === 'item' ? (
            <div>
              <div className="d-flex gap-2 mt-5">
                <Form.Group>
                  <Form.Control
                    id={generateRandomId()}
                    value={vanNo}
                    onChange={handleVanNoChange}
                    type="text"
                    placeholder="Enter VAN Number"
                  />
                </Form.Group>
                <Button size={'sm'} onClick={fetchProduct} variant="primary">
                  <FontAwesomeIcon icon={faRefresh} />
                  &nbsp;&nbsp;{t('Fetch Data')}
                </Button>
              </div>
              {data && (
                <div className="mt-2">Product Name : {data?.productName}</div>
              )}
            </div>
          ) : (
            <div className="mt-5">
              <Form.Group>
                <EditableDropDown
                  id={'StoreName'}
                  onChange={handleStoreChange}
                  value={storeCode}
                  options={storeOptions}
                  placeholder={'Select a store to assign'}
                ></EditableDropDown>
              </Form.Group>
            </div>
          ))}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={handleOnConfirm}
          disabled={!enableButton}
        >
          {t('Confirm')}
        </Button>
        <Button
          variant="link"
          className="text-gray ms-auto"
          onClick={closeModal}
        >
          {t('Cancel')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ReplacementModal;
