import { Button, Modal } from "@themesberg/react-bootstrap";
import React from "react";
import { useTranslation } from "react-i18next";
import AttendanceCategory from "./attendanceCategoryListing";

const TargetAttendanceHandler = (props) => {
  const {
    show,
    teamLeadList,
    handleClose,
    handleChangeTeamLeadSelection,
    allocateTargetConfirm,
    selectedTeamLeadList,
  } = props;
  const { t } = useTranslation();
  return (
    <Modal show={show} onHide={handleClose} fullscreen size="xl">
      <Modal.Header className="mx-auto">
        <h4>{t("Choose Available Team Leads")}</h4>
      </Modal.Header>
      <Modal.Body className="pt-4 d-flex">
        {teamLeadList &&
          Object.keys(teamLeadList)?.length > 0 &&
          Object.keys(teamLeadList)?.map((categoryName) => {
            return (
              <div className="mx-2">
                <AttendanceCategory
                  selectedTeamLeadList={selectedTeamLeadList[categoryName]}
                  handleChangeTeamLeadSelection={handleChangeTeamLeadSelection}
                  title={categoryName}
                  items={teamLeadList[categoryName]}
                />
              </div>
            );
          })}
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between pt-2 pb-2">
        <Button variant="secondary" onClick={handleClose}>
          {"Cancel"}
        </Button>
        <Button
          variant="primary"
          onClick={() => allocateTargetConfirm(true, false)}
        >
          {"Allocate"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default TargetAttendanceHandler;
