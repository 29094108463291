import Parse from "parse";

export const createNewRequisition = (data) => {
    return Parse.Cloud.run("createNewRequisition", data)
}

export const getRequisitionsFromServer = (data) => {
    return Parse.Cloud.run("getRequisitionsFromServer", data);
}

export const updateRequisitionStatusInServer = (data) => {
    return Parse.Cloud.run("updateRequisitionStatusInServer", data);
}

export const getRequisitionItemById = (data) => {
    return Parse.Cloud.run("getRequisitionItemById", data);
}
export const generateRequisitionsInBulk = (data) => {
    return Parse.Cloud.run("generateRequisitionsInBulk", data);
}