import { Badge } from "@themesberg/react-bootstrap";
import React from "react";
import { getTableRowCheckBox, returnCustomerAddressString } from "./table";
import { getTripBadgeStatus } from "./trips";
import { orderStatusMap } from "./constants";

export const temposheetReceiveTableColumns = (onRowSelect, isAllSelected) => {
    return ([
        {
            dataField: "objectId",
            text: "Select",
            formatter: (cell, row) => {
                return getTableRowCheckBox(onRowSelect, cell, row?.isSelected || false, row?.isDisabled || row?.receivedAt || row?.buybackReceivedAt|| false)
            },
            headerFormatter: (cell, row) => {
                return (
                    <div className="d-flex">
                    {getTableRowCheckBox(onRowSelect, "selectAll", isAllSelected, false)}&nbsp;&nbsp;Select All
                    </div>
                )
            }
        },
        {
            dataField: "customerName", sort: true, text: "Customer Info", formatter: (cell, row) => {
                return (
                    <div className="d-block">
                        <span className="fw-bold">{cell}</span>
                        <div className="fw-normal">{row?.mobileNo}</div>
                    </div>
                )
            }
        },
        {
            dataField: "customerAddress", sort: true, text: "Delivery Location", formatter: (cell, row) => {
                const customerAddress = `${row?.shippingAddress1 || ""} ${row?.shippingAddress2 || ""} ${row?.shippingAddress3 || ""}`;
                return returnCustomerAddressString(customerAddress, row?.shippingPincode, 300, 35)
            }
        },
        {
            dataField: "invoiceNo", text: "Invoice Details", formatter: (cell, row) => {
                return (
                    <div className="d-block">
                        <span className="fw-bold">{cell}</span>
                        <div className="fw-normal">{new Date(row?.date).toLocaleDateString()}</div>
                    </div>
                )
            }
        },
        {
            dataField: "serialNo", text: "Product Info", formatter: (cell, row) => {
                return (
                    <div className="d-block">
                        <div className="fw-normal">{row?.productCategory}</div>
                        <span className="fw-bold">{cell}</span>

                    </div>
                )
            }
        },
        {
            dataField: "status", text: "Current Status", formatter: (cell, row) => {
                const status = row?.dashboardStatus || row?.mobileStatus || row?.status || "";
                const subStatus = row?.dashboardSubStatus || row?.mobileSubStatus || "";
                return (
                    <div className="d-block">
                        <div className="fw-bold">{row?.targetBranch}</div>
                        <div className="fw-normal">
                        <span>
                            <Badge bg={getTripBadgeStatus(status)} className="badge-lg">{orderStatusMap[status]}</Badge> &nbsp;&nbsp;
                        </span>
                        <span>
                            <Badge bg={getTripBadgeStatus(subStatus)} className="badge-lg">{orderStatusMap[subStatus]}</Badge> &nbsp;&nbsp;
                        </span>
                        </div>
                    </div>
                )
            }
        },
        {
            dataField: "tripInfo.vehicleNumber", text: "Trip Info", formatter: (cell, row) => {
                return (
                    <div className="d-block">
                        <div className="fw-bold">
                            <span>{row?.tripNumber}</span>
                            <span>&nbsp;&nbsp;<Badge bg={getTripBadgeStatus(row?.tripInfo?.status)} className="badge-lg">{row?.tripInfo?.status}</Badge> &nbsp;&nbsp;</span>
                        </div>
                        <span className="fw-normal">{row?.tripInfo?.vehicleNumber}</span>
                    </div>
                )
            }
        }
    ])
};