export default {
    translations: {
        "Save Temporary Trip": "तात्पुरती ट्रिप जतन करा",
        "View Packing": "पॅकिंग पहा",
        "Orders Count": "ऑर्डरची संख्या",
        "Locations": "स्थाने",
        "Temporary Trip Created Successfully": "तात्पुरती सहल यशस्वीरित्या तयार केली",
        "Please visit the trip page to take more action on this trip": "या सहलीवर अधिक कृती करण्यासाठी कृपया ट्रिप पृष्ठास भेट द्या",
        "Temporary Trip Creation failed": "तात्पुरती ट्रिप तयार करणे अयशस्वी",
        "Please try again or contact support": "कृपया पुन्हा प्रयत्न करा किंवा समर्थनाशी संपर्क साधा",
        "Add New Warehouse": "नवीन कोठार जोडा",
        "Use this page to add a new warehouse": "नवीन कोठार जोडण्यासाठी हे पृष्ठ वापरा",
        "Warehouse Name": "गोदामाचे नाव",
        "Warehouse Code": "वेअरहाऊस कोड",
        "Warehouse Short Code": "वेअरहाऊस शॉर्ट कोड",
        "Address": "पत्ता",
        "Pincode": "पिन कोड",
        "UserName": "वापरकर्तानाव",
        "Password": "पासवर्ड",
        "Repeat Password": "पासवर्डची पुनरावृत्ती करा",
        "Create User": "वापरकर्ता तयार करा",
        "Cancel": "रद्द करा",
        "Add Users": "वापरकर्ते जोडा",
        "Add Warehouse": "गोदाम जोडा",
        "Add Incharge": "प्रभारी अॅड",
        "Add New Incharge": "नवीन प्रभारी जोडा",
        "Use this page to add a new incharge": "नवीन प्रभारी जोडण्यासाठी हे पृष्ठ वापरा",
        "Incharge Name": "प्रभारी नाव",
        "Phone Number": "फोन नंबर",
        "Email Id": "ई - मेल आयडी",
        "Select Application": "अर्ज निवडा",
        "Warehouse / Store Name": "गोदाम / दुकानाचे नाव",
        "Incharge Info": "प्रभारी माहिती",
        "Login Info": "लॉगिन माहिती",
        "Item Removed": "आयटम काढला",
        "Parcel Removed from trip successfully": "पार्सल ट्रिपमधून यशस्वीरित्या काढले",
        "Removal Failed": "काढणे अयशस्वी",
        "Parcel removal failed, Please try later": "पार्सल काढणे अयशस्वी, कृपया नंतर प्रयत्न करा",
        "Scan, Print EWB & Dispatch your vehicle from here": "स्कॅन करा, EWB प्रिंट करा आणि येथून तुमचे वाहन पाठवा",
        "Enter Serial No to Scan": "स्कॅन करण्यासाठी अनुक्रमांक प्रविष्ट करा",
        "શું તમે ખરેખર દૂર કરવા માંગો છો": "तुम्हाला खात्री आहे की तुम्ही काढू इच्छिता",
        "Remove Orders": "ऑर्डर काढा",
        "Confirm": "पुष्टी",
        "Drop File here": "फाइल येथे ड्रॉप करा",
        "Select Display Sheet": "डिस्प्ले शीट निवडा",
        "Please enter valid InvoiceId [X-Q-SI X] [RC/X/X-X/X] [PC/X/X-X/X] [X-S-SI X]": "कृपया वैध InvoiceId [X-Q-SI X] [RC/X/X-X/X] [PC/X/X-X/X] [X-S-SI X] एंटर करा",
        "Vehicle Number is mandatory": "वाहन क्रमांक अनिवार्य आहे",
        "Vehicle Area is mandatory": "Vehicle Area is mandatory",
        "Please Select an option": "कृपया एक पर्याय निवडा",
        "Please Enter Valid Vehicle Number": "कृपया वैध वाहन क्रमांक प्रविष्ट करा",
        "Please select an option": "कृपया एक पर्याय निवडा",
        "Create Temporary trip": "तात्पुरती सहल तयार करा",
        "Vehicle Number": "वाहन क्रमांक",
        "Vehicle Area": "वाहन क्षेत्र",
        "Save temporary": "तात्पुरते जतन करा",
        "Height": "उंची",
        "Length": "दृश्य",
        "Breadth": "रुंदी",
        "Incharge Added": "प्रभारी अॅड",
        "incharge added successfully": "प्रभारी यशस्वीरित्या जोडले",
        "incharge failed to add": "प्रभारी जोडण्यात अयशस्वी",
        "Incharge Add Failed": "प्रभारी जोडा अयशस्वी",
        "Please enter Incharge name": "कृपया प्रभारी नाव प्रविष्ट करा",
        "zone": "कृपया फोन नंबर प्रविष्ट करा",
        "Delivery Management": "वितरण व्यवस्थापन",
        "Warehouse Management": "कोठार व्यवस्थापन",
        "Finance": "वित्त",
        "Omni Channel": "ओम्नी चॅनल",
        "Product Management": "Product Management",
        "Select Applications to give access": "उत्पादन व्यवस्थापन",
        "All your temporary trips are listed here.": "तुमच्या सर्व तात्पुरत्या सहली येथे सूचीबद्ध केल्या आहेत.",
        "Previous": "मागील",
        "Next": "पुढे",
        "Dashboard": "डॅशबोर्ड",
        "Overview": "आढावा",
        "Dispatch": "डिस्पॅच",
        "Temporary Trips": "तात्पुरत्या सहली",
        "Trips List": "सहलींची यादी",
        "Requisitions": "मागणी",
        "Trips": "सहली",
        "Parcels": "पार्सल",
        "Vehicles": "वाहने",
        "Live": "राहतात",
        "Plan Vehicles": "वाहनांची योजना करा",
        "Prepare temporary trip sheet here": "येथे तात्पुरते ट्रिप शीट तयार करा",
        "Enter / Scan Invoice Number": "इन्व्हॉइस नंबर एंटर / स्कॅन करा",
        "Warehouse Info": "वेअरहाऊस माहिती",
        "Please select a zone": "कृपया एक झोन निवडा",
        "Active Trips": "सक्रिय सहली",
        "All the trips of your warehouse are listed here.": "तुमच्या वेअरहाऊसच्या सर्व ट्रिप येथे सूचीबद्ध आहेत.",
        "New Trip": "नवीन ट्रिप",
        "Share": "नवीन ट्रिपशेअर",
        "Export": "निर्यात करा",
        "Trip Number": "ट्रिप क्रमांक",
        "VEHICLE NUMBER": "वाहन क्रमांक",
        "VEHICLE AREA": "वाहन क्षेत्र",
        "ने निर्मित": "ने निर्मित",
        "DATE": "तारीख",
        "STATUS": "स्थिती",
        "Pending": "प्रलंबित",
        "Scan, Print EWB & Dispatch your vehicle from here.": "स्कॅन करा, EWB प्रिंट करा आणि येथून तुमचे वाहन पाठवा.",
        "Print Sheet": "पत्रक मुद्रित करा",
        "Print EWB": "EWB प्रिंट करा",
        "CUSTOMER": "ग्राहक",
        "INVOICE NO": "इन्व्हॉइस क्र",
        "ADDRESS": "पत्ता",
        "SERIAL NO": "अनु क्रमांक",
        "PRODUCT": "उत्पादन",
        "ACTION": "क्रिया",
        "Remove": "काढा",
        "Loader Name 1": "लोडरचे नाव 1",
        "Loader Number 1": "लोडर क्रमांक 1",
        "Loader Name 2": "लोडरचे नाव 2",
        "Loader Number 2": "लोडर क्रमांक 2",
        "Loader Name 3": "लोडरचे नाव 3",
        "Loader Number 3": "लोडर क्रमांक 3",
        "Loader Name 4": "लोडरचे नाव 4",
        "Loader Number 4": "लोडर क्रमांक 4",
        "Loader Name 5": "लोडरचे नाव 5",
        "Loader Number 5": "लोडर क्रमांक 5",
    }
}