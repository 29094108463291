import React, { useEffect, useState } from 'react';
import { Badge, Button, Form } from '@themesberg/react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { updateSpinnerState } from '../../../actions/spinner';
import { updateToastInfo } from '../../../actions/settings';
import { apiCall } from '../../../middlewares/api';
import { apiCallConsts } from '../../../constants/apiCallConsts';
import { useNavigate } from 'react-router-dom';
import { returnWarehouseDropdownOptions } from '../../../utils/warehouse';
import EditableDropDown from '../../../components/common/EditableDropdown';
import { arrayToDropdownOptions } from '../../../utils/sms/arrayToDropdownOptions';
import AsyncDropdown from '../../../components/AsyncDropdown';
import {
  setCompanyAndCategoryDataInStore,
  // setProductListDropdownInStore,
} from '../../../actions/products';
import { getAllCompaniesAndCategoriesData } from '../../../parse-functions/products';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowRotateLeft,
  faBroom,
  faSliders,
} from '@fortawesome/free-solid-svg-icons';
import { setStoresInStore } from '../../../actions/oms/stores';
import { setProductListDropdownInStore } from '../../../actions/oms/allProducts';
// import { useFetchOMSProducts } from '../custom-hooks/useFetchOMSProducts';
// import { useFetchOMSCategoriesAndCompanies } from '../custom-hooks/useFetchOMSCategoryAndCompany';
import { useFetchStores } from '../custom-hooks/useFetchStores';

const FilterOrders = (props) => {
  const { t } = useTranslation();
  const { data, setData, openFilter } = props;

  const [isLoadingOptions, setIsLoadingOptions] = useState(false);
  const productList = useSelector(
    (state) => state?.allProducts?.productsListDropdown || {}
  );
  const productsListAsOptions = arrayToDropdownOptions(
    Object.keys(productList)
  );
  const storeList = useSelector((state) => state?.stores?.storeList || []);
  const [isValidInput, setIsValidInput] = useState(true);
  const companies = useSelector((state) => state?.allProducts?.companies || {});
  const categories = useSelector(
    (state) => state?.allProducts?.categories || {}
  );
  const isSuperAdmin = useSelector(
    (state) => state?.user?.preferences?.isSuperAdmin || false
  );
  const isAdmin = useSelector(
    (state) => state?.user?.preferences?.isAdmin || false
  );

  const [vanNo, setVanNo] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const dispatch = useDispatch();

  // useFetchOMSProducts();
  useFetchStores();
  // useFetchOMSCategoriesAndCompanies();

  useEffect(() => {
    getAllCompaniesAndCategories();

    const getProductsList = async () => {
      try {
        dispatch(updateSpinnerState(true));
        const products = await apiCall(
          apiCallConsts.GET_METHOD,
          '/internal/serviceability/products'
        );
        dispatch(setProductListDropdownInStore(products));
        dispatch(updateSpinnerState(false));
      } catch (error) {
        dispatch(updateSpinnerState(false));
        dispatch(
          updateToastInfo({
            show: true,
            type: 'danger',
            title: t('Failed Getting Products data'),
            message: t(error.message),
          })
        );
      }
    };
    if (Object.keys(productList).length === 0) {
      getProductsList();
    }
  }, []);

  const getAllCompaniesAndCategories = async () => {
    if (
      Object.keys(companies).length > 0 &&
      Object.keys(categories).length > 0
    ) {
      return;
    }
    dispatch(updateSpinnerState(true));
    try {
      const data = await getAllCompaniesAndCategoriesData();
      dispatch(setCompanyAndCategoryDataInStore(data));
      dispatch(updateSpinnerState(false));
    } catch (e) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('Failed Getting Company & Category data'),
          message: t(
            'Please refresh the page to try getting this data again or contact support'
          ),
        })
      );
    }
  };
  const handleSearchClicked = async () => {
    setIsValidInput(true);
    try {
      let FiltredData = data
        .filter((each) => {
          if (vanNo != '') {
            if (each.vanNo == vanNo) {
              return true;
            } else {
              return false;
            }
          } else {
            return true;
          }
        })
        .filter((each) => {
          if (startDate != '') {
            if (new Date(each.createdAt) >= new Date(startDate)) {
              return true;
            } else {
              return false;
            }
          } else {
            return true;
          }
        })
        .filter((each) => {
          if (endDate != '') {
            if (new Date(each.createdAt) <= new Date(endDate)) {
              return true;
            } else {
              return false;
            }
          } else {
            return true;
          }
        })
        .filter((each) => {
          if (orderNo != '') {
            if (each.orderNo == orderNo) {
              return true;
            } else {
              return false;
            }
          } else {
            return true;
          }
        })
        .filter((each) => {
          if (storeCode != '') {
            if (each.storeCode == storeCode) {
              return true;
            } else {
              return false;
            }
          } else {
            return true;
          }
        });

      setData(() => FiltredData);
      dispatch(updateSpinnerState(false));
    } catch (error) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('Failed to Fetch The Data!'),
          message: t(error.message),
        })
      );
    }
  };

  const debounce = (func, delay = 500) => {
    let timeoutId;
    return (...args) => {
      return new Promise((resolve) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          resolve(func(...args));
        }, delay);
      });
    };
  };

  const handleReset = () => {
    setData(data);
    setVanNo((each) => '');
    setStartDate((each) => '');
    setEndDate((each) => '');
    setStatus((each) => '');
    setStoreCode((each) => '');
    setOrderNo((each) => '');
    // handleSearchClicked();
  };
  const fetchOptions = debounce(async (inputValue) => {
    setIsLoadingOptions(true);
    try {
      const filteredOptions = productsListAsOptions.filter((product) =>
        product.value.toLowerCase().includes(inputValue.toLowerCase())
      );
      return filteredOptions;
    } finally {
      setIsLoadingOptions(false);
    }
  });

  const [storeCode, setStoreCode] = useState([]);
  const [status, setStatus] = useState([]);
  const [options, setOptions] = useState(
    returnWarehouseDropdownOptions(storeList, false)
  );
  const [orderNo, setOrderNo] = useState([]);
  const [statusArray, setStatusArray] = useState([
    { label: 'Assigned', value: 'Assigned' },
    { label: 'Accepted', value: 'Accepted' },
    { label: 'Awaiting Invoice', value: 'Awaiting Invoice' },
    { label: 'Invoiced', value: 'Invoiced' },
    { label: 'Ready For Pickup', value: 'Ready For Pickup' },
    { label: 'PickedUp', value: 'PickedUp' },
    { label: 'Delivered', value: 'Delivered' },
  ]);

  // useEffect(() => {
  //   const fetchStore = async () => {
  //     try {
  //       dispatch(updateSpinnerState(true));
  //       const data = await apiCall('get', 'internal/store/all', {});
  //       console.log(returnWarehouseDropdownOptions(data, false));
  //       dispatch(setStoresInStore(data));
  //       setOptions(returnWarehouseDropdownOptions(data, false));
  //       dispatch(updateSpinnerState(false));
  //     } catch (error) {
  //       dispatch(updateSpinnerState(false));
  //       dispatch(
  //         updateToastInfo({
  //           show: true,
  //           type: 'danger',
  //           title: t('failed to fetch'),
  //           message: t(`${error.message}`),
  //         })
  //       );
  //     }
  //   };
  //   if (storeList.length === 0) {
  //     fetchStore();
  //   }
  // }, []);

  const filterObj = [
    { label: 'VAN NO', value: vanNo, func: setVanNo },
    { label: 'ORDER NO', value: orderNo, func: setOrderNo },
    {
      label: 'PRODUCT NAME',
      value: vanNo,
      func: setVanNo,
    },
    { label: 'STORE NAME', value: storeCode, func: setStoreCode },
    { label: 'START DATE', value: startDate, func: setStartDate },
    { label: 'END DATE', value: endDate, func: setEndDate },
  ];

  const handleEmpty = (fun) => {
    fun(() => '');
    handleSearchClicked();
  };
  return (
    <>
      <div
        className="table-settings mt-2 mb-4"
        style={{
          background: '#EAEDF2',

          maxHeight: openFilter ? '500px' : '0px',
          overflow: openFilter ? '' : 'hidden', // Ensure content is hidden when collapsed
          transition: 'max-height 0.7s ease-in-out', // Smooth transition when toggling
        }}
      >
        <div style={{ gap: 8, display: 'flex' }}>
          <Form.Group
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(3, 1fr)', // Three columns
              gap: '20px', // Gap between elements
              width: '100%',
              backgroundColor: 'white',
              padding: 20,
            }}
          >
            <div>
              <Form.Label>VAN NO.</Form.Label>
              <Form.Control
                style={{
                  height: 38,
                  width: '100%',
                }}
                isInvalid={!isValidInput}
                required
                type="text"
                value={vanNo}
                onChange={(event) => {
                  setVanNo(event.target.value);
                }}
                placeholder="Enter the VAN no."
              />
            </div>

            <div>
              <Form.Label>ORDER NO.</Form.Label>
              <Form.Control
                style={{
                  height: 38,
                  width: '100%',
                }}
                isInvalid={!isValidInput}
                required
                type="text"
                value={orderNo}
                onChange={(event) => {
                  setOrderNo(event.target.value);
                }}
                placeholder="Enter the Order no."
              />
            </div>

            <div>
              <Form.Label>PRODUCT NAME</Form.Label>
              <AsyncDropdown
                onChange={(option) => setVanNo(option.value)}
                style={{
                  height: 38,
                  width: '100%',
                }}
                disabled={false}
                fetchOptions={fetchOptions}
                placeholder={t('Select field to Search by')}
                isLoading={isLoadingOptions}
              />
            </div>

            {isAdmin || isSuperAdmin ? (
              <div>
                <Form.Label>STORE NAME</Form.Label>
                <EditableDropDown
                  id={'StoreName'}
                  onChange={(e) => setStoreCode(e.value)}
                  style={{
                    height: 38,
                    width: '100%',
                  }}
                  value={storeCode}
                  options={options}
                  placeholder={'Enter the Store Name'}
                />
              </div>
            ) : (
              <></>
            )}

            <div>
              <Form.Label>START DATE</Form.Label>
              <Form.Control
                style={{
                  height: 38,
                  width: '100%',
                }}
                isInvalid={!isValidInput}
                required
                type="date"
                value={startDate}
                defaultValue={null}
                onChange={(event) => {
                  setIsValidInput(true);
                  setStartDate(event.target.value);
                }}
                placeholder="Start Date"
              />
            </div>

            <div>
              <Form.Label>END DATE</Form.Label>
              <Form.Control
                style={{
                  height: 38,
                  width: '100%',
                }}
                isInvalid={!isValidInput}
                required
                value={endDate}
                type="date"
                defaultValue={null}
                onChange={(event) => {
                  setEndDate(event.target.value);
                }}
                placeholder="End Date"
              />
            </div>

            <div
              style={{
                gridColumn: 'span 3', // Span across all columns
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '10px',
              }}
            >
              <Button
                variant="white"
                className="fa-export"
                onClick={handleReset}
                style={{
                  width: '150px',
                  height: '38px',
                  borderRadius: 0,
                  background: '#EAEDF2',
                }}
              >
                <FontAwesomeIcon icon={faArrowRotateLeft} />
                &nbsp;&nbsp;{t('Reset')}
              </Button>

              <Button
                variant="white"
                className="fa-export"
                onClick={handleSearchClicked}
                style={{
                  width: '150px',
                  height: '38px',
                  borderRadius: 0,
                  border: '1px solid #262B40',
                }}
              >
                <FontAwesomeIcon icon={faSliders} />
                &nbsp;&nbsp;{t('Apply Filter')}
              </Button>
            </div>
          </Form.Group>
        </div>
      </div>

      {!openFilter &&
        (orderNo != '' ||
          vanNo != '' ||
          storeCode != '' ||
          startDate != '' ||
          endDate != '') && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div
              className="gap-2"
              style={{
                display: 'flex',

                width: '100%',
                background: '#F5F8FB',

                padding: 20,
              }}
            >
              {filterObj.map((each) => {
                if (each.value != '') {
                  return (
                    <div>
                      <Form.Label style={{ color: '#262B4099' }}>
                        {each.label}
                      </Form.Label>
                      <Badge
                        variant="light"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          width: '188px',
                          height: '41px',
                          background: 'white',
                          borderRadius: '2px',
                          cursor: 'pointer',
                          color: '#262B40',
                          fontSize: '14px',
                          fontWeight: '400',
                          lineHeight: '16.41px',
                        }}
                      >
                        {each.value}
                        <span
                          onClick={() => {
                            handleEmpty(each.func);
                          }}
                          style={{
                            color: '#262B40',
                            fontSize: '14px',
                            fontWeight: '400',
                            lineHeight: '16.41px',
                          }}
                        >
                          &times;
                        </span>
                      </Badge>
                    </div>
                  );
                }
              })}
            </div>
            <Button
              variant="primary"
              className="fa-export"
              onClick={handleReset}
              style={{
                width: '150px',
                height: '38px',
                borderRadius: 0,
              }}
            >
              <FontAwesomeIcon icon={faBroom} />
              &nbsp;&nbsp;{t('Clear')}
            </Button>
          </div>
        )}
    </>
  );
};

export default FilterOrders;
