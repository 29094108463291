import React, { useState } from 'react';
import OrderPage from './components/OrderPage';
import {
  orderStatus,
  reasonForRejection,
} from '../../../constants/orderStatus';
import { arrayToDropdownOptions } from '../../../utils/sms/arrayToDropdownOptions';
import { useSelector } from 'react-redux';

const Accepted = () => {
  const isAdmin = useSelector(
    (state) => state?.user?.preferences?.isAdmin || false
  );
  const bulkActionOptions = [
    // {
    //   label: 'QC Pass',
    //   value: 'awaitingInvoice',
    // },
    {
      label: 'QC Fail',
      value: 'qcFail',
    },
  ];

  const bulkActionOptionsAdmin = [
    {
      label: 'QC Pass',
      value: 'awaitingInvoice',
    },
    {
      label: 'QC Fail',
      value: 'qcFail',
    },
    ,
    {
      label: 'On Hold',
      value: 'On Hold',
    },
    {
      label: 'Cancel',
      value: 'cancelOrder',
    },
  ];

  const defaultConfirmationProps = {
    awaitingInvoice: [
      {
        type: 'text',
        title: 'Serial No.',
        value: '',
      },
    ],
    rejected: [
      {
        type: 'rejectComponent',
        title: 'Reject',
        options: arrayToDropdownOptions(reasonForRejection),
        value: '',
        remarks: '',
        position: 'bottom',
      },
    ],
    qcFail: [
      {
        type: 'text',
        title: 'Serial No.',
        value: '',
      },
    ],
    cancelled: [
      {
        type: 'text',
        title: 'SRT No',
        value: '',
      },
    ],
  };
  const [confirmationModalProps, setConfirmationModalProps] = useState([]);
  return (
    <div>
      <OrderPage
        type={orderStatus.accepted}
        bulkActionOptions={isAdmin ? bulkActionOptionsAdmin : bulkActionOptions}
        defaultConfirmationProps={defaultConfirmationProps}
        confirmationModalProps={confirmationModalProps}
        setConfirmationModalProps={setConfirmationModalProps}
      />
    </div>
  );
};

export default Accepted;
