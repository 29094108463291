export const Routes = {
  HomePage: {
    path: '/',
  },
  PlanningPage: {
    path: '/delivery/plan',
  },
  Login: {
    path: '/login',
  },
  Warehouse: {
    Add: {
      path: '/warehouse/add',
    },
  },
  Incharge: {
    Add: {
      path: '/incharge/add',
    },
    List: {
      path: '/incharge/list',
    },
  },
  Trips: {
    Temporary: {
      path: '/trips/temporary',
    },
    TemporaryDetail: {
      path: '/trips/temporary/:objectId',
    },
    PrintSheet: {
      path: '/trips/:tripId/print',
    },
    TripsList: {
      path: '/trips/list',
    },
    TripDetail: {
      path: '/trip/:objectId',
    },
    ReceiveStock: {
      path: '/trip/receive-stock',
    },
    ReceiveTemposheet: {
      path: '/trip/receive-temposheet',
    },
  },
  PricingTracker: {
    Home: {
      path: '/pricing',
    },
  },
  Returns: {
    ReturnOK: {
      path: '/return/ok',
    },
    ReturnRegister: {
      path: '/return/register',
    },
    ReturnScheduled: {
      path: '/return/scheduled',
    },
  },
  BuyBack: {
    Pending: {
      path: '/buyback/pending',
    },
    Received: {
      path: '/buyback/received',
    },
    Register: {
      path: '/buyback/register',
    },
    Scheduled: {
      path: '/buyback/scheduled',
    },
    CashRegister: {
      path: '/buyback/cash',
    },
  },
  Defective: {
    PickupPending: {
      path: '/defective/pickup-pending',
    },
    PickupReceived: {
      path: '/defective/pickup-received',
    },
    Register: {
      path: '/defective/register',
    },
    PickupScheduled: {
      path: '/defective/scheduled',
    },
  },
  Replacement: {
    PickupPending: {
      path: '/replacement/pending',
    },
    Register: {
      path: '/replacement/register',
    },
    Scheduled: {
      path: '/replacement/scheduled',
    },
  },
  Challan: {
    Print: {
      path: '/challan/print/:objectId',
    },
  },
  Vendors: {
    List: {
      path: '/vendor/list',
    },
    Add: {
      path: '/vendor/add',
    },
  },
  Vehicles: {
    List: {
      path: '/vehicle/list',
    },
    Add: {
      path: '/vehicle/add',
    },
  },
  Requisitions: {
    Add: {
      path: '/requisitions/add',
    },
    Sent: {
      path: '/requisitions/sent',
    },
    Received: {
      path: '/requisitions/received',
    },
    Print: {
      path: '/requisition/print/:objectId',
    },
    BranchBreakdown: {
      path: '/requisition/branch-breakdown',
    },
    Created: {
      path: '/requisition/created',
    },
    PendingApproval: {
      path: '/requisition/pending-approval',
    },
  },
  Analysis: {
    DeliveryData: {
      path: '/analysis/delivery-data',
    },
    DeliveryAnalysis: {
      path: '/analysis/delivery-analysis',
    },
    PriorityDelivery: {
      path: '/analysis/priority-delivery',
    },
    BranchPickup: {
      path: '/analysis/branch-pickup',
    },
  },
  EWB: {
    Print: {
      path: '/ewb/print/:objectId',
    },
  },
  Reports: {
    DailyDelivery: {
      path: '/reports/daily-delivery',
    },
    VehicleAttendance: {
      path: '/reports/vehicle-attendance',
    },
    MonthlyDelivery: {
      path: '/reports/monthly-delivery',
    },
  },
  Loader: {
    List: {
      path: '/loaders/list',
    },
    Add: {
      path: '/loaders/add',
    },
  },
  Admin: {
    Temposheet: {
      path: '/admin/temposheet',
    },
    Product: {
      Add: {
        path: '/admin/product/add',
      },
    },
    User: {
      Add: {
        path: '/admin/user/add',
      },
      Role: {
        path: '/admin/user/role',
      },
    },
  },
  ModelChange: {
    Pending: {
      path: '/model-change/pending',
    },
    Scheduled: {
      path: '/model-change/scheduled',
    },
    Register: {
      path: '/model-change/register',
    },
  },
  CustomerCare: {
    Add: {
      path: '/customer-care/add',
    },
    Manager: {
      Add: {
        path: '/customer-care/manager/add',
      },
    },
  },
  ExecutiveReports: {
    PendingDelivery: {
      path: '/executive-reports/pending-delivery',
    },
  },
  TeamLead: {
    List: {
      path: '/teamlead/list',
    },
    Add: {
      path: '/teamlead/add',
    },
  },
  WMS: {
    Target: {
      Assign: {
        path: '/wms/target/assign',
      },
      Scanned: {
        path: '/wms/target/scanned',
      },
      Pending: {
        path: '/wms/target/pending',
      },
      Assigned: {
        path: '/wms/target/assigned',
      },
      Completed: {
        path: '/wms/target/completed',
      },
      Cancelled: {
        path: '/wms/target/cancelled',
      },
    },
  },
  OMS: {
    Dashboard: {
      Home: {
        path: '/oms',
      },
    },
    Store: {
      Stores: {
        path: '/oms/store/stores',
      },
    },
    Products: {
      Inventory: {
        path: '/oms/products/inventory',
      },
      Released: {
        path: '/oms/products/released',
      },
      Sync: {
        path: '/oms/products/sync',
      },
      List: {
        path: '/oms/products',
      },
      Details: {
        path: '/oms/products/:vanNo',
      },
      UpdateInventory: {
        path: '/oms/products/updateInventory',
      },
    },
    Orders: {
      Assigned: {
        path: '/oms/orders/assigned',
      },
      Accepted: {
        path: '/oms/orders/accepted',
      },
      Awaiting: {
        path: '/oms/orders/awaiting',
      },
      Invoice: {
        path: '/oms/orders/invoice',
      },
      ReadyForPickup: {
        path: '/oms/orders/ready',
      },
      Picked: {
        path: '/oms/orders/picked',
      },
      Rejected: {
        path: '/oms/orders/rejected',
      },
      Cancelled: {
        path: '/oms/orders/cancelled',
      },
      PendingAction: {
        path: '/oms/orders/pendingaction',
      },
      Details: {
        path: '/oms/orders/:id',
      },
      Delivered: {
        path: '/oms/orders/delivered',
      },
      AutoRejected: {
        path: '/oms/orders/autorejected',
      },
      PreBooked: {
        path: '/oms/orders/prebooked',
      },
      PreRegistered: {
        path: '/oms/orders/preregistered',
      },
      QCFail: {
        path: '/oms/orders/qcfailed',
      },
      Filters: {
        path: '/oms/orders/filters',
      },
      allOrders: {
        path: '/oms/orders/allOrders',
      },
      onHold: {
        path: '/oms/orders/onHold',
      },
      outForDelivery: {
        path: '/oms/orders/outForDelivery',
      },
    },
    Users: {
      List: {
        path: '/oms/users/list',
      },
      AddDeliveryAgent: {
        path: '/oms/vehicle/add',
      },
      DeliveryAgentsList: {
        path: '/oms/vehicle/list',
      },
      AddUser: {
        path: '/oms/users/add',
      },
    },
    Serviceability: {
      Test: {
        path: '/oms/serviceability/test',
      },
      Details: {
        path: '/oms/serviceability/:id',
      },
    },
    DeliveryIntegrations: {
      List: {
        path: '/oms/3pl/list',
      },
      Details: {
        path: '/oms/list/:objectId',
      },
    },
    Returns: {
      Requested: {
        path: '/oms/returns/requested',
      },
      Accepted: {
        path: '/oms/returns/accepted',
      },
      Picked: {
        path: '/oms/returns/picked',
      },
      Received: {
        path: '/oms/returns/received',
      },
      QC: {
        path: '/oms/returns/qc',
      },
      RefundInitiated: {
        path: '/oms/returns/refund',
      },
      Refunded: {
        path: '/oms/returns/refunded',
      },
    },
    DeliveryAgents: {
      ManualAssign: {
        path: '/deliveryagent/manualassign',
      },
      GetHyperLocalOrders: {
        path: '/deliveryagent/getHyperLocalOrders',
      },
      ManageHyperLocal: {
        path: '/deliveryagent/managehyperlocal',
      },
      Analysis: {
        path: '/deliveryagent/analysis',
      },
    },
    RegionOrders: {
      Awaiting: {
        path: '/region/awaiting',
      },
    },
    EndlessAisle: {
      Pending: {
        path: '/endlessAisle/pending',
      },
      Processing: {
        path: '/endlessAisle/processing',
      },
    },
    CustomerPickup: {
      Pending: {
        path: '/customer/pending',
      },
      Completed: {
        path: '/customer/completed',
      },
    },
    GST: {
      Validation: {
        path: '/gst/validation',
      },
    },
    Payment: {
      Authorization: {
        path: '/oms/orders/authorization',
      },
    },
    InterBranchTransfers: {
      Create: {
        path: '/IBT/add',
      },
      Sent: {
        path: '/IBT/sent',
      },
      Received: {
        path: '/IBT/received',
      },
    },
  },
  SMS: {
    Target: {
      AddUser: {
        path: '/sms/scheme/adduser',
      },
      AddScheme: {
        path: '/scheme/add',
      },
      DraftSchemes: {
        path: '/scheme/draft',
      },
      ActiveSchemes: {
        path: '/scheme/ongoing',
      },
      RejectedSchemes: {
        path: '/scheme/rejected',
      },
      ClosedSchemes: {
        path: '/scheme/closed',
      },
      SchemeDetails: {
        path: '/scheme/analysis/:id',
      },
      SMSUserList: {
        path: '/scheme/users',
      },
      SchemeInfo: {
        path: '/scheme/:id/details',
      },
      RedesignAddScheme: {
        path: '/redesignscheme/add/',
      },
      RedesignDraftSchemes: {
        path: '/redesignscheme/draft/',
      },
      RedesignActiveSchemes: {
        path: '/redesignscheme/ongoing',
      },
      RedesignRejectedSchemes: {
        path: '/redesignscheme/rejected',
      },
      RedesignClosedSchemes: {
        path: '/redesignscheme/closed',
      },
      EditScheme: {
        path: '/scheme/:id/edit',
      },
      RedesignSchemeDetails: {
        path: '/scheme/schemeanalysis/:id',
      },
      AddSchemeProduct: {
        path: '/scheme/addProduct',
      },
      SchemeDashboard: {
        path: '/scheme/dashboard',
      },
    },
  },
  SecurityGuard: {
    Add: {
      path: '/security/add',
    },
    EntryList: {
      path: '/dms/security/entry',
    },
  },
  Payments: {
    Pay: {
      path: '/challans/pay',
    },
    Add: {
      path: '/voucher/add',
    },
    Sent: {
      path: '/voucher/sent',
    },
    Received: {
      path: '/voucher/received',
    },
    Print: {
      path: '/voucher/:objectId',
    },
    TripPayment: {
      Unpaid: {
        path: '/trippayments/unpaid',
      },
      Paid: {
        path: '/trippayments/paid',
      },
    },
  },
};
