import { Button, Form } from "@themesberg/react-bootstrap";
import Joi from "joi";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { returnValidationErrorMessages } from "../../utils/validation";
import EditableDropDown from "../../components/common/EditableDropdown";
import { useSelector } from "react-redux";
import { returnWarehouseDropdownOptions } from "../../utils/warehouse";
import { loaderCodeCustomValidation } from "../../utils/planning";

const updateLoaderSchema = Joi.object({
    name: Joi.string().required(),
    loaderCode: Joi.string().required().custom(loaderCodeCustomValidation, "Invalid Loader Code"),
    mobileNo: Joi.number().required().greater(1000000000).less(10000000000),
    agency: Joi.string().required(),
    storeCode: Joi.any().required('Please select a store / warehouse')
}).options({
    allowUnknown: true
});

const LoaderUpdateForm = (props) => {
    const {
        loader,
        closeModal,
        onConfirm,
        add = false
    } = props;
    const { t } = useTranslation();
    const [formData, setFormData] = useState({ ...loader });
    const [formErrors, setFormErrors] = useState({});
    const otherWarehouses = useSelector((state) => state?.user?.otherWarehousesInZone || {});

    const onFormInputChange = (fieldName, value) => {
        setFormData(oldFormData => ({
            ...oldFormData,
            [fieldName]: value
        }));
    };
    const handleFormUpdate = async () => {
        const { error: formErrors = {}, value: formValue } = updateLoaderSchema.validate(formData, {
            abortEarly: false
        });
        const {
            details = {}
        } = formErrors;
        if (details?.length > 0) {
            const validationErrorMessages = returnValidationErrorMessages(details);
            setFormErrors(validationErrorMessages);
        } else {
            onConfirm(formValue);
        }
    };
    return (
        <>
            <Form.Group className="mb-3 mt-4">
                <Form.Label>{t("Loader Code")}</Form.Label>
                <Form.Control
                    required
                    disabled={!add}
                    value={formData.loaderCode}
                    isInvalid={formErrors?.["loaderCode"]?.length}
                    type="text"
                    onChange={(event) => {
                        onFormInputChange("loaderCode", event.target.value);
                    }} />
                {formErrors?.["loaderCode"]?.length && (
                    <Form.Control.Feedback type="invalid">{t("Please enter Loader Code.")}</Form.Control.Feedback>
                )}
            </Form.Group>
            <Form.Group className="mb-3 mt-4">
                <Form.Label>{t("Loader Name")}</Form.Label>
                <Form.Control
                    required
                    value={formData.name}
                    isInvalid={formErrors?.["name"]?.length}
                    type="text"
                    onChange={(event) => {
                        onFormInputChange("name", event.target.value);
                    }} />
                {formErrors?.["name"]?.length && (
                    <Form.Control.Feedback type="invalid">{t(formErrors?.["name"] || "Please enter Loader Name.")}</Form.Control.Feedback>
                )}
            </Form.Group>
            <Form.Group className="mb-3 mt-4">
                <Form.Label>{t("Mobile Number")}</Form.Label>
                <Form.Control
                    required
                    value={formData.mobileNo}
                    isInvalid={formErrors?.["mobileNo"]?.length}
                    type="number"
                    onChange={(event) => {
                        onFormInputChange("mobileNo", event.target.value);
                    }} />
                {formErrors?.["mobileNo"]?.length && (
                    <Form.Control.Feedback type="invalid">{t(formErrors?.["mobileNo"] || "Please enter Mobile Number.")}</Form.Control.Feedback>
                )}
            </Form.Group>
            <Form.Group className="mb-3 mt-4">
                <Form.Label>{t("Agency")}</Form.Label>
                <Form.Control
                    required
                    value={formData.agency}
                    isInvalid={formErrors?.["agency"]?.length}
                    type="text"
                    onChange={(event) => {
                        onFormInputChange("agency", event.target.value);
                    }} />
                {formErrors?.["agency"]?.length && (
                    <Form.Control.Feedback type="invalid">{t(formErrors?.["agency"] || "Please enter Agency Name.")}</Form.Control.Feedback>
                )}
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>{t("Warehouse / Store Name")}</Form.Label>
                <EditableDropDown
                    value={formData.storeCode}
                    onChange={(option) => {
                        const matchingWarehouse = Object.values(otherWarehouses)?.filter(warehouse => warehouse?.warehouseCode === option.value);
                        setFormData(oldData => ({
                            ...oldData,
                            storeCode: option.value,
                            zone: matchingWarehouse[0].zone
                        }))
                    }}
                    options={returnWarehouseDropdownOptions(otherWarehouses)}
                    placeholder={t("Select Warehouse / Store Name")}
                />
                {formErrors?.["storeCode"]?.length > 0 && (
                    <span type="invalid" style={{
                        color: 'red'
                    }}>{t(formErrors?.["storeCode"])}</span>
                )}
            </Form.Group>

            <div className="d-flex justify-content-between">
                <Button variant="secondary" onClick={handleFormUpdate}>
                    {add ? t("Add") : t("Update")}
                </Button>
                <Button variant="link" className="text-gray ms-auto" onClick={closeModal}>
                    {t("Cancel")}
                </Button>
            </div>

        </>
    )
};
export default LoaderUpdateForm;