export const getOrderedVehicleAttendance = (data) => {
  const returnDataItem = [];
  // eslint-disable-next-line no-unused-expressions
  data?.forEach((vehicleData) => {
    let dataObject = {};
    dataObject.vehicleNumber = vehicleData.vehicleNumber;
    dataObject.vehicleType = vehicleData.vehicleType;
    dataObject.vendorName = vehicleData.vendorName;
    dataObject.branchName = vehicleData.warehouseCode;
    dataObject = {
      ...dataObject,
      ...vehicleData.vehicleAttendance,
    };
    dataObject.totalDeliveries = vehicleData.totalDeliveries;
    returnDataItem.push(dataObject);
  });
  return returnDataItem;
};
