import { maxLoaders } from "../constants/dispatch"

export const loadersFieldMap = {
    "AGENCY": "agency",
    "CODE": "loaderCode",
    "DESIGNATION": "designation",
    "MOBILE NO": "mobileNo",
    "WOFF": "weeklyOff",
    "SHIFT": "shift",
    "NAME": "name",
    "JOB": "job",
    "PRESENT/ ABSENT": "present/absent",
    "BRANCH/WH": "branch",
    "EDUCATION": "education",
    "DELIVERY AREA": "deliveryArea",
    "RESIDANCE": "residence",
    "DEPARTMENT": "department",
    "SN": "serialNo"
}

export const returnDropdownOptionsForLoadersList = (options) => {
    return options?.map(loader => {
        return {
            label: `${loader.loaderCode} : ${loader.name}`,
            value: loader
        }
    })
}

export const extractLoaderInfoForTrip = (dispatchData) => {
    const loaderObject = {};
    for (let i = 1; i < maxLoaders; i += 1) {
        if (dispatchData[`loaderName${i}`] || dispatchData[`loaderNumber${i}`]) {
            loaderObject[`Loader Name ${i}`] = dispatchData[`loaderName${i}`];
            loaderObject[`Loader Number ${i}`] = dispatchData[`loaderNumber${i}`];
        }
    }
    return loaderObject;
}