import {
    MARK_PARCEL_AS_DISABLED_IN_STATE,
    RESET_STORE,
    SET_PARCELS_FOR_STAGES_IN_STORE,
    SET_PARCEL_INFO_IN_STORE_FOR_STAGE
} from "../actions/types";

const INITIAL_STATE = {
    returnok: {}
};
const parcelReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_PARCELS_FOR_STAGES_IN_STORE: {
            const {
                stage,
                parcels
            } = action.data;
            return {
                ...state,
                [stage]: parcels
            }
        }
        case MARK_PARCEL_AS_DISABLED_IN_STATE: {
            const {
                pageName,
                parcelId
            } = action.data;
            const stageParcels = Object.assign({}, state[pageName]);
            delete stageParcels[parcelId]
            return {
                ...state,
                [pageName]: stageParcels
            }
        }
        case SET_PARCEL_INFO_IN_STORE_FOR_STAGE: {
            const {
                stage,
                parcelInfo
            } = action.data;
            const {
                objectId,
                data
            } = parcelInfo;
            const stageParcels = Object.assign({}, state[stage]);
            Object.keys(data).forEach(keyName => {
                if (keyName === "date") {
                    stageParcels[objectId][keyName] = data[keyName];
                    stageParcels[objectId]["estimatedDeliveryDate"] = data[keyName];
                } else {
                    stageParcels[objectId][keyName] = data[keyName];
                }
            });
            return {
                ...state,
                [stage]: stageParcels
            }
        }
        case RESET_STORE: {
            return INITIAL_STATE;
        }
        default: return state;
    }
};

export default parcelReducer;