import { Button, Col, Form, Row } from "@themesberg/react-bootstrap";
import JSSoup from "jssoup";
import React, { useState } from "react";
import pricingRaw from "../../constants/pricing.txt";

const Home = () => {
    const [url, seturl] = useState("");
    const [data, setData] = useState({});

    const fetchUrlData = async () => {
        fetch(pricingRaw)
            .then(r => r.text())
            .then(text => {
                const soup = new JSSoup(text);
                // soup.selectOne('h1 > span#productTitle');
                const title = soup.find("span", "product-title-word-break");
                const priceCurrency = soup.find("span", "a-price-symbol")
                const pricevalue = soup.find("span", "a-price-whole")
                // const offersItems = soup.find("div", "a-section vsx__offers multipleProducts");
                const features = soup.find("div", {
                    id: "feature-bullets"
                })
                setData({
                    title: title.contents[0]?._text?.trim(),
                    priceCurrency: priceCurrency?.contents[0]?._text?.trim(),
                    pricevalue: pricevalue?.contents[0]?._text?.trim(),
                })
                // console.log(title.contents[0]?._text?.trim());
                // console.log(priceCurrency?.contents[0]?._text?.trim());
                // console.log(pricevalue?.contents[0]?._text?.trim());
                // console.log(features);
            });
    };
    return (
        <>
            <Row className="mt-4">
                <Col>
                    <Form.Group id="firstName">
                        <Form.Label>Enter Url</Form.Label>
                        <Form.Control required type="text" onChange={(event) => {
                            seturl(event.target.value);
                        }} placeholder="Enter URL to Track" />
                        <Button variant="primary" className="mt-2" onClick={fetchUrlData}>Fetch data</Button>
                    </Form.Group>
                </Col>
                <Col>
                {JSON.stringify(data)}
                </Col>
            </Row>
        </>
    )
}
export default Home;