import React from 'react';
import { returnCustomerAddressString } from '../../../utils/table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

export const serviceablityColumns = (navigate, pincode) => {
  return [
    {
      dataField: 'vanNo',
      text: 'vanno',
    },
    {
      dataField: 'isServiceable',
      text: 'isServiceable',
    },
    {
      dataField: 'isHyperLocal',
      text: 'isHyperLocal',
    },
    {
      dataField: 'maxQuantity',
      text: 'maxQuantity',
    },
    {
      dataField: 'shippingModes',
      text: 'label',
      formatter: (cell, row) => {
        return <div>{cell[0]?.label ? cell[0]?.label : '-'}</div>;
      },
    },
    {
      dataField: 'storePickupList',
      text: 'storeName',
      formatter: (cell, row) => {
        return <div>{cell[0]?.storeName ? cell[0]?.storeName : '-'}</div>;
      },
    },
    {
      dataField: 'view',
      text: 'View',
      formatter: (cell, row) => {
        if (row?.isServiceable) {
          const id = row?.vanNo + pincode;
          return (
            <FontAwesomeIcon
              icon={faArrowRight}
              style={{ cursor: 'pointer' }}
              onClick={() => navigate(`/oms/serviceability/${id}`)}
            />
          );
        } else {
          return '-';
        }
      },
    },
  ];
};

export const serviceablityDetailsColumns = () => {
  return [
    {
      dataField: 'storeName',
      text: 'StoreName',
    },
    {
      dataField: 'storeCode',
      text: 'StoreCode',
    },
    {
      dataField: 'address',
      text: 'Address',
      formatter: (cell, row) => {
        return returnCustomerAddressString(cell);
      },
    },
    {
      dataField: 'pincode',
      text: 'pincode',
    },
    {
      dataField: 'workingHours',
      text: 'workinghours',
    },
    {
      dataField: 'distance',
      text: 'distance',
    },
  ];
};
