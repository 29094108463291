import React, { useState, useEffect } from 'react';
import { Button, Col, Form, Row } from '@themesberg/react-bootstrap';
import DropdownComponent from '../../../components/common/Dropdown';
import Joi from 'joi';
import { useTranslation } from 'react-i18next';
import {
  returnCategoriesDropdownList,
  returnCompaniesDropdownList,
} from '../../../utils/products';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { updateSpinnerState } from '../../../actions/spinner';
import { updateToastInfo } from '../../../actions/settings';
import { returnValidationErrorMessages } from '../../../utils/validation';
import { addNewCompanyCategoryName } from '../../../parse-functions/company';
import {
  setCompanyAndCategoryDataInStore,
  setSubCategoriesDataInStore,
} from '../../../actions/products';
import { getAllCompaniesCategoriesSubCategoriesData } from '../../../parse-functions/sms/scheme';
import { arrayToDropdownOptions } from '../../../utils/sms/arrayToDropdownOptions';

const addNewProductSchema = Joi.object({
  productCategory: Joi.string().required(),
  vanNo: Joi.string().required(),
  companyName: Joi.string().required(),
  productName: Joi.string().required(),
  productCode: Joi.string().required(),
  productSubCategory: Joi.string().required(),
});
const AddProductForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [formErrors, setFormErrors] = useState({});
  const [data, setFormData] = useState({});
  const [subCategories, setSubCategories] = useState([]);
  const companies = useSelector((state) => state?.products?.companies || {});
  const categories = useSelector((state) => state?.products?.categories || {});

  const onFormInputChange = (fieldName, value) => {
    setFormData((oldFormData) => ({
      ...oldFormData,
      [fieldName]: value,
    }));
  };
  const saveNewProduct = async () => {
    try {
      const { error: formErrors = {}, value: formValue } =
        addNewProductSchema.validate(data, {
          abortEarly: false,
        });
      const { details = {} } = formErrors;
      if (details?.length > 0) {
        const validationErrorMessages = returnValidationErrorMessages(details);
        setFormErrors(validationErrorMessages);
      } else {
        await addNewCompanyCategoryName({
          data,
          type: 'product',
        });
        dispatch(
          updateToastInfo({
            show: true,
            type: 'success',
            title: `${data?.productName} added successfully`,
          })
        );
        dispatch(updateSpinnerState(false));
      }
    } catch (e) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('Error Saving Product'),
          message: t('Please try again or contact support'),
        })
      );
    }
  };
  useEffect(() => {
    getAllInfoForProduct();
  }, []);

  const getAllInfoForProduct = async () => {
    dispatch(updateSpinnerState(true));
    try {
      // get all info related to company
      const data = await getAllCompaniesCategoriesSubCategoriesData();
      setSubCategories(
        data.subCategories
          .map((item) => (item.name ? item.name : null))
          .filter(Boolean)
      );
      dispatch(
        setCompanyAndCategoryDataInStore({
          companies: data.companies,
          categories: data.categories,
        })
      );
      dispatch(
        setSubCategoriesDataInStore({
          subCategories: data.subCategories,
        })
      );
      dispatch(updateSpinnerState(false));
    } catch (e) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t(
            'Failed Getting info related to products. Please contact support.'
          ),
        })
      );
    }
  };
  return (
    <Row>
      <Col />
      <Col>
        <div>
          <Form.Label>{t('Choose Company Name')}</Form.Label>
          <DropdownComponent
            onChange={(option) => {
              setFormData((oldData) => ({
                ...oldData,
                companyName: option.value,
              }));
            }}
            options={returnCompaniesDropdownList(companies)}
            placeholder={t('Select Company Name')}
          />
          {formErrors['companyName']?.length > 0 && (
            <div
              type="invalid"
              style={{
                color: 'red',
                fontSize: 14,
                fontStyle: 'italic',
              }}
            >
              {formErrors['companyName']}
            </div>
          )}
        </div>
        <div className="mt-4">
          <Form.Label>{t('Choose Category Name')}</Form.Label>
          <DropdownComponent
            onChange={(option) => {
              setFormData((oldData) => ({
                ...oldData,
                productCategory: option.value,
              }));
            }}
            options={returnCategoriesDropdownList(categories)}
            placeholder={t('Select Category Name')}
          />
          {formErrors['productCategory']?.length > 0 && (
            <div
              type="invalid"
              style={{
                color: 'red',
                fontSize: 14,
                fontStyle: 'italic',
              }}
            >
              {formErrors['productCategory']}
            </div>
          )}
        </div>
        <div className="mt-4">
          <Form.Label>{t('Choose Sub-Category Name')}</Form.Label>
          <DropdownComponent
            onChange={(option) => {
              setFormData((oldData) => ({
                ...oldData,
                productSubCategory: option.value,
              }));
            }}
            options={arrayToDropdownOptions(subCategories)}
            placeholder={t('Select Category Name')}
          />
          {formErrors['productSubCategory']?.length > 0 && (
            <div
              type="invalid"
              style={{
                color: 'red',
                fontSize: 14,
                fontStyle: 'italic',
              }}
            >
              {formErrors['productSubCategory']}
            </div>
          )}
        </div>
        <div className="mt-4">
          <Form.Label>{t('Enter VAN No')}</Form.Label>
          <Form.Control
            placeholder="Enter VAN No"
            required
            type="text"
            onChange={(event) => {
              onFormInputChange('vanNo', event.target.value);
            }}
          />
          {formErrors['vanNo']?.length > 0 && (
            <div
              type="invalid"
              style={{
                color: 'red',
                fontSize: 14,
                fontStyle: 'italic',
              }}
            >
              {formErrors['vanNo']}
            </div>
          )}
        </div>
        <div className="mt-4">
          <Form.Label>{t('Enter Product Name')}</Form.Label>
          <Form.Control
            placeholder="Enter Product Name"
            required
            type="text"
            onChange={(event) => {
              onFormInputChange('productName', event.target.value);
            }}
          />
          {formErrors['productName']?.length > 0 && (
            <div
              type="invalid"
              style={{
                color: 'red',
                fontSize: 14,
                fontStyle: 'italic',
              }}
            >
              {formErrors['productName']}
            </div>
          )}
        </div>
        <div className="mt-4">
          <Form.Label>{t('Enter product Code')}</Form.Label>
          <Form.Control
            placeholder="Enter Product Code"
            required
            type="text"
            onChange={(event) => {
              onFormInputChange('productCode', event.target.value);
            }}
          />
          {formErrors['productCode']?.length > 0 && (
            <div
              type="invalid"
              style={{
                color: 'red',
                fontSize: 14,
                fontStyle: 'italic',
              }}
            >
              {formErrors['productCode']}
            </div>
          )}
        </div>
        <Button
          className="w-100 mt-4"
          type="button"
          onClick={saveNewProduct}
          variant="primary"
        >
          <FontAwesomeIcon icon={faSave} /> &nbsp;&nbsp; Submit
        </Button>
      </Col>
      <Col />
    </Row>
  );
};
export default AddProductForm;
