/* eslint-disable no-unused-expressions */
export const extractkeyValueFromArrayOfObject = (excelData, keyName) => {
    const branchMap = {};
    excelData.forEach((row) => {
        branchMap[row[keyName]] = 1
    });
    return Object.keys(branchMap);
}
export const convertArrayToObj = (data, keyName) => {
    const returnData = {};
    data?.forEach((row) => {
        returnData[row[keyName]] = row
    })
    return returnData;
};

export const sortArrayOfJSONByKeyName = (items, keyName) => {
    if (!(items?.length && keyName)) {
        return items;
    }
    return items?.sort((object1, object2) => {
        if (object1[keyName] < object2[keyName]) {
            return -1;
        }
        if (object1[keyName] > object2[keyName]) {
            return 1;
        }
        return 0;
    });
}

export const sortArrayBasedOnKey = (itemsArray, keyName) => {
    return itemsArray?.sort((object1, object2) => {
        if (object1[keyName] > object2[keyName]) {
            return -1;
        }
        if (object1[keyName] < object2[keyName]) {
            return 1;
        }
        return 0;
    });
}

export const returnArrayUniqueValues = (value, index, array) => {
    return array.indexOf(value) === index;
  }