import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { updateSpinnerState } from '../../../actions/spinner';
import { updateToastInfo } from '../../../actions/settings';
import { useTranslation } from 'react-i18next';
import StatusCard from '../interBranchTransfers/StatusCard';
import { Button, Image } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { apiCallConsts } from '../../../constants/apiCallConsts';
import { apiCall } from '../../../middlewares/api';
import TableOnly from '../../scheme-management/Components/IndividualScheme.js/TableOnly';
import { inventoryColumnsStoreGuy } from '../inventory/utils';
import { InventoryColumns } from './utils';
import {
  returnUserReadableDate,
  returnUserReadableDateTime,
} from '../../../utils/datetime';

const ProductDetails = () => {
  const { t } = useTranslation();
  //   const shortCode = useSelector((state) => state?.user || '');
  //   console.log(shortCode);

  const isAdmin = useSelector(
    (state) => state?.user?.preferences?.isAdmin || false
  );
  const product = useSelector((state) => state?.allProducts?.product || {});
  const [data, setData] = useState([]);
  const [showInventory, setShowInventory] = useState(false);
  const [calculation, setCalculation] = useState({});

  const dispatch = useDispatch();
  const { vanNo } = useParams();

  const calculate = (response) => {
    const { data } = response;
    const calculated = data?.reduce(
      (acc, item) => {
        const available = parseInt(item?.available);
        const blocked = parseInt(item?.inBlocked);
        const process = parseInt(item?.inProcess);
        const defective = parseInt(item?.inDefective || 0);
        const fullSync = item?.lastFullSync?.iso || '';
        const deltaSync = item?.lastDeltaSync?.iso || '';

        if (
          fullSync &&
          (!acc.lastFull || new Date(fullSync) > new Date(acc.lastFull))
        ) {
          acc.lastFull = fullSync;
        }

        if (
          deltaSync &&
          (!acc.lastDelta || new Date(deltaSync) > new Date(acc.lastDelta))
        ) {
          acc.lastDelta = deltaSync;
        }

        acc.saleable += available;
        acc.blocked += blocked;
        acc.inProcess += process;
        acc.defective += defective;
        return acc;
      },
      {
        saleable: 0,
        blocked: 0,
        inProcess: 0,
        defective: 0,
        lastFull: '',
        lastDelta: '',
      }
    );
    let lastSync =
      new Date(calculated?.lastFull) > new Date(calculated?.lastDelta)
        ? calculated?.lastFull
        : calculated?.lastDelta;
    let syncType =
      new Date(calculated?.lastFull) > new Date(calculated?.lastDelta)
        ? 'Full'
        : 'Delta';
    let calculationAndSync = { ...calculated, lastSync, syncType };
    // console.log(calculated);

    setCalculation(calculationAndSync);
    return calculated;
  };
  //   console.log(data);

  const getProductInventory = async () => {
    try {
      dispatch(updateSpinnerState(true));
      const res = await apiCall(
        apiCallConsts.GET_METHOD,
        apiCallConsts.INVENTORY_URL,
        { vanNo, isAdmin, storeCode: '', city: '', region: '' }
      );
      setData(res?.data);
      calculate(res);
      dispatch(updateSpinnerState(false));
    } catch (e) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: 'Failed!',
          message: t(e.message),
        })
      );
    }
  };

  useEffect(() => {
    getProductInventory();
  }, []);
  // console.log(product);

  return (
    <>
      <h5 className="my-4">{t('Product Details')}</h5>
      <div className="d-flex gap-1 p-4 bg-white">
        <div style={{ width: '30%' }}>
          <div
            style={{
              border: '1px solid rgba(46, 54, 80, 1)',
              padding: '10px',
              width: 'fit-content',
              maxWidth: '22.5rem',
            }}
          >
            <Image src={product?.images || ''} alt="image" />
          </div>
        </div>
        <div style={{ width: '70%' }}>
          <div>
            <div className="fw-bold mb-2 fs-5">{product?.name || ''}</div>
            <div
              className="d-flex gap-5"
              style={{
                // maxHeight: '7rem',
                marginBottom: '20px',
              }}
            >
              <div className="d-flex flex-column w-50">
                <div className="d-inline-flex mb-2">
                  <div className="fw-bold" style={{ width: '45%' }}>
                    VAN No:
                  </div>
                  <div>{product?.vanNo || ''}</div>
                </div>

                <div className="d-inline-flex mb-2">
                  <div className="fw-bold" style={{ width: '45%' }}>
                    Product Category:
                  </div>
                  <div>{product?.l3 || ''}</div>
                </div>

                <div className="d-inline-flex  mb-2">
                  <div className="fw-bold" style={{ width: '45%' }}>
                    Brand:
                  </div>
                  <div>{product?.brand || ''}</div>
                </div>
                <div className="d-inline-flex  mb-2">
                  <div className="fw-bold" style={{ width: '45%' }}>
                    Size:
                  </div>
                  <div>{product?.size || 'Large'}</div>
                </div>
              </div>

              {/* Right Section */}
              <div className="d-flex flex-column w-50">
                <div className="d-inline-flex  mb-2">
                  <div className="fw-bold" style={{ width: '45%' }}>
                    MRP:
                  </div>
                  <div>{String(parseFloat(product?.mrp || 0).toFixed(2))}</div>
                </div>

                <div className="d-inline-flex  mb-2">
                  <div className="fw-bold" style={{ width: '45%' }}>
                    VS Price:
                  </div>
                  <div>{String(parseFloat(product?.vsp || 0).toFixed(2))}</div>
                </div>

                <div className="d-inline-flex  mb-2">
                  <div className="fw-bold" style={{ width: '45%' }}>
                    Offer Amount:
                  </div>
                  <div>
                    {String(
                      parseFloat(
                        product?.offerPrice || product?.vsp || 0
                      ).toFixed(2)
                    )}
                  </div>
                </div>

                <div className="d-inline-flex  mb-2">
                  <div className="fw-bold" style={{ width: '45%' }}>
                    Safety Stock:
                  </div>
                  <div>{product?.safetyStockLimit || 0}</div>
                </div>

                <div className="d-inline-flex  mb-2">
                  <div className="fw-bold" style={{ width: '45%' }}>
                    Stock Status:
                  </div>
                  <div style={{ color: 'green' }}>
                    {`In-Stock (${calculation?.saleable || 0} Units)`}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="my-2 py-3"
            style={{
              borderTop: '2px solid rgba(234, 237, 242, 1)',
              borderBottom: '2px solid rgba(234, 237, 242, 1)',
            }}
          >
            <div
              style={{
                fontWeight: '500',
                fontSize: '18px',
                color: 'rgba(46, 54, 80, 1)',
                marginBottom: '10px',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <p> {t('Bins Details')} </p>
              <div
                style={{
                  backgroundColor: 'rgba(234, 237, 242, 1)',
                  width: '23px',
                  height: '24px',
                  textAlign: 'center',
                  //   animation: 'dissolve 300ms ease-out',
                  transform: showInventory ? 'rotate(90deg)' : 'rotate(0deg)',
                  transition: 'transform 300ms ease-out',
                  cursor: 'pointer',
                }}
                onClick={() => setShowInventory(!showInventory)}
              >
                <FontAwesomeIcon
                  size="sm"
                  icon={faChevronRight}
                  color="rgba(46, 54, 80, 1)"
                />
              </div>
            </div>

            <div className="d-flex gap-2">
              <StatusCard
                status={t(
                  `Saleable Quantity: ${calculation?.saleable || 0} Units`
                )}
                bgColor={'rgba(212, 247, 232, 1)'}
                color={'rgba(33, 168, 113, 1)'}
                minWidth={'14rem'}
                height={'2.5rem'}
              />
              <StatusCard
                status={t(
                  `Blocked Quantity: ${calculation?.blocked || 0} Units`
                )}
                bgColor={'rgba(253, 221, 221, 1)'}
                color={'rgba(241, 49, 49, 1)'}
                minWidth={'14rem'}
                height={'2.5rem'}
              />
              <StatusCard
                status={t(
                  `In progress Quantity: ${calculation?.inProcess || 0} Units`
                )}
                bgColor={'rgba(253, 237, 217, 1)'}
                color={'rgba(244, 146, 27, 1)'}
                minWidth={'14rem'}
                height={'2.5rem'}
              />
              {/* <StatusCard
                status={t(
                  `Defective Quantity: ${calculation?.defective || 0} Units`
                )}
                bgColor={'rgba(234, 237, 242, 1)'}
                color={'rgba(46, 54, 80, 1)'}
                minWidth={'14rem'}
                height={'2.5rem'}
              /> */}
            </div>
          </div>
          <div>
            <div
              style={{
                fontWeight: '500',
                fontSize: '18px',
                color: 'rgba(46, 54, 80, 1)',
                marginBottom: '10px',
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '15px',
              }}
            >
              <p> {t('Inventory Sync Details')} </p>
              <div
                style={{
                  backgroundColor: 'rgba(234, 237, 242, 1)',
                  width: '23px',
                  height: '24px',
                  textAlign: 'center',
                  transform: 'rotate(0deg)',
                  transition: 'transform 300ms ease-out',
                }}
              >
                <FontAwesomeIcon
                  size="sm"
                  icon={faChevronRight}
                  color="rgba(46, 54, 80, 1)"
                />
              </div>
            </div>
            <div className="d-flex flex-column">
              <div className="d-inline-flex mb-2">
                <div className="fw-bold" style={{ width: '25%' }}>
                  Date:
                </div>
                <div>{returnUserReadableDate(calculation?.lastSync) || ''}</div>
              </div>
              <div className="d-inline-flex mb-2">
                <div className="fw-bold" style={{ width: '25%' }}>
                  Time:
                </div>
                <div>
                  {returnUserReadableDateTime(calculation?.lastSync)?.split(
                    ','
                  )[2] || ''}
                </div>
              </div>
              <div className="d-inline-flex mb-2">
                <div className="fw-bold" style={{ width: '25%' }}>
                  Status:
                </div>
                <div>
                  {
                    <StatusCard
                      status={t(`Successful`)}
                      bgColor={'rgba(212, 247, 232, 1)'}
                      color={'rgba(33, 168, 113, 1)'}
                      minWidth={'7em'}
                      height={'2.5rem'}
                    />
                  }
                </div>
              </div>
              <div className="d-inline-flex mb-2">
                <div className="fw-bold" style={{ width: '25%' }}>
                  Sync Type:
                </div>
                <div>{calculation?.syncType || ''}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showInventory && (
        <div className="mt-4">
          <TableOnly
            id="productDetails"
            data={data}
            columns={InventoryColumns()}
          />
        </div>
      )}
    </>
  );
};

export default ProductDetails;
