import React, { useEffect, useState } from 'react';
import {
  individualSchemeTable,
  sellOutTable,
} from '../../../../utils/sms/individualSchemeTable';
import PurchaseDataModal from './PurchaseDataModal';
import { getDetailedPurchase } from '../../../../parse-functions/sms/scheme';
import { useDispatch } from 'react-redux';
import { updateToastInfo } from '../../../../actions/settings';
import { updateSpinnerState } from '../../../../actions/spinner';
import TableOnly from './TableOnly';

const PurchaseData = ({ orders }) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [detailedPurchaseData, setDetailedPurchaseData] = useState([]);

  const HandleCloseModal = () => {
    setShowModal(false);
  };

  const fetchDetailedPurchase = async (row) => {
    try {
      dispatch(updateSpinnerState(true));
      const data = await getDetailedPurchase({ PurchaseVNo: row.PurchaseVNo });
      setDetailedPurchaseData(data);
      dispatch(updateSpinnerState(false));
      setShowModal(true);
    } catch (error) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: 'Data fetch Failed',
          message: error.message,
        })
      );
    }
  };
  const handleRowClick = async (row) => {
    if (orders?.type === 'Sell-in') {
      await fetchDetailedPurchase(row);
    } else {
      setShowModal(true);
      setDetailedPurchaseData(
        orders?.data.filter((item) => item.vanNo == row.vanNo)
      );
    }
  };
  return (
    <div>
      <TableOnly
        id="purchaseData"
        data={
          orders.type === 'Sell-in'
            ? orders?.data
            : Object.values(orders?.calculationReference || {})
        }
        columns={
          orders.type === 'Sell-in'
            ? individualSchemeTable(orders?.type)
            : sellOutTable()
        }
        handleRowClick={handleRowClick}
        rowStyle={{ cursor: 'pointer' }}
        showRowBgColor={true}
      />
      <PurchaseDataModal
        showModal={showModal}
        closeModal={HandleCloseModal}
        data={detailedPurchaseData}
        columns={individualSchemeTable(orders?.type)}
      />
    </div>
  );
};

export default PurchaseData;
