import Parse from 'parse';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmationModal from '../../../components/common/confirmationModal';
import arrayToBreadCrumbs from '../../../utils/sms/arrayToBreadCrumbs';
import { updateSpinnerState } from '../../../actions/spinner';
import { updateToastInfo } from '../../../actions/settings';
import ParcelStatusPage from '../../../components/common/parcelStatusPages';
import {
  getDeliveryAgents,
  updateDeliveryAgentInfo,
} from '../../../parse-functions/oms/deliveryAgent';
import { returnDeliveryAgentTableColumns } from './utils';
import DeliveryAgentUpdateModal from './DeliveryAgentUpdateModal';
import { updateUserStatus } from '../../../parse-functions/sms/addUsers';

const DeliveryAgentsList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showInchargeUpdateModal, setShowInchargeUpdateModal] = useState(false);
  const [editUserData, setEditUserData] = useState({});
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [usersData, setUsersData] = useState([]);
  const [updateMode, setUpdateMode] = useState();

  const breadCrumbItems = arrayToBreadCrumbs([['Users'], ['Delivery Agents']]);

  useEffect(() => {
    getDeliveryAgentsList();
  }, []);
  const getDeliveryAgentsList = async () => {
    dispatch(updateSpinnerState(true));
    try {
      const deliveryAgents = await getDeliveryAgents();
      setUsersData(deliveryAgents);
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'success',
          title: t('Delivery Agents List fetched'),
          message: t('All the Delivery Agents'),
        })
      );
    } catch (e) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('Error'),
          message: t(e.message),
        })
      );
    }
  };

  const onRefreshClick = () => {
    setSelectedUsers([]);
    getDeliveryAgentsList();
  };
  const onRowSelect = (selected, objectId) => {
    setUsersData((prev) =>
      prev.map((item) => (item.objectId === objectId ? { ...item, isSelected: selected } : item))
    );
    setSelectedUsers((prev) => {
      if (!selected) {
        return prev.filter((user) => user.objectId !== objectId);
      }
      return [...prev, usersData.find((user) => user.objectId === objectId)];
    });
  };

  const handleShowDeleteModal = () => {
    setShowDeleteModal(true);
  };
  const handleShowVehicleUpdateModal = () => {
    setShowInchargeUpdateModal(true);
  };
  const handleSelectedRowsUpdate = async (actionId) => {
    if (selectedUsers?.length > 0) {
      if (selectedUsers?.length > 1 && actionId === 'edit') {
        dispatch(
          updateToastInfo({
            show: true,
            type: 'danger',
            title: t('Max 1 person to select'),
            message: t('Please select maximum of 1 person for editing'),
          })
        );
        return;
      }
      // we can do edit and delete here
      switch (actionId) {
        case 'active': {
          setUpdateMode('Activate');
          handleShowDeleteModal();
          break;
        }
        case 'inactive': {
          setUpdateMode('Deactivate');
          handleShowDeleteModal();
          break;
        }
        case 'edit': {
          setEditUserData(selectedUsers[0]);
          handleShowVehicleUpdateModal();
          break;
        }
        default: {
          break;
        }
      }
    }
  };
  const handleOnConfirmClick = async (data, status) => {
    try {
      dispatch(updateSpinnerState(true));
      if (status) {
        await updateUserStatus(data);
      } else {
        await updateDeliveryAgentInfo(data);
      }
      getDeliveryAgentsList();
      dispatch(
        updateToastInfo({
          show: true,
          type: 'success',
          title: 'User Update Successful',
          message: '',
        })
      );
      setShowDeleteModal(false);
      setShowInchargeUpdateModal(false);
      setSelectedUsers([]);
    } catch (e) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: 'User Update Failed',
          message: e.message,
        })
      );
    }
  };
  const handleModalOnConfirmClick = () => {
    const data = {
      id: selectedUsers.map((item) => item.userId),
      status: updateMode === 'Activate' ? 'active' : 'inactive',
    };
    handleOnConfirmClick(data, true);
  };
  const handleInchargeUpdateInfo = (data) => {
    const deliveryAgentdata = {
      objectId: data.objectId,
      userId: data.userId,
      vehicleNumber: data.vehicleNumber,
      driverName: data.driverName,
      contactNumber: data.contactNumber,
      zone: data.zone,
      vendorName: data.vendorName,
      tripLimit: data.tripLimit,
      deliveryLimit: data.deliveryLimit,
    };
    handleOnConfirmClick(deliveryAgentdata);
  };

  return (
    <>
      <ParcelStatusPage
        pageType={'DeliveryAgentsList'}
        showDateFilters={false}
        tableData={usersData}
        tableColumns={returnDeliveryAgentTableColumns(onRowSelect)}
        handleRefresh={onRefreshClick}
        pageTitle={t('Delivery Agents List')}
        breadCrumbItems={breadCrumbItems}
        pageSubTitle={t('All the Delivery Agents')}
        searchPlaceHolder={t('Search By Name, Warehouse etc')}
        enableBulkActions={selectedUsers?.length > 0}
        bulkActionOptions={[
          {
            label: 'Edit',
            value: 'edit',
          },
          {
            label: 'Deactivate',
            value: 'inactive',
          },
          {
            label: 'Activate',
            value: 'active',
          },
        ]}
        handleItemBulkUpdate={handleSelectedRowsUpdate}
      />
      <ConfirmationModal
        showModal={showDeleteModal}
        onConfirm={handleModalOnConfirmClick}
        closeModal={() => {
          setShowDeleteModal(false);
        }}
        modalBody={t(`Are you sure you want to ${updateMode} these users?`)}
        modalTitle={t(`${updateMode} Users`)}
      />
      <DeliveryAgentUpdateModal
        closeModal={() => {
          setShowInchargeUpdateModal(false);
        }}
        user={editUserData}
        showModal={showInchargeUpdateModal}
        onConfirm={handleInchargeUpdateInfo}
      />
    </>
  );
};
export default DeliveryAgentsList;
