import React, { useState } from "react";
import { Form, Row, Col } from "@themesberg/react-bootstrap";
import { useTranslation } from "react-i18next";
import { toggleOptions } from "../../pages/warehouse/utils/toggleOptions";

const ToggleSwitch = ({ title, fieldName, formErrors, onFormInputChange }) => {
  const [value, setValue] = useState(false);
  const { t } = useTranslation();

  const handleToggleChange = (event) => {
    const newValue = event?.target?.checked;
    onFormInputChange(fieldName, newValue);
    setValue(newValue);
  };

  return (
    <>
      <Form.Group className="mb-3">
        <Form.Label>{t(title)}</Form.Label>

        <div className="toggleSwitch">
          <Form.Check
            required
            isInvalid={formErrors[fieldName]?.length}
            type="switch"
            onChange={handleToggleChange}
            id={`custom-switch-${fieldName}`}
            name={fieldName}
          />
          <p>{value ? toggleOptions[value] : toggleOptions[value]}</p>
        </div>

        {formErrors[fieldName]?.length && (
          <Form.Control.Feedback type="invalid">
            {t("Please select Yes or No.")}
          </Form.Control.Feedback>
        )}
      </Form.Group>
    </>
  );
};

export default ToggleSwitch;
