import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateSpinnerState } from '../../../actions/spinner';
import { apiCall } from '../../../middlewares/api';
import { apiCallConsts } from '../../../constants/apiCallConsts';
import { useTranslation } from 'react-i18next';
import { updateToastInfo } from '../../../actions/settings';
import { setStoresInStore } from '../../../actions/oms/stores';

export const useFetchStores = () => {
  const storeList = useSelector((state) => state?.stores?.storeList || []);
  const zone = useSelector((state) => state?.user?.warehouse?.zone || '');
  const region = useSelector((state) => state?.user?.preferences?.zone || '');
  const roles = useSelector((state) => state?.user?.preferences?.roles || []);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const fetchStores = useCallback(async () => {
    try {
      dispatch(updateSpinnerState(true));
      const stores = await apiCall(
        apiCallConsts.GET_METHOD,
        `internal/store/all?region=${region?.length > 0 ? region : zone}`
      );
      dispatch(setStoresInStore(stores));
    } catch (e) {
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: 'Failed!',
          message: t(e.message),
        })
      );
    } finally {
      dispatch(updateSpinnerState(false));
    }
  }, [dispatch, t]);

  useEffect(() => {
    if (storeList.length === 0) {
      if (region || roles?.includes('admin')) {
        fetchStores();
      }
    }
  }, [region, zone, roles]);
};
