/* eslint-disable no-unused-expressions */
import {
  SET_SELECTED_TARGETS_IN_STORE,
  RESET_STORE,
  SET_TARGETS_LIST_IN_STORE,
  UPDATE_WAREHOUSE_TARGET_ITEMS_IN_STORE,
  MARK_TARGET_ITEMS_AS_CANCELLED,
} from '../../actions/types';
import { convertArrayToObj } from '../../utils/json';

const INITIAL_STATE = {};
const warehouseTargets = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_SELECTED_TARGETS_IN_STORE: {
      const { id, value, pageName } = action.data;
      let updatedList = state?.[`selected${pageName}`]
        ? JSON.parse(JSON.stringify(state?.[`selected${pageName}`]))
        : [];
      let tableData = state?.[pageName] || [];
      if (value) {
        // add to the list if not already
        if (updatedList.indexOf(id) < 0) {
          updatedList.push(id);
        }
        tableData = tableData.map((each) =>
          each.objectId === id ? { ...each, isSelected: value } : each
        );
      } else {
        // remove from the list
        updatedList = updatedList.filter((item) => item !== id);
        tableData = tableData.map((each) =>
          each.objectId === id ? { ...each, isSelected: value } : each
        );
      }
      return {
        ...state,
        [`selected${pageName}`]: updatedList,
        [pageName]: tableData,
      };
    }
    case SET_TARGETS_LIST_IN_STORE: {
      const { list, pageName } = action.data;
      return {
        ...state,
        [pageName]: list,
        [`selected${pageName}`]: [],
      };
    }
    case UPDATE_WAREHOUSE_TARGET_ITEMS_IN_STORE: {
      const { pageName, data } = action.data;
      const modifiedIdsData = convertArrayToObj(data, 'objectId');
      let updatedList = state?.[`${pageName}`]
        ? JSON.parse(JSON.stringify(state?.[`${pageName}`]))
        : [];
      updatedList?.forEach((orderItem) => {
        if (Object.keys(modifiedIdsData)?.indexOf(orderItem.objectId) > -1) {
          modifiedIdsData?.[orderItem.objectId]?.dataToChange?.forEach(
            (objectToUpdate) => {
              Object.keys(objectToUpdate)?.forEach((keyName) => {
                orderItem[keyName] = objectToUpdate?.[keyName];
              });
            }
          );
        }
      });
      return {
        ...state,
        [pageName]: updatedList,
        [`selected${pageName}`]: [],
      };
    }
    case MARK_TARGET_ITEMS_AS_CANCELLED: {
      const { pageName, selectedTargetItems } = action.data;
      let updatedList = state?.[`${pageName}`]
        ? JSON.parse(JSON.stringify(state?.[`${pageName}`]))
        : [];
      updatedList = updatedList?.filter(
        (item) => selectedTargetItems.indexOf(item.objectId) === -1
      );
      return {
        ...state,
        [pageName]: updatedList,
        [`selected${pageName}`]: [],
      };
    }
    case RESET_STORE: {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
};

export default warehouseTargets;
