import React from "react";
import {
  getTableRowCheckBox,
  returnCustomerAddressString,
} from "../../../utils/table";
import { returnUserReadableDate } from "../../../utils/datetime";

export const productReleaseColumns = (onRowSelect) => {
  return [
    {
      dataField: "objectId",
      text: "Select",
      formatter: (cell, row) => {
        return getTableRowCheckBox(onRowSelect, cell, row?.isSelected, false);
      },
    },
    {
      dataField: "vanNo",
      text: "vanNo",
      formatter: (cell, row) => {
        return <div className="d-block">{cell}</div>;
      },
    },
    {
      dataField: "productName",
      text: "productName",
      formatter: (cell, row) => {
        return returnCustomerAddressString(cell, null, 300);
      },
    },
    {
      dataField: "productCategory",
      text: "Category",
      formatter: (cell, row) => {
        return <div className="d-block">{cell}</div>;
      },
    },
    {
      dataField: "companyName",
      text: "brand",
      formatter: (cell, row) => {
        return <div className="d-block">{cell}</div>;
      },
    },
    {
      dataField: "productSubCategory",
      text: "SubCategory",
      formatter: (cell, row) => {
        return <div className="d-block">{cell}</div>;
      },
    },
    {
      dataField: "productCode",
      text: "code",
      formatter: (cell, row) => {
        return <div className="d-block">{cell}</div>;
      },
    },
    {
      dataField: "size",
      text: "size",
      formatter: (cell, row) => {
        return <div className="d-block">{cell}</div>;
      },
    },
    {
      dataField: "startDate",
      text: "startDate",
      formatter: (cell, row) => {
        return <div className="d-block">{returnUserReadableDate(cell)}</div>;
      },
    },
  ];
};
