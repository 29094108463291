import React, { useEffect, useState } from "react";
import Barcode from "react-barcode";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { updateToastInfo } from "../../actions/settings";
import { updateSpinnerState } from "../../actions/spinner";
import logo from "../../assets/img/brand/vijay-sales-logo.svg";
import { getChallanInfoToPrint } from "../../parse-functions/parcels";
import { returnUserReadableDate } from "../../utils/datetime";

const PrintChallan = () => {
    const { objectId } = useParams();
    const dispatch = useDispatch();
    const [parcelData, setParcelData] = useState({});
    const [otherItems, setOtherItems] = useState([]);

    const { t } = useTranslation();

    useEffect(() => {
        getParcelDataToPrint();
    }, []);

    const getParcelDataToPrint = async () => {
        dispatch(updateSpinnerState(true));
        try {
            // get the print info from the table for this parcel
            const parcelInfo = await getChallanInfoToPrint({ objectId });
            setParcelData(parcelInfo.parcel);
            if (parcelInfo?.otherItems?.length > 0) {
                setOtherItems(parcelInfo.otherItems);
            }
            dispatch(updateSpinnerState(false));
            setPrintAttributesToPage();
        } catch (e) {
            dispatch(updateSpinnerState(false));
            dispatch(updateToastInfo({
                show: true,
                type: 'danger',
                title: 'Failed to get Parcel Data',
                message: t("Failed to get Parcel data from server. Please try again or contact support")
            }));
        }
    }

    const setPrintAttributesToPage = () => {
        const css = '@page { size: portrait; margin: 0mm 0mm 0mm 0mm; color: #000000 !important; }';
        const head = document.head || document.getElementsByTagName('head')[0];
        const style = document.createElement('style');
        style.type = 'text/css';
        style.media = 'print';

        if (style.styleSheet) {
            style.styleSheet.cssText = css;
        } else {
            style.appendChild(document.createTextNode(css));
        }
        head.appendChild(style);
        // window.print();
        // window.close();
    }
    const returnChallanTitle = (parcelData) => {
        if (parcelData?.challanNumber && parcelData?.challanNumber?.startsWith("RC") && parcelData?.newSerialNo) {
            return "Replacement Challan";
        }
        if (parcelData?.challanNumber?.startsWith("PC")) {
            return "Pickup Challan";
        }
    }
    const returnChallanSubTitle = (parcelData) => {
        if (parcelData?.dashboardSubStatus === "buybackpending" && parcelData?.buybackProduct && parcelData?.buyback) {
            return "Buy Back Pickup";
        } else if (parcelData?.dashboardSubStatus === "defectivepending" || parcelData?.mobileSubStatus === "defectivepending") {
            return "Defective Pickup & Delivery";
        } else if (parcelData?.dashboardSubStatus === "modelChange" || parcelData?.mobileSubStatus === "modelChange") {
            return "Product Pickup: Model Change";
        } else if (parcelData?.dashboardSubStatus === "replacementpending" || parcelData?.mobileSubStatus === "replacementpending") {
            return "Defective Pickup Only"
        }
    }
    console.log(otherItems);
    const challanTitle = returnChallanTitle(parcelData);
    const challanSubTitle = returnChallanSubTitle(parcelData);
    const returnProductTable = (parcelData) => {
        return (
            <table className="mb-0 table">
                <thead className="bg-light border-top">
                    <tr>
                        <th className="border-0 text-left">Product</th>
                        <th style={{ whiteSpace: "break-spaces" }} className="h6 fw-bold border-0">{parcelData?.product}</th>
                    </tr>
                </thead>
                <tbody className="border-0">
                    <tr className="border-bottom">
                        <td className="border-0 text-left">Brand</td>
                        <td className="text-left">{parcelData?.brand}</td>
                    </tr>
                    <tr className="border-bottom">
                        <td className="border-0">Product Category</td>
                        <td style={{ whiteSpace: "break-spaces" }}>{parcelData?.productCategory}</td>
                    </tr>
                    {(parcelData?.dashboardSubStatus === "buybackpending" && parcelData?.buybackProduct && parcelData?.buyback) && (
                        <tr className="border-bottom">
                            <td className="border-0">{"Buy back Product"}</td>
                            <th className="text-left" style={{ whiteSpace: "break-spaces" }}>{parcelData?.buybackProduct}</th>
                        </tr>
                    )}
                    <tr className="border-bottom">
                        <td className="border-0">{parcelData?.newSerialNo ? "Old Serial No" : "Serial No"}</td>
                        <th className="text-left">{parcelData?.serialNo}</th>
                    </tr>
                    {parcelData?.newSerialNo && (
                        <tr className="border-bottom">
                            <td className="border-0">New Serial No</td>
                            <th className="text-left">{parcelData?.newSerialNo}</th>
                        </tr>
                    )}
                    <tr className="border-bottom">
                        <td className="border-0">Quantity</td>
                        <td>{Number(parcelData?.quantity).toFixed(0)}</td>
                    </tr>
                    <tr className="border-bottom">
                        <td className="border-0">Remarks</td>
                        <td style={{
                            maxWidth: 500,
                            whiteSpace: "break-spaces"
                        }}>{parcelData?.remarks}</td>
                    </tr>
                </tbody>
            </table>
        )
    };
    return (
        <div className="d-flex" style={{
            color: '#000000 !important',
            fontSize: 12
        }}>
            <div className={otherItems?.length > 0 ? "position-relative p-3 p-md-3 card border-0" : "position-relative p-4 p-md-5 card border-0"}>
                <div className={`d-sm-flex justify-content-between border-bottom border-light ${otherItems?.length > 0 ? "pb-2 pb-md-2 mb-2 mb-md-2" : "pb-4 pb-md-5 mb-4 mb-md-5"}`}>
                    <img alt="Vijay Sales" src={logo} width="200" height="100" className="image-md" />
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column"
                    }}>
                        <h4>{challanTitle}</h4>
                        <h6>{challanSubTitle}</h6>
                    </div>
                </div>
                <div className="d-flex align-items-center justify-content-center mb-1">
                    <h1 className="mb-0">
                        <Barcode
                            height={30}
                            font='"Nunito Sans", sans-serif'
                            fontSize={16}
                            value={parcelData?.challanNumber} />
                    </h1>
                </div>
                <div className="justify-content-between mb-2 mb-md-2 row">
                    <div className="col-sm col">
                        <h5>
                            Customer Information:
                        </h5>
                        <div>
                            <div className="list-group simple-list list-group">
                                <div className="fw-normal list-group-item">
                                    {parcelData?.customerName}
                                </div>
                                <div className="fw-normal list-group-item">
                                    {parcelData?.shippingAddress1}{parcelData?.shippingAddress2}
                                </div>
                                <div className="fw-normal list-group-item">
                                    {parcelData?.shippingAddress3}, {parcelData?.shippingPincode}
                                </div>
                                <div className="fw-normal list-group-item">
                                    {parcelData?.shippingMobileNo || parcelData?.mobileNo}, {parcelData?.email}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm col-lg-4">
                        <div className="text-sm-right row" style={{
                            color: '#000000'
                        }}>
                            <div className="col-6"> <dt><strong>Invoice No.</strong></dt></div>
                            <div className="col-6"> <dd>{parcelData?.invoiceNo}</dd></div>
                            <div className="col-6"> <dt><strong>Invoice Date:</strong></dt></div>
                            <div className="col-6"> <dd>{returnUserReadableDate(parcelData?.date)}</dd></div>
                            <div className="col-6"> <dt><strong>Estimated Date:</strong></dt></div>
                            <div className="col-6"> <dd>{returnUserReadableDate(parcelData?.estimatedDeliveryDate)}</dd></div>
                            <div className="col-6"> <dt><strong>Branch:</strong></dt></div>
                            <div className="col-6"> <dd style={{ whiteSpace: "break-spaces" }}>{parcelData?.branch}</dd></div>
                            <div className="col-6"> <dt><strong>Created Date:</strong></dt></div>
                            <div className="col-6"> <dd style={{ whiteSpace: "break-spaces" }}>{returnUserReadableDate(parcelData?.challanCreatedDate)}</dd></div>
                        </div>
                    </div>
                </div>
                <div className="row" style={{
                    color: '#000000'
                }}>
                    <div className="col-12 mt-2">
                        <div className="printtable table-responsive">
                            {returnProductTable(parcelData)}
                        </div>
                        {otherItems?.length > 0 && (
                            <div className="mt-4">{otherItems?.map(parcel => {
                                return (
                                    <div className="printtable table-responsive">{returnProductTable(parcel)}</div>
                                )
                            })}</div>
                        )}
                        <div className="d-flex justify-content-end text-end mb-4 py-4">
                            <div className="mt-4"><table className="table-clear table"><tbody>
                                <tr><td className="left"><strong>Customer Signature</strong></td><td className="right"></td></tr>
                            </tbody>
                            </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default PrintChallan