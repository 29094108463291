import React, { useState } from "react";
import { Button, Col, Modal, Row } from "@themesberg/react-bootstrap";
import { useTranslation } from "react-i18next";
import AddInvoiceCustomerInfo from "./addManual/addInvoiceCustomerInfo";
import AddProductItemToInvoice from "./addManual/addProductItemToInvoice";
import AddedProductsCardList from "./addManual/addedProductsCardList";
import { returnValidationErrorMessages } from "../../utils/validation";
import Joi from "joi";
import { invoiceNumberValidationSchema } from "../../utils/planning";
import { useDispatch, useSelector } from "react-redux";
import { updateToastInfo } from "../../actions/settings";
import { convertCustomerDataIntoManualInvoiceFormat } from "../../utils/products";
import { addOrdersForVehicle } from "../../actions/vehicles";
import { addParcelToTemporaryTrip } from "../../parse-functions/trips";

const customerInfoSchema = Joi.object({
    invoiceNumber: Joi.string().required().custom(invoiceNumberValidationSchema, "Invalid Invoice Number"),
    address: Joi.string().required(),
    customerName: Joi.string().required(),
    invoiceDate: Joi.date().required(),
    pincode: Joi.number().greater(100000).less(999999),
    customerContactNumber: Joi.number().greater(1000000000).less(9999999999),
    targetBranch: Joi.string().required()
});

const AddManualOrderManual = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const {
        show,
        handleClose,
        invoiceNo,
        isTemporaryPage = false,
        handleParcelInsertInTemporary,
        tripMetaData
    } = props;
    const [productsAdded, setProductsAdded] = useState([]);
    const [customerData, setCustomerData] = useState({});
    const [formErrors, setFormErrors] = useState({});
    const activeVehicleId = useSelector((state) => state.vehicles.activeVehicleId || "");
    const vehicle = useSelector((state) => state?.vehicles?.vehiclesList?.[activeVehicleId] || {});
    const warehouse = useSelector((state) => state.user.warehouse || {});
    const selectedTripId = useSelector((state) => state?.trips?.selectedTrip || null);

    const {
        id
    } = vehicle;

    const onProductAdd = (product) => {
        setProductsAdded(oldProducts => ([...oldProducts, product]));
    }
    const handleAddInvoiceToTrip = async () => {
        // do form validation here
        const { error: formErrors = {}, value: formValue } = customerInfoSchema.validate(customerData, {
            abortEarly: false
        });
        const {
            details = {}
        } = formErrors;
        if (details?.length > 0) {
            const validationErrorMessages = returnValidationErrorMessages(details);
            setFormErrors(validationErrorMessages);
        } else {
            setFormErrors({});
            if (productsAdded?.length > 0) {
                const manualInvoices = convertCustomerDataIntoManualInvoiceFormat(formValue, productsAdded, warehouse?.warehouseCode, warehouse?.zone);
                if (isTemporaryPage) {
                    const parcel = await addParcelToTemporaryTrip({ vehicle: {orders: JSON.parse(JSON.stringify(manualInvoices)), isTransfer: tripMetaData?.isTransfer || false}, tripNumber: selectedTripId, warehouse});
                    handleParcelInsertInTemporary(parcel);
                } else {
                    dispatch(addOrdersForVehicle(manualInvoices, id));
                    dispatch(updateToastInfo({
                        show: true,
                        type: "success",
                        title: "Invoice Added",
                        message: "Manual Added Invoice Added to the temporary trip"
                    }));
                }

                setCustomerData({});
                setProductsAdded([]);
                handleClose();
            } else {
                dispatch(updateToastInfo({
                    show: true,
                    type: 'danger',
                    title: t("Products not added"),
                    message: t("Please consider adding some products to the invoice")
                }))
            }

        }
    }
    const setCustomerInfoData = (data) => {
        setCustomerData(data);
    }

    return (
        <Modal backdrop={"static"} dialogClassName="modal-90w" fullscreen size="xl" show={show} onHide={handleClose}>
            <Modal.Header>
                <Modal.Title className="h6">{t("Add Invoice")}</Modal.Title>
                <Button variant="close" aria-label="Close" onClick={handleClose} />
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col sm={3}>
                        <AddInvoiceCustomerInfo invoiceNo={invoiceNo} setCustomerInfoFormData={setCustomerInfoData} errors={formErrors} />
                    </Col>
                    <Col sm={5}>
                        <AddProductItemToInvoice onProductAdd={onProductAdd} />
                    </Col>
                    <Col sm={4}>
                        <AddedProductsCardList products={productsAdded} />
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleAddInvoiceToTrip}>
                    {t("Add Invoice to trip")}
                </Button>
                <Button variant="link" className="text-gray ms-auto" onClick={handleClose}>
                    {t("Cancel")}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
export default AddManualOrderManual;