import React from 'react';
import { Button, ProgressBar, Modal } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const AchievementModal = (props) => {
  const { t } = useTranslation();
  const {
    notification = false,
    handleCloseClick,
    show,
    handleClose,
    buttonText,
    topTitle,
    mainHeaderText,
    subText,
  } = props;
  return (
    <Modal
      centered
      show={show}
      onHide={handleClose}
      className={`modal-tertiary ${
        notification ? 'order-already-scheduled-modal' : 'achievement-modal'
      }`}
      backdrop={notification ? 'static' : true}
      keyboard={notification ? false : true}
    >
      <Modal.Header className="mx-auto">
        <p className="lead mb-0 text-white">{t(topTitle)}</p>
        {notification && (
          <Button
            variant="close"
            aria-label="Close"
            onClick={handleCloseClick}
          />
        )}
      </Modal.Header>
      <Modal.Body className="pt-0">
        <div className="py-3 px-5 text-center">
          <span className="modal-icon display-1">
            <FontAwesomeIcon
              icon={notification ? faCircleXmark : faThumbsUp}
              className="icon icon-xl text-white"
            />
          </span>

          <Modal.Title className="text-white mb-3">
            <h3>{mainHeaderText}</h3>
          </Modal.Title>
          <p className="mb-4 text-white">{subText}</p>
          <ProgressBar variant="primary" now={100} min={0} max={100} />
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center pt-2 pb-2">
        <Button
          variant="white"
          size="sm"
          className="text-tertiary"
          onClick={handleClose}
        >
          {buttonText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default AchievementModal;
