import React from "react";
import { getTableRowCheckBox, returnCustomerAddressString, returnParcelProductInformation, returnParcelRescheduleInformation, returnParcelReturnScanInformation, returnParcelStatusInformation, returnParcelVehicleInformation, returnParcelVehicleTripInfo } from "../../utils/table";
import { returnUserReadableDate } from "../../utils/datetime";

export const defectivePickupPendingTableColumns = (onRowSelect) => {
    return ([
        {
            dataField: "objectId", text: "Select", formatter: (cell, row) => {
                const isDisabled = row?.challanNumber;
                return getTableRowCheckBox(onRowSelect, cell, row?.isSelected, isDisabled)
            }
        },
        {
            dataField: "customerName", sort: true, text: "Customer Info", formatter: (cell, row) => {
                return returnCustomerAddressString(cell, row?.mobileNo, 200, 30);
            }
        },
        {
            dataField: "shippingAddress", sort: true, text: "Customer Address", formatter: (cell, row) => {
                const customerAddress = `${row?.shippingAddress1}${row?.shippingAddress2}${row?.shippingAddress3}`;
                return returnCustomerAddressString(customerAddress, row?.shippingPincode, 200, 30);
            }
        },
        {
            dataField: "invoiceNo", text: "Invoice Details", formatter: (cell, row) => {
                return (
                    <div className="d-block">
                        <span className="fw-bold">{cell}</span>
                        <div className="fw-normal">{new Date(row?.date).toLocaleDateString()}</div>
                    </div>
                )
            }
        },
        {
            dataField: "challanNumber", text: "Challan & New S.No", formatter: (cell, row) => {
                return (
                    <div className="d-block">
                        <span className="fw-bold">{cell}</span>
                        <div className="fw-normal">{row?.newSerialNo ? `New Serial: ${row?.newSerialNo}` : ""}</div>
                    </div>
                )
            }
        },
        {
            dataField: "serialNo", text: "Product Info", formatter: (cell, row) => {
                return returnParcelProductInformation(row);
            }
        },
        {
            dataField: "status", text: "Current Status", formatter: (cell, row) => {
                return returnParcelStatusInformation(row)
            }
        },
        {
            dataField: "rescheduledBy", text: "Resch. By", formatter: (cell, row) => {
                return returnParcelRescheduleInformation(row)
            }
        }, {
            dataField: "newDeliveryDate", text: "New Del. Date", formatter: (cell, row) => {
                return (
                    <div className="d-block">
                        <div className="fw-normal">{row?.estimatedDeliveryDate ? returnUserReadableDate(row?.estimatedDeliveryDate) : ""}</div>
                        <div className="fw-normal">{row?.remarks ? `Remarks: ${row?.remarks}` : ""}</div>
                    </div>
                )
            }
        },
        {
            dataField: "status", text: "Received Info", formatter: (cell, row) => {
                return returnParcelReturnScanInformation(row);
            }
        },
        {
            dataField: "tripInfo.vehicleNumber", text: "Trip Info", formatter: (cell, row) => {
                return returnParcelVehicleTripInfo(row);
            }
        }
    ])
}

export const defectivePickupReceivedTableColumns = (onRowSelect) => {
    return ([
        {
            dataField: "objectId", text: "Select", formatter: (cell, row) => {
                const isDisabled = row?.receivedBy;
                return getTableRowCheckBox(onRowSelect, cell, row?.isSelected, isDisabled)
            }
        },
        {
            dataField: "customerName", sort: true, text: "Customer Info", formatter: (cell, row) => {
                return returnCustomerAddressString(cell, row?.mobileNo, 200, 30);
            }
        },
        {
            dataField: "shippingAddress", sort: true, text: "Customer Address", formatter: (cell, row) => {
                const customerAddress = `${row?.shippingAddress1}${row?.shippingAddress2}${row?.shippingAddress3}`;
                return returnCustomerAddressString(customerAddress, row?.shippingPincode, 200, 30);
            }
        },
        {
            dataField: "invoiceNo", text: "Invoice Details", formatter: (cell, row) => {
                return (
                    <div className="d-block">
                        <span className="fw-bold">{cell}</span>
                        <div className="fw-normal">{new Date(row?.date).toLocaleDateString()}</div>
                    </div>
                )
            }
        },
        {
            dataField: "serialNo", text: "Product Info", formatter: (cell, row) => {
                return returnParcelProductInformation(row);
            }
        },
        {
            dataField: "status", text: "Current Status", formatter: (cell, row) => {
                return returnParcelStatusInformation(row)
            }
        },
        {
            dataField: "status", text: "Received Info", formatter: (cell, row) => {
                return returnParcelReturnScanInformation(row);
            }
        },
        {
            dataField: "tripInfo.vehicleNumber", text: "Trip Info", formatter: (cell, row) => {
                return returnParcelVehicleTripInfo(row);
            }
        }
    ])
}

export const defectivePickupRegisterTableColumns = (onRowSelect) => {
    return ([
        {
            dataField: "objectId", text: "Select", formatter: (cell, row) => {
                const isDisabled = !row?.rescheduledBy;
                return getTableRowCheckBox(onRowSelect, cell, row?.isSelected, isDisabled)
            }
        },
        {
            dataField: "customerName", sort: true, text: "Customer Info", formatter: (cell, row) => {
                return returnCustomerAddressString(cell, row?.mobileNo, 200, 30);
            }
        },
        {
            dataField: "shippingAddress", sort: true, text: "Customer Address", formatter: (cell, row) => {
                const customerAddress = `${row?.shippingAddress1}${row?.shippingAddress2}${row?.shippingAddress3}`;
                return returnCustomerAddressString(customerAddress, row?.shippingPincode, 200, 30);
            }
        },
        {
            dataField: "invoiceNo", text: "Invoice Details", formatter: (cell, row) => {
                return (
                    <div className="d-block">
                        <span className="fw-bold">{cell}</span>
                        <div className="fw-normal">{new Date(row?.date).toLocaleDateString()}</div>
                    </div>
                )
            }
        },
        {
            dataField: "challanNumber", text: "Challan & New S.No", formatter: (cell, row) => {
                return (
                    <div className="d-block">
                        <span className="fw-bold">{cell}</span>
                        <div className="fw-normal">{row?.newSerialNo ? `New Serial: ${row?.newSerialNo}` : ""}</div>
                    </div>
                )
            }
        },
        {
            dataField: "serialNo", text: "Product Info", formatter: (cell, row) => {
                return returnParcelProductInformation(row);
            }
        },
        {
            dataField: "status", text: "Current Status", formatter: (cell, row) => {
                return returnParcelStatusInformation(row)
            }
        },
        {
            dataField: "rescheduledBy", text: "Resch. By", formatter: (cell, row) => {
                return returnParcelRescheduleInformation(row)
            }
        }, {
            dataField: "newDeliveryDate", text: "New Del. Date", formatter: (cell, row) => {
                return (
                    <div className="d-block">
                        <div className="fw-normal">{row?.estimatedDeliveryDate ? returnUserReadableDate(row?.estimatedDeliveryDate) : ""}</div>
                        <div className="fw-normal">{row?.remarks ? `Remarks: ${row?.remarks}` : ""}</div>
                    </div>
                )
            }
        },
        {
            dataField: "status", text: "Received Info", formatter: (cell, row) => {
                return returnParcelReturnScanInformation(row);
            }
        },
        {
            dataField: "tripInfo.vehicleNumber", text: "Trip Info", formatter: (cell, row) => {
                return returnParcelVehicleTripInfo(row);
            }
        }
    ])
}

export const defectivePickupScheduledTableColumns = (onRowSelect) => {
    return ([
        {
            dataField: "objectId", text: "Select", formatter: (cell, row) => {
                const isDisabled = !row?.rescheduledBy;
                return getTableRowCheckBox(onRowSelect, cell, row?.isSelected, isDisabled)
            }
        },
        {
            dataField: "customerName", sort: true, text: "Customer Info", formatter: (cell, row) => {
                return returnCustomerAddressString(cell, row?.mobileNo, 200, 30);
            }
        },
        {
            dataField: "shippingAddress", sort: true, text: "Customer Address", formatter: (cell, row) => {
                const customerAddress = `${row?.shippingAddress1}${row?.shippingAddress2}${row?.shippingAddress3}`;
                return returnCustomerAddressString(customerAddress, row?.shippingPincode, 200, 30);
            }
        },
        {
            dataField: "invoiceNo", text: "Invoice Details", formatter: (cell, row) => {
                return (
                    <div className="d-block">
                        <span className="fw-bold">{cell}</span>
                        <div className="fw-normal">{new Date(row?.date).toLocaleDateString()}</div>
                    </div>
                )
            }
        },
        {
            dataField: "challanNumber", text: "Challan & New S.No", formatter: (cell, row) => {
                return (
                    <div className="d-block">
                        <span className="fw-bold">{cell}</span>
                        <div className="fw-normal">{row?.newSerialNo ? `New Serial: ${row?.newSerialNo}` : ""}</div>
                    </div>
                )
            }
        },
        {
            dataField: "serialNo", text: "Product Info", formatter: (cell, row) => {
                return returnParcelProductInformation(row);
            }
        },
        {
            dataField: "status", text: "Current Status", formatter: (cell, row) => {
                return returnParcelStatusInformation(row)
            }
        },
        {
            dataField: "rescheduledBy", text: "Resch. By", formatter: (cell, row) => {
                return returnParcelRescheduleInformation(row)
            }
        }, {
            dataField: "newDeliveryDate", text: "New Del. Date", formatter: (cell, row) => {
                return (
                    <div className="d-block">
                        <div className="fw-normal">{row?.estimatedDeliveryDate ? returnUserReadableDate(row?.estimatedDeliveryDate) : ""}</div>
                        <div className="fw-normal">{row?.remarks ? `Remarks: ${row?.remarks}` : ""}</div>
                    </div>
                )
            }
        },
        {
            dataField: "tripInfo.vehicleNumber", text: "Trip Info", formatter: (cell, row) => {
                return returnParcelVehicleTripInfo(row);
            }
        }
    ])
}