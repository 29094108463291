import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { updateToastInfo } from "../../actions/settings";
import { updateSpinnerState } from "../../actions/spinner";
import { setVendorsListInStore } from "../../actions/vendors";
import ParcelStatusPage from "../../components/common/parcelStatusPages";
import { getVendorsListInRegion } from "../../parse-functions/vendors";
import { Routes } from "../../router/routes";
import { convertArrayToObj } from "../../utils/json";
import { returnVendorListTableColumns } from "./vendorsUtils";

const VendorsList = () => {
    const { t } = useTranslation();
    const pageStatus = "vendorsList"
    const dispatch = useDispatch();
    const breadCrumbItems = [{
        title: t("Vendors"),
        link: Routes.Vendors.List.path
    }, {
        title: t("List"),
        link: Routes.Vendors.List.path
    }];
    const zone = useSelector((state) => state?.user?.warehouse?.zone || "");
    const vendorsList = useSelector((state) => state?.vendors?.[pageStatus] || {});

    useEffect(() => {
        if (Object.keys(vendorsList) < 1 && zone) {
            getAvailableVendorList();
        }
    }, [zone]);
    const getAvailableVendorList = async () => {
        dispatch(updateSpinnerState(true));
        try {
            const filters = {
                zone,
                status: "active"
            };
            const vendors = await getVendorsListInRegion(filters);
            dispatch(setVendorsListInStore(convertArrayToObj(JSON.parse(JSON.stringify(vendors)), "objectId")))
            dispatch(updateSpinnerState(false));
            dispatch(updateToastInfo({
                show: true,
                type: 'success',
                title: t('Vehicle Vendors fetched'),
                message: t("All the Vehicle Vendors for the zone are listed here")
            }))

        } catch (e) {
            dispatch(updateSpinnerState(false));
            dispatch(updateToastInfo({
                show: true,
                type: 'danger',
                title: t('Error'),
                message: t(e.message)
            }))
        }
    }

    const onRefreshClick = () => {
        getAvailableVendorList();
    }
    return (
        <ParcelStatusPage
            pageType={pageStatus}
            showDateFilters={false}
            tableData={vendorsList}
            tableColumns={returnVendorListTableColumns()}
            handleRefresh={onRefreshClick}
            pageTitle={t("Vehicle Vendors")}
            breadCrumbItems={breadCrumbItems}
            pageSubTitle={t("All the vendors who are associated with us and provide us with vehicles")}
            searchPlaceHolder={t("Search By Vendor Code")}
            hideBulkActions
            enableBulkActions={false}
        />
    )
}
export default VendorsList