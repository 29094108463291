import Parse from 'parse';

export const addOrUpdateRoles = (data) => {
  return Parse.Cloud.run('addOrUpdateRoles', data);
};

export const getUserPermissions = (data) => {
  return Parse.Cloud.run('getUserPermissions', data);
};

export const getAllRoles = () => {
  return Parse.Cloud.run('getAllRoles');
};
