import React, { useState } from "react";
import Pagination from "react-bootstrap-table2-paginator";
import * as Paginator from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BreadCrumbComponent from "../../components/common/Breadcrumb";
import { Button, ButtonGroup, Card, Col, Form, Row } from "@themesberg/react-bootstrap";
import Table from "react-bootstrap-table-next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { Routes } from "../../router/routes";
import { customSizePerPage, customTotal } from "../../components/common/parcelStatusPages";
import EmptyTable from "../../components/common/EmptyTableView";
import DropdownComponent from "../../components/common/Dropdown";
import { faFilePen, faFilter, faRefresh } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { updateToastInfo } from "../../actions/settings";
import { updateSpinnerState } from "../../actions/spinner";
import { searchParcelResultsByFilterParams } from "../../parse-functions/parcels";
import { deliveryDataTableColumns } from "./analysisUtils";
import { returnRowBGColor } from "../../utils/parcels";
import { returnDeliveryDataItemOptions } from "../../utils/delivery-data";
import DeliveryDataRescheduleModal from "./deliveryDataRescheduleModal";
import { updateClosureDataInParcel } from "../../parse-functions/trips";
import PODImageSlider from "../../components/common/images/podImagesSlider";
import ExcelDownloadReport from "../../components/common/excel/download";
import { returnUserReadableDateTime } from "../../utils/datetime";
// import DateRangeSelector from "../../components/common/DateRangeSelector";

const searchByFields = [{
    label: "Search by Phone Number",
    value: "mobileNo"
}, {
    label: "Search by Invoice No",
    value: "invoiceNo"
}, {
    label: "Search by Serial No",
    value: "serialNo"
}, {
    label: "Search by Customer Name",
    value: "customerName"
}, {
    label: "Search by Product Code",
    value: "productCode"
}, {
    label: "Search by Product Name",
    value: "productName"
}, {
    label: "Search by Product Category",
    value: "productCategory"
}];

const DeliveryData = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [searchData, setSearchData] = useState("");
    const [parcels, setParcels] = useState([]);
    const [searchByFieldName, setSearchByFieldName] = useState("");
    const [isValidInput, setIsValidInput] = useState(true);
    const [podImages, setShowPODImages] = useState([]);
    const [podImageDescription, setPODImageDescription] = useState("");
    const [showPODImagesModal, setShowPODImagesModal] = useState(false);
    const [enableBulkUpdate, setEnableBulkUpdate] = useState(false);
    const [bulkUpdateOption, setBulkUpdateOption] = useState(null);
    const [showRescheduleModal, setShowRescheduleModal] = useState(false);
    const username = useSelector((state) => state?.user?.username || "");
    const warehouse = useSelector((state) => state?.user?.warehouse || {});

    const breadCrumbItems = [{
        title: t("Analysis"),
        link: Routes.Analysis.DeliveryData.path
    }, {
        title: t("Delivery Data"),
        link: ""
    }];
    const handleDisplayHideToaster = (show, type, message, title) => {
        dispatch(updateToastInfo({
            show: true,
            type: type,
            message: t(message),
            title: t(title)
        }));
    }
    const handleSearchClicked = async () => {
        if (searchByFieldName?.length < 1) {
            handleDisplayHideToaster(true, "danger", "Please select atleast one field from the options", "No Field Selected");
        } else {
            dispatch(updateSpinnerState(true));
            try {
                const results = await searchParcelResultsByFilterParams({
                    filters: {
                        [searchByFieldName]: searchData
                    }
                });
                if (results?.length > 0) {
                    setParcels(results);
                    handleDisplayHideToaster(true, "success", "Following parcels found", "Parcel found");
                } else {
                    handleDisplayHideToaster(true, "danger", "No matching parcels found.", "Please try to refine your query");
                }
                dispatch(updateSpinnerState(false));
            } catch (e) {
                dispatch(updateSpinnerState(false));
            }
        }
    }
    const onRowSelect = (value, objectId) => {
        const updatedParcels = JSON.parse(JSON.stringify(parcels));
        updatedParcels.forEach((parcel) => {
            if (parcel.objectId === objectId) {
                parcel.isSelected = value;
            }
        });
        setParcels(updatedParcels);
        const itemsSelected = updatedParcels.filter(parcel => parcel.isSelected);
        setEnableBulkUpdate(itemsSelected?.length > 0);
    }
    const handleBulkUpdateClick = () => {
        if (bulkUpdateOption?.length > 0) {
            switch (bulkUpdateOption) {
                case "reschedule": {
                    setShowRescheduleModal(true);
                    break;
                }
                default: {
                    break;
                }
            }
        } else {
            dispatch(updateToastInfo({
                show: true,
                type: "danger",
                title: t("Please select an operation"),
                message: t("Please choose an option from the available dropdown bulk update actions")
            }))
        }
    };
    const handleRescheduleSubmit = async (selectedOption) => {
        try {
            dispatch(updateSpinnerState(true));
            setShowRescheduleModal(false);
            const remarks = "";
            const selectedParcels = parcels.filter(parcel => parcel?.isSelected);
            const selectedParcelIds = selectedParcels?.map(parcel => parcel.objectId);
            await updateClosureDataInParcel({
                ids: selectedParcelIds,
                data: {
                    dashboardStatus: selectedOption.status,
                    dashboardSubStatus: selectedOption.subStatus,
                    remarks,
                    reopenedBy: username,
                    shortCode: warehouse?.shortCode,
                    warehouseCode: warehouse?.warehouseCode
                }
            });
            const updatedParcels = JSON.parse(JSON.stringify(parcels));
            updatedParcels.forEach((parcel) => {
                if (selectedParcelIds?.indexOf(parcel.objectId) > -1) {
                    parcel.dashboardStatus = selectedOption.status;
                    parcel.dashboardSubStatus = selectedOption.subStatus;
                    parcel.remarks = remarks;
                    parcel.isSelected = false;
                }
            });
            setParcels(updatedParcels)
            dispatch(updateSpinnerState(false));
            dispatch(updateToastInfo({
                show: true,
                type: "success",
                title: t("Reschedule Completed"),
                message: t("Please go to respective Page to reschedule delivery / pickup")
            }))
        } catch (e) {
            dispatch(updateSpinnerState(false));
            dispatch(updateToastInfo({
                show: true,
                type: "danger",
                title: t("Reschedule Failed"),
                message: e.message || t("Please contact support if you see this issue again")
            }))

        }
    }
    const handleShowPODImagesModal = (images, parcelUpdateLocation) => {
        setShowPODImages(images);
        if (parcelUpdateLocation?.time) {
            setPODImageDescription(`Image taken at: ${returnUserReadableDateTime(parcelUpdateLocation?.time)}`);
        }
        setShowPODImagesModal(true);
    };
    return (
        <div>
            <ToolkitProvider
                exportCSV
                keyField="id"
                search={true}
                sort
                columns={deliveryDataTableColumns(onRowSelect, handleShowPODImagesModal)}
                data={parcels}
                wrapperClasses="table-responsive"
            >
                {({ baseProps, searchProps, csvProps }) => (
                    <Paginator.PaginationProvider pagination={
                        Pagination({
                            custom: true,
                            showTotal: true,
                            alwaysShowAllBtns: true,
                            totalSize: parcels?.length,
                            paginationTotalRenderer: customTotal,
                            sizePerPageRenderer: customSizePerPage
                        })
                    }>
                        {({ paginationProps, paginationTableProps }) => (
                            <>
                                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                                    <div className="d-block mb-4 mb-md-0">
                                        <BreadCrumbComponent
                                            items={breadCrumbItems}
                                        />
                                        <h4>{t("Delivery Data")}</h4>
                                        <p className="mb-0">{t("Search for customer information using Phone number / name")}</p>
                                    </div>
                                    <div className="btn-toolbar mb-2 mb-md-0">
                                        <ButtonGroup>
                                            <Button
                                                onClick={() => {
                                                    handleSearchClicked();
                                                }}
                                                variant="outline-primary"
                                                size="sm">
                                                <FontAwesomeIcon icon={faRefresh} />&nbsp;&nbsp;{t("Refresh")}
                                            </Button>
                                            <ExcelDownloadReport
                                                data={parcels || []}
                                                fileName={"download-data.csv"}
                                            />
                                        </ButtonGroup>
                                    </div>
                                </div>
                                {/* <div>
                                    <DateRangeSelector
                                        showSubmit={false}
                                    />
                                </div> */}
                                <div className="table-settings mt-2 mb-4">
                                    <div style={{ gap: 8, display: "flex" }}>
                                        <DropdownComponent
                                            onChange={(option) => {
                                                setSearchByFieldName(option.value);
                                            }}
                                            defaultValue={searchByFieldName}
                                            width={300}
                                            disabled={false}
                                            options={searchByFields}
                                            placeholder={t("Select field to Search by")}
                                        />
                                        <Form.Group>
                                            <Form.Control
                                                style={{
                                                    height: 38,
                                                    display: "flex",
                                                    alignItems: "center"
                                                }}
                                                isInvalid={!isValidInput}
                                                required
                                                type="text"
                                                defaultValue={searchData}
                                                onChange={(event) => {
                                                    setSearchData(event.target.value)
                                                }}
                                                onKeyDown={(event) => {
                                                    if (event.nativeEvent.code === "Enter") {
                                                        handleSearchClicked();
                                                    }
                                                }}
                                                placeholder={t("Search Value")} />
                                            {!isValidInput && (
                                                <Form.Control.Feedback type="invalid">{t("Please add value to search")}</Form.Control.Feedback>
                                            )}
                                        </Form.Group>
                                        <Button
                                            style={{
                                                height: 38
                                            }}
                                            variant="primary"
                                            onClick={handleSearchClicked}
                                        >
                                            <FontAwesomeIcon icon={faFilter} />&nbsp;&nbsp; {t("Filter")}
                                        </Button>
                                    </div>
                                </div>
                                <Card>
                                    <div className="table-responsive py-2">
                                        <Card.Header style={{
                                            padding: "8px 12px"
                                        }}>
                                            <Row className="d-flex justify-content-between">
                                                <Col xs={12} md={4}>
                                                    <Row className="d-flex">
                                                        <Col>
                                                            <DropdownComponent
                                                                onChange={(option) => {
                                                                    setBulkUpdateOption(option.value);
                                                                }}
                                                                width={300}
                                                                disabled={!enableBulkUpdate}
                                                                options={returnDeliveryDataItemOptions()}
                                                                placeholder={t("Select an operation")}
                                                            />
                                                        </Col>
                                                        <Col className="d-flex">
                                                            <Button
                                                                onClick={() => {
                                                                    handleBulkUpdateClick();
                                                                }}
                                                                disabled={!enableBulkUpdate}
                                                                variant="primary"
                                                                size="sm">
                                                                <FontAwesomeIcon icon={faFilePen} />&nbsp;&nbsp;{t("Apply")}
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col xs={12} md={4} className="d-flex justify-content-md-end">
                                                    <Search.SearchBar {...searchProps} placeholder={t("Search any value in table here")} />
                                                    <Search.ClearSearchButton {...searchProps} />
                                                </Col>
                                            </Row>
                                        </Card.Header>
                                        <Table
                                            rowClasses={(row) => {
                                                const rowBGColor = returnRowBGColor(row?.dashboardStatus || row?.mobileStatus || row?.status);
                                                return `${rowBGColor} border-bottom`;
                                            }}
                                            noDataIndication={<EmptyTable />}
                                            {...baseProps}
                                            {...paginationTableProps}
                                            bodyClasses="border-0"
                                            classes="table-flush dataTable-table"
                                        />

                                        <Card.Footer>
                                            <Row>
                                                <Col xs={12} md={4} className="py-1">
                                                    <Paginator.SizePerPageDropdownStandalone {...paginationProps} />
                                                </Col>
                                                <Col xs={12} md={4} className="d-flex align-items-center py-1">
                                                    <Paginator.PaginationTotalStandalone {...paginationProps} />
                                                </Col>
                                                <Col xs={12} md={4} className="d-flex justify-content-md-end align-items-center mb-0 py-1">
                                                    <Paginator.PaginationListStandalone {...paginationProps} />
                                                </Col>
                                            </Row>
                                        </Card.Footer>
                                    </div>
                                </Card>
                            </>
                        )}
                    </Paginator.PaginationProvider>
                )}
            </ToolkitProvider>
            <DeliveryDataRescheduleModal
                showModal={showRescheduleModal}
                closeModal={() => {
                    setShowRescheduleModal(false)
                }}
                onSubmit={handleRescheduleSubmit} />
            <PODImageSlider
                description={podImageDescription}
                show={showPODImagesModal}
                handleClose={setShowPODImagesModal}
                images={podImages} />
        </div>
    )
}
export default DeliveryData;