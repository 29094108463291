import React from 'react';
import OrderPage from './components/OrderPage';
import { orderStatus } from '../../../constants/orderStatus';

const Cancelled = () => {
  const bulkActionOptions = [
    {
      label: 'Refund Initated',
      value: 'refundInitated',
    },
    {
      label: 'Stop Cancellation',
      value: 'stopCancellation',
    },
    {
      label: 'Reassign',
      value: 'assign',
    },
  ];
  return (
    <div>
      <OrderPage
        type={orderStatus.cancelled}
        bulkActionOptions={bulkActionOptions}
      />
    </div>
  );
};

export default Cancelled;
