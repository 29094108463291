import Parse from "parse";

export const allocateWarehouseTargetToTeamleads = (data) => {
    return Parse.Cloud.run("allocateWarehouseTargetToTeamleads", data);
}

export const getTargetsForTeamLeadsInWarehouse = (data) => {
    return Parse.Cloud.run("getTargetsForTeamLeadsInWarehouse", data);
}

export const updateWarehouseTargetData = (data) => {
    return Parse.Cloud.run("updateWarehouseTargetData", data);
}

export const markTargetItemsAsStatusChange = (data) => {
    return Parse.Cloud.run("markTargetItemsAsStatusChange", data);
}