import React, { useState } from 'react';
import OrderReturnPage from './components/OrderReturnPage';
import { returnOrderStatus } from '../../../constants/orderStatus';

const Picked = () => {
  const bulkActionOptions = [
    {
      label: 'Received',
      value: 'received',
    },
    {
      label: 'Refund Completed',
      value: 'earlyRefund',
    },
  ];
  const defaultConfirmationProps = {
    earlyRefund: [
      {
        type: 'date',
        title: 'Transaction Date',
        value: '',
      },
      {
        type: 'text',
        title: 'Amount',
        value: '',
      },
      {
        type: 'text',
        title: 'Transaction No.',
        value: '',
      },
      {
        type: 'text',
        title: 'Mode',
        value: '',
      },
      {
        type: 'textArea',
        title: 'Remarks',
        value: '',
      },
    ],
  };

  const [confirmationModalProps, setConfirmationModalProps] = useState([]);

  return (
    <>
      <OrderReturnPage
        type={returnOrderStatus.PICKED}
        bulkActionOptions={bulkActionOptions}
        defaultConfirmationProps={defaultConfirmationProps}
        confirmationModalProps={confirmationModalProps}
        setConfirmationModalProps={setConfirmationModalProps}
      />
    </>
  );
};

export default Picked;
