import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Breadcrumb, Button, Col, Form, Row } from "@themesberg/react-bootstrap";
import Joi from "joi";
import React, { useState } from "react"
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { updateToastInfo } from "../../actions/settings";
import { updateSpinnerState } from "../../actions/spinner";
import { returnValidationErrorMessages } from "../../utils/validation";
import { addNewCustomerCareUser } from "../../parse-functions/customercare";
import DropdownComponent from "../../components/common/Dropdown";
import { returnWarehouseDropdownOptions } from "../../utils/warehouse";

const addCCUserSchema = Joi.object({
    name: Joi.string().required(),
    username: Joi.string().required(),
    password: Joi.string().required(),
    repeatPassword: Joi.ref('password'),
    branchName: Joi.string().allow('')
})
const AddIncharge = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [formErrors, setFormErrors] = useState({});
    const [formData, setFormData] = useState({
        name: '',
        username: '',
        password: '',
        repeatPassword: '',
        branchName: ''
    });
    const otherWarehouses = useSelector((state) => state?.user?.otherWarehousesInZone || {});
    const onFormInputChange = (fieldName, value) => {
        setFormData(oldFormData => ({
            ...oldFormData,
            [fieldName]: value
        }));
    };
    const handleFormSubmit = async () => {
        const { error: formErrors = {}, value: formValue } = addCCUserSchema.validate(formData, {
            abortEarly: false
        });
        const {
            details = {}
        } = formErrors;
        if (details?.length > 0) {
            const validationErrorMessages = returnValidationErrorMessages(details);
            setFormErrors(validationErrorMessages);
        } else {
            dispatch(updateSpinnerState(true));
            try {
                await addNewCustomerCareUser(formValue);
                dispatch(updateToastInfo({
                    show: true,
                    type: "success",
                    title: t("Incharge Added"),
                    message: t(`${formData.name} added successfully`)
                }))
            } catch (error) {
                // inside error
                const {
                    message,
                    code
                } = error;
                setFormErrors(message);
                dispatch(updateToastInfo({
                    show: true,
                    type: "danger",
                    title: t("Incharge Add Failed"),
                    message: code || t(`${formData.name} failed to add`)
                }))
            }
            dispatch(updateSpinnerState(false));
        }
    };
    return (
        <div className="mt-1">
            <div className="mb-4 d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-2">
                <div className="d-block mb-4 mb-md-0">
                    <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
                        <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
                        <Breadcrumb.Item>{t("Customer Care")}</Breadcrumb.Item>
                        <Breadcrumb.Item active>{t("Add")}</Breadcrumb.Item>
                    </Breadcrumb>
                    <h4>{t("Add New CC User")}</h4>
                    <p className="mb-0">{t("Use this page to add a new CC User")}</p>
                </div>
            </div>

            <Row className="mt-3">
                <Col />
                <Col>
                    <Alert variant="info">
                        <FontAwesomeIcon icon={faHome} />&nbsp;&nbsp;{t("Info")}
                    </Alert>
                    <Form.Group className="mb-3 mt-4">
                        <Form.Label>{t("Name / Identifier")}</Form.Label>
                        <Form.Control
                            required
                            isInvalid={formErrors["name"]?.length}
                            type="text"
                            onChange={(event) => {
                                onFormInputChange("name", event.target.value);
                            }} />
                        {formErrors["name"]?.length && (
                            <Form.Control.Feedback type="invalid">{t("Please enter Incharge name / identifier.")}</Form.Control.Feedback>
                        )}

                    </Form.Group>
                    <Form.Group className="mb-3 mt-4">
                        <Form.Label>{t("Branch, if this is a Branch CC")}</Form.Label>
                        <DropdownComponent
                        onChange={(option) => {
                            onFormInputChange("branchName", option?.value);
                        }}
                        options={returnWarehouseDropdownOptions(otherWarehouses)}
                        placeholder={t('Select only if this is Branch CC')}
                    />
                        {formErrors["branchName"]?.length && (
                            <Form.Control.Feedback type="invalid">{t("Please enter Incharge name / identifier.")}</Form.Control.Feedback>
                        )}

                    </Form.Group>
                    <Form.Group className="mb-3 mt-4">
                        <Form.Label>{t("UserName")}</Form.Label>
                        <Form.Control required isInvalid={formErrors["username"]?.length} type="text" onChange={(event) => {
                            onFormInputChange("username", event.target.value);
                        }} />
                        {formErrors["username"]?.length && (
                            <Form.Control.Feedback type="invalid">{t("Please enter a username")}</Form.Control.Feedback>
                        )}

                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>{t("Password")}</Form.Label>
                        <Form.Control required isInvalid={formErrors["password"]?.length} type="text" onChange={(event) => {
                            onFormInputChange("password", event.target.value);
                        }} />
                        {formErrors["password"]?.length && (
                            <Form.Control.Feedback type="invalid">{t("Please enter a password")}</Form.Control.Feedback>
                        )}

                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>{t("Repeat Password")}</Form.Label>
                        <Form.Control required isInvalid={formErrors["repeatPassword"]?.length} type="text" onChange={(event) => {
                            onFormInputChange("repeatPassword", event.target.value);
                        }} />
                        {formErrors["repeatPassword"]?.length && (
                            <Form.Control.Feedback type="invalid">{t("Please enter the same password as above")}</Form.Control.Feedback>
                        )}

                    </Form.Group>
                    <Row className="d-flex justify-content-between mt-5">
                        <Button
                            style={{ width: '98%' }}
                            className="mx-2"
                            variant="primary"
                            onClick={handleFormSubmit}
                        >{t("Create User")}</Button>
                        <Button className="mt-3" variant="outline" >{t("Cancel")}</Button>
                    </Row>
                </Col>
                <Col />
            </Row>

        </div>
    )
}
export default AddIncharge