import { Button, Form, Modal, Table } from "@themesberg/react-bootstrap";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { updateToastInfo } from "../../actions/settings";

const DisplayBranchMismatchDataModal = (props) => {
    const {
        showModal,
        handleClose,
        missingBranches,
        onMissingDataSubmit,
        zone
    } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({});

    const handleUpdatedInfoForBranch = () => {
        let missingData = false;
        // eslint-disable-next-line no-unused-expressions
        missingBranches?.forEach((branchName) => {
            if (!formData?.[branchName]?.address || !formData?.[branchName]?.pincode) {
                missingData = true;
            }
        });
        if (missingData) {
            dispatch(updateToastInfo({
                show: true,
                type: "danger",
                title: "Missing Data",
                message: "Please fill all available fields"
            }));
        } else {
            onMissingDataSubmit(formData)
        }
    };

    const handleInputValueChange = (value, branchName, fieldName) => {
        if (!formData[branchName]) {
            formData[branchName] = {
                zone
            };
        }
        formData[branchName][fieldName] = value;
        setFormData(formData)
    }

    const TableRow = (props) => {
        return (
            <tr>
                <td>
                    {props.branchName}
                </td>
                <td>
                <Form.Control
                    required
                    type="text"
                    placeholder="Enter Address"
                    onChange={(event) => {
                        handleInputValueChange(event.target.value, props.branchName, "address")
                    }} />
                </td>
                <td>
                <Form.Control
                    required
                    type="text"
                    placeholder="Enter Pincode"
                    onChange={(event) => {
                        handleInputValueChange(event.target.value, props.branchName, "pincode")
                    }}
                    />
                </td>
            </tr>
        )
    }
    return (
        <Modal backdrop={"static"} dialogClassName="modal-90w" size="lg" show={showModal} onHide={handleClose}>
            <Modal.Header>
                <Modal.Title className="h6">{t("Add Missing Data")}</Modal.Title>
                <Button variant="close" aria-label="Close" onClick={handleClose} />
            </Modal.Header>
            <Modal.Body style={{
                maxHeight: 500,
                overflowY: "scroll"
            }}>
                <Table responsive className="align-items-center table-flush">
                    <thead className="thead-light">
                        <tr>
                            <th scope="col w-10">Branch Name</th>
                            <th scope="col">Address</th>
                            <th scope="col">Pincode</th>
                        </tr>
                    </thead>
                    <tbody>
                        {missingBranches.map(pv => <TableRow branchName={pv} />)}
                    </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="tertiary" onClick={handleClose}>
                        {t("Cancel")}
                    </Button>
                    <Button variant="secondary" onClick={handleUpdatedInfoForBranch}>
                        {t("Update")}
                    </Button>
            </Modal.Footer>
        </Modal>
    )
}
export default DisplayBranchMismatchDataModal