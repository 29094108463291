import React from "react";
import { getTableRowCheckBox } from "../../utils/table";
import { getTripBadgeStatus } from "../../utils/trips";
import { Badge } from "@themesberg/react-bootstrap";

export const returnLoadersListTableColumns = (onRowSelect) => {
    return ([
        {
            dataField: "objectId", text: "Select", formatter: (cell, row) => {
                return getTableRowCheckBox(onRowSelect, cell, row?.isSelected, false)
            }
        },
        {
            dataField: "loaderCode", sort: true, text: "CODE", formatter: (cell, row) => {
                return (
                    <div className="d-block">
                        <span className="fw-bold">{cell}</span>
                    </div>
                )
            }
        },
        {
            dataField: "name", text: "NAME", formatter: (cell, row) => {
                return (
                    <div className="d-block">
                        <span className="fw-bold">{cell}</span>
                    </div>
                )
            }
        },
        {
            dataField: "mobileNo", text: "MOBILE NO", formatter: (cell, row) => {
                return (
                    <div className="d-block">
                        <span className="fw-bold">{cell}</span>
                    </div>
                )
            }
        },
        {
            dataField: "storeCode", sort: true, text: "StoreCode", formatter: (cell, row) => {
                return (
                    <div className="d-block">
                        <span className="fw-bold">{cell}</span>
                    </div>
                )
            }
        },
        {
            dataField: "department", text: "DEPARTMENT", formatter: (cell, row) => {
                return (
                    <div className="d-block">
                        <span className="fw-bold">{cell}</span>
                    </div>
                )
            }
        },
        {
            dataField: "agency", text: "AGENCY", formatter: (cell, row) => {
                return (
                    <div className="d-block">
                        <span className="fw-bold">{cell}</span>
                    </div>
                )
            }
        },
        {
            dataField: "designation", text: "DESIGNATION", formatter: (cell, row) => {
                return (
                    <div className="d-block">
                        <span className="fw-bold">{cell}</span>
                    </div>
                )
            }
        },
        {
            dataField: "weeklyOff", text: "WOFF", formatter: (cell, row) => {
                return (
                    <div className="d-block">
                        <span className="fw-bold">{cell}</span>
                    </div>
                )
            }
        },
        {
            dataField: "status", text: "Status", formatter: (cell, row) => {
                return (
                    <div className="d-block">
                        <Badge bg={getTripBadgeStatus(cell)} className="badge-lg">{cell}</Badge>
                    </div>
                )
            }
        }
    ])
}