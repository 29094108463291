import React from 'react';
import ActionButton from '../interBranchTransfers/components/ActionButton';

export const bikerAnalysisColumns = (actionClicked) => {
  const columns = [
    {
      dataField: 'driverName',
      text: 'driver Name',
    },
    {
      dataField: 'avgDeliveriesPerDay',
      text: 'avg deliveries/day',
    },
    {
      dataField: 'avgTimePerDeliery',
      text: 'avg time/delivery',
    },
    {
      dataField: 'deliveriesCompleted',
      text: 'deliveries completed',
    },

    {
      dataField: 'deliveriesRejected',
      text: 'deliveries rejected',
    },
    {
      dataField: 'deliveriesAssgined',
      text: 'deliveries assigned',
    },
    {
      dataField: 'lastLocation',
      text: 'Last Location',
    },
    {
      dataField: 'status',
      text: 'status',
    },
    {
      dataField: 'action',
      text: 'action',
      formatter: (cell, row) => {
        return (
          <>
            <ActionButton
              options={row?.status === 'active' ? ['deactivate'] : ['active']}
              onActionClick={(option) => actionClicked(option, row?.objectId)}
            />
          </>
        );
      },
    },
  ];
  return columns;
};
