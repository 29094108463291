import React, { useState } from 'react';
import Pagination from 'react-bootstrap-table2-paginator';
import * as Paginator from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import {
  Button,
  ButtonGroup,
  Card,
  Col,
  Form,
  Row,
} from '@themesberg/react-bootstrap';
import Table from 'react-bootstrap-table-next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { orderTableColumns, ordersDataColumns, searchByFields } from './utils';
import {
  customSizePerPage,
  customTotal,
} from '../../../components/common/parcelStatusPages';
import BreadCrumbComponent from '../../../components/common/Breadcrumb';
import arrayToBreadCrumbs from '../../../utils/sms/arrayToBreadCrumbs';
import { faFilter, faRefresh } from '@fortawesome/free-solid-svg-icons';
import ExcelDownloadReport from '../../../components/common/excel/download';
import DropdownComponent from '../../../components/common/Dropdown';
import { returnRowBGColor } from '../../../utils/parcels';
import EmptyTable from '../../../components/common/EmptyTableView';
import { useDispatch } from 'react-redux';
import { updateSpinnerState } from '../../../actions/spinner';
import { updateToastInfo } from '../../../actions/settings';
import { apiCall } from '../../../middlewares/api';
import { apiCallConsts } from '../../../constants/apiCallConsts';
import { useNavigate } from 'react-router-dom';

const FilteredOrders = () => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [searchByFieldName, setSearchByFieldName] = useState('');
  const breadCrumbItems = arrayToBreadCrumbs([['orders'], ['data']]);
  const [isValidInput, setIsValidInput] = useState(true);
  const [searchData, setSearchData] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSearchClicked = async () => {
    if (!searchByFieldName || !searchData) {
      setIsValidInput(false);
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('Input Error'),
          message: t('Please provide both search field and search value.'),
        })
      );
      return;
    }
    setIsValidInput(true);
    try {
      dispatch(updateSpinnerState(true));
      const res = await apiCall(
        apiCallConsts.GET_METHOD,
        'internal/order/filter',
        {
          [searchByFieldName]: searchData.trim(),
        }
      );
      setData(res);
      dispatch(
        updateToastInfo({
          show: true,
          type: 'success',
          title: t(''),
          message: t('orders fetched successfully!'),
        })
      );
      dispatch(updateSpinnerState(false));
    } catch (error) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('Failed to Fetch The Data!'),
          message: t(error.message),
        })
      );
    }
  };

  return (
    <ToolkitProvider
      exportCSV
      keyField="id"
      search={true}
      sort
      columns={ordersDataColumns(navigate)}
      data={data}
      wrapperClasses="table-responsive"
    >
      {({ baseProps, searchProps, csvProps }) => (
        <Paginator.PaginationProvider
          pagination={Pagination({
            custom: true,
            showTotal: true,
            alwaysShowAllBtns: true,
            totalSize: data?.length,
            paginationTotalRenderer: customTotal,
            sizePerPageRenderer: customSizePerPage,
          })}
        >
          {({ paginationProps, paginationTableProps }) => (
            <>
              <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-md-0">
                  <BreadCrumbComponent items={breadCrumbItems} />
                  <h4>{t('Orders Data')}</h4>
                  <p className="mb-0">
                    {t('Search for Orders using Phone number / Filters')}
                  </p>
                </div>
                <div className="btn-toolbar mb-2 mb-md-0">
                  <ButtonGroup>
                    <Button
                      onClick={() => {
                        handleSearchClicked();
                      }}
                      variant="outline-primary"
                      size="sm"
                    >
                      <FontAwesomeIcon icon={faRefresh} />
                      &nbsp;&nbsp;{t('Refresh')}
                    </Button>
                    <ExcelDownloadReport
                      data={data || []}
                      fileName={'download-data.csv'}
                    />
                  </ButtonGroup>
                </div>
              </div>
              <div className="table-settings mt-2 mb-4">
                <div style={{ gap: 8, display: 'flex' }}>
                  <DropdownComponent
                    onChange={(option) => {
                      setSearchByFieldName(option.value);
                    }}
                    defaultValue={searchByFieldName}
                    width={300}
                    disabled={false}
                    options={searchByFields}
                    placeholder={t('Select field to Search by')}
                  />
                  <Form.Group>
                    <Form.Control
                      style={{
                        height: 38,
                        display: 'flex',
                        alignItems: 'center',
                      }}
                      isInvalid={!isValidInput}
                      required
                      type="text"
                      defaultValue={searchData}
                      onChange={(event) => {
                        setIsValidInput(true);
                        setSearchData(event.target.value);
                      }}
                      onKeyDown={(event) => {
                        if (event.nativeEvent.code === 'Enter') {
                          handleSearchClicked();
                        }
                      }}
                      placeholder={t('Search Value')}
                    />
                    {!isValidInput && (
                      <Form.Control.Feedback type="invalid">
                        {t('Please add value to search')}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                  <Button
                    style={{
                      height: 38,
                    }}
                    variant="primary"
                    onClick={handleSearchClicked}
                  >
                    <FontAwesomeIcon icon={faFilter} />
                    &nbsp;&nbsp; {t('Filter')}
                  </Button>
                </div>
              </div>
              <Card>
                <div className="table-responsive py-2">
                  <Card.Header
                    style={{
                      padding: '8px 12px',
                    }}
                  >
                    <Row className="d-flex justify-content-end">
                      <Col
                        xs={12}
                        md={4}
                        className="d-flex justify-content-md-end"
                      >
                        <Search.SearchBar
                          {...searchProps}
                          placeholder={t('Search any value in table here')}
                        />
                        <Search.ClearSearchButton {...searchProps} />
                      </Col>
                    </Row>
                  </Card.Header>
                  <Table
                    noDataIndication={<EmptyTable />}
                    {...baseProps}
                    {...paginationTableProps}
                    bodyClasses="border-0"
                    classes="table-flush dataTable-table"
                  />

                  <Card.Footer>
                    <Row>
                      <Col xs={12} md={4} className="py-1">
                        <Paginator.SizePerPageDropdownStandalone
                          {...paginationProps}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        className="d-flex align-items-center py-1"
                      >
                        <Paginator.PaginationTotalStandalone
                          {...paginationProps}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        className="d-flex justify-content-md-end align-items-center mb-0 py-1"
                      >
                        <Paginator.PaginationListStandalone
                          {...paginationProps}
                        />
                      </Col>
                    </Row>
                  </Card.Footer>
                </div>
              </Card>
            </>
          )}
        </Paginator.PaginationProvider>
      )}
    </ToolkitProvider>
  );
};

export default FilteredOrders;
