import React from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

export const customDropDownStyles = (width) => {
  return {
    control: (provided) => ({
      ...provided,
      borderRadius: '3.79px',
      backgroundColor: '#eaedf2;',
      width: width ? width : provided.width,
    }),

    multiValue: (provided) => ({
      ...provided,
      borderRadius: '.5rem',
    }),

    multiValueRemove: (provided) => ({
      ...provided,
      borderRadius: '.5rem',
    }),

    placeholder: (provided) => ({
      ...provided,
      borderRadius: '.5rem',
    }),
    menu: (provided) => ({
      ...provided,
      width: width ? width : provided.width,
      borderRadius: '0.5rem',
    }),

    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';

      return {
        ...provided,
        opacity,
        transition,
      };
    },
  };
};
const DropdownComponent = (props) => {
  const { t } = useTranslation();
  const {
    onChange,
    options, // should have a label and value field.
    placeholder = 'Select an Option',
    isMultiSelect = false,
    disabled = false,
    width,
  } = props;
  return (
    <Select
      isDisabled={disabled}
      onChange={onChange}
      options={options}
      placeholder={t(placeholder)}
      theme={(theme) => ({
        ...theme,
        borderRadius: 0,
        colors: {
          ...theme.colors,
          primary25: '#61DAFB',
          primary: '#61DAFB',
        },
      })}
      styles={customDropDownStyles(width)}
      isMulti={isMultiSelect}
    />
  );
};
export default DropdownComponent;
