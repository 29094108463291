import React from "react";
import { returnUserReadableDate } from "../datetime";

export const returnPurchaseTargetTableColumns = () => {
    return ([
        {
            dataField: "Voucher No", sort: true, text: "Voucher No.", formatter: (cell, row) => {
                return (
                    <div className="d-block">
                        <span className="fw-bold">{cell}</span>
                    </div>
                )
            }
        },
        {
            dataField: "Priority Delivery", text: "Priority Delivery", formatter: (cell, row) => {
                return (
                    <div className="d-block">
                        <span className="fw-bold">{cell}</span>
                    </div>
                )
            }
        },
        {
            dataField: "Customer", text: "Customer", formatter: (cell, row) => {
                return (
                    <div className="d-block">
                        <span className="fw-bold">{cell}</span>
                    </div>
                )
            }
        },{
            dataField: "Delivery Date", text: "Delivery Date", formatter: (cell, row) => {
                return (
                    <div className="d-block">
                        <span className="fw-bold">{cell ? returnUserReadableDate(cell) : "-"}</span>
                    </div>
                )
            }
        },
        {
            dataField: "Product", text: "Product", formatter: (cell, row) => {
                return (
                    <div className="d-block">
                        <span className="fw-bold">{cell}</span>
                    </div>
                )
            }
        },
        {
            dataField: "Pending Quantity", text: "Quantity", formatter: (cell, row) => {
                return (
                    <div className="d-block">
                        <span className="fw-bold">{cell}</span>
                    </div>
                )
            }
        }, {
            dataField: "VA Nnumber", text: "VA Nnumber", formatter: (cell, row) => {
                return (
                    <div className="d-block">
                        <span className="fw-bold">{cell}</span>
                    </div>
                )
            }
        }, {
            dataField: "Product Category", text: "Product Category", formatter: (cell, row) => {
                return (
                    <div className="d-block">
                        <span className="fw-bold">{cell}</span>
                    </div>
                )
            }
        }, {
            dataField: "Product Code", text: "Product Code", formatter: (cell, row) => {
                return (
                    <div className="d-block">
                        <span className="fw-bold">{cell}</span>
                    </div>
                )
            }
        }, {
            dataField: "Brand", text: "Brand", formatter: (cell, row) => {
                return (
                    <div className="d-block">
                        <span className="fw-bold">{cell}</span>
                    </div>
                )
            }
        }, {
            dataField: "Remarks", text: "Remarks", formatter: (cell, row) => {
                return (
                    <div className="d-block">
                        <span className="fw-bold">{cell}</span>
                    </div>
                )
            }
        }
    ])
};

export const returnLeadsBasedOnCategory = (leadsItemArray) => {
    const leadsWithCategory = {};
    // eslint-disable-next-line no-unused-expressions
    leadsItemArray?.forEach(lead => {
        // eslint-disable-next-line no-unused-expressions
        lead?.category?.forEach(leadCategory => {
            if (!leadsWithCategory[leadCategory]) {
                leadsWithCategory[leadCategory] = [];
            }
            leadsWithCategory[leadCategory].push({
                name: lead.inchargeName,
                id: lead.userId
            })
        });
    });
    return leadsWithCategory;
};

export const returnActualSelectedCategoryName = (selectedCategoryName, categoryBasedLeads) => {
    if (Object.keys(categoryBasedLeads)?.indexOf(selectedCategoryName) > -1) {
        return selectedCategoryName;
    }
    return "smallAppliances";
};

export const returnActualSelectedCategoryNameMultiple = (allSelectedCategories, categoryBasedLeads) => {
    return allSelectedCategories?.map((categoryName) => returnActualSelectedCategoryName(categoryName, categoryBasedLeads))
};