import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { updateToastInfo } from '../../../../actions/settings';
import { updateSpinnerState } from '../../../../actions/spinner';
import ConfirmationModal from '../../../../components/common/confirmationModal';
import {
  getAllSchemes,
  updateSchemesStatus,
} from '../../../../parse-functions/sms/scheme';
import { useNavigate } from 'react-router-dom';
import SchemeInfoModal from '../Scheme/SchemeInfoModal';
import { infoTable } from '../../../../utils/sms/infoTable';
import DifferenceProductsModal from '../common/DifferenceProductsModal';
import BulkAddSchemeModal from '../BulkAddSchemeModal';
import BreadCrumbTitle from '../common/BreadCrumbTitle';
import {
  schemeTableRedesignColumns,
  ViewProductsTable,
} from '../../../../utils/sms/schemeTableRedesign';
import {
  faCircleCheck,
  faXmarkCircle,
} from '@fortawesome/free-regular-svg-icons';
import { faBan, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ButtonGroup } from '@themesberg/react-bootstrap';
import ExcelDownloadReport from '../../../../components/common/excel/download';
import TableOnly from '../IndividualScheme.js/TableOnly';
import { setSchemesDataInStore } from '../../../../actions/sms/schemes';
import { StyledSearchBar } from '../../../trips/styles';
import { setPageNumberInStore } from '../../../../actions/tablePage';

const ActiveSchemes = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userName = useSelector((state) => state?.user?.user?.username || {});
  const isAdmin = useSelector(
    (state) => state?.user?.preferences?.isAdmin || false
  );
  const isBrandManager = useSelector(
    (state) => state?.user?.preferences?.isBrandManager || false
  );

  const isBrandVendor = useSelector(
    (state) => state?.user?.preferences?.isBrandVendor || false
  );

  const isAccountManager = useSelector(
    (state) => state?.user?.preferences?.isAccountManager || false
  );

  const role = isAdmin
    ? 'isAdmin'
    : isBrandManager
    ? 'isBrandManager'
    : isBrandVendor
    ? 'isBrandVendor'
    : isAccountManager
    ? 'isAccountManager'
    : 'Unknown';
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [updateMode, setUpdateMode] = useState('');
  const [schemeData, setSchemeData] = useState([]);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [infoData, setInfoData] = useState();
  const [confirmationModalProps, setConfirmationModalProps] = useState([]);
  const [newProducts, setNewProducts] = useState([]);
  const [showDifferenceModal, setShowDifferenceModal] = useState(false);
  const [showProductsModal, setShowProductsModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [id, setId] = useState();
  const schemes = useSelector((state) => state.schemes || {});
  let options = ['Action Timeline', 'View', 'Details'];

  const filteredData = schemeData.filter((row) => {
    return Object.values(row).some((value) =>
      value?.toString()?.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  if (isBrandManager) {
    options.push(...['Complete', 'Cancel']);
  } else if (isBrandVendor) {
    options.push(...['Request Cancellation', 'Request Completion']);
  } else if (isAccountManager) {
    options.push(...['Close']);
  } else {
    options.push(...['Complete', 'Close', 'Cancel']);
  }
  const fetchActiveSchemes = async () => {
    try {
      dispatch(updateSpinnerState(true));
      const response = await getAllSchemes({
        status: ['ongoing', 'completed'],
        role: role,
      });
      setSchemeData(response);
      dispatch(setSchemesDataInStore({ type: 'active', data: response }));
    } catch (error) {
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: 'Cannot fetch at the moment!',
          message: 'Please try again!',
        })
      );
    } finally {
      dispatch(updateSpinnerState(false));
    }
  };

  const onRefreshClick = () => {
    try {
      fetchActiveSchemes();
      dispatch(
        updateToastInfo({
          show: true,
          type: 'success',
          title: 'Refreshed Schemes!',
          message: 'Schemes fetched successfully!',
        })
      );
    } catch (error) {
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: 'Error fetching Schemes!',
          message: error.message,
        })
      );
    }
  };

  const handleShowDeleteModal = () => {
    setShowDeleteModal(true);
  };

  const handleSelectedRowUpdate = (actionId, row) => {
    switch (actionId) {
      case 'Complete': {
        setUpdateMode('Complete');
        setConfirmationModalProps([
          {
            type: 'textArea',
            title: t('Remarks'),
            value: '',
            placeholder: 'Enter Remarks',
          },
          {
            type: 'date',
            title: t('Completion Date'),
            value: row.validTill,
          },
          {
            headerIcon: <FontAwesomeIcon icon={faCircleCheck} />,
          },
        ]);
        setId(row.objectId);
        handleShowDeleteModal();
        break;
      }
      case 'Cancel': {
        setUpdateMode('Cancel');
        setConfirmationModalProps([
          {
            type: 'textArea',
            title: t('Remarks'),
            placeholder: 'Enter Remarks',
            value: '',
          },
          {
            headerIcon: <FontAwesomeIcon icon={faXmarkCircle} />,
          },
        ]);
        setId(row.objectId);
        handleShowDeleteModal();
        break;
      }
      case 'Request Cancellation': {
        setUpdateMode('Request Cancel');
        setConfirmationModalProps([
          {
            headerIcon: <FontAwesomeIcon icon={faXmarkCircle} />,
          },
        ]);
        setId(row.objectId);
        handleShowDeleteModal();
        break;
      }
      case 'Request Completion': {
        setUpdateMode('Request Complete');
        setConfirmationModalProps([
          {
            headerIcon: <FontAwesomeIcon icon={faCircleCheck} />,
          },
        ]);
        setId(row.objectId);
        handleShowDeleteModal();
        break;
      }
      case 'Close': {
        setUpdateMode('Close');
        setConfirmationModalProps([
          {
            type: 'textArea',
            title: t('Remarks'),
            placeholder: 'Enter Remarks',
            value: '',
          },
          {
            headerIcon: <FontAwesomeIcon icon={faBan} />,
          },
        ]);
        setId(row.objectId);
        handleShowDeleteModal();
        break;
      }
      case 'View': {
        navigate(`/scheme/${row.objectId}/details`);
        break;
      }
      case 'Details': {
        navigate(`/scheme/schemeanalysis/${row.objectId}`);
        break;
      }
      case 'Action Timeline': {
        setInfoData(row.actionLog);
        setShowInfoModal(true);
      }
      default: {
        break;
      }
    }
  };
  const handleOnConfirmClick = async (data) => {
    try {
      dispatch(updateSpinnerState(true));
      const result = await updateSchemesStatus(data);
      if (result[0].status === 'error') {
        setShowDifferenceModal(true);
        setNewProducts(result[0].difference);
        throw new Error(
          'There are new products that can be added to this scheme!'
        );
      }
      await fetchActiveSchemes();
      setId();
      setConfirmationModalProps([]);

      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'success',
          title: `Status Update Successful`,
          message: '',
        })
      );
      setShowDeleteModal(false);
      setShowDifferenceModal(false);
    } catch (e) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: 'Status cannot be updated!',
          message: e.message,
        })
      );
    }
  };
  const handleModalOnConfirmClick = (data) => {
    const selectedSchemeData = {
      schemeId: [id],
      userName: userName,
      status:
        updateMode === 'Cancel'
          ? 'cancelled'
          : updateMode === 'Complete'
          ? 'completed'
          : updateMode === 'Close'
          ? 'closed'
          : 'ongoing',
      actionType:
        updateMode === 'Request Cancel'
          ? 'cancellation'
          : updateMode === 'Request Complete'
          ? 'completion'
          : '',
      reason: confirmationModalProps[0].value,
    };
    if (updateMode === 'Complete') {
      selectedSchemeData.completionDate = confirmationModalProps[1].value;
    }
    if (data) {
      selectedSchemeData.isChecked = true;
    }
    if (data?.length > 0) {
      selectedSchemeData.moreProducts = data;
    }
    handleOnConfirmClick(selectedSchemeData);
  };

  const handleMoreProductsClick = (id) => {
    setId(id);
    setShowProductsModal(true);
  };

  const handleSave = () => {
    let hasErrors = false;
    const data = newProducts.map((item) => {
      const updatedItem = { ...item, errors: {} };
      if (item.isSelected) {
        if (!item.payout) {
          updatedItem.errors.payout = 'Payout needs a value.';
          hasErrors = true;
        }
        if (!item.payoutBasis) {
          updatedItem.errors.payoutBasis = 'Payout Basis needs a value.';
          hasErrors = true;
        }
        if (!item.valuationType) {
          updatedItem.errors.valuationType = 'Valuation Type needs a value.';
          hasErrors = true;
        }
      }
      return updatedItem;
    });

    setNewProducts(data);
    if (!hasErrors) {
      handleModalOnConfirmClick(newProducts.filter((item) => item.isSelected));
    }
  };

  useEffect(() => {
    if (role !== 'Unknown' && schemes.active.length === 0) {
      fetchActiveSchemes();
    } else if (schemes.active.length > 0) {
      setSchemeData(schemes.active);
    }
  }, [role]);

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <BreadCrumbTitle breadcrumbs={[{ title: 'Active Schemes' }]} />
        </div>
        <div className="btn-toolbar mb-2 mb-md-0 gap-3">
          <StyledSearchBar
            width={'360.89px'}
            height={'46.78px'}
            type={'text'}
            value={searchTerm}
            placeholder={'Search value in the table'}
            onChange={(e) => {
              dispatch(
                setPageNumberInStore({ id: 'activeSchemesTable', page: 1 })
              );
              setSearchTerm(e.target.value);
            }}
          />
          <ButtonGroup className="gap-3">
            <Button
              onClick={onRefreshClick}
              variant="white"
              className="btn-export "
              size="md"
            >
              <FontAwesomeIcon icon={faRefresh} />
              &nbsp;&nbsp;{t('Refresh')}
            </Button>
            <ExcelDownloadReport
              data={Object.values(schemeData) || []}
              fileName={`Active Schemes`}
            />
          </ButtonGroup>
        </div>
      </div>
      <TableOnly
        data={filteredData || []}
        columns={schemeTableRedesignColumns({
          handleMoreProductsClick,
          options: options,
          handleSelectedRowUpdate,
        })}
        id={'activeSchemesTable'}
      />
      <ConfirmationModal
        showModal={showDeleteModal}
        onConfirm={() => handleModalOnConfirmClick()}
        closeModal={() => {
          setShowDeleteModal(false);
          setConfirmationModalProps([]);
        }}
        modalBody={t(`Are you sure you want to ${updateMode?.toLowerCase()}?`)}
        modalTitle={t(`${updateMode} Scheme(s)`)}
        confirmationModalProps={confirmationModalProps}
        setConfirmationModalProps={setConfirmationModalProps}
      />
      <SchemeInfoModal
        showModal={showInfoModal}
        closeModal={() => setShowInfoModal(false)}
        data={infoData}
        columns={infoTable()}
      />
      <DifferenceProductsModal
        showModal={newProducts.length > 0 && showDifferenceModal}
        closeModal={() => setShowDifferenceModal(false)}
        data={newProducts}
        setNewProducts={setNewProducts}
        handleSave={handleSave}
      />
      {showProductsModal && (
        <BulkAddSchemeModal
          data={
            schemeData?.filter((item) => item.objectId === id)[0]?.products ||
            []
          }
          showModal={showProductsModal}
          closeModal={() => setShowProductsModal(false)}
          columns={ViewProductsTable(
            schemeData?.filter((item) => item.objectId === id)[0]
          )}
        />
      )}
    </>
  );
};
export default ActiveSchemes;
