import { Form, InputGroup } from "@themesberg/react-bootstrap";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Datetime from "react-datetime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { returnUserReadableDate } from "../../../utils/datetime";
import DropdownComponent from "../Dropdown";
import { useSelector } from "react-redux";
import { returnWarehouseDropdownOptions } from "../../../utils/warehouse";

const AddInvoiceCustomerInfo = (props) => {
    const {
        errors,
        setCustomerInfoFormData,
        invoiceNo
    } = props;
    const { t } = useTranslation();
    const [formErrors, setFormErrors] = useState(errors);
    const [invoiceDate, setInvoiceDate] = useState();
    const otherWarehouses = useSelector((state) => state?.user?.otherWarehousesInZone || {});
    const [formData, setFormData] = useState({
        invoiceNumber: invoiceNo
    });

    const onFormInputChange = (fieldName, value) => {
        setFormData(oldFormData => ({
            ...oldFormData,
            [fieldName]: value
        }));
    };

    useEffect(() => {
        setFormErrors(errors);
    }, [errors]);

    useEffect(() => {
        setCustomerInfoFormData(formData);
    }, [formData])

    return (
        <>
        <h4>{t("Invoice Details")}</h4>
            <Form.Group className="mb-3 mt-4">
                <Form.Label>{t("Invoice Number")}</Form.Label>
                <Form.Control
                    required
                    disabled
                    value={formData.invoiceNumber}
                    isInvalid={formErrors?.["invoiceNumber"]?.length}
                    type="text"
                    onChange={(event) => {
                        onFormInputChange("invoiceNumber", event.target.value);
                    }} />
                {formErrors?.["invoiceNumber"]?.length && (
                    <Form.Control.Feedback type="invalid">{t("Please enter Invoice Number.")}</Form.Control.Feedback>
                )}
            </Form.Group>
            <Form.Label>{t("Invoice Date")}</Form.Label>
            <Datetime
                timeFormat={false}
                closeOnSelect={true}
                onChange={(value) => {
                    setInvoiceDate(value);
                    onFormInputChange("invoiceDate", new Date(value));
                }}
                renderInput={(props, openCalendar) => (
                    <InputGroup>
                        <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} className="icon icon-xs" /></InputGroup.Text>
                        <Form.Control
                            isInvalid={formErrors?.["invoiceDate"]?.length > 0}
                            required
                            type="text"
                            value={returnUserReadableDate(invoiceDate)}
                            placeholder="Select end: dd/mm/yyyy"
                            onFocus={openCalendar} />
                        {formErrors?.["invoiceDate"]?.length > 0 && (
                            <Form.Control.Feedback type="invalid">{formErrors["invoiceDate"]}</Form.Control.Feedback>
                        )}
                    </InputGroup>
                )} />

            <Form.Group className="mb-3 mt-4">
                <Form.Label>{t("Customer Name")}</Form.Label>
                <Form.Control
                    required
                    isInvalid={formErrors?.["customerName"]?.length}
                    type="text"
                    onChange={(event) => {
                        onFormInputChange("customerName", event.target.value);
                    }} />
                {formErrors?.["customerName"]?.length && (
                    <Form.Control.Feedback type="invalid">{t("Please enter Customer Name.")}</Form.Control.Feedback>
                )}
            </Form.Group>

            <Form.Group className="mb-3 mt-4">
                <Form.Label>{t("Customer Contact #")}</Form.Label>
                <Form.Control
                    required
                    isInvalid={formErrors?.["customerContactNumber"]?.length}
                    type="number"
                    onChange={(event) => {
                        onFormInputChange("customerContactNumber", event.target.value);
                    }} />
                {formErrors?.["customerContactNumber"]?.length && (
                    <Form.Control.Feedback type="invalid">{t("Please enter Customer Number.")}</Form.Control.Feedback>
                )}
            </Form.Group>
            <Form.Group className="mb-3 mt-4">
                <Form.Label>{t("Address")}</Form.Label>
                <Form.Control
                    required
                    isInvalid={formErrors?.["address"]?.length}
                    type="text"
                    onChange={(event) => {
                        onFormInputChange("address", event.target.value);
                    }} />
                {formErrors?.["address"]?.length && (
                    <Form.Control.Feedback type="invalid">{t("Please enter customer address.")}</Form.Control.Feedback>
                )}
            </Form.Group>

            <Form.Group className="mb-3 mt-4">
                <Form.Label>{t("Pincode")}</Form.Label>
                <Form.Control
                    required
                    isInvalid={formErrors?.["pincode"]?.length}
                    type="number"
                    onChange={(event) => {
                        onFormInputChange("pincode", event.target.value);
                    }} />
                {formErrors?.["pincode"]?.length && (
                    <Form.Control.Feedback type="invalid">{t("Please enter Delivery Pincode.")}</Form.Control.Feedback>
                )}
            </Form.Group>

            <Form.Group className="mb-4">
                <Form.Label className={formErrors?.["targetBranch"]?.length > 0 ? "text-danger" : ""}>{t("Choose Target Branch")}</Form.Label>
                <DropdownComponent
                    onChange={(option) => {
                        setFormData(oldData => ({
                            ...oldData,
                            targetBranch: option.value
                        }))
                    }}
                    options={returnWarehouseDropdownOptions(otherWarehouses)}
                    placeholder={t("Select Target / Delivery branch")}
                />
            </Form.Group>
            {formErrors?.["targetBranch"]?.length > 0 && (
                <Form.Control.Feedback type="invalid">{formErrors["targetBranch"]}</Form.Control.Feedback>
            )}
        </>
    );
}
export default AddInvoiceCustomerInfo;