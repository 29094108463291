import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import english from "./english";
import gujarati from "./gujarati";
import hindi from "./hindi";
import marathi from "./marathi";
import telugu from "./telugu";

let currentLanguage = "en";
const langInfo = localStorage.getItem("vsLanguage");
if (langInfo?.length > 0) {
  currentLanguage = JSON.parse(langInfo).code;
}
i18n
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      mr: marathi,
      te: telugu,
      gu: gujarati,
      hi: hindi,
      en: english
    },
    fallbackLng: "en",
    debug: false,
    lng: currentLanguage,
    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
