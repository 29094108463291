/* eslint-disable no-loop-func */

import { faEllipsisH, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, ButtonGroup, Card, Col, Dropdown, Form, Nav, Pagination, Row, Table } from "@themesberg/react-bootstrap";
import React, { useEffect, useState } from "react";
import { getCustomerAvatarName } from "../../utils/string";
import { getColumnHeaders, getTableRowCheckBox, returnCustomerAddressString, returnParcelProductInformation, returnProductBuybackInformation, returnProductDispatchScanInformation } from "../../utils/table";
import { StyledCustomerNameAvatar } from "./styles";
import { returnUserReadableDateTime } from "../../utils/datetime";

const columnNames = ["checkBox", "Customer", "Invoice No", "Address", "Product", "Qty", "Scan Info", "Buyback", "Action"];
const TableRow = (props) => {
    const {
        customerName,
        shippingMobileNo,
        invoiceNo,
        shippingAddress1,
        shippingAddress2,
        shippingAddress3,
        objectId,
        quantity,
        shippingPincode,
        scannedAt,
        scannedBy,
        date,
        removeItemFromList,
        onRowSelect,
        isSelected,
    } = props;

    return (
        <tr className={scannedAt ? "bg-scan-marked" : ""}>
            <td>
                {getTableRowCheckBox(onRowSelect, objectId, isSelected)}
            </td>
            <td>
                <span className="fw-normal">
                    <div className="d-flex align-items-center">
                        <div className="avatar d-flex align-items-center justify-content-center fw-bold rounded bg-secondary me-3">
                            <StyledCustomerNameAvatar className="d-flex align-items-center justify-content-center">
                                {getCustomerAvatarName(customerName)}
                            </StyledCustomerNameAvatar>
                        </div>
                        {returnCustomerAddressString(customerName, shippingMobileNo, 150, 20)}
                    </div>
                    <div>
                        {props?.customerCode ? `Code: ${props.customerCode}` : ""}
                    </div>
                </span>
            </td>
            <td>
                <div className="d-block">
                    <span className="fw-bold">{invoiceNo}</span>
                    <div className="fw-normal">{new Date(date).toLocaleDateString()}</div>
                </div>

            </td>
            <td>
                {returnCustomerAddressString(`${shippingAddress1}${shippingAddress2}${shippingAddress3}`, shippingPincode, 300)}
            </td>
            <td>
                {returnParcelProductInformation(props, 300, 60)}
            </td>
            <td>
                {quantity}
            </td>
            <td>
                {returnProductDispatchScanInformation(props)}
            </td>
            <td>
                {returnProductBuybackInformation(props)}
            </td>
            <td>
                <Dropdown as={ButtonGroup}>
                    <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
                        <span className="icon icon-sm">
                            <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
                        </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item
                            onClick={() => {
                                removeItemFromList([objectId])
                            }}
                            className="text-danger">
                            <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Remove
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </td>
        </tr>
    );
};


const TemporaryTripParcels = (props) => {
    const {
        data,
        removeItemFromList,
        onTableSelectAll,
        bulkUpdateEnabled,
        onBulkUpdateApply,
        onRowSelect
    } = props;
    const [selectedBulkOption, setSelectedBulkOption] = useState("scanAll");
    const [currentPage, setCurrentPage] = useState(1);
    const [disablePrevious, setDisablePrevious] = useState(false);
    const [disableNext, setDisableNext] = useState(false);
    const [currentPageData, setCurrentPageData] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPageDataStartIndex, setCurrentPageDataStartIndex] = useState(0);
    const [currentPageDataEndIndex, setCurrentPageDataEndIndex] = useState(0);
    const columnHeaders = getColumnHeaders(columnNames, onTableSelectAll);
    const pageSize = 100;


    useEffect(() => {
        calculateTableData();
    }, [data, currentPage]);

    const calculateTableData = () => {
        // validate the system again and fetch the orders to be shown.
        const currentPageDataStartIndex = ((currentPage - 1) * pageSize);
        const currentPageDataEndIndex = currentPage * pageSize;
        setCurrentPageDataStartIndex(currentPageDataStartIndex);
        setCurrentPageDataEndIndex(currentPageDataEndIndex);
        const dataCopy = Object.assign([], data);
        const currentData = dataCopy.slice(currentPageDataStartIndex, currentPageDataEndIndex);
        setCurrentPageData(currentData);
        setDisablePrevious(currentPage === 1);
        setDisableNext(currentPage === totalPages);
        setTotalPages(Math.ceil(data?.length / pageSize));
    }

    const onPaginationChange = (pageNumber) => {
        if (pageNumber === "previous") {
            setCurrentPage(currentPage - 1);
        } else if (pageNumber === "next") {
            setCurrentPage(currentPage + 1)
        } else {
            setCurrentPage(pageNumber);
        }
    }
    const returnTotalPages = () => {
        const pagesArr = [];
        for (let i = 1; i <= totalPages; i += 1) {
            pagesArr.push(
                <Pagination.Item
                    onClick={() => {
                        onPaginationChange(i)
                    }}
                    active={currentPage === i}>
                    {i}
                </Pagination.Item>
            )
        }
        return pagesArr;
    }
    const itemsScanned = data?.filter(parcel => parcel.scannedAt);
    return (
        <Card border="light" className="table-wrapper table-responsive shadow-sm">
            <Card.Body>
                <Row>
                    <Col>
                        <div className="d-flex mb-3">
                            <Form.Group id="bulkOptions">
                                <Form.Select
                                    style={{
                                        width: 250
                                    }}
                                    onChange={(event) => {
                                        setSelectedBulkOption(event.target.value);
                                    }}
                                    disabled={!bulkUpdateEnabled}>
                                    <option value="scanAll" defaultValue>Mark Scan</option>
                                    <option value="deleteAll">Delete Selected Parcels</option>
                                </Form.Select>
                            </Form.Group>
                            <Button
                                onClick={() => {
                                    onBulkUpdateApply(selectedBulkOption);
                                }}
                                disabled={!bulkUpdateEnabled}
                                className="ms-3 btn btn-secondary"
                                variant="secondary">Apply</Button>
                        </div>
                    </Col>
                    <Col className="d-flex align-items-center">
                        <div style={{
                            fontSize: 18,
                            fontWeight: 700
                        }}>Items Scanned:
                            <span><b>&nbsp;{itemsScanned?.length || 0}</b></span>
                        </div>
                    </Col>
                    <Col className="d-flex align-items-center">
                        <div style={{
                            fontSize: 18,
                            fontWeight: 700
                        }}>Remaining Scan: <span><b>&nbsp;{data?.length - itemsScanned?.length}</b></span></div>
                    </Col>
                </Row>
                <Table hover className="user-table align-items-center">
                    <thead>
                        <tr>
                            {columnHeaders}
                        </tr>
                    </thead>
                    <tbody>
                        {currentPageData.map((t, index) => <TableRow onRowSelect={onRowSelect} key={`transaction-${t.objectId}`} {...t} index={index} currentPageDataStartIndex={currentPageDataStartIndex} removeItemFromList={removeItemFromList} />)}
                    </tbody>
                </Table>
                <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
                    {totalPages > 1 && (
                        <Nav>
                            <Pagination className="mb-2 mb-lg-0">
                                <Pagination.Prev disabled={disablePrevious} onClick={() => {
                                    onPaginationChange("previous")
                                }}>
                                    Previous
                                </Pagination.Prev>
                                {returnTotalPages()}
                                <Pagination.Next disabled={disableNext} onClick={() => {
                                    onPaginationChange("next")
                                }}>
                                    Next
                                </Pagination.Next>
                            </Pagination>
                        </Nav>
                    )}
                    <small className="fw-bold">
                        Showing <b>{currentPageDataStartIndex + 1} - {currentPageDataEndIndex > data?.length ? data?.length : currentPageDataEndIndex}</b> out of <b>{data?.length}</b> entries
                    </small>
                </Card.Footer>
            </Card.Body>
        </Card>

    )
}
export default TemporaryTripParcels