import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge, Button } from "@themesberg/react-bootstrap";
import React from "react";
import EditableDropDown from "../../../components/common/EditableDropdown";

const AttendanceCategory = (props) => {
    const {
        title,
        items,
        handleChangeTeamLeadSelection,
        selectedTeamLeadList
    } = props;
    console.log(selectedTeamLeadList);
    const onDropdownItemChange = (value) => {
        handleChangeTeamLeadSelection(value?.value, title, true);
    }
    const dropdownItems = items?.map(item => ({
        label: item.name,
        value: item
    }));
    return (
        <div>
            <h5>{title}</h5>
            <div style={{
                display: "flex",
                flexDirection: "column"
            }}>
                <EditableDropDown
                    placeholder={'Select Team lead'}
                    options={dropdownItems}
                    onChange={onDropdownItemChange} />
                {selectedTeamLeadList?.map(teamLead => {
                    return (
                        <Badge bg="primary" className="badge-lg mx-2 my-2" style={{
                            display: "inline-flex",
                            alignItems: "center"
                        }}>
                            <span style={{
                                display: "inline-flex",
                                fontSize: 16
                            }}>{teamLead.name}</span> &nbsp;&nbsp;
                            <Button size="sm" variant="danger" onClick={() => {
                                handleChangeTeamLeadSelection(teamLead, title, false)
                            }}>
                                <FontAwesomeIcon icon={faXmark} />
                            </Button>
                        </Badge>
                    )
                })}

            </div>
        </div>
    )
}
export default AttendanceCategory