import React, { useState } from 'react';
import ParcelStatusPage from '../../components/common/parcelStatusPages';
import { useDispatch, useSelector } from 'react-redux';
import arrayToBreadCrumbs from '../../utils/sms/arrayToBreadCrumbs';
import { setFiltersInStore } from '../../actions/filters';
import { useTranslation } from 'react-i18next';
import { returnEndOfDay, returnStartOfDay } from '../../utils/datetime';
import RequisitionUpdateModal from '../requisitions/requisitionUpdateModal';
import { updateSpinnerState } from '../../actions/spinner';
import { getPaymentVouchers } from '../../parse-functions/payments';
import { updateToastInfo } from '../../actions/settings';
import { VouchersSentColumns } from './utils';
import VoucherDataModal from './VoucherDataModal';

const SentVouchers = () => {
  const { t } = useTranslation();
  const pageType = 'vouchersSent';
  const filtersValue = useSelector((state) => state.filters?.[pageType] || {});
  const warehouse = useSelector((state) => state?.user?.warehouse || {});
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const [readOnlyMode, setReadOnlyMode] = useState(false);
  const [selectedVoucherId, setSelectedVoucherId] = useState(null);
  const [showRemarkModal, setShowRemarkModal] = useState(false);
  const breadCrumbItems = arrayToBreadCrumbs([['payments'], ['sent']]);

  const handleFiltersSubmit = (startDate, endDate) => {
    dispatch(
      setFiltersInStore({
        pageType,
        data: {
          startDate: new Date(startDate),
          endDate: new Date(endDate),
        },
      })
    );
    getAllSentVouchers(startDate, endDate);
  };

  const getAllSentVouchers = async (startDate, endDate) => {
    try {
      dispatch(updateSpinnerState(true));
      const res = await getPaymentVouchers({
        filters: {
          startDate: new Date(startDate),
          endDate: new Date(endDate),
          createdByBranch: warehouse?.warehouseName,
        },
      });
      setData(res);
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'success',
          title: t('Vouchers fetched'),
          message: t('These are all the Vouchers sent out'),
        })
      );
    } catch (error) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('failed!'),
          message: t('Vouchers failed to fetch. Please contact support'),
        })
      );
    }
  };

  const showItemsModal = (objectId) => {
    onRowSelect(true, objectId);
    setReadOnlyMode(true);
    setShowRemarkModal(true);
  };

  const printVoucherDetails = () => {
    dispatch(updateSpinnerState(true));
    try {
      // dispatch(setSelectedRequisitionInStore(requisitonItems[selectedRequisitionId]));
      window.open(`/voucher/${selectedVoucherId}`, '_blank');
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'success',
          title: t(''),
          message: t('Voucher Printed Successfully.'),
        })
      );
    } catch (e) {
      dispatch(updateSpinnerState(false));
    }
  };

  const handleItemBulkUpdate = (actionId) => {
    if (selectedVoucherId) {
      switch (actionId) {
        case 'print':
          printVoucherDetails();
          break;
        default: {
          dispatch(
            updateToastInfo({
              show: true,
              type: 'danger',
              title: t('Select an Action'),
              message: t('Please select an action from the dropdown'),
            })
          );
          break;
        }
      }
    }
  };

  const onRowSelect = (value, objectId) => {
    if (selectedVoucherId) {
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('Only 1 item allowed'),
          message: t('Please deselect other item before choosing this'),
        })
      );
    } else {
      if (value) {
        setSelectedVoucherId(objectId);
      } else {
        setSelectedVoucherId(null);
      }
    }
  };

  const onRefreshClick = () => {
    const currentStartDate = filtersValue?.startDate
      ? new Date(filtersValue?.startDate)
      : returnStartOfDay(new Date());
    const currentEndDate = filtersValue?.endDate
      ? new Date(filtersValue?.endDate)
      : returnEndOfDay(new Date());
    getAllSentVouchers(currentStartDate, currentEndDate);
  };

  return (
    <>
      <ParcelStatusPage
        filtersValue={filtersValue}
        filterSubmit={handleFiltersSubmit}
        tableData={Object.values(data) || {}}
        tableColumns={VouchersSentColumns(onRowSelect, showItemsModal)}
        handleRefresh={onRefreshClick}
        pageTitle={t('Vouchers Sent')}
        breadCrumbItems={breadCrumbItems}
        pageSubTitle={t(
          'All the Vouchers that have been sent by your warehouse'
        )}
        handleItemBulkUpdate={handleItemBulkUpdate}
        bulkActionOptions={[{ label: 'Print', value: 'print' }]}
        enableBulkActions={selectedVoucherId && Object.values(data)?.length > 0}
      />

      <VoucherDataModal
        readOnly
        showUpdateModal={showRemarkModal}
        handleCloseModal={() => {
          setShowRemarkModal(false);
        }}
        requisitonItems={data[selectedVoucherId] || {}}
      />
    </>
  );
};

export default SentVouchers;
