import Parse from "parse";
import store from "../store";

export const getParcelsByStatusAndSubStatus = async (filters) => {
    const storeData = store.getState();
    const isSuperAdmin = storeData?.user?.preferences?.isSuperAdmin || false;
    return Parse.Cloud.run("getParcelsByStatusAndSubStatus", {
        ...filters,
        isSuperAdmin
    });
};

export const handleParcelReturnScan = async (data) => {
    return Parse.Cloud.run("handleParcelReturnScan", data);
}
export const handleParcelBuybackReceive = async (data) => {
    return Parse.Cloud.run("handleParcelBuybackReceive", data);
}

export const createReturnOKRescheduledParcel = async (data) => {
    return Parse.Cloud.run("createReturnOKRescheduledParcel", data);
}

export const updateParcelDataWithInfo = async (data) => {
    return Parse.Cloud.run("updateParcelDataWithInfo", data);
}

export const createBuyBackPendingParcel = async (data) => {
    return Parse.Cloud.run("createBuyBackPendingParcel", data);
}
export const createDefectivePickupPendingParcel = async (data) => {
    return Parse.Cloud.run("createDefectivePickupPendingParcel", data);
}
export const getParcelInfoById = async (data) => {
    return Parse.Cloud.run("getParcelInfoById", data);
}
export const updateOrdersDataWithInfo = async (data) => {
    return Parse.Cloud.run("updateOrdersDataWithInfo", data);
}

export const searchParcelResultsByFilterParams = async (filtersData) => {
    return Parse.Cloud.run("searchParcelResultsByFilterParams", filtersData);
}

export const getParcelStatusForDeliveryAnalysis = async (filters) => {
    return Parse.Cloud.run("getParcelStatusForDeliveryAnalysis", filters);
}

export const receiveTemposheetOrStock = async (data) => {
    return Parse.Cloud.run("receiveTemposheetOrStock", data);
}
export const getChallanInfoToPrint = async (data) => {
    return Parse.Cloud.run("getChallanInfoToPrint", data);
}