import { faBarcode, faHome, faPlaneArrival, faSearch, faTimes, faTruckMoving } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge, Breadcrumb, Button, ButtonGroup, Col, Form, InputGroup, Row } from "@themesberg/react-bootstrap";
import React, { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { updateToastInfo } from "../../actions/settings";
import { updateSpinnerState } from "../../actions/spinner";
import { setCurrentTripParcels, updateCurrentTripParcelsInStore } from "../../actions/trips";
import AchievementModal from "../../components/common/achievementModal";
import ConfirmationModal from "../../components/common/confirmationModal";
import { handleParcelReturnScan } from "../../parse-functions/parcels";
import { closeCurrentTrip, getTripDetailsByTripNumberFromServer, removeParcelFromSheet } from "../../parse-functions/trips";
import { Routes } from "../../router/routes";
import { convertArrayToObj, sortArrayOfJSONByKeyName } from "../../utils/json";
import ActiveTripParcels from "./activeTripParcels";
import ParcelTableToClose from "./ParcelTableToClose";
import EWayBillComponent from "../../components/ewaybill";
import { getTripBadgeStatus } from "../../utils/trips";
import { returnUserReadableDate } from "../../utils/datetime";
import { orderStatusMap } from "../../utils/constants";

const ActiveTripDetails = () => {
  const { objectId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedTrip = useSelector((state) => state.trips.selectedTrip || null);
  const activeTrips = useSelector((state) => state.trips.activeTrips || {});
  const [bulkUpdateEnabled, setBulkUpdateEnabled] = useState(false);
  const [currentActiveItem, setCurrentActiveItem] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [closeTripMode, setCloseTripMode] = useState(false);
  const [showTripCloseConfirmModal, setShowTripCloseConfirmModal] = useState(false);
  const [showAchievementModal, setShowAchievementModal] = useState(false);
  const warehouseCode = useSelector((state) => state?.user?.warehouse?.warehouseCode || "");
  const shortCode = useSelector((state) => state?.user?.warehouse?.shortCode || "");
  const [returnItemScanNo, setReturnItemScanNo] = useState("");
  const isSuperAdmin = useSelector((state) => state?.user?.preferences?.isSuperAdmin || false);
  
  useEffect(() => {
    if (selectedTrip) {
      getTripDetails()
    } else {
      navigate(Routes.Trips.TripsList.path);
    }
  }, []);


  const getTripDetails = async () => {
    dispatch(updateSpinnerState(true));
    try {
      const tripDetails = await getTripDetailsByTripNumberFromServer(selectedTrip, true);
      dispatch(setCurrentTripParcels(convertArrayToObj(tripDetails, "objectId")));
      dispatch(updateSpinnerState(false));
    } catch (e) {
      dispatch(updateSpinnerState(false));
    }
  }
  const handleRemoveItemFromList = (parcelObjectId) => {
    setCurrentActiveItem(parcelObjectId);
    setShowDeleteModal(true);
  };
  const handleOnConfirmClick = async () => {
    dispatch(updateSpinnerState(true));
    try {
      await removeParcelFromSheet(currentActiveItem);
      const parcelsCopy = JSON.parse(JSON.stringify(activeTrips[selectedTrip]?.parcels));
      delete parcelsCopy[currentActiveItem[0]];
      dispatch(setCurrentTripParcels(parcelsCopy))
      dispatch(updateToastInfo({
        show: true,
        type: "success",
        title: "Item Removed",
        message: "Parcel Removed from trip successfully"
      }));
      dispatch(updateSpinnerState(false));
      setCurrentActiveItem(null);
      setShowDeleteModal(false);
    } catch (e) {
      dispatch(updateToastInfo({
        show: true,
        type: "danger",
        title: "Removal Failed",
        message: "Parcel removal failed, Please try later"
      }));
      dispatch(updateSpinnerState(false));
      setCurrentActiveItem(null);
      setShowDeleteModal(false);
    }
  }

  const onTableSelectAll = (value) => {
    const tripData = Object.assign([], activeTrips[selectedTrip]?.parcels);
    Object.values(tripData).forEach((data) => data.isSelected = value);
    setBulkUpdateEnabled(value);
    dispatch(setCurrentTripParcels(tripData));
  };
  const onBulkUpdateApply = (action) => {
    console.log("bulk update action");
    console.log(action)
  }
  const onRowSelect = (value, objectId) => {
    const tripData = Object.assign([], activeTrips[selectedTrip]?.parcels);
    Object.values(tripData).forEach((data) => {
      if (data.objectId === objectId) {
        data.isSelected = value
      }
    });
    dispatch(setCurrentTripParcels(tripData, "objectId"));
    const someSelected = Object.values(tripData)?.filter(item => item.isSelected === true);
    setBulkUpdateEnabled(someSelected?.length > 0);
  }
  const handleDeleteModalClose = () => {
    setCurrentActiveItem(null);
    setShowDeleteModal(false);
  }
  const handleItemScan = async (val, parcelIds) => {
    dispatch(updateSpinnerState(true));
    try {
      const scannedItem = await handleParcelReturnScan({
        tripNumber: selectedTrip,
        serialNo: val,
        parcelIds,
        warehouseCode,
        shortCode
      });
      const {
        message,
        parcels
      } = scannedItem;
      dispatch(updateSpinnerState(false));
      dispatch(updateCurrentTripParcelsInStore(convertArrayToObj(parcels, "objectId")));
      dispatch(updateToastInfo({
        show: true,
        type: 'success',
        title: t("Return Scan Complete"),
        message: t(message)
      }))
      // dispatch(markCurrentParcelAs)
    } catch (e) {
      dispatch(updateToastInfo({
        show: true,
        type: 'danger',
        title: t("Return mark Scan Failed"),
        message: t(e.message)
      }))
      dispatch(updateSpinnerState(false));
    }
  }
  const handleCloseTrip = (value) => {
    setCloseTripMode(value);
  }
  const handleTripCloseConfirmModalClose = () => {
    setShowTripCloseConfirmModal(false);
  }
  const handleCloseAchievementModal = () => {
    setShowAchievementModal(false);
    navigate(Routes.Trips.TripsList.path);
  }
  const handleTripCloseConfirmation = async () => {
    dispatch(updateSpinnerState(true));
    try {
      const {
        tripNumber
      } = activeTrips[selectedTrip];
      await closeCurrentTrip(objectId, tripNumber, warehouseCode);
      dispatch(updateSpinnerState(false));
      setShowAchievementModal(true);
    } catch (e) {
      setShowTripCloseConfirmModal(false);
      dispatch(updateToastInfo({
        show: true,
        type: "danger",
        title: t("Trip Closure Failed"),
        message: t(e.message)
      }))
      dispatch(updateSpinnerState(false));
    }
  }

const handleItemScanWithoutSerial = () => {
  const {
    parcels
  } = activeTrips[selectedTrip];
  const selectedItems = Object.values(parcels)?.filter(parcel => parcel.isSelected);
  const parcelIds = selectedItems.map(item => item.objectId);
  handleItemScan("", parcelIds);
}

const disableMarkScan = () => {
  const parcels = activeTrips?.[selectedTrip]?.parcels || {};
  const selectedItem = Object.values(parcels)?.filter(parcel => parcel.isSelected);
  return selectedItem?.length === 0;
}
  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>{t("Trips")}</Breadcrumb.Item>
            <Breadcrumb.Item active>{t("Active")}</Breadcrumb.Item>
          </Breadcrumb>
          <h4>{`${selectedTrip} (${activeTrips[selectedTrip]?.vehicleNumber})`}</h4>
          <p className="mb-0">
            <span style={{
              marginRight: 12
            }}>
              {activeTrips[selectedTrip]?.createdBy && (
                <>Created By: <span className="fw-bold">{activeTrips[selectedTrip].createdBy}</span>,</>
              )}
            </span>
            <span style={{
              marginRight: 12
            }}>
              {activeTrips[selectedTrip]?.createdAt && (
                <>Created At: <span className="fw-bold">{returnUserReadableDate(activeTrips[selectedTrip].createdAt)}</span>,</>
              )}
            </span>
            <span style={{
              marginRight: 12
            }}>
              {activeTrips[selectedTrip]?.status && (
                <><span>&nbsp;&nbsp;<Badge bg={getTripBadgeStatus(activeTrips[selectedTrip].status)} className="badge-lg">{activeTrips[selectedTrip].status}</Badge> &nbsp;&nbsp;,</span></>
              )}
            </span>
            <span style={{
              marginRight: 12
            }}>
              {activeTrips[selectedTrip]?.dispatchData?.vehicleDetails?.vehicleType && (
                <>Vehicle Type: <span className="fw-bold">{activeTrips[selectedTrip]?.dispatchData?.vehicleDetails?.vehicleType}</span></>
              )}
            </span>
          </p>
        </div>

        <div className="btn-toolbar mb-2 mb-md-0">
          {closeTripMode ? (
            <ButtonGroup>
              <Button
                variant="primary"
                size="sm"
                onClick={() => {
                  setShowTripCloseConfirmModal(true);
                }}>
                <FontAwesomeIcon icon={faPlaneArrival} />&nbsp;&nbsp;
                {t("Close Trip")}
              </Button>
            </ButtonGroup>
          ) : (
            <ButtonGroup>
              <Button variant="outline-primary" size="sm" onClick={() => { window.open(`${window.location.origin}/trips/${objectId}/print`, "_blank") }}>{t("Print Sheet")}</Button>
              <EWayBillComponent />
            </ButtonGroup>
          )}

          <Button
            disabled={isSuperAdmin ? false : activeTrips[selectedTrip]?.status !== "pending"}
            onClick={() => {
              handleCloseTrip(!closeTripMode);
            }}
            size="sm"
            variant="outline-primary"
            style={{
              marginLeft: 20
            }}>
            {closeTripMode ? (
              <><FontAwesomeIcon icon={faTimes} />&nbsp;&nbsp; {t("Cancel")}</>
            ) : (
              <><FontAwesomeIcon icon={faTruckMoving} dis/>&nbsp;&nbsp; {t("Close Trip")}</>
            )}

          </Button>
        </div>
        
      </div>
      <div>
        {activeTrips[selectedTrip]?.analytics && (
          <span style={{
            maxWidth: '100%',
            overflowWrap: "anywhere"
          }}>{Object.keys(activeTrips[selectedTrip]?.analytics)?.map(keyName => {
            return (
              <>
              <span style={{
                marginRight: 10
              }}>
                <span className="fw-bold" style={{
                  marginRight: 5
                }}>
                  {orderStatusMap[keyName] || keyName}:
                </span>
                <span>
                  {activeTrips[selectedTrip]?.analytics?.[keyName]},
                </span>
              </span>
              </>
              
            )
          })}</span>
        )}
        </div>
      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
          {(closeTripMode) && (
            <>
            <Col xs={8} md={6} lg={3} xl={4}>
              <Row className="d-flex">
                <Col xs={8} md={8} lg={8} xl={8}>
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faSearch} />
                    </InputGroup.Text>
                    <Form.Control
                      onChange={(event) => {
                        setReturnItemScanNo(event.target.value)
                      }}
                      onKeyDown={(event) => {
                        if (event.nativeEvent.code === 'Enter') {
                          handleItemScan(event.target.value)
                          setReturnItemScanNo("");
                        }
                      }}
                      type="text"
                      value={returnItemScanNo}
                      placeholder={t("Enter Serial No to return scan")}
                    />
                  </InputGroup>
                </Col>
                <Col xs={4} md={4} lg={4} xl={4}>
                  <Button variant="primary" type="button" style={{ width: 100}} onClick={() => {
                    handleItemScan(returnItemScanNo);
                  }} >
                    <FontAwesomeIcon icon={faBarcode} />&nbsp;&nbsp;{t("Scan")}
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col xs={4} md={6} lg={3} xl={3} className="d-flex justify-content-end">
              <Button variant="primary" type="button" onClick={handleItemScanWithoutSerial} disabled={disableMarkScan()}>
                <FontAwesomeIcon icon={faBarcode} />&nbsp;&nbsp;{t("Mark Scan")}
                </Button>
            </Col>
            </>
          )}
        </Row>
      </div>
      {closeTripMode ? (
        <ParcelTableToClose />
      ) : (
        <ActiveTripParcels
          onRowSelect={onRowSelect}
          onBulkUpdateApply={onBulkUpdateApply}
          bulkUpdateEnabled={bulkUpdateEnabled}
          onTableSelectAll={onTableSelectAll}
          data={activeTrips[selectedTrip]?.parcels ? sortArrayOfJSONByKeyName(Object.values(activeTrips[selectedTrip]?.parcels), "invoiceNo") : []}
          removeItemFromList={handleRemoveItemFromList} />
      )}
      <ConfirmationModal
        showModal={showDeleteModal}
        onConfirm={handleOnConfirmClick}
        closeModal={handleDeleteModalClose}
        modalBody={"Are you sure you want to remove"}
        modalTitle={"Remove Orders"}
      />
      <ConfirmationModal
        showModal={showTripCloseConfirmModal}
        onConfirm={handleTripCloseConfirmation}
        closeModal={handleTripCloseConfirmModalClose}
        modalBody={"Are you sure you close this trip"}
        modalTitle={"Close Trip"}
      />
      <AchievementModal
        show={showAchievementModal}
        handleClose={handleCloseAchievementModal}
        buttonText={t("Go to trips")}
        topTitle={t("Your trip has successfully been closed")}
        mainHeaderText={t("Trip Completed")}
        subText={t("The freight of this trip will be automatically calculated based on an available contract")}
      />
    </>
  )
}
export default ActiveTripDetails;