import { MARK_LOADER_AS_SELECTED, SET_LOADERS_LIST_IN_STORE } from "./types"

export const setLoadersListInStore = (data) => {
    return {
        type: SET_LOADERS_LIST_IN_STORE,
        data
    }
}
export const markLoaderAsSelected = (objectId, selected) => {
    return {
        type: MARK_LOADER_AS_SELECTED,
        data: {
            objectId,
            selected
        }
    }
}