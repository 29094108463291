import Parse from "parse";

export const addNewTeamLead = (data) => {
    return Parse.Cloud.run("addNewIncharge", {
        ...data,
        isTeamLead: true
    });
}

export const getWMSUsersList = (data) => {
    return Parse.Cloud.run("getWMSUsersList", data);
}