import React from 'react';
import BreadCrumbComponent from '../../../components/common/Breadcrumb';
import { useTranslation } from 'react-i18next';
import { Routes } from '../../../router/routes';
import { Col, Nav, Row, Tab } from '@themesberg/react-bootstrap';
import AddProductForm from './AddProductForm';
import AddCategoryForm from './AddCategoryForm';
import AddCompanyForm from './AddCompanyForm';
import { useSelector } from 'react-redux';

const AddProductToPIM = () => {
  const { t } = useTranslation();
  const isAdmin = useSelector(
    (state) => state?.user?.preferences?.isAdmin || false
  );

  const breadCrumbItems = !isAdmin
    ? [
        {
          title: t('Products'),
        },
        {
          title: t('Add Product'),
        },
      ]
    : [
        {
          title: t('Admin'),
          link: Routes.Admin.Temposheet.path,
        },
        {
          title: t('Add Product'),
        },
      ];

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <BreadCrumbComponent items={breadCrumbItems} />
          <h4>{'Add New Product'}</h4>
          <p className="mb-0">
            {'You can add a new Product / Category / Company from here'}
          </p>
        </div>
      </div>
      <Tab.Container defaultActiveKey="product">
        <Row>
          <Col lg={12}>
            <Nav className="nav-tabs">
              <Nav.Item>
                <Nav.Link eventKey="product" className="mb-sm-3 mb-md-0">
                  {t('Add Product')}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="company" className="mb-sm-3 mb-md-0">
                  {t('Add Company')}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="category" className="mb-sm-3 mb-md-0">
                  {t('Add Category')}
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col lg={12}>
            <Tab.Content>
              <Tab.Pane eventKey="product" className="py-4">
                <AddProductForm />
              </Tab.Pane>
              <Tab.Pane eventKey="category" className="py-4">
                <AddCategoryForm />
              </Tab.Pane>
              <Tab.Pane eventKey="company" className="py-4">
                <AddCompanyForm />
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </>
  );
};
export default AddProductToPIM;
