import Parse from 'parse';

export const createPaymentVoucher = async (data) => {
  return Parse.Cloud.run('createPaymentVoucher', data);
};

export const getPaymentVouchers = async (data) => {
  return Parse.Cloud.run('getPaymentVouchers', data);
};

export const getPaymentVoucherById = async (data) => {
  return Parse.Cloud.run('getPaymentVoucherById', data);
};

export const getUnPaidVouchersList = async (data) => {
  return Parse.Cloud.run('getUnPaidVouchersList', data);
};

export const updatePaymentVoucher = async (data) => {
  return Parse.Cloud.run('updatePaymentVoucher', data);
};

export const getTripsPaymentList = async (data) => {
  return Parse.Cloud.run('getTripsPaymentList', data);
};

export const getPaidTripsPaymentList = async (data) => {
  return Parse.Cloud.run('getPaidTripsPaymentList', data);
};

export const savePaymentData = async (data) => {
  return Parse.Cloud.run('savePaymentData', data);
};
