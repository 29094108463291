import React, { useState } from 'react';
import Pagination from 'react-bootstrap-table2-paginator';
import * as Paginator from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { Button, Card, Col, Form, Row } from '@themesberg/react-bootstrap';
import Table from 'react-bootstrap-table-next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faFilter } from '@fortawesome/free-solid-svg-icons';
import DropdownComponent from '../../components/common/Dropdown';
import { returnRowBGColor } from '../../utils/parcels';
import EmptyTable from '../../components/common/EmptyTableView';
import arrayToBreadCrumbs from '../../utils/sms/arrayToBreadCrumbs';
import { useTranslation } from 'react-i18next';
import AsyncDropdown from '../../components/AsyncDropdown';
import BreadCrumbComponent from '../../components/common/Breadcrumb';
import {
  customSizePerPage,
  customTotal,
} from '../../components/common/parcelStatusPages';
import { arrayToDropdownOptions } from '../../utils/sms/arrayToDropdownOptions';
import { useDispatch } from 'react-redux';
import { updateToastInfo } from '../../actions/settings';
import { updateSpinnerState } from '../../actions/spinner';
import {
  getPaymentVoucherById,
  getUnPaidVouchersList,
  updatePaymentVoucher,
} from '../../parse-functions/payments';
import { VouchersSentColumns } from './utils';
import VoucherDataModal from './VoucherDataModal';
import { debounce } from '../../utils/debounce';

const PaymentChallans = () => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [voucherNumber, setVoucherNumber] = useState('');
  const [selectedVoucherId, setSelectedVoucherId] = useState(null);
  const [showRemarkModal, setShowRemarkModal] = useState(false);
  const [readOnlyMode, setReadOnlyMode] = useState(false);
  const [bulkActionSelectedOption, setBulkActionSelectedOption] =
    useState(null);
  const dispatch = useDispatch();
  const breadCrumbItems = arrayToBreadCrumbs([['challans']]);
  const bulkActionOptions = [
    {
      label: 'Pay',
      value: 'pay',
    },
  ];

  const fetchOptions = debounce(async (inputValue) => {
    if (inputValue.length > 1) {
      try {
        const res = await getUnPaidVouchersList({
          filter: inputValue.toUpperCase(),
        });
        const options = arrayToDropdownOptions(res);
        return options;
      } catch (error) {
        console.log(error);
      }
    }
  });

  const handleChange = (options) => {
    setVoucherNumber(options.value);
  };

  const handleItemUpdate = () => {
    if (selectedVoucherId) {
      switch (bulkActionSelectedOption) {
        case 'pay':
          setReadOnlyMode(false);
          setShowRemarkModal(true);
          break;
        default: {
          dispatch(
            updateToastInfo({
              show: true,
              type: 'danger',
              title: t('Select an Action'),
              message: t('Please select an action from the dropdown'),
            })
          );
          break;
        }
      }
    }
  };

  const handleSubmit = async () => {
    if (!voucherNumber) {
      dispatch(
        updateToastInfo({
          show: true,
          type: t('danger'),
          title: '',
          message: t('please select/enter VoucherNumber!'),
        })
      );
      return;
    }
    try {
      dispatch(updateSpinnerState(true));
      const voucher = await getPaymentVoucherById({ voucherNumber });
      setData([voucher]);
      dispatch(updateSpinnerState(false));
    } catch (error) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: t('danger'),
          title: t('failed to get trips!'),
          message: t(error.message),
        })
      );
    }
  };

  const onRowSelect = (value, objectId) => {
    if (selectedVoucherId) {
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('Only 1 item allowed'),
          message: t('Please deselect other item before choosing this'),
        })
      );
    } else {
      if (value) {
        setSelectedVoucherId(objectId);
      } else {
        setSelectedVoucherId(null);
      }
    }
  };

  const showItemsModal = (objectId) => {
    onRowSelect(true, objectId);
    setReadOnlyMode(true);
    setShowRemarkModal(true);
  };

  const handleUpdateModalSubmit = async (id, tripsDetails) => {
    try {
      dispatch(updateSpinnerState(true));
      const res = await updatePaymentVoucher({ id, tripsDetails });
      dispatch(
        updateToastInfo({
          show: true,
          type: 'success',
          title: '',
          message: res.message,
        })
      );
      setShowRemarkModal(false);
      setVoucherNumber('');
      setData([]);
      dispatch(updateSpinnerState(false));
    } catch (error) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('failed'),
          message: t(error.message),
        })
      );
    }
  };
  return (
    <>
      <ToolkitProvider
        exportCSV
        keyField="objectId"
        search={true}
        sort
        columns={VouchersSentColumns(onRowSelect, showItemsModal, true)}
        data={data}
        wrapperClasses="table-responsive"
      >
        {({ baseProps, searchProps, csvProps }) => (
          <Paginator.PaginationProvider
            pagination={Pagination({
              custom: true,
              showTotal: true,
              alwaysShowAllBtns: true,
              totalSize: data?.length,
              paginationTotalRenderer: customTotal,
              sizePerPageRenderer: customSizePerPage,
            })}
          >
            {({ paginationProps, paginationTableProps }) => (
              <>
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                  <div className="d-block mb-4 mb-md-0">
                    <BreadCrumbComponent items={breadCrumbItems} />
                    <h4>{t('Payment Challans')}</h4>
                  </div>
                </div>
                <div className="table-settings  mb-4">
                  <div className="d-flex align-items-center gap-3">
                    <div>
                      <Form.Label>VoucherNumbers</Form.Label>
                      <AsyncDropdown
                        onChange={handleChange}
                        width={300}
                        disabled={false}
                        fetchOptions={fetchOptions}
                        placeholder={t('Select field to Search by')}
                        // isMultiSelect={true}
                      />
                    </div>
                    <div className="mt-4">
                      <Button
                        style={{
                          height: 38,
                        }}
                        variant="primary"
                        onClick={handleSubmit}
                      >
                        <FontAwesomeIcon icon={faFilter} />
                        &nbsp;&nbsp; {t('submit')}
                      </Button>
                    </div>
                  </div>
                </div>
                <Card>
                  <div className="table-responsive py-2">
                    <Card.Header>
                      <Row className="d-flex justify-content-between">
                        <Col xs={8} md={6} className="d-flex py-1">
                          <>
                            <Form.Group
                              id="substatus"
                              className="d-flex align-items-center flex-column"
                            >
                              <DropdownComponent
                                onChange={(option) => {
                                  setBulkActionSelectedOption(option.value);
                                }}
                                width={300}
                                disabled={!selectedVoucherId}
                                options={bulkActionOptions}
                                placeholder={t('Select Action')}
                              />
                            </Form.Group>
                            <Button
                              style={{
                                height: 37,
                              }}
                              size="sm"
                              onClick={handleItemUpdate}
                              disabled={!selectedVoucherId}
                              className="ms-3 btn btn-secondary"
                              variant="secondary"
                            >
                              <FontAwesomeIcon icon={faCheck} />
                              &nbsp;&nbsp;{t('Update')}
                            </Button>
                          </>
                        </Col>
                        <Col
                          xs={4}
                          md={4}
                          className="d-flex justify-content-md-end py-1"
                        >
                          <Search.SearchBar
                            {...searchProps}
                            placeholder={t('Search any value in table here')}
                          />
                          <Search.ClearSearchButton {...searchProps} />
                        </Col>
                      </Row>
                    </Card.Header>
                    <Table
                      rowClasses={(row) => {
                        const rowBGColor = returnRowBGColor(
                          row?.dashboardStatus ||
                            row?.mobileStatus ||
                            row?.status
                        );
                        return `${rowBGColor} border-bottom`;
                      }}
                      noDataIndication={<EmptyTable />}
                      {...baseProps}
                      {...paginationTableProps}
                      bodyClasses="border-0"
                      classes="table-flush dataTable-table"
                    />

                    <Card.Footer>
                      <Row>
                        <Col xs={12} md={4} className="py-1">
                          <Paginator.SizePerPageDropdownStandalone
                            {...paginationProps}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          className="d-flex align-items-center py-1"
                        >
                          <Paginator.PaginationTotalStandalone
                            {...paginationProps}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          className="d-flex justify-content-md-end align-items-center mb-0 py-1"
                        >
                          <Paginator.PaginationListStandalone
                            {...paginationProps}
                          />
                        </Col>
                      </Row>
                    </Card.Footer>
                  </div>
                </Card>
              </>
            )}
          </Paginator.PaginationProvider>
        )}
      </ToolkitProvider>

      <VoucherDataModal
        readOnly={readOnlyMode}
        showUpdateModal={showRemarkModal}
        handleCloseModal={() => {
          setShowRemarkModal(false);
        }}
        addRemarkAndUpdateRequisition={handleUpdateModalSubmit}
        requisitonItems={data[0] || {}}
      />
    </>
  );
};

export default PaymentChallans;
