import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Row } from "@themesberg/react-bootstrap"
import { useTranslation } from "react-i18next";
import EditableDropDown from "../../../components/common/EditableDropdown";
import { returnSelectedCategories } from "../../../utils/wms/teamlead";
import { useSelector } from "react-redux";
import { returnWarehouseCategoryOptions } from "../../../utils/wms/categories";

const TeamLeadUpdateModal = (props) => {
    const {
        show,
        handleClose,
        onConfirm,
        user
    } = props;
    const { t } = useTranslation();
    const [formData, setFormData] = useState(user);
    const [formErrors, setFormErrors] = useState({});
    const warehouseCategories = useSelector((state) => state?.user?.config?.warehouseCategories || []);

    useEffect(() => {
        setFormData(user);
    }, [user]);

    const onFormInputChange = (fieldName, value) => {
        setFormData(oldFormData => ({
            ...oldFormData,
            [fieldName]: value
        }));
    };
    const handleConfirmClick = () => {
        onConfirm(formData);
    }
    return (
        <Modal backdrop={"static"} show={show} onHide={handleClose}>
        <Modal.Header>
            <Modal.Title className="h6">{t(`Update ${user?.inchargeName || "TeamLead"}`)}</Modal.Title>
            <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
            <Row>
            <Form.Group className="mb-3 mt-4">
                        <Form.Label>{t("TeamLead Name")}</Form.Label>
                        <Form.Control
                            required
                            value={formData.inchargeName}
                            isInvalid={formErrors["inchargeName"]?.length}
                            type="text"
                            onChange={(event) => {
                                onFormInputChange("inchargeName", event.target.value);
                            }} />
                        {formErrors["inchargeName"]?.length && (
                            <Form.Control.Feedback type="invalid">{t("Please enter TeamLead name.")}</Form.Control.Feedback>
                        )}

                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control
                            required
                            value={formData.phoneNumber}
                            isInvalid={formErrors["phoneNumber"]?.length}
                            type="number"
                            onChange={(event) => {
                                onFormInputChange("phoneNumber", event.target.value);
                            }} />
                        {formErrors["phoneNumber"]?.length && (
                            <Form.Control.Feedback type="invalid">{t("Please enter phonenumber.")}</Form.Control.Feedback>
                        )}

                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>{t("Category")}</Form.Label>
                        <EditableDropDown
                            isMultiSelect
                            value={formData.category}
                            onChange={(option) => {
                                const selectedCategories = returnSelectedCategories(option, warehouseCategories);
                                setFormData(oldData => ({
                                    ...oldData,
                                    category: selectedCategories,
                                }))
                            }}
                            options={returnWarehouseCategoryOptions(warehouseCategories, true)}
                            placeholder={t("Select Category")}
                        />
                    </Form.Group>
            </Row>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={handleConfirmClick}>
                {t("Update Teamlead")}
            </Button>
            <Button variant="link" className="text-gray ms-auto" onClick={handleClose}>
                {t("Cancel")}
            </Button>
        </Modal.Footer>
    </Modal>
    )
}
export default TeamLeadUpdateModal;