import { Badge } from "@themesberg/react-bootstrap";
import React from "react";
import { getTripBadgeStatus } from "../../utils/trips";

export const returnVendorListTableColumns = (onRowSelect) => {
    return ([
        {
            dataField: "vendorName", sort: true, text: "Vendor Info", formatter: (cell, row) => {
                return (
                    <div className="d-block">
                        <span className="fw-bold">{cell}</span>
                        <div className="fw-normal">{row?.vendorCode}</div>
                    </div>
                )
            }
        },
        {
            dataField: "contactNumber", text: "Contact Info", formatter: (cell, row) => {
                return (
                    <div className="d-block">
                        <span className="fw-bold">{cell}</span>
                        <div className="fw-normal">{row?.emailId}</div>
                    </div>
                )
            }
        },
        {
            dataField: "gstNumber", text: "GST Number", formatter: (cell, row) => {
                return (
                    <div className="d-block">
                        <span className="fw-bold">{cell}</span>
                    </div>
                )
            }
        },
        {
            dataField: "status", text: "Status", formatter: (cell, row) => {
                return (
                    <div className="d-block">
                        <div className="fw-bold">
                            <span>&nbsp;&nbsp;<Badge bg={getTripBadgeStatus(cell)} className="badge-lg">{cell}</Badge> &nbsp;&nbsp;</span>
                        </div>
                        <span className="fw-normal">{row?.tripInfo?.vehicleNumber}</span>
                    </div>
                )
            }
        }
    ])
}