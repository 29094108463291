import { Button, Card, Form, InputGroup } from '@themesberg/react-bootstrap';
import Joi from 'joi';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { returnWarehouseDropdownOptions } from '../../../utils/warehouse';
import { useDispatch, useSelector } from 'react-redux';
import DropdownComponent from '../../../components/common/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarAlt,
  faPlus,
  faSync,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { StyledSearchBar } from '../../trips/styles';
import {
  returnCategoriesDropdownList,
  returnCompaniesDropdownList,
  returnProductsDropdownList,
} from '../../../utils/products';
import Datetime from 'react-datetime';
import { returnUserReadableDate } from '../../../utils/datetime';
import TableOnly from '../../scheme-management/Components/IndividualScheme.js/TableOnly';
import { updateSpinnerState } from '../../../actions/spinner';
import { apiCall } from '../../../middlewares/api';
import { apiCallConsts } from '../../../constants/apiCallConsts';
import { updateToastInfo } from '../../../actions/settings';
import {
  getAllCompaniesAndCategoriesData,
  getProductDataByVANNo,
  getProductsBasedOnCategoryAndCompany,
} from '../../../parse-functions/products';
import {
  setCompanyAndCategoryDataInStore,
  setMatchingProductsInStore,
} from '../../../actions/products';
import { productsColumns } from './utils';
import { returnValidationErrorMessages } from '../../../utils/validation';
import { setStoresInStore } from '../../../actions/oms/stores';

const createSchema = Joi.object({
  requestingBranchName: Joi.string().required(),
  receivingBranchName: Joi.string().required(),
  preferredDeliveryDate: Joi.date().required(),
  requestingContactPerson: Joi.string().required(),
  requestingContactNumber: Joi.string().length(10).required(),
  requesterRemarks: Joi.string(),
  products: Joi.any(),
});

const CreateRequest = () => {
  const { t } = useTranslation();
  const storeName = useSelector(
    (state) => state?.user?.preferences?.storeName || ''
  );
  const storeList = useSelector((state) => state?.stores?.storeList || []);
  const [formData, setFormData] = useState({
    requestingBranchName: storeName,
    receivingBranchName: '',
  });
  const [formErrors, setFormErrors] = useState({});
  const [warehouseOptions, setWarehouseOptions] = useState(storeList);
  const zone = useSelector((state) => state?.user?.warehouse?.zone || '');
  const [searchBy, setSearchBy] = useState('van');
  const [vanNo, setVanNo] = useState(null);
  const [allProducts, setAllProducts] = useState([]);
  const companies = useSelector((state) => state?.products?.companies || {});
  const categories = useSelector((state) => state?.products?.categories || {});
  const userName = useSelector((state) => state?.user?.user?.username || '');
  const [availableQuantity, setAvailabeQuantity] = useState('');
  const productsList = useSelector(
    (state) => state.products?.productsList || {}
  );
  const dispatch = useDispatch();

  const onFormInputChange = (fieldName, value) => {
    setFormErrors((prev) => ({ ...prev, [fieldName]: null }));
    setFormData((oldFormData) => ({
      ...oldFormData,
      [fieldName]: value,
    }));
  };
  // console.log(formData);

  const messageDisplayer = (type, title, message) => {
    dispatch(
      updateToastInfo({
        show: true,
        type: type,
        title: t(title),
        message: t(message),
      })
    );
  };

  const addProducts = () => {
    const productItem = {
      vanNo: formData?.products?.vanNo,
      productTitle: formData?.products?.productTitle,
      company: formData?.products?.company,
      category: formData?.products?.category,
      requestedQuantity: formData?.products?.requestedQuantity,
      id: Date.now(),
    };
    setAllProducts([...allProducts, productItem]);
    setFormData((prev) => ({ ...prev, products: {} }));
    if (searchBy === vanNo) {
      setVanNo(null);
    }
  };

  const getAllCompaniesAndCategories = async () => {
    if (
      Object.keys(companies).length > 0 &&
      Object.keys(categories).length > 0
    ) {
      return;
    }
    dispatch(updateSpinnerState(true));
    try {
      const data = await getAllCompaniesAndCategoriesData();
      dispatch(setCompanyAndCategoryDataInStore(data));
      dispatch(updateSpinnerState(false));
    } catch (e) {
      dispatch(updateSpinnerState(false));
      messageDisplayer(
        'danger',
        'Failed Getting Company & Category data',
        'Please refresh the page to try getting this data again or contact support'
      );
    }
  };

  const fetchStores = async () => {
    if (storeList.length > 0) {
      return;
    }
    try {
      dispatch(updateSpinnerState(false));
      const res = await apiCall(
        apiCallConsts.GET_METHOD,
        `internal/store/all?region=${zone}`
      );
      setWarehouseOptions(res);
      dispatch(setStoresInStore(res));
      dispatch(updateSpinnerState(false));
    } catch (error) {
      dispatch(updateSpinnerState(false));
      messageDisplayer('danger', 'failed', error.message);
    }
  };

  useEffect(() => {
    fetchStores();
    getAllCompaniesAndCategories();
  }, []);

  const handleXClick = (id) => {
    setAllProducts((prev) => prev.filter((products) => products.id !== id));
  };

  const getProductListFromCategory = async (companyName, productCategory) => {
    try {
      dispatch(updateSpinnerState(true));
      const products = await getProductsBasedOnCategoryAndCompany({
        companyName,
        productCategory,
      });
      dispatch(setMatchingProductsInStore(products));
      dispatch(updateSpinnerState(false));
    } catch (e) {
      dispatch(updateSpinnerState(false));
      messageDisplayer('danger', 'Failed', e.message);
    }
  };

  const fetchProductDetailsByVAN = async () => {
    dispatch(updateSpinnerState(true));
    try {
      const data = await getProductDataByVANNo({ vanNo });
      const { product } = data;
      setFormData((oldData) => ({
        ...oldData,
        products: {
          vanNo: product?.vanNo,
          company: product?.companyName,
          category: product?.productCategory,
          productTitle: product?.productName,
        },
      }));
      if (Object.keys(data)?.length > 0) {
        if (formData?.receivingBranchName) {
          messageDisplayer('success', '', 'Product data has been fetched');
        }
      } else {
        messageDisplayer(
          'danger',
          'Failed',
          'Failed to get the product info using VAN number. Please check the value entered or contact support.'
        );
      }
      dispatch(updateSpinnerState(false));
    } catch (e) {
      dispatch(updateSpinnerState(false));
      messageDisplayer('danger', 'Failed', e.message);
    }
  };

  const handleAddRequest = async () => {
    const { error: formErrors = {}, value: formValue } = createSchema.validate(
      formData,
      {
        abortEarly: false,
      }
    );
    const { details = {} } = formErrors;
    if (details?.length > 0) {
      const validationErrorMessages = returnValidationErrorMessages(details);
      setFormErrors(validationErrorMessages);
    } else {
      try {
        dispatch(updateSpinnerState(true));
        // console.log(
        //   JSON.stringify({ data: { ...formData, products: allProducts } })
        // );
        const res = await apiCall(apiCallConsts.POST_METHOD, 'internal/ibt', {
          data: { ...formData, products: allProducts, username: userName },
        });
        setFormData({ requestingBranchName: storeName });
        setAllProducts([]);
        setVanNo('');
        setAvailabeQuantity('');
        setSearchBy('van');
        messageDisplayer('success', '', 'Created successfully!');
        dispatch(updateSpinnerState(false));
      } catch (e) {
        dispatch(updateSpinnerState(false));
        messageDisplayer('danger', 'failed!', e.message);
      }
    }
  };

  const fetchAvailableQuantity = async () => {
    if (!formData?.receivingBranchName) {
      messageDisplayer(
        'danger',
        'failed!',
        'please select Receiving Branch before fetching'
      );
      return;
    }
    try {
      const storeCode = warehouseOptions.find(
        (option) => option.warehouseName === formData?.receivingBranchName
      )?.shortCode;
      const response = await apiCall(
        apiCallConsts.GET_METHOD,
        'internal/inventory/all',
        { vanNo, storeCode }
      );
      console.log(response?.data[0]);
      setAvailabeQuantity(response?.data[0]?.available || 0);
    } catch (error) {
      messageDisplayer('danger', 'failed!', error.message);
    }
  };
  // console.log(availableQuantity);

  return (
    <>
      <h2 className="mb-3 ms-2">{t('Inter- Branch Transfers')}</h2>
      <div className="d-flex gap-3 mx-2">
        <div className="w-25">
          <div className="d-flex  bg-white p-4 mb-2">
            <h5>{t('Branch Details')}</h5>
          </div>
          <div className="bg-white p-3 py-4">
            <Form.Group className="mb-3">
              <Form.Label
                className={
                  formErrors?.['requestingBranchName'] ? 'text-danger' : ''
                }
              >
                {t('Requesting Branch Name')}
              </Form.Label>
              <DropdownComponent
                onChange={(option) =>
                  onFormInputChange('requestingBranchName', option.value)
                }
                width={'100%'}
                options={
                  warehouseOptions?.length > 0 &&
                  returnWarehouseDropdownOptions(warehouseOptions)
                }
                placeholder={storeName ? storeName : 'Select branch'}
                // value={formData?.requestingBranchName || ''}
              />
            </Form.Group>
            {formErrors?.['requestingBranchName'] && (
              <Form.Control.Feedback type="invalid">
                {formErrors['requestingBranchName']}
              </Form.Control.Feedback>
            )}

            <Form.Group className="mb-3">
              <Form.Label
                className={
                  formErrors?.['receivingBranchName'] ? 'text-danger' : ''
                }
              >
                {t('Fulfilling Branch Name')}
              </Form.Label>
              <DropdownComponent
                onChange={(option) =>
                  onFormInputChange('receivingBranchName', option.value)
                }
                // value={formData?.receivingBranchName || ''}
                options={
                  warehouseOptions?.length > 0 &&
                  returnWarehouseDropdownOptions(warehouseOptions)
                }
                placeholder={t('Select branch')}
              />
            </Form.Group>
            {formErrors?.['receivingBranchName'] && (
              <Form.Control.Feedback type="invalid">
                {formErrors['receivingBranchName']}
              </Form.Control.Feedback>
            )}

            <Form.Label>{t('Preferred Delivery Date')}</Form.Label>
            <Datetime
              timeFormat={false}
              closeOnSelect={true}
              onChange={(value) => {
                onFormInputChange('preferredDeliveryDate', new Date(value));
              }}
              renderInput={(props, openCalendar) => (
                <InputGroup>
                  <InputGroup.Text className="bg-light">
                    <FontAwesomeIcon
                      icon={faCalendarAlt}
                      className="icon icon-lg"
                    />
                  </InputGroup.Text>
                  <Form.Control
                    className="bg-light"
                    isInvalid={!!formErrors?.['preferredDeliveryDate']}
                    required
                    type="text"
                    value={
                      formData?.preferredDeliveryDate
                        ? returnUserReadableDate(
                            formData?.preferredDeliveryDate
                          )
                        : ''
                    }
                    placeholder="Select end: dd/mm/yyyy"
                    onFocus={openCalendar}
                  />
                  {formErrors?.['preferredDeliveryDate'] && (
                    <Form.Control.Feedback type="invalid">
                      {formErrors['preferredDeliveryDate']}
                    </Form.Control.Feedback>
                  )}
                </InputGroup>
              )}
            />

            <Form.Group className="mt-1">
              <Form.Label>{t('Contact Person')}</Form.Label>
              <Form.Control
                className="bg-light"
                required
                value={formData?.requestingContactPerson || ''}
                isInvalid={formErrors?.['requestingContactPerson']?.length}
                type="text"
                onChange={(event) => {
                  onFormInputChange(
                    'requestingContactPerson',
                    event.target.value
                  );
                }}
                placeholder={t('Enter Name')}
              />
              {formErrors?.['requestingContactPerson']?.length && (
                <Form.Control.Feedback type="invalid">
                  {t('Please enter contact name.')}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Group className="mt-1">
              <Form.Label>{t('Contact Number')}</Form.Label>
              <Form.Control
                className="bg-light"
                required
                value={formData?.requestingContactNumber || ''}
                isInvalid={formErrors?.['requestingContactNumber']?.length}
                type="number"
                onChange={(event) => {
                  onFormInputChange(
                    'requestingContactNumber',
                    event.target.value
                  );
                }}
                placeholder={t('Enter Number')}
              />
              {formErrors?.['requestingContactNumber']?.length && (
                <Form.Control.Feedback type="invalid">
                  {t('Please enter proper Number.')}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Group className="mt-1">
              <Form.Label>{t('Remarks')}</Form.Label>
              <Form.Control
                className="bg-light"
                required
                as="textarea"
                rows="1"
                value={formData?.requesterRemarks || ''}
                isInvalid={formErrors?.['requesterRemarks']?.length}
                type="textArea"
                onChange={(event) => {
                  onFormInputChange('requesterRemarks', event.target.value);
                }}
                placeholder={t('Enter Remarks')}
              />
              {formErrors?.['requesterRemarks']?.length && (
                <Form.Control.Feedback type="invalid">
                  {t('Please enter Remarks.')}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </div>
        </div>
        <div className="w-75">
          <div
            className="d-flex flex-row justify-content-between align-items-center bg-white mb-2"
            style={{ padding: '2.5%' }}
          >
            <div className="flex-grow-1">
              <h5>{t('Product Details')}</h5>
            </div>
            <div className="d-flex flex-grow-1 gap-3 justify-content-center align-items-center">
              <Form.Label>{t('Search Mode')}</Form.Label>
              <DropdownComponent
                onChange={(option) => {
                  setSearchBy(option.value);
                }}
                width={'100%'}
                options={[
                  {
                    label: 'Search by VAN No.',
                    value: 'van',
                  },
                  {
                    label: 'Search Manually',
                    value: 'manual',
                  },
                ]}
                placeholder={'Select an Option'}
                defaultValue={'Search by VAN No.'}
              />
            </div>
          </div>

          {searchBy === 'van' && (
            <div className="bg-white px-3 py-3 mb-2">
              <Form.Group>
                <Form.Label>{t('Enter VAN No.')}</Form.Label>
                <div className="d-flex gap-5">
                  <StyledSearchBar
                    required
                    isInvalid={formErrors?.['vanNo']?.length}
                    type="number"
                    width={'80%'}
                    value={vanNo}
                    placeholder={t('Enter VAN No')}
                    onChange={(event) => {
                      setFormErrors((prev) => ({ ...prev, vanNo: null }));
                      setVanNo(event.target.value);
                    }}
                  />
                  <Button
                    variant="light"
                    style={{ width: '180px' }}
                    className="rounded-0"
                    disabled={!vanNo}
                    onClick={() => {
                      if (!vanNo) {
                        setFormErrors((prev) => ({
                          ...prev,
                          vanNo: 'Please enter VanNo.',
                        }));
                      }
                      fetchProductDetailsByVAN();
                      fetchAvailableQuantity();
                    }}
                  >
                    <FontAwesomeIcon icon={faSync} /> &nbsp;&nbsp; {t('Fetch')}
                  </Button>
                </div>
                {formErrors?.['vanNo']?.length > 0 && (
                  <Form.Control.Feedback type="invalid">
                    {t('Please enter VanNo.')}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </div>
          )}
          <div className="bg-white p-4">
            <div className="d-flex flex-wrap gap-4">
              <div className="flex-grow-1" style={{ flexBasis: '48%' }}>
                <Form.Label>{t('Company Name')}</Form.Label>
                <DropdownComponent
                  disabled={searchBy === 'van'}
                  onChange={(option) => {
                    setFormData((prev) => ({
                      ...prev,
                      products: {
                        ...prev.products,
                        company: option.value,
                      },
                    }));
                  }}
                  options={returnCompaniesDropdownList(companies)}
                  placeholder={
                    formData?.products
                      ? formData?.products?.company
                      : t('Select Company Name')
                  }
                />
              </div>
              <div className="flex-grow-1" style={{ flexBasis: '48%' }}>
                <Form.Label>{t('Product Category')}</Form.Label>
                <DropdownComponent
                  disabled={searchBy === 'van'}
                  className="mt-2 mb-2"
                  onChange={(option) => {
                    setFormData((prev) => ({
                      ...prev,
                      products: {
                        ...prev.products,
                        category: option.value,
                      },
                    }));
                    getProductListFromCategory(
                      formData.products.company,
                      option.value
                    );
                  }}
                  options={returnCategoriesDropdownList(categories)}
                  placeholder={
                    formData?.products
                      ? formData?.products?.category
                      : t('Select Product Category')
                  }
                />
              </div>
              <div className="flex-grow-1" style={{ flexBasis: '48%' }}>
                <Form.Label>{t('Product Name')}</Form.Label>
                <DropdownComponent
                  disabled={searchBy === 'van'}
                  className="mt-2 mb-2"
                  onChange={(option) => {
                    setFormData((prev) => ({
                      ...prev,
                      products: {
                        ...prev.products,
                        productTitle: option?.value?.productName,
                        vanNo: option?.value?.vanNo,
                      },
                    }));
                    fetchAvailableQuantity();
                  }}
                  options={returnProductsDropdownList(productsList)}
                  placeholder={
                    formData?.products
                      ? formData?.products?.productTitle
                      : t('Select Product Name')
                  }
                />
              </div>
              <div className="flex-grow-1" style={{ flexBasis: '48%' }}>
                <Form.Label>{t('Available Quantity')}</Form.Label>
                <StyledSearchBar
                  type="number"
                  width={'100%'}
                  value={availableQuantity}
                  disabled={true}
                  placeholder={t('Available Quantity')}
                />
              </div>
              <div className="flex-grow-1" style={{ flexBasis: '48%' }}>
                <Form.Label>{t('Quantity')}</Form.Label>
                <StyledSearchBar
                  type="number"
                  width={'100%'}
                  onChange={(event) => {
                    const { value } = event.target;
                    setFormData((prev) => ({
                      ...prev,
                      products: {
                        ...prev.products,
                        requestedQuantity: value,
                      },
                    }));
                  }}
                  value={formData?.products?.requestedQuantity || ''}
                  placeholder={t('Enter Product Quantity')}
                />
              </div>
            </div>
            <div className="mt-4 d-flex justify-content-end gap-3">
              <Button
                onClick={() =>
                  setFormData((prev) => ({ ...prev, products: {} }))
                }
                variant="light"
                className="rounded-0"
                style={{ width: '180px' }}
              >
                <FontAwesomeIcon icon={faXmark} />
                &nbsp;&nbsp;{t('Clear')}
              </Button>
              <Button
                disabled={
                  !(
                    formData?.products?.productTitle &&
                    formData?.products?.requestedQuantity
                  )
                }
                onClick={addProducts}
                style={{ width: '180px' }}
                variant="light"
                className="rounded-0"
              >
                <FontAwesomeIcon icon={faPlus} />
                &nbsp;&nbsp;{t('Add to Products')}
              </Button>
            </div>
          </div>
        </div>
      </div>
      {allProducts.length > 0 && (
        <Card className="p-2 mt-2">
          <Card.Title>{t('Products Requested for Transfer')}</Card.Title>
          <TableOnly
            data={allProducts || []}
            isFooterRequired={true}
            columns={productsColumns(handleXClick)}
            id="createRequest"
          />
        </Card>
      )}
      <div className="mt-2 mb-5 d-flex justify-content-end gap-3">
        <Button
          onClick={() => {
            setFormData({});
            setAllProducts([]);
            setSearchBy('van');
            setVanNo(null);
          }}
          variant="light"
          className="rounded-0"
          style={{ width: '180px' }}
        >
          <FontAwesomeIcon icon={faXmark} />
          &nbsp;&nbsp;{t('Clear')}
        </Button>
        <Button
          onClick={handleAddRequest}
          disabled={allProducts.length === 0}
          style={{ width: '180px' }}
          variant="light"
          className="rounded-0"
        >
          <FontAwesomeIcon icon={faPlus} />
          &nbsp;&nbsp;{t('Add to Request')}
        </Button>
      </div>
    </>
  );
};

export default CreateRequest;
