import { Button } from "@themesberg/react-bootstrap";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ExcelRenderer } from "react-excel-renderer";
import useFileUpload from 'react-use-file-upload';
import { updateToastInfo } from "../../actions/settings";
import { generateDisplayDataFromExcel, convertExcelDataToJSONRows, missingBranchesData, validateUploadedInvoiceNumbers } from "../../utils/excel";
import { addOrdersForVehicle } from "../../actions/vehicles";
import { updateSpinnerState } from "../../actions/spinner";
import { getBranchDataFromServer } from "../../parse-functions/branch";
import { convertArrayToObj } from "../../utils/json";
import { useTranslation } from "react-i18next";
import { addParcelToTemporaryTrip } from "../../parse-functions/trips";
import UploadDisplayMissingBranchInfoModal from "./uploadDisplayMissingBranchInfoModal";
import DisplayBranchMismatchDataModal from "./DisplayBranchMismatchDataModal";

const UploadDisplayTempoSheet = (props) => {
    const {
        tripCreated,
        textTitle = "Drop File here",
        buttonText = "Select Display Sheet",
        isTemporaryPage = false,
        handleParcelInsertInTemporary
    } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const selectedTripId = useSelector((state) => state?.trips?.selectedTrip || null);
    const warehouse = useSelector((state) => state.user.warehouse || {});
    const [disableUpload, setDisableUpload] = useState(tripCreated);
    const currentActiveVehicle = useSelector((state) => state.vehicles.activeVehicleId);
    const [showMissingBranchInfoModal, setShowMissingBranchInfoModal] = useState(false);
    const [missingBranchNames, setMissingBranchNames] = useState([]);
    const [showBranchDataMismatchDataModal, setShowBranchDataMismatchDataModal] = useState(false);
    const [uploadFileBranchData, setUploadFileBranchData] = useState({});
    const [currentUploadedExcelData, setCurrentUploadedExcelData] = useState([]);

    const {
        files,
        fileNames,
        fileTypes,
        totalSize,
        totalSizeInBytes,
        handleDragDropEvent,
        clearAllFiles,
        createFormData,
        setFiles,
        removeFile,
    } = useFileUpload();
    const inputRef = useRef();

    useEffect(() => {
        if (tripCreated) {
            setDisableUpload(true);
        }
    }, [tripCreated]);

    useEffect(() => {
        try {
            if (files.length > 0) {
                validateAndUploadDisplaySheet(files[0])
            }
        } catch (e) {
            dispatch(updateToastInfo({
                show: true,
                type: "danger",
                title: "Display Upload Failed",
                message: "Display sheet upload failed. Contact Support"
            }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [files]);

    const validateAndUploadDisplaySheet = (file) => {
        // parse this file into meaningful data for transfer sheet
        ExcelRenderer(file, async (err, resp) => {
            if (err) {
                dispatch(updateToastInfo({
                    show: true,
                    type: "danger",
                    title: "Upload Failed",
                    message: "Display Upload failed"
                }));
            }
            else {
                const {
                    rows
                } = resp;
                // show spinner
                dispatch(updateSpinnerState(true));
                const allColumns = rows[0];
                const allRows = rows.slice(1, rows.length);
                const uploadedData = convertExcelDataToJSONRows(allColumns, allRows);
                // filter used data here for display sheet upload
                const excelData = uploadedData.filter((item) => item["Voucher No"]?.length > 0)
                // first check if all the invoices entered here are as per format. If not, We should reject.
                const allInvoicesValid = validateUploadedInvoiceNumbers(excelData);
                if (allInvoicesValid) {
                    setCurrentUploadedExcelData(excelData);
                    const branchData = await getBranchDataFromServer(excelData);
                    const branchDataObj = convertArrayToObj(branchData, 'warehouseName');
                    setUploadFileBranchData(branchDataObj);
                    validateAndUploadDisplaySheetData(excelData, branchDataObj);
                } else {
                    dispatch(updateSpinnerState(false));
                    dispatch(updateToastInfo({
                        show: true,
                        type: "danger",
                        title: "Invalid Invoice Number",
                        message: "Please check File"
                    }));
                }

            }
        });
    }
    const validateAndUploadDisplaySheetData = async (excelData, branchDataObj) => {
        // validate all data present in sheet matches the branch data available
        const data = excelData?.length > 0 ? excelData : currentUploadedExcelData;
        const missingBranches = missingBranchesData(data, branchDataObj);
        if (missingBranches?.length > 0) {
            setMissingBranchNames(missingBranches);
            // setShowMissingBranchInfoModal(true);
            dispatch(updateSpinnerState(false));
            setShowBranchDataMismatchDataModal(true);
            return;
        }
        const displaySheetData = generateDisplayDataFromExcel(data, branchDataObj);
        if (isTemporaryPage) {
            // add these orders to the existing temporary Trip
            const parcels = await addParcelToTemporaryTrip({ vehicle: { orders: displaySheetData }, tripNumber: selectedTripId, warehouse });
            handleParcelInsertInTemporary(parcels);
        } else {
            dispatch(addOrdersForVehicle(displaySheetData, currentActiveVehicle));
        }

        dispatch(updateSpinnerState(false));
        dispatch(updateToastInfo({
            show: true,
            type: "success",
            title: t("Display Upload"),
            message: t("Display Temposheet Uploaded successfully")
        }));
    }
    const onMissingBranchesDataSubmit = (data) => {
        const updatedBranchInfoData = {
            ...uploadFileBranchData,
            ...data
        };
        setUploadFileBranchData(updatedBranchInfoData);
        validateAndUploadDisplaySheetData(currentUploadedExcelData, updatedBranchInfoData);
        setShowBranchDataMismatchDataModal(false);
    }
    return (
        <>
            <div
                style={{
                    height: 80
                }}
                className="flex-row dropzone h-100 rounded d-flex align-items-center justify-content-center mb-4"
                onDragEnter={handleDragDropEvent}
                onDragOver={handleDragDropEvent}
                onDrop={(e) => {
                    if (disableUpload) {
                        dispatch(updateToastInfo({
                            show: true,
                            type: "danger",
                            title: t("Cant Upload to created Trip"),
                            message: t("This trip has already been generated. Can't add more items to this")
                        }));
                    } else {
                        handleDragDropEvent(e);
                        setFiles(e, 'a');
                    }

                }}
            >
                <div className="dz-default dz-message text-center">
                    <p className="dz-button mb-0">
                        {t(textTitle)}
                    </p>
                </div>&nbsp;&nbsp;
                <div className="dz-default dz-message text-center">
                    <p className="dz-button mb-0">
                        {t("or")}
                    </p>
                </div>&nbsp;&nbsp;

                <Button onClick={() => inputRef.current.click()} variant="light" className="m-1">&nbsp;{t(buttonText)}</Button>
                <input
                    ref={inputRef}
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    tabindex="-1"
                    type="file"
                    className="form-control"
                    onChange={(e) => {
                        if (disableUpload) {
                            dispatch(updateToastInfo({
                                show: true,
                                type: "danger",
                                title: t("Cant Upload to created Trip"),
                                message: t("This trip has already been generated. Can't add more items to this")
                            }));
                        } else {
                            setFiles(e, 'a');
                            inputRef.current.value = null;
                        }

                    }}
                    style={{ display: "none" }} />
            </div>
            <UploadDisplayMissingBranchInfoModal
                show={showMissingBranchInfoModal}
                handleClose={() => {
                    setShowMissingBranchInfoModal(false);
                }}
                buttonText={"Acknowledge"}
                mainHeaderText={"Details of the following branches are not found in System"}
                missingBranchNames={missingBranchNames}
            />
            <DisplayBranchMismatchDataModal
                showModal={showBranchDataMismatchDataModal}
                handleClose={() => {
                    setShowBranchDataMismatchDataModal(false);
                }}
                missingBranches={missingBranchNames}
                onMissingDataSubmit={onMissingBranchesDataSubmit}
                zone={warehouse?.zone}
            />
        </>
    )
}
export default UploadDisplayTempoSheet;