import { RESET_STORE, SET_STORES_IN_STORE } from '../../actions/types';

const INITIAL_STATE = {
  allStoreList: [],
  regionStoreList: [],
};

const stores = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_STORES_IN_STORE: {
      return {
        storeList: action.data,
      };
    }
    case RESET_STORE: {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
};

export default stores;
