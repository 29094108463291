import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CardDropdown from './components/CardDropDown';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import Pagination from 'react-bootstrap-table2-paginator';
import * as Paginator from 'react-bootstrap-table2-paginator';
import {
  Button,
  Card,
  Col,
  Form,
  Nav,
  Row,
  Tab,
} from '@themesberg/react-bootstrap';
import {
  customSizePerPage,
  customTotal,
} from '../../../components/common/parcelStatusPages';
import EmptyTable from '../../../components/common/EmptyTableView';
import Table from 'react-bootstrap-table-next';
import { inventoryColumnsStoreGuy, productInventoryColumns } from './utils';
import DropdownComponent from '../../../components/common/Dropdown';
import { returnWarehouseDropdownOptions } from '../../../utils/warehouse';
import { useTranslation } from 'react-i18next';
import { apiCall } from '../../../middlewares/api';
import { apiCallConsts } from '../../../constants/apiCallConsts';
import { updateSpinnerState } from '../../../actions/spinner';
import { updateToastInfo } from '../../../actions/settings';

const CompleteInventory = ({ searchText, setExportData }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isAdmin = useSelector(
    (state) => state?.user?.preferences?.isAdmin || false
  );
  const storeName = useSelector(
    (state) => state?.user?.preferences?.storeName || ''
  );
  const otherWarehouses = useSelector(
    (state) => state?.user?.otherWarehousesInZone || {}
  );
  const storeList = useSelector((state) => state?.stores?.storeList || []);
  const roles = useSelector((state) => state?.user?.preferences?.roles || []);
  const zone = useSelector((state) => state?.user?.preferences?.zone);

  const [selected, setSelected] = useState(storeName);
  const [products, setProducts] = useState([]);

  const handleSelectStore = (option) => {
    setSelected(option.value);
  };

  const getWarehouseShortCode = (warehouseName) => {
    let options = returnWarehouseDropdownOptions(storeList);
    const res = options?.filter((option) => option?.value === warehouseName);
    const shortCode = res.length > 0 ? res[0]?.data?.shortCode : '';
    return shortCode;
  };

  useEffect(() => {
    const fetchInventory = async () => {
      try {
        dispatch(updateSpinnerState(true));
        const storeCode = getWarehouseShortCode(selected);
        const products = await apiCall(
          apiCallConsts.GET_METHOD,
          apiCallConsts.INVENTORY_URL,
          { storeCode, allStoreData: true }
        );
        setProducts(products?.data);
        let exportData = products?.data.map((each) => {
          let { images, productTitle, productCategory, size, ...rest } = each;
          return rest;
        });
        setExportData(exportData);
        dispatch(updateSpinnerState(false));
        dispatch(
          updateToastInfo({
            show: true,
            type: 'success',
            title: t(''),
            message: t('inventory filtered successfully'),
          })
        );
      } catch (error) {
        dispatch(updateSpinnerState(false));
        dispatch({
          show: true,
          type: 'danger',
          title: t('failed to fetch inventory'),
          message: t(error.message),
        });
      }
    };
    if (selected?.length && storeList.length > 0) {
      fetchInventory();
    }
  }, [storeList, storeName, selected]);

  return (
    <>
      <ToolkitProvider
        exportCSV
        keyField="completeInventory"
        search={true}
        sort
        data={products || []}
        columns={inventoryColumnsStoreGuy()}
        wrapperClasses="table-responsive"
      >
        {({ baseProps, searchProps, csvProps }) => {
          searchProps.onSearch(searchText);
          return (
            <Paginator.PaginationProvider
              pagination={Pagination({
                custom: true,
                showTotal: true,
                alwaysShowAllBtns: true,
                totalSize: products?.length,
                paginationTotalRenderer: customTotal,
                sizePerPageRenderer: customSizePerPage,
              })}
            >
              {({ paginationProps, paginationTableProps }) => (
                <>
                  <Card>
                    <div className="table-responsive py-2">
                      <Card.Header className="d-flex justify-content-between align-items-center py-3 px-3">
                        <div style={{ width: '300px' }}>
                          <CardDropdown
                            placeholder={t(
                              roles.includes('inchargeOMS')
                                ? storeName
                                : 'store'
                            )}
                            options={returnWarehouseDropdownOptions(
                              storeList,
                              false,
                              zone
                            )}
                            disabled={roles.includes('inchargeOMS')}
                            width={'100%'}
                            onChange={handleSelectStore}
                          />
                        </div>
                        <div>totalStock</div>
                      </Card.Header>
                      <Table
                        noDataIndication={<EmptyTable />}
                        {...baseProps}
                        {...paginationTableProps}
                        bodyClasses="border-0"
                        classes="table-flush dataTable-table"
                      />

                      <Card.Footer>
                        <Row>
                          <Col xs={12} md={4} className="py-1">
                            <Paginator.SizePerPageDropdownStandalone
                              {...paginationProps}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            className="d-flex align-items-center py-1"
                          >
                            <Paginator.PaginationTotalStandalone
                              {...paginationProps}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            className="d-flex justify-content-md-end align-items-center mb-0 py-1"
                          >
                            <Paginator.PaginationListStandalone
                              {...paginationProps}
                            />
                          </Col>
                        </Row>
                      </Card.Footer>
                    </div>
                  </Card>
                </>
              )}
            </Paginator.PaginationProvider>
          );
        }}
      </ToolkitProvider>
    </>
  );
};

export default CompleteInventory;
