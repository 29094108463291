export const returnDeliveryDataItemOptions = () => {
    return [{
        label: "Reschedule",
        value: "reschedule"
    }]
};

export const returnDeliveryDataRescheduleOptions = () => {
    return [{
        label: "Buyback Pending",
        value: {
            status: "delivered",
            subStatus: "buybackpending"
        }
    }, {
        label: "Defective Pickup Pending",
        value: {
            status: "delivered",
            subStatus: "replacementpending"
        }
    }, {
        label: "Replacement Challan",
        value: {
            status: "delivered",
            subStatus: "defectivepending"
        } 
    }, {
        label: "Model Change Pickup Pending",
        value: {
            status: "delivered",
            subStatus: "modelChange"
        } 
    }, {
        label: "Return OK",
        value: {
            status: "failed",
            subStatus: "returnok"
        }
    }]
}