import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { returnWarehouseDropdownOptions } from '../../utils/warehouse';
import { addSecurityGuardSchema, editSecurityGuardSchema } from './utils';
import FormComponent from '../scheme-management/Components/FormComponent';
import { useTranslation } from 'react-i18next';
import { updateSpinnerState } from '../../actions/spinner';
import { addNewSecurityGuard } from '../../parse-functions/securityGuard';
import { updateToastInfo } from '../../actions/settings';

const AddSecurityGuard = ({ editData, onConfirm }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const otherWarehouses = useSelector(
    (state) => state?.user?.otherWarehousesInZone
  );

  const getWarehouseDropdownOptions = () => {
    return returnWarehouseDropdownOptions(otherWarehouses, false);
  };

  let schema = editData ? editSecurityGuardSchema : addSecurityGuardSchema;
  schema[0][3].options = getWarehouseDropdownOptions() || [];

  const handleSave = async (data, callback) => {
    try {
      dispatch(updateSpinnerState(true));
      if (editData) {
        onConfirm(data);
      } else {
        await addNewSecurityGuard(data);
      }
      dispatch(
        updateToastInfo({
          show: true,
          type: 'success',
          title: editData ? t('User Edited') : t('User Added'),
          message: editData
            ? t(`${data.name} updated successfully`)
            : t(`${data.name} added successfully`),
        })
      );
      dispatch(updateSpinnerState(false));
      callback(null);
    } catch (error) {
      const { message } = error;
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: editData ? t('User Edit failed') : t('User Add Failed'),
          message: editData
            ? t(`${data.name} failed to edit`)
            : t(message.username),
        })
      );
      callback(message);
    }
  };
  console.log(editData);
  return (
    <>
      {Object.keys(otherWarehouses).length > 0 && (
        <>
          <FormComponent
            schema={schema}
            editData={editData}
            savefunction={handleSave}
          />
        </>
      )}
    </>
  );
};

export default AddSecurityGuard;
