export const invoiceNumberValidation = (input) => {
    if (/(^(([0-9,A-Z]{3,4}|[A-Z]{2}|[7-8]{1}$)(-|\/)([A-Z,0-9]+)(-|\/)(([A-Z]{2,5} [0-9]{1,5}$)|([0-9]{2}-[0-9]{1,2}\/[0-9]{1,5}$)))|(([A-Z]{3})(-)([A-Z]+)(-)([A-Z]{2,5})([0-9]+$)))+/gm.test(input)) {
        return true;
    }
    return false;
}

export const vehicleNumberSchemaValidation = (value, helpers) => {
    const vehicleNumberRegex = new RegExp('(^[A-Z]{2}[0-9]{1,2}(?:[A-Z])?(?:[A-Z]*)?[0-9]{1,4}$)', 'igm');
    const delhiVehicleRegex = new RegExp('(^[A-Z]{2}[0-9]{1,3}(?:[A-Z])?(?:[A-Z]*)?[0-9]{1,4}$)', 'igm');

    const isValid = vehicleNumberRegex.test(value) && value.length >= 5 && value.length <= 10;
    const isValidDelhiNumber = delhiVehicleRegex.test(value) && value?.startsWith("DL");
    if (isValid || isValidDelhiNumber) {
        return value
    }else {
        throw new Error("Vehicle Number format is not correct")
    }
}
export const invoiceNumberValidationSchema = (value, helpers) => {

    if (/(^(([0-9,A-Z]{3,4}|[A-Z]{2}|[7-8]{1}$)(-|\/)([A-Z,0-9]+)(-|\/)(([A-Z]{2,5} [0-9]{1,5}$)|([0-9]{2}-[0-9]{1,2}\/[0-9]{1,5}$)))|(([A-Z]{3})(-)([A-Z]+)(-)([A-Z]{2,5})([0-9]+$)))+/gm.test(value)) {
        return value;
    }
    throw new Error('Invalid Invoice Number');
};

export const loaderCodeCustomValidation = (value) => {
    if (/^[A-Za-z0-9]*$/gm.test(value)) {
        return value;
    }
    throw new Error('Invalid Loader Code')
}


export const gstNumberValidationSchema = (value, helpers) => {
    if (value === "URP") {
        return true
    } else if (/((\d{2})(\w{13})$)|(URP$)/gm.test(value) && ((value.length === 15) || (value.length === 3))) {
        return true;
    } else {
        throw new Error('Invalid GST Number');
    }
}