import React from "react";
import OrderReturnPage from "./components/OrderReturnPage";
import { returnOrderStatus } from "../../../constants/orderStatus";

const Refunded = () => {
  return (
    <>
      <OrderReturnPage
        type={returnOrderStatus.REFUNDED}
        hideBulkActions={true}
      />
    </>
  );
};

export default Refunded;
