import React from "react";
import { Modal, Button } from "@themesberg/react-bootstrap";
import { useTranslation } from "react-i18next";
import AddScheme from "./Scheme/AddScheme";

const SchemeEditModal = (props) => {
  const { t } = useTranslation();
  const { show, handleClose, topTitle, editData, handleSave } = props;
  const newEditData = {
    ...editData,
    products: editData?.products?.map((item) => item.productName),
    category: editData?.productCategory?.includes("All")
      ? ["All"]
      : editData?.productCategory,
    subCategory: editData?.subCategory?.includes("All")
      ? ["All"]
      : editData?.subCategory,
  };
  return (
    <Modal
      centered
      show={show}
      onHide={handleClose}
      className="modal-primary"
      size="xl"
    >
      <Modal.Header className="d-flex justify-content-between align-items-center p-3">
        <p className="lead mb-0">{t(topTitle)}</p>
        <Button
          variant="transparent"
          className="btn-close"
          onClick={handleClose}
        />
      </Modal.Header>
      <Modal.Body>
        <AddScheme editData={newEditData} handleSave={handleSave} />
      </Modal.Body>
    </Modal>
  );
};
export default SchemeEditModal;
