import React, { useEffect, useState } from 'react';
import EntryListPage from './components/EntryListPage';
import arrayToBreadCrumbs from '../../utils/sms/arrayToBreadCrumbs';
import EntryModal from './components/EntryModal';
import { useDispatch, useSelector } from 'react-redux';
import {
  getEntryRegisteredVehicles,
  registerVehicleEntry,
  registerVehicleExit,
} from '../../parse-functions/vehicleEntries';
import { updateSpinnerState } from '../../actions/spinner';
import { updateToastInfo } from '../../actions/settings';
import { useTranslation } from 'react-i18next';
import { vehicleColums } from './utils';
import ConfirmationModal from '../../components/common/confirmationModal';

const EntryList = () => {
  const { t } = useTranslation();
  const zone = useSelector((state) => state?.user?.warehouse?.zone || '');
  const storeName = useSelector(
    (state) => state?.user?.warehouse?.warehouseName || ''
  );
  const guardObjectId = useSelector(
    (state) => state?.user?.user?.objectId || ''
  );
  const dispatch = useDispatch();
  const [entryModal, setEntryModal] = useState(false);
  const [vehiclesData, setVehiclesData] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState([]);
  const breadCrumbItems = arrayToBreadCrumbs([['Entry'], ['List']]);
  const [showModal, setShowModal] = useState(false);
  const bulkActionOptions = [
    {
      label: 'Mark Exit',
      value: 'markExit',
    },
  ];

  const handleShowEntryModal = () => {
    setEntryModal(true);
  };

  const fetchData = async () => {
    try {
      dispatch(updateSpinnerState(true));
      const res = await getEntryRegisteredVehicles({ storeName });
      setVehiclesData(res);
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'success',
          title: t(''),
          message: t('Vehicles fetched successfully'),
        })
      );
    } catch (error) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('failed to fetch Vehicles'),
          message: t(`${error.message}`),
        })
      );
    }
  };
  useEffect(() => {
    if (storeName) {
      fetchData();
    }
  }, [storeName]);

  const hanldeSubmit = async (data) => {
    setEntryModal(false);
    const finalData = { ...data, storeName, guardObjectId };
    try {
      dispatch(updateSpinnerState(true));
      const entry = await registerVehicleEntry(finalData);
      await fetchData();
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'success',
          title: t('vehicle Entry register successfully'),
          message: t(`${entry.objectId} registered`),
        })
      );
    } catch (error) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('vehicle Entry register failed'),
          message: t(`${error.message}`),
        })
      );
    }
  };

  const onRowSelect = (selected, objectId) => {
    if (selected) {
      setSelectedVehicle((prev) => {
        const selectedItem = vehiclesData.find(
          (item) => item.objectId === objectId
        );
        return [...prev, selectedItem];
      });
    } else {
      setSelectedVehicle((prev) =>
        prev.filter((item) => item.objectId !== objectId)
      );
    }
    setVehiclesData((prev) =>
      prev.map((item) =>
        item.objectId === objectId ? { ...item, isSelected: selected } : item
      )
    );
  };

  const handleSelectedRowsUpdate = (actionId) => {
    if (selectedVehicle.length > 1) {
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t(''),
          message: t('Only One Vehicle Allowed To Update'),
        })
      );
      return;
    }
    switch (actionId) {
      case 'markExit': {
        handleShowModal();
        break;
      }
      default:
        break;
    }
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleModalOnConfirmClick = async () => {
    try {
      dispatch(updateSpinnerState(true));
      const entry = await registerVehicleExit({
        entryId: selectedVehicle[0].objectId,
      });
      await fetchData();
      setShowModal(false);
      setSelectedVehicle([]);
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'success',
          title: t('vehicle Exit successfully'),
          message: t(`${entry.objectId} registered`),
        })
      );
    } catch (error) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('vehicle exit failed'),
          message: t(`${error.message}`),
        })
      );
    }
  };

  return (
    <>
      <EntryListPage
        title={'Entry List'}
        data={vehiclesData}
        columns={vehicleColums(onRowSelect)}
        breadCrumbItems={breadCrumbItems}
        handleEntryModal={handleShowEntryModal}
        enableBulkActions={selectedVehicle.length > 0}
        hideBulkActions={!bulkActionOptions}
        bulkActionOptions={bulkActionOptions}
        handleItemBulkUpdate={handleSelectedRowsUpdate}
      />

      <EntryModal
        showModal={entryModal}
        closeModal={() => setEntryModal(false)}
        handleSubmit={hanldeSubmit}
        zone={zone}
      />

      <ConfirmationModal
        showModal={showModal}
        onConfirm={handleModalOnConfirmClick}
        closeModal={() => {
          setShowModal(false);
        }}
        modalBody={t(
          `Do You Want To Mark This ${selectedVehicle[0]?.vehicleNumber} Vehicle as Exited!`
        )}
        modalTitle={t(`Exit Confirmation`)}
      />
    </>
  );
};

export default EntryList;
