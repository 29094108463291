import React, { useState } from "react";
import Pagination from "react-bootstrap-table2-paginator";
import * as Paginator from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BreadCrumbComponent from "../../components/common/Breadcrumb";
import { monthlyVehicleDeliveryReportColumns } from "../../utils/vehicleAttendance";
import { Button, ButtonGroup, Card, Col, Row } from "@themesberg/react-bootstrap";
import Table from "react-bootstrap-table-next";
import { customSizePerPage, customTotal } from "../../components/common/parcelStatusPages";
import { useTranslation } from "react-i18next";
import { Routes } from "../../router/routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import ExcelDownloadReport from "../../components/common/excel/download";
import EmptyTable from "../../components/common/EmptyTableView";
import { useDispatch, useSelector } from "react-redux";
import { updateSpinnerState } from "../../actions/spinner";
import { getMonthlyDeliveryReportFromServer } from "../../parse-functions/trips";
import { updateToastInfo } from "../../actions/settings";
import DateRangeSelector from "../../components/common/DateRangeSelector";

const MonthlyDelivery = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const storeInfo = useSelector((state) => state.user.warehouse || {});
    const [dataToDownload, setDataToDownload] = useState([]);
    const isSuperAdmin = useSelector((state) => state.user.preferences.isSuperAdmin || false);
    const [data, setCurrentData] = useState([]);

    const breadCrumbItems = [{
        title: t("Reports"),
        link: Routes.Reports.DailyDelivery.path
    }, {
        title: t("Vehicle Attendance"),
        link: ""
    }];
    const handleGetAvailableAnalyticsData = async (requestProps) => {
        dispatch(updateSpinnerState(true));
        try {
            const dataList = await getMonthlyDeliveryReportFromServer(requestProps);
            setDataToDownload(dataList);
            setCurrentData(dataList);
            // use the data here and generate the table data and the 
            dispatch(updateToastInfo({
                show: true,
                type: 'success',
                title: t('All Analytics data fetched'),
                message: t('These are all the closed trips currently from your warehouse')
            }));
            dispatch(updateSpinnerState(false));
        } catch (e) {
            dispatch(updateSpinnerState(false));
            dispatch(updateToastInfo({
                show: true,
                type: 'danger',
                title: t("Error in getting active analytics data"),
                message: e.message
            }));
        }
    }
    const handleFilterSubmit = (startDate, endDate) => {
        handleGetAvailableAnalyticsData({
            startDate: new Date(startDate),
            endDate: new Date(endDate),
            warehouseCode: storeInfo?.warehouseCode,
            isAnalytics: true,
            isSuperAdmin
        })
    };
    return (
        <ToolkitProvider
            exportCSV
            keyField="id"
            search={true}
            sort
            columns={monthlyVehicleDeliveryReportColumns()}
            data={data}
            wrapperClasses="table-responsive"
        >
            {({ baseProps, searchProps, csvProps }) => (
                <Paginator.PaginationProvider pagination={
                    Pagination({
                        custom: true,
                        showTotal: true,
                        alwaysShowAllBtns: true,
                        totalSize: data?.length,
                        paginationTotalRenderer: customTotal,
                        sizePerPageRenderer: customSizePerPage
                    })
                }>
                    {({ paginationProps, paginationTableProps }) => (
                        <>
                            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                                <div className="d-block mb-4 mb-md-0">
                                    <BreadCrumbComponent
                                        items={breadCrumbItems}
                                    />
                                    <h4>{t("Monthly Delivery Report")}</h4>
                                    <p className="mb-0">{t("Vehicle Delivery Report for all the vehicles for the selected month")}</p>
                                </div>
                                <div className="btn-toolbar mb-2 mb-md-0">
                                    <div>
                                        <div style={{
                                            display: "flex",
                                            justifyContent: "flex-end"
                                        }}>
                                            <ButtonGroup>
                                                <Button
                                                    onClick={() => {
                                                        // handleRefreshData();
                                                    }}
                                                    variant="outline-primary"
                                                    size="sm">
                                                    <FontAwesomeIcon icon={faRefresh} />&nbsp;&nbsp;{t("Refresh")}
                                                </Button>
                                                <ExcelDownloadReport
                                                    data={dataToDownload || []}
                                                    fileName={"vehicle-delivery-monthly"}
                                                />
                                            </ButtonGroup>
                                        </div>
                                        <div>
                                            <div style={{
                                                fontSize: 11,
                                                paddingTop: 10,
                                                fontStyle: "italic"
                                            }}>This is a real time data and will update every hour</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Card>
                                <div className="table-responsive py-2">
                                    <Card.Header style={{
                                        padding: "8px 12px"
                                    }}>
                                        <Row className="d-flex justify-content-between">
                                            <Col md={6} className="d-flex justify-content-start">
                                                <DateRangeSelector onSubmit={handleFilterSubmit}  />
                                            </Col>
                                            <Col xs={12} md={4} className="d-flex justify-content-end">
                                                <Search.SearchBar {...searchProps} placeholder={t("Search any value in table here")} />
                                                <Search.ClearSearchButton { ...searchProps } />
                                            </Col>
                                        </Row>
                                    </Card.Header>
                                    <Table
                                        noDataIndication={<EmptyTable />}
                                        {...baseProps}
                                        {...paginationTableProps}
                                        bodyClasses="border-0"
                                        classes="table-flush dataTable-table"
                                    />

                                    <Card.Footer>
                                        <Row>
                                            <Col xs={12} md={4} className="py-1">
                                                <Paginator.SizePerPageDropdownStandalone {...paginationProps} />
                                            </Col>
                                            <Col xs={12} md={4} className="d-flex align-items-center py-1">
                                                <Paginator.PaginationTotalStandalone {...paginationProps} />
                                            </Col>
                                            <Col xs={12} md={4} className="d-flex justify-content-md-end align-items-center mb-0 py-1">
                                                <Paginator.PaginationListStandalone {...paginationProps} />
                                            </Col>
                                        </Row>
                                    </Card.Footer>
                                </div>
                            </Card>
                        </>
                    )}
                </Paginator.PaginationProvider>
            )}
        </ToolkitProvider>
    )
}
export default MonthlyDelivery