import {
  faHighlighter,
  faInfinity,
  faInfoCircle,
  faPen,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import ActionButtonWithIcons from '../../../components/common/ActionButtonWithIcons';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import StatusCard from '../interBranchTransfers/StatusCard';
import { getTableRowCheckBox } from '../../../utils/table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OverlayTrigger, Tooltip } from '@themesberg/react-bootstrap';
import { StyledSearchBar } from '../../trips/styles';

export const productsOptions = {
  view: 'View',
  markEndlessAisle: 'Mark as Endless Aisle',
  updateInventory: 'Update Inventory',
  updateSafetyStock: 'Update Saftey Stock',
};

export const productListColumns = (onActionClick, onRowSelect) => {
  const columns = [
    {
      dataField: 'objectId',
      text: 'select',
      headerClasses: 'rounded-0 p-3',
      style: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        height: 'auto',
      },
      formatter: (cell, row) => {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {row?.isEndlessAisle ? (
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 300, hide: 300 }}
                overlay={
                  <Tooltip id={`tooltip-${row.objectId}`}>
                    <FontAwesomeIcon size="xs" icon={faInfinity} /> Marked as
                    Endless Aisle
                  </Tooltip>
                }
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'rgba(240, 240, 240, 1)',
                    width: '2.25rem',
                    height: '2.25rem',
                    borderRadius: '50%',
                    border: '1px solid rgba(200, 200, 200, 0.8)',
                  }}
                >
                  <FontAwesomeIcon
                    size="sm"
                    icon={faInfinity}
                    style={{
                      color: 'rgba(100, 100, 100, 1)',
                    }}
                  />
                </div>
              </OverlayTrigger>
            ) : (
              getTableRowCheckBox(onRowSelect, cell, row?.isSelected, false)
            )}
          </div>
        );
      },
    },
    {
      dataField: 'vanNo',
      text: 'vanNo',
      headerClasses: 'rounded-0 p-3',
      formatter: (cell, row) => {
        return <div className="d-block">{cell}</div>;
      },
    },
    {
      dataField: 'productTitle',
      text: 'name',
      headerClasses: 'rounded-0 p-3',
      formatter: (cell, row) => {
        return (
          <div className="d-block text-wrap" style={{ width: '25rem' }}>
            {cell}
          </div>
        );
      },
    },
    {
      dataField: 'brand',
      text: 'company',
      headerClasses: 'rounded-0 p-3',
      formatter: (cell, row) => {
        return <div className="d-block">{cell}</div>;
      },
    },
    {
      dataField: 'l3',
      text: 'category',
      headerClasses: 'rounded-0 p-3',
      formatter: (cell, row) => {
        return <div className="d-block">{cell}</div>;
      },
    },
    {
      dataField: 'l4',
      text: 'subcategory',
      headerClasses: 'rounded-0 p-3',
      formatter: (cell, row) => {
        return <div className="d-block">{cell}</div>;
      },
    },
    {
      dataField: 'size',
      text: 'size',
      headerClasses: 'rounded-0 p-3',
      formatter: (cell, row) => {
        return <div className="d-block">{cell}</div>;
      },
    },
    {
      dataField: 'safetyStockLimit',
      text: 'safetyStockLimit',
      headerClasses: 'rounded-0 p-3',
      formatter: (cell, row) => {
        return <div className="d-block text-center">{cell || 0}</div>;
      },
    },
    {
      dataField: 'Action',
      text: 'action',
      headerClasses: 'rounded-0 p-3',
      // style: { textAlign: 'center' },
      formatter: (cell, row) => {
        return (
          <>
            <ActionButtonWithIcons
              options={
                row?.isEndlessAisle
                  ? [
                      { icon: faEye, label: productsOptions.view },
                      { icon: faPen, label: productsOptions.updateSafetyStock },
                      { icon: faPlus, label: productsOptions.updateInventory },
                    ]
                  : [
                      { icon: faEye, label: productsOptions.view },
                      { icon: faPen, label: productsOptions.updateSafetyStock },
                      {
                        icon: faHighlighter,
                        label: productsOptions.markEndlessAisle,
                      },
                    ]
              }
              onActionClick={(option) => onActionClick(option, row?.vanNo)}
            />
          </>
        );
      },
    },
  ];
  return columns;
};

export const InventoryColumns = () => {
  const columns = [
    {
      dataField: 'storeCode',
      text: 'storeCode',
      headerClasses: 'mx-0',
      style: { width: '200px' },
      //   classes: 'p-5',
      formatter: (cell, row) => {
        return <div className="d-block">{cell}</div>;
      },
    },
    {
      dataField: 'safetyStock',
      text: 'safety',
      headerClasses: 'text-center',
      formatter: (cell, row) => {
        return <div className="d-block  text-center">{cell || 0}</div>;
      },
    },
    {
      dataField: 'inProcess',
      text: 'inProcess',
      headerClasses: 'text-center',
      formatter: (cell, row) => {
        return <div className="d-block  text-center">{cell || 0}</div>;
      },
    },
    {
      dataField: 'inBlocked',
      text: 'inBlocked',
      headerClasses: 'text-center',
      formatter: (cell, row) => {
        return <div className="d-block text-center">{cell || 0}</div>;
      },
    },
    {
      dataField: 'onHold',
      text: 'onHold',
      headerClasses: 'text-center',
      formatter: (cell, row) => {
        return <div className="d-block text-center">{cell || 0}</div>;
      },
    },
    {
      dataField: 'available',
      text: 'available',
      headerClasses: 'text-center',
      formatter: (cell, row) => {
        return <div className="d-block text-center">{cell}</div>;
      },
    },
    {
      dataField: 'totalStock',
      text: 'total',
      headerClasses: 'text-center',
      formatter: (cell, row) => {
        return <div className="d-block text-center">{row?.totalStock}</div>;
      },
    },
  ];
  return columns;
};

export const updateInventoryColumns = (setValues) => {
  const columns = [
    {
      dataField: 'cityName',
      text: 'city Name',
      headerClasses: 'mx-0',
      style: { width: '200px' },
      //   classes: 'p-5',
      formatter: (cell, row) => {
        return <div className="d-block">{cell}</div>;
      },
    },
    {
      dataField: 'available',
      text: 'available Inventory',
      headerClasses: 'text-center',
      headerFormatter: () => {
        return (
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 300, hide: 300 }}
            overlay={
              <Tooltip id={`tooltip-availableInventory`}>
                NOTE: Available inventory excludes products/orders currently in
                the process of delivery.
              </Tooltip>
            }
          >
            <div>
              <span>Available Inventory</span>{' '}
              <FontAwesomeIcon
                size="sm"
                icon={faInfoCircle}
                style={{
                  color: 'rgba(68, 68, 68, 1)',
                }}
              />
            </div>
          </OverlayTrigger>
        );
      },
      formatter: (cell, row) => {
        return <div className="d-block  text-center">{cell || 0}</div>;
      },
    },
    {
      dataField: 'safetyStock',
      text: 'Available Safety Stock',
      headerClasses: 'text-center',
      formatter: (cell, row) => {
        return <div className="d-block  text-center">{cell || 0}</div>;
      },
    },
    {
      dataField: 'Uavailable',
      text: 'Update Inventory',
      headerClasses: 'text-center',
      formatter: (cell, row) => {
        return (
          <StyledSearchBar
            width={'100%'}
            type="number"
            height={'1.7rem'}
            placeholder={row?.Uavailable}
            onChange={(e) => {
              const { value } = e?.target;
              setValues((prev) => ({
                ...prev,
                [row?.objectId]: {
                  ...prev[row?.objectId],
                  Uavailable: value,
                },
              }));
            }}
          />
        );
      },
    },
    {
      dataField: 'UsafetyStock',
      text: 'Update Safety Stock',
      headerClasses: 'text-center',
      formatter: (cell, row) => {
        return (
          <StyledSearchBar
            width={'100%'}
            type="number"
            height={'1.7rem'}
            placeholder={row?.UsafetyStock}
            onChange={(e) => {
              const { value } = e?.target;
              setValues((prev) => ({
                ...prev,
                [row?.objectId]: {
                  ...prev[row?.objectId],
                  UsafetyStock: value,
                },
              }));
            }}
          />
        );
      },
    },
  ];
  return columns;
};

export const citiesToRemove = new Set([
  // 'THANE',
  'NAVI MUMBAI',
  // 'PALGHAR',
  'DELHI',
  // 'INDIRAPURAM',
]);

export const summaryModalColumns = () => {
  const columns = [
    {
      dataField: 'cityName',
      text: 'City Name',
    },
    {
      dataField: 'available',
      text: 'Previous Inventory',
      formatter: (cell, row) => {
        return <div className="d-block">{cell || 0}</div>;
      },
    },
    {
      dataField: 'Uavailable',
      text: 'Updated Inventory',
    },
    {
      dataField: 'safetyStock',
      text: 'Previous Safety Stock',
      formatter: (cell, row) => {
        return <div className="d-block">{cell || 0}</div>;
      },
    },
    {
      dataField: 'UsafetyStock',
      text: 'Updated Safety stock',
    },
  ];
  return columns;
};
