export default {
    translations: {
        "Save Temporary Trip": "తాత్కాలిక పర్యటనను సేవ్ చేయండి",
        "View Packing": "ప్యాకింగ్ చూడండి",
        "Orders Count": "ఆర్డర్లు కౌంట్",
        "Locations": "స్థానాలు",
        "Temporary Trip Created Successfully": "తాత్కాలిక పర్యటన విజయవంతంగా సృష్టించబడింది",
        "Please visit the trip page to take more action on this trip": "దయచేసి ఈ పర్యటనపై మరింత చర్య తీసుకోవడానికి యాత్ర పేజీని సందర్శించండి",
        "Temporary Trip Creation failed": "తాత్కాలిక పర్యటన సృష్టి విఫలమైంది",
        "Please try again or contact support": "దయచేసి మళ్లీ ప్రయత్నించండి లేదా మద్దతును సంప్రదించండి",
        "Add New Warehouse": "కొత్త గిడ్డంగిని జోడించండి",
        "Use this page to add a new warehouse": "కొత్త గిడ్డంగిని జోడించడానికి ఈ పేజీని ఉపయోగించండి",
        "Warehouse Name": "గిడ్డంగి పేరు",
        "Warehouse Code": "గిడ్డంగి కోడ్",
        "Warehouse Short Code": "వేర్‌హౌస్ షార్ట్ కోడ్",
        "Address": "చిరునామా",
        "Pincode": "పిన్ కోడ్",
        "zone": "మండలము",
        "UserName": "వినియోగదారు పేరు",
        "Password": "పాస్వర్డ్",
        "Repeat Password": "పాస్వర్డ్ మళ్ళి చెప్పండి",
        "Create User": "వినియోగదారుని సృష్టించండి",
        "Cancel": "రద్దు చేయండి",
        "Add Users": "వినియోగదారులను జోడించండి",
        "Add Warehouse": "గిడ్డంగిని జోడించండి",
        "Add Incharge": "వేర్‌హౌస్‌ని జోడించు ఇంఛార్జ్‌ని జోడించండి",
        "Add New Incharge": "కొత్త ఇంఛార్జ్‌ని జోడించండి",
        "Use this page to add a new incharge": "కొత్త ఇంఛార్జ్‌ని జోడించడానికి ఈ పేజీని ఉపయోగించండి",
        "Incharge Name": "ఇంచార్జి పేరు",
        "Phone Number": "ప్రభారీ కా పేరు",
        "Email Id": "ఇమెయిల్ ఐడి",
        "Select Application": "అప్లికేషన్ ఎంచుకోండి",
        "Warehouse / Store Name": "గిడ్డంగి / దుకాణం పేరు",
        "Incharge Info": "ఇంఛార్జ్ సమాచారం",
        "Login Info": "లాగిన్ సమాచారం",
        "Item Removed": "",
        "Parcel Removed from trip successfully": "పర్యటన నుండి పార్శిల్ విజయవంతంగా తీసివేయబడింది",
        "Removal Failed": "తొలగింపు విఫలమైంది",
        "Parcel removal failed, Please try later": "స్కాన్ చేయండి, EWBని ప్రింట్ చేయండి & మీ వాహనాన్ని ఇక్కడి నుండి పంపించండి",
        "Scan, Print EWB & Dispatch your vehicle from here": "స్కాన్ చేయండి, EWBని ప్రింట్ చేయండి & మీ వాహనాన్ని ఇక్కడి నుండి పంపించండి",
        "Enter Serial No to Scan": "స్కాన్ చేయడానికి క్రమ సంఖ్యను నమోదు చేయండి",
        "શું તમે ખરેખર દૂર કરવા માંગો છો": "మీరు ఖచ్చితంగా తీసివేయాలనుకుంటున్నారా",
        "Remove Orders": "ఆర్డర్‌లను తీసివేయండి",
        "Confirm": "నిర్ధారించండి",
        "Drop File here": "ఫైల్‌ను ఇక్కడ వదలండి",
        "Select Display Sheet": "డిస్ప్లే షీట్ ఎంచుకోండి",
        "Please enter valid InvoiceId [X-Q-SI X] [RC/X/X-X/X] [PC/X/X-X/X] [X-S-SI X]": "దయచేసి చెల్లుబాటు అయ్యే ఇన్‌వాయిస్ ఐడి [X-Q-SI X] [RC/X/X-X/X] [PC/X/X-X/X] [X-S-SI X]",
        "Vehicle Number is mandatory": "వాహనం నంబర్ తప్పనిసరి",
        "Vehicle Area is mandatory": "వాహన ప్రాంతం తప్పనిసరి",
        "Please Select an option": "దయచేసి ఒక ఎంపికను ఎంచుకోండి",
        "Please Enter Valid Vehicle Number": "దయచేసి చెల్లుబాటు అయ్యే వాహన సంఖ్యను నమోదు చేయండి",
        "Please select an option": "దయచేసి ఒక ఎంపికను ఎంచుకోండి",
        "Create Temporary trip": "తాత్కాలిక యాత్రను సృష్టించండి",
        "Vehicle Number": "వాహనం సంఖ్య",
        "Vehicle Area": "వాహన ప్రాంతం",
        "Save temporary": "తాత్కాలికంగా సేవ్ చేయండి",
        "Height": "ఎత్తు",
        "Length": "పొడవు",
        "Breadth": "వెడల్పు",
        "Incharge Added": "ఇంఛార్జ్ చేర్చబడింది",
        "incharge added successfully": "ఇంఛార్జ్ విజయవంతంగా జోడించబడింది",
        "incharge failed to add": "ఇంఛార్జ్ జోడించడంలో విఫలమైంది",
        "Incharge Add Failed": "ఇంఛార్జ్ యాడ్ విఫలమైంది",
        "Please enter Incharge name": "దయచేసి ఇంఛార్జ్ పేరును నమోదు చేయండి",
        "Delivery Management": "డెలివరీ నిర్వహణ",
        "Warehouse Management": "గోడౌన్ నిర్వహణ",
        "Finance": "ఫైనాన్స్",
        "Omni Channel": "ఓమ్ని ఛానల్",
        "Product Management": "ఉత్పత్తి నిర్వహణ",
        "Select Applications to give access": "యాక్సెస్ ఇవ్వడానికి అప్లికేషన్‌లను ఎంచుకోండి",
        "All your temporary trips are listed here.": "మీ అన్ని తాత్కాలిక పర్యటనలు ఇక్కడ జాబితా చేయబడ్డాయి.",
        "Previous": "మునుపటి",
        "Next": "తరువాత",
        "Dashboard": "డాష్బోర్డ్",
        "Overview": "అవలోకనం",
        "Dispatch": "పంపండి",
        "Temporary Trips": "తాత్కాలిక పర్యటనలు",
        "Trips List": "పర్యటనల జాబితా",
        "Requisitions": "అభ్యర్థనలు",
        "Trips": "ప్రయాణాలు",
        "Parcels": "పొట్లాలు",
        "Vehicles": "వాహనాలు",
        "Live": "ప్రత్యక్షం",
        "Plan Vehicles": "వాహనాలను ప్లాన్ చేయండి",
        "Prepare temporary trip sheet here": "ఇక్కడ తాత్కాలిక ట్రిప్ షీట్‌ను సిద్ధం చేయండి",
        "Enter / Scan Invoice Number": "ఇన్వాయిస్ నంబర్ / స్కాన్ నమోదు చేయండి",
        "Warehouse Info": "గిడ్డంగి సమాచారం",
        "Please select a zone": "దయచేసి ఒక జోన్‌ను ఎంచుకోండి",
        "Active Trips": "క్రియాశీల పర్యటనలు",
        "All the trips of your warehouse are listed here.": "మీ గిడ్డంగి యొక్క అన్ని పర్యటనలు ఇక్కడ జాబితా చేయబడ్డాయి.",
        "New Trip": "కొత్త ప్రయాణం",
        "Share": "షేర్ చేయండి",
        "Export": "ఎగుమతి చేయండి",
        "Trip Number": "యాత్ర సంఖ్య",
        "VEHICLE NUMBER": "వాహనం నంబర్",
        "VEHICLE AREA": "వాహన ప్రాంతం",
        "ने निर्मित": "సృష్టికర్త",
        "DATE": "తేదీ",
        "STATUS": "స్థితి",
        "Pending": "పెండింగ్‌లో ఉంది",
        "Scan, Print EWB & Dispatch your vehicle from here.": "స్కాన్ చేయండి, EWBని ప్రింట్ చేయండి & మీ వాహనాన్ని ఇక్కడి నుండి పంపించండి.",
        "Print Sheet": "ప్రింట్ షీట్",
        "Print EWB": "EWBని ముద్రించండి",
        "CUSTOMER": "కస్టమర్",
        "INVOICE NO": "ఇన్వాయిస్ నం",
        "ADDRESS": "చిరునామా",
        "SERIAL NO": "క్రమసంఖ్య",
        "PRODUCT": "ఉత్పత్తి",
        "ACTION": "చర్య",
        "Remove": "తొలగించు",
        "Loader Name 1": "లోడర్ పేరు 1",
        "Loader Number 1": "లోడర్ సంఖ్య 1",
        "Loader Name 2": "లోడర్ పేరు 2",
        "Loader Number 2": "లోడర్ సంఖ్య 2",
        "Loader Name 3": "లోడర్ పేరు 3",
        "Loader Number 3": "లోడర్ సంఖ్య 3",
        "Loader Name 4": "లోడర్ పేరు 4",
        "Loader Number 4": "లోడర్ సంఖ్య 4",
        "Loader Name 5": "లోడర్ పేరు 5",
        "Loader Number 5": "లోడర్ సంఖ్య 5",
    }
}