import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { returnProductInfo } from '../../../utils/sms/tableUtils';
import { getTableRowCheckBox } from '../../../utils/table';
import CountdownTimer from './components/CountdownTimer';
import {
  Badge,
  Form,
  OverlayTrigger,
  Tooltip,
} from '@themesberg/react-bootstrap';
import { getTripBadgeStatus } from '../../../utils/trips';
import { returnUserReadableDateTime } from '../../../utils/datetime';
import { orderStatus } from '../../../constants/orderStatus';
import EditableDropDown from '../../../components/common/EditableDropdown';
import { generateRandomId } from '../../../utils/string';
import { arrayToDropdownOptions } from '../../../utils/sms/arrayToDropdownOptions';
import { ErrorMessage } from '../../scheme-management/Components/StyledComponents';

export const orderTableColumns = (
  onRowSelect,
  navigate,
  bulkActionOptions,
  isAdmin,
  isCustomerPickup = false
) => {
  const columns = [
    {
      dataField: 'productTitle',
      text: 'Product',
      formatter: (cell, row) => {
        return returnProductInfo([cell]);
      },
    },
    {
      dataField: 'orderNo',
      text: 'order No',
      sort: true,
    },
    {
      dataField: 'customerName',
      text: 'Customer Name',
      sort: true,
    },
    {
      dataField: 'vanNo',
      text: 'van No',
    },
    {
      dataField: 'quantity',
      text: 'Quantity',
    },
    {
      dataField: 'paymentGateway',
      text: 'payment Details',
      formatter: (cell, row) => {
        return (
          <div>
            <div className="d-block">{row?.paymentGateway || ''}</div>
            <div className="d-block">{row?.paymentType || ''}</div>
          </div>
        );
      },
    },
    {
      dataField: 'updatedAt',
      text: 'last update',
      sort: true,
      formatter: (cell, row) => {
        return returnUserReadableDateTime(cell);
      },
    },
    {
      dataField: 'view',
      text: 'View',
      formatter: (cell, row) => {
        return (
          <FontAwesomeIcon
            icon={faArrowRight}
            style={{ cursor: 'pointer' }}
            onClick={() => navigate(`/oms/orders/${row.objectId}`)}
          />
        );
      },
    },
  ];
  if (bulkActionOptions) {
    columns.splice(0, 0, {
      dataField: 'objectId',
      text: 'Select',
      formatter: (cell, row) => {
        const isInvoicedLarge =
          bulkActionOptions[0].value === 'invoiced' && row.isLarge;

        return isInvoicedLarge ? (
          <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={
              <Tooltip id={`tooltip-${row.objectId}`}>
                Large Products are only allowed to update in DMS
              </Tooltip>
            }
          >
            <div>
              {getTableRowCheckBox(onRowSelect, cell, row?.isSelected, true)}
            </div>
          </OverlayTrigger>
        ) : (
          <div>
            {getTableRowCheckBox(
              onRowSelect,
              cell,
              row?.isSelected,
              false // Enable checkbox when not invoiced or not large
            )}
          </div>
        );
      },
    });
  }

  if (isAdmin) {
    columns.splice(2, 0, {
      dataField: 'storeCode',
      text: 'Store Name',
    });
  }

  if (isCustomerPickup) {
    columns.splice(5, 0, {
      dataField: 'expectedDeliveryDate',
      text: 'Expected PickUp',
      formatter: (cell, row) => {
        let date = new Date(new Date(cell).getTime() - 5.5 * 60 * 60 * 1000);
        return returnUserReadableDateTime(date);
      },
    });
  }
  return columns;
};

export const orderCancelColumns = (handleChange, data) => {
  const columns = [
    {
      dataField: 'productTitle',
      text: 'Product',
      formatter: (cell, row) => {
        return returnProductInfo([cell]);
      },
    },
    {
      dataField: 'orderNo',
      text: 'order No',
      sort: true,
    },
    {
      dataField: 'customerName',
      text: 'Customer Name',
      sort: true,
    },
    {
      dataField: 'vanNo',
      text: 'van No',
    },
    {
      dataField: 'status',
      text: 'Status',
      formatter: (cell, row) => {
        const currentItem = data.find((item) => item.objectId === row.objectId);
        const options = arrayToDropdownOptions([
          orderStatus.cancelled,
          currentItem?.status,
        ]);

        return data.length > 1 ? (
          <Form.Group>
            <EditableDropDown
              id={generateRandomId()}
              onChange={(e) => handleChange(row, e.value)}
              value={cell}
              options={options}
              required={true}
              placeholder={'Select a status'}
            />
            {row.error && <ErrorMessage>{row.error}</ErrorMessage>}
          </Form.Group>
        ) : (
          <>{orderStatus.cancelled}</>
        );
      },
    },
  ];
  return columns;
};

export const orderAssignedColumns = (
  onRowSelect,
  navigate,
  handleTimeOut,
  bulkActionOptions,
  isAdmin
) => {
  const columns = [
    {
      dataField: 'productTitle',
      text: 'Product',
      formatter: (cell, row) => {
        return returnProductInfo([cell]);
      },
    },
    {
      dataField: 'orderNo',
      text: 'order No',
      sort: true,
    },
    {
      dataField: 'customerName',
      text: 'Customer Name',
      sort: true,
    },
    {
      dataField: 'vanNo',
      text: 'van No',
    },
    {
      dataField: 'quantity',
      text: 'Quantity',
    },
    {
      dataField: 'paymentGateway',
      text: 'payment Details',
      formatter: (cell, row) => {
        return (
          <div>
            <div className="d-block">{row?.paymentGateway || ''}</div>
            <div className="d-block">{row?.paymentType || ''}</div>
          </div>
        );
      },
    },
    {
      dataField: 'expiresAt',
      text: 'TimeRemaining',
      searchable: false,
      formatter: (cell, row) => {
        let time = remainingTime(cell?.iso);
        return (
          <span className=" fs-6 text-danger">
            {row?.isLarge || row?.isMedium || row?.isEndlessAisle ? (
              '-'
            ) : (
              <CountdownTimer
                hours={time?.hours < 0 ? 0 : time.hours}
                mins={time?.mins < 0 ? 0 : time.mins}
                callback={() => handleTimeOut(row?.objectId)}
              />
            )}
          </span>
        );
      },
    },
    {
      dataField: 'view',
      text: 'View',
      searchable: false,
      formatter: (cell, row) => {
        return (
          <FontAwesomeIcon
            icon={faArrowRight}
            style={{ cursor: 'pointer' }}
            onClick={() => navigate(`/oms/orders/${row.objectId}`)}
          />
        );
      },
    },
  ];
  if (bulkActionOptions) {
    columns.splice(0, 0, {
      dataField: 'objectId',
      text: 'Select',
      formatter: (cell, row) => {
        return getTableRowCheckBox(onRowSelect, cell, row?.isSelected, false);
      },
    });
  }
  if (isAdmin) {
    columns.splice(2, 0, {
      dataField: 'storeCode',
      text: 'Store Name',
    });
  }
  return columns;
};

export const orderAcceptedColumns = (
  onRowSelect,
  navigate,
  handleTimeOut,
  bulkActionOptions,
  isAdmin
) => {
  const columns = [
    {
      dataField: 'productTitle',
      text: 'Product',
      formatter: (cell, row) => {
        return returnProductInfo([cell]);
      },
    },
    {
      dataField: 'orderNo',
      text: 'order No',
      sort: true,
    },
    {
      dataField: 'customerName',
      text: 'Customer Name',
      sort: true,
    },
    {
      dataField: 'vanNo',
      text: 'van No',
    },
    {
      dataField: 'quantity',
      text: 'Quantity',
    },
    {
      dataField: 'updatedAt',
      text: 'last update',
      sort: true,
      formatter: (cell, row) => {
        return returnUserReadableDateTime(cell);
      },
    },
    {
      dataField: 'paymentGateway',
      text: 'payment Details',
      formatter: (cell, row) => {
        return (
          <div>
            <div className="d-block">{row?.paymentGateway || ''}</div>
            <div className="d-block">{row?.paymentType || ''}</div>
          </div>
        );
      },
    },
    {
      dataField: 'expiresAt',
      text: 'TimeRemaining',
      formatter: (cell, row) => {
        // let result = addTwoHoursOrNextDay(new Date(row?.updatedAt));
        // let time = remainingTime(result);
        let time = remainingTime(cell?.iso);
        return (
          <span className=" fs-6 text-danger">
            {row?.isLarge || row?.isMedium || row?.isEndlessAisle ? (
              '-'
            ) : (
              <CountdownTimer
                hours={time?.hours < 0 ? 0 : time.hours}
                mins={time?.mins < 0 ? 0 : time.mins}
                callback={() => handleTimeOut(row?.objectId)}
              />
            )}
          </span>
        );
      },
    },
    {
      dataField: 'view',
      text: 'View',
      formatter: (cell, row) => {
        return (
          <FontAwesomeIcon
            icon={faArrowRight}
            style={{ cursor: 'pointer' }}
            onClick={() => navigate(`/oms/orders/${row.objectId}`)}
          />
        );
      },
    },
  ];
  if (bulkActionOptions) {
    columns.splice(0, 0, {
      dataField: 'objectId',
      text: 'Select',
      formatter: (cell, row) => {
        return row?.isLarge ? (
          <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={
              <Tooltip id={`tooltip-${row.objectId}`}>
                Large Products are only allowed to update in DMS
              </Tooltip>
            }
          >
            <div>
              {getTableRowCheckBox(onRowSelect, cell, row?.isSelected, true)}
            </div>
          </OverlayTrigger>
        ) : (
          <div>
            {getTableRowCheckBox(onRowSelect, cell, row?.isSelected, false)}
          </div>
        );
      },
    });
  }
  if (isAdmin) {
    columns.splice(2, 0, {
      dataField: 'storeCode',
      text: 'Store Name',
    });
  }
  return columns;
};

export const remainingTime = (expiringTime) => {
  const currentTime = new Date();
  const expiryTime = new Date(expiringTime);
  const diffTime = expiryTime - currentTime;
  const hours = Math.floor(diffTime / (1000 * 60 * 60));
  const mins = Math.floor((diffTime % (1000 * 60 * 60)) / (1000 * 60));
  return {
    hours,
    mins,
  };
};

export const preRegisteredColumns = () => {
  const columns = [
    {
      dataField: 'vanNo',
      text: 'vanNo',
    },
    {
      dataField: 'name',
      text: 'customerName',
    },
    {
      dataField: 'email',
      text: 'email',
    },
    {
      dataField: 'mobileNumber',
      text: 'Number',
    },
    {
      dataField: 'orderDate',
      text: 'order date',
    },
  ];
  return columns;
};

export const preBookedColumns = (navigate) => {
  const columns = [
    {
      dataField: 'productTitle',
      text: 'Product',
      formatter: (cell, row) => {
        return returnProductInfo([cell]);
      },
    },
    {
      dataField: 'vanNo',
      text: 'vanNo',
    },
    {
      dataField: 'mrp',
      text: 'MRP',
    },
    {
      dataField: 'quantity',
      text: 'Quantity',
    },
    {
      dataField: 'view',
      text: 'View',
      formatter: (cell, row) => {
        return (
          <FontAwesomeIcon
            icon={faArrowRight}
            style={{ cursor: 'pointer' }}
            onClick={() => navigate(`/oms/orders/${row.objectId}`)}
          />
        );
      },
    },
  ];
  return columns;
};

export const sameorderItemsColumns = (isAdmin) => {
  const columns = [
    {
      dataField: 'productTitle',
      text: 'Product',
      formatter: (cell, row) => {
        return returnProductInfo([cell]);
      },
    },
    {
      dataField: 'orderNo',
      text: 'order No',
    },
    {
      dataField: 'customerName',
      text: 'Customer Name',
      sort: true,
    },
    {
      dataField: 'vanNo',
      text: 'vanNo',
    },
    {
      dataField: 'quantity',
      text: 'Quantity',
    },
    {
      dataField: 'status',
      text: 'Status',
      formatter: (cell, row) => {
        return (
          <div className="d-block">
            <div className="fw-bold">
              <span>
                &nbsp;&nbsp;
                <Badge bg={getTripBadgeStatus(cell)} className="badge-lg">
                  {cell?.toUpperCase()}
                </Badge>
                &nbsp;&nbsp;
              </span>
            </div>
          </div>
        );
      },
    },
  ];
  if (isAdmin) {
    columns.push({
      dataField: 'storeCode',
      text: 'Store Name',
    });
  }
  return columns;
};

export const getColumns = (
  type,
  onRowSelect,
  navigate,
  handleTimeOut,
  bulkActionOptions,
  isAdmin
) => {
  switch (type) {
    case orderStatus.assigned: {
      return orderAssignedColumns(
        onRowSelect,
        navigate,
        handleTimeOut,
        bulkActionOptions,
        isAdmin
      );
    }
    case orderStatus.preRegistered: {
      return preRegisteredColumns();
    }
    case orderStatus.preBooked: {
      return preBookedColumns(navigate);
    }
    case orderStatus.accepted: {
      return orderAcceptedColumns(
        onRowSelect,
        navigate,
        handleTimeOut,
        bulkActionOptions,
        isAdmin
      );
    }
    default: {
      return orderTableColumns(
        onRowSelect,
        navigate,
        bulkActionOptions,
        isAdmin
      );
    }
  }
};

export const searchByFields = [
  {
    label: 'Search by Phone Number',
    value: 'contactNo',
  },
  {
    label: 'Search by Invoice No',
    value: 'invoiceNo',
  },
  {
    label: 'Search by Serial No',
    value: 'serialNo',
  },
  {
    label: 'Search by Order No',
    value: 'orderNo',
  },
  {
    label: 'Search by Customer Name',
    value: 'customerName',
  },
  // {
  //   label: 'Search by Product Code',
  //   value: 'productCode',
  // },
  // {
  //   label: 'Search by Product Name',
  //   value: 'productName',
  // },
  // {
  //   label: 'Search by Product Category',
  //   value: 'productCategory',
  // },
];

export const ordersDataColumns = (navigate) => {
  const columns = [
    {
      dataField: 'orderNo',
      text: 'orderNo',
    },
    {
      dataField: 'vanNo',
      text: 'vanNo',
    },
    {
      dataField: 'isCancellable',
      text: 'isCancellable',
    },
    {
      dataField: 'serialNo',
      text: 'serialno',
      formatter: (cell, row) => {
        return <div className="fw-bold">{cell ? cell : '-'}</div>;
      },
    },
    {
      dataField: 'invoiceNo',
      text: 'invoice',
      formatter: (cell, row) => {
        return <div className="fw-bold">{cell ? cell : '-'}</div>;
      },
    },
    {
      dataField: 'storeCode',
      text: 'store',
      formatter: (cell, row) => {
        return <div className="fw-bold">{cell ? cell : '-'}</div>;
      },
    },
    {
      dataField: 'paymentGateway',
      text: 'payment Details',
      formatter: (cell, row) => {
        return (
          <div>
            <div className="d-block">{row?.paymentGateway || ''}</div>
            <div className="d-block">{row?.paymentType || ''}</div>
          </div>
        );
      },
    },
    {
      dataField: 'productTitle',
      text: 'title',
      formatter: (cell, row) => {
        return returnProductInfo([cell]);
      },
    },
    {
      dataField: 'expiresAt',
      text: 'TimeRemaining',
      formatter: (cell, row) => {
        let time = remainingTime(cell?.iso);
        return (
          <span className=" fs-6 text-danger">
            {row?.isLarge ||
            row?.isMedium ||
            row?.serviceType == 'reverse' ||
            (row?.status != 'Assigned' && row?.status != 'Accepted') ? (
              '-'
            ) : (
              <CountdownTimer
                hours={time?.hours < 0 ? 0 : time.hours}
                mins={time?.mins < 0 ? 0 : time.mins}
                callback={() => {}}
              />
            )}
          </span>
        );
      },
    },
    {
      dataField: 'orderStatus',
      text: 'orderStatus',
      formatter: (cell, row) => {
        return (
          <div className="d-block">
            <div className="fw-bold">
              <span>
                &nbsp;&nbsp;
                <Badge bg={getTripBadgeStatus(cell)} className="badge-lg">
                  {cell?.toUpperCase()}
                </Badge>
                &nbsp;&nbsp;
              </span>
            </div>
          </div>
        );
      },
    },
    {
      dataField: 'view',
      text: 'View',
      formatter: (cell, row) => {
        return (
          <FontAwesomeIcon
            icon={faArrowRight}
            style={{ cursor: 'pointer' }}
            onClick={() => navigate(`/oms/orders/${row?.objectId}`)}
          />
        );
      },
    },
  ];
  return columns;
};

export const manualAssignColumns = (onRowSelect, navigate) => {
  const columns = [
    {
      dataField: 'objectId',
      text: 'Select',
      formatter: (cell, row) => {
        return getTableRowCheckBox(onRowSelect, cell, row?.isSelected, false);
      },
    },
    {
      dataField: 'storeCode',
      text: 'Store Name',
    },
    {
      dataField: 'vanNo',
      text: 'Van No',
      formatter: (cell, row) => {
        return returnProductInfo(cell);
      },
    },
    {
      dataField: 'orderNo',
      text: 'order No',
      sort: true,
    },
    {
      dataField: 'quantity',
      text: 'Quantity',
      formatter: (cell, row) => {
        return returnProductInfo(cell);
      },
    },
    {
      dataField: 'updatedAt',
      text: 'Last Update',
      sort: true,
      formatter: (cell, row) => {
        return returnUserReadableDateTime(cell);
      },
    },
    {
      dataField: 'status',
      text: 'Status',
      formatter: (cell, row) => {
        return (
          <Badge bg={getTripBadgeStatus(cell)} className="badge-lg">
            {cell}
          </Badge>
        );
      },
    },
    {
      dataField: '',
      text: 'Driver Details',
      formatter: (cell, row) => {
        return (
          <div className="d-block">
            <div className="fw-bold">{row?.driverName}</div>
            <div className="fw-bold">{row?.contactNumber}</div>
            <div className="fw-bold">{row?.vehicleNumber}</div>
          </div>
        );
      },
    },
    {
      dataField: 'view',
      text: 'View',
      formatter: (cell, row) => {
        return (
          <FontAwesomeIcon
            icon={faArrowRight}
            style={{ cursor: 'pointer' }}
            onClick={() => navigate(`/oms/orders/${row.objectId}`)}
          />
        );
      },
    },
  ];
  return columns;
};

export const HyperLocalDeliveryColumns = (onRowSelect, navigate) => {
  const columns = [
    {
      dataField: 'objectId',
      text: 'Select',
      formatter: (cell, row) => {
        return getTableRowCheckBox(onRowSelect, cell, row?.isSelected, false);
      },
    },
    {
      dataField: 'storeCode',
      text: 'Store Name',
    },
    {
      dataField: 'orderNo',
      text: 'order No',
      sort: true,
    },
    {
      dataField: 'updatedAt',
      text: 'Last Update',
      sort: true,
      formatter: (cell, row) => {
        return returnUserReadableDateTime(cell);
      },
    },
    {
      dataField: 'vanNo',
      text: 'Van No',
    },
    {
      dataField: 'quantity',
      text: 'Quantity',
    },
    {
      dataField: 'status',
      text: 'Status',
      formatter: (cell, row) => {
        return (
          <Badge bg={getTripBadgeStatus(cell)} className="badge-lg">
            {cell}
          </Badge>
        );
      },
    },
    {
      dataField: 'view',
      text: 'View',
      formatter: (cell, row) => {
        return (
          <FontAwesomeIcon
            icon={faArrowRight}
            style={{ cursor: 'pointer' }}
            onClick={() => navigate(`/oms/orders/${row.objectId}`)}
          />
        );
      },
    },
  ];
  return columns;
};

export const manageHyperLocalDeliveriesColumns = (onRowSelect) => {
  const columns = [
    {
      dataField: 'objectId',
      text: 'Select',
      formatter: (cell, row) => {
        return getTableRowCheckBox(onRowSelect, cell, row?.isSelected, false);
      },
    },
    {
      dataField: 'pincode',
      text: 'Pincode',
    },
    {
      dataField: 'city',
      text: 'City',
    },
  ];
  return columns;
};

export const addTwoHoursOrNextDay = (date) => {
  const IST_OFFSET = 5.5 * 60 * 60 * 1000;
  const currentTime = new Date(date.getTime());
  const currentHour = currentTime.getUTCHours();
  const isWithinTimeRange = currentHour >= 5 && currentHour < 10;

  if (isWithinTimeRange) {
    return new Date(currentTime.getTime() + 2 * 60 * 60 * 1000);
  } else {
    const nextDay = new Date(currentTime);
    nextDay.setDate(nextDay.getDate() + 1);
    nextDay.setUTCHours(5, 30, 0, 0);
    return new Date(nextDay.getTime());
  }
};
