import React from "react";
import { Button, Modal, Table } from "@themesberg/react-bootstrap";
import { useTranslation } from "react-i18next";

export const EWBModalForTrip = (props) => {
    const { t } = useTranslation();
    const {
        handleClose,
        showModal,
        ewbInfo,
        handleEWBConsolidate,
        handleFailedItemsEWBRetry
    } = props;
    const failedItems = ewbInfo?.filter(item => item.status === "error");
    const TableRow = (item) => {
        return (
            <tr>
                <th scope="row">{item.invoiceNo}</th>
                <td>{item.status}</td>
                <td>{item?.data?.error_cd || item?.data?.ewayBillNo}</td>
                <td>{item?.data?.message || item?.data?.alert}</td>
            </tr>
        )
    }
    const atleastOneSuccessful = ewbInfo?.filter(ewbItem => ewbItem?.status === "success");
    return (
        <Modal backdrop={"static"} dialogClassName="modal-90w" size="lg" show={showModal} onHide={handleClose}>
            <Modal.Header>
                <Modal.Title className="h6">{t("Create EWB")}</Modal.Title>
                <Button variant="close" aria-label="Close" onClick={handleClose} />
            </Modal.Header>
            <Modal.Body style={{
                maxHeight: 500,
                overflowY: "scroll"
            }}>
                <Table responsive className="align-items-center table-flush">
                    <thead className="thead-light">
                        <tr>
                            <th scope="col w-10">Invoice Number</th>
                            <th scope="col">Status</th>
                            <th scope="col">Error / EWB Number</th>
                            <th scope="col">Message</th>
                        </tr>
                    </thead>
                    <tbody>
                        {ewbInfo.map(pv => <TableRow key={`page-visit-${pv.objectId}`} {...pv} />)}
                    </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer>
                {(failedItems?.length > 0) ? (
                    <Button variant="secondary" onClick={() => {
                        handleFailedItemsEWBRetry();
                    }}>
                        {t("Retry Failed Items")}
                    </Button>
                ) : (
                    <Button disabled={atleastOneSuccessful?.length === 0} variant="secondary" onClick={handleEWBConsolidate}>
                        {t("Consolidate")}
                    </Button>
                )}

                {(failedItems?.length > 0) ? (
                    <Button disabled={atleastOneSuccessful?.length === 0} variant="warning" className="ms-auto" onClick={handleEWBConsolidate}>
                        {t("Consolidate Partial")}
                    </Button>
                ) : (
                    <Button variant="link" className="ms-auto" onClick={handleClose}>
                        {t("Close")}
                    </Button>
                )}

            </Modal.Footer>
        </Modal>
    )
}
export default EWBModalForTrip;