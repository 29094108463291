import { MARK_VEHICLE_AS_SELECTED, RESET_STORE, SET_DRIVERS_LIST_IN_STORE } from "../actions/types";

const INITIAL_STATE = {
    driversList: {}
};
const driversReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_DRIVERS_LIST_IN_STORE: {
            return {
                ...state,
                driversList: action.data
            }
        }
        case MARK_VEHICLE_AS_SELECTED: {
            const {
                objectId,
                selected
            } = action.data;
            const updatedVehicleList = JSON.parse(JSON.stringify(state.driversList));
            
            if (updatedVehicleList[objectId]) {
                updatedVehicleList[objectId] = {
                    ...updatedVehicleList[objectId],
                    isSelected: selected
                };
            }
            return {
                ...state,
                driversList: updatedVehicleList
            }
        }
        case RESET_STORE: {
            return INITIAL_STATE;
        }
        default: return state;
    }
};

export default driversReducer;