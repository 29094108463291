import Parse from "parse";

export const addNewCustomerCareManager = (data) => {
    return Parse.Cloud.run("addNewCustomerCareManager", data)
};

export const addNewCustomerCareUser = data => {
    return Parse.Cloud.run("addNewCustomerCareUser", data);
}

export const addNewAdminUserToSystem = (data) => {
    return Parse.Cloud.run("addNewAdminUserToSystem", data);
}