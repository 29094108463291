import React from 'react';
import navigationConfig from './wmsNavigationConfig';
import RenderNavItems from '../common/renderNavItems';

const WMSSidebarOptions = () => {
  return (
    <>
      <RenderNavItems config={navigationConfig} />
    </>
  );
};

export default WMSSidebarOptions;