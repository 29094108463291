import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

// Register the required components for Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BarGraph = ({ labels, dataset, options }) => {
  // Build the data object using labels and dataset passed from the parent
  const data = {
    labels: labels,
    datasets: dataset,
  };

  // Return the Bar component from react-chartjs-2
  return <Bar data={data} options={options} />;
};

export default BarGraph;
