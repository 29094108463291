import React from 'react';
import OrderPage from './components/OrderPage';
import { orderStatus } from '../../../constants/orderStatus';

const QCFailed = () => {
  return (
    <div>
      <OrderPage type={orderStatus.qcfailed} />
    </div>
  );
};

export default QCFailed;
