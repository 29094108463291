import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { updateSpinnerState } from '../../../actions/spinner';
import {
  setCompanyAndCategoryDataInStore,
  setSubCategoriesDataInStore,
} from '../../../actions/products';
import { updateToastInfo } from '../../../actions/settings';
import { getAllCompaniesCategoriesSubCategoriesData } from '../../../parse-functions/sms/scheme';

const useGetCompanyAndCategory = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const companies = useSelector((state) => state?.products?.companies || {});
  const categories = useSelector((state) => state?.products?.categories || {});
  const subCategories = useSelector(
    (state) => state?.products?.subCategories || {}
  );

  useEffect(() => {
    const getAllInfoForProduct = async () => {
      dispatch(updateSpinnerState(true));
      try {
        // get all info related to company
        const data = await getAllCompaniesCategoriesSubCategoriesData();
        dispatch(
          setCompanyAndCategoryDataInStore({
            companies: data.companies,
            categories: data.categories,
          })
        );
        dispatch(
          setSubCategoriesDataInStore({
            subCategories: data.subCategories,
          })
        );
        dispatch(updateSpinnerState(false));
      } catch (e) {
        dispatch(updateSpinnerState(false));
        dispatch(
          updateToastInfo({
            show: true,
            type: 'danger',
            message: t(
              'Failed Getting Info related to Scheme. Please contact support.'
            ),
          })
        );
      }
    };
    if (
      Object.keys(companies).length === 0 ||
      Object.keys(categories).length === 0 ||
      Object.keys(subCategories).length === 0
    ) {
      getAllInfoForProduct();
    }
  }, []);
};

export default useGetCompanyAndCategory;
