import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Form, InputGroup, Modal } from "@themesberg/react-bootstrap";
import Joi from "joi";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import Datetime from "react-datetime";
import { useTranslation } from "react-i18next";
import { returnUserReadableDate } from "../../utils/datetime";
import { generateRandomId } from "../../utils/string";
import { returnValidationErrorMessages } from "../../utils/validation";
import DropdownComponent from "./Dropdown";

const buybackRescheduleSchema = Joi.object({
    targetBranch: Joi.string().required(),
    date: Joi.date().required(),
    remarks: Joi.string().required(),
    buybackProduct: Joi.string().required()
});

const BuyBackPickupScheduleModal = (props) => {
    const {
        showModal,
        closeModal,
        onConfirm,
        selectedParcelId,
        pageStatus,
        targetDropdownOptions,
        disableTargetDropdown = true
    } = props;
    const [formErrors, setFormErrors] = useState({});
    const pageParcels = useSelector((state) => state.parcels[pageStatus] || {});
    const selectedParcel = pageParcels[selectedParcelId] || {};
    const [data, setFormData] = useState({});

    const { t } = useTranslation();
    const handleConfirmClick = async () => {
        const { error: formErrors = {}, value: formValue } = buybackRescheduleSchema.validate(data, {
            abortEarly: false
        });
        const {
            details = {}
        } = formErrors;
        if (details?.length > 0) {
            const validationErrorMessages = returnValidationErrorMessages(details);
            setFormErrors(validationErrorMessages);
        } else {
            onConfirm({ ...formValue, parcelObjectId: selectedParcelId })
        }
    };
    return (
        <Modal id={generateRandomId()} as={Modal.Dialog} centered show={showModal} onHide={closeModal}>
            <Modal.Header>
                <Modal.Title className="h6">{t("Reschedule Parcel")}</Modal.Title>
                <Button variant="close" aria-label="Close" onClick={closeModal} />
            </Modal.Header>
            <Modal.Body>
                <Form.Group className="mb-4">
                    <Form.Label className={formErrors["targetBranch"]?.length > 0 ? "text-danger" : ""}>{t("Choose Target Branch")}</Form.Label>
                    <DropdownComponent
                        disabled={disableTargetDropdown}
                        onChange={(option) => {
                            setFormData(oldData => ({
                                ...oldData,
                                targetBranch: option.value
                            }))
                        }}
                        options={targetDropdownOptions}
                        placeholder={t(selectedParcel?.returnScanWarehouse)}
                    />
                </Form.Group>
                {formErrors["targetBranch"]?.length > 0 && (
                    <Form.Control.Feedback type="invalid">{formErrors["targetBranch"]}</Form.Control.Feedback>
                )}
                <Form.Group className="mb-4">
                    <Form.Label>{t("Choose Delivery Date")}</Form.Label>
                    <Datetime
                        timeFormat={false}
                        closeOnSelect={true}
                        onChange={(value) => {
                            setFormData(oldData => ({
                                ...oldData,
                                date: new Date(value)
                            }))
                        }}
                        renderInput={(props, openCalendar) => (
                            <InputGroup>
                                <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} className="icon icon-xs" /></InputGroup.Text>
                                <Form.Control
                                    isInvalid={formErrors["date"]?.length > 0}
                                    required
                                    type="text"
                                    value={returnUserReadableDate(data.date)}
                                    placeholder="Select delivery date"
                                    onFocus={openCalendar} />
                                {formErrors["date"]?.length > 0 && (
                                    <Form.Control.Feedback type="invalid">{formErrors["date"]}</Form.Control.Feedback>
                                )}
                            </InputGroup>
                        )} />
                </Form.Group>
                <Form.Group className="mb-4">
                    <Form.Label>{t("Item Details to pickup")}</Form.Label>
                    <Form.Control
                        isInvalid={formErrors["buybackProduct"]?.length > 0}
                        required
                        type="text"
                        defaultValue={data.buybackProduct}
                        onChange={(event) => {
                            const buybackProduct = event.target.value;
                            setFormData(oldData => ({
                                ...oldData,
                                buybackProduct
                            }))
                        }}
                        placeholder={t("Item Details to pickup")} />
                    {formErrors["buybackProduct"]?.length > 0 && (
                        <Form.Control.Feedback type="invalid">{formErrors["buybackProduct"]}</Form.Control.Feedback>
                    )}
                </Form.Group>
                <Form.Group className="mb-4">
                    <Form.Label>{t("Reason")}</Form.Label>
                    <Form.Control
                        isInvalid={formErrors["remarks"]?.length > 0}
                        required
                        type="text"
                        defaultValue={data.remarks || selectedParcel?.remarks}
                        onChange={(event) => {
                            const remarks = event.target.value;
                            setFormData(oldData => ({
                                ...oldData,
                                remarks
                            }))
                        }}
                        placeholder={t("Reason to Reschedule")} />
                    {formErrors["remarks"]?.length > 0 && (
                        <Form.Control.Feedback type="invalid">{formErrors["remarks"]}</Form.Control.Feedback>
                    )}
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleConfirmClick}>
                    {t("Reschedule")}
                </Button>
                <Button variant="link" className="text-gray ms-auto" onClick={closeModal}>
                    {t("Cancel")}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
export default BuyBackPickupScheduleModal;