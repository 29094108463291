import React from 'react';
import { Button, Modal } from '@themesberg/react-bootstrap';
import { useTranslation } from 'react-i18next';
import { generateRandomId } from '../../../utils/string';
import ParcelStatusPage from '../../../components/common/parcelStatusPages';

const BulkAddSchemeModal = (props) => {
  const { showModal, closeModal, data, columns } = props;
  const { t } = useTranslation();
  const uploadedData = data.reduce((acc, obj, index) => {
    acc[index + 1] = obj;
    return acc;
  }, {});

  return (
    <div>
      <>
        <Modal
          id={generateRandomId()}
          as={Modal.Dialog}
          centered
          show={showModal}
          onHide={closeModal}
          size="xl"
        >
          <Modal.Header>
            <Modal.Title className="h6">
              {t(`All Uploaded Products`)}
            </Modal.Title>
            <Button variant="close" aria-label="Close" onClick={closeModal} />
          </Modal.Header>
          <Modal.Body>
            {Object.keys(uploadedData).length > 0 && (
              <ParcelStatusPage
                tableData={uploadedData}
                tableColumns={columns}
                hideBulkActions={true}
                showDateFilters={false}
                showHeaders={false}
              />
            )}
          </Modal.Body>
        </Modal>
      </>
    </div>
  );
};

export default BulkAddSchemeModal;
