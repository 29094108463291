import { Button, Col, Form, Row } from "@themesberg/react-bootstrap";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { updateToastInfo } from "../../../actions/settings";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { updateSpinnerState } from "../../../actions/spinner";
import { addNewCompanyCategoryName } from "../../../parse-functions/company";
import { getAllCompaniesAndCategoriesData } from "../../../parse-functions/products";
import { setCompanyAndCategoryDataInStore } from "../../../actions/products";

const AddCategory = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [categoryName, setCategoryName] = useState(null);

    const saveCategoryName = async () => {
        dispatch(updateSpinnerState(true));
        try {
            if (categoryName) {
                await addNewCompanyCategoryName({
                    name: categoryName,
                    type: "category"
                })
                const data = await getAllCompaniesAndCategoriesData()
                dispatch(setCompanyAndCategoryDataInStore(data));
                dispatch(updateToastInfo({
                    show: true,
                    type: "success",
                    title: `${categoryName} added successfully`,
                    message: "Go ahead and add category to if needed"
                }))
                dispatch(updateSpinnerState(false));
            } else {
                dispatch(updateToastInfo({
                    show: true,
                    type: "danger",
                    title: "Category Name is compulsory",
                    message: "Please enter category name in textbox"
                }))
            }
        } catch(e) {
            dispatch(updateSpinnerState(false));
            dispatch(updateToastInfo({
                show: true,
                type: "danger",
                title: t("Error saving Category Name"),
                message: e.error || t("Please enter category name in textbox")
            }))
        }
        
    };
    return (
        <Row>
            <Col />
            <Col>
                <Form.Group>
                    <Form.Control
                        className="mt-4"
                        placeholder="Enter Category Name"
                        required
                        type="text"
                        onKeyDown={(event) => {
                            if (event.nativeEvent.code === "Enter") {
                                saveCategoryName();
                            }
                        }}
                        onChange={(event) => {
                            setCategoryName(event.target.value);
                        }} />
                </Form.Group>
                <Button
                    className="w-100 mt-4"
                    type="button"
                    onClick={saveCategoryName}
                    variant="primary">
                    <FontAwesomeIcon icon={faSave} /> &nbsp;&nbsp; Submit
                </Button>
            </Col>
            <Col />
        </Row>
    )
};
export default AddCategory;