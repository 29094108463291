import { Form } from '@themesberg/react-bootstrap';
import React, { useEffect, useState } from 'react';
import EditableDropDown from '../../../components/common/EditableDropdown';

const Child = ({ id, title, updateRoutes, routeOptions }) => {
  const [currRoute, setCurrRoute] = useState([]);
  const [childRouteOptions, setChildRouteOptions] = useState([]);
  const [subChild, setChild] = useState([]);

  const handleRouteChange = (selectedOptions) => {
    const routes = selectedOptions.map((option) => option.value);
    setCurrRoute(routes);
    updateRoutes(id, routes);

    let nestedOptions = [];
    selectedOptions
      .filter((option) => option.children)
      .forEach((option) => {
        nestedOptions = [...nestedOptions, ...option.children];
      });

    if (nestedOptions.length > 0) {
      if (subChild.length === 0) {
        setChild((prev) => [...prev, { id: Date.now(), routes: [] }]);
      }
      setChildRouteOptions(nestedOptions);
    } else {
      setChildRouteOptions([]);
      setChild([]);
    }
  };

  const handleSubRouteChange = (id, value) => {
    setChild(
      subChild.map((child) => {
        if (child.id === id) {
          return { ...child, routes: value };
        }
        return child;
      })
    );
  };

  useEffect(() => {
    updateRoutes(
      id,
      subChild.reduce((acc, curr) => {
        if (curr.routes.length > 0) {
          acc = [...acc, ...curr.routes];
        }
        return acc;
      }, currRoute)
    );
  }, [subChild, currRoute]);

  return (
    <>
      <Form.Label>{title}</Form.Label>
      <EditableDropDown
        isMultiSelect
        onChange={handleRouteChange}
        options={[{ label: 'All', value: 'all' }, ...routeOptions]}
        placeholder="Select Routes from below"
      />
      {subChild.map((child) => (
        <Child
          key={child.id}
          id={child.id}
          title={'please select sub route'}
          updateRoutes={handleSubRouteChange}
          routeOptions={childRouteOptions}
        />
      ))}
    </>
  );
};

export default Child;
