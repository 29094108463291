import React from 'react';
import ActionButton from '../../pages/oms/interBranchTransfers/components/ActionButton';
import { SchemeBadge } from './schemeTableRedesign';

export const usersTableColumns = ({ handleSelectedRowUpdate }) => {
  const columns = [
    {
      dataField: 'name',
      text: 'NAME',
    },
    {
      dataField: 'emailId',
      text: 'EMAIL ID',
    },
    {
      dataField: 'phoneNumber',
      text: 'PHONE NUMBER',
    },
    {
      dataField: 'role',
      text: 'USER ROLE',
    },
    {
      dataField: 'status',
      text: 'STATUS',
      formatter: (cell, row) => {
        return (
          <div className="d-block">
            <SchemeBadge text={cell} />
          </div>
        );
      },
    },
    {
      dataField: 'objectId',
      text: 'ACTION',
      formatter: (cell, row) => {
        return (
          <ActionButton
            options={
              row.status === 'inactive'
                ? ['Edit', 'Activate']
                : ['Edit', 'Deactivate']
            }
            onActionClick={(option) => handleSelectedRowUpdate(option, row)}
          />
        );
      },
    },
  ];
  return columns;
};
