import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setExcelMismatchedProductUploadDataInStore,
  setExcelProductUploadDataInStore,
  setShowMismatchProductsModal,
} from '../../../../actions/sms/productsExcelUpload';
import { convertExcelDataToJSONRows } from '../../../../utils/excel';
import FileUploadButton from '../../../../components/common/fileUpload';
import { productsUploadSampleFile } from '../../../../utils/constants';
import BulkAddSchemeModal from '../BulkAddSchemeModal';
import { bulkAddSchemeColumns } from '../../../../utils/sms/BulkAddSchemeColumns';
import { Button } from '@themesberg/react-bootstrap';
import { useTranslation } from 'react-i18next';
import EditProducts from './EditProducts';
import { updateToastInfo } from '../../../../actions/settings';
import useGetCompanyAndCategory from '../../custom-hooks/useGetCompanyAndCategory';
const ExcelProductsUpload = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const uploadedData = useSelector(
    (state) => state?.productsExcelUpload?.uploadedData || []
  );
  const showMismatchModal = useSelector(
    (state) => state?.productsExcelUpload?.showModal || false
  );
  const categories = Object.values(
    useSelector((state) => state?.products?.categories || {})
  ).map((item) => item.name);

  const lowercaseCategories = categories.reduce((acc, item) => {
    acc[item.toLowerCase()] = item;
    return acc;
  }, {});
  const userInfo = useSelector((state) => state?.user?.userInfo);
  const isAdmin = useSelector(
    (state) => state?.user?.preferences?.isAdmin || false
  );
  const [showBulkAddSchemeModal, setShowBulkAddSchemeModal] = useState(false);
  const [mismatches, setMismatches] = useState([]);
  const fileUploadComplete = (file) => {
    dispatch(setExcelProductUploadDataInStore([]));
    const allColumns = file.rows[0];
    const allRows = file.rows.slice(1, file.rows.length);
    const arrData = convertExcelDataToJSONRows(allColumns, allRows);
    excelValidate(arrData);
  };
  const excelValidate = (arr) => {
    const matchedProducts = [];
    const mismatchedProducts = [];
    if (isAdmin) {
      arr.map((item) => {
        if (
          !Object.keys(lowercaseCategories).includes(
            item.category.toLowerCase().trim()
          ) ||
          item.category.trim().length === 0
        ) {
          mismatchedProducts.push(item);
        } else {
          matchedProducts.push({
            ...item,
            category: lowercaseCategories[item.category.toLowerCase().trim()],
          });
        }
      });
    } else {
      arr.map((item) => {
        if (
          !userInfo.productCategory
            .map((item) => item.toLowerCase().trim())
            .includes(
              item?.category?.trim().length === 0 ||
                item?.category?.toLowerCase().trim()
            )
        ) {
          mismatchedProducts.push(item);
        } else {
          matchedProducts.push({
            ...item,
            category: lowercaseCategories[item.category.toLowerCase().trim()],
          });
        }
      });
    }
    if (matchedProducts.length > 0) {
      dispatch(setExcelProductUploadDataInStore(matchedProducts));
    }
    if (mismatchedProducts.length > 0) {
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          message: t(`Some products have category mismatches.`),
        })
      );
      setMismatches(
        mismatchedProducts.reduce((acc, item, index) => {
          acc[index + 1] = {
            ...item,
            category: '',
            id: index + 1,
          };
          return acc;
        }, {})
      );
      dispatch(setExcelMismatchedProductUploadDataInStore(mismatchedProducts));
      dispatch(setShowMismatchProductsModal(true));
    } else {
      setShowBulkAddSchemeModal(true);
    }
  };

  const handleChange = (row, value) => {
    setMismatches((prev) => ({
      ...prev,
      [row.id]: {
        ...prev[row.id],
        category: value,
      },
    }));
  };

  const validateCategories = (state) => {
    let errorCount = 0;
    const updatedState = Object.keys(state).reduce((acc, key) => {
      const item = state[key];
      if (item.category.length === 0) {
        errorCount++;
        acc[key] = {
          ...item,
          error: 'Cannot be empty',
        };
      } else {
        const { error, ...rest } = item;
        acc[key] = rest;
      }
      return acc;
    }, {});
    return { updatedState, errorCount };
  };

  const handleSubmit = () => {
    const { updatedState, errorCount } = validateCategories(mismatches);
    if (errorCount === 0) {
      const newProducts = Object.values(mismatches).map((item) => {
        const { id, ...rest } = item;
        return rest;
      });
      dispatch(
        setExcelProductUploadDataInStore([...uploadedData, ...newProducts])
      );
      setMismatches([]);
      dispatch(setExcelMismatchedProductUploadDataInStore([]));
      dispatch(setShowMismatchProductsModal(false));
    } else {
      setMismatches(updatedState);
    }
  };

  useGetCompanyAndCategory();

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        height: 500,
      }}
    >
      {uploadedData.length > 0 && (
        <Button
          onClick={() => setShowBulkAddSchemeModal(true)}
          className="mb-3"
        >
          {t('Show Uploaded Products')}
        </Button>
      )}
      {Object.keys(mismatches).length > 0 && (
        <Button
          style={{ backgroundColor: 'red' }}
          onClick={() => dispatch(setShowMismatchProductsModal(true))}
          className="mb-3"
        >
          {t('Show Mismatched Products')}
        </Button>
      )}
      <FileUploadButton
        buttonText={t('Upload File')}
        onFileUpload={fileUploadComplete}
      />
      <div>or</div>
      <div
        style={{
          cursor: 'pointer',
          textDecoration: 'underline',
        }}
        onClick={() => {
          window.open(productsUploadSampleFile, '_blank');
        }}
      >
        Download Template from here
      </div>
      <BulkAddSchemeModal
        showModal={uploadedData.length > 0 && showBulkAddSchemeModal}
        closeModal={() => setShowBulkAddSchemeModal(false)}
        data={uploadedData}
        columns={bulkAddSchemeColumns()}
      />
      <EditProducts
        showModal={Object.keys(mismatches).length > 0 && showMismatchModal}
        closeModal={() => dispatch(setShowMismatchProductsModal(false))}
        data={Object.values(mismatches)}
        columns={bulkAddSchemeColumns(
          isAdmin ? categories : userInfo.productCategory,
          handleChange
        )}
        onSubmit={handleSubmit}
      />
    </div>
  );
};

export default ExcelProductsUpload;
