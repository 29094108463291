import React from "react";
import WarehouseTargetPage from "../purchase/assigned";
import { Routes } from "../../../router/routes";
import { useTranslation } from "react-i18next";

const ScannedTargets = () => {
    const { t } = useTranslation();
    const status = "scanned";
    const breadCrumbItems = [{
        title: t("WMS"),
        link: Routes.WMS.Target.Assign.path
    }, {
        title: t("Scanned Targets"),
        link: Routes.WMS.Target.Completed.path
    }]
    return (
        <WarehouseTargetPage
            pageName={"scannedTargets"}
            status={status}
            bulkActionOptions={[{
                label: "Reassign",
                value: "reassign"
            }, {
                label: "Seen",
                value: "seen"
            }, {
                label: "Cancel",
                value: "cancel"
            }]}
            pageTitle={"Scanned Targets"}
            pageSubTitle={"See all the scanned items."}
            breadCrumbItems={breadCrumbItems}
        />
    )
}
export default ScannedTargets;
