import Parse from 'parse';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmationModal from '../../../components/common/confirmationModal';
import arrayToBreadCrumbs from '../../../utils/sms/arrayToBreadCrumbs';
import { getCurrentWarehouseInfo } from '../../../parse-functions/warehouse';
import { setCurrentWarehouseInfoInStore } from '../../../actions/user';
import { updateSpinnerState } from '../../../actions/spinner';
import { updateToastInfo } from '../../../actions/settings';
import { getSpecificUserPreferences } from '../../../parse-functions/preferences';
import ParcelStatusPage from '../../../components/common/parcelStatusPages';
import { returnDMSUserListTableColumns } from '../../incharge/inchargeUtils';
import InchargeUpdateModal from '../../incharge/inchargeUpdateModal';
import { updateDMSUserInfo } from '../../../parse-functions/users';
import { apiCall } from '../../../middlewares/api';
import { getOMSUsers } from '../../../parse-functions/oms/deliveryAgent';

const OMSUsersList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showInchargeUpdateModal, setShowInchargeUpdateModal] = useState(false);
  const [editUserData, setEditUserData] = useState({});
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [usersData, setUsersData] = useState([]);
  const otherWarehouses = useSelector(
    (state) => state?.user?.otherWarehousesInZone || {}
  );
  const isSuperAdmin = useSelector(
    (state) => state?.user?.preferences?.isSuperAdmin || false
  );
  const preferences = useSelector((state) => state?.user?.preferences || {});

  const breadCrumbItems = arrayToBreadCrumbs([['Users'], ['List']]);

  useEffect(() => {
    getAvailableOMSUsersList();
    // if (Object.keys(otherWarehouses)?.length < 1) {
    //   getWarehousesList();
    // }
  }, []);

  // const getWarehousesList = async () => {
  //   try {
  //     const warehouseInfo = await getCurrentWarehouseInfo({
  //       warehouseName: preferences?.storeName,
  //       isSuperAdmin,
  //     });
  //     console.log(warehouseInfo);
  //     dispatch(setCurrentWarehouseInfoInStore(warehouseInfo));
  //   } catch (e) {}
  // };
  const getAvailableOMSUsersList = async () => {
    dispatch(updateSpinnerState(true));
    try {
      const omsUsers = await getOMSUsers();
      setUsersData(omsUsers);
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'success',
          title: t('OMS Users List fetched'),
          message: t('All the Users that use the OMS system'),
        })
      );
    } catch (e) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('Error'),
          message: t(e.message),
        })
      );
    }
  };

  const fetchUserPreferences = async () => {
    dispatch(updateSpinnerState(true));
    try {
      const preferences = await getSpecificUserPreferences({
        id: selectedUsers[0].userId,
      });
      setEditUserData({
        ...selectedUsers[0],
        applications: preferences.applications,
      });
      dispatch(updateSpinnerState(false));
    } catch (e) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('Error'),
          message: t(e.message),
        })
      );
    }
  };

  const onRefreshClick = () => {
    setSelectedUsers([]);
    getAvailableOMSUsersList();
  };
  const onRowSelect = (selected, objectId) => {
    setUsersData((prev) =>
      prev.map((item) =>
        item.objectId === objectId ? { ...item, isSelected: selected } : item
      )
    );
    setSelectedUsers((prev) => {
      if (!selected) {
        return prev.filter((user) => user.objectId !== objectId);
      }
      return [...prev, usersData.find((user) => user.objectId === objectId)];
    });
  };

  const handleShowDeleteModal = () => {
    setShowDeleteModal(true);
  };
  const handleShowVehicleUpdateModal = () => {
    setShowInchargeUpdateModal(true);
  };
  const handleSelectedRowsUpdate = async (actionId) => {
    if (selectedUsers?.length > 0) {
      if (selectedUsers?.length > 1 && actionId === 'edit') {
        dispatch(
          updateToastInfo({
            show: true,
            type: 'danger',
            title: t('Max 1 person to select'),
            message: t('Please select maximum of 1 person for editing'),
          })
        );
        return;
      }
      // we can do edit and delete here
      switch (actionId) {
        case 'delete': {
          handleShowDeleteModal();
          break;
        }
        case 'edit': {
          await fetchUserPreferences();
          handleShowVehicleUpdateModal();
          break;
        }
        default: {
          break;
        }
      }
    }
  };
  const handleOnConfirmClick = async (data) => {
    try {
      dispatch(updateSpinnerState(true));
      await updateDMSUserInfo({ data });
      getAvailableOMSUsersList();
      dispatch(
        updateToastInfo({
          show: true,
          type: 'success',
          title: 'User Update Successful',
          message: '',
        })
      );
      setShowDeleteModal(false);
      setShowInchargeUpdateModal(false);
      setSelectedUsers([]);
    } catch (e) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: 'User Update Failed',
          message: 'Please contact support for help',
        })
      );
    }
  };
  const handleModalOnConfirmClick = () => {
    const userItemData = [];
    // eslint-disable-next-line no-unused-expressions
    selectedUsers?.forEach((userItem) => {
      const data = {};
      data.objectId = userItem.objectId;
      data.isDeleted = true;
      data.className = 'incharges';
      data.dataFields = {
        status: 'deleted',
        actionLog: {
          action: 'deleted',
          user: Parse.User.current().getUsername(),
          time: new Date().getTime(),
        },
      };
      userItemData.push(data);
    });
    handleOnConfirmClick(userItemData);
  };
  const handleInchargeUpdateInfo = (vehicleObject) => {
    const inchargeUpdateData = [
      {
        objectId: vehicleObject.objectId,
        isDeleted: false,
        className: 'incharges',
        userId: vehicleObject.userId,
        applications: vehicleObject.applications,
        dataFields: {
          phoneNumber: vehicleObject?.phoneNumber?.toString(),
          name: vehicleObject.name,
          emailId: vehicleObject.emailId,
          storeName: vehicleObject.storeName,
          actionLog: {
            action: 'updated',
            user: Parse.User.current().getUsername(),
            time: new Date().getTime(),
          },
        },
      },
    ];
    handleOnConfirmClick(inchargeUpdateData);
  };

  return (
    <>
      <ParcelStatusPage
        pageType={'OMSInchargeList'}
        showDateFilters={false}
        tableData={usersData}
        tableColumns={returnDMSUserListTableColumns(onRowSelect, 'oms')}
        handleRefresh={onRefreshClick}
        pageTitle={t('OMS User List')}
        breadCrumbItems={breadCrumbItems}
        pageSubTitle={t('All the Users who use the OMS Platform')}
        searchPlaceHolder={t('Search By Name, Warehouse etc')}
        enableBulkActions={selectedUsers?.length > 0}
        bulkActionOptions={[
          {
            label: 'Edit',
            value: 'edit',
          },
          {
            label: 'Delete / Remove',
            value: 'delete',
          },
        ]}
        handleItemBulkUpdate={handleSelectedRowsUpdate}
      />
      <ConfirmationModal
        showModal={showDeleteModal}
        onConfirm={handleModalOnConfirmClick}
        closeModal={() => {
          setShowDeleteModal(false);
        }}
        modalBody={t('Are you sure you want to delete these users')}
        modalTitle={t('Delete Users')}
      />
      <InchargeUpdateModal
        closeModal={() => {
          setShowInchargeUpdateModal(false);
        }}
        user={editUserData}
        showModal={showInchargeUpdateModal}
        onConfirm={handleInchargeUpdateInfo}
      />
    </>
  );
};
export default OMSUsersList;
