import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Form, Row } from "@themesberg/react-bootstrap";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { updateSpinnerState } from "../../../actions/spinner";
import { addNewCompanyCategoryName } from "../../../parse-functions/company";
import { updateToastInfo } from "../../../actions/settings";
import { getAllCompaniesAndCategoriesData } from "../../../parse-functions/products";
import { setCompanyAndCategoryDataInStore } from "../../../actions/products";

const AddCompanyForm = () => {
    const dispatch = useDispatch();
    const [companyName, setCompanyName] = useState("");

    const saveCompanyName = async () => {
        dispatch(updateSpinnerState(true));
        try {
            await addNewCompanyCategoryName({
                name: companyName,
                type: "company"
            })
            const data = await getAllCompaniesAndCategoriesData()
            dispatch(setCompanyAndCategoryDataInStore(data));
            dispatch(updateToastInfo({
                show: true,
                type: "success",
                title: `${companyName} added successfully`,
                message: "Go ahead and add category to if needed"
            }))
            dispatch(updateSpinnerState(false));
        } catch(e) {
            dispatch(updateSpinnerState(false));
        }
    };
    return (
        <Row>
            <Col />
            <Col>
                <Form.Group>
                    <Form.Control
                        placeholder="Enter Company Name"
                        required
                        type="text"
                        onKeyDown={(event) => {
                            if (event.nativeEvent.code === "Enter") {
                                saveCompanyName();
                            }
                        }}
                        onChange={(event) => {
                            setCompanyName(event.target.value);
                        }} />
                </Form.Group>
                <Button
                    className="w-100 mt-4"
                    type="button"
                    onClick={saveCompanyName}
                    variant="primary">
                    <FontAwesomeIcon icon={faSave} /> &nbsp;&nbsp; Submit
                </Button>
            </Col>
            <Col />
        </Row>
    )
};
export default AddCompanyForm;