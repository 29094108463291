import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Breadcrumb, Col, Row } from "@themesberg/react-bootstrap";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setVehicleTypesInStore, setActiveVehicleId } from "../actions/vehicles";
import VehicleCard from "../components/planning/vehicleCard";
import VehicleOrdersTable from "../components/planning/vehicleOrdersTable";
import VehicleAlgoResultCard from "../components/planning/vehicleResultCard";
import { DefaultVehiclesList } from "../constants/vehicles";
import { returnDisplayVehiclesList } from "../utils/vehicles";

const PlanningPage = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const vehiclesList = useSelector((state) => state.vehicles.vehiclesList || []);
    const activeVehicleId = useSelector((state) => state?.vehicles?.activeVehicleId || null);
    useEffect(() => {
        if (!Object.keys(vehiclesList).length) {
            dispatch(setVehicleTypesInStore(returnDisplayVehiclesList(DefaultVehiclesList)));
        }
        if (!activeVehicleId && Object.keys(vehiclesList).length) {
            dispatch(setActiveVehicleId(Object.keys(vehiclesList)[0]))
        }
    }, [vehiclesList]);
    return (
        <div className="mt-1">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-2">
                <div className="d-block mb-4 mb-md-0">
                    <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
                        <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
                        <Breadcrumb.Item>{t("Home")}</Breadcrumb.Item>
                        <Breadcrumb.Item active>{t("Plan")}</Breadcrumb.Item>
                    </Breadcrumb>
                    <h4>{t("Plan Vehicles")}</h4>
                    <p className="mb-0">{t("Prepare temporary trip sheet here")}</p>
                </div>
            </div>
            <Row>
                <Col
                    md={3}
                    lg={3}
                    xl={3}
                    style={{
                        height: '83vh',
                        overflowY: 'scroll'
                    }}>
                    {Object.keys(vehiclesList).map((vehicleId, index) => <VehicleCard vehicle={vehiclesList[vehicleId]} index={index} isActive={activeVehicleId === vehicleId} />)}
                </Col>
                <Col md={9} lg={9} xl={9}>
                    <Row>
                        <Col>
                            <VehicleAlgoResultCard />
                        </Col>
                    </Row>
                    <Row style={{ height: '47vh' }}>
                        <VehicleOrdersTable vehicle={vehiclesList[activeVehicleId]} />
                    </Row>
                </Col>
            </Row>

        </div>
    )
}
export default PlanningPage