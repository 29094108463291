import { Dropdown, Form } from '@themesberg/react-bootstrap';
import { styled } from 'styled-components';

export const StyledCustomerNameAvatar = styled.span`
  height: 40px;
  width: 40px;
  font-size: 18px;
`;

export const StyledDropDownMenu = styled(Dropdown.Menu)`
  position: absolute;
  // inset: 0px 0px auto auto;
  // transform: translate3d(0px, 26px, 0px)
`;

export const StyledTD = styled.td`
  font-size: 10px !important;
  white-space: break-spaces;
`;

export const StyledSearchBar = styled(Form.Control)`
  width: ${(props) => props.width || '371px'};
  height: ${(props) => props.height || '38px'};
  border-radius: 3.79px;
  background-color: #eaedf2;
`;
