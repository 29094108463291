import Parse from 'parse';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { updateToastInfo } from '../../actions/settings';
import { updateSpinnerState } from '../../actions/spinner';
import ParcelStatusPage from '../../components/common/parcelStatusPages';
import { Routes } from '../../router/routes';
import ConfirmationModal from '../../components/common/confirmationModal';
import {
  getDMSUsersList,
  updateDMSUserInfo,
} from '../../parse-functions/users';
import {
  setDMSUserAsSelected,
  setDMSUsersListInStore,
} from '../../actions/dmsUsers';
import { returnDMSUserListTableColumns } from './inchargeUtils';
import InchargeUpdateModal from './inchargeUpdateModal';
import { getCurrentWarehouseInfo } from '../../parse-functions/warehouse';
import { setCurrentWarehouseInfoInStore } from '../../actions/user';
import { getSpecificUserPreferences } from '../../parse-functions/preferences';

const DMSUsersList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const dmsUsersList = useSelector((state) => state?.dmsUsers?.usersList || {});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showInchargeUpdateModal, setShowInchargeUpdateModal] = useState(false);
  const [editUserData, setEditUserData] = useState({});
  const otherWarehouses = useSelector(
    (state) => state?.user?.otherWarehousesInZone || {}
  );
  const isSuperAdmin = useSelector(
    (state) => state?.user?.preferences?.isSuperAdmin || false
  );
  const selectedUsers = Object.values(dmsUsersList)?.filter(
    (user) => user?.isSelected === true
  );
  const preferences = useSelector((state) => state?.user?.preferences || {});

  const breadCrumbItems = [
    {
      title: t('Users'),
      link: Routes.Incharge.List.path,
    },
    {
      title: t('List'),
      link: Routes.Incharge.List.path,
    },
  ];

  useEffect(() => {
    if (Object.keys(dmsUsersList)?.length < 1) {
      getAvailableDMSUsersList();
    }
    if (Object.keys(otherWarehouses)?.length < 1) {
      getWarehousesList();
    }
  }, [preferences]);

  const getWarehousesList = async () => {
    try {
      const warehouseInfo = await getCurrentWarehouseInfo({
        warehouseName: preferences?.storeName,
        isSuperAdmin,
      });
      dispatch(setCurrentWarehouseInfoInStore(warehouseInfo));
    } catch (e) {}
  };
  const getAvailableDMSUsersList = async () => {
    dispatch(updateSpinnerState(true));
    try {
      const dmsUsers = await getDMSUsersList();
      dispatch(setDMSUsersListInStore(dmsUsers));
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'success',
          title: t('DMS Users List fetched'),
          message: t('All the Users that use the DMS system'),
        })
      );
    } catch (e) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('Error'),
          message: t(e.message),
        })
      );
    }
  };

  const fetchUserPreferences = async () => {
    dispatch(updateSpinnerState(true));
    try {
      const preferences = await getSpecificUserPreferences({
        id: selectedUsers[0].userId,
      });
      setEditUserData({
        ...selectedUsers[0],
        applications: preferences.applications,
      });
      dispatch(updateSpinnerState(false));
    } catch (e) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('Error'),
          message: t(e.message),
        })
      );
    }
  };

  const onRefreshClick = () => {
    getAvailableDMSUsersList();
  };
  const onRowSelect = (selected, objectId) => {
    dispatch(setDMSUserAsSelected({ objectId, selected }));
  };
  const handleShowDeleteModal = () => {
    setShowDeleteModal(true);
  };
  const handleShowVehicleUpdateModal = () => {
    setShowInchargeUpdateModal(true);
  };
  const handleSelectedRowsUpdate = async (actionId) => {
    const isNonDMSSelected = selectedUsers?.filter(
      (user) => user?.type !== 'DMS User' && user?.type !== 'Security Guard'
    );
    if (selectedUsers?.length < 1) {
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('Loader Not Selected'),
          message: t(
            'Please select a loader from the list before applying operation'
          ),
        })
      );
      return;
    }
    if (isNonDMSSelected?.length > 0 && actionId === 'edit') {
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('Cant Edit CC User / Manager'),
          message: t(
            'Editing is not allowed for CC Users / Managers. You can only delete them'
          ),
        })
      );
      return;
    }
    if (selectedUsers?.length > 0) {
      if (selectedUsers?.length > 1 && actionId === 'edit') {
        dispatch(
          updateToastInfo({
            show: true,
            type: 'danger',
            title: t('Max 1 vehicle to select'),
            message: t('Please select maximum of 1 vehicle for editing'),
          })
        );
        return;
      }
      // we can do edit and delete here
      switch (actionId) {
        case 'delete': {
          handleShowDeleteModal();
          break;
        }
        case 'edit': {
          await fetchUserPreferences();
          handleShowVehicleUpdateModal();
          break;
        }
        default: {
          break;
        }
      }
    }
  };
  const handleOnConfirmClick = async (data) => {
    try {
      dispatch(updateSpinnerState(true));
      await updateDMSUserInfo({ data });
      getAvailableDMSUsersList();
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'success',
          title: 'User Update Successful',
          message: '',
        })
      );
      setShowDeleteModal(false);
      setShowInchargeUpdateModal(false);
    } catch (e) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: 'Vehicle Update Failed',
          message: 'Please contact support for help',
        })
      );
    }
  };
  const handleModalOnConfirmClick = () => {
    const userItemData = [];
    // eslint-disable-next-line no-unused-expressions
    selectedUsers?.forEach((userItem) => {
      const data = {};
      data.objectId = userItem.objectId;
      data.isDeleted = true;
      data.className = userItem.className;
      data.dataFields = {
        status: 'deleted',
        actionLog: {
          action: 'deleted',
          user: Parse.User.current().getUsername(),
          time: new Date().getTime(),
        },
      };
      userItemData.push(data);
    });
    handleOnConfirmClick(userItemData);
  };
  const handleInchargeUpdateInfo = (vehicleObject) => {
    const inchargeUpdateData = [
      {
        objectId: vehicleObject.objectId,
        isDeleted: false,
        className: vehicleObject.className,
        userId: vehicleObject.userId,
        applications: vehicleObject.applications,
        dataFields: {
          phoneNumber: vehicleObject?.phoneNumber?.toString(),
          name: vehicleObject.name,
          emailId: vehicleObject.emailId,
          storeName: Array.isArray(vehicleObject.storeName)
            ? vehicleObject.storeName[0]
            : vehicleObject.storeName,
          actionLog: {
            action: 'updated',
            user: Parse.User.current().getUsername(),
            time: new Date().getTime(),
          },
        },
      },
    ];
    handleOnConfirmClick(inchargeUpdateData);
  };

  return (
    <>
      <ParcelStatusPage
        pageType={'inchargeList'}
        showDateFilters={false}
        tableData={dmsUsersList}
        tableColumns={returnDMSUserListTableColumns(onRowSelect)}
        handleRefresh={onRefreshClick}
        pageTitle={t('DMS User List')}
        breadCrumbItems={breadCrumbItems}
        pageSubTitle={t(
          'All the Users who use the Platform apart from the Vehicles'
        )}
        searchPlaceHolder={t('Search By Name, Warehouse etc')}
        enableBulkActions={selectedUsers?.length > 0}
        bulkActionOptions={[
          {
            label: 'Edit',
            value: 'edit',
          },
          {
            label: 'Delete / Remove',
            value: 'delete',
          },
        ]}
        handleItemBulkUpdate={handleSelectedRowsUpdate}
      />
      <ConfirmationModal
        showModal={showDeleteModal}
        onConfirm={handleModalOnConfirmClick}
        closeModal={() => {
          setShowDeleteModal(false);
        }}
        modalBody={t('Are you sure you want to delete these users')}
        modalTitle={t('Delete Users')}
      />
      <InchargeUpdateModal
        closeModal={() => {
          setShowInchargeUpdateModal(false);
        }}
        user={editUserData}
        showModal={showInchargeUpdateModal}
        onConfirm={handleInchargeUpdateInfo}
        isSecurityGuard={editUserData?.type === 'Security Guard'}
      />
    </>
  );
};
export default DMSUsersList;
