import React from "react";
import OrderPage from "./components/OrderPage";
import { orderStatus } from "../../../constants/orderStatus";

const PickedUp = () => {
  return (
    <div>
      <OrderPage type={orderStatus.pickedUp} />
    </div>
  );
};

export default PickedUp;
