import React from "react";
import WarehouseTargetPage from "../purchase/assigned";
import { Routes } from "../../../router/routes";
import { useTranslation } from "react-i18next";

const AssignedTargets = () => {
    const { t } = useTranslation();
    const status = "assigned";
    const breadCrumbItems = [{
        title: t("WMS"),
        link: Routes.WMS.Target.Assign
    }, {
        title: t("Targets"),
        link: Routes.WMS.Target.Assign
    }, {
        title: t(status)
    }]
    return (
        <WarehouseTargetPage
            pageName={"assignedTargets"}
            status={status}
            bulkActionOptions={[{
                label: "Reassign",
                value: "reassign"
            }, {
                label: "Cancel",
                value: "cancel"
            }]}
            pageTitle={"Assigned Targets"}
            pageSubTitle={"All the Scan target Assigned Items are listed here."}
            breadCrumbItems={breadCrumbItems}
        />
    )
}
export default AssignedTargets