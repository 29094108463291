import { faClock } from '@fortawesome/free-regular-svg-icons';
import { faStopwatch, faStopwatch20 } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState, useRef } from 'react';

const CountdownTimer = (props) => {
  const { hours, mins, callback } = props;
  const [time, setTime] = useState({});
  const idRef = useRef(null);

  useEffect(() => {
    let secondsRemaining = hours * 3600 + mins * 60;

    const updateTimer = () => {
      const updatedHours = Math.floor(secondsRemaining / 3600);
      const updatedMins = Math.floor((secondsRemaining % 3600) / 60);
      const updatedSecs = secondsRemaining % 60;

      setTime({ hours: updatedHours, mins: updatedMins, secs: updatedSecs });

      if (secondsRemaining === 0) {
        clearInterval(idRef.current);
        callback();
      } else {
        secondsRemaining -= 1;
      }
    };

    // console.log(time)
    updateTimer();
    idRef.current = setInterval(updateTimer, 1000);

    return () => clearInterval(idRef.current);
  }, [hours, mins, callback]);

  const formatNumber = (num) => (num < 10 ? `0${num}` : num);

  const timeLeft = time.hours * 60 + time.mins;

  return (
    <div
      style={{
        marginTop: '-5px',
        marginBottom: '-5px',
        width: 'fit-content',
        height: '40px',
        padding: '10px',

        backgroundColor:
          timeLeft <= 15
            ? '#FDDDDD'
            : timeLeft > 15 && timeLeft < 30
            ? '#FDEDD9'
            : '#D4F7E8',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          color:
            timeLeft <= 15
              ? '#F13131'
              : timeLeft > 15 && timeLeft < 30
              ? '#F4921B'
              : '#21A871',
          fontSize: '16px',
          fontWeight: '500',
        }}
        className="fw-bold"
      >
        <FontAwesomeIcon icon={faStopwatch} />
        <span className="ms-2">{`${formatNumber(time.hours)} : ${formatNumber(
          time.mins
        )} : ${formatNumber(time.secs)}`}</span>
      </div>
    </div>
  );
};

export default CountdownTimer;
