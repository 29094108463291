import {
  SET_CURRENT_USER_IN_STATE,
  SET_USER_PREFERENCES_IN_STORE,
  SET_APPLICATION_CONFIG_IN_STORE,
  SET_CURRENT_WAREHOUSE_INFO_IN_STORE,
  SET_CURRENT_USER_INFO_IN_STATE,
  RESET_STORE,
  SET_CURRENT_USER_PERMISSIONS_IN_STORE,
} from './types';

export const setCurrentUserInState = (user) => {
  return {
    type: SET_CURRENT_USER_IN_STATE,
    data: user,
  };
};
export const setApplicationConfigInState = (config) => {
  return {
    type: SET_APPLICATION_CONFIG_IN_STORE,
    data: config,
  };
};
export const setUserPreferencesInStore = (data) => {
  return {
    type: SET_USER_PREFERENCES_IN_STORE,
    data,
  };
};
export const setCurrentWarehouseInfoInStore = (data) => {
  return {
    type: SET_CURRENT_WAREHOUSE_INFO_IN_STORE,
    data,
  };
};
export const setCurrentUserInfoInState = (data) => {
  return {
    type: SET_CURRENT_USER_INFO_IN_STATE,
    data,
  };
};

export const setCurrentUserPermissionsInStore = (data) => {
  return {
    type: SET_CURRENT_USER_PERMISSIONS_IN_STORE,
    data,
  };
};
export const resetStore = () => {
  return {
    type: RESET_STORE,
  };
};
