import React, { useEffect, useState } from 'react';
import InventoryPages from '../inventory/components/InventoryPages';
import { apiCall } from '../../../middlewares/api';
import { serviceablityDetailsColumns } from './utils';
import arrayToBreadCrumbs from '../../../utils/sms/arrayToBreadCrumbs';
import { useDispatch } from 'react-redux';
import { updateToastInfo } from '../../../actions/settings';
import { useTranslation } from 'react-i18next';
import { updateSpinnerState } from '../../../actions/spinner';
import { useParams } from 'react-router-dom';
import { apiCallConsts } from '../../../constants/apiCallConsts';

const TestServiceability = () => {
  const { t } = useTranslation();
  const [data, setData] = useState({});
  const dispatch = useDispatch();
  const { id } = useParams();
  const vanNo = id.substring(0, 6);
  const pincode = id.substring(6);

  const breadCrubItems = arrayToBreadCrumbs([
    ['serviceability', '/oms/serviceability/test'],
    [vanNo],
  ]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(updateSpinnerState(true));
        const res = await apiCall(
          apiCallConsts.GET_METHOD,
          '/internal/serviceability',
          { vanNo: vanNo, pincode: pincode }
        );
        setData(res?.data);
        dispatch(updateSpinnerState(false));
      } catch (error) {
        dispatch(updateSpinnerState(false));
        dispatch(
          updateToastInfo({
            show: true,
            type: 'danger',
            title: t('Failed To Fetch data'),
            message: t(error.message),
          })
        );
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <InventoryPages
        title={t('Serviceablity Details')}
        // subTitle={t('Enter the pincode and van number to test serviceability')}
        data={data[vanNo]?.storePickupList || []}
        storesListTableColumns={serviceablityDetailsColumns()}
        breadCrumbItems={breadCrubItems}
        hideDescription={false}
        descriptionData={
          Object.keys(data).length > 0 ? data[parseInt(vanNo)] : {}
        }
        enableBulkActions={false}
        hideBulkActions={true}
        hideFetchButton={true}
      />
    </>
  );
};

export default TestServiceability;
