import { faHome, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Alert,
  Breadcrumb,
  Button,
  Col,
  Form,
  Row,
} from '@themesberg/react-bootstrap';
import Joi from 'joi';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { updateToastInfo } from '../../actions/settings';
import { updateSpinnerState } from '../../actions/spinner';
import { addNewWarehouse } from '../../parse-functions/warehouse';
import { returnValidationErrorMessages } from '../../utils/validation';
import TimePicker from '../../components/common/TimePicker';
import ToggleSwitch from '../../components/common/ToggleSwitch';
import EditableDropDown from '../../components/common/EditableDropdown';
import { erpOptions } from './utils/erpOptions';

const addWarehouseSchema = Joi.object({
  warehouseName: Joi.string().required(),
  warehouseCode: Joi.string().required(),
  shortCode: Joi.string().length(3).required(),
  address: Joi.string().required(),
  pincode: Joi.number().min(100001).max(999999).required(),
  zone: Joi.string().required(),
  startTime: Joi.string()
    .regex(/^([01]\d|2[0-3]):([0-5]\d)$/)
    .required(),
  endTime: Joi.string()
    .regex(/^([01]\d|2[0-3]):([0-5]\d)$/)
    .required(),
  customerPickupAllowed: Joi.boolean().required(),
  courrierPickupAllowed: Joi.boolean().required(),
  deliveryAllowed: Joi.boolean().required(),
  storeEnabled: Joi.boolean().required(),
  onBoardedOn: Joi.string().required(),
  latitude: Joi.number().required().min(-90).max(90),
  longitude: Joi.number().required().min(-180).max(180),
  username: Joi.string().required(),
  password: Joi.string().required(),
  repeatPassword: Joi.ref('password'),
});
const AddWarehouse = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const configData = useSelector((state) => state.user.config || {});
  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState({
    warehouseName: '',
    warehouseCode: '',
    shortCode: '',
    address: '',
    pincode: null,
    zone: '',
    startTime: '',
    endTime: '',
    customerPickupAllowed: false,
    courrierPickupAllowed: false,
    deliveryAllowed: false,
    storeEnabled: false,
    onBoardedOn: '',
    latitude: null,
    longitude: null,
    username: '',
    password: '',
    repeatPassword: '',
  });

  const onFormInputChange = (fieldName, value) => {
    setFormData((oldFormData) => ({
      ...oldFormData,
      [fieldName]: value,
    }));
    setFormErrors((prev) => ({
      ...prev,
      [fieldName]: null,
    }));
  };
  const zoneDropDownOptions = () => {
    const { zones = {} } = configData;
    const returnVal = [];
    returnVal.push(<option>{t('Please select a zone')}</option>);
    Object.keys(zones).forEach((zoneName) => {
      returnVal.push(<option>{t(zoneName)}</option>);
    });
    return returnVal;
  };
  const handleFormSubmit = async () => {
    const { error: formErrors = {}, value: formValue } =
      addWarehouseSchema.validate(formData, {
        abortEarly: false,
      });
    const { details = {} } = formErrors;
    if (details?.length > 0) {
      const validationErrorMessages = returnValidationErrorMessages(details);
      setFormErrors(validationErrorMessages);
    } else {
      dispatch(updateSpinnerState(true));
      try {
        let config = configData.zones[formValue.zone];
        formValue.configData = config;
        const savedStore = await addNewWarehouse(formValue);
        dispatch(
          updateToastInfo({
            show: true,
            type: 'success',
            title: t('Warehouse Added'),
            message: t(`${formValue.warehouseCode} added successfully`),
          })
        );
      } catch (error) {
        // inside error
        const { message, code } = error;
        setFormErrors(message);
        dispatch(
          updateToastInfo({
            show: true,
            type: 'danger',
            title: t('Warehouse Add Failed'),
            message: t(`${formValue.warehouseCode} failed to add`),
          })
        );
      }
      dispatch(updateSpinnerState(false));
    }
  };
  return (
    <div className="mt-1">
      <div className="mb-4 d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-2">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: 'breadcrumb-dark breadcrumb-transparent' }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item>{t('Warehouse')}</Breadcrumb.Item>
            <Breadcrumb.Item active>{t('Add')}</Breadcrumb.Item>
          </Breadcrumb>
          <h4>{t('Add New Warehouse')}</h4>
          <p className="mb-0">{t('Use this page to add a new warehouse')}</p>
        </div>
      </div>

      <Row className="mt-3">
        <Col>
          <Alert variant="info">
            <FontAwesomeIcon icon={faHome} />
            &nbsp;&nbsp;{t('Warehouse Info')}
          </Alert>
          <Form.Group className="mb-3 mt-4">
            <Form.Label>{t('Warehouse Name')}</Form.Label>
            <Form.Control
              required
              isInvalid={formErrors['warehouseName']?.length}
              type="text"
              onChange={(event) => {
                onFormInputChange('warehouseName', event.target.value);
              }}
            />
            {formErrors['warehouseName']?.length && (
              <Form.Control.Feedback type="invalid">
                {t('Please enter warehouse name.')}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>{t('Warehouse Code')}</Form.Label>
                <Form.Control
                  required
                  isInvalid={formErrors['warehouseCode']?.length}
                  type="text"
                  onChange={(event) => {
                    onFormInputChange('warehouseCode', event.target.value);
                  }}
                />
                {formErrors['warehouseCode']?.length && (
                  <Form.Control.Feedback type="invalid">
                    {t('Please enter warehouse code.')}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>{t('Warehouse Short Code')}</Form.Label>
                <Form.Control
                  required
                  isInvalid={formErrors['shortCode']?.length}
                  type="text"
                  onChange={(event) => {
                    onFormInputChange('shortCode', event.target.value);
                  }}
                />
                {formErrors['shortCode']?.length && (
                  <Form.Control.Feedback type="invalid">
                    {t(formErrors['shortCode'])}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Form.Group className="mb-3">
            <Form.Label>{t('Address')}</Form.Label>
            <Form.Control
              required
              isInvalid={formErrors['address']?.length}
              type="text"
              onChange={(event) => {
                onFormInputChange('address', event.target.value);
              }}
            />
            {formErrors['address']?.length && (
              <Form.Control.Feedback type="invalid">
                {t('Please enter address.')}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>{t('Pincode')}</Form.Label>
                <Form.Control
                  required
                  isInvalid={formErrors['pincode']?.length}
                  type="number"
                  onChange={(event) => {
                    onFormInputChange('pincode', event.target.value);
                  }}
                />
                {formErrors['pincode']?.length && (
                  <Form.Control.Feedback type="invalid">
                    {t('Please enter pincode.')}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>{t('Zone')}</Form.Label>
                <Form.Select
                  required
                  isInvalid={formErrors['zone']?.length}
                  onChange={(event) => {
                    onFormInputChange('zone', event.target.value);
                  }}
                >
                  {zoneDropDownOptions()}
                </Form.Select>
                {formErrors['zone']?.length && (
                  <Form.Control.Feedback type="invalid">
                    {t('Please choose a zone')}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
          </Row>
          {/* need to add timings component here */}
          <TimePicker
            formErrors={formErrors}
            onFormInputChange={onFormInputChange}
          />
          <Row>
            <Col>
              <ToggleSwitch
                title="Customer Pickup Allowed"
                fieldName={'customerPickupAllowed'}
                formErrors={formErrors}
                onFormInputChange={onFormInputChange}
              />
            </Col>
            <Col>
              <ToggleSwitch
                title="Courrier Pickup Allowed"
                fieldName={'courrierPickupAllowed'}
                formErrors={formErrors}
                onFormInputChange={onFormInputChange}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <ToggleSwitch
                title="HyperLocal Allowed"
                fieldName={'deliveryAllowed'}
                formErrors={formErrors}
                onFormInputChange={onFormInputChange}
              />
            </Col>
            <Col>
              <ToggleSwitch
                title="Store Enabled"
                fieldName={'storeEnabled'}
                formErrors={formErrors}
                onFormInputChange={onFormInputChange}
              />
            </Col>
          </Row>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>{t('OnBoarded On')}</Form.Label>
              <EditableDropDown
                value={formData?.onBoardedOn}
                onChange={(option) => {
                  onFormInputChange('onBoardedOn', option.value);
                }}
                options={erpOptions?.map((option) => ({
                  value: option.value,
                  label: t(option.value),
                }))}
                placeholder={t('please choose a ERP')}
              />
              {formErrors['onBoardedOn']?.length && (
                <Form.Control.Feedback type="invalid">
                  {t('Please choose a ERP')}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Col>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>{t('Latitude')}</Form.Label>
                <Form.Control
                  required
                  isInvalid={formErrors['latitude']?.length}
                  type="number"
                  onChange={(event) => {
                    onFormInputChange('latitude', event.target.value);
                  }}
                />
                {formErrors['latitude']?.length && (
                  <Form.Control.Feedback type="invalid">
                    {t('Please enter latitude.')}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>{t('Longitude')}</Form.Label>
                <Form.Control
                  required
                  isInvalid={formErrors['longitude']?.length}
                  type="number"
                  onChange={(event) => {
                    onFormInputChange('longitude', event.target.value);
                  }}
                />
                {formErrors['longitude']?.length && (
                  <Form.Control.Feedback type="invalid">
                    {t('Please enter longitude.')}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
          </Row>
        </Col>
        <Col>
          <Alert variant="info">
            <FontAwesomeIcon icon={faUserCircle} />
            &nbsp;&nbsp;{t('Login Info')}
          </Alert>
          <Form.Group className="mb-3 mt-4">
            <Form.Label>{t('UserName')}</Form.Label>
            <Form.Control
              required
              isInvalid={formErrors['username']?.length}
              type="text"
              onChange={(event) => {
                onFormInputChange('username', event.target.value);
              }}
            />
            {formErrors['username']?.length && (
              <Form.Control.Feedback type="invalid">
                {t('Please enter a username')}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>{t('Password')}</Form.Label>
            <Form.Control
              required
              isInvalid={formErrors['password']?.length}
              type="text"
              onChange={(event) => {
                onFormInputChange('password', event.target.value);
              }}
            />
            {formErrors['password']?.length && (
              <Form.Control.Feedback type="invalid">
                {t('Please enter a password')}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>{t('Repeat Password')}</Form.Label>
            <Form.Control
              required
              isInvalid={formErrors['repeatPassword']?.length}
              type="text"
              onChange={(event) => {
                onFormInputChange('repeatPassword', event.target.value);
              }}
            />
            {formErrors['repeatPassword']?.length && (
              <Form.Control.Feedback type="invalid">
                {t('Please enter the same password as above')}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Row className="d-flex justify-content-between mt-5">
            <Button
              style={{ width: '98%' }}
              className="mx-2"
              variant="primary"
              onClick={handleFormSubmit}
            >
              {t('Create User')}
            </Button>
            <Button className="mt-3" variant="outline">
              {t('Cancel')}
            </Button>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default AddWarehouse;
