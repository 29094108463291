export const isThisACOutdoor = (parcel) => {
  const isOutDoorOfACRegEx = new RegExp("O/DR+", "igm");
  return isOutDoorOfACRegEx.test(parcel.product);
};

export const returnRowBGColor = (status) => {
  switch (status) {
    case "delivered":
    case "completed":
    case "completion": {
      return "bg-trip-completed";
    }
    case "pending":
    case "Accepted":
    case "negotiation": {
      return "bg-trip-pending";
    }
    case "droppedathub": {
      return "bg-scan-marked";
    }
    case "failed":
    case "deleted":
    case "cancellation":
    case "Assigned":
    case "inactive": {
      return "bg-trip-deleted";
    }
    default: {
      return "";
    }
  }
};

export const showTagTypeInTrips = (row) => {
  let tagType = null;
  const {
    status,
    dashboardStatus,
    mobileStatus,
    dashboardSubStatus,
    mobileSubStatus,
    buybackReceivedAt,
    returnScannedAt,
  } = row;
  const statusPresent = dashboardStatus || mobileStatus || status;
  const subStatus = dashboardSubStatus || mobileSubStatus;
  if (statusPresent === "delivered") {
    if (
      ["buybackpicked", "buybackpickedcashcollected"].indexOf(subStatus) > -1 &&
      !buybackReceivedAt
    ) {
      tagType = "buyback";
    }
  }
  if (statusPresent === "failed") {
    if (
      ["replacementpicked", "defectivepicked", "returnok"].indexOf(subStatus) >
        -1 &&
      !returnScannedAt
    ) {
      tagType = "product";
    }
  }
  return tagType;
};

export const getAdditionalParcelInInvoiceToReschedule = (
  categoriesToCheck,
  allParcels,
  selectedParcel
) => {
  let sameInvoiceParcels = [];
  // we should have the same invoice number
  // item must have been received via field returnScannedAt
  // check if current is indoor or outdoor, Ask based on that
  // if (categoriesToCheck?.indexOf(selectedParcel?.productCategory) > -1) {
  sameInvoiceParcels = Object.values(allParcels)?.filter((parcel) => {
    return (
      parcel.invoiceNo === selectedParcel?.invoiceNo &&
      // parcel?.productCategory === selectedParcel?.productCategory &&
      parcel?.returnScannedAt &&
      parcel.objectId !== selectedParcel.objectId &&
      parcel?.dashboardStatus === selectedParcel?.dashboardStatus &&
      parcel?.dashboardSubStatus === selectedParcel?.dashboardSubStatus
    );
  });
  // }
  return sameInvoiceParcels;
};
