import React from 'react';
import { Modal, Button } from '@themesberg/react-bootstrap';
import { useTranslation } from 'react-i18next';
import AddCMUser from './AddUsers/AddCMUser';
import AddVendorUser from './AddUsers/AddVendorUser';
import AddAccountsUser from './AddUsers/AddAccountsUser';
import useGetCompanyAndCategory from '../custom-hooks/useGetCompanyAndCategory';

const UserEditModal = (props) => {
  const { t } = useTranslation();
  const { show, handleClose, topTitle, editData, handleSave } = props;
  const newEditData = {
    ...editData,
    category: editData?.productCategory?.includes('All')
      ? ['All']
      : editData?.productCategory,
    company: editData?.company?.includes('All') ? ['All'] : editData?.company,
  };
  useGetCompanyAndCategory();
  return (
    <Modal
      centered
      show={show}
      onHide={handleClose}
      className="modal-primary"
      size="xl"
    >
      <Modal.Header className="d-flex justify-content-between align-items-center p-3">
        <p className="lead mb-0">{t(topTitle)}</p>
        <Button
          variant="transparent"
          className="btn-close"
          onClick={handleClose}
        />
      </Modal.Header>
      <Modal.Body>
        {editData?.role === 'Brand Manager' ? (
          <AddCMUser editData={newEditData} handleSave={handleSave} />
        ) : editData?.role === 'Brand Vendor' ? (
          <AddVendorUser editData={newEditData} handleSave={handleSave} />
        ) : (
          <AddAccountsUser editData={editData} handleSave={handleSave} />
        )}
      </Modal.Body>
    </Modal>
  );
};
export default UserEditModal;
