import React from 'react';
import { Dropdown, Form } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faEllipsisV } from '@fortawesome/free-solid-svg-icons';

const ActionButton = ({ options, onActionClick }) => {
  return (
    <Dropdown>
      <Dropdown.Toggle
        id="dropdown-custom-components"
        variant="light"
        className="shadow-none border-0"
      >
        <FontAwesomeIcon icon={faEllipsisV} />
      </Dropdown.Toggle>

      <Dropdown.Menu align={'start'} className="dropdown-menu-top-left">
        {options?.map((option) => (
          <Dropdown.Item key={option} onClick={() => onActionClick(option)}>
            {option}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ActionButton;
