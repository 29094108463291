import {
    SET_TEMPOSHEET_RECEIVE_PARCELS_IN_STORE,
    SET_SELECTED_PARCEL_STATE_WHILE_RECEIVING,
    SET_CURRENT_RECEIVE_TEMPOSHEET_PARCELS_AS_RECEIVED
} from "./types"

export const setTemposheetReceiveParcelsInStore = (data) => {
    return {
        type: SET_TEMPOSHEET_RECEIVE_PARCELS_IN_STORE,
        data
    }
}
export const setSelectedParelStateInStore = (data) => {
    return {
        type: SET_SELECTED_PARCEL_STATE_WHILE_RECEIVING,
        data
    }
}
export const setCurrentReceiveTemposheetParcelsAsReceived = (data) => {
    return {
        type: SET_CURRENT_RECEIVE_TEMPOSHEET_PARCELS_AS_RECEIVED,
        data
    }
}