import React from "react";
import { getTableRowCheckBox, returnBuybackReceivedInformation, returnBuybackScanInformation, returnCustomerAddressString, returnParcelBranchInfo, returnParcelProductInformation, returnParcelReturnScanInformation, returnParcelStatusInfo, returnParcelVehicleInformation, returnParcelVehicleTripInfo } from "../../utils/table";
import { isThisACOutdoor } from "../../utils/parcels";
import { returnUserReadableDateTime } from "../../utils/datetime";

export const defectiveReceivedTableColumns = (onRowSelect) => {
    return ([
        {
            dataField: "objectId", text: "Select", formatter: (cell, row) => {
                const isDisabled = row?.receivedAt || row?.receivedBy;
                return getTableRowCheckBox(onRowSelect, cell, row?.isSelected, isDisabled)
            }
        },
        {
            dataField: "customerName", sort: true, text: "Customer Info", formatter: (cell, row) => {
                return (
                    <div className="d-block">
                        <span className="fw-bold">{cell}</span>
                        <div className="fw-normal">{row?.mobileNo}</div>
                    </div>
                )
            }
        },
        {
            dataField: "invoiceNo", text: "Invoice Details", formatter: (cell, row) => {
                return (
                    <div className="d-block">
                        <span className="fw-bold">{cell}</span>
                        <div className="fw-normal">{new Date(row?.date).toLocaleDateString()}</div>
                    </div>
                )
            }
        },
        {
            dataField: "serialNo", text: "Product Info", formatter: (cell, row) => {
                return returnParcelProductInformation(row);
            }
        },
        {
            dataField: "receivedAt", text: "Receiver Info", formatter: (cell, row) => {
                return (
                    <div className="d-block">
                        <div className="fw-normal">{returnUserReadableDateTime(row?.receivedAt)}</div>
                        <span className="fw-bold">{row?.receivedBy}</span>
                    </div>
                )
            }
        },
        {
            dataField: "status", text: "Current Status", formatter: (cell, row) => {
                return (
                    <div className="d-block">
                        <div className="fw-bold">{row?.targetBranch}</div>
                        <div className="fw-normal">{`${row?.dashboardStatus} (${row?.dashboardSubStatus})`}</div>
                    </div>
                )
            }
        },
        {
            dataField: "tripInfo.vehicleNumber", text: "Trip Info", formatter: (cell, row) => {
                return returnParcelVehicleTripInfo(row);
            }
        }
    ])
}

export const buybackPendingTableColumns = (onRowSelect) => {
    return ([
        {
            dataField: "objectId", text: "Select", formatter: (cell, row) => {
                const isDisabled = isThisACOutdoor(row);
                return getTableRowCheckBox(onRowSelect, cell, row?.isSelected, isDisabled)
            }
        },
        {
            dataField: "customerName", sort: true, text: "Customer Info", formatter: (cell, row) => {
                return returnCustomerAddressString(cell, row?.mobileNo, 200, 30);
            }
        },
        {
            dataField: "mobileNo", sort: true, hidden: true ,text: "Customer Contact Number", formatter: (cell, row) => {
                return <span className="fw-bold">{cell}</span>
            }
        },
        {
            dataField: "shippingAddress", sort: true, text: "Customer Address", formatter: (cell, row) => {
                const customerAddress = `${row?.shippingAddress1}${row?.shippingAddress2}${row?.shippingAddress3}`;
                return returnCustomerAddressString(customerAddress, row?.shippingPincode, 200, 30);
            }
        },
        {
            dataField: "invoiceNo", text: "Invoice Details", formatter: (cell, row) => {
                return (
                    <div className="d-block">
                        <span className="fw-bold">{cell}</span>
                        <div className="fw-normal">{new Date(row?.date).toLocaleDateString()}</div>
                    </div>
                )
            }
        },
        {
            dataField: "serialNo", text: "Product Info", formatter: (cell, row) => {
                return returnParcelProductInformation(row);
            }
        },
        {
            dataField: "status", text: "status", formatter: (cell, row) => {
                return returnParcelStatusInfo(row);
            }
        },
        {
            dataField: "branch", text: "Branch Info", formatter: (cell, row) => {
                return returnParcelBranchInfo(row);
            }
        },
        {
            dataField: "tripInfo.vehicleNumber", text: "Trip Info", formatter: (cell, row) => {
                return returnParcelVehicleTripInfo(row);
            }
        }
    ])
}

export const buybackScheduledTableColumns = (onRowSelect) => {
    return ([
        {
            dataField: "objectId", text: "Select", formatter: (cell, row) => {
                const isDisabled = isThisACOutdoor(row);
                return getTableRowCheckBox(onRowSelect, cell, row?.isSelected, isDisabled)
            }
        },
        {
            dataField: "customerName", sort: true, text: "Customer Info", formatter: (cell, row) => {
                return returnCustomerAddressString(cell, row?.mobileNo, 200, 30);
            }
        },
        {
            dataField: "mobileNo", sort: true, hidden: true ,text: "Customer Contact Number", formatter: (cell, row) => {
                return <span className="fw-bold">{cell}</span>
            }
        },
        {
            dataField: "shippingAddress", sort: true, text: "Customer Address", formatter: (cell, row) => {
                const customerAddress = `${row?.shippingAddress1}${row?.shippingAddress2}${row?.shippingAddress3}`;
                return returnCustomerAddressString(customerAddress, row?.shippingPincode, 200, 30);
            }
        },
        {
            dataField: "invoiceNo", text: "Invoice Details", formatter: (cell, row) => {
                return (
                    <div className="d-block">
                        <span className="fw-bold">{cell}</span>
                        <div className="fw-normal">{new Date(row?.date).toLocaleDateString()}</div>
                    </div>
                )
            }
        },
        {
            dataField: "challanNumber", text: "Challan Details", formatter: (cell, row) => {
                return (
                    <div className="d-block">
                        <span className="fw-bold">{cell}</span>
                        <div className="fw-normal">{row?.estimatedDeliveryDate ? new Date(row?.estimatedDeliveryDate).toLocaleDateString(): "-"}</div>
                    </div>
                )
            }
        },
        {
            dataField: "serialNo", text: "Product Info", formatter: (cell, row) => {
                return returnParcelProductInformation(row);
            }
        },
        {
            dataField: "status", text: "Target & Remarks", formatter: (cell, row) => {
                return (
                    <div className="d-block">
                        <div className="fw-bold">{row?.targetBranch}</div>
                        <div className="fw-normal">{`${row?.remarks}`}</div>
                    </div>
                )
            }
        },
        {
            dataField: "tripInfo.vehicleNumber", text: "Trip Info", formatter: (cell, row) => {
                return returnParcelVehicleTripInfo(row);
            }
        }
    ])
}

export const buybackCashRegisterTableColumns = (onRowSelect) => {
    return ([
        {
            dataField: "customerName", sort: true, text: "Customer Info", formatter: (cell, row) => {
                return (
                    <div className="d-block">
                        <span className="fw-bold">{cell}</span>
                        <div className="fw-normal">{row?.mobileNo}</div>
                    </div>
                )
            }
        },
        {
            dataField: "mobileNo", sort: true, hidden: true ,text: "Customer Contact Number", formatter: (cell, row) => {
                return <span className="fw-bold">{cell}</span>
            }
        },
        {
            dataField: "invoiceNo", text: "Invoice Details", formatter: (cell, row) => {
                return (
                    <div className="d-block">
                        <span className="fw-bold">{cell}</span>
                        <div className="fw-normal">{new Date(row?.date).toLocaleDateString()}</div>
                    </div>
                )
            }
        },
        {
            dataField: "cashCollected", text: "Cash & Remarks", formatter: (cell, row) => {
                return (
                    <div className="d-block">
                        <div className="fw-bold">
                            <span>{row?.cashCollected ? `INR ${row?.cashCollected}`: ""}</span>
                        </div>
                        <span className="fw-normal">{row?.remarks}</span>
                    </div>
                )
            }
        },
        {
            dataField: "serialNo", text: "Product Info", formatter: (cell, row) => {
                return returnParcelProductInformation(row);
            }
        },
        {
            dataField: "status", text: "Target & Status", formatter: (cell, row) => {
                return returnParcelStatusInfo(row);
            }
        },
        {
            dataField: "returnScannedAt", text: "Return Scanned", formatter: (cell, row) => {
                return returnParcelReturnScanInformation(row);
            }
        },
        {
            dataField: "buybackScannedAt", text: "Buyback Received", formatter: (cell, row) => {
                return returnBuybackScanInformation(row);
            }
        },
        {
            dataField: "tripInfo.vehicleNumber", text: "Trip Info", formatter: (cell, row) => {
                return returnParcelVehicleTripInfo(row);
            }
        }
    ])
}


export const buybackReceivedTableColumns = (onRowSelect, warehouseCode) => {
    return ([
        {
            dataField: "objectId", text: "Select", formatter: (cell, row) => {
                const isDisabled = row?.wingsTransferNo || row?.buybackReceivedWarehouse !== warehouseCode;
                return getTableRowCheckBox(onRowSelect, cell, row?.isSelected, isDisabled)
            }
        },
        {
            dataField: "customerName", sort: true, text: "Customer Info", formatter: (cell, row) => {
                return returnCustomerAddressString(cell, row?.mobileNo, 200, 30);
            }
        },
        {
            dataField: "mobileNo", sort: true, hidden: true ,text: "Customer Contact Number", formatter: (cell, row) => {
                return <span className="fw-bold">{cell}</span>
            }
        },
        {
            dataField: "shippingAddress", sort: true, text: "Customer Address", formatter: (cell, row) => {
                const customerAddress = `${row?.shippingAddress1}${row?.shippingAddress2}${row?.shippingAddress3}`;
                return returnCustomerAddressString(customerAddress, row?.shippingPincode, 200, 30);
            }
        },
        {
            dataField: "invoiceNo", text: "Invoice Details", formatter: (cell, row) => {
                return (
                    <div className="d-block">
                        <span className="fw-bold">{cell}</span>
                        <div className="fw-normal">{new Date(row?.date).toLocaleDateString()}</div>
                    </div>
                )
            }
        },
        {
            dataField: "serialNo", text: "Product Info", formatter: (cell, row) => {
                return returnParcelProductInformation(row);
            }
        },
        {
            dataField: "status", text: "Current Status", formatter: (cell, row) => {
                return returnParcelStatusInfo(row);
            }
        },
        {
            dataField: "buybackScannedAt", text: "Buyback Collected", formatter: (cell, row) => {
                return returnBuybackScanInformation(row);
            }
        },
        {
            dataField: "buybackReceivedBy", text: "Buyback Received", formatter: (cell, row) => {
                return returnBuybackReceivedInformation(row);
            }
        },
        {
            dataField: "tripInfo.vehicleNumber", text: "Trip Info", formatter: (cell, row) => {
                return returnParcelVehicleTripInfo(row);
            }
        }
    ])
}

export const buybackRegisterTableColumns = (onRowSelect) => {
    return ([
        {
            dataField: "objectId", text: "Select", formatter: (cell, row) => {
                const isDisabled = row?.buybackReceivedAt || isThisACOutdoor(row) || !row?.isRescheduled;
                return getTableRowCheckBox(onRowSelect, cell, row?.isSelected, isDisabled)
            }
        }, {
            dataField: "customerName", sort: true, text: "Customer Info", formatter: (cell, row) => {
                return returnCustomerAddressString(cell, row?.mobileNo, 200, 30);
            }
        },
        {
            dataField: "mobileNo", sort: true, hidden: true ,text: "Customer Contact Number", formatter: (cell, row) => {
                return <span className="fw-bold">{cell}</span>
            }
        },
        {
            dataField: "shippingAddress", sort: true, text: "Customer Address", formatter: (cell, row) => {
                const customerAddress = `${row?.shippingAddress1}${row?.shippingAddress2}${row?.shippingAddress3}`;
                return returnCustomerAddressString(customerAddress, row?.shippingPincode, 200, 30);
            }
        },
        {
            dataField: "invoiceNo", text: "Invoice Details", formatter: (cell, row) => {
                return (
                    <div className="d-block">
                        <span className="fw-bold">{cell}</span>
                        <div className="fw-normal">{new Date(row?.date).toLocaleDateString()}</div>
                    </div>
                )
            }
        },
        {
            dataField: "serialNo", text: "Product Info", formatter: (cell, row) => {
                return returnParcelProductInformation(row);
            }
        },
        {
            dataField: "status", text: "Current Status", formatter: (cell, row) => {
                return returnParcelStatusInfo(row);
            }
        },
        {
            dataField: "buybackScannedAt", text: "Buyback Collected", formatter: (cell, row) => {
                return returnBuybackScanInformation(row);
            }
        },
        {
            dataField: "buybackReceivedBy", text: "Buyback Received", formatter: (cell, row) => {
                return returnBuybackReceivedInformation(row);
            }
        },
        {
            dataField: "tripInfo.vehicleNumber", text: "Trip Info", formatter: (cell, row) => {
                return returnParcelVehicleTripInfo(row);
            }
        }
    ])
}