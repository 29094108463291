import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { updateToastInfo } from '../../../../actions/settings';
import { updateSpinnerState } from '../../../../actions/spinner';
import ParcelStatusPage from '../../../../components/common/parcelStatusPages';
import ConfirmationModal from '../../../../components/common/confirmationModal';
import arrayToBreadCrumbs from '../../../../utils/sms/arrayToBreadCrumbs';
import { schemeTableColumns } from '../../../../utils/sms/schemeTable';
import {
  getAllSchemes,
  updateSchemesStatus,
} from '../../../../parse-functions/sms/scheme';
import { useNavigate } from 'react-router-dom';
import SchemeInfoModal from './SchemeInfoModal';
import { infoTable } from '../../../../utils/sms/infoTable';
import { convertSchemeDataToTableData } from '../../../../utils/sms/convertProductsToDropdownOptions';
import DifferenceProductsModal from '../common/DifferenceProductsModal';
import BulkAddSchemeModal from '../BulkAddSchemeModal';
import { bulkAddSchemeColumns } from '../../../../utils/sms/BulkAddSchemeColumns';

const ActiveSchemes = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userName = useSelector((state) => state?.user?.user?.username || {});
  const isAdmin = useSelector(
    (state) => state?.user?.preferences?.isAdmin || false
  );
  const isBrandManager = useSelector(
    (state) => state?.user?.preferences?.isBrandManager || false
  );

  const isBrandVendor = useSelector(
    (state) => state?.user?.preferences?.isBrandVendor || false
  );

  const isAccountManager = useSelector(
    (state) => state?.user?.preferences?.isAccountManager || false
  );

  const role = isAdmin
    ? 'isAdmin'
    : isBrandManager
    ? 'isBrandManager'
    : isBrandVendor
    ? 'isBrandVendor'
    : isAccountManager
    ? 'isAccountManager'
    : 'Unknown';
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedSchemes, setSelectedSchemes] = useState({});
  const [updateMode, setUpdateMode] = useState('');
  const [schemeData, setSchemeData] = useState([]);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [infoData, setInfoData] = useState();
  const [confirmationModalProps, setConfirmationModalProps] = useState([]);
  const [newProducts, setNewProducts] = useState([]);
  const [showDifferenceModal, setShowDifferenceModal] = useState(false);
  const [updatedSchemeData, setUpdatedSchemeData] = useState([]);
  const [showProductsModal, setShowProductsModal] = useState(false);
  const [id, setId] = useState();

  const breadCrumbItems = arrayToBreadCrumbs([[t('Schemes')], [t('Active')]]);

  let bulkActionOptions;

  if (isBrandManager) {
    bulkActionOptions = [
      {
        label: 'View',
        value: 'view',
      },
      {
        label: 'Cancel',
        value: 'cancel',
      },
      {
        label: 'Complete',
        value: 'complete',
      },
    ];
  } else if (isBrandVendor) {
    bulkActionOptions = [
      {
        label: 'View',
        value: 'view',
      },
      {
        label: 'Request Cancellation',
        value: 'requestCancel',
      },
      {
        label: 'Request Completion',
        value: 'requestComplete',
      },
    ];
  } else if (isAccountManager) {
    bulkActionOptions = [
      {
        label: 'View',
        value: 'view',
      },
      {
        label: 'Close',
        value: 'close',
      },
    ];
  } else {
    bulkActionOptions = [
      {
        label: 'View',
        value: 'view',
      },
      {
        label: 'Cancel',
        value: 'cancel',
      },
      {
        label: 'Complete',
        value: 'complete',
      },
      {
        label: 'Close',
        value: 'close',
      },
    ];
  }

  const fetchActiveSchemes = async () => {
    try {
      dispatch(updateSpinnerState(true));
      const response = await getAllSchemes({
        status: ['ongoing', 'completed'],
        role: role,
      });
      setUpdatedSchemeData(response);
      setSchemeData(convertSchemeDataToTableData(response));
    } catch (error) {
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: 'Cannot fetch at the moment!',
          message: 'Please try again!',
        })
      );
    } finally {
      dispatch(updateSpinnerState(false));
    }
  };

  const onRefreshClick = () => {
    try {
      fetchActiveSchemes();
      setSelectedSchemes({});
      dispatch(
        updateToastInfo({
          show: true,
          type: 'success',
          title: 'Refreshed Schemes!',
          message: 'Schemes fetched successfully!',
        })
      );
    } catch (error) {
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: 'Error fetching Schemes!',
          message: error.message,
        })
      );
    }
  };
  const onRowSelect = (selected, objectId) => {
    setSelectedSchemes((prev) => {
      if (!selected) {
        const { [objectId]: _, ...newState } = prev;
        return newState;
      }
      return {
        ...prev,
        [objectId]: selected,
      };
    });
    setSchemeData((prev) =>
      prev.map((item) =>
        item.objectId === objectId ? { ...item, isSelected: selected } : item
      )
    );
  };

  const handleShowDeleteModal = () => {
    setShowDeleteModal(true);
  };

  const handleSelectedRowsUpdate = (actionId) => {
    if (Object.keys(selectedSchemes).length === 1) {
      switch (actionId) {
        case 'complete': {
          setUpdateMode('Complete');
          setConfirmationModalProps([
            {
              type: 'textArea',
              title: t('Remarks'),
              value: '',
            },
            {
              type: 'date',
              title: t('Completion Date'),
              value: '',
            },
          ]);
          handleShowDeleteModal();
          break;
        }
        case 'cancel': {
          setUpdateMode('Cancel');
          setConfirmationModalProps([
            {
              type: 'textArea',
              title: t('Remarks'),
              value: '',
            },
          ]);
          handleShowDeleteModal();
          break;
        }
        case 'requestCancel': {
          setUpdateMode('Request Cancel');
          handleShowDeleteModal();
          break;
        }
        case 'requestComplete': {
          setUpdateMode('Request Complete');
          handleShowDeleteModal();
          break;
        }
        case 'close': {
          setUpdateMode('Close');
          setConfirmationModalProps([
            {
              type: 'textArea',
              title: t('Remarks'),
              value: '',
            },
          ]);
          handleShowDeleteModal();
          break;
        }
        case 'view': {
          navigate(`/scheme/${Object.keys(selectedSchemes)[0]}/details`);
          break;
        }
        default: {
          break;
        }
      }
    } else {
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: 'Status cannot be updated!',
          message: 'Select a Maximum of 1 scheme',
        })
      );
    }
  };
  const handleOnConfirmClick = async (data) => {
    try {
      dispatch(updateSpinnerState(true));
      const result = await updateSchemesStatus(data);
      if (result[0].status === 'error') {
        setShowDifferenceModal(true);
        setNewProducts(result[0].difference);
        throw new Error(
          'There are new products that can be added to this scheme!'
        );
      }
      await fetchActiveSchemes();
      setId();
      setConfirmationModalProps([]);
      setSelectedSchemes({});

      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'success',
          title: `Status Update Successful`,
          message: '',
        })
      );
      setShowDeleteModal(false);
      setShowDifferenceModal(false);
    } catch (e) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: 'Status cannot be updated!',
          message: e.message,
        })
      );
    }
  };
  const handleModalOnConfirmClick = (data) => {
    const selectedSchemeData = {
      schemeId: Object.keys(selectedSchemes),
      userName: userName,
      status:
        updateMode === 'Cancel'
          ? 'cancelled'
          : updateMode === 'Complete'
          ? 'completed'
          : updateMode === 'Close'
          ? 'closed'
          : 'ongoing',
      actionType:
        updateMode === 'Request Cancel'
          ? 'cancellation'
          : updateMode === 'Request Complete'
          ? 'completion'
          : '',
      reason: confirmationModalProps[0].value,
    };
    if (updateMode === 'Complete') {
      selectedSchemeData.completionDate = confirmationModalProps[1].value;
    }
    if (data) {
      selectedSchemeData.isChecked = true;
    }
    if (data?.length > 0) {
      selectedSchemeData.moreProducts = data;
    }
    handleOnConfirmClick(selectedSchemeData);
  };

  const handleInfoClick = (cell) => {
    setInfoData(cell);
    setShowInfoModal(true);
  };

  const handleMoreProductsClick = (id) => {
    setId(id);
    setShowProductsModal(true);
  };

  const handleSave = () => {
    let hasErrors = false;
    const data = newProducts.map((item) => {
      const updatedItem = { ...item, errors: {} };
      if (item.isSelected) {
        if (!item.payout) {
          updatedItem.errors.payout = 'Payout needs a value.';
          hasErrors = true;
        }
        if (!item.payoutBasis) {
          updatedItem.errors.payoutBasis = 'Payout Basis needs a value.';
          hasErrors = true;
        }
        if (!item.valuationType) {
          updatedItem.errors.valuationType = 'Valuation Type needs a value.';
          hasErrors = true;
        }
      }
      return updatedItem;
    });

    setNewProducts(data);
    if (!hasErrors) {
      handleModalOnConfirmClick(newProducts.filter((item) => item.isSelected));
    }
  };

  useEffect(() => {
    if (role !== 'Unknown') {
      fetchActiveSchemes();
    }
  }, [role]);

  return (
    <>
      <ParcelStatusPage
        pageType={'activeSchemes'}
        showDateFilters={false}
        tableData={schemeData}
        tableColumns={schemeTableColumns(
          onRowSelect,
          'active',
          navigate,
          handleInfoClick,
          handleMoreProductsClick
        )}
        handleRefresh={onRefreshClick}
        pageTitle={t('Active Schemes')}
        breadCrumbItems={breadCrumbItems}
        pageSubTitle={t('All the active and completed schemes')}
        enableBulkActions={Object.keys(selectedSchemes).length > 0}
        bulkActionOptions={bulkActionOptions}
        handleItemBulkUpdate={handleSelectedRowsUpdate}
      />
      <ConfirmationModal
        showModal={showDeleteModal}
        onConfirm={() => handleModalOnConfirmClick()}
        closeModal={() => {
          setShowDeleteModal(false);
          setConfirmationModalProps([]);
        }}
        modalBody={t(`Are you sure you want to ${updateMode?.toLowerCase()}?`)}
        modalTitle={t(`${updateMode} Scheme(s)`)}
        confirmationModalProps={confirmationModalProps}
        setConfirmationModalProps={setConfirmationModalProps}
      />
      <SchemeInfoModal
        showModal={showInfoModal}
        closeModal={() => setShowInfoModal(false)}
        data={infoData}
        columns={infoTable()}
      />
      <DifferenceProductsModal
        showModal={newProducts.length > 0 && showDifferenceModal}
        closeModal={() => setShowDifferenceModal(false)}
        data={newProducts}
        setNewProducts={setNewProducts}
        handleSave={handleSave}
      />
      {id && (
        <BulkAddSchemeModal
          data={
            updatedSchemeData?.filter((item) => item.objectId === id)[0]
              ?.products || []
          }
          showModal={showProductsModal}
          closeModal={() => setShowProductsModal(false)}
          columns={bulkAddSchemeColumns()}
        />
      )}
    </>
  );
};
export default ActiveSchemes;
