import React from "react"
import { returnUserReadableDate, returnUserReadableDateTime } from "../../utils/datetime"
import { getTableRowCheckBox, returnCustomerAddressString, returnParcelProductInformation, returnParcelRescheduleInformation, returnParcelReturnScanInformation, returnParcelStatusInformation, returnParcelVehicleTripInfo } from "../../utils/table"
import { orderStatusMap } from "../../utils/constants"

export const returnOKTableColumns = (onRowSelect) => {
    return ([
        {
            dataField: "objectId", text: "Select", formatter: (cell, row) => {
                return getTableRowCheckBox(onRowSelect, cell, row?.isSelected, row?.isDisabled || !row?.returnScannedAt)
            }
        },
        {
            dataField: "customerName", sort: true, text: "Customer Info", formatter: (cell, row) => {
                return (
                    <div className="d-block">
                        <span className="fw-bold">{cell}</span>
                        <div className="fw-normal">{row?.mobileNo}</div>
                    </div>
                )
            }
        },
        {
            dataField: "customerAddress", sort: true, text: "Address", formatter: (cell, row) => {
                const customerAddress = `${row?.shippingAddress1 || ""} ${row?.shippingAddress2 || ""} ${row?.shippingAddress3 || ""}`;
                return returnCustomerAddressString(customerAddress, row.mobileNo || row.shippingMobileNo, 300, 35)
            }

        },
        {
            dataField: "invoiceNo", text: "Invoice Details", formatter: (cell, row) => {
                return (
                    <div className="d-block">
                        <span className="fw-bold">{cell}</span>
                        <div className="fw-normal">{new Date(row?.date).toLocaleDateString()}</div>
                    </div>
                )
            }
        },
        {
            dataField: "serialNo", text: "Product Info", formatter: (cell, row) => {
                return returnParcelProductInformation(row);
            }
        },
        {
            dataField: "closedAt", text: "Return Scan Info", formatter: (cell, row) => {
                return returnParcelReturnScanInformation(row);
            }
        },
        {
            dataField: "status", text: "Current Status", formatter: (cell, row) => {
                return (
                    <div className="d-block">
                        <div className="fw-bold">{`${orderStatusMap[row?.dashboardStatus || row?.mobileStatus]} (${orderStatusMap[row?.dashboardSubStatus]})`}</div>
                        <span className="fw-normal">{orderStatusMap[row?.failureReason]}</span>
                    </div>
                )
            }
        },
        {
            dataField: "tripInfo.vehicleNumber", text: "Trip Info", formatter: (cell, row) => {
                return returnParcelVehicleTripInfo(row);
            }
        }
    ])
}

export const returnRegisterTableColumns = (onRowSelect) => {
    return ([
        {
            dataField: "objectId", text: "Select", formatter: (cell, row) => {
                return getTableRowCheckBox(onRowSelect, cell, row?.isSelected, row?.isDisabled)
            }
        },
        {
            dataField: "customerName", sort: true, text: "Customer Info", formatter: (cell, row) => {
                return (
                    <div className="d-block">
                        <span className="fw-bold">{cell}</span>
                        <div className="fw-normal">{row?.mobileNo}</div>
                    </div>
                )
            }
        },
        {
            dataField: "customerAddress", sort: true, text: "Address", formatter: (cell, row) => {
                const customerAddress = `${row?.shippingAddress1 || ""} ${row?.shippingAddress2 || ""} ${row?.shippingAddress3 || ""}`;
                return returnCustomerAddressString(customerAddress, row.mobileNo || row.shippingMobileNo, 300, 35)
            }

        },
        {
            dataField: "invoiceNo", text: "Invoice Details", formatter: (cell, row) => {
                return (
                    <div className="d-block">
                        <span className="fw-bold">{cell}</span>
                        <div className="fw-normal">{new Date(row?.date).toLocaleDateString()}</div>
                    </div>
                )
            }
        },
        {
            dataField: "serialNo", text: "Product Info", formatter: (cell, row) => {
                return returnParcelProductInformation(row);
            }
        },
        {
            dataField: "tripInfo.vehicleNumber", text: "Trip Info", formatter: (cell, row) => {
                return returnParcelVehicleTripInfo(row);
            }
        },
        {
            dataField: "closedAt", text: "Return Scan Info", formatter: (cell, row) => {
                return returnParcelReturnScanInformation(row);
            }
        },
        {
            dataField: "status", text: "Current Status", formatter: (cell, row) => {
                return returnParcelStatusInformation(row);
            }
        },
        {
            dataField: "returnInfo", text: "Received Info", formatter: (cell, row) => {
                return returnParcelReturnScanInformation(row);
            }
        },
        {
            dataField: "rescheduleInfo", text: "Rescheduled Info", formatter: (cell, row) => {
                return returnParcelRescheduleInformation(row);
            }
        },
        {
            dataField: "estimatedDeliveryDate", text: "New Date", formatter: (cell, row) => {
                return (
                    <div className="d-block">
                        <div className="fw-bold d-flex row">
                            <span className="fw-bold">{row?.estimatedDeliveryDate ? returnUserReadableDate(row?.estimatedDeliveryDate) : "-"}</span>
                            <span className="fw-normal">{row?.remarks}</span>
                        </div>

                    </div>
                )
            }
        }
    ])
}

export const returnScheduledTableColumns = () => {
    return ([
        {
            dataField: "customerName", sort: true, text: "Customer Info", formatter: (cell, row) => {
                return (
                    <div className="d-block">
                        <span className="fw-bold">{cell}</span>
                        <div className="fw-normal">{row?.mobileNo}</div>
                    </div>
                )
            }
        },
        {
            dataField: "customerAddress", sort: true, text: "Address", formatter: (cell, row) => {
                const customerAddress = `${row?.shippingAddress1 || ""} ${row?.shippingAddress2 || ""} ${row?.shippingAddress3 || ""}`;
                return returnCustomerAddressString(customerAddress, row.mobileNo || row.shippingMobileNo, 300, 35)
            }

        },
        {
            dataField: "invoiceNo", text: "Invoice Details", formatter: (cell, row) => {
                return (
                    <div className="d-block">
                        <span className="fw-bold">{cell}</span>
                        <div className="fw-normal">{new Date(row?.date).toLocaleDateString()}</div>
                    </div>
                )
            }
        },
        {
            dataField: "closedAt", text: "Return Scan Info", formatter: (cell, row) => {
                return returnParcelReturnScanInformation(row);
            }
        },
        {
            dataField: "tripInfo.vehicleNumber", text: "Trip Info", formatter: (cell, row) => {
                return returnParcelVehicleTripInfo(row);
            }

        },
        {
            dataField: "serialNo", text: "Product Info", formatter: (cell, row) => {
                return returnParcelProductInformation(row);
            }
        },
        {
            dataField: "closedAt", text: "Closed Time / User", formatter: (cell, row) => {
                return (
                    <div className="d-block">
                        <div className="fw-bold">{row?.returnScannedAt ? returnUserReadableDateTime(row?.returnScannedAt) : "-"}</div>
                        <span className="fw-normal">{row?.returnScannedBy}</span>
                    </div>
                )
            }
        },
        {
            dataField: "status", text: "Current Status", formatter: (cell, row) => {
                return returnParcelStatusInformation(row);
            }
        },
        {
            dataField: "returnInfo", text: "Received Info", formatter: (cell, row) => {
                return returnParcelReturnScanInformation(row);
            }
        },
        {
            dataField: "estimatedDeliveryDate", text: "New Date", formatter: (cell, row) => {
                return (
                    <div className="d-block">
                        <div className="fw-bold d-flex row">
                            <span className="fw-bold">{row?.estimatedDeliveryDate ? returnUserReadableDate(row?.estimatedDeliveryDate) : "-"}</span>
                            <span className="fw-normal">{row?.remarks}</span>
                        </div>

                    </div>
                )
            }
        }
    ])
}