import { Button, Form, Modal, InputGroup } from '@themesberg/react-bootstrap';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { generateRandomId } from '../../utils/string';
import Datetime from 'react-datetime';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarAlt,
  faCheck,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { returnUserReadableDate } from '../../utils/datetime';
import RejectDropdown from './RejectDropdown';
import EditableDropDown from './EditableDropdown';
import DatePicker from './DatePicker';

const ConfirmationModal = (props) => {
  const { t } = useTranslation();
  const {
    showModal,
    closeModal,
    modalBody,
    modalTitle,
    onConfirm,
    confirmationModalProps,
    setConfirmationModalProps,
  } = props;
  const headerIcon =
    confirmationModalProps?.[confirmationModalProps?.length - 1]?.headerIcon;
  return (
    <Modal
      id={generateRandomId()}
      as={Modal.Dialog}
      centered
      show={showModal}
      onHide={closeModal}
    >
      <Modal.Header className="d-flex flex-column justify-content-center">
        {headerIcon && <Modal.Title className="h6">{headerIcon}</Modal.Title>}
        <Modal.Title className="h6">{t(modalTitle)}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="text-center">{t(modalBody)}</div>
        {confirmationModalProps?.map((item, index) => (
          <>
            {item?.type === 'date' && (
              <>
                <Form.Label>{t(`${item.title} :`)}</Form.Label>
                <DatePicker
                  value={item.value}
                  setValue={(date) => {
                    setConfirmationModalProps((prev) =>
                      prev.map((prevItem, i) =>
                        i === index
                          ? { ...prevItem, value: new Date(date) }
                          : prevItem
                      )
                    );
                  }}
                />
                {/* <Datetime
                  timeFormat={false}
                  closeOnSelect={true}
                  onChange={(value) => {
                    setConfirmationModalProps((prev) =>
                      prev.map((prevItem, i) =>
                        i === index
                          ? { ...prevItem, value: new Date(value) }
                          : prevItem
                      )
                    );
                  }}
                  renderInput={(props, openCalendar) => (
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon
                          icon={faCalendarAlt}
                          className="icon icon-xs"
                        />
                      </InputGroup.Text>
                      <Form.Control
                        required
                        type="text"
                        value={returnUserReadableDate(item.value)}
                        placeholder="Select date: dd/mm/yyyy"
                        onFocus={openCalendar}
                      />
                    </InputGroup>
                  )}
                /> */}
              </>
            )}
            {item?.type === 'textArea' && (
              <Form.Group>
                <Form.Label className="flex-start">
                  {t(`${item.title} :`)}
                </Form.Label>
                <Form.Control
                  id={generateRandomId()}
                  value={item.value}
                  onChange={(e) => {
                    const { value } = e.target;
                    setConfirmationModalProps((prev) =>
                      prev.map((prevItem, i) =>
                        i === index ? { ...prevItem, value: value } : prevItem
                      )
                    );
                  }}
                  rows={2}
                  as="textarea"
                  style={{
                    backgroundColor: '#F0F0F0',
                  }}
                  placeholder={item?.placeholder}
                />
              </Form.Group>
            )}
            {item?.type === 'text' && (
              <Form.Group>
                <Form.Label>{t(`${item.title} :`)}</Form.Label>
                <Form.Control
                  id={generateRandomId()}
                  value={item.value}
                  onChange={(e) => {
                    const { value } = e.target;
                    setConfirmationModalProps((prev) =>
                      prev.map((prevItem, i) =>
                        i === index ? { ...prevItem, value: value } : prevItem
                      )
                    );
                  }}
                  type={item.type}
                />
              </Form.Group>
            )}
            {item?.type === 'dropdown' && (
              <Form.Group>
                <Form.Label>{t(`${item.title} :`)}</Form.Label>
                <EditableDropDown
                  isMultiSelect={item.isMulti}
                  onChange={(option) => {
                    const { value } = option;
                    setConfirmationModalProps((prev) =>
                      prev.map((item, i) =>
                        i === index ? { ...item, value: value } : item
                      )
                    );
                  }}
                  value={item.value}
                  options={item.options}
                  placeholder="Select Value from below"
                />
              </Form.Group>
            )}
            {item?.type === 'rejectComponent' && (
              <RejectDropdown
                options={item.options}
                setReason={(value) =>
                  setConfirmationModalProps((prev) =>
                    prev.map((prevItem, i) =>
                      i === index ? { ...prevItem, value: value } : prevItem
                    )
                  )
                }
                setRemarks={(value) =>
                  setConfirmationModalProps((prev) =>
                    prev.map((prevItem, i) =>
                      i === index ? { ...prevItem, remarks: value } : prevItem
                    )
                  )
                }
                reason={item.value}
                remarks={item.remarks}
                position={item.position}
              />
            )}
          </>
        ))}
      </Modal.Body>

      <Modal.Footer className="justify-content-center">
        <div className="d-flex justify-content-center gap-4">
          <Button
            className="text-gray"
            onClick={closeModal}
            style={{
              backgroundColor: '#F0F0F0',
              color: '#444444',
              border: 'None',
            }}
          >
            <FontAwesomeIcon icon={faXmark} />
            &nbsp;&nbsp;
            {t('Cancel')}
          </Button>
          <Button
            style={{
              backgroundColor: '#444444',
              color: '#FFFFFF',
              border: 'None',
            }}
            onClick={onConfirm}
            disabled={
              confirmationModalProps &&
              confirmationModalProps.some(
                (item) => item.hasOwnProperty('value') && !item.value
              )
            }
          >
            <FontAwesomeIcon icon={faCheck} />
            &nbsp;&nbsp;
            {t('Confirm')}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
export default ConfirmationModal;
