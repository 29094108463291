import {
  SET_PRODUCT_BY_VANNO_IN_STORE,
  SET_PRODUCTSLIST_DROPDOWN_IN_STORE,
  SET_PRODUCTSMAP_IN_STORE,
} from '../types';

export const setProductListDropdownInStore = (data) => {
  return {
    type: SET_PRODUCTSLIST_DROPDOWN_IN_STORE,
    data,
  };
};

export const setProductsMapInStore = (data) => {
  return {
    type: SET_PRODUCTSMAP_IN_STORE,
    data,
  };
};

export const setProductByVanNoInStore = (data) => {
  return {
    type: SET_PRODUCT_BY_VANNO_IN_STORE,
    data,
  };
};
