import React, { useEffect, useState } from "react";
import Barcode from "react-barcode";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { updateToastInfo } from "../../actions/settings";
import { updateSpinnerState } from "../../actions/spinner";
import logo from "../../assets/img/brand/vijay-sales-logo.svg";
import { returnUserReadableDate } from "../../utils/datetime";
import { getRequisitionItemById } from "../../parse-functions/requisitions";

const PrintRequisition = () => {
    const { objectId } = useParams();
    const dispatch = useDispatch();
    const [reqData, setRequisitionData] = useState({});
    const { t } = useTranslation();

    useEffect(() => {
        getRequisitionDataToPrint();
    }, []);

    const getRequisitionDataToPrint = async () => {
        dispatch(updateSpinnerState(true));
        try {
            // get the print info from the table for this parcel
            const parcelInfo = await getRequisitionItemById({ reqNumber: decodeURIComponent(objectId) });
            setRequisitionData(parcelInfo);
            dispatch(updateSpinnerState(false));
            setPrintAttributesToPage();
        } catch (e) {
            dispatch(updateSpinnerState(false));
            dispatch(updateToastInfo({
                show: true,
                type: 'danger',
                title: 'Failed to get Requisition Data',
                message: t("Failed to get Requisition data from server. Please try again or contact support")
            }));
        }
    }

    const setPrintAttributesToPage = () => {
        const css = '@page { size: landscape; margin: 0mm 0mm 0mm 0mm; color: #000000 !important; }';
        const head = document.head || document.getElementsByTagName('head')[0];
        const style = document.createElement('style');
        style.type = 'text/css';
        style.media = 'print';

        if (style.styleSheet) {
            style.styleSheet.cssText = css;
        } else {
            style.appendChild(document.createTextNode(css));
        }
        head.appendChild(style);
        window.print();
        // window.close();
    }
    console.log(reqData);
    return (
        <div className="d-flex" style={{
            color: '#000000'
        }}>
            <div className="position-relative p-4 p-md-5 card border-0">
                <div className="d-sm-flex justify-content-between border-bottom border-light">
                    <img alt="Vijay Sales" src={logo} width="200" height="100" className="image-md" />
                    <div>
                        <h4>Vijay Sales India Pvt. Ltd.</h4>
                        <div className="simple-list list-group">
                            <div className="fw-normal list-group-item" style={{
                                paddingTop: 0,
                                paddingBottom: 0,
                                display: "flex",
                                justifyContent: "center"
                            }}>
                                {reqData?.createdByBranch}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex align-items-center justify-content-center mb-3">
                    <h1 className="mb-0">
                        <Barcode
                            height={30}
                            font='"Nunito Sans", sans-serif'
                            fontSize={16}
                            value={reqData?.requisitionNumber} />
                    </h1>
                </div>
                <div className="justify-content-between mb-2 mb-md-2 mt-2 row">
                    <div className="col-sm-4 col-lg-4">
                        <h5>
                            Customer Information:
                        </h5>
                        <div>
                            <div className="list-group simple-list list-group">
                                <div className="fw-normal list-group-item">
                                    {reqData?.toBranch}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-8 col-lg-8">
                        <div className="text-sm-right row">
                            <div className="col-3 d-flex justify-content-end"> <dt><strong>Requisition No.</strong></dt></div>
                            <div className="col-9 d-flex justify-content-start"> <dd>{reqData?.requisitionNumber}</dd></div>
                            <div className="col-3 d-flex justify-content-end"> <dt><strong>Req Date:</strong></dt></div>
                            <div className="col-9 d-flex justify-content-start"> <dd>{returnUserReadableDate(reqData.requisitionDate)}</dd></div>
                            <div className="col-3 d-flex justify-content-end"> <dt><strong>Delivery Branch:</strong></dt></div>
                            <div className="col-9 d-flex justify-content-start"> <dd>{reqData?.deliveryBranch}</dd></div>
                            <div className="col-3 d-flex justify-content-end"> <dt><strong>Remarks:</strong></dt></div>
                            <div className="col-9 d-flex justify-content-start" style={{ textAlign: "justify" }}> <dd>{reqData?.remarks}</dd></div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="table-responsive">
                            <table className="mb-0 table" style={{
                                color: "#000000 !important"
                            }}>
                                <thead className="bg-light border-top">
                                    <tr>
                                        <th className="border-0">Product Name / Code</th>
                                        <th className="border-0">Company / Category</th>
                                        <th className="border-0">VAN No</th>
                                        <th className="border-0">Quantity</th>
                                        <th className="border-0">Purpose</th>
                                        <th className="border-0">Remarks</th>
                                    </tr>
                                </thead>
                                <tbody className="border-0">
                                    {reqData.products?.map((product, index) => {
                                        if (product?.status === "rejected") {
                                            return null;
                                        }
                                        return (
                                            <tr>
                                                <td className="border-0">
                                                    <div className="d-block">
                                                        <span className="fw-bold">{product?.product?.productName || "-"}</span><br />
                                                        <span className="fw-normal">{product?.product?.productCode || "-"}</span>
                                                    </div>
                                                </td>
                                                <td className="border-0">
                                                    <div className="d-block">
                                                        <span className="fw-bold">{product?.product?.productCategory || "-"}</span><br />
                                                        <span className="fw-normal">{product?.product?.companyName || "-"}</span>
                                                    </div></td>
                                                <td className="border-0">{product?.product?.vanNo || "-"}</td>
                                                <td className="border-0">{product?.quantity || "-"}</td>
                                                <td className="border-0">{reqData?.requisitionType || "-"}</td>
                                                <td className="border-0">{product?.remarks || "-"}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default PrintRequisition