import {
  faCheck,
  faDownload,
  faRefresh,
  faSliders,
  faSort,
  faSortDown,
  faSortUp,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  ButtonGroup,
  Card,
  Col,
  Form,
  Row,
} from '@themesberg/react-bootstrap';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Table from 'react-bootstrap-table-next';
import Pagination from 'react-bootstrap-table2-paginator';
import * as Paginator from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { updateToastInfo } from '../../actions/settings';
import BreadCrumbComponent from './Breadcrumb';
import ExcelDownloadReport from './excel/download';
import DateRangeSelector from './DateRangeSelector';
import DropdownComponent from './Dropdown';
import EmptyTable from './EmptyTableView';
import FileUploadButton from '../common/fileUpload';
import FilterOrders from '../../pages/oms/orders/FIlterOrders';
import { returnOrderStatus } from '../../constants/orderStatus';
import { setPageNumberInStore } from '../../actions/tablePage';

export const customTotal = (from, to, size) => (
  <div className="w-100 d-flex justify-content-center">
    Showing {from} to {to} of {size} entries
  </div>
);

export const customSizePerPage = (props) => {
  const { options, currentSizePerPage, onSizePerPageChange } = props;

  const onPageChange = (e) => {
    const page = e.target.value;
    onSizePerPageChange(page);
  };

  return (
    <Row>
      <Col xs="auto">
        <Form.Select
          value={currentSizePerPage}
          onChange={onPageChange}
          className="pe-5"
        >
          {options.map((o) => (
            <option key={o.page} value={o.page}>
              {o.text}
            </option>
          ))}
        </Form.Select>
      </Col>
      <Col xs="auto" className="d-flex align-items-center">
        entries per page
      </Col>
    </Row>
  );
};
const OrderListPage = (props) => {
  const {
    pageTitle,
    breadCrumbItems,
    pageSubTitle,
    searchPlaceHolder,
    tableColumns = [],
    tableData = [],
    filterSubmit,
    handleRefresh,
    handleItemBulkUpdate,
    bulkActionOptions,
    enableBulkActions,
    hideBulkActions = false,
    showDateFilters = false,
    filtersValue,
    pageType,
    showFileUploadSection = false,
    onFileUpload,
    sampleFileUrl,
    onRowClick,
    filter = true,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [bulkActionSelectedOption, setBulkActionSelectedOption] =
    useState(null);

  const [showData, setShowData] = useState(Object.values(tableData));
  const [openFilter, setOpenFilter] = useState(false);
  useEffect(() => {
    setShowData(tableData);
  }, [tableData]);
  const handleItemUpdate = () => {
    if (bulkActionSelectedOption) {
      handleItemBulkUpdate(bulkActionSelectedOption);
    } else {
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('Select an Action'),
          message: t('Please select an action from the dropdown'),
        })
      );
    }
  };
  const totalPages = Math.ceil((tableData?.length || 0) / 10);
  const storedPage = useSelector((state) => state?.pageNumber?.[pageType] || 1);
  const [currentPage, setCurrentPage] = useState(
    storedPage > totalPages ? 1 : storedPage
  );

  const handlePageChange = (page) => {
    if (pageType) {
      dispatch(setPageNumberInStore({ id: pageType, page }));
    }
  };

  useEffect(() => {
    setCurrentPage(storedPage > totalPages ? 1 : storedPage);
  }, [storedPage, totalPages]);
  const handleOnFileUpload = (fileContent) => {};

  const handleFilter = () => {
    setOpenFilter(!openFilter);
  };

  const rowStyle = (row, rowIndex) => {
    if (row.isIBT) {
      return 'table-success';
    }
    if (row.isHyperLocal) {
      return 'table-warning';
    }
    return '';
  };

  return (
    <>
      <div>
        <ToolkitProvider
          exportCSV
          keyField="id"
          search={true}
          sort
          columns={tableColumns}
          data={showData}
          wrapperClasses="table-responsive"
        >
          {({ baseProps, searchProps, csvProps }) => (
            <Paginator.PaginationProvider
              pagination={Pagination({
                custom: true,
                showTotal: true,
                alwaysShowAllBtns: true,
                totalSize: Object.values(tableData)?.length,
                paginationTotalRenderer: customTotal,
                sizePerPageRenderer: customSizePerPage,
                onPageChange: handlePageChange,
                page: currentPage,
              })}
            >
              {({ paginationProps, paginationTableProps }) => (
                <>
                  <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-md-0">
                      <BreadCrumbComponent items={breadCrumbItems} />
                      <h4>{pageTitle}</h4>
                      <p className="mb-0">{pageSubTitle}</p>
                    </div>
                    <div className="btn-toolbar mb-2 mb-md-0">
                      <ButtonGroup className="gap-2">
                        {pageTitle ===
                          returnOrderStatus.REFUND_INITIATED + ' Orders' && (
                          <FileUploadButton
                            buttonText={t('Upload File')}
                            onFileUpload={handleOnFileUpload}
                          />
                        )}
                        <Button
                          variant={openFilter ? 'primary' : 'white'}
                          onClick={handleFilter}
                          style={{
                            width: '150px',
                            height: '38px',
                            borderRadius: 0,
                          }}
                          size="sm"
                        >
                          <FontAwesomeIcon icon={faSliders} />
                          &nbsp;&nbsp;{t('Filter')}
                        </Button>

                        <Button
                          variant="white"
                          onClick={handleRefresh}
                          style={{
                            width: '150px',
                            height: '38px',
                            borderRadius: 0,
                          }}
                          size="sm"
                        >
                          <FontAwesomeIcon icon={faRefresh} />
                          &nbsp;&nbsp;{t('Refresh')}
                        </Button>

                        <ExcelDownloadReport
                          data={Object.values(tableData) || []}
                          className="btn-export"
                          fileName={`${pageType}`}
                        />
                      </ButtonGroup>
                    </div>
                  </div>
                  {filter && (
                    <FilterOrders
                      data={tableData}
                      setData={setShowData}
                      openFilter={openFilter}
                    />
                  )}
                  {showDateFilters && (
                    <div className="table-settings mt-2 mb-2">
                      <Row className="justify-content-start">
                        <Col xs={12} md={6}>
                          <DateRangeSelector
                            onSubmit={filterSubmit}
                            filtersValue={filtersValue}
                          />
                        </Col>
                      </Row>
                    </div>
                  )}
                  {showFileUploadSection && (
                    <Row className="d-flex justify-content-end">
                      <Col sm={6} className="d-flex justify-content-end">
                        {sampleFileUrl && (
                          <>
                            <Button
                              onClick={() => {
                                window.open(sampleFileUrl, '_blank');
                                dispatch(
                                  updateToastInfo({
                                    show: true,
                                    type: 'success',
                                    title: 'Sample File is downloaded',
                                    message:
                                      'Use this file to remove the data and add your own',
                                  })
                                );
                              }}
                              variant="primary-outline"
                            >
                              <FontAwesomeIcon icon={faDownload} />
                              &nbsp;&nbsp;Download Sample
                            </Button>
                            &nbsp;&nbsp;&nbsp;
                          </>
                        )}
                        <FileUploadButton
                          onFileUpload={handleOnFileUpload}
                          buttonText={'Upload Loader Sheet'}
                        />
                      </Col>
                    </Row>
                  )}
                  <Card>
                    <div className="table-responsive py-2">
                      <Card.Header>
                        <Row className="d-flex justify-content-between">
                          <Col xs={6} md={5} className="d-flex py-1">
                            {!hideBulkActions && (
                              <>
                                <Form.Group
                                  id="substatus"
                                  className="d-flex align-items-center flex-column"
                                >
                                  <DropdownComponent
                                    onChange={(option) => {
                                      setBulkActionSelectedOption(option.value);
                                    }}
                                    width={300}
                                    disabled={!enableBulkActions}
                                    options={bulkActionOptions}
                                    placeholder={t('Select Action')}
                                  />
                                </Form.Group>
                                <Button
                                  style={{
                                    height: 37,
                                  }}
                                  size="sm"
                                  onClick={handleItemUpdate}
                                  disabled={!enableBulkActions}
                                  className="ms-3 btn btn-secondary"
                                  variant="secondary"
                                >
                                  <FontAwesomeIcon icon={faCheck} />
                                  &nbsp;&nbsp;{t('Update')}
                                </Button>
                              </>
                            )}
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            className="d-flex justify-content-md-end py-1"
                          >
                            <Search.SearchBar
                              {...searchProps}
                              placeholder={
                                searchPlaceHolder ||
                                t('Search any value in table here')
                              }
                              onSearch={(value) => {
                                setCurrentPage(1);
                                searchProps.onSearch(value);
                              }}
                            />
                            <Search.ClearSearchButton {...searchProps} />
                          </Col>
                        </Row>
                      </Card.Header>

                      <Table
                        {...(onRowClick && {
                          rowEvents: {
                            onClick: (e, row, index) => {
                              onRowClick(row?.objectId);
                            },
                          },
                        })}
                        noDataIndication={<EmptyTable />}
                        {...baseProps}
                        {...paginationTableProps}
                        bodyClasses="border-0"
                        classes="table-flush dataTable-table"
                        sort={{
                          sortCaret: (order, column) => {
                            if (!order)
                              return (
                                <span>
                                  &nbsp;&nbsp;
                                  <FontAwesomeIcon icon={faSort} />
                                </span>
                              );
                            else if (order === 'asc')
                              return (
                                <span>
                                  &nbsp;&nbsp;
                                  <FontAwesomeIcon icon={faSortUp} />
                                </span>
                              );
                            else if (order === 'desc')
                              return (
                                <span>
                                  &nbsp;&nbsp;
                                  <FontAwesomeIcon icon={faSortDown} />
                                </span>
                              );
                            return null;
                          },
                        }}
                        rowClasses={rowStyle} // Updated rowClasses prop here
                      />

                      <Card.Footer>
                        <Row>
                          <Col xs={12} md={4} className="py-1">
                            <Paginator.SizePerPageDropdownStandalone
                              {...paginationProps}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            className="d-flex align-items-center py-1"
                          >
                            <Paginator.PaginationTotalStandalone
                              {...paginationProps}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            className="d-flex justify-content-md-end align-items-center mb-0 py-1"
                          >
                            <Paginator.PaginationListStandalone
                              {...paginationProps}
                            />
                          </Col>
                        </Row>
                      </Card.Footer>
                    </div>
                  </Card>
                </>
              )}
            </Paginator.PaginationProvider>
          )}
        </ToolkitProvider>
      </div>
    </>
  );
};
export default OrderListPage;
