import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BreadCrumbComponent from "../../../components/common/Breadcrumb";
import arrayToBreadCrumbs from "../../../utils/sms/arrayToBreadCrumbs";
import { Button, Card } from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Add3plModal from "./Add3plModal";
import { apiCall } from "../../../middlewares/api";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateSpinnerState } from "../../../actions/spinner";
import { updateToastInfo } from "../../../actions/settings";
import { apiCallConsts } from "../../../constants/apiCallConsts";

const AllDeliveries = () => {
  const [list, setList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    fetchList();
  }, []);

  const fetchList = async () => {
    try {
      dispatch(updateSpinnerState(true));
      const res = await apiCall(
        apiCallConsts.GET_METHOD,
        apiCallConsts.ALL_3PLS_URL,
        {}
      );
      setList(res);
      dispatch(updateSpinnerState(false));
    } catch (error) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: "danger",
          title: t("Failed to fetch"),
          message: t(`${error.message}`),
        })
      );
    }
  };
  const breadCrubItems = arrayToBreadCrumbs([["3pl integrations"]]);

  const handleCardClick = (objectId) => {
    navigate(`/oms/list/${objectId}`);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSubmit = async (data) => {
    handleCloseModal();
    console.log(data);
    try {
      dispatch(updateSpinnerState(true));
      const res = await apiCall(
        apiCallConsts.POST_METHOD,
        apiCallConsts.ADD_3PL_URL,
        data
      );
      dispatch(updateSpinnerState(false));
      if (res?.message) {
        fetchList();
        dispatch(
          updateToastInfo({
            show: true,
            type: "success",
            title: t("Delivery Partner Added Successfully"),
            message: `${res.message}`,
          })
        );
      }
    } catch (error) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: "danger",
          title: t("Delivery Partner Adding Failed"),
          message: `${error.message}`,
        })
      );
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <BreadCrumbComponent items={breadCrubItems} />
          <h4>{t("ThirdParty Logistics")}</h4>
          <p className="mb-0">
            {t("This is the list of all third party logistics available")}
          </p>
        </div>
        <div className="mb-0">
          <Button onClick={() => setShowModal(true)}>
            <FontAwesomeIcon icon={faPlus} />
            {t(" Add new patner")}
          </Button>
        </div>
      </div>
      <div className="d-flex gap-3 flex-wrap  mt-5 ms-4">
        {list &&
          list.map((each) => {
            return (
              <Card
                style={{ width: "15rem", height: "10rem", cursor: "pointer" }}
                key={each.name}
                onClick={() => handleCardClick(each?.objectId)}
              >
                <Card.Body>
                  <Card.Title className="mb-5 text-center">
                    {t(each.name)}
                  </Card.Title>
                  {each?.isDefault && (
                    <Card.Text
                      style={{
                        border: "2px solid",
                        width: "fit-content",
                        padding: "0px 5px",
                        borderRadius: "5px",
                        margin: "auto",
                        fontWeight: "bold",
                        color: "green",
                      }}
                    >
                      &#10003; {t(" Default")}
                    </Card.Text>
                  )}
                </Card.Body>
              </Card>
            );
          })}
      </div>

      <Add3plModal
        showModal={showModal}
        closeModal={handleCloseModal}
        handleSubmit={handleSubmit}
      />
    </>
  );
};

export default AllDeliveries;
