import React from 'react';
import { Form } from '@themesberg/react-bootstrap';

const ToggleSwitch = (props) => {
  const { type, input, setInput, name } = props;

  return (
    <div
      className="d-flex align-items-center p-2 mt-2 mb-2 gap-2"
      style={{
        backgroundColor: type === 'secondary' ? '#FFFFFF' : '#F0F0F0',
        borderRadius: '0.5rem',
      }}
    >
      <span
        style={{
          marginRight: '8px',
          fontWeight: 'bold',
        }}
      >
        {name}
      </span>
      <Form.Check
        type="switch"
        id="custom-switch"
        checked={input}
        onChange={(e) => setInput(e.target.checked)}
      />
    </div>
  );
};

export default ToggleSwitch;
