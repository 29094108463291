export const brandManagerUserSchema = [
  [
    {
      title: 'Brand Manager User Info',
      type: 'header',
    },
    {
      title: 'Name',
      id: 'name',
      type: 'text',
      required: true,
    },
    {
      title: 'Phone Number',
      id: 'phoneNumber',
      type: 'number',
      required: true,
    },
    {
      title: 'Email Id',
      id: 'emailId',
      type: 'email',
      required: false,
    },
    {
      title: 'Company',
      id: 'company',
      type: 'select',
      isMultiSelect: true,
      options: [],
      required: true,
      placeholder: 'Select Company/Companies',
    },
    {
      title: 'Category',
      id: 'category',
      type: 'select',
      isMultiSelect: true,
      options: [],
      required: true,
      placeholder: 'Select Category/Categories',
    },
    {
      title: 'Applications',
      id: 'applications',
      type: 'select',
      isMultiSelect: true,
      options: [
        { label: 'SMS', value: 'sms' },
        { label: 'DMS', value: 'dms' },
      ],
      required: true,
      placeholder: 'Select Applications',
    },
  ],

  [
    {
      title: 'Login Info',
      type: 'header',
    },
    {
      title: 'UserName',
      id: 'userName',
      type: 'text',
      required: true,
    },
    {
      title: 'Password',
      id: 'password',
      type: 'text',
      required: true,
    },
    {
      title: 'Repeat Password',
      id: 'repeatPassword',
      type: 'text',
      required: true,
    },
    {
      title: 'Create user',
      type: 'button',
      width: '30%',
    },
  ],
];
