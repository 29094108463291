import React, { useState } from 'react';
import {
  Badge,
  Button,
  Col,
  Form,
  Modal,
  Row,
} from '@themesberg/react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { updateToastInfo } from '../../actions/settings';
import { getTripBadgeStatus } from '../../utils/trips';

const VoucherDataModal = (props) => {
  const {
    showUpdateModal = false,
    handleCloseModal,
    addRemarkAndUpdateRequisition,
    requisitonItems,
    readOnly = false,
  } = props;
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const { t } = useTranslation();

  const handleChangeInput = (reqId, field, value) => {
    if (!formData[reqId]) {
      formData[reqId] = {};
    }
    formData[reqId][field] = Math.round(Number(value));
    setFormData(formData);
  };

  const handlAddRemarkAndUpdateRequisition = () => {
    for (const tripNumber in formData) {
      for (const field in formData[tripNumber]) {
        if (!formData[tripNumber][field] || formData[tripNumber][field] === 0) {
          dispatch(
            updateToastInfo({
              show: true,
              type: 'danger',
              title: t('error'),
              message: t('please fill all fields and value not be 0'),
            })
          );
          return;
        }
      }
    }

    let tripsDetails = {};
    Object.keys(requisitonItems?.tripsDetails).forEach((each) => {
      if (formData.hasOwnProperty(each)) {
        tripsDetails[each] = {
          ...requisitonItems.tripsDetails[each],
          ...formData[each],
          status: 'paid',
        };
      } else {
        tripsDetails[each] = {
          ...requisitonItems.tripsDetails[each],
          status: 'paid',
        };
      }
    });

    addRemarkAndUpdateRequisition(requisitonItems?.objectId, tripsDetails);
  };

  return (
    <Modal
      backdrop={'static'}
      dialogClassName="modal-90w"
      size="xl"
      show={showUpdateModal}
      onHide={handleCloseModal}
    >
      <Modal.Header>
        <Modal.Title className="h6">
          {t(readOnly ? 'See Voucher Details' : 'Update Amount & Status')}
        </Modal.Title>
        <Button variant="close" aria-label="Close" onClick={handleCloseModal} />
      </Modal.Header>
      <Modal.Body>
        <Form.Group className="mb-3 mt-4">
          {Object.keys(requisitonItems).length > 0 &&
            Object.values(requisitonItems?.tripsDetails || {})?.map((trip) => {
              return (
                <Row key={trip?.tripNumber}>
                  <Col>
                    <Form.Label>{t('Trip Number')}</Form.Label>
                    <Form.Control
                      required
                      disabled={true}
                      defaultValue={trip?.tripNumber}
                      type="text"
                    />
                  </Col>
                  <Col>
                    <Form.Label>{t('Payment Type')}</Form.Label>
                    <Form.Control
                      disabled={true}
                      required
                      defaultValue={trip?.paymentType}
                      type="text"
                    />
                  </Col>
                  <Col>
                    <Form.Label>{t('Amount')}</Form.Label>
                    <Form.Control
                      required
                      disabled={readOnly}
                      defaultValue={trip?.amount}
                      type="number"
                      onChange={(event) => {
                        handleChangeInput(
                          trip?.tripNumber,
                          'amount',
                          event.target.value
                        );
                      }}
                    />
                  </Col>
                  <Col>
                    {readOnly && (
                      <>
                        <Form.Label>{t('Status')}</Form.Label>
                        <div>
                          <Badge
                            bg={getTripBadgeStatus(trip?.status)}
                            className="badge-lg"
                          >
                            {trip?.status ? trip?.status : 'created'}
                          </Badge>
                        </div>
                      </>
                    )}
                  </Col>
                </Row>
              );
            })}
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        {!readOnly && (
          <Button
            variant="secondary"
            onClick={handlAddRemarkAndUpdateRequisition}
          >
            {t('Update')}
          </Button>
        )}
        <Button
          variant="link"
          className="text-gray ms-auto"
          onClick={handleCloseModal}
        >
          {t('Cancel')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default VoucherDataModal;
