import React from 'react';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import * as Paginator from 'react-bootstrap-table2-paginator';
import Pagination from 'react-bootstrap-table2-paginator';
import Table from 'react-bootstrap-table-next';
import {
  customSizePerPage,
  customTotal,
} from '../../../../components/common/parcelStatusPages';
import { useTranslation } from 'react-i18next';
import { Card, Col, Row } from '@themesberg/react-bootstrap';
import { returnRowBGColor } from '../../../../utils/parcels';
import EmptyTable from '../../../../components/common/EmptyTableView';

const TableList = (props) => {
  const { data, columns, title } = props;
  const { t } = useTranslation();
  return (
    <ToolkitProvider
      exportCSV
      keyField="id"
      sort
      columns={columns}
      data={data || []}
      wrapperClasses="table-responsive"
    >
      {({ baseProps }) => (
        <Paginator.PaginationProvider
          pagination={Pagination({
            custom: true,
            showTotal: false,
            alwaysShowAllBtns: false,
            totalSize: data?.length,
            // paginationTotalRenderer: customTotal,
            // sizePerPageRenderer: customSizePerPage,
          })}
        >
          {({ paginationProps, paginationTableProps }) => (
            <>
              <Card>
                <div className="table-responsive py-2">
                  <Card.Header className="font-weight-medium font-size-lg">
                    WAREHOUSE IN {title.toUpperCase() || ''}
                  </Card.Header>
                  <Table
                    id={'parcelListTable'}
                    rowClasses={(row) => {
                      const rowBGColor =
                        row?.distance <= 5 ? '#EAEDF2;' : '#FFFFFF';
                      return `${rowBGColor} border-bottom`;
                    }}
                    noDataIndication={<EmptyTable />}
                    {...baseProps}
                    {...paginationTableProps}
                    // bodyClasses="border-0"
                    classes="table-flush dataTable-table"
                    // {...(handleRowClick && {
                    //   rowEvents: {
                    //     onClick: (e, row, index) => {
                    //       handleRowClick(row);
                    //     },
                    //   },
                    // })}
                    // {...(showRowBgColor && {
                    //   rowClasses: (row) => {
                    //     const rowBGColor = returnRowBGColor(row?.status);
                    //     return `${rowBGColor} border-bottom`;
                    //   },
                    // })}
                  />

                  {/* {isFooterRequired &&  <Card.Footer>
                    <Row>
                      <Col xs={12} md={4} className="py-1">
                        <Paginator.SizePerPageDropdownStandalone
                          {...paginationProps}
                          paginationSize={5}
                          sizePerPageList={[{ text: "5", value: 5 }]}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        className="d-flex align-items-center py-1"
                      >
                        <Paginator.PaginationTotalStandalone
                          {...paginationProps}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        className="d-flex justify-content-md-end align-items-center mb-0 py-1"
                      >
                        <Paginator.PaginationListStandalone
                          {...paginationProps}
                        />
                      </Col>
                    </Row>
                  </Card.Footer>} */}
                </div>
              </Card>
            </>
          )}
        </Paginator.PaginationProvider>
      )}
    </ToolkitProvider>
  );
};

export default TableList;
