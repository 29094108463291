export const vehicleAttendanceReportTableColumns = (headers) => {
    return headers?.map(keyName => ({
        dataField: keyName,
        text: keyName,
        style: (cell) => ({
                backgroundColor: cell === "P" ? "#caf5d5" : cell === "A" ? "#f09693" : ""
        })
    }))
};

export const monthlyVehicleDeliveryReportColumns = () => {
    return ([
        {dataField: "vehicleNumber", text: "Vehicle Number"},
        {dataField: "vehicleType", text: "Vehicle Type"},
        {dataField: "deliveryDate", text: "Delivery Date"},
        {dataField: "warehouseCode", text: "Branch Name"},
        {dataField: "vendorName", text: "Vendor Name"},
        {dataField: "products", text: "Products"},
        {dataField: "deliveryQuantity", text: "Delivery Quantity"},
        {dataField: "isDisplay", text: "Is Display"},
        {dataField: "branchDroppedAt", text: "Branches"},
        {dataField: "delivered", text: "Delivered"},
        {dataField: "pending", text: "Pending"},
        {dataField: "droppedathub", text: "Dropped At Hub"},
        {dataField: "returnok", text: "Return OK"},
        {dataField: "defectivepicked", text: "Return Defective"},
    ])
};