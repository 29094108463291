import { orderStatus } from '../../../constants/orderStatus';

export const IBTStatuses = {
  PENDING: 'Pending',
  ACCEPTED: 'Accepted',
  PARTIALLY_ACCEPTED: 'Partially Accepted',
  CANCELLED: 'Cancelled',
  INVOICED: 'Invoiced',
  PROCESSING: 'Processing',
  IN_TRANSIT: 'In-Transit',
  RECEIVED: 'Received',
  DELIVERED: 'Delivered',
  ASSIGNED: orderStatus.assigned,
  SUB_ACCEPTED: orderStatus.assigned,
  ASSIGNED: orderStatus.assigned,
  QCPASSED: orderStatus.awaitingInvoice,
  INVOICED: orderStatus.invoiced,
  READY_FOR_PICKUP: orderStatus.readyForPickup,
};

export const statusMap = {
  [IBTStatuses.PENDING]: {
    bgColor: '#FDEDD9',
    color: '#F4921B',
    minWidth: '116px',
    heigth: '28px',
  },
  [IBTStatuses.PARTIALLY_ACCEPTED]: {
    bgColor: '#E2F7FF',
    color: '#00A3E0',
    minWidth: '116px',
    heigth: '28px',
  },
  [IBTStatuses.ACCEPTED]: {
    bgColor: '#D4F7E8',
    color: '#21A871',
    minWidth: '116px',
    heigth: '28px',
  },
  [IBTStatuses.IN_TRANSIT]: {
    bgColor: '#ECF9FD',
    color: '#0948B3',
    minWidth: '116px',
    heigth: '28px',
  },
  [IBTStatuses.IN_TRANSIT]: {
    bgColor: '#ECF9FD',
    color: '#0948B3',
    minWidth: '116px',
    heigth: '28px',
  },
  [IBTStatuses.CANCELLED]: {
    bgColor: '#FDDDDD',
    color: 'red',
    minWidth: '116px',
    heigth: '28px',
  },
  [IBTStatuses.ASSIGNED]: {
    bgColor: '#ECF9FD',
    color: '#0948B3',
    minWidth: '116px',
    heigth: '28px',
  },
  [IBTStatuses.SUB_ACCEPTED]: {
    bgColor: '#ECF9FD',
    color: '#0948B3',
    minWidth: '116px',
    heigth: '28px',
  },
  [IBTStatuses.QCPASSED]: {
    bgColor: '#ECF9FD',
    color: '#0948B3',
    minWidth: '116px',
    heigth: '28px',
  },
  [IBTStatuses.INVOICED]: {
    bgColor: '#ECF9FD',
    color: '#0948B3',
    minWidth: '116px',
    heigth: '28px',
  },
  [IBTStatuses.READY_FOR_PICKUP]: {
    bgColor: '#ECF9FD',
    color: '#0948B3',
    minWidth: '116px',
    heigth: '28px',
  },
  [IBTStatuses.DELIVERED]: {
    bgColor: '#D4F7E8',
    color: '#4efa59',
    minWidth: '116px',
    heigth: '28px',
  },
};

export const IBTdropDownStatus = {
  PENDING: 'Pending',
  ACCEPTED: 'Accepted',
  PARTIALLY_ACCEPTED: 'Partially Accepted',
  CANCELLED: 'Cancelled',
  DELIVERED: 'Delivered',
};
