import React, { useEffect, useState } from 'react';
import { Button, Card, Form } from '@themesberg/react-bootstrap';
import { ErrorMessage } from '../StyledComponents';
import { useTranslation } from 'react-i18next';
import FileUploadButton from '../../../../components/common/fileUpload';
import { productsUploadSampleFile } from '../../../../utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { updateToastInfo } from '../../../../actions/settings';
import { updateSpinnerState } from '../../../../actions/spinner';
import { arrayToDropdownOptions } from '../../../../utils/sms/arrayToDropdownOptions';
import { getAllCompaniesCategoriesSubCategoriesData } from '../../../../parse-functions/sms/scheme';
import ToggleSwitch from '../common/ToggleSwitch';
import EditableDropDown from '../../../../components/common/EditableDropdown';
import DatePicker from '../../../../components/common/DatePicker';

const AddSchemeDetails = (props) => {
  const { formInput, setFormInput, formErrors, handleFileUpload } = props;
  const [companyOptions, setCompanyOptions] = useState([]);
  const [regionOptions, setRegionOptions] = useState([]);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const configData = useSelector((state) => state.user.config || {});
  const isAdmin = useSelector(
    (state) => state?.user?.preferences?.isAdmin || false
  );
  const userInfo = useSelector((state) => state?.user?.userInfo);

  useEffect(() => {
    if (regionOptions.length === 0) {
      setRegionOptions([
        { label: 'All', value: 'All' },
        ...arrayToDropdownOptions(Object.keys(configData.zones || {})),
      ]);
    }
  }, [configData]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(updateSpinnerState(true));
        let data = [];
        if (isAdmin) {
          data = await getAllCompaniesCategoriesSubCategoriesData({
            isCompany: true,
          });
          setCompanyOptions(
            data?.map((item) => {
              return { label: item.companyName, value: item.companyName };
            })
          );
          dispatch(updateSpinnerState(false));
        } else {
          data = userInfo?.company?.filter((item) => item !== 'All');
          setCompanyOptions(arrayToDropdownOptions(data));
        }
      } catch (error) {
        dispatch(updateSpinnerState(false));
        console.log(error);
        dispatch(
          updateToastInfo({
            show: true,
            type: 'danger',
            title: '',
            message: t(`Error while fetching companies.`),
          })
        );
      }
    };
    if (companyOptions.length === 0) {
      fetchData();
    }
  }, [userInfo]);

  return (
    <Card className="p-2">
      <div className="d-flex flex-row">
        <div className="w-50  p-2">
          <Form.Group className="mb-2">
            <Form.Label>{t('Company Name*')}</Form.Label>
            <EditableDropDown
              id={'company'}
              onChange={(e) =>
                setFormInput((prev) => ({
                  ...prev,
                  company: [e.value],
                }))
              }
              value={formInput['company']}
              options={companyOptions}
              placeholder={'Choose Company Name'}
              isInvalid={formErrors['company']}
              backgroundColor={'#F0F0F0'}
            ></EditableDropDown>
            <ErrorMessage>{formErrors['company']}</ErrorMessage>
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label>{t('Scheme Circular No.')}</Form.Label>
            <Form.Control
              type="text"
              id={'schemeCircularNo'}
              value={formInput['schemeCircularNo']}
              onChange={(e) => {
                const value = e.target.value;
                setFormInput((prev) => ({
                  ...prev,
                  schemeCircularNo: value,
                }));
              }}
              isInvalid={formErrors['schemeCircularNo']}
              style={{ backgroundColor: '#F0F0F0' }}
              placeholder="Enter Scheme Circular No."
            />
            <ErrorMessage>{formErrors['schemeCircularNo']}</ErrorMessage>
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label>{t('Scheme Circular Date*')}</Form.Label>
            <DatePicker
              value={formInput['schemeCircularDate']}
              setValue={(date) =>
                setFormInput((prev) => ({
                  ...prev,
                  schemeCircularDate: date,
                }))
              }
              isInvalid={formErrors['schemeCircularDate']}
              errorMessage={formErrors['schemeCircularDate']}
            />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label>{t('Regions*')}</Form.Label>
            <EditableDropDown
              id={'regions'}
              onChange={(e) =>
                setFormInput((prev) => ({
                  ...prev,
                  regions: e.map(({ value }) => value),
                }))
              }
              value={formInput['regions']}
              options={regionOptions}
              placeholder={'Choose Regions'}
              isInvalid={formErrors['regions']}
              isMultiSelect={true}
              backgroundColor={'#F0F0F0'}
              optionColor="#FFFFFF"
            ></EditableDropDown>
            <ErrorMessage>{formErrors['regions']}</ErrorMessage>
          </Form.Group>
          <div className="d-flex flex-row justify-content-between gap-2">
            <Form.Group className="w-100">
              <Form.Label>{t('Valid From*')}</Form.Label>
              <DatePicker
                value={formInput['validFrom']}
                setValue={(date) =>
                  setFormInput((prev) => ({
                    ...prev,
                    validFrom: date,
                  }))
                }
                isInvalid={formErrors['validFrom']}
                errorMessage={formErrors['validFrom']}
              />
            </Form.Group>
            <Form.Group className="w-100">
              <Form.Label>{t('Valid Till*')}</Form.Label>
              <DatePicker
                value={formInput['validTill']}
                setValue={(date) =>
                  setFormInput((prev) => ({
                    ...prev,
                    validTill: date,
                  }))
                }
                isInvalid={formErrors['validTill']}
                errorMessage={formErrors['validTill']}
              />
            </Form.Group>
          </div>
        </div>
        <div className="w-50  p-2">
          <Form.Group className="mb-2">
            <Form.Label>{t('Scheme Type*')}</Form.Label>
            <EditableDropDown
              id={'type'}
              onChange={(e) =>
                setFormInput((prev) => ({
                  ...prev,
                  type: [e.value],
                }))
              }
              value={formInput['type']}
              options={[
                { label: 'Sell-in', value: 'Sell-in' },
                { label: 'Sell-out', value: 'Sell-out' },
              ]}
              placeholder={'Choose Scheme Type'}
              isInvalid={formErrors['type']}
              backgroundColor={'#F0F0F0'}
            ></EditableDropDown>
            <ErrorMessage>{formErrors['type']}</ErrorMessage>
          </Form.Group>
          {formInput?.type?.[0] === 'Sell-in' && (
            <Form.Group className="mb-2">
              <Form.Label>{t('Sell-In Type*')}</Form.Label>
              <EditableDropDown
                id={'sellIntype'}
                onChange={(e) =>
                  setFormInput((prev) => ({
                    ...prev,
                    sellIntype: [e.value],
                  }))
                }
                value={formInput['sellIntype']}
                backgroundColor={'#F0F0F0'}
                options={[
                  { label: 'On-Invoice', value: 'On-Invoice' },
                  { label: 'Off-Invoice', value: 'Off-Invoice' },
                ]}
                placeholder={'Choose Sell In type'}
                isInvalid={formErrors['sellIntype']}
              ></EditableDropDown>
              <ErrorMessage>{formErrors['sellIntype']}</ErrorMessage>
            </Form.Group>
          )}
          <Form.Group className="mb-2">
            <Form.Label>{t('Remarks')}</Form.Label>
            <Form.Control
              id={'remarks'}
              value={formInput['remarks']}
              onChange={(e) => {
                const value = e.target.value;
                setFormInput((prev) => ({
                  ...prev,
                  remarks: value,
                }));
              }}
              rows={5}
              as="textarea"
              isInvalid={formErrors['remarks']}
              style={{ backgroundColor: '#F0F0F0' }}
              placeholder="Enter Remark"
            />
          </Form.Group>
          <Form.Group className="mb-2 mt-4" style={{ width: 'fit-content' }}>
            <ToggleSwitch
              type={'primary'}
              input={formInput.uploadExcel}
              setInput={(checked) =>
                setFormInput((prev) => ({
                  ...prev,
                  uploadExcel: checked,
                }))
              }
              name={'Upload Excel'}
            />
          </Form.Group>
          {formInput.uploadExcel && (
            <div
              className="d-flex align-items-center justify-content-center mt-4"
              style={{ width: '100%' }}
            >
              <FileUploadButton
                buttonText={t('Upload File')}
                onFileUpload={handleFileUpload}
                style={{ display: 'inline-block', marginRight: '8px' }}
              />
              <h5 style={{ margin: '0 8px' }}>OR</h5>
              <Button
                style={{
                  display: 'inline-block',
                  cursor: 'pointer',
                  backgroundColor: '#eaedf2',
                  color: '#444444',
                }}
                onClick={() => {
                  window.open(productsUploadSampleFile, '_blank');
                }}
              >
                <FontAwesomeIcon icon={faDownload} />
                &nbsp;&nbsp;Download Template from here
              </Button>
            </div>
          )}
        </div>
      </div>
    </Card>
  );
};

export default AddSchemeDetails;
