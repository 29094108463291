import Parse from "parse";

export const getAllCompaniesAndCategoriesData = () => {
    return Parse.Cloud.run("getAllCompaniesAndCategoriesData");
}

export const getProductsBasedOnCategoryAndCompany = (data) => {
    return Parse.Cloud.run("getProductsBasedOnCategoryAndCompany", data);
}

export const getProductDataByVANNo = (input) => {
    return Parse.Cloud.run("getProductDataByVanNo", input);
}