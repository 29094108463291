import React from 'react';
import Datetime from 'react-datetime';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { Form, InputGroup } from '@themesberg/react-bootstrap';
const DatePicker = ({
  value,
  setValue,
  isInvalid,
  disabled,
  errorMessage,
  placeholder,
}) => {
  const handleDateChange = (newValue) => {
    setValue(new Date(newValue));
  };
  return (
    <Datetime
      timeFormat={false}
      closeOnSelect={true}
      onChange={handleDateChange}
      renderInput={(props, openCalendar) => (
        <InputGroup>
          <InputGroup.Text
            style={{
              backgroundColor: '#eaedf2',
              borderColor: isInvalid && 'red',
            }}
          >
            <FontAwesomeIcon icon={faCalendarAlt} className="icon icon-xs" />
          </InputGroup.Text>
          <Form.Control
            isInvalid={isInvalid}
            required
            type="text"
            value={value ? value.toLocaleDateString() : ''}
            placeholder={placeholder || 'Select date: dd/mm/yyyy'}
            onFocus={openCalendar}
            disabled={disabled}
            style={{ backgroundColor: '#eaedf2' }}
          />
          {isInvalid && errorMessage && (
            <Form.Control.Feedback type="invalid">
              {errorMessage}
            </Form.Control.Feedback>
          )}
        </InputGroup>
      )}
    />
  );
};
export default DatePicker;
