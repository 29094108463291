import {
  SET_EXCEL_PRODUCT_UPLOAD_DATA_IN_STORE,
  SET_EXCEL_MISMATCHED_PRODUCT_UPLOAD_DATA_IN_STORE,
  SET_SHOW_MISMATCH_PRODUCTS_MODAL,
} from '../types';

export const setExcelProductUploadDataInStore = (data) => {
  return {
    type: SET_EXCEL_PRODUCT_UPLOAD_DATA_IN_STORE,
    data,
  };
};

export const setExcelMismatchedProductUploadDataInStore = (data) => {
  return {
    type: SET_EXCEL_MISMATCHED_PRODUCT_UPLOAD_DATA_IN_STORE,
    data,
  };
};

export const setShowMismatchProductsModal = (data) => {
  return {
    type: SET_SHOW_MISMATCH_PRODUCTS_MODAL,
    data,
  };
};
