import React from "react";
import WarehouseTargetPage from "../purchase/assigned";
import { Routes } from "../../../router/routes";
import { useTranslation } from "react-i18next";

const CancelledTargets = () => {
    const { t } = useTranslation();
    const status = "cancelled";
    const breadCrumbItems = [{
        title: t("WMS"),
        link: Routes.WMS.Target.Assign.path
    }, {
        title: t("Cancelled Targets"),
        link: Routes.WMS.Target.Cancelled.path
    }]
    return (
        <WarehouseTargetPage
            pageName={"cancelledTargets"}
            status={status}
            bulkActionOptions={[]}
            pageTitle={"Cancelled Targets"}
            pageSubTitle={"See all the Cancelled items."}
            breadCrumbItems={breadCrumbItems}
        />
    )
}
export default CancelledTargets