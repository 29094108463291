import {
    SET_SELECTED_TARGETS_IN_STORE,
    SET_TARGETS_LIST_IN_STORE,
    UPDATE_WAREHOUSE_TARGET_ITEMS_IN_STORE,
    MARK_TARGET_ITEMS_AS_CANCELLED
} from "../types"

export const setSelectedTargetListInStore = (data) => {
    return {
        type: SET_SELECTED_TARGETS_IN_STORE,
        data
    }
}

export const setTargetsListInStore = (data) => {
    return {
        type: SET_TARGETS_LIST_IN_STORE,
        data
    }
}

export const updateWarehouseTargetItemsInStore = (data) => {
    return {
        type: UPDATE_WAREHOUSE_TARGET_ITEMS_IN_STORE,
        data
    }
}

export const markTargetItemsAsCancelled = (data) => {
    return {
        type: MARK_TARGET_ITEMS_AS_CANCELLED,
        data
    }
}