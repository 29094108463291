import React, { useState } from "react";
import ParcelStatusPage from "../../components/common/parcelStatusPages"
import { useDispatch, useSelector } from "react-redux";
import { requisitionSentTableColumns } from "../buyback/requisitionUtils";
import { useTranslation } from "react-i18next";
import { Routes } from "../../router/routes";
import { updateSpinnerState } from "../../actions/spinner";
import { getRequisitionsFromServer } from "../../parse-functions/requisitions";
import { updateToastInfo } from "../../actions/settings";
import { convertArrayToObj } from "../../utils/json";
import { setSentRequisitionsInStore } from "../../actions/requisitions";
import { setFiltersInStore } from "../../actions/filters";
import { returnEndOfDay, returnStartOfDay } from "../../utils/datetime";
import RequisitionUpdateModal from "./requisitionUpdateModal";

const RequisitionCreated = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const pageType = "requisitionsCreated";
    const [showRemarkModal, setShowRemarkModal] = useState(false);
    const storeCode = useSelector((state) => state?.user?.warehouse?.warehouseCode || "");
    const requisitonItems = useSelector((state) => state?.requisitions?.sent || {});
    const filtersValue = useSelector((state) => state.filters?.[pageType]);
    const [readOnlyMode, setReadOnlyMode] = useState(false);
    const [selectedRequisitionId, setSelectedRequisitionId] = useState(null);

    const breadCrumbItems = [{
        title: t("Requisitions"),
        link: Routes.Requisitions.Created.path
    }, {
        title: t("Created"),
        link: ""
    }];

    const handleFiltersSubmit = (startDate, endDate) => {
        dispatch(setFiltersInStore({
            pageType,
            data: {
                startDate: new Date(startDate),
                endDate: new Date(endDate)
            }
        }))
        getAllSentRequisitions(startDate, endDate);
    };

    const getAllSentRequisitions = async (startDate, endDate) => {
        try {
            dispatch(updateSpinnerState(true));
            const requisitions = await getRequisitionsFromServer({
                filters: {
                    startDate: new Date(startDate),
                    endDate: new Date(endDate),
                    createdByBranch: storeCode
                }
            });
            // const reqsItems = combineMultipleReqsIntoOne(requisitions);
            dispatch(setSentRequisitionsInStore(convertArrayToObj(Object.values(requisitions), "objectId")))
            dispatch(updateSpinnerState(false));
            dispatch(updateToastInfo({
                show: true,
                type: 'success',
                title: t('Requisitions fetched'),
                message: t("These are all the requisitions sent out")
            }))
        } catch (e) {
            console.log(e);
            dispatch(updateToastInfo({
                show: true,
                type: 'danger',
                title: t('Requisitions failed'),
                message: t("Requisitions failed to fetch. Please contact support")
            }))
            dispatch(updateSpinnerState(false));
        }
    }

    const onRefreshClick = () => {
        const currentStartDate = filtersValue.startDate ? new Date(filtersValue.startDate) : returnStartOfDay(new Date());
        const currentEndDate = filtersValue.endDate ? new Date(filtersValue.endDate) : returnEndOfDay(new Date());
        getAllSentRequisitions(currentStartDate, currentEndDate);
    }
    const showItemsModal = (objectId) => {
        setSelectedRequisitionId(objectId);
        setReadOnlyMode(true);
        setShowRemarkModal(true);   
    }
    return (
        <>
            <ParcelStatusPage
                pageType={pageType}
                filtersValue={filtersValue}
                filterSubmit={handleFiltersSubmit}
                tableData={Object.values(requisitonItems)}
                tableColumns={requisitionSentTableColumns(showItemsModal)}
                handleRefresh={onRefreshClick}
                pageTitle={t("Requisitions Created")}
                breadCrumbItems={breadCrumbItems}
                pageSubTitle={t("All the requisitions that have been created by your warehouse")}
                searchPlaceHolder={t("Search by Requisition number")}
                hideBulkActions
            />
            <RequisitionUpdateModal
                readOnly
                showUpdateModal={showRemarkModal}
                handleCloseModal={() => {
                    setShowRemarkModal(false);
                }}
                askRequisitionTransferNo={false}
                requisitonItems={requisitonItems[selectedRequisitionId] || {}}
            />
        </>
    )
}
export default RequisitionCreated;