import { MARK_LOADER_AS_SELECTED, SET_LOADERS_LIST_IN_STORE } from "../actions/types";

const INITIAL_STATE = {
    loadersList: []
};
const loadersReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_LOADERS_LIST_IN_STORE: {
            return {
                ...state,
                loadersList: action.data
            }
        }
        case MARK_LOADER_AS_SELECTED: {
            const {
                objectId,
                selected
            } = action.data;
            const loadersList = JSON.parse(JSON.stringify(state?.loadersList || []));
            // eslint-disable-next-line no-unused-expressions
            loadersList?.forEach((loaderItem) => {
                if (loaderItem.objectId === objectId) {
                    loaderItem.isSelected = selected;
                }
            });
            return {
                ...state,
                loadersList
            }
        }
        default: return state;
    }
};

export default loadersReducer;