import React from "react";
import { Button, ProgressBar, Modal} from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

const OrderAlertForAlreadyScheduledModal = (props) => {
    const { t } = useTranslation();
    const {
        show,
        handleClose,
        buttonText,
        topTitle,
        mainHeaderText,
        order
    } = props;
    return (
        <Modal centered show={show} onHide={handleClose} className="modal-tertiary order-already-scheduled-modal">
            {/* <Modal.Header className="mx-auto">
                <p className="lead mb-0 text-white">{t(topTitle)}</p>
            </Modal.Header>
            */}
            <Modal.Body className="pt-0">
                <div className="py-3 px-5 text-center">
                    <span className="modal-icon display-1">
                        <FontAwesomeIcon icon={faWarning} className="icon icon-xl text-white" />
                    </span>

                    <Modal.Title className="text-white mb-3">
                        <h3>{mainHeaderText}</h3>
                    </Modal.Title>
                    <p className="mb-4 text-white">
                        <div>
                            <span>Invoice Number: </span>
                            <b><span>{order?.invoiceNo}</span></b>
                        </div>
                        
                        <div>
                            <span>Dashboard Status: </span>
                            <b><span>{order?.dashboardStatus || order?.status || order?.mobileStatus}</span></b>
                        </div>
                        <div>
                            <span>SubStatus: </span>
                            <b><span>{order?.dashboardSubStatus || order?.mobileSubStatus}</span></b>
                        </div>
                        <div>
                            <span>Trip Number: </span>
                            <b><span>{order?.tripNumber}</span></b>
                        </div>
                    </p>
                </div>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center pt-2 pb-2">
                <Button variant="white" size="sm" className="text-tertiary" onClick={handleClose}>
                    {buttonText}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
export default OrderAlertForAlreadyScheduledModal;