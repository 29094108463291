import { Badge } from '@themesberg/react-bootstrap';
import React from 'react';
import { getTableRowCheckBox } from '../../../utils/table';
import { getTripBadgeStatus } from '../../../utils/trips';

export const returnDeliveryAgentTableColumns = (onRowSelect) => {
  const columns = [
    {
      dataField: 'objectId',
      text: 'Select',
      formatter: (cell, row) => {
        return getTableRowCheckBox(onRowSelect, cell, row?.isSelected, false);
      },
    },
    {
      dataField: 'driverName',
      sort: true,
      text: 'Name',
      formatter: (cell, row) => {
        return (
          <div className="d-block">
            <span className="fw-bold">{cell}</span>
            {/* <div className="fw-normal">{row?.vendorCode}</div> */}
          </div>
        );
      },
    },
    {
      dataField: 'vehicleNumber',
      text: 'Vehicle Number',
      formatter: (cell, row) => {
        return (
          <div className="d-block">
            <span className="fw-bold">{cell}</span>
          </div>
        );
      },
    },
    {
      dataField: 'contactNumber',
      text: 'Contact Number',
      formatter: (cell, row) => {
        return (
          <div className="d-block">
            <span className="fw-bold">{cell}</span>
          </div>
        );
      },
    },
    {
      dataField: 'vendorName',
      text: 'Vendor Name',
      formatter: (cell, row) => {
        return (
          <div className="d-block">
            <span className="fw-bold">{cell}</span>
          </div>
        );
      },
    },
    {
      dataField: 'zone',
      text: 'Zone',
      formatter: (cell, row) => {
        return (
          <div className="d-block">
            <span className="fw-bold">{cell}</span>
          </div>
        );
      },
    },
    {
      dataField: 'tripLimit',
      text: 'Trip Limit',
      formatter: (cell, row) => {
        return (
          <div className="d-block">
            <span className="fw-bold">{cell}</span>
          </div>
        );
      },
    },
    {
      dataField: 'deliveryLimit',
      text: 'Delivery Limit',
      formatter: (cell, row) => {
        return (
          <div className="d-block">
            <span className="fw-bold">{cell}</span>
          </div>
        );
      },
    },
    {
      dataField: 'status',
      text: 'Status',
      formatter: (cell, row) => {
        return (
          <div className="d-block">
            <div className="fw-bold">
              <span>
                &nbsp;&nbsp;
                <Badge bg={getTripBadgeStatus(cell)} className="badge-lg">
                  {cell?.toUpperCase()}
                </Badge>
                &nbsp;&nbsp;
              </span>
            </div>
          </div>
        );
      },
    },
  ];
  return columns;
};

export const addDeliveryAgentSchema = [
  [
    {
      title: 'Agent & Vendor Info',
      type: 'header',
    },
    {
      title: 'Vehicle Number',
      id: 'vehicleNumber',
      type: 'text',
      required: true,
    },
    {
      title: 'Driver Name',
      id: 'driverName',
      type: 'text',
      required: true,
    },
    {
      title: 'Contact Number',
      id: 'contactNumber',
      type: 'number',
      required: true,
    },
    {
      title: 'Vendor Name',
      id: 'vendorName',
      type: 'select',
      options: [],
      required: true,
      placeholder: 'Select Vendor Name',
    },
  ],
  [
    {
      title: 'Starting Zone',
      id: 'startingZone',
      type: 'select',
      options: [],
      required: true,
      placeholder: 'Select a Zone',
    },
    {
      title: 'Region',
      id: 'region',
      type: 'select',
      options: [],
      required: true,
      placeholder: 'Select a Region',
    },
    {
      title: 'Trip Limit',
      id: 'tripLimit',
      type: 'number',
      required: true,
    },
    {
      title: 'Delivery Limit',
      id: 'deliveryLimit',
      type: 'number',
      required: true,
    },
    {
      title: 'Submit',
      type: 'button',
    },
  ],
];

export const AddDeliveryVendorSchema = [
  [],
  [
    {
      title: 'Vendor Details',
      type: 'header',
    },
    {
      title: 'Vendor Name',
      id: 'vendorName',
      type: 'text',
      required: true,
    },
    {
      title: 'Vendor Code',
      id: 'vendorCode',
      type: 'text',
      required: true,
    },
    {
      title: 'Submit',
      type: 'button',
    },
  ],
  [],
];

export const regionTeamSchema = [
  [
    {
      title: 'User Info',
      type: 'header',
    },
    {
      title: 'Name',
      type: 'text',
      id: 'name',
      required: true,
    },
    {
      title: 'Phone Number',
      id: 'phoneNumber',
      type: 'number',
      required: true,
    },
    {
      title: 'Email',
      id: 'email',
      type: 'email',
      required: false,
    },
    {
      title: 'Zone',
      id: 'zone',
      type: 'select',
      isMultiSelect: true,
      options: [],
      required: true,
      placeholder: 'Select zone',
    },
  ],
  [
    {
      title: 'Login Info',
      type: 'header',
    },
    {
      title: 'UserName',
      id: 'username',
      type: 'text',
      required: true,
    },
    {
      title: 'Password',
      id: 'password',
      type: 'text',
      required: true,
    },
    {
      title: 'Repeat Password',
      id: 'repeatPassword',
      type: 'text',
      required: true,
    },
    {
      title: 'Create user',
      type: 'button',
    },
    {
      title: 'Cancel',
      type: 'button',
    },
  ],
];
