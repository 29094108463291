import React, { useEffect, useState } from 'react';
import logo from '../../assets/img/brand/vijay-sales-logo.svg';
import { returnUserReadableDateTime } from '../../utils/datetime';
import Barcode from 'react-barcode';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateSpinnerState } from '../../actions/spinner';
import { updateToastInfo } from '../../actions/settings';
import { getPaymentVoucherById } from '../../parse-functions/payments';
import { useTranslation } from 'react-i18next';

const VoucherPrint = () => {
  const { t } = useTranslation();
  const all = useParams();
  const { objectId } = useParams();
  const dispatch = useDispatch();
  const [voucherData, setVoucherData] = useState({});
  const [isHalfPage, setIsHalfPage] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(updateSpinnerState(true));
        const res = await getPaymentVoucherById({ id: objectId });
        setVoucherData(res);
        setIsHalfPage(res.tripsDetails && res.tripsDetails.length <= 4);
        dispatch(updateSpinnerState(false));
        setPrintAttributesToPage();
      } catch (error) {
        dispatch(updateSpinnerState(false));
        dispatch(
          updateToastInfo({
            show: true,
            type: 'danger',
            title: 'Failed to get Voucher Data',
            message: t(
              'Failed to get Voucher data from server. Please try again or contact support'
            ),
          })
        );
      }
    };
    fetchData();
  }, [objectId, dispatch, t]);

  const setPrintAttributesToPage = () => {
    const css =
      '@page { size: A4 potrait; margin: 0mm 0mm 0mm 0mm; color: #000000 !important; }';
    const head = document.head || document.getElementsByTagName('head')[0];
    const style = document.createElement('style');
    style.type = 'text/css';
    style.media = 'print';

    if (style.styleSheet) {
      style.styleSheet.cssText = css;
    } else {
      style.appendChild(document.createTextNode(css));
    }
    head.appendChild(style);
    window.print();
    // window.close();
  };

  const containerStyle = {
    color: '#000000',
    height: isHalfPage ? '50vh' : '100vh',
  };

  const tableStyle = {
    color: '#000000',
    maxHeight: isHalfPage ? '35vh' : '70vh',
    overflowY: 'auto',
  };

  const listItemStyle = {
    padding: '0',
    margin: '0',
    fontSize: '14px',
  };

  const thTdStyle = {
    padding: '5px',
    fontSize: '10px',
    // border: 'none',
  };

  const trStyle = {
    margin: '2px 0',
  };

  return (
    <div className="d-flex w-100" style={containerStyle}>
      <div className="px-4 py-2 card border-0 w-100">
        <div className="d-sm-flex justify-content-between border-bottom border-light">
          <img
            alt="Vijay Sales"
            src={logo}
            width="200"
            height="80"
            className="image-md"
          />
          <div>
            <h5>Vijay Sales India Pvt. Ltd.</h5>
            <div className="simple-list list-group">
              <div
                className="fw-normal list-group-item"
                style={{
                  paddingTop: 0,
                  paddingBottom: 0,
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                {voucherData?.createdByBranch}
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-center mb-1">
          <h1 className="mb-0">
            <Barcode
              height={25}
              font='"Nunito Sans", sans-serif'
              fontSize={14}
              value={voucherData?.voucherNumber}
            />
          </h1>
        </div>
        <div className="row mb-1">
          <div className="col-sm-6 col-lg-6">
            <h5>Voucher Information:</h5>
            <div className="list-group simple-list">
              <div className="fw-normal list-group-item" style={listItemStyle}>
                <strong>Warehouse Name:</strong> {voucherData?.createdByBranch}
              </div>
              <div className="fw-normal list-group-item" style={listItemStyle}>
                <strong>Created By:</strong>{' '}
                {voucherData?.actionLog && voucherData.actionLog[0]?.user}
              </div>
              <div className="fw-normal list-group-item" style={listItemStyle}>
                <strong>Creation Date:</strong>{' '}
                {voucherData?.createdAt &&
                  returnUserReadableDateTime(voucherData.createdAt)}
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-6 mt-4">
            <div className="list-group simple-list">
              <div className="fw-normal list-group-item" style={listItemStyle}>
                <strong>Voucher No.:</strong> {voucherData?.voucherNumber}
              </div>
              <div className="fw-normal list-group-item" style={listItemStyle}>
                <strong>Total Amount: {voucherData?.totalAmount}</strong>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="table-responsive" style={tableStyle}>
              <table className="mb-0 table">
                <thead className="bg-light border-top">
                  <tr style={trStyle}>
                    <th className="border-0" style={thTdStyle}>
                      Trip Number
                    </th>
                    <th className="border-0" style={thTdStyle}>
                      Payment Type
                    </th>
                    <th className="border-0" style={thTdStyle}>
                      Amount
                    </th>
                  </tr>
                </thead>
                <tbody className="border-0" style={thTdStyle}>
                  {voucherData?.tripsDetails &&
                    Object.values(voucherData?.tripsDetails)?.map(
                      (trip, index) => (
                        <tr key={index} style={trStyle}>
                          <td className="border-0" style={thTdStyle}>
                            {trip?.tripNumber || '-'}
                          </td>
                          <td className="border-0" style={thTdStyle}>
                            {trip?.paymentType || '-'}
                          </td>
                          <td className="border-0" style={thTdStyle}>
                            {trip?.amount || '-'}
                          </td>
                        </tr>
                      )
                    )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VoucherPrint;
