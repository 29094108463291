export const generateRandomId = (length) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
}


export const getCustomerAvatarName = (name) => {
  if (!name || name?.length === 0) {
    return name;
  }
  let returnVal = name?.length > 0 ? "" : "";
  let nameParts = name?.split(" ");
  if (nameParts?.length === 1) {
    nameParts = name?.split("_");
  }
  if (nameParts?.[0] && nameParts?.[0]?.length > 0) {
    returnVal += nameParts[0][0]
  }
  if (nameParts[1] && nameParts[1]?.length > 0) {
    if (["(", ")"].indexOf(nameParts[1][0]) > -1) {
      returnVal += nameParts[1][1]
    } else {
      returnVal += nameParts[1][0]
    }
    
  }
  return returnVal?.toUpperCase();
}