import { Col, Image, Modal, Row, Table } from "@themesberg/react-bootstrap";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { updateToastInfo } from "../../actions/settings";
import { updateSpinnerState } from "../../actions/spinner";
import { getTripInfoByObjectId } from "../../parse-functions/trips";
import VijaySalesLogo from "../../assets/img/brand/vijay-sales-logo.svg";
import { StyledTD } from "./styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileInvoice } from "@fortawesome/free-solid-svg-icons";
import { sortArrayOfJSONByKeyName } from "../../utils/json";

const TableRow = (props) => {
  const { index, invoiceNo, branch, customerName, status, mobileNo, shippingMobileNo, product, serialNo, quantity, buybackProduct, buyback } = props;

  return (
    <tr>
      <StyledTD>
        {index + 1}
      </StyledTD>
      <StyledTD>
        {invoiceNo}
      </StyledTD>
      <StyledTD>
        {branch}
      </StyledTD>
      <StyledTD>
        <div>{customerName}</div>
        <div>{mobileNo || shippingMobileNo || '-'}</div>
      </StyledTD>
      <StyledTD>
        <div>{product}</div>
        <div>{serialNo}</div>
      </StyledTD>
      <StyledTD>
        {Number(quantity).toFixed(0)}
      </StyledTD>
      <StyledTD>
        <div>
          {buyback ? "Yes" : ""}
        </div>
      </StyledTD>
      <StyledTD>
        <div>
          {buybackProduct || ""}
        </div>
      </StyledTD>
      <td>
      </td>
    </tr>
  );
}
const PrintTripSheet = () => {
  const { tripId } = useParams();
  const dispatch = useDispatch();
  const [tripInfo, setTripInfo] = useState({});
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  useEffect(() => {
    getTripInformation();
  }, []);
  const getTripInformation = async () => {
    dispatch(updateSpinnerState(true));
    try {
      const tripInfo = await getTripInfoByObjectId(tripId);
      setTripInfo(tripInfo);
      const css = '@page { size: landscape; margin: default; color: #000000 !important; }';
      const head = document.head || document.getElementsByTagName('head')[0];
      const style = document.createElement('style');
      style.type = 'text/css';
      style.media = 'print';

      if (style.styleSheet) {
        style.styleSheet.cssText = css;
      } else {
        style.appendChild(document.createTextNode(css));
      }
      head.appendChild(style);
      window.print();
      // window.close();
    } catch (e) {
      dispatch(updateSpinnerState(false));
      setErrorMessage(e.message);
      setShowErrorModal(true);
      dispatch(updateToastInfo({
        show: true,
        type: 'danger',
        title: 'Error in Trip',
        message: e.message ? e.message : 'Error Getting Trip Information. Contact Support.'
      }))
    }
  }
  const returnLoaderInfoToPrint = (loaderObject) => {
    let loaderString = [];
    for (let i = 1; i < 6; i += 1) {
      if (loaderObject[`loaderName${i}`] && loaderObject[`loaderNumber${i}`]) {
        loaderString.push(`${loaderObject[`loaderName${i}`]} (${loaderObject[`loaderNumber${i}`]})`)
      }
    }
    return loaderString.join(", ");
  };

  const sortedParcelsInOrder = tripInfo?.parcels ? sortArrayOfJSONByKeyName(tripInfo?.parcels, "invoiceNo") : [];
  return (
    <div style={{
      paddingLeft: 20,
      color: '#000000'
    }} className="d-flex flex-column">
      <Row className="d-flex align-items-center">
        <Col>
          <Image src={VijaySalesLogo} height={40} width={200} />
        </Col>
        <Col>
          <h2>{tripInfo?.tripInfo?.vehicleNumber}</h2>
        </Col>
        <Col>
          <h5>{tripInfo?.tripInfo?.warehouseCode}</h5>
        </Col>
      </Row>
      <Row className="mt-3 small">
        <Col>
          <b>Trip Number:</b> {tripInfo?.tripInfo?.tripNumber}
        </Col>
        <Col>
          <b>Date:</b> {new Date(tripInfo?.tripInfo?.dispatchedAt).toLocaleString()}
        </Col>
        <Col>
          <b>Location</b> : {tripInfo?.tripInfo?.convertedBy}
        </Col>
      </Row>
      <Row className="mt-2 small">
        <Col>
          <b>Loaders:</b> {returnLoaderInfoToPrint(tripInfo?.tripInfo?.dispatchData || {})}
          {/* {`${tripInfo?.tripInfo?.dispatchData?.loaderName1}(${tripInfo?.tripInfo?.dispatchData?.loaderNumber1}), ${tripInfo?.tripInfo?.dispatchData?.loaderName2}(${tripInfo?.tripInfo?.dispatchData?.loaderNumber2}) ${tripInfo?.tripInfo?.dispatchData?.loaderName1}(${tripInfo?.tripInfo?.dispatchData?.loaderNumber1}), ${tripInfo?.tripInfo?.dispatchData?.loaderName2}(${tripInfo?.tripInfo?.dispatchData?.loaderNumber2}) ${tripInfo?.tripInfo?.dispatchData?.loaderName1}(${tripInfo?.tripInfo?.dispatchData?.loaderNumber1}), ${tripInfo?.tripInfo?.dispatchData?.loaderName2}(${tripInfo?.tripInfo?.dispatchData?.loaderNumber2})`} */}
        </Col>
      </Row>
      <Row className="mt-4">
        <Table hover style={{ fontSize: '12px !important', color: '#000000', border: '1px solid black' }} className="user-table align-items-center">
          <thead>
            <tr>
              <th className="border-bottom">S NO</th>
              <th className="border-bottom">Invoice #</th>
              <th className="border-bottom">Branch</th>
              <th className="border-bottom">Name</th>
              <th className="border-bottom w-25">Product & Serial</th>
              <th className="border-bottom">Qty</th>
              <th className="border-bottom">Buyback</th>
              <th className="border-bottom">BB Details</th>
              <th className="border-bottom">Sign</th>
            </tr>
          </thead>
          <tbody>
            {sortedParcelsInOrder?.map((t, index) => <TableRow key={`transaction-${t.invoiceNo}`} {...t} index={index} />)}
          </tbody>
        </Table>
      </Row>
      <Row className="mt-4">
        <Col>Total Quantity:</Col>
        <Col>Received Quantity:</Col>
        <Col />
      </Row>
      <Row className="mt-4">
        <Col>Unloading Date:</Col>
        <Col>Unloading Time:</Col>
        <Col>Incharge Sign:</Col>
      </Row>
      <Row className="mt-4">
        <Col>Outward Stamp:</Col>
        <Col>Inward Stamp:</Col>
        <Col />
      </Row>
      <Modal centered show={showErrorModal} className="modal-tertiary order-already-scheduled-modal">
        <Modal.Body className="pt-0">
          <div className="py-3 px-5 text-center">
            <span className="modal-icon display-1">
              <FontAwesomeIcon icon={faFileInvoice} className="icon icon-xl text-white" />
            </span>

            <Modal.Title className="text-white mb-3">
              <h3>{errorMessage}</h3>
            </Modal.Title>
            <p className="mb-4 text-white">
            </p>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default PrintTripSheet