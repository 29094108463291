import React, { useState, useMemo, useEffect } from 'react';
import BreadCrumbComponent from '../../../components/common/Breadcrumb';
import arrayToBreadCrumbs from '../../../utils/sms/arrayToBreadCrumbs';
import { useTranslation } from 'react-i18next';
import { Form, Row, Col, Button } from '@themesberg/react-bootstrap';
import EditableDropDown from '../../../components/common/EditableDropdown';
import Creatable from 'react-select/creatable';
import { Routes } from '../../../router/routes';
import Child from './Child';
import { useDispatch } from 'react-redux';
import { updateToastInfo } from '../../../actions/settings';
import { updateSpinnerState } from '../../../actions/spinner';
import { addOrUpdateRoles, getAllRoles } from '../../../parse-functions/roles';
import { arrayToDropdownOptions } from '../../../utils/sms/arrayToDropdownOptions';

const Roles = () => {
  const { t } = useTranslation();
  const breadCrumbItems = arrayToBreadCrumbs([['Admin'], ['Role']]);

  const [roleOptions, setRoleOptions] = useState([]);
  const [role, setRole] = useState([]);
  const [routes, setRoutes] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [children, setChildren] = useState([]);
  const [nestedOptions, setNestedOptions] = useState({});
  const [subChildSelected, setSubChildSelected] = useState([]);
  const roles = arrayToDropdownOptions(roleOptions);
  const dispatch = useDispatch();

  const getRoutesAsOptions = (routes, parentKey = '') => {
    let options = [];

    Object.keys(routes).forEach((key) => {
      const route = routes[key];
      const routeKey = parentKey ? `${parentKey}.${key}` : key;

      if (typeof route === 'object' && !route.path) {
        const children = getRoutesAsOptions(route, routeKey);

        if (children.length > 0) {
          options.push({
            label: `Routes.${routeKey}`,
            value: routeKey,
            children: children,
          });
        } else {
          options.push({
            label: `Routes.${routeKey}`,
            value: routeKey,
          });
        }
      } else if (route.path) {
        options.push({
          label: `Routes.${routeKey}.path`,
          value: route.path,
        });
      }
    });

    return options;
  };

  const routeOptions = useMemo(() => {
    return getRoutesAsOptions(Routes);
  }, [Routes]);

  const handleRoleChange = (option) => {
    setFormErrors((prev) => ({ ...prev, role: '' }));
    setRole(option);
  };

  const handleRouteChange = (selectedOptions) => {
    setFormErrors((prev) => ({ ...prev, routes: '' }));
    const routes = selectedOptions.map((option) => option.value);
    setRoutes(routes);

    let res = [];
    selectedOptions
      .filter((option) => option.children)
      .forEach((option) => {
        res = [...res, ...option.children];
      });

    if (res.length > 0) {
      if (children.length === 0) {
        setChildren((prev) => [...prev, { id: Date.now(), routes: [] }]);
      }
      setNestedOptions(res);
    } else {
      setNestedOptions([]);
      setChildren([]);
    }
  };

  const handlechildRouteUpdate = (id, value) => {
    setChildren(
      children.map((child) =>
        child.id === id ? { ...child, routes: value } : child
      )
    );
    setSubChildSelected(value);
  };

  const handleSubmitCall = async (roles, routes) => {
    try {
      dispatch(updateSpinnerState(true));
      const res = await addOrUpdateRoles({ roles, routes });
      // if (res?.objectId) {
      setRole([]);
      setRoutes([]);
      setChildren([]);
      setNestedOptions([]);
      setSubChildSelected([]);
      // }
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'success',
          title: '',
          message: `role added/updated successfully!`,
        })
      );
    } catch (error) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: 'Failed to add/update role',
          message: `${error.message}`,
        })
      );
    }
  };

  const findSubChild = (arr) => {
    return (
      arr?.find((each) => each.includes('.') && each.split('.').length === 3) ||
      ''
    );
  };

  const handleSubmit = () => {
    let errors = {};

    if (role.length === 0) {
      errors['role'] = 'Role must not be empty!';
    }

    if (routes.length === 0) {
      errors['routes'] = 'Please select at least one route';
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }
    let allRoutes = [];
    let rout = routes.filter((r) => r.includes('/') || r === 'all');
    let childrr =
      children.length > 0
        ? children[0]?.routes.filter((r) => r.includes('/') || r === 'all')
        : [];

    allRoutes = [...rout, ...childrr];
    const collectRoutes = (options) => {
      options.forEach((option) => {
        if (option.children) {
          collectRoutes(option.children);
        } else {
          allRoutes.push(option.value);
        }
      });
    };
    if (rout.includes('all')) {
      collectRoutes(routeOptions);
    } else {
      if (childrr.includes('all')) {
        let parent = routeOptions.filter((rr) => routes.includes(rr.value));
        if (subChildSelected.length > 1 && subChildSelected.includes('all')) {
          let child = parent
            .map((p) =>
              p.children
                ? p.children.filter((c) => subChildSelected.includes(c.value))
                : []
            )
            .flat();
          const isSubChildAvailable = findSubChild(subChildSelected);
          if (isSubChildAvailable) {
            let subChild = child
              .map((p) =>
                p.children
                  ? p.children.filter((c) => c.value === isSubChildAvailable)
                  : []
              )
              .flat();
            collectRoutes(subChild);
          } else {
            collectRoutes(child);
          }
        } else {
          collectRoutes(parent);
        }
      }
    }
    const uniqueRoutes = new Set(allRoutes);
    const finalRoutes = [...uniqueRoutes]
      .filter((route) => route.includes('/'))
      .map((route) => {
        if (route.includes(':')) {
          return route
            .split('/')
            .filter((r) => !r.includes(':'))
            .join('/');
        }
        return route;
      });
    const roles = role.map((option) => option.value);

    console.log(roles, finalRoutes);
    handleSubmitCall(roles, finalRoutes);
  };

  const fetchRoles = async () => {
    try {
      dispatch(updateSpinnerState(true));
      const roles = await getAllRoles();
      setRoleOptions(roles?.flat());
      dispatch(updateSpinnerState(false));
    } catch (error) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: 'failed to fetch roles!',
          message: `${error.message}`,
        })
      );
    }
  };

  useEffect(() => {
    fetchRoles();
  }, []);

  return (
    <div className="mt-1">
      <div className="mb-4 d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-2">
        <div className="d-block mb-4 mb-md-0">
          <BreadCrumbComponent items={breadCrumbItems} />
          <h4>{t('Add New Role/ Update from here ')}</h4>
        </div>
      </div>
      <div className="d-flex gap-3 flex-column align-items-center">
        <Col md={5}>
          <Form.Group>
            <Form.Label>{t('Role')}</Form.Label>
            <Creatable
              value={role}
              isMulti={true}
              onChange={handleRoleChange}
              options={roles}
              placeholder="Select Or Type an Option"
              className={formErrors['role'] ? 'is-invalid' : ''}
            />
            {formErrors['role'] && (
              <div className="invalid-feedback d-block">
                {t('Please enter role.')}
              </div>
            )}
          </Form.Group>
        </Col>
        <Col md={5}>
          {' '}
          <Form.Group>
            <Form.Label>{t('Routes')}</Form.Label>
            <EditableDropDown
              isMultiSelect
              onChange={handleRouteChange}
              options={[{ label: 'All', value: 'all' }, ...routeOptions]}
              placeholder="Select Routes from below"
              className={formErrors['routes'] ? 'is-invalid' : ''}
            />
            {formErrors['routes'] && (
              <div className="invalid-feedback d-block">
                {t('Please select routes.')}
              </div>
            )}
          </Form.Group>
        </Col>
        {children?.map((child) => (
          <Col md={5} key={child.id}>
            <Child
              id={child.id}
              title={'please select sub route'}
              updateRoutes={handlechildRouteUpdate}
              routeOptions={nestedOptions}
            />
          </Col>
        ))}
        <Col md={5}>
          <Button style={{ width: '100%' }} onClick={handleSubmit}>
            {t('Submit')}
          </Button>
        </Col>
      </div>
    </div>
  );
};

export default Roles;
