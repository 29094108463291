import { Button, Form } from '@themesberg/react-bootstrap';
import { returnUserReadableLocalDate } from '../../utils/datetime';
import { getTableRowCheckBox } from '../../utils/table';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRemove } from '@fortawesome/free-solid-svg-icons';

export const inputValidate = (data, setFormErrors) => {
  const errors = {};
  let isValid = true;
  if (!data.fromDate) {
    errors['fromDate'] = 'Cannot be Empty';
    isValid = false;
  }
  if (!data.toDate) {
    errors['toDate'] = 'Cannot be Empty';
    isValid = false;
  }
  if (data.toDate < data.fromDate) {
    errors['toDate'] = 'Cannot be before From Date';
    isValid = false;
  }
  if (data.vehicleNumber.length > 10) {
    errors['vehicleNumber'] = 'Length cannot be more than 10';
    isValid = false;
  }
  if (!data.vehicleNumber && !data.vendorName) {
    errors['vendorName'] = 'Both cannot be Empty!';
    errors['vehicleNumber'] = 'Both cannot be Empty!';
    isValid = false;
  }
  setFormErrors(errors);
  return isValid;
};

export const paymentInputValidate = (formInput, setFormErrors) => {
  const errors = {};
  let isValid = true;
  if (!formInput.paymentMethod) {
    errors['paymentMethod'] = 'Cannot be Empty!';
    isValid = false;
  }
  if (!formInput.invoiceNumber) {
    errors['invoiceNumber'] = 'Cannot be Empty!';
    isValid = false;
  }

  if (!formInput.invoiceDate) {
    errors['invoiceDate'] = 'Cannot be Empty!';
    isValid = false;
  }
  if (!formInput.paymentDate) {
    errors['paymentDate'] = 'Cannot be Empty!';
    isValid = false;
  }
  if (!formInput.paymentRemarks) {
    errors['paymentRemarks'] = 'Cannot be Empty!';
    isValid = false;
  }
  setFormErrors(errors);
  return isValid;
};

export const unpaidTripsColumns = ({
  onRowSelect,
  isModal,
  handleInputChange,
  isFinalTextField,
  isDelete,
  setFunction,
  isdisabled,
  isPaid,
}) => {
  const columns = [
    {
      dataField: 'tripNumber',
      text: 'Trip No.',
    },
    {
      dataField: 'driverInfo',
      text: 'Vendor Name',
      formatter: (cell, row) => {
        return <div className="d-block">{cell.vendorName}</div>;
      },
    },
    {
      dataField: 'driverInfo',
      text: 'Vehicle Details',
      formatter: (cell, row) => {
        return (
          <div className="d-block">
            <div>{cell?.vehicleNumber}</div>
            <div>{cell?.paymentMode}</div>
            <div>{cell?.vehicleType}</div>
          </div>
        );
      },
    },
    {
      dataField: 'deliveryRateInfo',
      text: 'Deliveries',
      formatter: (cell, row) => {
        return (
          <div className="d-block">
            <div>{`Quantity - ${cell?.deliveryQuantity || 0}`}</div>
            <div>{`Defective - ${cell?.defectivePicked || 0}`}</div>
            <div>{`Packages - ${cell?.package || 0}`}</div>
          </div>
        );
      },
    },
    {
      dataField: 'finalDeliveryRateInfo',
      text: 'Final Deliveries',
      formatter: (cell, row) => {
        return (
          <div className="d-block">
            <div>{`Quantity - ${cell?.deliveryQuantity || 0}`}</div>
            <div>{`Defective - ${cell?.defectivePicked || 0}`}</div>
            <div>{`Packages - ${cell?.package || 0}`}</div>
          </div>
        );
      },
    },
    {
      dataField: 'tripDate',
      text: 'Trip Date',
      formatter: (cell, row) => {
        return (
          <div className="d-block">
            {returnUserReadableLocalDate(row.dispatchedAt)}
          </div>
        );
      },
    },
    {
      dataField: 'totalAmount',
      text: 'Total Amount',
    },
  ];
  if (!isModal) {
    columns.splice(0, 0, {
      dataField: 'objectId',
      text: 'Select',
      formatter: (cell, row) => {
        return getTableRowCheckBox(onRowSelect, cell, row?.isSelected, false);
      },
    });
  }
  if (isFinalTextField) {
    columns.push({
      dataField: 'finalPayment',
      text: 'Final Payment',
      formatter: (cell, row) => {
        return (
          <Form.Group>
            <Form.Control
              type="number"
              id={row.objectId}
              value={cell}
              onChange={(e) => handleInputChange(row.objectId, e)}
              disabled={isdisabled}
            />
          </Form.Group>
        );
      },
    });
  }

  if (isPaid) {
    columns.push({
      dataField: 'finalPayment',
      text: 'Final Payment',
    });
  }

  if (isDelete) {
    columns.push({
      dataField: 'objectId',
      text: '',
      formatter: (cell, row) => {
        return (
          <Button
            onClick={() => {
              setFunction((prev) =>
                prev.filter((item) => item.objectId !== cell)
              );
            }}
          >
            <FontAwesomeIcon icon={faRemove} />
          </Button>
        );
      },
    });
  }
  return columns;
};

export const paidTripsColumns = () => {
  return [
    {
      dataField: 'invoiceNumber',
      text: 'Invoice No.',
    },
    {
      dataField: 'invoiceDate',
      text: 'Invoice Date',
      formatter: (cell, row) => {
        return (
          <div className="d-block">
            {returnUserReadableLocalDate(row.invoiceDate)}
          </div>
        );
      },
    },
    {
      dataField: 'vendorName',
      text: 'Vendor Name',
    },
    {
      dataField: 'totalTrips',
      text: 'Total Trips',
    },
    {
      dataField: 'totalAmount',
      text: 'Total Amount',
    },
    {
      dataField: 'finalPayment',
      text: 'Final Payment',
    },
  ];
};

export const paidInputValidate = (data, setFormErrors) => {
  let isValid = true;
  let errors = {};
  if (!data.invoiceNumber && !data.vendorName) {
    errors['vendorName'] = 'Both cannot be Empty!';
    errors['invoiceNumber'] = 'Both cannot be Empty!';
  }
  if (!data.fromDate && !data.invoiceNumber) {
    errors['fromDate'] = 'Cannot be Empty';
    errors['invoiceNumber'] = 'Both cannot be Empty!';
    isValid = false;
  }
  if (!data.toDate && !data.invoiceNumber) {
    errors['toDate'] = 'Cannot be Empty';
    errors['invoiceNumber'] = 'Both cannot be Empty!';
    isValid = false;
  }
  if (data.toDate < data.fromDate) {
    errors['toDate'] = 'Cannot be before From Date';
    isValid = false;
  }
  setFormErrors(errors);
  return isValid;
};
