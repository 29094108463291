import React, { useEffect, useMemo, useState } from 'react';
import BreadCrumbTitle from '../../scheme-management/Components/common/BreadCrumbTitle';
import { Routes } from '../../../router/routes';
import { useDispatch, useSelector } from 'react-redux';
import { Button, ButtonGroup, Form } from '@themesberg/react-bootstrap';
import { arrayToDropdownOptions } from '../../../utils/sms/arrayToDropdownOptions';
import EditableDropDown from '../../../components/common/EditableDropdown';
import { useTranslation } from 'react-i18next';
import { useFetchStores } from '../custom-hooks/useFetchStores';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import NewTable from '../../../components/common/NewTable';
import {
  citiesToRemove,
  summaryModalColumns,
  updateInventoryColumns,
} from './utils';
import { updateSpinnerState } from '../../../actions/spinner';
import { apiCall } from '../../../middlewares/api';
import { apiCallConsts } from '../../../constants/apiCallConsts';
import { updateToastInfo } from '../../../actions/settings';
import SummaryModal from './components/SummaryModal';

const UpdateInventory = () => {
  const { t } = useTranslation();
  const product = useSelector((state) => state?.allProducts?.product || {});
  const storeList = useSelector((state) => state?.stores?.storeList || []);
  const cities = useMemo(
    () => [
      ...new Set(
        storeList
          ?.filter(
            (store) =>
              !store.customerPickupAllowed && !citiesToRemove.has(store.city)
          )
          .map((store) => store.city)
      ),
    ],
    [storeList]
  );
  const [selectedCities, setSelectedCities] = useState([]);
  const [copySafety, setCopySafety] = useState(false);
  const [copyInventory, setCopyInventory] = useState(false);
  const [summaryModal, setSummaryModal] = useState(false);
  const [data, setData] = useState([]);
  const [values, setValues] = useState({});
  const dispatch = useDispatch();

  useFetchStores();

  const handleChange = (options) => {
    const selectedOptions = options?.map((option) => option.value);
    setSelectedCities(selectedOptions);
  };

  const handleFetch = async () => {
    if (!Object.keys(product).length > 0) {
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: 'failed!',
          message: 'Product is not found',
        })
      );
      return;
    }
    try {
      const data = {
        vanNo: product?.vanNo,
        citiesArray: selectedCities.join(','),
      };
      dispatch(updateSpinnerState(true));
      const response = await apiCall(
        apiCallConsts.GET_METHOD,
        apiCallConsts.INVENTORY_URL,
        data
      );
      const { city } = response;
      let d = [];

      selectedCities.forEach((item) => {
        const {
          available = 0,
          data = [],
          safetyStock = 0,
          totalStock = 0,
        } = city[item] || {};
        const updatedAvailable = copyInventory
          ? values[Object.keys(values)[0]]?.Uavailable || 0
          : 0;
        const updatedSafetyStock = copySafety
          ? values[Object.keys(values)[0]]?.UsafetyStock || 0
          : 0;

        if (data.length > 0) {
          d.push({
            objectId: data[0]?.objectId,
            cityName: item,
            available: totalStock || 0,
            safetyStock: safetyStock || 0,
            storeCode: data[0]?.storeCode,
            Uavailable: updatedAvailable,
            UsafetyStock: updatedSafetyStock,
          });
        }
      });

      // console.log('Final Data:', d);
      setData(d);
    } catch (error) {
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: 'failed!',
          message: error.message,
        })
      );
    } finally {
      dispatch(updateSpinnerState(false));
    }
  };

  const updateAddInventory = async () => {
    try {
      const apiData = {
        inventories: data?.map((each) => ({
          ean: product?.vanNo,
          fcId: each?.storeCode || '',
          quantity: Number(each?.Uavailable || ''),
          movementType: 'RTW',
          transactionType: 'Overwrite',
        })),
      };
      dispatch(updateSpinnerState(true));
      const response = await apiCall(
        apiCallConsts.POST_METHOD,
        'inventory/update',
        apiData
      );
      console.log(response);
    } catch (error) {
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: 'failed!',
          message: error.message,
        })
      );
    } finally {
      dispatch(updateSpinnerState(false));
    }
  };

  const updateSafetyStock = async () => {
    try {
      dispatch(updateSpinnerState(true));
      const response = await Promise.all(
        data?.map((each) =>
          apiCall(
            apiCallConsts.PUT_METHOD,
            `${apiCallConsts.INVENTORY_UPDATE_URL}/safetyStock`,
            {
              id: [each.objectId],
              safetyStockLimit: Number(each?.UsafetyStock),
            }
          )
        )
      );

      console.log(response);
    } catch (error) {
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: 'failed!',
          message: error.message,
        })
      );
    } finally {
      dispatch(updateSpinnerState(false));
    }
  };

  const handleUpdateSubmit = async () => {
    await updateAddInventory();
    await updateSafetyStock();
    setSummaryModal(false);
    setData([]);
    setSelectedCities([]);
    setValues({});
    setCopyInventory(false);
    setCopySafety(false);
  };

  const handleSaveClick = () => {
    setData((prev) =>
      prev.map((each) => ({ ...each, ...values[each?.objectId] }))
    );
    setSummaryModal(true);
  };

  return (
    <>
      <BreadCrumbTitle
        breadcrumbs={[
          { title: 'Products List', link: Routes.OMS.Products.List.path },
          { title: 'Update Inventory' },
        ]}
      />

      <div className="fw-medium mt-4" style={{ fontSize: '1.25rem' }}>
        {product?.productTitle || ''}
      </div>

      <div
        className="d-flex justify-content-start align-items-center bg-white my-3 p-3"
        style={{ height: '9rem' }}
      >
        <Form.Group className="mb-2">
          <div className="text-start fs-5">{t(`Select City`)}</div>
          <div className="d-flex gap-4">
            <span style={{ minWidth: '20rem' }}>
              <EditableDropDown
                isMultiSelect={true}
                onChange={handleChange}
                value={selectedCities}
                options={arrayToDropdownOptions(cities)}
                placeholder="Select City (or All Cities)"
                optionColor="white"
                backgroundColor="rgba(240, 240, 240, 1)"
              />
            </span>
            <Button
              variant="primary"
              className="rounded-0"
              style={{ height: '2.5rem', textAlign: 'center' }}
              disabled={selectedCities.length === 0}
              onClick={handleFetch}
            >
              <FontAwesomeIcon icon={faSync} /> &nbsp;&nbsp; {t('FETCH DATA')}
            </Button>
          </div>
        </Form.Group>
      </div>
      <div className="d-flex justify-content-end align-items-center gap-3 bg-white my-2">
        <div className="d-flex align-items-center gap-3 p-2">
          <Form.Label className="mb-0 fs-6">Copy Inventory Value</Form.Label>
          <Form.Group>
            <Form.Check
              type="checkbox"
              id={'copyInventoryValue'}
              checked={copyInventory}
              onChange={(e) => setCopyInventory(e.target.checked)}
              className="custom-checkbox"
            />
          </Form.Group>
        </div>
        <div className="d-flex align-items-center gap-3 p-2">
          <Form.Label className="mb-0 fs-6">Copy Safety Stock Value</Form.Label>
          <Form.Group>
            <Form.Check
              type="checkbox"
              id={'copySafetyValue'}
              checked={copySafety}
              onChange={(e) => setCopySafety(e.target.checked)}
              className="custom-checkbox"
            />
          </Form.Group>
        </div>
      </div>
      <NewTable
        id="updateInventory"
        data={data || []}
        columns={updateInventoryColumns(setValues)}
      />
      {data.length > 0 && (
        <div className="mt-5 d-flex justify-content-end">
          <ButtonGroup className="d-flex gap-4">
            <Button
              variant="white"
              className="rounded-0 p-3"
              style={{ width: '11rem' }}
              //   className="text-gray ms-auto"
              // onClick={closeModal}
            >
              {t('Cancel')}
            </Button>
            <Button
              className="rounded-0 p-3"
              variant="primary"
              style={{ width: '11rem' }}
              onClick={handleSaveClick}
            >
              {t('Save')}
            </Button>
          </ButtonGroup>
        </div>
      )}

      {summaryModal && (
        <SummaryModal
          showModal={summaryModal}
          closeModal={() => setSummaryModal(false)}
          modalTitile={'Inventory Update Summary'}
          data={data || []}
          columns={summaryModalColumns()}
          handleSubmit={handleUpdateSubmit}
        />
      )}
    </>
  );
};

export default UpdateInventory;
