export default {
    translations: {
        "Save Temporary Trip": "અસ્થાયી સફર સાચવો",
        "View Packing": "પેકિંગ જુઓ",
        "Orders Count": "ઓર્ડરની ગણતરી",
        "Locations": "સ્થાનો",
        "Temporary Trip Created Successfully": "અસ્થાયી સફર સફળતાપૂર્વક બનાવવામાં આવી",
        "Please visit the trip page to take more action on this trip": "આ ટ્રિપ પર વધુ પગલાં લેવા માટે કૃપા કરીને ટ્રિપ પૃષ્ઠની મુલાકાત લો",
        "Temporary Trip Creation failed": "અસ્થાયી ટ્રિપ બનાવવાનું નિષ્ફળ થયું",
        "Please try again or contact support": "કૃપા કરીને ફરી પ્રયાસ કરો અથવા સપોર્ટનો સંપર્ક કરો",
        "Add New Warehouse": "નવું વેરહાઉસ ઉમેરો",
        "Use this page to add a new warehouse": "નવું વેરહાઉસ ઉમેરવા માટે આ પૃષ્ઠનો ઉપયોગ કરો",
        "Warehouse Name": "વેરહાઉસનું નામ",
        "Warehouse Code": "વેરહાઉસ કોડ",
        "Warehouse Short Code": "વેરહાઉસ શોર્ટ કોડ",
        "Address": "સરનામું",
        "Pincode": "પીન કોડ",
        "zone": "ઝોન",
        "UserName": "વપરાશકર્તા નામ",
        "Password": "પાસવર્ડ",
        "Repeat Password": "પાસવર્ડ નું પુનરાવર્તન કરો",
        "Create User": "વપરાશકર્તા બનાવો",
        "Cancel": "રદ કરો",
        "Add Users": "વપરાશકર્તાઓ ઉમેરો",
        "Add Warehouse": "વેરહાઉસ ઉમેરો",
        "Add Incharge": "ઈન્ચાર્જ ઉમેરો",
        "Add New Incharge": "નવા ઇન્ચાર્જ ઉમેરો",
        "Use this page to add a new incharge": "નવા ઇન્ચાર્જ ઉમેરવા માટે આ પૃષ્ઠનો ઉપયોગ કરો",
        "Incharge Name": "ઈન્ચાર્જનું નામ",
        "Phone Number": "ફોન નંબર",
        "Email Id": "ઈમેલ આઈડી",
        "Select Application": "એપ્લિકેશન પસંદ કરો",
        "Warehouse / Store Name": "ઇન્ચાર્જ માહિતી",
        "Incharge Info": "ઇન્ચાર્જ માહિતી",
        "Login Info": "લૉગિન માહિતી",
        "Item Removed": "આઇટમ દૂર કરી",
        "Parcel Removed from trip successfully": "સફરમાંથી પાર્સલ સફળતાપૂર્વક દૂર કરવામાં આવ્યું",
        "Removal Failed": "દૂર કરવાનું નિષ્ફળ થયું",
        "Parcel removal failed, Please try later": "પાર્સલ દૂર કરવામાં નિષ્ફળ થયું, કૃપા કરીને પછીથી પ્રયાસ કરો",
        "Scan, Print EWB & Dispatch your vehicle from here": "સ્કેન કરો, EWB પ્રિન્ટ કરો અને અહીંથી તમારું વાહન ડિસ્પેચ કરો",
        "Enter Serial No to Scan": "સ્કેન કરવા માટે સીરીયલ નંબર દાખલ કરો",
        "શું તમે ખરેખર દૂર કરવા માંગો છો": "શું તમે ખરેખર દૂર કરવા માંગો છો",
        "Remove Orders": "ઓર્ડર દૂર કરો",
        "Confirm": "પુષ્ટિ કરો",
        "Drop File here": "ફાઇલ અહીં મૂકો",
        "Select Display Sheet": "ડિસ્પ્લે શીટ પસંદ કરો",
        "Please enter valid InvoiceId [X-Q-SI X] [RC/X/X-X/X] [PC/X/X-X/X] [X-S-SI X]": "કૃપા કરીને માન્ય ઇનવોઇસ આઈડી [X-Q-SI X] [RC/X/X-X/X] [PC/X/X-X/X] [X-S-SI X] દાખલ કરો",
        "Vehicle Number is mandatory": "વાહન નંબર ફરજિયાત છે",
        "Vehicle Area is mandatory": "વાહન વિસ્તાર ફરજિયાત છે",
        "Please Select an option": "કૃપા કરીને એક વિકલ્પ પસંદ કરો",
        "Please Enter Valid Vehicle Number": "કૃપા કરીને માન્ય વાહન નંબર દાખલ કરો",
        "Please select an option": "કૃપા કરીને એક વિકલ્પ પસંદ કરો",
        "Create Temporary trip": "અસ્થાયી સફર બનાવો",
        "Vehicle Number": "વાહન નંબર",
        "Vehicle Area": "વાહન વિસ્તાર",
        "Save temporary": "કામચલાઉ સાચવો",
        "Height": "ઊંચાઈ",
        "Length": "લંબાઈ",
        "Breadth": "પહોળાઈ",
        "Incharge Added": "ઈન્ચાર્જ ઉમેર્યું",
        "incharge added successfully": "ઇન્ચાર્જ સફળતાપૂર્વક ઉમેરાયો",
        "incharge failed to add": "ઈન્ચાર્જ ઉમેરવામાં નિષ્ફળ ગયા",
        "Incharge Add Failed": "ઇન્ચાર્જ ઉમેરો નિષ્ફળ",
        "Please enter Incharge name": "કૃપા કરીને ઈન્ચાર્જનું નામ દાખલ કરો",
        "Delivery Management": "ડિલિવરી મેનેજમેન્ટ",
        "Warehouse Management": "વેરહાઉસ મેનેજમેન્ટ",
        "Finance": "ફાઇનાન્સ",
        "Omni Channel": "ઓમ્ની ચેનલ",
        "Product Management": "ઉત્પાદન વ્યવસ્થાપન",
        "Select Applications to give access": "ઍક્સેસ આપવા માટે એપ્લિકેશન્સ પસંદ કરો",
        "All your temporary trips are listed here.": "તમારી બધી અસ્થાયી ટ્રિપ્સ અહીં સૂચિબદ્ધ છે.",
        "Previous": "અગાઉના",
        "Next": "આગળ",
        "Dashboard": "ડેશબોર્ડ",
        "Overview": "ઝાંખી",
        "Dispatch": "રવાનગી",
        "Temporary Trips": "કામચલાઉ પ્રવાસો",
        "Trips List": "પ્રવાસોની સૂચિ",
        "Requisitions": "ટ્રિપ્સ લિસ્ટની જરૂરિયાતો",
        "Trips": "પ્રવાસો",
        "Parcels": "પાર્સલ",
        "Vehicles": "વાહનો",
        "Live": "જીવંત",
        "Plan Vehicles": "વાહનોની યોજના બનાવો",
        "Prepare temporary trip sheet here": "અહીં કામચલાઉ ટ્રિપ શીટ તૈયાર કરો",
        "Enter / Scan Invoice Number": "ઇન્વોઇસ નંબર દાખલ કરો / સ્કેન કરો",
        "Warehouse Info": "વેરહાઉસ માહિતી",
        "Please select a zone": "કૃપા કરીને એક ઝોન પસંદ કરો",
        "Active Trips": "સક્રિય પ્રવાસો",
        "All the trips of your warehouse are listed here.": "તમારા વેરહાઉસની તમામ ટ્રિપ્સ અહીં સૂચિબદ્ધ છે.",
        "New Trip": "નવી સફર",
        "Share": "શેર કરો",
        "Export": "નિકાસ કરો",
        "Trip Number": "ટ્રીપ નંબર",
        "VEHICLE NUMBER": "વાહન નંબર",
        "VEHICLE AREA": "વાહન નંબર વાહન વિસ્તાર",
        "ने निर्मित": "દ્વારા નિર્મિત",
        "DATE": "તારીખ",
        "STATUS": "સ્ટેટસ",
        "Pending": "બાકી છે",
        "Scan, Print EWB & Dispatch your vehicle from here.": "સ્કેન કરો, EWB પ્રિન્ટ કરો અને અહીંથી તમારું વાહન ડિસ્પેચ કરો.",
        "Print Sheet": "શીટ છાપો",
        "Print EWB": "EWB છાપો",
        "CUSTOMER": "ગ્રાહક",
        "INVOICE NO": "ઇન્વોઇસ નં",
        "ADDRESS": "એડ્રેસ",
        "SERIAL NO": "અનુક્રમ નંબર",
        "PRODUCT": "ઉત્પાદન",
        "ACTION": "એક્શન",
        "Remove": "દૂર કરો",
        "Loader Name 1": "લોડરનું નામ 1",
        "Loader Number 1": "લોડર નંબર 1",
        "Loader Name 2": "લોડરનું નામ 2",
        "Loader Number 2": "લોડર નંબર 2",
        "Loader Name 3": "લોડરનું નામ 3",
        "Loader Number 3": "લોડર નંબર 3",
        "Loader Name 4": "લોડરનું નામ 4",
        "Loader Number 4": "લોડર નંબર 4",
        "Loader Name 5": "લોડરનું નામ 5",
        "Loader Number 5": "લોડર નંબર 5",
    }
}