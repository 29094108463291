import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Col, Nav, Row, Tab } from '@themesberg/react-bootstrap';
import { useSelector } from 'react-redux';
import AddVendorUser from './AddVendorUser';
import AddAccountsUser from './AddAccountsUser';
import AddCMUser from './AddCMUser';
import arrayToBreadCrumbs from '../../../../utils/sms/arrayToBreadCrumbs';
import useGetCompanyAndCategory from '../../custom-hooks/useGetCompanyAndCategory';
import BreadCrumbTitle from '../common/BreadCrumbTitle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';

const AddSchemeUsers = () => {
  const { t } = useTranslation();

  const isBrandManager = useSelector(
    (state) => state?.user?.preferences?.isBrandManager || false
  );
  const isAccountManager = useSelector(
    (state) => state?.user?.preferences?.isAccountManager || false
  );
  const isAdmin = useSelector(
    (state) => state?.user?.preferences?.isAdmin || false
  );
  const [breadCrumbs, setBreadCrumbs] = useState([{ title: t('Add User') }]);
  const [currentTab, setCurrentTab] = useState('');
  let tabs = [];

  if (isAccountManager) {
    tabs = ['Add PMG'];
  } else if (isBrandManager) {
    tabs = ['Add Brand Vendor'];
  } else {
    tabs = ['Add Brand Vendor', 'Add PMG', 'Add Accounts Manager'];
  }

  useGetCompanyAndCategory();

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          {breadCrumbs?.map((item, index) => (
            <span key={index} style={{ display: 'inline-block' }}>
              {index !== breadCrumbs.length - 1 ? (
                <span
                  style={{
                    color: '#444444',
                    fontWeight: '400',
                    fontSize: '24px',
                    lineHeight: '28.13px',
                    textDecoration: 'None',
                    opacity: '60%',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setCurrentTab('');
                    setBreadCrumbs([{ title: t('Add User') }]);
                  }}
                >
                  {item.title}
                </span>
              ) : (
                <span
                  style={{
                    color: '#444444',
                    fontWeight: '500',
                    fontSize: '24px',
                    lineHeight: '28.13px',
                  }}
                >
                  {item.title}
                </span>
              )}
              {index < breadCrumbs.length - 1 && (
                <span
                  style={{
                    margin: '0 10px',
                    color: '#999',
                    fontWeight: '500',
                    fontSize: '24px',
                    lineHeight: '28.13px',
                    fontWeight: '500',
                  }}
                >
                  &gt;
                </span>
              )}
            </span>
          ))}
        </div>
      </div>
      {!currentTab && (
        <div className="d-flex gap-4">
          {tabs.map((item) => (
            <Card
              className="p-2 justify-content-center align-items-center gap-3"
              style={{ width: '254px', height: '182px', cursor: 'pointer' }}
              onClick={() => {
                setCurrentTab(item);
                setBreadCrumbs((prev) => [...prev, { title: item }]);
              }}
            >
              <FontAwesomeIcon icon={faUserPlus} style={{ color: '#444444' }} />
              <h5
                style={{
                  color: '#2E3650',
                  fontSize: '20px',
                  fontWeight: '500',
                }}
              >
                {item}
              </h5>
            </Card>
          ))}
        </div>
      )}
      {currentTab === 'Add Brand Vendor' && <AddVendorUser />}
      {currentTab === 'Add PMG' && <AddCMUser />}
      {currentTab === 'Add Accounts Manager' && <AddAccountsUser />}
    </>
  );
};
export default AddSchemeUsers;
