import { Badge, Button } from '@themesberg/react-bootstrap';
import React from 'react';
import { getTripBadgeStatus } from '../../utils/trips';
import { returnUserReadableDate } from '../../utils/datetime';
import {
  getTableRowCheckBox,
  returnCustomerAddressString,
} from '../../utils/table';
import { RequisitionStatusMap } from '../requisitions/utils';

export const requisitionSentTableColumns = (showItemsModal) => {
  return [
    {
      dataField: 'requisitionNumber',
      sort: true,
      text: 'Requisition No. / Date',
      formatter: (cell, row) => {
        return (
          <div className="d-block">
            <div className="fw-bold">{cell}</div>
            <div className="fw-bold">
              {returnUserReadableDate(row?.requisitionDate)}
            </div>
            <br />
          </div>
        );
      },
    },
    {
      dataField: 'deliveryBranch',
      text: 'Delivery / To Branch',
      formatter: (cell, row) => {
        return (
          <div className="d-block">
            <span className="fw-bold">{cell}</span>
            <br />
            <span className="fw-normal">To : {row.toBranch}</span>
          </div>
        );
      },
    },
    {
      dataField: 'requisitionDate',
      text: 'Requisition Date / Remarks',
      formatter: (cell, row) => {
        return returnCustomerAddressString(row?.remarks, '', 200, 30);
      },
    },
    {
      dataField: 'transferNumber',
      text: 'Transfer No.',
      formatter: (cell, row) => {
        return (
          <div className="d-block">
            <span className="fw-bold">{row?.transferNumber}</span>
            <br />
          </div>
        );
      },
    },
    {
      dataField: 'products',
      text: 'Products',
      formatter: (cell, row) => {
        const allProducts = JSON.parse(JSON.stringify(row?.products));
        const showMore = allProducts?.length > 1;
        const productsToShow =
          allProducts?.length > 1 ? allProducts?.splice(0, 1) : allProducts;
        return (
          <>
            {productsToShow?.map((product) => {
              return (
                <div className="d-block">
                  <span className="fw-bold">{product.product.productName}</span>
                  <br />
                  <span className="fw-normal">
                    {product.product.companyName}
                  </span>
                  &nbsp;/&nbsp;
                  <span className="fw-normal">
                    {product.product.productCode}
                  </span>
                  &nbsp;/&nbsp;
                  <span className="fw-normal">Qty: {product.quantity}</span>
                  &nbsp;/&nbsp;
                  <span className="fw-normal">{product.product.vanNo}</span>
                </div>
              );
            })}
            <>
              {showMore && (
                <>
                  {`+ ${allProducts?.length} More Product(s)`}&nbsp;&nbsp;
                  <Button
                    size="sm"
                    variant="outline-primary"
                    onClick={() => {
                      showItemsModal(row?.objectId);
                    }}
                  >
                    Show Details
                  </Button>
                </>
              )}
            </>
          </>
        );
      },
    },
    {
      dataField: 'requisitionType',
      text: 'Requisition Type',
      formatter: (cell, row) => {
        let isPartiallyApproved = false;
        const totalProducts = row?.products?.length;

        if (totalProducts > 1) {
          const reviewProducts = row?.products?.filter(
            (product) => product.status === 'review'
          ).length;
          if (reviewProducts > 0 && reviewProducts < totalProducts) {
            isPartiallyApproved = true;
          }
        }
        return (
          <div className="d-block">
            <div className="fw-bold">
              <span>{RequisitionStatusMap[row?.requisitionType]}</span>
              <span>
                &nbsp;&nbsp;
                <Badge
                  bg={getTripBadgeStatus(row?.status)}
                  className="badge-lg"
                >
                  {isPartiallyApproved
                    ? RequisitionStatusMap['partialApproved']
                    : RequisitionStatusMap[row?.status]}
                </Badge>{' '}
                &nbsp;&nbsp;
              </span>
            </div>
          </div>
        );
      },
    },
  ];
};

export const requisitionReceivedTableColumns = (
  handleRowSelect,
  showItemsModal
) => {
  return [
    {
      dataField: 'objectId',
      text: 'Select',
      formatter: (cell, row) => {
        const isDisabled = row?.status !== 'pending' && row?.status !== 'print';
        return getTableRowCheckBox(
          handleRowSelect,
          cell,
          row?.isSelected,
          isDisabled
        );
      },
    },
    {
      dataField: 'requisitionNumber',
      sort: true,
      text: 'Requisition Number',
      formatter: (cell, row) => {
        return (
          <div className="d-block">
            <div className="fw-bold">{cell}</div>
            <div className="fw-bold">
              {returnUserReadableDate(row?.requisitionDate)}
            </div>
          </div>
        );
      },
    },
    {
      dataField: 'deliveryBranch',
      text: 'Delivery / To Branch',
      formatter: (cell, row) => {
        return (
          <div className="d-block">
            <span className="fw-bold">{cell}</span>
            <br />
            <span className="fw-normal">To : {row.toBranch}</span>
          </div>
        );
      },
    },
    {
      dataField: 'requisitionDate',
      text: 'Remarks / Transfer No',
      formatter: (cell, row) => {
        return (
          <div className="d-block">
            <div>{returnCustomerAddressString(row?.remarks, '', 200, 30)}</div>
            <div>
              {row?.transferNumber ? `Tranfer No: ${row?.transferNumber}` : ''}
            </div>
          </div>
        );
      },
    },
    {
      dataField: 'products',
      text: 'Products',
      formatter: (cell, row) => {
        const allProducts = JSON.parse(JSON.stringify(row?.products));
        const showMore = allProducts?.length > 1;
        const productsToShow =
          allProducts?.length > 1 ? allProducts?.splice(0, 1) : allProducts;
        return (
          <>
            {productsToShow?.map((product) => {
              return (
                <div className="d-block">
                  <span className="fw-bold">{product.product.productName}</span>
                  <br />
                  <span className="fw-normal">
                    {product.product.companyName}
                  </span>
                  &nbsp;/&nbsp;
                  <span className="fw-normal">
                    {product.product.productCode}
                  </span>
                  &nbsp;/&nbsp;
                  <span className="fw-normal">Qty: {product.quantity}</span>
                  &nbsp;/&nbsp;
                  <span className="fw-normal">{product.product.vanNo}</span>
                </div>
              );
            })}
            <>
              {showMore && (
                <>
                  {`+ ${allProducts?.length} More Product(s)`}&nbsp;&nbsp;
                  <Button
                    size="sm"
                    variant="outline-primary"
                    onClick={() => {
                      showItemsModal(row?.objectId);
                    }}
                  >
                    Show Details
                  </Button>
                </>
              )}
            </>
          </>
        );
      },
    },
    {
      dataField: 'requisitionType',
      text: 'Requisition Type',
      formatter: (cell, row) => {
        let isPartiallyApproved = false;
        const totalProducts = row?.products?.length;

        if (totalProducts > 1) {
          const reviewProducts = row?.products?.filter(
            (product) => product.status === 'review'
          ).length;
          if (reviewProducts > 0 && reviewProducts < totalProducts) {
            isPartiallyApproved = true;
          }
        }
        return (
          <div className="d-block">
            <div className="fw-bold">
              <span>{RequisitionStatusMap[row?.requisitionType]}</span>
              <span>
                &nbsp;&nbsp;
                <Badge
                  bg={getTripBadgeStatus(row?.status)}
                  className="badge-lg"
                >
                  {isPartiallyApproved
                    ? RequisitionStatusMap['partialApproved']
                    : RequisitionStatusMap[row?.status]}
                </Badge>{' '}
                &nbsp;&nbsp;
              </span>
            </div>
          </div>
        );
      },
    },
  ];
};

export const requisitionPendingApprovalTableColumns = (
  handleRowSelect,
  showItemsModal
) => {
  return [
    {
      dataField: 'objectId',
      text: 'Select',
      formatter: (cell, row) => {
        const isDisabled = row?.status !== 'review';
        return getTableRowCheckBox(
          handleRowSelect,
          cell,
          row?.isSelected,
          isDisabled
        );
      },
    },
    {
      dataField: 'requisitionNumber',
      sort: true,
      text: 'Requisition Number',
      formatter: (cell, row) => {
        return (
          <div className="d-block">
            <div className="fw-bold">{cell}</div>
            <div className="fw-bold">
              {returnUserReadableDate(row?.requisitionDate)}
            </div>
          </div>
        );
      },
    },
    {
      dataField: 'deliveryBranch',
      text: 'Delivery / To Branch',
      formatter: (cell, row) => {
        return (
          <div className="d-block">
            <span className="fw-bold">{cell}</span>
            <br />
            <span className="fw-normal">To : {row.toBranch}</span>
          </div>
        );
      },
    },
    {
      dataField: 'requisitionDate',
      text: 'Remarks / Transfer No',
      formatter: (cell, row) => {
        return (
          <div className="d-block">
            <div>{returnCustomerAddressString(row?.remarks, '', 200, 30)}</div>
            <div>
              {row?.transferNumber ? `Tranfer No: ${row?.transferNumber}` : ''}
            </div>
          </div>
        );
      },
    },
    {
      dataField: 'products',
      text: 'Products',
      formatter: (cell, row) => {
        const allProducts = JSON.parse(JSON.stringify(row?.products));
        const showMore = allProducts?.length > 2;
        const productsToShow =
          allProducts?.length >= 2 ? allProducts?.splice(0, 2) : allProducts;
        return (
          <>
            {productsToShow?.map((product) => {
              return (
                <div className="d-block">
                  <span className="fw-bold">{product.product.productName}</span>
                  <br />
                  <span className="fw-normal">
                    {product.product.companyName}
                  </span>
                  &nbsp;/&nbsp;
                  <span className="fw-normal">
                    {product.product.productCode}
                  </span>
                  &nbsp;/&nbsp;
                  <span className="fw-normal">Qty: {product.quantity}</span>
                  &nbsp;/&nbsp;
                  <span className="fw-normal">{product.product.vanNo}</span>
                </div>
              );
            })}
            <>
              {showMore && (
                <>
                  {`+ ${allProducts?.length} More Product(s)`}&nbsp;&nbsp;
                  <Button
                    size="sm"
                    variant="outline-primary"
                    onClick={() => {
                      showItemsModal(row?.objectId);
                    }}
                  >
                    Show Details
                  </Button>
                </>
              )}
            </>
          </>
        );
      },
    },
    {
      dataField: 'requisitionType',
      text: 'Requisition Type',
      formatter: (cell, row) => {
        return (
          <div className="d-block">
            <div className="fw-bold">
              <span>{RequisitionStatusMap[row?.requisitionType]}</span>
              <span>
                &nbsp;&nbsp;
                <Badge
                  bg={getTripBadgeStatus(row?.status)}
                  className="badge-lg"
                >
                  {RequisitionStatusMap[row?.status]}
                </Badge>{' '}
                &nbsp;&nbsp;
              </span>
            </div>
          </div>
        );
      },
    },
  ];
};
