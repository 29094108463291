import React from 'react';
import { generateRandomId } from '../../utils/string';
import { StyledSearchBar } from '../trips/styles';
import { unpaidTripsColumns } from './utils';
import { useTranslation } from 'react-i18next';
import { Form, Modal } from '@themesberg/react-bootstrap';
import TableOnly from '../scheme-management/Components/IndividualScheme.js/TableOnly';

const PaidTripsModal = (props) => {
  const { tripsData, invoiceData, showModal, closeModal } = props;
  const { t } = useTranslation();
  return (
    <Modal
      id={generateRandomId()}
      as={Modal.Dialog}
      centered
      show={showModal}
      onHide={closeModal}
      size="xl"
    >
      <Modal.Header>
        <Modal.Title className="h6 d-flex justify-content-between w-100">
          <div>{t('Paid Trips Details')}</div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-between mb-3">
          <Form.Group>
            <Form.Label>Total Trips</Form.Label>
            <StyledSearchBar
              width={'250px'}
              type="number"
              height={'35px'}
              placeholder={tripsData?.length}
              disabled={true}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Total Amount</Form.Label>
            <StyledSearchBar
              width={'250px'}
              type="number"
              height={'35px'}
              placeholder={invoiceData?.totalAmount || 0}
              disabled={true}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Total Final Payment</Form.Label>
            <StyledSearchBar
              width={'250px'}
              type="number"
              height={'35px'}
              placeholder={invoiceData?.finalPayment || 0}
              disabled={true}
            />
          </Form.Group>
        </div>
        <TableOnly
          id="paidTripsModal"
          data={tripsData}
          columns={unpaidTripsColumns({
            isPaid: true,
            isModal: true,
          })}
        />
      </Modal.Body>
    </Modal>
  );
};

export default PaidTripsModal;
