import React from 'react';
import FormComponent from '../FormComponent';
import { useSelector, useDispatch } from 'react-redux';
import {
  returnCategoriesDropdownList,
  returnCompaniesDropdownList,
} from '../../../../utils/products';
import { brandManagerUserSchema } from '../../schema/brandManagerUser';
import { addNewBrandManagerUser } from '../../../../parse-functions/sms/addUsers';
import { updateToastInfo } from '../../../../actions/settings';
import { useTranslation } from 'react-i18next';
import { updateSpinnerState } from '../../../../actions/spinner';
import { editBrandManagerUserSchema } from '../../schema/userEdit';
import { transformFormData } from '../../../../utils/sms/convertProductsToDropdownOptions';

const AddCMUser = ({ editData, handleSave }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const companies = useSelector((state) => state?.products?.companies || {});
  const categories = useSelector((state) => state?.products?.categories || {});
  const isAdmin = useSelector(
    (state) => state?.user?.preferences?.isAdmin || false
  );

  let schema = editData ? editBrandManagerUserSchema : brandManagerUserSchema;

  schema = schema.map((section) =>
    section.map((field) => {
      if (field.title === 'Company') {
        field.options = [
          { label: 'All', value: 'All' },
          ...returnCompaniesDropdownList(companies),
        ];
      }
      if (field.title === 'Category' && Object.keys(categories).length > 0) {
        field.options = [
          { label: 'All', value: 'All' },
          ...returnCategoriesDropdownList(categories),
        ];
      }
      return field;
    })
  );
  const saveCMUser = async (formData, callback) => {
    try {
      dispatch(updateSpinnerState(true));
      formData = transformFormData({
        formData,
        categories,
        isAdmin,
        companies,
      });
      if (editData) {
        handleSave(formData);
      } else {
        await addNewBrandManagerUser(formData);
      }
      dispatch(
        updateToastInfo({
          show: true,
          type: 'success',
          title: editData ? t('User Edited') : t('User Added'),
          message: editData
            ? t(`${formData.name} updated successfully`)
            : t(`${formData.userName} added successfully`),
        })
      );
      callback(null);
    } catch (error) {
      const { message } = error;
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: editData ? t('User Edit failed') : t('User Add Failed'),
          message: editData
            ? t(`${formData.name} failed to edit`)
            : t(`${formData.userName} failed to add`),
        })
      );
      callback(message);
    }
    dispatch(updateSpinnerState(false));
  };
  return (
    <div>
      <div>
        <FormComponent
          schema={schema}
          savefunction={saveCMUser}
          editData={editData}
        />
      </div>
    </div>
  );
};

export default AddCMUser;
