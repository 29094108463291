/* eslint-disable no-unused-expressions */

export const returnValidationErrorMessages = (joiErrorMessages) => {
    const errorMap = {};
    joiErrorMessages?.forEach((field) => {
        errorMap[field.path[0]] = field.message;
    });
    return errorMap;
};

export const validateVehicleNumber = (value) => {
    let returnVal = false;
    if (/(^[A-Z]{2}[0-9]{1,2}(?:[A-Z])?(?:[A-Z]*)?[0-9]{1,4}$)+/i.test(value) && (value.length >= 5 && value.length <= 10)) {
        returnVal = true;
    }
    return returnVal;
}