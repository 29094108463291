import React from 'react';
import { Badge } from '@themesberg/react-bootstrap';
import { getTableRowCheckBox, returnCustomerAddressString } from '../table';
import { getTripBadgeStatus } from '../trips';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { returnProductInfo, returnAttachmentsInfo } from './tableUtils';

export const schemeTableColumns = (
  onRowSelect,
  type,
  navigate,
  handleInfoClick,
  handleMoreProductsClick
) => {
  const columns = [
    {
      dataField: 'objectId',
      text: 'Select',
      formatter: (cell, row) => {
        return getTableRowCheckBox(onRowSelect, cell, row?.isSelected, false);
      },
    },
    {
      dataField: 'schemeCircularNo',
      text: 'Scheme Circular',
      formatter: (cell, row) => {
        return (
          <div className="d-block">
            <div className="d-block fw-bold">
              {returnCustomerAddressString(
                `No: ${row.schemeCircularNo}`,
                null,
                200,
                30,
                'top'
              )}
            </div>
            <div className="d-block fw-bold">{`Date: ${row.schemeCircularDate}`}</div>
          </div>
        );
      },
    },
    {
      dataField: 'company',
      text: 'Company',
    },
    {
      dataField: 'productCategory',
      text: 'Category',
      formatter: (cell, row) => {
        return returnProductInfo(cell, 'Categories');
      },
    },
    {
      dataField: 'subCategory',
      text: 'Sub Category',
      formatter: (cell, row) => {
        return returnProductInfo(cell, 'Sub Categories');
      },
    },
    {
      dataField: 'products',
      text: 'Products',
      formatter: (cell, row) => {
        return returnProductInfo(
          cell,
          'Products',
          handleMoreProductsClick,
          row
        );
      },
    },
    {
      dataField: 'validFrom',
      text: 'Valid From',
    },
    {
      dataField: 'validTill',
      text: 'Valid Till',
    },
    {
      dataField: 'type',
      text: 'Scheme Type',
    },
    {
      dataField: 'status',
      text: 'Status',
      formatter: (cell, row) => {
        return (
          <div className="d-block">
            <Badge bg={getTripBadgeStatus(cell)} className="badge-md">
              {cell}
            </Badge>
            {row.actionType && (
              <Badge
                bg={getTripBadgeStatus(row.actionType)}
                className="badge-md"
              >
                {row.actionType}
              </Badge>
            )}
          </div>
        );
      },
    },
    {
      dataField: 'actionLog',
      text: 'Action Log',
      formatter: (cell, row) => {
        return (
          <FontAwesomeIcon
            icon={faCircleInfo}
            onClick={() => handleInfoClick(cell)}
          />
        );
      },
    },
    {
      dataField: 'isAutoGenerated',
      text: 'Email Generated',
      formatter: (cell, row) => {
        return (
          <div className="d-block">
            <Badge
              bg={getTripBadgeStatus(row?.isAutoGenerated)}
              className="badge-md"
            >
              {row?.isAutoGenerated ? 'Yes' : 'No'}
            </Badge>
          </div>
        );
      },
    },
    {
      dataField: 'attachments',
      text: 'Attachments',
      formatter: (cell, row) => {
        return returnAttachmentsInfo(cell, row);
      },
    },
  ];
  if (type === 'active') {
    columns.push({
      dataField: 'view',
      text: 'View',
      formatter: (cell, row) => {
        return (
          <FontAwesomeIcon
            icon={faArrowRight}
            onClick={() => navigate(`/scheme/analysis/${row.objectId}`)}
          />
        );
      },
    });
  }

  return columns;
};
