import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faTimes } from "@fortawesome/free-solid-svg-icons";
import { returnValidationErrorMessages } from "../../utils/validation";
import { useTranslation } from "react-i18next";
import Joi from "joi";
import { vehicleNumberSchemaValidation } from "../../utils/planning";
import DropdownComponent from "../common/Dropdown";

const temporaryTripCreationSchema = Joi.object({
    vehicleNumber: Joi.string().custom(vehicleNumberSchemaValidation, "Invalid Vehicle Number").allow(''),
    vehicleArea: Joi.string().required(),
    allowLoaderUpdate: Joi.boolean(),
    isTransfer: Joi.boolean().required()
});

const TemporaryTripDispatchModal = (props) => {
    const {
        showModal,
        handleClose,
        onSuccess,
        disableSaveButton
    } = props;
    const [show, setShow] = useState(showModal);
    const { t } = useTranslation();
    const [formData, setFormData] = useState({});
    const [formErrors, setFormErrors] = useState({});

    useEffect(() => {
        setShow(showModal)
    }, [showModal]);

    const handleModalClose = () => {
        setShow(false);
        handleClose(false);
    }
    const onFormInputChange = (fieldName, value) => {
        setFormData(oldFormData => ({
            ...oldFormData,
            [fieldName]: value
        }));
    };
    const saveTemporaryTrip = async () => {
        const { error: formErrors = {}, value: formValue } = temporaryTripCreationSchema.validate(formData, {
            abortEarly: false
        });
        const {
            details = {}
        } = formErrors;
        if (details?.length > 0) {
            const validationErrorMessages = returnValidationErrorMessages(details);
            setFormErrors(validationErrorMessages);
        } else {
            console.log("form data--");
            console.log(formData);
            await onSuccess(formData);
        }
    };
    return (
        <Modal as={Modal.Dialog} centered show={show} onHide={handleModalClose}>
            <Modal.Header>
                <Modal.Title className="h6">{t("Create Temporary trip")}</Modal.Title>
                <Button variant="close" aria-label="Close" onClick={handleModalClose} />
            </Modal.Header>
            <Modal.Body>
                <Form.Group className="mb-3">
                    <Form.Label>{t("Vehicle Number")}</Form.Label>
                    <Form.Control
                        isInvalid={formErrors?.["vehicleNumber"]?.length > 0}
                        type="text"
                        onChange={(event) => {
                            onFormInputChange("vehicleNumber", event.target.value);
                        }}
                    />
                    {formErrors?.["vehicleNumber"]?.length > 0 && (
                        <Form.Control.Feedback type="invalid">{t(formErrors?.["vehicleNumber"])}</Form.Control.Feedback>
                    )}

                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>{t("Vehicle Area")}</Form.Label>
                    <Form.Control
                        required
                        isInvalid={formErrors?.["vehicleArea"]?.length > 0}
                        type="text"
                        onChange={(event) => {
                            onFormInputChange("vehicleArea", event.target.value);
                        }}
                    />
                    {formErrors?.["vehicleArea"]?.length > 0 && (
                        <Form.Control.Feedback type="invalid">{t(formErrors?.["vehicleArea"])}</Form.Control.Feedback>
                    )}

                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label className={formErrors?.["isTransfer"]?.length > 0 ? "text-danger" : ""}>{t("Is Transfer / Is Display")}</Form.Label>
                    <DropdownComponent
                        onChange={(option) => {
                            onFormInputChange("isTransfer", option.value);
                        }}
                        options={[{
                            label: "Branch Transfer",
                            value: true
                        }, {
                            label: "Customer Delivery",
                            value: false
                        }]}
                        placeholder={"Select an option"}
                    />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    disabled={disableSaveButton}
                    variant="secondary"
                    type="button"
                    onClick={saveTemporaryTrip}>
                    <FontAwesomeIcon icon={faSave} />&nbsp;&nbsp; {t("Save Temporary")}
                </Button>
                <Button variant="link" className="text-gray ms-auto" onClick={handleModalClose}>
                    <FontAwesomeIcon icon={faTimes} />&nbsp;&nbsp; {t("Close")}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
export default TemporaryTripDispatchModal;