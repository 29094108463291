export const returnCompaniesDropdownList = (companies) => {
    return Object.values(companies)?.map(item => ({
        label: item.name,
        value: item.name
    }))
}

export const returnCategoriesDropdownList = (categories) => {
    return Object.values(categories)?.map(item => ({
        label: item.name,
        value: item.name
    }))
}

export const returnProductsDropdownList = (products) => {
    return Object.values(products)?.map(item => ({
        label: item.productName,
        value: item
    }))
}

export const convertCustomerDataIntoManualInvoiceFormat = (customerInfo, products, branch, zone) => {
    return products?.map((product) => {
        return {
            CGST: product.CGST,
            IGST: 0,
            SGST: product.SGST,
            amount: product.amount,
            shippingAddress1: customerInfo.address,
            shippingAddress2: "",
            shippingAddress3: "",
            shippingPincode: customerInfo.pincode,
            branch,
            brand: product?.product.companyName,
            buyback: product?.buybackDetails,
            customerCode: "",
            customerName: customerInfo.customerName,
            date: customerInfo.invoiceDate,
            deliveryWarehouse: customerInfo.targetBranch,
            email: "",
            gstAmount: product?.gstAmount,
            gstAssessableValue: product?.gstAssessableValue,
            gstProductCategory: product?.gstProductCategory,
            gstRate: product?.gstRate,
            gstin: product?.gstin,
            invoiceNo: customerInfo.invoiceNumber,
            isDisplay: false,
            itemNumber: product?.product?.productCode,
            mobileNo: customerInfo?.customerContactNumber,
            pickUpLocation: branch,
            priorityDelivery: false,
            product: product?.product?.productName,
            productCategory: product?.product?.productCategory,
            productCode: product?.product?.productCode,
            rate: product?.rate,
            serialNo: product?.serialNo,
            quantity: product?.quantity,
            targetBranch: customerInfo?.targetBranch,
            zone,
            mode: "Manual"
        }
    });
};