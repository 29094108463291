import React, { useEffect, useState } from 'react';
import OrderListPage from '../../../components/common/OrderListPage';
import { orderTableColumns } from '../orders/utils';
import { useDispatch, useSelector } from 'react-redux';
import { updateToastInfo } from '../../../actions/settings';
import { updateSpinnerState } from '../../../actions/spinner';
import { apiCall } from '../../../middlewares/api';
import { apiCallConsts } from '../../../constants/apiCallConsts';
import arrayToBreadCrumbs from '../../../utils/sms/arrayToBreadCrumbs';
import { useTranslation } from 'react-i18next';
import ConfirmationModal from '../../../components/common/confirmationModal';
import { useNavigate } from 'react-router-dom';
import { orderStatus } from '../../../constants/orderStatus';

const Pending = () => {
  const { t } = useTranslation();
  const type = 'Pending';
  const storeName = useSelector(
    (state) => state?.user?.preferences?.storeName || ''
  );
  const isAdmin = useSelector(
    (state) => state?.user?.preferences?.isAdmin || false
  );
  const [ordersData, setOrdersData] = useState([]);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [confirmationModalProps, setConfirmationModalProps] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const breadCrumbItems = arrayToBreadCrumbs([
    ['Customer Pickup'],
    ['Pending'],
  ]);

  const bulkActionOptions = [
    {
      label: 'Update',
      value: 'update',
    },
  ];

  const messageDisplayer = (type, title, message) => {
    dispatch(
      updateToastInfo({
        show: true,
        type: type,
        title: t(title),
        message: t(message),
      })
    );
  };

  const onRowSelect = (selected, objectId) => {
    if (selected) {
      setSelectedOrders((prev) => {
        const selectedItem = ordersData.find(
          (item) => item.objectId === objectId
        );
        return [...prev, selectedItem];
      });
    } else {
      setSelectedOrders((prev) =>
        prev.filter((item) => item.objectId !== objectId)
      );
    }
    setOrdersData((prev) =>
      prev.map((item) =>
        item.objectId === objectId ? { ...item, isSelected: selected } : item
      )
    );
  };

  const handleSelectedRowsUpdate = (actionId) => {
    if (selectedOrders.length > 0) {
      switch (actionId) {
        case 'update':
          setConfirmationModalProps([
            {
              type: 'text',
              title: 'Invoice No',
              value: '',
            },
            {
              type: 'text',
              title: 'Serial No',
              value: '',
            },
          ]);
          setShowDeleteModal(true);
          break;
        default:
          break;
      }
    }
  };

  const handleModalOnConfirmClick = async () => {
    const selectedOrdersData = {
      id: selectedOrders.map((order) => order.objectId),
      status: 'Completed',
      invoiceNo: confirmationModalProps[0].value,
      serialNo: confirmationModalProps[1].value,
    };
    try {
      dispatch(updateSpinnerState(true));
      await apiCall(
        apiCallConsts.PUT_METHOD,
        apiCallConsts.ORDER_UPDATE_URL,
        selectedOrdersData
      );
      await fetchOrders();
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'success',
          title: `Update Successful!`,
          message: '',
        })
      );
      setShowDeleteModal(false);
      setSelectedOrders([]);
      setConfirmationModalProps([]);
    } catch (error) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: 'Cannot be updated!',
          message: error.message,
        })
      );
    }
  };

  const fetchOrders = async () => {
    try {
      dispatch(updateSpinnerState(true));
      const res = await apiCall(
        apiCallConsts.GET_METHOD,
        `/internal/order?storeCode=${storeName}&isCustomerPickup=true&status=${type}`
      );
      setOrdersData(res);
      dispatch(updateSpinnerState(false));
      messageDisplayer('success', '', 'Orders fetched successfully');
    } catch (error) {
      dispatch(updateSpinnerState(false));
      messageDisplayer('danger', 'Failed to fetch Orders', error.message);
    }
  };

  useEffect(() => {
    if (isAdmin) {
      fetchOrders();
    }
    if (storeName) {
      fetchOrders();
    }
  }, [storeName, isAdmin]);

  return (
    <>
      <OrderListPage
        pageType={`${type} orders`}
        tableData={ordersData}
        tableColumns={orderTableColumns(
          onRowSelect,
          navigate,
          bulkActionOptions,
          isAdmin,
          true
        )}
        handleRefresh={() => fetchOrders()}
        pageTitle={t(`${type} Orders`)}
        breadCrumbItems={breadCrumbItems}
        enableBulkActions={selectedOrders.length > 0}
        hideBulkActions={!bulkActionOptions}
        bulkActionOptions={bulkActionOptions}
        handleItemBulkUpdate={handleSelectedRowsUpdate}
      />
      <ConfirmationModal
        showModal={showDeleteModal}
        onConfirm={handleModalOnConfirmClick}
        closeModal={() => {
          setShowDeleteModal(false);
          setConfirmationModalProps([]);
        }}
        modalBody={t('Are you sure want to change the status to Completed?')}
        modalTitle={t(`Completed Order(s)`)}
        confirmationModalProps={confirmationModalProps}
        setConfirmationModalProps={setConfirmationModalProps}
      />
    </>
  );
};

export default Pending;
