import React, { useState } from "react";
import ParcelStatusPage from "../../components/common/parcelStatusPages"
import { useDispatch, useSelector } from "react-redux";
import { requisitionReceivedTableColumns } from "../buyback/requisitionUtils";
import { useTranslation } from "react-i18next";
import { Routes } from "../../router/routes";
import { updateSpinnerState } from "../../actions/spinner";
import { getRequisitionsFromServer, updateRequisitionStatusInServer } from "../../parse-functions/requisitions";
import { updateToastInfo } from "../../actions/settings";
import { convertArrayToObj } from "../../utils/json";
import { setReceivedRequisitionsInStore, setSelectedRequisitionInStore } from "../../actions/requisitions";
import { setFiltersInStore } from "../../actions/filters";
import { returnEndOfDay, returnStartOfDay } from "../../utils/datetime";
import { requisitionBulkActionOptions } from "./utils";
import RequisitionUpdateModal from "./requisitionUpdateModal";

const RequisitionsReceived = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const pageType = "requisitionsReceived";
    const [showRemarkModal, setShowRemarkModal] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [requisitionRemark, setRequisitionRemark] = useState(null);
    const [requisitionTransferNo, setRequisitionTransferNo] = useState(null);
    const [readOnlyMode, setReadOnlyMode] = useState(false);
    const [askRequisitionTransferNo, setAskRequisitionNo] = useState(false);
    const [selectedRequisitionId, setSelectedRequisitionId] = useState(null);
    const storeCode = useSelector((state) => state?.user?.warehouse?.warehouseCode || "");
    const filtersValue = useSelector((state) => state.filters?.[pageType]);
    const requisitonItems = useSelector((state) => state?.requisitions?.[pageType] || {});

    const breadCrumbItems = [{
        title: t("Requisitions"),
        link: Routes.Requisitions.Received.path
    }, {
        title: t("Received"),
        link: ""
    }];

    const handleFiltersSubmit = (startDate, endDate) => {
        dispatch(setFiltersInStore({
            pageType,
            data: {
                startDate: new Date(startDate),
                endDate: new Date(endDate)
            }
        }))
        getAllReceivedRequisitions(startDate, endDate);
    };

    const getAllReceivedRequisitions = async (startDate, endDate) => {
        try {
            dispatch(updateSpinnerState(true));
            const requisitions = await getRequisitionsFromServer({
                filters: {
                    startDate: new Date(startDate),
                    endDate: new Date(endDate),
                    toBranch: storeCode
                }
            })
            // const reqsItems = combineMultipleReqsIntoOne(requisitions);
            dispatch(setReceivedRequisitionsInStore(convertArrayToObj(Object.values(requisitions), "objectId"), pageType))
            dispatch(updateSpinnerState(false));
            dispatch(updateToastInfo({
                show: true,
                type: 'success',
                title: t('Requisitions fetched'),
                message: t("These are all the requisitions that have been received")
            }))
        } catch (e) {
            console.log(e);
            dispatch(updateToastInfo({
                show: true,
                type: 'danger',
                title: t('Requisitions failed'),
                message: t("Requisitions failed to fetch. Please contact support")
            }))
            dispatch(updateSpinnerState(false));
        }
    }

    const onRefreshClick = () => {
        const currentStartDate = filtersValue.startDate ? new Date(filtersValue.startDate) : returnStartOfDay(new Date());
        const currentEndDate = filtersValue.endDate ? new Date(filtersValue.endDate) : returnEndOfDay(new Date());
        getAllReceivedRequisitions(currentStartDate, currentEndDate);
    }
    const onRowSelect = (value, objectId) => {
        if (selectedRequisitionId) {
            dispatch(updateToastInfo({
                show: true,
                type: 'danger',
                title: t('Only 1 item allowed'),
                message: t("Please deselect other item before choosing this")
            }))
        } else {
            if (value) {
                setSelectedRequisitionId(objectId);
            } else {
                setSelectedRequisitionId(null);
            }
        }
    }
    const updateRequisitionStatus = async (data, actionId) => {
        dispatch(updateSpinnerState(true));
        try {
            const params = { status: actionId || selectedStatus, data };
            await updateRequisitionStatusInServer(params);
            setRequisitionRemark(null);
            setSelectedStatus(null);
            setSelectedRequisitionId(null);
            setRequisitionTransferNo(null);
            dispatch(updateToastInfo({
                show: true,
                type: "success",
                title: t("Requisition Updated"),
                message: t("Requisition has successfully updated")
            }))
            onRefreshClick();
        } catch (e) {
            dispatch(updateSpinnerState(false));
            dispatch(updateToastInfo({
                show: true,
                type: "danger",
                title: t("Requisition Failed"),
                message: t("Requisition failed update. Contact Support")
            }))
            return e;
        }
    };
    const updateAndPrintRequisition = async (actionId) => {
        dispatch(updateSpinnerState(true));
        try {
            await updateRequisitionStatus({ reqItems: [ { ...requisitonItems[selectedRequisitionId], reqId: selectedRequisitionId}]}, actionId);
            dispatch(setSelectedRequisitionInStore(requisitonItems[selectedRequisitionId]));
            window.open(`/requisition/print/${encodeURIComponent(requisitonItems[selectedRequisitionId].requisitionNumber)}`, "_blank")
            dispatch(updateSpinnerState(false));
            dispatch(updateToastInfo({
                show: true,
                type: "success",
                title: t("Requisition Printed"),
                message: t("Requisition Printed Successfully.")
            }))
        } catch (e) {
            dispatch(updateSpinnerState(false));
        }
    }
    const handleItemBulkUpdate = (actionId) => {
        if (selectedRequisitionId) {
            switch (actionId) {
                case "print": {
                    setSelectedStatus(actionId);
                    updateAndPrintRequisition(actionId);
                    break;
                }
                case "completed":
                case "stockNA":
                case "cancelled": {
                    setAskRequisitionNo(actionId === "completed");
                    //open a modal asking for remarks as mandatory
                    setSelectedStatus(actionId);
                    setShowRemarkModal(true);
                    break;
                }
                default: {
                    dispatch(updateToastInfo({
                        show: true,
                        type: 'danger',
                        title: t('Select an Action'),
                        message: t("Please select an action from the dropdown")
                    }))
                    break;
                }
            }
        } else {
            dispatch(updateToastInfo({
                show: true,
                type: 'danger',
                title: t('Select Item'),
                message: t("Please select an item to take action")
            }))
        }
    }
    const addRemarkAndUpdateRequisition = async (data) => {
        try {
            await updateRequisitionStatus(data, selectedStatus);
            setShowRemarkModal(false);
            dispatch(updateSpinnerState(false));
            dispatch(updateToastInfo({
                show: true,
                type: 'success',
                title: t('Requisition Updated'),
                message: t("Requisition Status updated successfully")
            }))
        } catch (e) {
            dispatch(updateSpinnerState(false));
            dispatch(updateToastInfo({
                show: true,
                type: 'danger',
                title: t('Update Failed'),
                message: t("Requisition Updated Failed.")
            }))
        }

    };
    const handleCloseRemarkModal = () => {
        setShowRemarkModal(false);
        setReadOnlyMode(false);
    }
    const showItemsModal = (objectId) => {
        onRowSelect(true, objectId)
        setReadOnlyMode(true);
        setShowRemarkModal(true);
    }
    return (
        <>
            <ParcelStatusPage
                pageType={pageType}
                filtersValue={filtersValue}
                filterSubmit={handleFiltersSubmit}
                tableData={Object.values(requisitonItems)}
                tableColumns={requisitionReceivedTableColumns(onRowSelect, showItemsModal)}
                handleRefresh={onRefreshClick}
                pageTitle={t("Requisitions Received")}
                breadCrumbItems={breadCrumbItems}
                pageSubTitle={t("All the requisitions that have been received by our warehouse")}
                searchPlaceHolder={t("Search by Requisition number")}
                handleItemBulkUpdate={handleItemBulkUpdate}
                bulkActionOptions={requisitionBulkActionOptions}
                enableBulkActions={selectedRequisitionId && Object.values(requisitonItems)?.length > 0}
            />
            <RequisitionUpdateModal
                readOnly={readOnlyMode}
                showUpdateModal={showRemarkModal}
                handleCloseModal={handleCloseRemarkModal}
                askRequisitionTransferNo={askRequisitionTransferNo}
                addRemarkAndUpdateRequisition={addRemarkAndUpdateRequisition}
                requisitonItems={requisitonItems[selectedRequisitionId] || {}}
                selectedStatus={selectedStatus}
            />

        </>
    )
}
export default RequisitionsReceived;