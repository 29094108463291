import React from 'react';
import { Dropdown, Form } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

const PopoverComponent = ({ selected, setSelected, options }) => {
  //   const handleToggleCheckboxChange = () => {
  //     setIsChecked(!isChecked);
  //   };

  return (
    <div
      className="d-flex align-items-center justify-content-center gap-2"
      style={{ width: '90px', height: '44px', backgroundColor: '#FFFFFF' }}
    >
      <Form.Check
        type="checkbox"
        label=""
        checked={selected.length > 0 ? true : false}
        // onChange={handleToggleCheckboxChange}
        style={{ marginRight: '10px', height: '29px' }}
      />

      {/* Dropdown */}
      <Dropdown>
        <Dropdown.Toggle
          as="div"
          id="dropdown-basic"
          style={{ cursor: 'pointer' }}
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {options?.map((option) => (
            <Dropdown.Item key={option} onClick={() => setSelected(option)}>
              {option}
            </Dropdown.Item>
          ))}
          {/* <Dropdown.Item onClick={() => setSelected('All')}>All</Dropdown.Item>
          <Dropdown.Item onClick={() => setSelected('Allowed')}>
            Allowed
          </Dropdown.Item>
          <Dropdown.Item onClick={() => setSelected('Blacklisted')}>
            Blacklisted
          </Dropdown.Item> */}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default PopoverComponent;
