import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Row } from '@themesberg/react-bootstrap';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import Pagination from 'react-bootstrap-table2-paginator';
import * as Paginator from 'react-bootstrap-table2-paginator';
import { inventoryColumnsStoreGuy } from '../inventory/utils';
import {
  customSizePerPage,
  customTotal,
} from '../../../components/common/parcelStatusPages';
import Table from 'react-bootstrap-table-next';
import EmptyTable from '../../../components/common/EmptyTableView';
import { StyledSearchBar } from '../../trips/styles';
import {
  faBan,
  faCheck,
  faPlus,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ExcelDownloadReport from '../../../components/common/excel/download';
import AddGSTModal from './AddGSTModal';
import PopoverComponent from './components/PopoverComponent';
import ToggleButton from './components/toggleButton';
import { useDispatch } from 'react-redux';
import { updateSpinnerState } from '../../../actions/spinner';
import { apiCall } from '../../../middlewares/api';
import { apiCallConsts } from '../../../constants/apiCallConsts';
import { updateToastInfo } from '../../../actions/settings';
import NewConfirmationModal from './components/NewConfirmationModal';
import { gstColumns } from './utils';

const Validation = () => {
  const dispatch = useDispatch();
  const [response, setResponse] = useState({});
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [gstNumber, setGstNumber] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [selected, setSelected] = useState('');
  const isInitialRender = useRef(true);
  // const [isChecked, setIsChecked] = useState(false);
  const [value, setValue] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [selectedNumbers, setSelectedNumbers] = useState([]);
  const [selectedButton, setSelectedButton] = useState('');
  const [modalValues, setModalValues] = useState({
    icon: '',
    modalTitle: '',
    modalSubTitle: '',
  });

  const statusvalues = {
    Block: {
      icon: faBan,
      modalTitle: 'Confirm Blacklist Action',
      modalSubTitle: 'Are you sure you want to Blacklist this GST Number?',
    },
    Delete: {
      icon: faTrash,
      modalTitle: 'Confirm Delete Action',
      modalSubTitle: 'Are you sure you want to Delete this GST Number?',
    },
    UnBlock: {
      icon: faCheck,
      modalTitle: 'Confirm UnBlock Action',
      modalSubTitle: 'Are you sure you want to UnBlock this GST Number?',
    },
  };

  const status = {
    Block: 'Blacklisted',
    UnBlock: 'Allowed',
    Delete: 'Deleted',
  };

  const onRowSelect = (selected, objectId) => {
    console.log(selected, objectId);
    console.log(selectedNumbers);

    if (selected) {
      setSelectedNumbers((prev) => {
        // console.log(products, products?.data);
        const selectedItem = data?.find((item) => item?.objectId === objectId);
        return [...prev, selectedItem];
      });
    } else {
      setSelectedNumbers((prev) =>
        prev.filter((item) => item?.objectId !== objectId)
      );
    }
    setData((prev) =>
      prev?.map((item) =>
        item?.objectId === objectId ? { ...item, isSelected: selected } : item
      )
    );
  };

  const fetchData = async () => {
    try {
      dispatch(updateSpinnerState(true));
      const response = await apiCall(
        apiCallConsts.GET_METHOD,
        'internal/gst',
        {}
      );
      setResponse(response);
      setData(response?.data);
      setValue(response?.isEnabled);
      dispatch(updateSpinnerState(false));
    } catch (error) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: '',
          message: 'failed to fetch data',
        })
      );
    }
  };

  const handleSubmit = async (data) => {
    try {
      dispatch(updateSpinnerState(true));
      await apiCall(apiCallConsts.POST_METHOD, 'internal/gst', {
        gstNumber: data,
      });
      dispatch(
        updateToastInfo({
          show: true,
          type: 'success',
          title: '',
          message: 'GST number successfully added',
        })
      );
      dispatch(updateSpinnerState(false));
      fetchData();
    } catch (error) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: '',
          message: 'failed to submit',
        })
      );
    }
  };
  console.log(value);

  const handleUpdate = async (data, enableToggle = false) => {
    console.log(value);

    try {
      let body;
      if (enableToggle) {
        body = {
          isEnabled: data ? 'yes' : 'no',
        };
      } else {
        body = {
          objectId: selectedNumbers.map((e) => e.objectId),
          status: status[selectedButton],
          isDeleted: data.status === 'Delete' ? true : false,
        };
      }
      dispatch(updateSpinnerState(true));
      await apiCall(apiCallConsts.PUT_METHOD, 'internal/gst', body);
      setUpdateModal(false);
      dispatch(
        updateToastInfo({
          show: true,
          type: 'success',
          title: '',
          message: 'GST number updated successfully',
        })
      );
      dispatch(updateSpinnerState(false));
      setSelected('');
      setSelectedNumbers([]);
      fetchData();
    } catch (error) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: '',
          message: 'failed to fetch data',
        })
      );
    }
  };

  const handleChange = async (value) => {
    console.log(value);
    await handleUpdate(value, true);
  };

  useEffect(() => {
    if (selectedNumbers.length <= 0) {
      setSelected('');
    }
    setSelectedNumbers([]);
    if (selected.length > 0) {
      if (selected === 'All') {
        for (let i = 0; i < data.length; i++) {
          onRowSelect(true, data[i].objectId);
        }
      } else {
        // const filtered = data.filter((each) => each.status === selected);
        for (let i = 0; i < data.length; i++) {
          if (selected === data[i].status) {
            onRowSelect(true, data[i].objectId);
          } else {
            onRowSelect(false, data[i].objectId);
          }
        }
      }
    }
  }, [selected]);

  // useEffect(() => {
  //   if (isInitialRender.current) {
  //     isInitialRender.current = false;
  //     return;
  //   }

  //   if (value !== null) {
  //     handleUpdate(null, true);
  //   }
  // }, [value]);

  useEffect(() => {
    if (selectedButton.length > 0) {
      setModalValues(statusvalues[selectedButton]);
      setUpdateModal(true);
    }
  }, [selectedButton]);

  useEffect(() => {
    fetchData();
  }, []);

  console.log(selectedNumbers.length);

  return (
    <>
      <h4>GST Blocklist</h4>
      <Row className="d-flex align-items-center my-3">
        <Col>
          <StyledSearchBar
            //   size="xs"
            width={'360.89px'}
            heigth={'53.89px'}
            type={'text'}
            value={searchText}
            placeholder={'Search'}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </Col>
        <Col className="d-flex justify-content-end gap-4">
          <div
            className=""
            style={{
              backgroundColor: 'white',
              height: '55.89px',
              borderRadius: '3.99px',
            }}
          >
            <ToggleButton
              title={''}
              options={['Enable', 'Disable']}
              value={value}
              setValue={setValue}
              onChange={handleChange}
            />
          </div>
          <div className="btn-export">
            <ExcelDownloadReport data={data} fileName="gstNumbers" />
          </div>
          <Button
            style={{
              height: '55.89px',
              borderRadius: '3.99px',
            }}
            onClick={() => setShowModal(true)}
            variant="primary"
          >
            <FontAwesomeIcon icon={faPlus} />
            &nbsp;&nbsp; {'Add GST Number'}
          </Button>
        </Col>
      </Row>
      {selectedNumbers.length > 0 && (
        <Row className="d-flex align-items-center my-3">
          <Col>
            <PopoverComponent
              selected={selected}
              setSelected={setSelected}
              options={['All', 'Allowed', 'Blacklisted']}
            />
          </Col>
          <Col className="d-flex align-items-center justify-content-end gap-4">
            <Button
              style={{
                height: '55.89px',
                borderRadius: '3.99px',
              }}
              onClick={() =>
                setSelectedButton(
                  selected === 'Blacklisted' ? 'UnBlock' : 'Block'
                )
              }
              variant="white"
              className="btn-export"
            >
              <FontAwesomeIcon icon={faBan} />
              &nbsp;&nbsp; {selected === 'Blacklisted' ? 'UnBlock' : 'Block'}
            </Button>
            <Button
              style={{
                height: '55.89px',
                borderRadius: '3.99px',
              }}
              onClick={() => setSelectedButton('Delete')}
              variant="primary"
              className="me-2"
            >
              <FontAwesomeIcon icon={faTrash} />
              &nbsp;&nbsp; {'Delete'}
            </Button>
          </Col>
        </Row>
      )}
      <ToolkitProvider
        exportCSV
        keyField="objectId"
        search={true}
        sort
        data={data || []}
        columns={gstColumns(onRowSelect, setSelectedButton)}
        wrapperClasses="table-responsive"
      >
        {({ baseProps, searchProps, csvProps }) => {
          searchProps.onSearch(searchText);
          return (
            <Paginator.PaginationProvider
              pagination={Pagination({
                custom: true,
                showTotal: true,
                alwaysShowAllBtns: true,
                totalSize: data?.length,
                paginationTotalRenderer: customTotal,
                sizePerPageRenderer: customSizePerPage,
              })}
            >
              {({ paginationProps, paginationTableProps }) => (
                <>
                  <Card>
                    <div className="table-responsive py-2">
                      {/* <Card.Header className="d-flex justify-content-between align-items-center py-3 px-3">
                        <div style={{ width: '300px' }}></div>
                        <div>totalStock</div>
                      </Card.Header> */}
                      <Table
                        noDataIndication={<EmptyTable />}
                        {...baseProps}
                        {...paginationTableProps}
                        bodyClasses="border-0"
                        classes="table-flush dataTable-table"
                      />

                      <Card.Footer>
                        <Row>
                          <Col xs={12} md={4} className="py-1">
                            <Paginator.SizePerPageDropdownStandalone
                              {...paginationProps}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            className="d-flex align-items-center py-1"
                          >
                            <Paginator.PaginationTotalStandalone
                              {...paginationProps}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            className="d-flex justify-content-md-end align-items-center mb-0 py-1"
                          >
                            <Paginator.PaginationListStandalone
                              {...paginationProps}
                            />
                          </Col>
                        </Row>
                      </Card.Footer>
                    </div>
                  </Card>
                </>
              )}
            </Paginator.PaginationProvider>
          );
        }}
      </ToolkitProvider>

      <AddGSTModal
        showModal={showModal}
        closeModal={() => setShowModal(false)}
        value={gstNumber}
        setValue={setGstNumber}
        modalTitle={'Add new GST number'}
        handleSubmit={handleSubmit}
      />

      {updateModal && (
        <NewConfirmationModal
          icon={modalValues?.icon}
          showModal={updateModal}
          closeModal={() => {
            setUpdateModal(false);
            setSelectedButton('');
            setModalValues({});
            selectedNumbers.forEach((each) =>
              onRowSelect(false, each?.objectId)
            );
          }}
          modalTitle={modalValues?.modalTitle}
          modalSubTitle={modalValues?.modalSubTitle}
          handleSubmit={handleUpdate}
          confirmActionName={'Yes'}
          cancelActionName={'Cancel'}
        />
      )}
    </>
  );
};

export default Validation;
