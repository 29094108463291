import React from "react";
import WarehouseTargetPage from "../purchase/assigned";
import { Routes } from "../../../router/routes";
import { useTranslation } from "react-i18next";

const CompletedTargets = () => {
    const { t } = useTranslation();
    const status = "seen";
    const breadCrumbItems = [{
        title: t("WMS"),
        link: Routes.WMS.Target.Assign.path
    }, {
        title: t("Seen / Completed Targets"),
        link: Routes.WMS.Target.Completed.path
    }]
    return (
        <WarehouseTargetPage
            pageName={"seenTargets"}
            status={status}
            bulkActionOptions={[{
                label: "Reassign",
                value: "reassign"
            }, {
                label: "Cancel",
                value: "cancel"
            }]}
            pageTitle={"Seen / Completed Targets"}
            pageSubTitle={"See all the scanned and seen items."}
            breadCrumbItems={breadCrumbItems}
        />
    )
}
export default CompletedTargets