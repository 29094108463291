import React, { useState } from "react";
import { Button } from "@themesberg/react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { updateSpinnerState } from "../../actions/spinner";
import { createEWBForFailedItems, generateConsolidatedEWB, generateEWBFromData, getEWBInfoByTripNumber } from "../../parse-functions/ewaybill";
import { isAlreadyConsolidated } from "../../utils/ewaybills";
import { updateToastInfo } from "../../actions/settings";
import { useTranslation } from "react-i18next";
import EWBModalForTrip from "../planning/EWBModalForTrip";

const EWayBillComponent = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    isTemporary = false
  } = props;
  const activeTrips = useSelector((state) => state.trips.activeTrips || {});
  const temporaryTrips = useSelector((state) => state.trips.temporaryTrips || {});
  const warehouse = useSelector((state) => state.user.warehouse || {});
  const selectedTrip = useSelector((state) => state.trips.selectedTrip || null);
  const [ewbInfo, setEWBInfo] = useState([]);
  const [showEWBModal, setShowEWBModal] = useState(false);

  const generateEWBForTrip = async () => {
    try {
      dispatch(updateSpinnerState(true));
      // search for the ewb Items if already done for this trip number, if not, then proceed with
      const ewbDetails = await getEWBInfoByTripNumber(selectedTrip);
      if (ewbDetails?.length > 0) {
        // lets check for consolidated item and simply open the path to print the same
        const consolidatedItem = isAlreadyConsolidated(ewbDetails);
        if (consolidatedItem?.length > 0) {
          // navigate to new url to print.
          window.open(`${window.location.origin}/ewb/print/${consolidatedItem[0].objectId}`, "_blank")
          dispatch(updateSpinnerState(false));
        } else {
          // data exists, and has not been consolidated. show it as is
          setEWBInfo(ewbDetails);
          setShowEWBModal(true);
          dispatch(updateSpinnerState(false));
        }
      } else {
        // go and generate info
        const ewaybillResponse = await generateEWBFromData({
          tripNumber: selectedTrip,
          warehouse
        });
        setEWBInfo(JSON.parse(JSON.stringify(ewaybillResponse)));
        setShowEWBModal(true);
        dispatch(updateSpinnerState(false));
      }
    } catch (e) {
      dispatch(updateSpinnerState(false));
      dispatch(updateToastInfo({
        show: true,
        type: "danger",
        title: "EWB Failed",
        message: e.message || "Error Generating EWB. Please contact support"
      }))
    }
  }
  const handleFailedItemsEWBRetry = async () => {
    dispatch(updateSpinnerState(true));
    try {
      await createEWBForFailedItems({
        warehouse,
        tripNumber: selectedTrip,
      });
      const ewbDetails = await getEWBInfoByTripNumber(selectedTrip);
      setEWBInfo(ewbDetails);
      dispatch(updateSpinnerState(false));
    } catch (e) {
      dispatch(updateToastInfo({
        show: true,
        type: 'danger',
        title: t("EWB Generation Failed"),
        message: t("EWB Generation failed. Please Retry or contact support")
      }))
      dispatch(updateSpinnerState(false));
    }
  }
  const getVehicleNumber = () => {
    if (isTemporary) {
      return temporaryTrips[selectedTrip]?.dispatchData?.vehicleNumber
    } else {
      return activeTrips[selectedTrip].vehicleNumber
    }
  }
  const handleEWBConsolidate = async () => {
    const vehicleNumber = getVehicleNumber();
    try {
      dispatch(updateSpinnerState(true));
      setShowEWBModal(false);
      const consolidatedEWB = await generateConsolidatedEWB({
        tripNumber: selectedTrip,
        warehouse,
        vehicleNumber
      });
      dispatch(updateSpinnerState(false));
      dispatch(updateToastInfo({
        show: true,
        type: "success",
        title: t("EWB Consolidated"),
        message: t("Please go Ahead and print the EWB")
      }))
      window.open(`${window.location.origin}/ewb/print/${consolidatedEWB.objectId}`, "_blank")
    } catch (e) {
      dispatch(updateSpinnerState(false));
      dispatch(updateToastInfo({
        show: true,
        type: "danger",
        title: t("EWB Consolidation Failed. Please Try Again"),
        message: t(e)
      }))
    }
  }
  return (
    <>
      <Button variant="outline-primary" size="sm" onClick={() => { generateEWBForTrip(); }}>{t("Print EWB")}</Button>
      <EWBModalForTrip
        showModal={showEWBModal}
        ewbInfo={ewbInfo}
        handleEWBConsolidate={handleEWBConsolidate}
        handleClose={() => {
          setShowEWBModal(false)
        }}
        handleFailedItemsEWBRetry={handleFailedItemsEWBRetry} />
    </>
  )
}
export default EWayBillComponent;