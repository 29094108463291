/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-loop-func */

import { Badge, Button, Card, Form, Table } from "@themesberg/react-bootstrap";
import Joi from "joi";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { updateToastInfo } from "../../actions/settings";
import { updateSpinnerState } from "../../actions/spinner";
import { setCurrentTripParcels, setDataForSelectedParcelsInActiveTrip } from "../../actions/trips";
import DropdownComponent from "../../components/common/Dropdown";
import { updateClosureDataInParcel } from "../../parse-functions/trips";
import { getColumnHeaders, getTableRowCheckBox, returnBuybackScanInformation, returnCustomerAddressString, returnParcelBranchInfo, returnParcelProductInformation, returnParcelReturnReceivedInfo, returnParcelReturnScanInformation, returnProductBuybackInformation } from "../../utils/table";
import { returnValidationErrorMessages } from "../../utils/validation";
import PODImageSlider from "../../components/common/images/podImagesSlider";
import { returnUserReadableDate, returnUserReadableDateTime } from "../../utils/datetime";
import { returnRowBGColor, showTagTypeInTrips } from "../../utils/parcels";
import { getTripBadgeStatus } from "../../utils/trips";
import { orderStatusMap } from "../../utils/constants";
import { convertArrayToObj, sortArrayBasedOnKey } from "../../utils/json";

const cashSubStatusItems = ["buybackdeclined", "buybackpickedcashcollected"];

const updateParcelSchema = Joi.object({
    dashboardStatus: Joi.string().required(),
    dashboardSubStatus: Joi.string().required(),
    failureReason: Joi.string().when('dashboardStatus', { is: "failed", then: Joi.required(), otherwise: Joi.optional().allow(null) }),
    cashCollected: Joi.alternatives().conditional('dashboardSubStatus', {
        is: Joi.array().valid(...cashSubStatusItems),
        then: Joi.string().required(),
        otherwise: Joi.optional(),
    }),
    remarks: Joi.string().when('failureReason', { is: "others", then: Joi.required(), otherwise: Joi.optional().allow('') })
})

const columnNames = ["checkBox", "Customer", "Address", "Invoice", "Serial", "Product", "Buyback", "Mobile Status", "Dashboard Status", "Return Info", "Buyback Received", "Received Info", "Others", "POD / Sign"];
const TableRow = (props) => {
    const {
        customerName,
        shippingMobileNo,
        serialNo,
        invoiceNo,
        objectId,
        quantity,
        date,
        onRowSelect,
        isSelected,
        mobileStatus,
        mobileSubStatus,
        dashboardStatus,
        dashboardSubStatus,
        remarks,
        cashCollected,
        podImages,
        customerSignature,
        handleShowPODImagesModal,
        returnScannedAt
    } = props;
    const status = props.dashboardStatus || mobileStatus || props.status;
    const returnPODImagesAndSign = () => {
        let imgArray = [];
        if (podImages?.length > 0) {
            imgArray = [...imgArray, ...podImages];
        }
        if (customerSignature) {
            imgArray = [...imgArray, customerSignature];
        }
        return imgArray;
    }
    const totalImages = returnPODImagesAndSign();
    return (
        <tr className={returnRowBGColor(status)}>
            <td>
                {getTableRowCheckBox(onRowSelect, objectId, isSelected)}
            </td>
            <td>
                <div className="row d-flex">
                    {returnCustomerAddressString(customerName, shippingMobileNo, 150, 35)}
                </div>
                <div className="row d-flex">
                    {showTagTypeInTrips(props) ? <Badge bg={getTripBadgeStatus(showTagTypeInTrips(props))} className="w-50 pl-4 badge-lg">{`Scan ${showTagTypeInTrips(props)}`}</Badge> : ""}
                    {props?.customerCode ? `Code: ${props.customerCode}` : ""}
                </div>
            </td>
            <td>
                {returnCustomerAddressString(`${props.shippingAddress1 || ""}${props.shippingAddress2 || ""}${props.shippingAddress3 || ""}`, props.shippingMobileNo || props.mobileNo, 150, 35)}
            </td>
            <td>
                <div className="d-block">
                    <span className="fw-bold">{invoiceNo}</span>
                    <div className="fw-normal">{date ? returnUserReadableDate(date) : "-"}</div>
                </div>

            </td>
            <td>
                <div className="d-block">
                    <span className="fw-normal">
                        {serialNo}
                    </span>
                    <div className="fw-normal">
                        {Number(quantity).toFixed(0)} Quantity
                    </div>
                </div>
            </td>
            <td>
                {returnParcelProductInformation(props)}
            </td>
            <td>
                {returnProductBuybackInformation(props)}
            </td>
            <td>
                {returnParcelReturnReceivedInfo(props)}
            </td>
            <td>
                <div className="d-block">
                    <span className="fw-bold">
                        {mobileStatus ? <Badge bg={getTripBadgeStatus(status)} className="badge-lg w-100">{orderStatusMap[status]}</Badge> : ""}
                    </span>
                    <div className="fw-normal">
                        {mobileSubStatus ? <Badge bg={getTripBadgeStatus(mobileSubStatus)} className="badge-lg w-100">{orderStatusMap[mobileSubStatus]}</Badge> : ""}
                    </div>
                </div>
            </td>
            <td>
                <div className="d-block">
                    <span className="fw-bold">
                        {dashboardStatus ? <Badge bg={getTripBadgeStatus(dashboardStatus)} className="badge-lg w-100">{orderStatusMap[dashboardStatus]}</Badge> : ""}
                    </span>
                    <div className="fw-normal">
                        {dashboardSubStatus ? <Badge bg={getTripBadgeStatus(dashboardSubStatus)} className="badge-lg w-100">{orderStatusMap[dashboardSubStatus]}</Badge> : ""}
                    </div>
                </div>
            </td>
            <>
                {returnParcelReturnScanInformation(props)}
            </>
            <td>
                {returnBuybackScanInformation(props)}
            </td>
            <td>
                <div className="d-block">
                    <span className="fw-normal">
                        {remarks || "-"}
                    </span>
                    <div className="fw-normal">
                        {cashCollected ? `Cash: INR ${cashCollected}` : "-"}
                    </div>
                </div>
            </td>
            <td>
                <div className="d-block">
                    <span className="fw-normal">
                        <Button
                            disabled={!totalImages?.length}
                            onClick={() => {
                                handleShowPODImagesModal(totalImages, props?.parcelUpdateLocation);
                            }}
                            variant="outline-info"
                            size="sm"
                            className="m-1">{`${totalImages?.length || 0} Images`}</Button>
                    </span>
                </div>
            </td>
        </tr>
    );
};


const CloseTripParcelsList = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [updateEnabled, setUpdateEnabled] = useState(false);
    const activeTrips = useSelector((state) => state.trips.activeTrips || {});
    const selectedTrip = useSelector((state) => state.trips.selectedTrip || "");
    const [parcelDataObj, setParcelDataObj] = useState([]);
    const statusAndReasons = useSelector((state) => state.user.config.statusAndReasons || {});
    const [subStatusOptions, setSubStatusOptions] = useState([]);
    const [reasonsOptions, setReasonsOptions] = useState([]);
    const [currentSelectedStatus, setCurrentSelectedStatus] = useState(null);
    const [currentSelectedSubstatus, setCurrentSelectedSubStatus] = useState(null);
    const [currentSelectedReason, setCurrentSelectedReason] = useState(null);
    const [cashCollected, setCashCollected] = useState('');
    const [remarks, setRemarks] = useState('');
    const [errorsInStatus, setErrorInStatus] = useState({});
    const [podImages, setShowPODImages] = useState([]);
    const [showPODImagesModal, setShowPODImagesModal] = useState(false);
    const [podImageDescription, setPODImageDescription] = useState("");
    const warehouse = useSelector((state) => state?.user?.warehouse);

    useEffect(() => {
        // set ParcelData
        const sortedDataForTags = returnSortedParcelsForScanning(activeTrips[selectedTrip]?.parcels);
        setParcelDataObj(sortedDataForTags)
    }, []);

    useEffect(() => {
        const sortedDataForTags = returnSortedParcelsForScanning(activeTrips[selectedTrip]?.parcels);
        setParcelDataObj(sortedDataForTags);
    }, [activeTrips, selectedTrip]);

    const returnSortedParcelsForScanning = (parcelsObj) => {
        const parcelsArray = Object.values(parcelsObj).map((parcel) => {
            if (showTagTypeInTrips(parcel)) {
                return {
                    ...parcel,
                    showTag: true
                }
            }
            return {
                ...parcel,
                showTag: false
            }
        });
        return convertArrayToObj(sortArrayBasedOnKey(parcelsArray, "showTag"), "objectId");
    };

    const onTableSelectAll = (value) => {
        handleOnRowItemsSelected(value, Object.keys(parcelDataObj));
    }
    const onRowSelect = (value, objectId) => {
        handleOnRowItemsSelected(value, [objectId]);
    }
    const handleOnRowItemsSelected = (value, objectIds) => {
        const updatedParcelObjItems = Object.assign({}, parcelDataObj);
        // eslint-disable-next-line no-unused-expressions
        objectIds?.forEach(objectId => {
            updatedParcelObjItems[objectId] = {
                ...updatedParcelObjItems[objectId],
                isSelected: value
            };
        })
        const areItemsSelected = Object.values(updatedParcelObjItems)?.filter(item => item.isSelected);
        dispatch(setCurrentTripParcels(updatedParcelObjItems));
        setUpdateEnabled(areItemsSelected?.length > 0);
    }

    const handleStatusSelect = (option) => {
        setCurrentSelectedStatus(option.value);
        setSubStatusOptions(statusAndReasons.orderSubStatusMap[option.value])
        if (option.value === "failed") {
            setReasonsOptions(statusAndReasons.orderFailureReasonList)
        } else {
            setReasonsOptions([]);
        }
    }
    const handleItemLineSave = async () => {
        const statusData = {
            dashboardStatus: currentSelectedStatus,
            dashboardSubStatus: currentSelectedSubstatus,
            failureReason: currentSelectedReason,
            remarks,
            cashCollected
        };
        const { error: formErrors = {}, value: formValue } = updateParcelSchema.validate(statusData, {
            abortEarly: false
        });
        const {
            details = {}
        } = formErrors;
        if (details?.length > 0) {
            const validationErrorMessages = returnValidationErrorMessages(details);
            setErrorInStatus(validationErrorMessages);
        } else {
            dispatch(updateSpinnerState(true));
            const selectedParcels = Object.values(activeTrips[selectedTrip]?.parcels).filter(parcel => parcel?.isSelected);
            const selectedParcelIds = selectedParcels?.map(parcel => parcel.objectId);
            try {
                // update the parcel in the server.
                await updateClosureDataInParcel({
                    ids: selectedParcelIds,
                    data: formValue,
                    warehouseCode: warehouse.warehouseCode,
                    shortCode: warehouse.shortCode
                });
                // object is valid, now store these informations.
                dispatch(updateToastInfo({
                    show: true,
                    type: "success",
                    title: t("Parcel status updated"),
                    message: t("Parcel Updated. Please scan the buyback / return items")
                }))
                dispatch(setDataForSelectedParcelsInActiveTrip(formValue))
                dispatch(updateSpinnerState(false));
                setUpdateEnabled(false);
                setCurrentSelectedStatus(null);
                setCurrentSelectedSubStatus(null);
                setCurrentSelectedReason(null);
                setCashCollected('');
                setRemarks('');
                setReasonsOptions([]);
            } catch (e) {
                dispatch(updateToastInfo({
                    show: true,
                    type: "danger",
                    title: t("Parcel Update Failed"),
                    message: t("Try again or contact support if issue persists")
                }))
                dispatch(updateSpinnerState(false));
            }
            setErrorInStatus({});
        }
    };
    const handleShowPODImagesModal = (images, parcelUpdateLocation) => {
        setShowPODImages(images);
        if (parcelUpdateLocation?.time) {
            setPODImageDescription(`Image taken at: ${returnUserReadableDateTime(parcelUpdateLocation?.time)}`);
        }
        setShowPODImagesModal(true);
    };

    const columnHeaders = getColumnHeaders(columnNames, onTableSelectAll);
    return (
        <>
            <Card border="light" className="table-wrapper table-responsive shadow-sm">
                <Card.Body>
                    <div className="d-flex mb-3">
                        <Form.Group id="status" className="d-flex align-items-center ms-3 flex-column">
                            <DropdownComponent
                                width={200}
                                onChange={handleStatusSelect}
                                disabled={!updateEnabled}
                                options={statusAndReasons?.orderStatuses || []}
                                placeholder={"Status"}
                            />
                            {errorsInStatus["dashboardStatus"]?.length > 0 && (
                                <span type="invalid" style={{
                                    color: 'red'
                                }}>{t(("Please Select Status"))}</span>

                            )}
                        </Form.Group>
                        <Form.Group id="substatus" className="d-flex align-items-center ms-3 flex-column">
                            <DropdownComponent
                                onChange={(option) => {
                                    setCurrentSelectedSubStatus(option.value);
                                    if (option.value === "defectivepicked") {
                                        // set the remarks and reasons as different set of values
                                        setReasonsOptions(statusAndReasons.orderDefectiveReasonList);
                                    }
                                }}
                                width={300}
                                disabled={subStatusOptions?.length === 0 || !updateEnabled}
                                options={subStatusOptions}
                                placeholder={"Sub Status"}
                            />
                            {errorsInStatus["dashboardSubStatus"]?.length > 0 && (
                                <span type="invalid" style={{
                                    color: 'red'
                                }}>{t("Please Select Sub Status")}</span>

                            )}
                        </Form.Group>
                        <Form.Group id="reason" className="d-flex align-items-center ms-3 flex-column">
                            <DropdownComponent
                                onChange={(option) => {
                                    setCurrentSelectedReason(option.value);
                                }}
                                width={300}
                                disabled={reasonsOptions?.length === 0 || !updateEnabled}
                                options={reasonsOptions}
                                placeholder={"Reason"}
                            />
                            {errorsInStatus["failureReason"]?.length > 0 && (
                                <span type="invalid" style={{
                                    color: 'red'
                                }}>{t(("Please Select Reason"))}</span>

                            )}
                        </Form.Group>
                        <Form.Group id="cashCollected" className="d-flex align-items-center ms-3 flex-column">
                            <Form.Control
                                isInvalid={errorsInStatus["cashCollected"]?.length}
                                onChange={(event) => {
                                    setCashCollected(event.target.value);
                                }}
                                type="number"
                                placeholder="Enter Cash Collected"
                                disabled={!updateEnabled} />
                            {errorsInStatus["cashCollected"]?.length > 0 && (
                                <span type="invalid" style={{
                                    color: 'red'
                                }}>{t(("Please Enter Amount Collected"))}</span>

                            )}
                        </Form.Group>
                        <Form.Group id="remarks" className="d-flex align-items-center ms-3 flex-column">
                            <Form.Control
                                isInvalid={errorsInStatus["remarks"]?.length}
                                onChange={(event) => {
                                    setRemarks(event.target.value);
                                }}
                                type="text"
                                placeholder="Enter Remarks"
                                disabled={!updateEnabled} />
                            {errorsInStatus["remarks"]?.length && (
                                <Form.Control.Feedback type="invalid">{t("Please enter remarks")}</Form.Control.Feedback>
                            )}
                        </Form.Group>
                        <Button
                            onClick={handleItemLineSave}
                            disabled={!updateEnabled}
                            className="ms-3 btn btn-secondary"
                            variant="secondary">{t("Update")}</Button>
                    </div>

                    <Table hover className="user-table align-items-center">
                        <thead>
                            <tr>
                                {columnHeaders}
                            </tr>
                        </thead>
                        <tbody>
                            {Object.values(parcelDataObj)?.map((t, index) => <TableRow handleShowPODImagesModal={handleShowPODImagesModal} onRowSelect={onRowSelect} key={`transaction-${t.objectId}`} {...t} index={index} />)}
                        </tbody>
                    </Table>
                    <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
                        <small className="fw-bold">
                            Showing <b>{Object.keys(parcelDataObj)?.length}</b> entries
                        </small>
                    </Card.Footer>
                </Card.Body>
            </Card>
            <PODImageSlider
                description={podImageDescription}
                show={showPODImagesModal}
                handleClose={setShowPODImagesModal}
                images={podImages} />
        </>

    )
}
export default CloseTripParcelsList;