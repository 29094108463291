export default {
    translations: {
        "Save Temporary Trip": "अस्थायी यात्रा सहेजें",
        "View Packing": "पैकिंग देखें",
        "Orders Count": "पैकिंग ऑर्डर की संख्या देखें",
        "Locations": "स्थानों",
        "Temporary Trip Created Successfully": "अस्थायी यात्रा सफलतापूर्वक बनाई गई",
        "Please visit the trip page to take more action on this trip": "इस यात्रा पर अधिक कार्रवाई करने के लिए कृपया यात्रा पृष्ठ पर जाएँ",
        "Temporary Trip Creation failed": "अस्थायी यात्रा निर्माण विफल रहा",
        "Please try again or contact support": "कृपया पुनः प्रयास करें या सहायता से संपर्क करें",
        "Add New Warehouse": "नया गोदाम जोड़ें",
        "Use this page to add a new warehouse": "नया गोदाम जोड़ने के लिए इस पृष्ठ का उपयोग करें",
        "Warehouse Name": "गोदाम का नाम",
        "Warehouse Code": "गोदाम कोड",
        "Warehouse Short Code": "वेयरहाउस शॉर्ट कोड",
        "Address": "पता",
        "Pincode": "पिन कोड",
        "zone": "क्षेत्र",
        "UserName": "क्षेत्र उपयोक्तानाम",
        "Password": "पासवर्ड",
        "Repeat Password": "पासवर्ड दोहराएं",
        "Create User": "उपयोगकर्ता बनाइये",
        "Cancel": "रद्द करना",
        "Add Users": "उपयोगकर्ता जोड़ें",
        "Add Warehouse": "गोदाम जोड़ें",
        "Add Incharge": "प्रभारी जोड़ें",
        "Add New Incharge": "नया प्रभारी जोड़ें",
        "Use this page to add a new incharge": "नया प्रभारी जोड़ने के लिए इस पृष्ठ का उपयोग करें",
        "Incharge Name": "प्रभारी का नाम",
        "Phone Number": "प्रभारी का नाम",
        "Email Id": "ईमेल आईडी",
        "Select Application": "एप्लिकेशन चुनें",
        "Warehouse / Store Name": "गोदाम/भंडार का नाम",
        "Incharge Info": "प्रभारी जानकारी",
        "Login Info": "लॉगिन जानकारी",
        "Item Removed": "आइटम हटा दिया गया",
        "Parcel Removed from trip successfully": "पार्सल को यात्रा से सफलतापूर्वक हटा दिया गया",
        "Removal Failed": "निष्कासन विफल",
        "Parcel removal failed, Please try later": "पार्सल हटाना विफल रहा, कृपया बाद में प्रयास करें",
        "Scan, Print EWB & Dispatch your vehicle from here": "ईडब्ल्यूबी को स्कैन करें, प्रिंट करें और यहां से अपना वाहन भेजें",
        "Enter Serial No to Scan": "स्कैन करने के लिए सीरियल नंबर दर्ज करें",
        "શું તમે ખરેખર દૂર કરવા માંગો છો": "क्या आप अवश्य हटाना चाहते हैं",
        "Remove Orders": "आदेश हटाएँ",
        "Confirm": "पुष्टि करना",
        "Drop File here": "फ़ाइल यहां छोड़ें",
        "Select Display Sheet": "डिस्प्ले शीट चुनें",
        "Please enter valid InvoiceId [X-Q-SI X] [RC/X/X-X/X] [PC/X/X-X/X] [X-S-SI X]": "कृपया वैध चालान आईडी [X-Q-SI X] [RC/X/X-X/X] [PC/X/X-X/X] [X-S-SI X] दर्ज करें",
        "Vehicle Number is mandatory": "वाहन का नंबर अनिवार्य है",
        "Vehicle Area is mandatory": "वाहन क्षेत्र अनिवार्य है",
        "Please Select an option": "कृपया एक विकल्प चुनें",
        "Please Enter Valid Vehicle Number": "कृपया वैध वाहन नंबर दर्ज करें",
        "Please select an option": "कृपया एक विकल्प चुनें",
        "Create Temporary trip": "अस्थायी यात्रा बनाएँ",
        "Vehicle Number": "गाडी नंबर",
        "Vehicle Area": "वाहन क्षेत्र",
        "Save temporary": "अस्थायी सहेजें",
        "Height": "ऊंचाई",
        "Length": "लंबाई",
        "Breadth": "चौड़ाई",
        "Incharge Added": "प्रभारी जोड़ा गया",
        "incharge added successfully": "प्रभारी सफलतापूर्वक जोड़ा गया",
        "incharge failed to add": "प्रभारी जोड़ने में असफल रहे",
        "Incharge Add Failed": "प्रभारी जोड़ें विफल",
        "Please enter Incharge name": "कृपया प्रभारी का नाम दर्ज करें",
        "Delivery Management": "वितरण प्रबंधन",
        "Warehouse Management": "गोदाम प्रबंधन",
        "Finance": "वित्त",
        "Omni Channel": "ओमनी चैनल",
        "Product Management": "उत्पाद प्रबंधन",
        "Select Applications to give access": "पहुंच देने के लिए एप्लिकेशन का चयन करें",
        "All your temporary trips are listed here.": "आपकी सभी अस्थायी यात्राएँ यहाँ सूचीबद्ध हैं।",
        "Previous": "पहले का",
        "Next": "अगला",
        "Dashboard": "डैशबोर्ड",
        "Overview": "अवलोकन",
        "Dispatch": "प्रेषण",
        "Temporary Trips": "अस्थायी यात्राएँ",
        "Trips List": "यात्रा सूची",
        "Requisitions": "मांग",
        "Trips": "ट्रिप्स",
        "Parcels": "पार्सल",
        "Vehicles": "वाहनों",
        "Live": "रहना",
        "Plan Vehicles": "योजना वाहन",
        "Prepare temporary trip sheet here": "यहां अस्थायी यात्रा पत्रक तैयार करें",
        "Enter / Scan Invoice Number": "इनवॉइस नंबर दर्ज करें/स्कैन करें",
        "Warehouse Info": "गोदाम की जानकारी",
        "Please select a zone": "कृपया एक क्षेत्र चुनें",
        "Active Trips": "सक्रिय यात्राएँ",
        "All the trips of your warehouse are listed here.": "आपके गोदाम की सभी यात्राएँ यहाँ सूचीबद्ध हैं।",
        "New Trip": "नई यात्रा",
        "Share": "शेयर करना",
        "Export": "निर्यात",
        "Trip Number": "यात्रा संख्या",
        "VEHICLE NUMBER": "गाडी नंबर",
        "VEHICLE AREA": "वाहन क्षेत्र",
        "ने निर्मित": "के द्वारा बनाई गई",
        "DATE": "तारीख",
        "STATUS": "दर्जा",
        "Pending": "लंबित",
        "Scan, Print EWB & Dispatch your vehicle from here.": "ईडब्ल्यूबी को स्कैन करें, प्रिंट करें और यहां से अपना वाहन भेजें।",
        "Print Sheet": "शीट प्रिंट करें",
        "Print EWB": "ईडब्लूबी प्रिंट करें",
        "CUSTOMER": "ग्राहक",
        "INVOICE NO": "चालान नंबर",
        "ADDRESS": "पता",
        "SERIAL NO": "सीरीयल नम्बर",
        "PRODUCT": "उत्पाद",
        "ACTION": "कार्य",
        "Remove": "निकालना",
        "Loader Name 1": "लोडर का नाम 1",
        "Loader Number 1": "लोडर नंबर 1",
        "Loader Name 2": "लोडर का नाम 2",
        "Loader Number 2": "लोडर क्रमांक 2",
        "Loader Name 3": "लोडर का नाम 3",
        "Loader Number 3": "लोडर क्रमांक 3",
        "Loader Name 4": "लोडर का नाम 4",
        "Loader Number 4": "लोडर नंबर 4",
        "Loader Name 5": "लोडर का नाम 5",
        "Loader Number 5": "लोडर क्रमांक 5",
    }
}