import {
    SET_DMS_USERS_LIST_IN_STORE,
    SET_DMS_USER_LIST_AS_SELECTED
} from "./types"

export const setDMSUsersListInStore = (data) => {
    return {
        type: SET_DMS_USERS_LIST_IN_STORE,
        data
    }
}

export const setDMSUserAsSelected = (data) => {
    return {
        type: SET_DMS_USER_LIST_AS_SELECTED,
        data
    }
}
