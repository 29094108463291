import { faFileExport, faHome, faPlus, faRefresh } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Breadcrumb, Button, ButtonGroup, Col, Row } from "@themesberg/react-bootstrap";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setActiveTripsListInStore, setSelectedTripInStore } from "../../actions/trips";
import { Routes } from "../../router/routes";
import { getCurrentTripsFromServer } from "../../parse-functions/trips";
import { convertArrayToObj } from "../../utils/json";
import TripsTableData from "./tripsTableData";
import { updateSpinnerState } from "../../actions/spinner";
import { updateToastInfo } from "../../actions/settings";
import DateRangeSelector from "../../components/common/DateRangeSelector";
import { setFiltersInStore } from "../../actions/filters";
import { returnEndOfDay, returnStartOfDay } from "../../utils/datetime";
import { returnActiveTripColumns } from "./tripsUtils";
import { returnActiveTripsDataToDownload } from "../../utils/trips";
import ExcelDownloadReport from "../../components/common/excel/download";

const TripsList = () => {
  const { t } = useTranslation();
  const pageType = "activeTrips";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const activeTrips = useSelector((state) => state.trips.activeTrips || {});
  const storeInfo = useSelector((state) => state.user.warehouse || {});
  const filtersValue = useSelector((state) => state.filters?.[pageType]);
  const [dataToDownload, setDataToDownload] = useState([]);
  const isSuperAdmin = useSelector((state) => state.user.preferences.isSuperAdmin || false);

  const handleRowClick = (tripNumber, objectId) => {
    dispatch(setSelectedTripInStore(tripNumber));
    navigate(`/trip/${objectId}`);
  };

  const handlePrintClick = (event, objectId) => {
    event.stopPropagation();
    window.open(`${window.location.origin}/trips/${objectId}/print`, "_blank")
  }

  const getAllTrips = async (requestProps) => {
    dispatch(updateSpinnerState(true));
    try {
      const tempTrips = await getCurrentTripsFromServer(requestProps);
      const tripsObject = convertArrayToObj(tempTrips, "tripNumber");
      dispatch(setActiveTripsListInStore(tripsObject));
      const data = returnActiveTripsDataToDownload(tripsObject);
      setDataToDownload(data);
      dispatch(updateToastInfo({
        show: true,
        type: 'success',
        title: t('All trips fetched'),
        message: t('These are all the active trips currently from your warehouse')
      }));
      dispatch(updateSpinnerState(false));
    } catch (e) {
      dispatch(updateSpinnerState(false));
      dispatch(updateToastInfo({
        show: true,
        type: 'danger',
        title: t("Error in getting active Trips"),
        message: e.message
      }));
    }
  };
  const onDateSelect = (startDate, endDate) => {
    const requestProps = {
      startDate: new Date(startDate),
      endDate: new Date(endDate),
      warehouseCode: storeInfo?.warehouseCode,
      isSuperAdmin
    };
    dispatch(setFiltersInStore({
      pageType,
      data: {
        startDate: new Date(startDate),
        endDate: new Date(endDate)
      }
    }))
    getAllTrips(requestProps);
  }
  const handleGetTrips = () => {
    getAllTrips({
      startDate: filtersValue.startDate ? new Date(filtersValue.startDate) : returnStartOfDay(new Date()),
      endDate: filtersValue.endDate ? new Date(filtersValue.endDate) : returnEndOfDay(new Date()),
      warehouseCode: storeInfo?.warehouseCode,
      isSuperAdmin
    })
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>{t("Trips")}</Breadcrumb.Item>
            <Breadcrumb.Item active>{t("List")}</Breadcrumb.Item>
          </Breadcrumb>
          <h4>{t("Active Trips")}</h4>
          <p className="mb-0">{t("All the trips of your warehouse are listed here.")}</p>
        </div>

        <div className="btn-toolbar mb-2 mb-md-0">
          <Button
            size="sm"
            variant="primary"
            style={{
              marginRight: 20
            }}
            onClick={() => {
              navigate(Routes.PlanningPage.path)
            }}>
            <FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp; {t("New Trip")}
          </Button>
          <ButtonGroup>
            <Button onClick={() => {
              handleGetTrips();
            }} variant="outline-primary" size="sm">
              <FontAwesomeIcon icon={faRefresh} />&nbsp;&nbsp;{t("Refresh")}
            </Button>
            <ExcelDownloadReport
                data={dataToDownload || []}
                fileName={`Dispatched-Trips.csv`}
            />
          </ButtonGroup>
        </div>
      </div>

      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
          <Col xs={8} md={6} lg={6} xl={6}>
            <DateRangeSelector onSubmit={onDateSelect} filtersValue={filtersValue} />
          </Col>
        </Row>
      </div>

      <TripsTableData
        columns={returnActiveTripColumns(handlePrintClick)}
        data={Object.values(activeTrips)}
        onRowClick={handleRowClick}
      />
    </>
  )
}
export default TripsList;