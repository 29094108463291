import React, { useState } from "react";
import { Button, Form, Modal } from "@themesberg/react-bootstrap";
import { generateRandomId } from "../../utils/string";
import { useTranslation } from "react-i18next";


const BuybackReceivedModal = (props) => {
    const { t } = useTranslation();
    const {
        showModal,
        closeModal,
        onSubmit
    } = props;
    const [transferNo, setTransferNo] = useState(null);
    const [showError, setShowError] = useState(false);

    const handleConfirmClick = () => {
        if (transferNo?.length > 0) {
            onSubmit(transferNo);
        } else {
            setShowError(true);
        }
    }
    const handleCloseModal = () => {
        setTransferNo({});
        closeModal();
    }
    return (
        <Modal id={generateRandomId()} as={Modal.Dialog} centered show={showModal} onHide={handleCloseModal}>
            <Modal.Header>
                <Modal.Title className="h6">{t("Close Buyback")}</Modal.Title>
                <Button variant="close" aria-label="Close" onClick={handleCloseModal} />
            </Modal.Header>
            <Modal.Body>
            <Form.Group id="wingsTransferNo">
                <Form.Label>Wings Transfer No</Form.Label>
                <Form.Control
                    required
                    type="text"
                    onChange={(event) => {
                        setTransferNo(event.target.value)
                    }}
                    placeholder="Enter WINGS Transfer No" />
              </Form.Group>
                {showError && (
                    <Form.Control.Feedback type="invalid">{t("Please enter WINGS Transfer No")}</Form.Control.Feedback>
                )}
                
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleConfirmClick}>
                    {t("Confirm")}
                </Button>
                <Button variant="link" className="text-gray ms-auto" onClick={handleCloseModal}>
                    {t("Cancel")}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
export default BuybackReceivedModal;