import React, { useState } from "react";
import LoaderUpdateForm from "./LoaderUpdateForm";
import { Breadcrumb, Col, Modal, Row } from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { updateToastInfo } from "../../actions/settings";
import { updateSpinnerState } from "../../actions/spinner";
import { createNewLoader } from "../../parse-functions/loaders";

const AddLoaders = () => {
    const [showAlreadyExistsModal, setShowAlreadyExistsModal] = useState(false);
    const [formData, setFormData] = useState({});
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const onFormSubmit = async (formData) => {
        setFormData(formData);
        try {
            dispatch(updateSpinnerState(true));
            const savedLoader = await createNewLoader({
                data: formData
            });
            dispatch(updateSpinnerState(false));
            if (savedLoader.code === 200) {
                dispatch(updateToastInfo({
                    show: true,
                    type: "success",
                    title: t("Loader Created"),
                    message: t(savedLoader?.message || "New Loader added successfully")
                }))
            } else {
                setShowAlreadyExistsModal(true);
            }
        } catch (e) {
            console.log(e);
            if(e.code === 409)  {
                setShowAlreadyExistsModal(true);
            }
            dispatch(updateSpinnerState(false));
            dispatch(updateToastInfo({
                show: true,
                type: "danger",
                title: t("Error in creating new loader"),
                message: t(e?.message || "Please contact support if this issue persists")
            }))
        }
    };
    return (
        <div className="mt-1">
            <div className="mb-4 d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-2">
                <div className="d-block mb-4 mb-md-0">
                    <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
                        <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
                        <Breadcrumb.Item>{t("Vendor")}</Breadcrumb.Item>
                        <Breadcrumb.Item active>{t("Add")}</Breadcrumb.Item>
                    </Breadcrumb>
                    <h4>{t("Add New Loader")}</h4>
                    <p className="mb-0">{t("Use this page to add add a new Loader")}</p>
                </div>
            </div>
            <Row>
                <Col />
                <Col>
                    <LoaderUpdateForm add onConfirm={onFormSubmit} />
                </Col>
                <Col />
            </Row>
            <Modal
                centered
                show={showAlreadyExistsModal}
                onHide={() => {
                    setShowAlreadyExistsModal(false)
                }}
                className="modal-tertiary order-already-scheduled-modal">
                <Modal.Header className="mx-auto">
                    <p className="lead mb-0 text-white">{t("Loader already exists for this Code")}</p>
                </Modal.Header>
                <Modal.Body className="pt-0">
                    <div className="py-3 px-5 text-center">
                        <Modal.Title className="text-white mb-3">
                            <h3>{"Please try with a different Code"}</h3>
                        </Modal.Title>
                        <p className="mb-4 text-white">
                            <div>
                                <span>Loader Code: </span>
                                <b><span>{formData?.loaderCode}</span></b>
                            </div>
                        </p>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}
export default AddLoaders;