import React from "react";
import { returnReadableDate } from "../datetime";
import { returnCustomerAddressString } from "../table";

export const debitNoteTable = () => {
  return [
    {
      dataField: "noteNo",
      text: "Note Number",
    },
    {
      dataField: "date",
      text: "Issued Date",
      formatter: (cell, row) => {
        return returnReadableDate(cell);
      },
    },
    {
      dataField: "amount",
      text: "Amount",
    },
    {
      dataField: "remarks",
      text: "Remarks",
      formatter: (cell, row) => {
        return returnCustomerAddressString(cell, null, 200, 30, "top");
      },
    },
    {
      dataField: "actionType",
      text: "Created By",
      formatter: (cell, row) => {
        return <div>{cell[0].user}</div>;
      },
    },
  ];
};
