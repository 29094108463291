import React from 'react';

const StatusCard = ({ status, bgColor, color, minWidth, height }) => {
  return (
    <div
      className="text-center"
      style={{
        backgroundColor: bgColor,
        border: 'none',
        minWidth: minWidth,
        height: height,
        color: color,
        padding: '10px',
        fontSize: '16px',
        fontWeight: '500',
        lineHeight: '18.75px',
      }}
    >
      <div>
        <span className="fw-bold">{status}</span>
      </div>
    </div>
  );
};

export default StatusCard;
