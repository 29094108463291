import { Card, Col, Row } from "@themesberg/react-bootstrap";
import React from "react";
import { useTranslation } from "react-i18next";

const AddedProductsCardList = (props) => {
    const { t } = useTranslation();
    const {
        products,
        title,
        height
    } = props;
    return (
        <>
            <h4>
                {t(title) || t("Products In Invoice")}
            </h4>
            {(products?.length > 0) ? (
                <div>{products?.map(prod => {
                    return (
                        <Card
                            border={"light"}
                            className="shadow-sm mb-4"
                        >
                            <Card.Body>
                                <Row className="d-block d-xl-flex align-items-center">
                                    <h5 className="mb-1">{prod?.product?.productName}</h5>
                                    <h6>{prod?.product?.productCode}</h6>
                                </Row>
                                <Row className="d-block d-xl-flex align-items-center">
                                    <Col className="px-xl-0">
                                        <small>
                                            {t("Product Category")}: {prod?.product?.productCategory},
                                            {t("VAN No")}: {prod?.product?.vanNo},
                                            {t("Company Name")}: {prod?.product?.companyName},
                                            {t("Quantity")}: {prod?.quantity},
                                            {prod?.hsnCode && `${t("HSN Code")} : ${prod?.hsnCode},`}
                                            {prod?.amount && `${t("Amount")} : ${prod?.amount},`}
                                            {prod?.gstRate && `${t("GST Rate")} : ${prod?.gstRate},`}
                                        </small>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    )
                })}

                </div>
            ) : (
                <div className="d-flex align-items-center justify-content-center" style={{
                    height: height || "80vh"
                }}>
                    <h5>{t("No Products added to invoice yet")}</h5>
                </div>
            )}
        </>
    )
}
export default AddedProductsCardList;