import React, { useState } from "react";
import OrderReturnPage from "./components/OrderReturnPage";
import { returnOrderStatus } from "../../../constants/orderStatus";
import { useSelector } from "react-redux";

const RefundInitiated = () => {
  const isAdmin = useSelector(
    (state) => state?.user?.preferences?.isAdmin || false
  );

  const bulkActionOptions = [
    {
      label: "Refund Completed",
      value: "refunded",
    },
  ];

  const defaultConfirmationProps = {
    refunded: [
      {
        type: "date",
        title: "Transaction Date",
        value: "",
      },
      {
        type: "text",
        title: "Amount",
        value: "",
      },
      {
        type: "text",
        title: "Transaction No.",
        value: "",
      },
      {
        type: "text",
        title: "Mode",
        value: "",
      },
      {
        type: "textArea",
        title: "Remarks",
        value: "",
      },
    ],
  };

  const [confirmationModalProps, setConfirmationModalProps] = useState([]);
  return (
    <>
      <OrderReturnPage
        type={returnOrderStatus.REFUND_INITIATED}
        hideBulkActions={!isAdmin}
        bulkActionOptions={isAdmin && bulkActionOptions}
        defaultConfirmationProps={defaultConfirmationProps}
        confirmationModalProps={confirmationModalProps}
        setConfirmationModalProps={setConfirmationModalProps}
      />
    </>
  );
};

export default RefundInitiated;
