import React from "react";
import { getTableRowCheckBox } from "../table"
import { Badge } from "@themesberg/react-bootstrap";
import { getTripBadgeStatus } from "../trips";

export const returnTeamLeadTableColumns = (onRowSelect) => {
    return ([
        {
            dataField: "objectId", text: "Select", formatter: (cell, row) => {
                return getTableRowCheckBox(onRowSelect, cell, row?.isSelected, false)
            }
        },
        {
            dataField: "inchargeName", sort: true, text: "Name", formatter: (cell, row) => {
                return (
                    <div className="d-block">
                        <span className="fw-bold">{cell}</span>
                        {/* <div className="fw-normal">{row?.vendorCode}</div> */}
                    </div>
                )
            }
        },
        {
            dataField: "storeName", text: "Store Name", formatter: (cell, row) => {
                return (
                    <div className="d-block">
                        <span className="fw-bold">{cell}</span>
                    </div>
                )
            }
        },
        {
            dataField: "category", text: "Categories", formatter: (cell, row) => {
                return (
                    <div className="d-block">
                        <span className="fw-bold">{cell?.join(", ")}</span>
                    </div>
                )
            }
        },{
            dataField: "phoneNumber", text: "Phone Number", formatter: (cell, row) => {
                return (
                    <div className="d-block">
                        <span className="fw-bold">{cell}</span>
                    </div>
                )
            }
        },
        {
            dataField: "status", text: "Status", formatter: (cell, row) => {
                return (
                    <div className="d-block">
                        <div className="fw-bold">
                            <span>&nbsp;&nbsp;<Badge bg={getTripBadgeStatus(cell || "active")} className="badge-lg">{cell?.toUpperCase() || "Active"}</Badge> &nbsp;&nbsp;</span>
                        </div>
                    </div>
                )
            }
        }
    ])
}

export const returnSelectedCategories = (selectedOptions, allCategories) => {
    const allItems = selectedOptions?.filter(option => option.value === "all");
    if(allItems?.length > 0) {
        return allCategories?.map(category => category.value);
    };
    return selectedOptions?.map(option => option.value);
}