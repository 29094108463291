import { RESET_STORE, SET_PAGE_NUMBER_IN_STORE } from '../actions/types';

const INITIAL_STATE = {};

const pageNumber = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_PAGE_NUMBER_IN_STORE: {
      const { id, page } = action.data;
      return {
        ...state,
        [id]: page,
      };
    }
    case RESET_STORE: {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
};

export default pageNumber;
