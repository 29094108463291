export const returnZoneDropdownOptions = (zonesElement, addAll = true) => {
  const zonesInfo = [];
  if (addAll) {
    zonesInfo.push({
      id: 'all',
      label: 'All',
    });
  }
  // eslint-disable-next-line no-unused-expressions
  Object.keys(zonesElement)?.forEach((zoneName) => {
    zonesInfo.push({
      id: zoneName,
      label: zoneName,
    });
  });
  return zonesInfo;
};
