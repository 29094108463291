import React, { useEffect, useState } from 'react';
import {
  NotificationBell,
  PopoverNotificationCenter,
  useSocket,
} from '@novu/notification-center';
import AchievementModal from './achievementModal';
import { useTranslation } from 'react-i18next';

const NotificationContent = ({ onNotificationClick }) => {
  const { t } = useTranslation();
  const { socket } = useSocket();
  const [showModal, setShowModal] = useState(false);
  const [notificationData, setNotificationData] = useState(null);

  const handleConfirm = () => {
    setShowModal(false);
    onNotificationClick(notificationData?.message);
  };

  const getHeaderText = (text) => {
    if (text) {
      const wordsArray = text?.split(' ');
      return wordsArray.includes('Auto')
        ? 'Auto Rejected'
        : wordsArray?.includes('Accepted')
        ? 'Accepted'
        : 'Rejected';
    }
  };

  useEffect(() => {
    if (socket) {
      socket.on('notification_received', (data) => {
        setShowModal(true);
        setNotificationData(data);
        // console.log(data);
      });
    }

    return () => {
      if (socket) {
        socket.off('notification_received');
      }
    };
  }, [socket]);

  return (
    <>
      <PopoverNotificationCenter
        position="bottom"
        onNotificationClick={onNotificationClick}
      >
        {({ unseenCount }) => <NotificationBell unseenCount={unseenCount} />}
      </PopoverNotificationCenter>
      <AchievementModal
        notification={true}
        show={showModal}
        handleClose={handleConfirm}
        handleCloseClick={() => setShowModal(false)}
        buttonText={t('Go to order')}
        topTitle={t('You Have New Notification!')}
        mainHeaderText={t(
          getHeaderText(notificationData?.message?.payload?.text) ||
            'Notification Header'
        )}
        subText={
          t(notificationData?.message?.payload?.text) || 'Notification subText'
        }
      />
    </>
  );
};

export default NotificationContent;
