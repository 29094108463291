export const combineMultipleReqsIntoOne = (requisitions) => {
  const reqsItems = {};
  // eslint-disable-next-line no-unused-expressions
  requisitions?.forEach((req) => {
    if (!reqsItems[req.requisitionNumber]) {
      reqsItems[req.requisitionNumber] = req;
    } else {
      reqsItems[req.requisitionNumber].products = [
        ...reqsItems[req.requisitionNumber].products,
        ...req.products,
      ];
    }
  });
  return reqsItems;
};

export const requisitionBulkActionOptions = [
  {
    label: 'Print',
    value: 'print',
  },
  {
    label: 'Transfer Done',
    value: 'completed',
  },
  {
    label: 'Stock NA',
    value: 'stockNA',
  },
  {
    label: 'Cancelled',
    value: 'cancelled',
  },
];

export const requisitionApprovalBulkActionOptions = [
  {
    label: 'Approved',
    value: 'pending',
  },
  {
    label: 'Rejected',
    value: 'rejected',
  },
];

export const RequisitionStatusMap = {
  print: 'Print Taken',
  completed: 'Transfer Done',
  stockNA: 'Stock NA',
  cancelled: 'Cancelled',
  review: 'HO Approval',
  approved: 'Approved',
  rejected: 'Rejected',
  pending: 'Pending',
  partialApproved: 'Partial Approved',
};
