import React, { useEffect, useState } from "react";
import SimpleBar from "simplebar-react";
import { CSSTransition } from "react-transition-group";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { Nav, Image, Button, Navbar } from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";

import { Routes as AppRoutes } from "../router/routes";
import ReactHero from "../assets/img/technologies/react-hero-logo.svg";
import { useTranslation } from "react-i18next";
import DMSSidebarOptions from "./sidebar/dms";
import PricingSidebarOptions from "./sidebar/PricingSidebarOptions";
import SchemeSidebarOptions from "./sidebar/SchemeSidebarOptions";
import WMSSidebarOptions from "./sidebar/wms";
import OMSSidebarOptitons from "./sidebar/oms";

export default (props = {}) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const showClass = show ? "show" : "";
  const [currentApplication, setCurrentApplication] = useState({
    label: "Delivery Management",
    value: "dms",
  });
  const onCollapse = () => setShow(!show);
  const applicationArray = useSelector(
    (state) => state?.user?.preferences?.applications || ["dms"]
  );

  useEffect(() => {
    getApplicationInfo();
  }, []);

  window.addEventListener("vsApplication", async () => {
    getApplicationInfo();
  });

  const getApplicationInfo = async () => {
    const application = await localStorage.getItem("vsApplication");
    if (application) {
      setCurrentApplication(JSON.parse(JSON.stringify(application)));
    }
  };
  const returnNavElements = (appName) => {
    const app = appName?.length ? JSON.parse(appName) : {};
    switch (app?.value || applicationArray[0]) {
      case "dms": {
        return <DMSSidebarOptions />;
      }
      case "pricing": {
        return <PricingSidebarOptions />;
      }
      case "wms": {
        return <WMSSidebarOptions />;
      }
      case "oms": {
        return <OMSSidebarOptitons />;
      }
      case "sms": {
        return <SchemeSidebarOptions />;
      }
      default: {
        return <DMSSidebarOptions />;
      }
    }
  };

  return (
    <>
      <Navbar
        expand={false}
        collapseOnSelect
        variant="dark"
        className="navbar-theme-primary px-4 d-md-none"
      >
        <Navbar.Brand
          className="me-lg-5"
          as={Link}
          to={AppRoutes.HomePage.path}
        >
          <Image src={ReactHero} className="navbar-brand-light" />
        </Navbar.Brand>
        <Navbar.Toggle
          as={Button}
          aria-controls="main-navbar"
          onClick={onCollapse}
        >
          <span className="navbar-toggler-icon" />
        </Navbar.Toggle>
      </Navbar>
      <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
        <SimpleBar
          className={`collapse ${showClass} sidebar d-md-block bg-primary text-white`}
        >
          <div className="sidebar-inner px-4 pt-3">
            <div className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
              <div className="d-flex align-items-center">
                <div className="d-block">
                  <h6>{t("Hi")}</h6>
                  {/* <Button as={Link} variant="secondary" size="xs" to={Routes.Signin.path} className="text-dark">
                    <FontAwesomeIcon icon={faSignOutAlt} className="me-2" />{t("Sign Out")}
                  </Button> */}
                </div>
              </div>
              <Nav.Link
                className="collapse-close d-md-none"
                onClick={onCollapse}
              >
                <FontAwesomeIcon icon={faTimes} />
              </Nav.Link>
            </div>
            {/** Main Application Paths*/}
            <Nav className="flex-column pt-3 pt-md-0">
              {returnNavElements(currentApplication)}
            </Nav>
          </div>
        </SimpleBar>
      </CSSTransition>
    </>
  );
};
