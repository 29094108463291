import { faHome, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Breadcrumb, Button, Col, Form, Row } from "@themesberg/react-bootstrap";
import Joi from "joi";
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { updateToastInfo } from "../../../actions/settings";
import { updateSpinnerState } from "../../../actions/spinner";
import { returnValidationErrorMessages } from "../../../utils/validation";
import EditableDropDown from "../../../components/common/EditableDropdown";
import { removeUnusedWarehousesFromList, returnWarehouseDropdownOptions } from "../../../utils/warehouse";
import { setCurrentWarehouseInfoInStore } from "../../../actions/user";
import { getCurrentWarehouseInfo } from "../../../parse-functions/warehouse";
import { returnWarehouseCategoryOptions } from "../../../utils/wms/categories";
import { addNewTeamLead } from "../../../parse-functions/wms/teamlead";
import { returnSelectedCategories } from "../../../utils/wms/teamlead";

const addTeamLeadSchema = Joi.object({
    inchargeName: Joi.string().required(),
    phoneNumber: Joi.number().required().greater(1000000000).less(10000000000),
    category: Joi.array().min(1).required().items(Joi.string()),
    username: Joi.string().required(),
    password: Joi.string().required(),
    repeatPassword: Joi.ref('password'),
    storeName: Joi.string().required(),
    zone: Joi.string().allow('')
});

const editTeamLeadSchema = Joi.object({
    inchargeName: Joi.string(),
    phoneNumber: Joi.number().greater(1000000000).less(10000000000),
    category: Joi.array().min(1).required().items(Joi.string()),
    storeName: Joi.string().required(),
    zone: Joi.string().allow('')
}).options({
    allowUnknown: true
});

const TeamLeadAdd = (props) => {
    
    const { t } = useTranslation();
    const warehouseZone = useSelector((state) => state.user.warehouse.zone || '');
    const dispatch = useDispatch();
    const configData = useSelector((state) => state.user.config || {});
    const [formErrors, setFormErrors] = useState({});
    const isHO = useSelector((state) => state?.user?.preferences?.isHO || false);
    const otherWarehouses = useSelector((state) => state?.user?.otherWarehousesInZone || {});
    const preferences = useSelector((state) => state?.user?.preferences || {});
    const warehouseCategories = useSelector((state) => state?.user?.config?.warehouseCategories || []);
    const isSuperAdmin = useSelector((state) => state?.user?.preferences?.isSuperAdmin || false);
    const state =useSelector((state) => state);
    const emptyUser = {
        inchargeName: '',
        phoneNumber: '',
        category: "",
        username: '',
        password: '',
        repeatPassword: '',
        storeName: preferences?.storeName,
        zone: warehouseZone
    }
    const {
        edit = false,
        user = emptyUser,
        onConfirm
    } = props;
    const [formData, setFormData] = useState(user);
    useEffect(() => {
        async function getWareHouseData() {
            const warehouseInfo = await getCurrentWarehouseInfo({
                warehouseName: preferences?.storeName,
                isSuperAdmin: true
            });
            const finalWarehouseList = removeUnusedWarehousesFromList(warehouseInfo.otherWarehousesInZone);
            warehouseInfo.otherWarehousesInZone = finalWarehouseList;
            dispatch(setCurrentWarehouseInfoInStore(warehouseInfo));
        }
        if (!otherWarehouses || Object.keys(otherWarehouses)?.length < 1) {
            getWareHouseData();
        }
    }, []);
    const onFormInputChange = (fieldName, value) => {
        setFormData(oldFormData => ({
            ...oldFormData,
            [fieldName]: value
        }));
    };
    const handleFormSubmit = async () => {
        const validationSchemaName = edit ? editTeamLeadSchema : addTeamLeadSchema;
        const formDataToValidate = formData;
        if (!formDataToValidate.storeName) {
            formDataToValidate.storeName = preferences?.storeName;
        }
        const { error: formErrors = {}, value: formValue } = validationSchemaName.validate(formDataToValidate, {
            abortEarly: false
        });
        const {
            details = {}
        } = formErrors;
        if (details?.length > 0) {
            const validationErrorMessages = returnValidationErrorMessages(details);
            setFormErrors(validationErrorMessages);
        } else {
            if (edit) {
                onConfirm(formValue);
            } else {
                dispatch(updateSpinnerState(true));
                try {
                    await addNewTeamLead(formData);
                    dispatch(updateToastInfo({
                        show: true,
                        type: "success",
                        title: t("Incharge Added"),
                        message: t(`${formData.inchargeName} added successfully`)
                    }))
                } catch (error) {
                    // inside error
                    const {
                        message,
                        code
                    } = error;
                    setFormErrors(message);
                    dispatch(updateToastInfo({
                        show: true,
                        type: "danger",
                        title: t("Team Lead Add Failed"),
                        message: t(`${formData.inchargeName} failed to add`)
                    }))
                }
                dispatch(updateSpinnerState(false));
            }
        }
    };
    return (
        <div className="mt-1">
            {(!edit) && (
                <div className="mb-4 d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-2">
                    <div className="d-block mb-4 mb-md-0">
                        <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
                            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
                            <Breadcrumb.Item>{t("WMS")}</Breadcrumb.Item>
                            <Breadcrumb.Item>{t("TeamLead")}</Breadcrumb.Item>
                            <Breadcrumb.Item active>{t("Add")}</Breadcrumb.Item>
                        </Breadcrumb>
                        <h4>{t("Add New Teamlead")}</h4>
                        <p className="mb-0">{t("Use this page to add a new Teamlead")}</p>
                    </div>
                </div>
            )}

            <Row className="mt-3">
                <Col>
                    <Alert variant="info">
                        <FontAwesomeIcon icon={faHome} />&nbsp;&nbsp;{t("Team Lead Info")}
                    </Alert>
                    <Form.Group className="mb-3 mt-4">
                        <Form.Label>{t("TeamLead Name")}</Form.Label>
                        <Form.Control
                            required
                            value={formData.inchargeName}
                            isInvalid={formErrors["inchargeName"]?.length}
                            type="text"
                            onChange={(event) => {
                                onFormInputChange("inchargeName", event.target.value);
                            }} />
                        {formErrors["inchargeName"]?.length && (
                            <Form.Control.Feedback type="invalid">{t("Please enter TeamLead name.")}</Form.Control.Feedback>
                        )}

                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control
                            required
                            value={formData.phoneNumber}
                            isInvalid={formErrors["phoneNumber"]?.length}
                            type="number"
                            onChange={(event) => {
                                onFormInputChange("phoneNumber", event.target.value);
                            }} />
                        {formErrors["phoneNumber"]?.length && (
                            <Form.Control.Feedback type="invalid">{t("Please enter phonenumber.")}</Form.Control.Feedback>
                        )}

                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>{t("Category")}</Form.Label>
                        <EditableDropDown
                            isMultiSelect
                            value={formData.category}
                            onChange={(option) => {
                                const selectedCategories = returnSelectedCategories(option, warehouseCategories);
                                setFormData(oldData => ({
                                    ...oldData,
                                    category: selectedCategories,
                                }))
                            }}
                            options={returnWarehouseCategoryOptions(warehouseCategories, true)}
                            placeholder={t("Select Category")}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>{t("Warehouse / Store Name")}</Form.Label>
                        <EditableDropDown
                            value={preferences?.storeName || formData.storeName}
                            disabled={!isSuperAdmin}
                            onChange={(option) => {
                                const matchingWarehouse = Object.values(otherWarehouses)?.filter(warehouse => warehouse?.warehouseCode === option.value);
                                setFormData(oldData => ({
                                    ...oldData,
                                    storeName: option.value,
                                    zone: matchingWarehouse[0].zone
                                }))
                            }}
                            options={returnWarehouseDropdownOptions(otherWarehouses)}
                            placeholder={t("Select Warehouse / Branch Name")}
                        />
                    </Form.Group>
                    {(!edit) && (
                        <Form.Group className="mb-3">
                            <Form.Label>{t("Zone")}</Form.Label>
                            <Form.Select disabled>
                                {<option>{warehouseZone || formData.zone}</option>}
                            </Form.Select>
                            {formErrors["zone"]?.length && (
                                <Form.Control.Feedback type="invalid">{t("Please choose a zone")}</Form.Control.Feedback>
                            )}
                        </Form.Group>
                    )}
                    {(edit) && (
                        <div className="d-flex justify-content-between">
                            <Button
                                variant="primary"
                                onClick={handleFormSubmit}
                            >{t("Update User")}</Button>
                            <Button className="mt-3" variant="outline" >{t("Cancel")}</Button>
                        </div>
                    )}
                </Col>
                {(!edit) && (
                    <Col>
                        <Alert variant="info">
                            <FontAwesomeIcon icon={faUserCircle} />&nbsp;&nbsp;{t("Login Info")}
                        </Alert>
                        <Form.Group className="mb-3 mt-4">
                            <Form.Label>{t("UserName")}</Form.Label>
                            <Form.Control required isInvalid={formErrors["username"]?.length} type="text" onChange={(event) => {
                                onFormInputChange("username", event.target.value);
                            }} />
                            {formErrors["username"]?.length && (
                                <Form.Control.Feedback type="invalid">{t("Please enter a username")}</Form.Control.Feedback>
                            )}

                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>{t("Password")}</Form.Label>
                            <Form.Control required isInvalid={formErrors["password"]?.length} type="text" onChange={(event) => {
                                onFormInputChange("password", event.target.value);
                            }} />
                            {formErrors["password"]?.length && (
                                <Form.Control.Feedback type="invalid">{t("Please enter a password")}</Form.Control.Feedback>
                            )}

                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>{t("Repeat Password")}</Form.Label>
                            <Form.Control required isInvalid={formErrors["repeatPassword"]?.length} type="text" onChange={(event) => {
                                onFormInputChange("repeatPassword", event.target.value);
                            }} />
                            {formErrors["repeatPassword"]?.length && (
                                <Form.Control.Feedback type="invalid">{t("Please enter the same password as above")}</Form.Control.Feedback>
                            )}

                        </Form.Group>
                        <Row className="d-flex justify-content-between mt-5">
                            <Button
                                style={{ width: '98%' }}
                                className="mx-2"
                                variant="primary"
                                onClick={handleFormSubmit}
                            >{t("Create User")}</Button>
                            <Button className="mt-3" variant="outline" >{t("Cancel")}</Button>
                        </Row>
                    </Col>
                )}

            </Row>

        </div>
    )
}
export default TeamLeadAdd