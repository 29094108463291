import React from 'react';
import { useTranslation } from 'react-i18next';
import { generateRandomId } from '../../utils/string';
import { Button, Modal } from '@themesberg/react-bootstrap';
import AddIncharge from './addIncharge';
import AddSecurityGuard from '../securityGuard/add';

const InchargeUpdateModal = (props) => {
  const { t } = useTranslation();
  const {
    showModal,
    closeModal,
    onConfirm,
    isSecurityGuard = false,
    user,
  } = props;
  return (
    <Modal
      id={generateRandomId()}
      size="lg"
      show={showModal}
      onHide={closeModal}
    >
      <Modal.Header>
        <Modal.Title className="h6">
          {t(`Update ${user?.name || 'user details'}`)}
        </Modal.Title>
        <Button variant="close" aria-label="Close" onClick={closeModal} />
      </Modal.Header>
      <Modal.Body>
        {isSecurityGuard ? (
          <AddSecurityGuard
            editData={{ ...user, storeName: [user?.storeName] }}
            onConfirm={onConfirm}
          />
        ) : (
          <AddIncharge edit user={user} onConfirm={onConfirm} />
        )}
      </Modal.Body>
    </Modal>
  );
};
export default InchargeUpdateModal;
