import {
  faBan,
  faBox,
  faEye,
  faFileInvoice,
  faFileInvoiceDollar,
  faReceipt,
  faShop,
  faTruckFast,
} from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import ActionButtonWithIcons from '../../../components/common/ActionButtonWithIcons';
import StatusCard from '../interBranchTransfers/StatusCard';
import { returnProductInfo } from '../../../utils/sms/tableUtils';
import { orderStatus } from '../../../constants/orderStatus';

export const endlessOptions = {
  view: 'View',
  poRaised: 'PO Raised',
  brandInvoiceReceived: 'Brand Invoice Received',
  customerInvoiceRaised: 'Customer Invoice Raised',
  assignStore: 'Assign Store',
  deliveryDetails: 'Delivery Details',
  delivered: 'Delivered',
};

export const endlessStatus = {
  pending: 'Pending',
  poRaised: 'PO Raised',
  brandInvoiceReceived: 'Brand Invoiced',
  customerInvoiceRaised: 'Customer Invoice Raised',
  // delivered: 'Delivered',
};

const updateOptions = {
  [endlessStatus.poRaised]: [
    { icon: faEye, label: endlessOptions.view },
    {
      icon: faFileInvoice,
      label: endlessOptions.brandInvoiceReceived,
    },
  ],
  [endlessStatus.brandInvoiceReceived]: [
    { icon: faEye, label: endlessOptions.view },
    {
      icon: faFileInvoiceDollar,
      label: endlessOptions.customerInvoiceRaised,
    },
    { icon: faShop, label: endlessOptions.assignStore },
  ],
  [endlessStatus.customerInvoiceRaised]: [
    { icon: faEye, label: endlessOptions.view },
    { icon: faTruckFast, label: endlessOptions.deliveryDetails },
  ],
  [orderStatus.pickedUp]: [
    { icon: faEye, label: endlessOptions.view },
    { icon: faBox, label: endlessOptions.delivered },
  ],
};

export const getStatusColors = (status) => {
  const result = {
    bgColor: '',
    color: '',
  };

  switch (status) {
    case endlessStatus.poRaised:
      result.bgColor = 'rgba(242, 230, 255, 1)';
      result.color = 'rgba(102, 0, 196, 1)';
      break;

    case endlessStatus.brandInvoiceReceived:
      result.bgColor = 'rgba(236, 249, 253, 1)';
      result.color = 'rgba(9, 72, 179, 1)';
      break;

    case endlessStatus.customerInvoiceRaised:
      result.bgColor = 'rgba(226, 247, 255, 1)';
      result.color = 'rgba(0, 163, 224, 1)';
      break;

    case endlessStatus.pending:
      result.bgColor = '#FDEDD9';
      result.color = '#F4921B';
      break;
    case orderStatus.delivered:
      result.bgColor = 'rgba(212, 247, 232, 1)';
      result.color = 'rgba(33, 168, 113, 1)';
      break;
    default:
      result.bgColor = 'rgba(242, 230, 255, 1)';
      result.color = 'rgba(102, 0, 196, 1)';
      break;
  }

  return result;
};

export const endlessAisleProcessingColumns = (onActionClick, type, isAdmin) => {
  const columns = [
    {
      dataField: 'vanNo',
      text: 'vanNo',
      headerClasses: 'rounded-0 p-3 text-center',
      formatter: (cell, row) => {
        return <div className="d-block text-center">{cell}</div>;
      },
    },
    {
      dataField: 'productTitle',
      text: 'product',
      headerClasses: 'rounded-0 p-3 text-center',
      formatter: (cell, row) => {
        return returnProductInfo([cell]);
      },
    },
    {
      dataField: 'orderNo',
      text: 'order No',
      sort: true,
    },
    {
      dataField: 'customerName',
      text: 'Customer Name',
      sort: true,
    },
    {
      dataField: 'quantity',
      text: 'Quantity',
    },
    {
      dataField: 'brand',
      text: 'company',
      headerClasses: 'rounded-0 p-3 text-center',
      formatter: (cell, row) => {
        return <div className="d-block">{cell}</div>;
      },
    },
    {
      dataField: 'status',
      text: 'status',
      headerClasses: 'rounded-0 p-3 text-center',
      formatter: (cell, row) => {
        return (
          <div className="d-flex">
            {cell ? (
              <StatusCard
                status={cell}
                bgColor={getStatusColors(cell).bgColor}
                color={getStatusColors(cell).color}
                minWidth={'7em'}
                height={'2.4rem'}
              />
            ) : (
              <div>{'-'}</div>
            )}
          </div>
        );
      },
    },
    {
      dataField: 'Action',
      text: 'action',
      headerClasses: 'rounded-0 p-3 text-center',
      style: { textAlign: 'center' },
      formatter: (cell, row) => {
        let baseOptions =
          type === 'Pending'
            ? [
                { icon: faEye, label: endlessOptions.view },
                { icon: faReceipt, label: endlessOptions.poRaised },
              ]
            : updateOptions.hasOwnProperty(row?.status)
            ? updateOptions[row?.status]
            : [{ icon: faEye, label: endlessOptions.view }];

        const options =
          isAdmin && row?.status !== orderStatus.delivered
            ? [
                ...baseOptions,
                {
                  icon: faBan,
                  label: orderStatus.cancelled,
                },
              ]
            : baseOptions;
        return (
          <>
            <ActionButtonWithIcons
              options={options}
              onActionClick={(option) => onActionClick(option, row?.objectId)}
            />
          </>
        );
      },
    },
  ];
  return columns;
};
