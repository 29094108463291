import { faCaretDown, faCartPlus, faSearch, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Dropdown, Form, Row } from "@themesberg/react-bootstrap";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setCompanyAndCategoryDataInStore, setMatchingProductsInStore } from "../../../actions/products";
import { updateToastInfo } from "../../../actions/settings";
import { updateSpinnerState } from "../../../actions/spinner";
import { getAllCompaniesAndCategoriesData, getProductDataByVANNo, getProductsBasedOnCategoryAndCompany } from "../../../parse-functions/products";
import { invoiceFieldTypeMap, invoiceTypeFieldTypes, InvoiceTypes, numberFields, returnFormFieldsSchema } from "../../../utils/addInvoiceManual";
import { returnCategoriesDropdownList, returnCompaniesDropdownList, returnProductsDropdownList } from "../../../utils/products";
import { returnValidationErrorMessages } from "../../../utils/validation";
import DropdownComponent from "../Dropdown";

const AddProductItemToInvoice = (props) => {
    const {
        onProductAdd
    } = props;
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [searchBy, setSearchBy] = useState("van");
    const [searchByText, setSearchByText] = useState("Search by VAN No.");
    const [formFields, setFormFields] = useState([[], []]);
    const [formData, setFormData] = useState({});
    const [combiProductFormData, setCombiProductFormData] = useState({});
    const [invoiceType, setInvoiceType] = useState();
    const [formErrors, setFormErrors] = useState({});
    const [combiProductFormErrors, setCombiProductFormErrors] = useState({});
    const companies = useSelector((state) => state?.products?.companies || {});
    const categories = useSelector((state) => state?.products?.categories || {});
    const productsList = useSelector((state) => state.products?.productsList || {});
    const [vanNo, setVanNo] = useState(null);
    const [extraProduct, setExtraProduct] = useState({});

    const isSearchByVAN = searchBy === "van";

    useEffect(() => {
        getAllCompaniesAndCategories();
    }, []);

    const fetchProductDetailsByVAN = async () => {
        dispatch(updateSpinnerState(true));
        try {
            const data = await getProductDataByVANNo({ vanNo });
            const {
                product,
                extra
            } = data
            if (extra && Object.keys(extra)?.length > 0) {
                setExtraProduct(extra);
            }
            setFormData(oldData => ({
                ...oldData,
                product
            }))
            if (Object.keys(product)?.length > 0) {
                dispatch(updateToastInfo({
                    show: true,
                    type: 'success',
                    title: t('Product Info fetched'),
                    message: t("Product data has been fetched and added to the invoice")
                }));
            } else {
                dispatch(updateToastInfo({
                    show: true,
                    type: 'danger',
                    title: t('Failed getting product info'),
                    message: t("Failed to get the product info using VAN number. Please check the value entered or contact support.")
                }));
            }
            dispatch(updateSpinnerState(false));
        } catch (e) {
            dispatch(updateSpinnerState(false));
            dispatch(updateToastInfo({
                show: true,
                type: 'danger',
                title: t('Failed Getting Company & Category data'),
                message: t("Please refresh the page to try getting this data again or contact support")
            }));
        }
    };
    const getAllCompaniesAndCategories = async () => {
        if (
            Object.keys(companies).length > 0 &&
            Object.keys(categories).length > 0
          ) {
            return;
          }
        dispatch(updateSpinnerState(true));
        try {
            const data = await getAllCompaniesAndCategoriesData();
            dispatch(setCompanyAndCategoryDataInStore(data));
            dispatch(updateSpinnerState(false));
        } catch (e) {
            dispatch(updateSpinnerState(false));
            dispatch(updateToastInfo({
                show: true,
                type: 'danger',
                title: t('Failed Getting Company & Category data'),
                message: t("Please refresh the page to try getting this data again or contact support")
            }));
        }

    };
    const onFormInputChange = (fieldName, value) => {
        setFormData(oldFormData => ({
            ...oldFormData,
            [fieldName]: value
        }));
    };

    const onCombiProductFormInputChange = (fieldName, value) => {
        setCombiProductFormData(oldFormData => ({
            ...oldFormData,
            [fieldName]: value
        }));
    };

    const handleAddProductToInvoice = async () => {
        const formFieldsSchema = returnFormFieldsSchema(formFields);
        const { error: formErrors = {} } = formFieldsSchema.validate(formData, {
            abortEarly: false
        });
        const {
            details = {}
        } = formErrors;
        let combiFormErrors = {};
        if (extraProduct && Object.keys(extraProduct)?.length > 0) {
            const combiFormSchema = returnFormFieldsSchema([formFields[0]]);
            const { error = {} } = combiFormSchema.validate({ ...combiProductFormData, product: extraProduct}, {
                abortEarly: false
            });
            combiFormErrors = error.details;
        }
        if (details?.length > 0 || combiFormErrors?.length > 0) {
            if (details?.length > 0) {
                const validationErrorMessages = returnValidationErrorMessages(details);
                setFormErrors(validationErrorMessages);
            }
            if (combiFormErrors?.length > 0) {
                const combiValidationErrorMessages = returnValidationErrorMessages(combiFormErrors);
                setCombiProductFormErrors(combiValidationErrorMessages);
            }

        } else {
            dispatch(updateSpinnerState(false));
            try {
                const finalAmount = Number(Number(formData.amount) * (1 + Number(formData.gstRate)/ 100)).toFixed();
                const rate = Number(Number(finalAmount) / Number(formData.quantity)).toFixed(2);
                const gstAmount = Number((Number(formData.gstRate) * Number(formData.amount)) / 100).toFixed(2);

                // modify the fields for the rate calculation etc here
                const updatedFormData = {
                    ...formData,
                    rate,
                    gstAmount,
                    gstProductCategory: `GST ${formData.gstRate}%`,
                    CGST: Number(formData.cgst),
                    SGST: Number(formData.cgst),
                    gstAssessableValue: formData.amount,
                    amount: finalAmount
                }
                onProductAdd(updatedFormData);
                if (extraProduct && Object.keys(extraProduct)?.length > 0) {
                    onProductAdd({ ...combiProductFormData, product: extraProduct, gstin: updatedFormData.gstin })
                }
                dispatch(updateToastInfo({
                    show: true,
                    type: "success",
                    title: t("Product Added to Invoice Successfully"),
                    message: t(`${updatedFormData?.product?.productCode} added successfully`)
                }))
                setFormErrors({});
                setFormData({});
                setExtraProduct(null);
                setVanNo(null);
                setInvoiceType(null);
            } catch (error) {
                // inside error
                const {
                    message,
                    code
                } = error;
                setFormErrors(message);
                dispatch(updateToastInfo({
                    show: true,
                    type: "danger",
                    title: t("Adding Product Info Failed"),
                    message: t("Please correct the errors in the form and resubmit")
                }))
            }
            dispatch(updateSpinnerState(false));
        }
    };

    const getProductListFromCategory = async (companyName, productCategory) => {
        try {
            dispatch(updateSpinnerState(true));
            const products = await getProductsBasedOnCategoryAndCompany({ companyName, productCategory });
            dispatch(setMatchingProductsInStore(products));
        } catch (e) {
            dispatch(updateToastInfo({
                show: true,
                type: 'danger',
                title: t("Fetching Products Failed"),
                message: t("Please refetch by changing category. If still problem persists, Please contact support")
            }))
            dispatch(updateSpinnerState(false));
        }

    }
    const handleRemoveCombiProduct = () => {
        setExtraProduct(null);
    }
    return (
        <>
            <h4>{t("Product Details")}</h4>
            <Row className="mt-4">
                <Col>
                    <Dropdown>
                        <Dropdown.Toggle>
                            <div className="media d-flex align-items-center">
                                <div className="align-items-center">
                                    <span className="mb-0 fw-bold">
                                        <FontAwesomeIcon icon={faSearch} size="lg" />&nbsp;&nbsp;{t(searchByText)} &nbsp;<FontAwesomeIcon icon={faCaretDown} />
                                    </span>
                                </div>
                            </div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu-right mt-2">
                            <Dropdown.Item
                                onClick={() => {
                                    setSearchBy("van");
                                    setSearchByText("Search by VAN No.");
                                }}
                                className="fw-bold">
                                {t("Search By VAN No.")}
                            </Dropdown.Item>
                            <Dropdown.Item
                                onClick={() => {
                                    setSearchBy("company");
                                    setSearchByText("Search by Company");
                                }}
                                className="fw-bold">
                                {t("Search by Company")}
                            </Dropdown.Item>

                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
                <Col>
                    {(searchBy === "van") && (
                        <Row>
                            <Col sm={7}>
                                <Form.Group>
                                    <Form.Control
                                        placeholder="Enter VAN Number"
                                        required
                                        type="number"
                                        onKeyDown={(event) => {
                                            if (event.nativeEvent.code === "Enter") {
                                                fetchProductDetailsByVAN();
                                            }
                                        }}
                                        onChange={(event) => {
                                            setVanNo(event.target.value);
                                        }} />
                                </Form.Group>
                            </Col>
                            <Col sm={5} className="d-block align-self-end mb-2">
                                <Button
                                    type="button"
                                    onClick={fetchProductDetailsByVAN}
                                    variant="primary">
                                    <FontAwesomeIcon icon={faSearch} /> &nbsp;&nbsp; Lookup
                                </Button>
                            </Col>
                        </Row>
                    )}
                </Col>
            </Row>
            <Row
                className="mt-2 mb-2"
            >
                <Col>
                    <DropdownComponent
                        disabled={isSearchByVAN}
                        onChange={(option) => {
                            setFormData(oldData => ({
                                ...oldData,
                                companyName: option.value
                            }))
                        }}
                        options={returnCompaniesDropdownList(companies)}
                        placeholder={formData?.product ? formData?.product?.productName : t("Select Company Name")}
                    />
                </Col>
                <Col>
                    <DropdownComponent
                        disabled={!formData.companyName || isSearchByVAN}
                        className="mt-2 mb-2"
                        onChange={(option) => {
                            setFormData(oldData => ({
                                ...oldData,
                                productCategory: option.value
                            }))
                            getProductListFromCategory(formData.companyName, option.value);
                        }}
                        options={returnCategoriesDropdownList(categories)}
                        placeholder={formData?.product ? formData?.product?.productCategory : t("Select Product Category")}
                    />
                </Col>

            </Row>

            <Row className="mt-2 mb-2">
                <Col>
                    <DropdownComponent
                        disabled={!formData.companyName || !formData.productCategory || isSearchByVAN}
                        className="mt-2 mb-2"
                        onChange={(option) => {
                            setFormData(oldData => ({
                                ...oldData,
                                product: option.value
                            }))
                        }}
                        options={returnProductsDropdownList(productsList)}
                        placeholder={formData?.product ? formData?.product?.productName : t("Select Product Name")}
                    />
                </Col>
                <Col>
                    <DropdownComponent
                        placeholder={formData?.product?.productName || "Select Product"}
                        disabled
                        className="mt-2 mb-2"
                        onChange={(option) => {
                        }}
                        options={[]}
                    />
                </Col>
            </Row>
            <Row className="mt-2">
                <Form.Label>{t("Delivery Type")}</Form.Label>
                <DropdownComponent
                    className="mt-2 mb-2"
                    onChange={(option) => {
                        setInvoiceType(option.value);
                        setFormFields(invoiceTypeFieldTypes[option.value])
                    }}
                    options={InvoiceTypes}
                    placeholder={t("Select Invoice category")}
                />
                {extraProduct && Object.keys(extraProduct)?.length > 0 && formFields[0]?.length > 0 && (
                    <>
                        <Form.Group className="mb-2 mt-2">
                            <Row>
                                <Col>
                                    <Form.Label className="fw-bold">{t("Combi Product")}</Form.Label></Col>
                                <Col className="d-flex justify-content-end">
                                    <Button
                                        variant="outline-primary"
                                        size="sm"
                                        onClick={handleRemoveCombiProduct}>
                                            <FontAwesomeIcon icon={faTrash} />&nbsp;&nbsp;Remove

                                    </Button>
                                </Col>
                            </Row>
                        </Form.Group>
                        <Row>
                            <Col>{formFields[0].map((fieldName) => {
                                return (
                                    <Form.Group className="mb-2 mt-2">
                                        <Form.Label>{t(invoiceFieldTypeMap[fieldName])}</Form.Label>
                                        <Form.Control
                                            value={combiProductFormData[fieldName]}
                                            required
                                            isInvalid={combiProductFormErrors[fieldName]?.length}
                                            type={numberFields.indexOf(fieldName) > -1 || fieldName === "gstRate" ? "number" : "text"}
                                            onChange={(event) => {
                                                onCombiProductFormInputChange(fieldName, event.target.value);
                                                if (fieldName === "gstRate") {
                                                    const totalGSTValue = Number((Number(combiProductFormData["amount"] || 0) * Number(event.target.value)) / 200).toFixed(2)
                                                    onCombiProductFormInputChange("cgst", totalGSTValue);
                                                    onCombiProductFormInputChange("sgst", totalGSTValue);
                                                }
                                            }} />
                                        {combiProductFormErrors[fieldName]?.length && (
                                            <Form.Control.Feedback type="invalid">{t(combiProductFormErrors[fieldName])}</Form.Control.Feedback>
                                        )}
                                    </Form.Group>)
                            })}
                            </Col>
                        </Row>
                    </>
                )}
                {(invoiceType) && (
                    <Row className="mt-3">
                        <Form.Label className="fw-bold">{t("Original Product")}</Form.Label>
                        <Col>{formFields[0].map((fieldName) => {
                            return (
                                <Form.Group className="mb-2 mt-2">
                                    <Form.Label>{t(invoiceFieldTypeMap[fieldName])}</Form.Label>
                                    <Form.Control
                                        value={formData[fieldName]}
                                        required
                                        isInvalid={formErrors[fieldName]?.length}
                                        type={numberFields.indexOf(fieldName) > -1 || fieldName === "gstRate" ? "number" : "text"}
                                        onChange={(event) => {
                                            onFormInputChange(fieldName, event.target.value);
                                            if (fieldName === "gstRate") {
                                                const totalGSTValue = Number((Number(formData["amount"]) * Number(event.target.value)) / 200).toFixed(2)
                                                onFormInputChange("cgst", totalGSTValue);
                                                onFormInputChange("sgst", totalGSTValue);
                                            }
                                        }} />
                                    {formErrors[fieldName]?.length && (
                                        <Form.Control.Feedback type="invalid">{t(formErrors[fieldName])}</Form.Control.Feedback>
                                    )}
                                </Form.Group>)
                        })}
                        </Col>
                        <Col>
                            {formFields[1].map((fieldName) => {
                                return (
                                    <Form.Group className="mb-2 mt-2">
                                        <Form.Label>{t(invoiceFieldTypeMap[fieldName])}</Form.Label>
                                        <Form.Control
                                            value={formData[fieldName]}
                                            disabled={fieldName === "cgst" || fieldName === "sgst"}
                                            required
                                            isInvalid={formErrors[fieldName]?.length}
                                            type={numberFields.indexOf(fieldName) > -1 || fieldName === "gstRate" ? "number" : "text"}
                                            onChange={(event) => {
                                                onFormInputChange(fieldName, event.target.value);
                                                // if this is GSTRate, Do the calculation on amount and set in the form
                                                if (fieldName === "gstRate") {
                                                    const totalGSTValue = Number((Number(formData["amount"]) * Number(event.target.value)) / 200).toFixed(2)
                                                    onFormInputChange("cgst", totalGSTValue);
                                                    onFormInputChange("sgst", totalGSTValue);
                                                }
                                            }} />
                                        {formErrors[fieldName]?.length && (
                                            <Form.Control.Feedback type="invalid">{t(formErrors[fieldName])}</Form.Control.Feedback>
                                        )}
                                    </Form.Group>
                                )
                            })}
                        </Col>
                    </Row>
                )}
                {(formErrors["product"]) && (
                    <div style={{
                        color: "red",
                        justifyContent: "center",
                        display: "flex"
                    }}>Please select a product from the options above</div>
                )}
                {(invoiceType) && (
                    <div className="mt-3 d-block w-100">
                        <Button className="w-100" type="button" onClick={handleAddProductToInvoice} variant="primary">
                            <FontAwesomeIcon icon={faCartPlus} />&nbsp;&nbsp;{t("Add product")}
                        </Button>
                    </div>
                )}
            </Row>
        </>
    )
}
export default AddProductItemToInvoice;