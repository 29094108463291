import React, { useMemo, useState } from 'react';
import BreadCrumbComponent from '../../components/common/Breadcrumb';
import { useTranslation } from 'react-i18next';
import arrayToBreadCrumbs from '../../utils/sms/arrayToBreadCrumbs';
import { Form, Row, Col, Button } from '@themesberg/react-bootstrap';
import AsyncDropdown from '../../components/AsyncDropdown';
import { getAllTripNumbers } from '../../parse-functions/trips';
import { arrayToDropdownOptions } from '../../utils/sms/arrayToDropdownOptions';
import { updateToastInfo } from '../../actions/settings';
import { useDispatch, useSelector } from 'react-redux';
import EditableDropDown from '../../components/common/EditableDropdown';
import {
  faCheck,
  faPlus,
  faRefresh,
  faUndo,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { updateSpinnerState } from '../../actions/spinner';
import { createPaymentVoucher } from '../../parse-functions/payments';
import { debounce } from '../../utils/debounce';

const CreatePaymentVoucher = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const warehouse = useSelector((state) => state?.user?.warehouse || {});
  const [eachLine, setEachLine] = useState([]);
  const [errors, setErrors] = useState({});
  const [total, SetTotal] = useState(0);
  const [isDisabled, setIsDisabled] = useState(false);
  const [showCreate, setShowCreate] = useState(false);
  const breadCrumbItems = arrayToBreadCrumbs([['payments'], ['add']]);

  const selectedTemposheets = useMemo(() => {
    const selected = new Set();
    if (formData.tripNumber) selected.add(formData.tripNumber);
    eachLine.forEach((line) => {
      if (line.data.tripNumber) selected.add(line.data.tripNumber);
    });
    return selected;
  }, [formData.tripNumber, eachLine]);

  const fetchOptions = debounce(async (inputValue) => {
    if (inputValue.length <= 1) return [];
    try {
      const res = await getAllTripNumbers({
        filter: inputValue.toUpperCase(),
      });
      const filtered = res.filter((option) => !selectedTemposheets.has(option));
      const options = arrayToDropdownOptions(filtered);
      return options;
    } catch (error) {
      dispatch(
        updateToastInfo({
          show: true,
          type: t('danger'),
          title: t('failed to get trips!'),
          message: t(error.message),
        })
      );
    }
    // }
  });

  const handleChange = (key, value) => {
    if (key === 'amount') {
      value = Number(value) || 0;
    }
    setFormData((prev) => ({ ...prev, [key]: value }));
    setErrors((prev) => ({ ...prev, [key]: undefined }));
  };

  const handlePlusClick = () => {
    setEachLine((prev) => [...prev, { id: Date.now(), data: {} }]);
  };

  const handleChildChange = (id, key, value) => {
    if (key === 'amount') {
      value = Number(value) || 0;
    }
    setEachLine(
      eachLine?.map((each) =>
        each.id === id
          ? { ...each, data: { ...each.data, [key]: value } }
          : each
      )
    );
    setErrors((prevErrors) => {
      const fieldKey = `${key}_${id}`;
      const { [fieldKey]: undefined, ...newErrors } = prevErrors;
      return newErrors;
    });
  };

  const handleDelete = (id) => {
    setEachLine(eachLine.filter((each) => each.id !== id));
  };

  const validate = () => {
    let newErrors = {};

    if (!formData.tripNumber) {
      newErrors.tripNumber = t('Trip Number is required');
    }
    if (!formData.paymentType) {
      newErrors.paymentType = t('Payment Type is required');
    }
    if (!formData.amount) {
      newErrors.amount = t('Amount is required');
    }

    eachLine.forEach((each) => {
      if (!each.data.tripNumber) {
        newErrors[`tripNumber_${each.id}`] = t('Trip Number is required');
      }
      if (!each.data.paymentType) {
        newErrors[`paymentType_${each.id}`] = t('Payment Type is required');
      }
      if (!each.data.amount) {
        newErrors[`amount_${each.id}`] = t('Amount is required');
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleCreatePayment = async () => {
    let data = eachLine.map((each) => {
      const data = each?.data;
      return data;
    });
    data.push(formData);

    try {
      dispatch(updateSpinnerState(true));
      const response = await createPaymentVoucher({
        data,
        warehouse,
        totalAmount: total,
      });
      setEachLine([]);
      setFormData({});
      setErrors({});
      SetTotal(0);
      setIsDisabled(false);
      setShowCreate(false);
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'success',
          title: 'Success',
          message: response?.message,
        })
      );
    } catch (error) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: 'failed!',
          message: error?.message,
        })
      );
    }

    // console.log({ data, warehouse, totalAmount: total });
  };

  const handleCalculate = () => {
    if (!validate()) {
      return;
    }
    setIsDisabled(true);
    let finalAmount = eachLine.reduce((accu, curr) => {
      const amount = Number(curr.data.amount) || 0;
      return accu + amount;
    }, 0);
    finalAmount += Math.round(Number(formData?.amount)) || 0;
    SetTotal(finalAmount);
    setShowCreate(true);
  };

  return (
    <>
      <div className="mt-1">
        <div className="mb-4 d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-2">
          <div className="d-block mb-4 mb-md-0">
            <BreadCrumbComponent items={breadCrumbItems} />
            <h4>{t('Create New Payment Voucher')}</h4>
          </div>
        </div>
        <Row>
          <Col md={3}>
            <Form.Group>
              <Form.Label>{t('Temposheet No.')}</Form.Label>
              <AsyncDropdown
                onChange={(option) => handleChange('tripNumber', option.value)}
                // width={300}
                disabled={isDisabled}
                fetchOptions={fetchOptions}
                placeholder={t('Search/Type TripNumber')}
              />
              {errors.tripNumber && (
                <div className="text-danger">{errors.tripNumber}</div>
              )}
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group>
              <Form.Label>{t('Payment Type')}</Form.Label>
              <EditableDropDown
                onChange={(option) => handleChange('paymentType', option.value)}
                // width={300}
                disabled={isDisabled}
                options={[
                  {
                    label: 'Delivery Pay',
                    value: 'deliveryPay',
                  },
                  {
                    label: 'Petty Cash',
                    value: 'pettyCash',
                  },
                ]}
                placeholder={t('Select Payment Type')}
              />
              {errors.paymentType && (
                <div className="text-danger">{errors.paymentType}</div>
              )}
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group>
              <Form.Label>{t('Amount')}</Form.Label>
              <Form.Control
                type="number"
                value={formData?.amount || ''}
                disabled={isDisabled}
                onChange={(e) => handleChange('amount', e.target.value)}
                placeholder={t('Enter Amount')}
              />
              {errors.amount && (
                <div className="text-danger">{errors.amount}</div>
              )}
            </Form.Group>
          </Col>
          <Col className="d-flex m-auto align-items-end justify-content-end">
            <Button size="md" onClick={handlePlusClick}>
              <FontAwesomeIcon icon={faPlus} />
              &nbsp;&nbsp; {t('Add More')}
            </Button>
          </Col>
        </Row>
        {eachLine?.map((each) => {
          return (
            <Row key={each.id}>
              <Col md={3}>
                <Form.Group>
                  <Form.Label>{t('Temposheet No.')}</Form.Label>
                  <AsyncDropdown
                    onChange={(option) =>
                      handleChildChange(each.id, 'tripNumber', option.value)
                    }
                    // width={300}
                    disabled={isDisabled}
                    fetchOptions={fetchOptions}
                    placeholder={t('Search/Type TripNumber')}
                  />
                  {errors[`tripNumber_${each.id}`] && (
                    <div className="text-danger">
                      {errors[`tripNumber_${each.id}`]}
                    </div>
                  )}
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group>
                  <Form.Label>{t('Payment Type')}</Form.Label>
                  <EditableDropDown
                    onChange={(option) =>
                      handleChildChange(each.id, 'paymentType', option.value)
                    }
                    // width={300}
                    disabled={isDisabled}
                    options={[
                      {
                        label: 'Delivery Pay',
                        value: 'deliveryPay',
                      },
                      {
                        label: 'Petty Cash',
                        value: 'pettyCash',
                      },
                    ]}
                    placeholder={t('Select Payment Type')}
                  />
                  {errors[`paymentType_${each.id}`] && (
                    <div className="text-danger">
                      {errors[`paymentType_${each.id}`]}
                    </div>
                  )}
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group>
                  <Form.Label>{t('Amount')}</Form.Label>
                  <Form.Control
                    type="number"
                    value={each?.data?.amount || ''}
                    disabled={isDisabled}
                    onChange={(e) =>
                      handleChildChange(each.id, 'amount', e.target.value)
                    }
                    placeholder={t('Enter Amount')}
                  />
                  {errors[`amount_${each.id}`] && (
                    <div className="text-danger">
                      {errors[`amount_${each.id}`]}
                    </div>
                  )}
                </Form.Group>
              </Col>
              <Col className="mt-4">
                <Button variant="danger" onClick={() => handleDelete(each.id)}>
                  <FontAwesomeIcon icon={faXmark} />
                </Button>
              </Col>
            </Row>
          );
        })}
        <Row className="mt-4">
          <Col className="fw-bold fs-4">
            {t(`Total Amount :  ${total.toFixed(2)}`)}
          </Col>
          {isDisabled ? (
            <Col className="me-5 d-flex justify-content-end">
              <Button
                onClick={() => {
                  setIsDisabled(false);
                  setShowCreate(false);
                }}
                variant="danger"
              >
                <FontAwesomeIcon icon={faUndo} />
                &nbsp;&nbsp; {t('Reset')}
              </Button>
            </Col>
          ) : (
            <Col className="d-flex justify-content-end">
              <Button onClick={handleCalculate}>
                <FontAwesomeIcon icon={faRefresh} />
                &nbsp;&nbsp; {t('Calculate')}
              </Button>
            </Col>
          )}
        </Row>
        {showCreate && (
          <Col className="d-flex justify-content-center">
            <Button onClick={handleCreatePayment}>
              <FontAwesomeIcon icon={faCheck} />
              &nbsp;&nbsp; {t('Create Voucher')}
            </Button>
          </Col>
        )}
      </div>
    </>
  );
};

export default CreatePaymentVoucher;
