export const convertProductsToDropdownOptions = (data) => {
  return data.map((item) => ({
    label: item.productName,
    value: item.productName,
  }));
};

export const convertSchemeDataToTableData = (data) => {
  const response = data.map((scheme) => {
    const updatedScheme = { ...scheme };
    updatedScheme.products = updatedScheme.products.map(
      (item) => item.productName
    );
    updatedScheme.validFrom = scheme.validFrom
      ? `${new Date(scheme.validFrom).toLocaleDateString()}`
      : scheme.validFrom;

    updatedScheme.validTill = scheme.validTill
      ? `${new Date(scheme.validTill).toLocaleDateString()}`
      : scheme.validTill;
    updatedScheme.schemeCircularDate = scheme.schemeCircularDate
      ? `${new Date(scheme.schemeCircularDate).toLocaleDateString()}`
      : scheme.schemeCircularDate;
    return updatedScheme;
  });

  return response;
};

export const transformFormData = ({
  formData,
  formProps,
  categories,
  subCategories,
  products,
  uploadedData,
  configData,
  isAdmin,
  userInfo,
  companies,
}) => {
  if (formProps?.uploadExcel) {
    formData.category = [...new Set(uploadedData.map((item) => item.category))];
    formData.subCategory = [
      ...new Set(uploadedData.map((item) => item.subCategory)),
    ];
    formData.products = uploadedData.map((item) => ({
      category: item.category,
      subCategory: item.subCategory,
      productName: item.productName,
      vanNo: item.vanNo,
      productCode: item.productCode,
      valuationType: item.valuationType,
      payout: item.payout,
      payoutBasis: item.payoutBasis,
      cappingQuantity: item.cappingQuantity,
    }));
  } else {
    let productOptions = [];
    if (products) {
      productOptions = Object.values(products);
    }
    if (!formData?.products?.includes('All')) {
      const tempProducts = productOptions.filter((obj) =>
        formData.products.includes(obj.productName)
      );
      formData.products = tempProducts.map((item) => ({
        productName: item.productName,
        vanNo: item.vanNo,
        category: item.productCategory,
        subCategory: item.subCategory,
      }));
      formData.isAllProducts = false;
    } else {
      formData.isAllProducts = true;
    }
  }
  if (formData?.regions?.includes('All')) {
    formData.regions = Object.keys(configData.zones);
  }
  if (formData?.category?.includes('All')) {
    if (isAdmin) {
      formData.category = [
        'All',
        ...Object.values(categories)?.map((item) => item.name),
      ];
    } else {
      formData.category = ['All', ...userInfo?.productCategory];
    }
  }
  if (formData?.company?.includes('All')) {
    formData.company = [
      'All',
      ...Object.values(companies)?.map((item) => item.name),
    ];
  }
  if (formData?.subCategory?.includes('All')) {
    formData.subCategory = [
      'All',
      ...Object.values(subCategories)?.map((item) => item.name),
    ];
  }
  return formData;
};
