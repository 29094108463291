import React, { useEffect, useState } from 'react';
import Parse from 'parse';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Navbar from '../Navbar';
import Sidebar from '../Sidebar';
import { StyledLoader } from './styles';
import ToastMessage from './toast';
import { Routes } from '../../router/routes';
import NotFound from '../../../src/pages/examples/NotFound';
import {
  resetStore,
  setApplicationConfigInState,
  setCurrentUserInState,
  setCurrentUserInfoInState,
  setCurrentUserPermissionsInStore,
  setCurrentWarehouseInfoInStore,
  setUserPreferencesInStore,
} from '../../actions/user';
import { getApplicationConfig } from '../../parse-functions/config';
import { getUserPreferences } from '../../parse-functions/preferences';
import { getCurrentWarehouseInfo } from '../../parse-functions/warehouse';
import { deleteUserInfoFromLocalStorage } from '../../utils/localStorage';
import { removeUnusedWarehousesFromList } from '../../utils/warehouse';
import {
  getAccountManagerInfo,
  getBrandManagerInfo,
  getBrandVendorInfo,
} from '../../parse-functions/sms/getUserInfo';
import { getUserPermissions } from '../../parse-functions/roles';
import { IGNORE_ROUTES } from '../../constants/ignoreRoutes';

const RouteWithSidebar = ({ component: Component, ...rest }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const state = useSelector((state) => state);
  const [showLoader, setShowLoader] = useState(true);
  const [isAllowed, setIsAllowed] = useState(null);
  const showSpinner = useSelector((state) => state.spinner.show || false);
  const isSuperAdmin = useSelector(
    (state) => state?.user?.preferences?.isSuperAdmin || false
  );
  const isBrandManager = useSelector(
    (state) => state?.user?.preferences?.isBrandManager || false
  );
  const isBrandVendor = useSelector(
    (state) => state?.user?.preferences?.isBrandVendor || false
  );
  const isAccountManager = useSelector(
    (state) => state?.user?.preferences?.isAccountManager || false
  );
  const isAdmin = useSelector(
    (state) => state?.user?.preferences?.isAdmin || false
  );
  const isCCManager = useSelector(
    (state) => state?.user?.preferences?.isCCManager || false
  );
  const isCCUser = useSelector(
    (state) => state?.user?.preferences?.isBranchCCUser || false
  );
  const isBranchCCUser = useSelector(
    (state) => state?.user?.preferences?.isBranchCCUser || false
  );
  const isHO = useSelector((state) => state?.user?.preferences?.isHO || false);
  const isFinance = useSelector(
    (state) => state?.user?.preferences?.isFinance || false
  );

  const preferences = useSelector((state) => state?.user?.preferences) || {};
  const permissions = useSelector((state) => state?.user?.permissions || {});
  const role = useSelector((state) => state?.user?.preferences?.roles || []);

  const { id, objectId } = useParams(location.pathname);

  useEffect(() => {
    setShowLoader(showSpinner);
  }, [showSpinner]);

  useEffect(() => {
    const initializeUser = async () => {
      if (Parse.User.current()) {
        if (
          !Object.keys(state?.user?.config).length ||
          !Object.keys(state?.user?.preferences).length
        ) {
          await setLoginInfo();
        }
      } else {
        navigate(Routes.Login.path);
      }
    };
    initializeUser();
  }, []);

  const setLoginInfo = async () => {
    try {
      // set User info here
      setShowLoader(true);
      dispatch(setCurrentUserInState(Parse.User.current()));
      const config = await getApplicationConfig();
      dispatch(setApplicationConfigInState(config));
      // get preferences and set it
      const preferences = await getUserPreferences();
      dispatch(setUserPreferencesInStore(preferences));

      const permissions = await getUserPermissions({
        role: preferences?.roles,
      });
      dispatch(setCurrentUserPermissionsInStore(permissions));

      if (preferences.isBrandManager) {
        const userInfo = await getBrandManagerInfo({
          id: Parse.User.current().id,
        });
        dispatch(setCurrentUserInfoInState(userInfo));
      }

      if (preferences.isBrandVendor) {
        const userInfo = await getBrandVendorInfo({
          id: Parse.User.current().id,
        });
        dispatch(setCurrentUserInfoInState(userInfo));
      }

      if (preferences.isAccountManager) {
        const userInfo = await getAccountManagerInfo({
          id: Parse.User.current().id,
        });
        dispatch(setCurrentUserInfoInState(userInfo));
      }
      if (!preferences.isCCUser && !preferences.isCCManager) {
        const warehouseInfo = await getCurrentWarehouseInfo({
          warehouseName: preferences?.storeName,
          isSuperAdmin: isSuperAdmin || preferences?.isSuperAdmin,
          isBrandManager: isBrandManager || preferences?.isBrandManager,
          isBrandVendor: isBrandVendor || preferences?.isBrandVendor,
          isAccountManager: isAccountManager || preferences?.isAccountManager,
        });

        if (isSuperAdmin) {
          const finalWarehouseList = removeUnusedWarehousesFromList(
            warehouseInfo?.otherWarehousesInZone
          );
          warehouseInfo.otherWarehousesInZone = finalWarehouseList;
          dispatch(setCurrentWarehouseInfoInStore(warehouseInfo));
        } else {
          dispatch(setCurrentWarehouseInfoInStore(warehouseInfo));
        }
      }
      setShowLoader(false);
      // if (preferences?.isSuperAdmin || preferences?.isAdmin) {
      //   navigate(Routes.HomePage.path);
      // }
      // if (Object.keys(permissions)?.[0] === '/login') {
      //   navigate(Object.keys(permissions?.[1]));
      // }
      // navigate(Object.keys(permissions)?.[0]);
    } catch (e) {
      setShowLoader(false);
      await deleteUserInfoFromLocalStorage();
      dispatch(resetStore());
      navigate(Routes.Login.path);
    }
  };

  if (!Parse.User.current()) {
    navigate(Routes.Login.path);
  }

  const hasPermission = (role, path) => {
    if (role[0] === 'admin' || role[0] === 'superAdmin') {
      return { read: true, write: true };
    }
    const finalId = id || objectId;
    if (finalId && path.includes(finalId)) {
      const segments = path.split('/');
      const idIndex = segments.indexOf(finalId);
      segments.splice(idIndex, 1);
      path = segments.join('/');
    }
    return permissions[path] || { read: false, write: false };
  };

  const checkPermission = () => {
    if (IGNORE_ROUTES.includes(location.pathname)) {
      setIsAllowed(true);
      return;
    }
    if (
      role?.length > 0 &&
      (Object.keys(permissions).length > 0 || isAdmin || isSuperAdmin)
    ) {
      const permission = hasPermission(role, location.pathname);

      setIsAllowed(permission?.read);
    } else {
      setIsAllowed(false);
    }
  };

  useEffect(() => {
    // Change this once all roles are known and routes are added
    if (!showLoader) {
      checkPermission();
    }
  }, [role, location, permissions, showLoader]);

  return (
    <StyledLoader active={showLoader} spinner>
      {isAllowed === null ? (
        <></>
      ) : isAllowed ? (
        <>
          <Sidebar />
          <main className="content">
            <Navbar />
            <Component {...rest} />
            {/* <Footer toggleSettings={toggleSettings} showSettings={showSettings} /> */}
            <ToastMessage />
          </main>
        </>
      ) : (
        <NotFound />
      )}
    </StyledLoader>
  );
};
export default RouteWithSidebar;
