import { RESET_STORE, SET_FILTERS_IN_STORE } from "../actions/types";

const INITIAL_STATE = {};
const filtersReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_FILTERS_IN_STORE: {
            const {
                pageType,
                data
            } = action.data;
            return {
                ...state,
                [pageType]: data
            }
        }
        case RESET_STORE: {
            return INITIAL_STATE;
        }
        default: return state;
    }
};

export default filtersReducer;