import { Button, Form, Modal } from '@themesberg/react-bootstrap';
import React from 'react';
import { generateRandomId } from '../../../../utils/string';
import { useTranslation } from 'react-i18next';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DatePicker from '../../../tripPayments/DatePicker';
import EditableDropDown from '../../../../components/common/EditableDropdown';

const DataModal = ({
  showModal,
  closeModal,
  modalTitle,
  modalSubTitle,
  confirmationModalProps,
  setConfirmationModalProps,
  handleSubmit,
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      id={generateRandomId()}
      as={Modal.Dialog}
      centered
      show={showModal}
      onHide={closeModal}
      size="lg"
    >
      <Modal.Body>
        <div className="mb-5 mt-2 mx-5">
          <div className="d-flex justify-content-between align-items-center mb-4">
            <div className="fs-5 text-center flex-grow-1">{t(modalTitle)}</div>
            <div>
              <Button variant="close" aria-label="Close" onClick={closeModal} />
            </div>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center text-center gap-3">
            {modalSubTitle && <div className="fs-5">{t(modalSubTitle)}</div>}
            <div
              style={{
                width: '100%',
                display:
                  confirmationModalProps?.length === 1 ? 'block' : 'grid',
                gridTemplateColumns: 'repeat(2,1fr)',
                gap: '1rem',
              }}
            >
              {confirmationModalProps?.map((item, index) => (
                <>
                  {item?.type === 'text' && (
                    <Form.Group className="mb-2">
                      <div className="text-start">{t(`${item.title} :`)}</div>
                      <Form.Control
                        id={generateRandomId()}
                        value={item.value}
                        onChange={(e) => {
                          const { value } = e.target;
                          setConfirmationModalProps((prev) =>
                            prev.map((prevItem, i) =>
                              i === index
                                ? { ...prevItem, value: value }
                                : prevItem
                            )
                          );
                        }}
                        // disabled={false}
                        type={item.type}
                      />
                    </Form.Group>
                  )}
                  {item?.type === 'number' && (
                    <Form.Group className="mb-2">
                      <div className="text-start">{t(`${item.title} :`)}</div>
                      <Form.Control
                        id={generateRandomId()}
                        value={item.value}
                        onChange={(e) => {
                          const { value } = e.target;
                          setConfirmationModalProps((prev) =>
                            prev.map((prevItem, i) =>
                              i === index
                                ? { ...prevItem, value: value }
                                : prevItem
                            )
                          );
                        }}
                        // disabled={false}
                        type={item.type}
                      />
                    </Form.Group>
                  )}
                  {item?.type === 'textArea' && (
                    <Form.Group className="mb-2">
                      <div className="text-start">{t(`${item.title} :`)}</div>
                      <Form.Control
                        id={generateRandomId()}
                        value={item.value}
                        onChange={(e) => {
                          const { value } = e.target;
                          setConfirmationModalProps((prev) =>
                            prev.map((prevItem, i) =>
                              i === index
                                ? { ...prevItem, value: value }
                                : prevItem
                            )
                          );
                        }}
                        // disabled={true}
                        rows={2}
                        as="textarea"
                      />
                    </Form.Group>
                  )}
                  {item?.type === 'date' && (
                    <>
                      <Form.Group className="mb-2 bg-white">
                        <div className="text-start">{t(`${item.title} :`)}</div>
                        <DatePicker
                          value={item.value}
                          backgroundColor="white"
                          setValue={(date) => {
                            setConfirmationModalProps((prev) =>
                              prev.map((prevItem, i) =>
                                i === index
                                  ? { ...prevItem, value: new Date(date) }
                                  : prevItem
                              )
                            );
                          }}
                        />
                      </Form.Group>
                    </>
                  )}
                  {item?.type === 'dropdown' && (
                    <Form.Group className="mb-2">
                      <div className="text-start">{t(`${item.title} :`)}</div>
                      <EditableDropDown
                        isMultiSelect={item.isMulti}
                        onChange={(option) => {
                          const { value } = option;
                          setConfirmationModalProps((prev) =>
                            prev.map((item, i) =>
                              i === index ? { ...item, value: value } : item
                            )
                          );
                        }}
                        value={item.value}
                        options={item.options}
                        placeholder="Select Value from below"
                      />
                    </Form.Group>
                  )}
                  {item?.type === 'switch' && (
                    <Form.Group>
                      <div className="text-start">{t(`${item.title} :`)}</div>
                      <Form.Check
                        type="switch"
                        id={`${item.title}`}
                        name={item.title}
                        //   value={item.value}
                        disabled={item.disabled}
                        checked={item.value || false}
                        onChange={(e) => {
                          const { checked } = e.target;
                          setConfirmationModalProps((prev) =>
                            prev.map((item, i) =>
                              i === index ? { ...item, value: checked } : item
                            )
                          );
                        }}
                        // isInvalid={formErrors[item.id]}
                      />
                    </Form.Group>
                  )}
                </>
              ))}
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex gap-5 justify-content-end">
          <Button
            variant="white"
            size="md"
            className="btn-export"
            onClick={closeModal}
          >
            <FontAwesomeIcon icon={faXmark} />
            &nbsp;&nbsp; {t('Cancel')}
          </Button>
          <Button
            variant="primary"
            size="md"
            className="btn btn-primary"
            onClick={handleSubmit}
          >
            <FontAwesomeIcon icon={faCheck} />
            &nbsp;&nbsp; {t('Submit')}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default DataModal;
