import React, { useEffect, useState } from 'react';
import OrderListPage from '../../../components/common/OrderListPage';
import arrayToBreadCrumbs from '../../../utils/sms/arrayToBreadCrumbs';
import { ShowAssignModal } from '../orders/components/ShowAssignModal';
import { orderStatus } from '../../../constants/orderStatus';
import { updateSpinnerState } from '../../../actions/spinner';
import { apiCall } from '../../../middlewares/api';
import { updateToastInfo } from '../../../actions/settings';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { apiCallConsts } from '../../../constants/apiCallConsts';
import { orderTableColumns } from '../orders/utils';
import { useNavigate } from 'react-router-dom';

const AwaitingInvoiceRegion = () => {
  const [ordersData, setOrdersData] = useState(null);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [updateMode, setUpdateMode] = useState('');
  const dispatch = useDispatch();
  const zone = useSelector((state) => state?.user?.preferences?.zone || '');
  const userName = useSelector((state) => state?.user?.user?.username || '');
  const navigate = useNavigate();
  const { t } = useTranslation();
  const breadCrumbItems = arrayToBreadCrumbs([['orders']]);
  const bulkActionOptions = [
    {
      label: 'Assign',
      value: 'assign',
    },
  ];
  const handleRefresh = () => {
    fetchOrders();
  };

  const messageDisplayer = (type, title, message) => {
    dispatch(
      updateToastInfo({
        show: true,
        type: type,
        title: t(title),
        message: t(message),
      })
    );
  };

  const onRowSelect = (selected, objectId) => {
    if (selected) {
      setSelectedOrders((prev) => {
        const selectedItem = ordersData.find(
          (item) => item.objectId === objectId
        );
        return [...prev, selectedItem];
      });
    } else {
      setSelectedOrders((prev) =>
        prev.filter((item) => item.objectId !== objectId)
      );
    }
    setOrdersData((prev) =>
      prev.map((item) =>
        item.objectId === objectId ? { ...item, isSelected: selected } : item
      )
    );
  };

  const fetchOrders = async () => {
    try {
      dispatch(updateSpinnerState(true));
      const res = await apiCall(
        apiCallConsts.GET_METHOD,
        `/internal/order?status=${orderStatus.awaitingInvoice}&zone=${zone}`
      );
      console.log(res);
      setOrdersData(res || []);
      dispatch(updateSpinnerState(false));
      messageDisplayer('success', '', 'Orders fetched successfully');
    } catch (error) {
      dispatch(updateSpinnerState(false));
      messageDisplayer('danger', 'Failed to fetch Orders', error.message);
    }
  };

  const handleShowAssignModal = () => {
    setShowAssignModal(true);
  };

  const handleSelectedRowsUpdate = async (actionId) => {
    switch (actionId) {
      case 'assign':
        setUpdateMode(orderStatus.accepted);
        handleShowAssignModal();
        break;
      default:
        break;
    }
  };

  const updateOrders = (data) =>
    apiCall(apiCallConsts.PUT_METHOD, apiCallConsts.ORDER_UPDATE_URL, data);

  const handleOnConfirmClick = async (data) => {
    try {
      dispatch(updateSpinnerState(true));
      await updateOrders(data);
      await fetchOrders();
      dispatch(updateSpinnerState(false));
      messageDisplayer('success', '', 'Update Successful!');

      if (updateMode === 'Accepted') {
        setShowAssignModal(false);
      }
      setSelectedOrders([]);
    } catch (e) {
      dispatch(updateSpinnerState(false));
      messageDisplayer('danger', 'Cannot be updated!', e.message);
    }
  };

  const handleModalOnConfirmClick = (storeCode) => {
    const selectedOrdersData = {
      id: selectedOrders.map((item) => item.objectId),
      status: updateMode,
      username: userName,
    };
    switch (updateMode) {
      case orderStatus.accepted:
        selectedOrdersData.storeCode =
          storeCode[0] || selectedOrders[0].storeCode;
        break;
      default:
        break;
    }
    handleOnConfirmClick(selectedOrdersData);
  };

  useEffect(() => {
    if (zone) {
      fetchOrders();
    }
  }, [zone]);

  return (
    <>
      <OrderListPage
        // pageType={'QC Passed Orders'}
        tableData={ordersData || []}
        tableColumns={orderTableColumns(
          onRowSelect,
          navigate,
          bulkActionOptions,
          true
        )}
        handleRefresh={handleRefresh}
        pageTitle={t(`QC Passed Orders`)}
        breadCrumbItems={breadCrumbItems}
        enableBulkActions={selectedOrders.length > 0}
        hideBulkActions={!bulkActionOptions}
        bulkActionOptions={bulkActionOptions}
        handleItemBulkUpdate={handleSelectedRowsUpdate}
      />
      <ShowAssignModal
        showModal={showAssignModal}
        onConfirm={handleModalOnConfirmClick}
        closeModal={() => setShowAssignModal(false)}
        modalTitle={t(`${updateMode} Order(s)`)}
      />
    </>
  );
};

export default AwaitingInvoiceRegion;
