import { Modal } from "@themesberg/react-bootstrap";
import React from "react";
import { useTranslation } from "react-i18next";

const EmptyTable = () => {
    const { t } = useTranslation();
    return (
        <div className="py-6 px-5 text-center">
            <Modal.Title className="text-primary mb-3">
                <h3>{t("No Data Found")}</h3>
            </Modal.Title>
            <p className="mb-4 text-primary">
                {t("Please reset your filters / Search text")}
            </p>
        </div>
    );
};
export default EmptyTable;