import React, { useEffect, useState } from 'react';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import * as Paginator from 'react-bootstrap-table2-paginator';
import Pagination from 'react-bootstrap-table2-paginator';
import Table from 'react-bootstrap-table-next';
import { useTranslation } from 'react-i18next';
import { Card, Col, Row } from '@themesberg/react-bootstrap';
import { customSizePerPage, customTotal } from './parcelStatusPages';
import { returnRowBGColor } from '../../utils/parcels';
import EmptyTable from './EmptyTableView';
import { useDispatch, useSelector } from 'react-redux';
import { setPageNumberInStore } from '../../actions/tablePage';

const NewTable = (props) => {
  const {
    id,
    data,
    columns,
    handleRowClick,
    showRowBgColor = false,
    isFooterRequired = true,
    isNewEmptyTable = false,
    rowStyle,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const totalPages = Math.ceil((data?.length || 0) / 10);
  const storedPage = useSelector((state) => state?.pageNumber?.[id] || 1);
  const [currentPage, setCurrentPage] = useState(
    storedPage > totalPages ? 1 : storedPage
  );

  const handlePageChange = (page) => {
    if (id) {
      dispatch(setPageNumberInStore({ id, page }));
    }
  };

  useEffect(() => {
    setCurrentPage(storedPage > totalPages ? 1 : storedPage);
  }, [storedPage, totalPages]);
  return (
    <ToolkitProvider
      exportCSV
      keyField="objectId"
      sort
      columns={columns}
      data={data || []}
      wrapperClasses="table-responsive"
    >
      {({ baseProps }) => (
        <Paginator.PaginationProvider
          pagination={Pagination({
            custom: true,
            showTotal: true,
            alwaysShowAllBtns: false,
            totalSize: data?.length,
            hidePageListOnlyOnePage: true,
            paginationTotalRenderer: customTotal,
            sizePerPageRenderer: customSizePerPage,
            onPageChange: handlePageChange,
            page: currentPage,
          })}
        >
          {({ paginationProps, paginationTableProps }) => (
            <>
              <div className="border-0 table-responsive  bg-white">
                {/* <div className="table-responsive py-2"> */}
                <Table
                  id={'parcelListTable'}
                  // rowClasses={(row) => {
                  //   const rowBGColor = !showRowBgColor
                  //     ? ''
                  //     : returnRowBGColor(
                  //         row?.dashboardStatus ||
                  //           row?.mobileStatus ||
                  //           row?.status
                  //       );
                  //   return `${rowBGColor}`;
                  // }}
                  noDataIndication={
                    isNewEmptyTable ? (
                      <div className="text-center  fs-5">{'No Data Found'}</div>
                    ) : (
                      <EmptyTable />
                    )
                  }
                  {...baseProps}
                  {...paginationTableProps}
                  bodyClasses="border-0"
                  classes="table table-borderless table-hover table-flush dataTable-table"
                  {...(handleRowClick && {
                    rowEvents: {
                      onClick: (e, row, index) => {
                        handleRowClick(row);
                      },
                    },
                  })}
                  rowStyle={
                    rowStyle
                      ? rowStyle
                      : {
                          cursor: 'pointer',
                          border: 'none',
                          borderRaidus: '0',
                          borderWidth: '0',
                          borderBottomWidth: '0',
                          marginBottom: '10px',
                          transition: 'background-color 0.3s ease',
                        }
                  }
                  // rowEvents={{
                  //   onMouseEnter: (e, row, rowIndex) => {
                  //     e.currentTarget.style.backgroundColor =
                  //       'rgba(255, 235, 236, 1)'; // Light blue on hover
                  //   },
                  //   onMouseLeave: (e, row, rowIndex) => {
                  //     e.currentTarget.style.backgroundColor = ''; // Reset background color
                  //   },
                  // }}
                />
                {/* </div> */}
              </div>

              {isFooterRequired && (
                <Card className="mt-2 p-2">
                  <Row className="align-items-center">
                    <Col
                      xs={12}
                      md={4}
                      className="d-flex justify-content-start py-1"
                    >
                      <Paginator.SizePerPageDropdownStandalone
                        {...paginationProps}
                        paginationSize={1}
                        sizePerPageList={[
                          { text: '10', value: 10 },
                          { text: '20', value: 20 },
                          { text: '50', value: 50 },
                        ]}
                      />
                    </Col>

                    <Col
                      xs={12}
                      md={4}
                      className="d-flex justify-content-center py-1"
                    >
                      <Paginator.PaginationTotalStandalone
                        {...paginationProps}
                      />
                    </Col>

                    <Col
                      xs={12}
                      md={4}
                      className="d-flex justify-content-end py-1"
                    >
                      <Paginator.PaginationListStandalone
                        {...paginationProps}
                        pagionationSize={1}
                      />
                    </Col>
                  </Row>
                </Card>
              )}
            </>
          )}
        </Paginator.PaginationProvider>
      )}
    </ToolkitProvider>
  );
};

export default NewTable;
