import { orderStatus } from "../constants/orderStatus";
import { returnUserReadableDateTime } from "./datetime";
import { extractLoaderInfoForTrip } from "./loaders";

export const returnMatchingSerialParcels = (parcels, serialToMatch) => {
  return parcels?.filter(
    (parcel) => parcel?.serialNo?.toUpperCase() === serialToMatch?.toUpperCase()
  );
};
export const getTripBadgeStatus = (status) => {
  let badgeStatus;
  switch (status) {
    case "temporary":
    case "draft": 
    case orderStatus.readyForPickup: {
      badgeStatus = "secondary";
      break;
    }
    case "pending":
    case "negotiation":
    case "cancellation":
    case "completion": 
    case "replacement": {
      badgeStatus = "warning";
      break;
    }
    case "completed": {
      badgeStatus = "dark";
      break;
    }
    case "active":
    case "dropppedathub":
    case "closed":
    case "delivered":
    case "ongoing": 
    case "return": {
      badgeStatus = "success";
      break;
    }
    case "deleted":
    case "failed":
    case "buyback":
    case "product":
    case "cancelled":
    case "rejected":
    case "stockNA":
    case "inactive": {
      badgeStatus = "danger";
      break;
    }

    default: {
      badgeStatus = "info";
    }
  }
  return badgeStatus;
};

export const allowTemposheetReceive = (tripInfo, currentWarehouse) => {
  return tripInfo?.warehouseCode !== currentWarehouse?.warehouseCode;
};

export const returnActiveTripsDataToDownload = (tripsObject) => {
  const dataToDownload = [];
  // eslint-disable-next-line no-unused-expressions
  Object.values(tripsObject)?.forEach((trip) => {
    const tripRow = {
      "Temposheet Number": trip.tripNumber,
      "Vehicle Number":
        trip?.dispatchData?.vehicleDetails?.vehicleNumber ||
        trip?.driverInfo?.vehicleNumber,
      "Vehicle Type":
        trip?.dispatchData?.vehicleDetails?.vehicleType ||
        trip?.driverInfo?.vehicleType,
      "Vehicle Area": trip?.vehicleArea,
      Status: trip.status,
      "Branch / Warehouse": trip?.warehouseCode,
      "Is Display / Transfer": trip?.isTransfer,
      "Created By": trip?.createdBy,
      "Created At": returnUserReadableDateTime(trip?.createdAt),
      "Converted By": trip?.convertedBy,
      "Converted At": returnUserReadableDateTime(trip?.dispatchedAt),
      "Closed By": trip?.closedBy || "-",
      "Closed At": trip?.closedAt
        ? returnUserReadableDateTime(trip?.closedAt)
        : "-",
      "Driver Name":
        trip?.dispatchData?.vehicleDetails?.driverName ||
        trip?.driverInfo?.driverName,
      "Driver Contact":
        trip?.dispatchData?.vehicleDetails?.contactNumber ||
        trip?.driverInfo?.contactNumber,
      "Vehicle Vendor Name":
        trip?.dispatchData?.vehicleDetails?.vendorName ||
        trip?.driverInfo?.vendorName,
    };

    // insert the loader details
    const loaderInfo = extractLoaderInfoForTrip(trip?.dispatchData);
    Object.keys(loaderInfo).forEach((loaderKey) => {
      tripRow[loaderKey?.toUpperCase()] = loaderInfo[loaderKey];
    });
    // insert the analytics details
    // eslint-disable-next-line no-unused-expressions
    trip?.analytics &&
      Object.keys(trip?.analytics)?.forEach((status) => {
        tripRow[status?.toUpperCase()] = trip?.analytics[status];
      });
    dataToDownload.push(tripRow);
  });
  return dataToDownload;
};
