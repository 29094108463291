export const apiCallConsts = {
  GET_METHOD: 'get',
  POST_METHOD: 'post',
  PUT_METHOD: 'put',
  DELETE_METHOD: 'delete',
  ALL_3PLS_URL: 'internal/delivery/all',
  ADD_3PL_URL: 'internal/delivery/add',
  DETAIL_3PL_URL: 'internal/delivery/',
  UPDATE_3PL_URL: 'internal/delivery/update',
  ORDER_DETAILS_URL: 'internal/order/',
  ORDER_UPDATE_URL: `internal/order/update`,
  ORDER_DATE_URL: 'internal/order',
  INVENTORY_BASE: 'internal/inventory',
  INVENTORY_URL: 'internal/inventory/all',
  INVENTORY_UPDATE_URL: 'internal/inventory/update',
  INVOICE_DOWNLOAD_URL: '/invoice/download',
};
