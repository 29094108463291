import React, { useEffect, useState } from "react";
import { Button, Card } from "@themesberg/react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import ConfirmationModal from "../common/confirmationModal";
import { removeSelectedOrdersFromVehicle } from "../../actions/orders";
import { updateToastInfo } from "../../actions/settings";
import { useTranslation } from "react-i18next";

const OrdersManage = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const vehicleState = useSelector((state) => state.vehicles || {});
    const [enableDeleteButton, setEnableDeleteButton] = useState(false);

    useEffect(() => {
        const {
            vehiclesList,
            activeVehicleId
        } = vehicleState;
        const selectedOrders = vehiclesList[activeVehicleId]?.orders?.filter(order => order.isSelected);
        if (selectedOrders?.length > 0 && !vehiclesList[activeVehicleId]?.tripCreated) {
            setEnableDeleteButton(true)
        } else {
            setEnableDeleteButton(false)
        }
    }, [vehicleState]);

    const handleRemoveOrders = () => {
        setShowDeleteModal(true);
    }
    const handleDeleteModalClose = () => {
        setShowDeleteModal(false);
    }
    const handleOnConfirmClick = () => {
        dispatch(removeSelectedOrdersFromVehicle());
        setShowDeleteModal(false);
        dispatch(updateToastInfo({
            show: true,
            type: 'success',
            title: t("Orders Removed"),
            message: t("Orders have been removed successfully")
        }))
    }
    return (
        <>
            <Button style={{
                width: 200,
                marginLeft: 22

            }} className="shadow-sm mt-2 mb-1" onClick={handleRemoveOrders} variant="danger" disabled={!enableDeleteButton}>
                <FontAwesomeIcon icon={faTrash} />&nbsp;&nbsp;{t("Remove Orders")}
            </Button>
            <ConfirmationModal
                showModal={showDeleteModal}
                onConfirm={handleOnConfirmClick}
                closeModal={handleDeleteModalClose}
                modalBody={t("Are you sure you want to remove")}
                modalTitle={t("Remove Orders")}
            />
        </>
    );
};
export default OrdersManage