import React from 'react';
import { Routes } from '../../../../router/routes';
import {
  orderStatus,
  returnOrderStatus,
} from '../../../../constants/orderStatus';
import { returnProductInfo } from '../../../../utils/sms/tableUtils';
import { returnCustomerAddressString } from '../../../../utils/table';

export const orderDetailsColumns = () => {
  return [
    {
      dataField: 'vanNo',
      text: 'vanno',
      //   formatter: (cell, row) => {
      //     console.log(cell);
      //     return <div>{cell}</div>;
      //   },
    },
    {
      dataField: 'orderNo',
      text: 'orderNo',
    },
    {
      dataField: 'productTitle',
      text: 'name',
      formatter: (cell, row) => {
        return returnCustomerAddressString(cell, null, 400, 70);
      },
    },
    {
      dataField: 'storeCode',
      text: 'storeName',
      formatter: (cell, row) => {
        return <div>{cell ? cell : '-'}</div>;
      },
    },
    {
      dataField: 'mrp',
      text: 'MRP',
    },
    {
      dataField: 'vsPrice',
      text: 'vsprice',
    },
    {
      dataField: 'quantity',
      text: 'quantity',
    },

    {
      dataField: 'offerAmount',
      text: 'offer amount',
    },
  ];
};

export const isBelongsToSameInvoice = (orders) => {
  if (orders.length === 0) return true;

  const invoice = orders[0].invoiceNo;
  return orders.every((order) => order.invoiceNo === invoice);
};

export const getBreadCrumbRoute = (currStatus, serviceType) => {
  const orderRoutes = {
    [orderStatus.assigned]: Routes.OMS.Orders.Assigned.path,
    [orderStatus.accepted]: Routes.OMS.Orders.Accepted.path,
    [orderStatus.awaitingInvoice]: Routes.OMS.Orders.Awaiting.path,
    [orderStatus.invoiced]: Routes.OMS.Orders.Invoice.path,
    [orderStatus.readyForPickup]: Routes.OMS.Orders.ReadyForPickup.path,
    [orderStatus.pickedUp]: Routes.OMS.Orders.Picked.path,
    [orderStatus.delivered]: Routes.OMS.Orders.Delivered.path,
    [orderStatus.pendingAction]: Routes.OMS.Orders.PendingAction.path,
    [orderStatus.rejected]: Routes.OMS.Orders.Rejected.path,
    [orderStatus.autoRejected]: Routes.OMS.Orders.AutoRejected.path,
    [orderStatus.preBooked]: Routes.OMS.Orders.PreBooked.path,
    [orderStatus.qcfailed]: Routes.OMS.Orders.QCFail.path,
    [orderStatus.cancelled]: Routes.OMS.Orders.Cancelled.path,
    [returnOrderStatus.REFUND_INITIATED]:
      Routes.OMS.Returns.RefundInitiated.path,
    [returnOrderStatus.REFUNDED]: Routes.OMS.Returns.Refunded.path,
    [orderStatus.completed]: Routes.OMS.CustomerPickup.Completed.path,
  };

  const returnRoutes = {
    [returnOrderStatus.REQUESETED]: Routes.OMS.Returns.Requested.path,
    [returnOrderStatus.ACCEPTED]: Routes.OMS.Returns.Accepted.path,
    [returnOrderStatus.PICKED]: Routes.OMS.Returns.Picked.path,
    [returnOrderStatus.RECEIVED]: Routes.OMS.Returns.Received.path,
    [returnOrderStatus.QCPASSED]: Routes.OMS.Returns.QC.path,
    [returnOrderStatus.REFUND_INITIATED]:
      Routes.OMS.Returns.RefundInitiated.path,
    [returnOrderStatus.REFUNDED]: Routes.OMS.Returns.Refunded.path,
  };

  const routes = serviceType ? returnRoutes : orderRoutes;
  return routes[currStatus] || Routes.OMS.Returns.Requested.path;
};

export const showShippingLabelDownload = (data) => {
  if (Array.isArray(data)) {
    return data.every(
      (order) =>
        !order?.isLarge &&
        !order?.isMedium &&
        !order?.isBiker &&
        !order?.isCustomerPickup
    );
  } else {
    return (
      !data?.isLarge &&
      !data?.isMedium &&
      !data?.isBiker &&
      !data?.isCustomerPickup
    );
  }
};
