import Parse from "parse";
export const saveTemporaryTrip = async (payload) => {
    return Parse.Cloud.run("saveTemporaryTrip", {...payload});
}

export const getTemporaryTripsFromServer = async (params) => {
    return Parse.Cloud.run("getTemporaryTrips", params);
}
export const getCurrentTripsFromServer = async (params) => {
    return Parse.Cloud.run("getActiveTrips", params);
}
export const getTripDetailsByTripNumberFromServer = async (tripNumber) => {
    return Parse.Cloud.run("getTripDetailsByTripNumber", {tripNumber});
}
export const removeParcelFromSheet = async (objectId) => {
    return Parse.Cloud.run("removeParcelsFromTrip", { objectId});
}
export const dispatchTemporaryTrip = async (data) => {
    return Parse.Cloud.run("dispatchTemporaryTrip", data);
}
export const getTripInfoByObjectId = async (id) => {
    return Parse.Cloud.run("getTripInfoByObjectId", {id});
}
export const deleteEmptyTripByObjectId = async (objectId) => {
    return Parse.Cloud.run("deleteEmptyTripByObjectId", {objectId})
};
export const markTripParcelAsScanned = async (parcelObjectIds, tripNumber) => {
    return Parse.Cloud.run("markTripParcelAsScanned", {parcelObjectIds, tripNumber});
}
export const updateClosureDataInParcel = async(data) => {
    return Parse.Cloud.run("updateClosureDataInParcel", data);
}
export const closeCurrentTrip = async (objectId, tripNumber, storeCode) => {
    return Parse.Cloud.run("closeCurrentTrip", {objectId, tripNumber, storeCode});
}
export const addParcelToTemporaryTrip = async (data) => {
    return Parse.Cloud.run("addParcelToTemporaryTrip", data);
}
export const getTripInfoByTemposheetNumber = async (temposheetNumber, getParcels) => {
    return Parse.Cloud.run("getTripInfoByTemposheetNumber", {temposheetNumber, getParcels});
}
export const updateTemposheetWithNewLoaderInfo = async (data) => {
    return Parse.Cloud.run("updateTemposheetWithNewLoaderInfo", data);
}

export const updateTemposheetDataForTrip = async (data) => {
    return Parse.Cloud.run("updateTemposheetDataForTrip", data);
}

export const getVehicleMonthlyAttendance = async (data) => {
    return Parse.Cloud.run("getVehicleMonthlyAttendance", data);
}

export const getMonthlyDeliveryReportFromServer = async (data) => {
    return Parse.Cloud.run("getMonthlyDeliveryReportFromServer", data);
}

export const getAllTripNumbers = async (data) => {
    return Parse.Cloud.run('getAllTripNumbers',data);
}

export const getMultipleTripsByTripNumbers = async(data) =>{
    return Parse.Cloud.run('getMultipleTripDetailsByTripNumber',data);
}