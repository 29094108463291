import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Form, Row } from "@themesberg/react-bootstrap";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { updateToastInfo } from "../../actions/settings";
import { updateSpinnerState } from "../../actions/spinner";
import { addOrdersForVehicle } from "../../actions/vehicles";
import { getOrderById } from "../../parse-functions/orders";
import { useFocus } from "../../utils/hooks";
import { invoiceNumberValidation } from "../../utils/planning";
import AddManualOrderManual from "../common/AddManualOrderModal";
import OrderAddManualPermissionModal from "../common/orderAddManualPermissionModal";
import OrderAlertForAlreadyScheduledModal from "../common/orderAlreadyScheduledModal";
import { addParcelToTemporaryTrip } from "../../parse-functions/trips";

const ScanInvoiceNumber = (props) => {
    const {
        showLabel = true,
        hideBottomMargin = false,
        isTemporaryPage = false,
        handleParcelInsertInTemporary,
        tripMetaData
    } = props;
    const [showOrderAlreadyScheduled, setShowOrderAlreadyScheduled] = useState(false);
    const [showOrderAddManualPermissionModal, setShowOrderAddManualPermissionModal] = useState(false);
    const [showAddManualOrderManual, setShowAddManualOrderManual] = useState(false);
    const activeVehicleId = useSelector((state) => state.vehicles.activeVehicleId || "");
    const selectedTripId = useSelector((state) => state?.trips?.selectedTrip || null);
    const warehouse = useSelector((state) => state.user.warehouse || {});
    const vehicle = useSelector((state) => state?.vehicles?.vehiclesList?.[activeVehicleId] || {});
    const [alreadyScheduledOrders, setAlreadyScheduledOrders] = useState({});
    const dispatch = useDispatch();
    const [inputRef, setInputFocus] = useFocus();
    const { t } = useTranslation();
    const [orderId, setOrderId] = useState('');
    const [isOrderIdValid, setIsOrderIdValid] = useState(true);
    const {
        id,
        tripCreated = false
    } = vehicle;
    const handleInputOnChange = (event) => {
        setOrderId(event.target.value);
    }
    const fetchOrder = async () => {
        try {
            dispatch(updateSpinnerState(true));
        const isValidOrderId = invoiceNumberValidation(orderId);
        setIsOrderIdValid(isValidOrderId);
        if (isValidOrderId) {
            const ordersResponse = await getOrderById(orderId);
            const {
                alreadyScheduled = false,
                orders
            } = ordersResponse;
            if (alreadyScheduled) {
                dispatch(updateSpinnerState(false));
                setAlreadyScheduledOrders(JSON.parse(JSON.stringify(orders[0])));
                setShowOrderAlreadyScheduled(true);
            } else if (orders?.length > 0) {
                if (isTemporaryPage) {
                    // add this order to the vehicle trip in the system
                    const parcel = await addParcelToTemporaryTrip({ vehicle: {orders: JSON.parse(JSON.stringify(orders)), isTransfer: tripMetaData?.isTransfer || false}, tripNumber: selectedTripId, warehouse});
                    handleParcelInsertInTemporary(parcel);
                } else {
                    // orders found, set them against the vehicle
                    dispatch(addOrdersForVehicle(orders, id));
                }
                dispatch(updateToastInfo({
                    show: true,
                    type: "success",
                    title: "Invoice Scan",
                    message: "Invoice Added to Vehicle"
                }));
                dispatch(updateSpinnerState(false));
                setOrderId("");
                setInputFocus();
            } else {
                // orders not found, show a toastr
                dispatch(updateSpinnerState(false));
                dispatch(updateToastInfo({
                    show: true,
                    type: "danger",
                    title: "Invoice Scan",
                    message: "Invoice not found"
                }));
                setShowOrderAddManualPermissionModal(true);
                setInputFocus();
            }
        } else {
            dispatch(updateSpinnerState(false));
        }
        } catch(e) {
            console.log(e);
        }
    }
    const handleAlreadyScheduledModal = () => {
        setShowOrderAlreadyScheduled(false);
    }

    const handleShowOrderAddManualPermissionModal = () => {
        setShowOrderAddManualPermissionModal(false);
    };
    const handleAddManualButtonClick = () => {
        setShowAddManualOrderManual(true);
        handleShowOrderAddManualPermissionModal();
    }
    const handleHideAddManualOrderManual = () => {
        setShowAddManualOrderManual(false);
    }
    return (
        <>
            <Form.Group className={`${hideBottomMargin ? "" : "mb3"} w-100`} >
                {(showLabel) && (
                    <Form.Label>{t("Enter / Scan Invoice Number")}</Form.Label>
                )}
                <Row>
                    <Col xs={8}>
                        <Form.Control
                            placeholder="Enter Invoice No to add"
                            ref={inputRef}
                            value={orderId}
                            disabled={tripCreated && !isTemporaryPage}
                            onKeyDown={(event) => {
                                if (event.nativeEvent.code === "Enter") {
                                    fetchOrder();
                                }
                            }}
                            onChange={handleInputOnChange}
                            required
                            isInvalid={!isOrderIdValid} type="text" />
                        {!isOrderIdValid && (
                            <Form.Control.Feedback type="invalid">{t("Please enter valid InvoiceId [X-Q-SI X] [RC/X/X-X/X] [PC/X/X-X/X] [X-S-SI X]")}</Form.Control.Feedback>
                        )}
                    </Col>
                    <Col xs={4}>
                        <Button type="button" disabled={tripCreated && !isTemporaryPage} className="w-100" onClick={fetchOrder} variant="primary">
                            <FontAwesomeIcon icon={faSearch} />&nbsp;&nbsp;{t("Search")}
                        </Button>
                    </Col>
                </Row>
            </Form.Group>
            <OrderAlertForAlreadyScheduledModal
                show={showOrderAlreadyScheduled}
                handleClose={handleAlreadyScheduledModal}
                buttonText={t("Add Other")}
                topTitle={""}
                mainHeaderText={t("Order already planned for Delivery")}
                order={alreadyScheduledOrders}
            />
            <OrderAddManualPermissionModal
                show={showOrderAddManualPermissionModal}
                handleClose={handleShowOrderAddManualPermissionModal}
                buttonText={"Add Manually"}
                onButtonClose={handleAddManualButtonClick}
            />
            <AddManualOrderManual
                tripMetaData={tripMetaData}
                isTemporaryPage={isTemporaryPage}
                invoiceNo={orderId}
                show={showAddManualOrderManual}
                handleParcelInsertInTemporary={handleParcelInsertInTemporary}
                handleClose={handleHideAddManualOrderManual}
            />
        </>
    )
}
export default ScanInvoiceNumber;