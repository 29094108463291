import React from "react";
import OrderPage from "./components/OrderPage";
import { orderStatus } from "../../../constants/orderStatus";

const Rejected = () => {
  return (
    <div>
      <OrderPage type={orderStatus.rejected} />
    </div>
  );
};

export default Rejected;
