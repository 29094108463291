import Parse from "parse";

export const uploadLoadersDataToServer = async (data) => {
    return Parse.Cloud.run("uploadLoadersDataToServer", data);
};

export const getLoadersListForStore = async (data) => {
    return Parse.Cloud.run("getLoadersListForStore", data);
}

export const updateLoadersInfo = async (data) => {
    return Parse.Cloud.run("updateLoadersInfo", data);
}

export const createNewLoader = async (data) => {
    return Parse.Cloud.run("createNewLoader", data);
}