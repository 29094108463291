import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setParcelsForStagesInStore } from "../../actions/parcels";
import { updateToastInfo } from "../../actions/settings";
import { updateSpinnerState } from "../../actions/spinner";
import ParcelStatusPage from "../../components/common/parcelStatusPages";
import { getParcelsByStatusAndSubStatus } from "../../parse-functions/parcels";
import { Routes } from "../../router/routes";
import { convertArrayToObj } from "../../utils/json";
import { returnScheduledTableColumns } from "./returnsUtils";
import { setFiltersInStore } from "../../actions/filters";
import { returnEndOfDay, returnStartOfDay } from "../../utils/datetime";

const ReturnScheduledList = () => {
    const pageStatus = "returnok"
    const reduxStoreKeyPageName = "returnokScheduledTargeted";
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const breadCrumbItems = [{
        title: t("Returns"),
        link: Routes.Returns.ReturnOK.path
    }, {
        title: t("Return Scheduled"),
        link: Routes.Returns.ReturnScheduled.path
    }];
    const storeCode = useSelector((state) => state?.user?.warehouse?.warehouseCode || "");
    const parcels = useSelector((state) => state?.parcels?.[reduxStoreKeyPageName] || {});
    const filtersValue = useSelector((state) => state.filters?.[reduxStoreKeyPageName]);
    const zone = useSelector((state) => state?.user?.warehouse?.zone || "");
    
    const getAvailableParcels = async (startDate, endDate) => {
        dispatch(updateSpinnerState(true));
        try {
            const filters = {
                subStatus: ["returnok", "defectivepicked"],
                rescheduleTargetBranch: storeCode,
                storeCode,
                isRescheduled: "true",
                startDate: new Date(startDate),
                endDate: new Date(endDate),
                zone
            };
            const parcels = await getParcelsByStatusAndSubStatus(filters);
            dispatch(setParcelsForStagesInStore(reduxStoreKeyPageName, convertArrayToObj(JSON.parse(JSON.stringify(parcels)), "objectId")))
            dispatch(updateSpinnerState(false));
            dispatch(updateToastInfo({
                show: true,
                type: 'success',
                title: t('Orders fetched'),
                message: t("You can Track your targeted return scheduled items from here")
            }))

        } catch (e) {
            dispatch(updateSpinnerState(false));
            dispatch(updateToastInfo({
                show: true,
                type: 'danger',
                title: t('Error'),
                message: t(e.message)
            }))
        }
    }
    const handleFiltersSubmit = (startDate, endDate) => {
        dispatch(setFiltersInStore({
            pageType: reduxStoreKeyPageName,
            data: {
              startDate: new Date(startDate),
              endDate: new Date(endDate)
            }
          }))
        getAvailableParcels(startDate, endDate);
    };

    const onRefreshClick = () => {
        const currentStartDate = filtersValue.startDate ? new Date(filtersValue.startDate): returnStartOfDay(new Date());
        const currentEndDate = filtersValue.endDate ? new Date(filtersValue.endDate) : returnEndOfDay(new Date());
        getAvailableParcels(currentStartDate, currentEndDate);
    }

    return (
        <>
            <ParcelStatusPage
                pageType={pageStatus}
                filtersValue={filtersValue}
                filterSubmit={handleFiltersSubmit}
                tableData={parcels}
                tableColumns={returnScheduledTableColumns()}
                handleRefresh={onRefreshClick}
                pageTitle={t("Return Scheduled")}
                breadCrumbItems={breadCrumbItems}
                pageSubTitle={t("All the Returned Parcels whose delivery has been targeted at your warehouse")}
                searchPlaceHolder={t("Search by Invoice No. in Database")}
                hideBulkActions
                enableBulkActions={false}
            />
        </>
    )
}
export default ReturnScheduledList;