import Parse from "parse";

export const getBrandManagerInfo = async (data) => {
  return Parse.Cloud.run("getBrandManagerById", data);
};

export const getBrandVendorInfo = async (data) => {
  return Parse.Cloud.run("getBrandVendorById", data);
};

export const getAccountManagerInfo = async (data) => {
  return Parse.Cloud.run("getAccountManagerById", data);
};

export const getSMSUsers = async (data) => {
  return Parse.Cloud.run("getSchemeUser", data);
};
