import React from "react";
import { faCubes, faWeight } from "@fortawesome/free-solid-svg-icons";
import { Card, Row, Col } from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";
// import { setActiveVehicleIndexInStore } from "../../actions/vehicles";
// import { accountLevelVehicleType } from "../../utils/constants";
import { returnVehicleIcon } from "../../utils/vehicles";
import { setActiveVehicleId } from "../../actions/vehicles";
import { useTranslation } from "react-i18next";

const VehicleCard = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { vehicle = {}, isActive } = props;
    const { vehicleNumber, vehicleType, weight, height, volume, length, breadth } = vehicle;
    const handleVehicleCardClick = () => {
        dispatch(setActiveVehicleId(vehicle?.id))
    }

    return (
        <Card
            border={!isActive ? "light": "dark"}
            className="shadow-sm mb-4"
            style={{
                cursor: 'pointer'
            }}
            onClick={handleVehicleCardClick}>
            <Card.Body>
                <Row className="d-block d-xl-flex align-items-center">
                    <Col xl={4} className="text-xl-center d-flex align-items-center justify-content-xl-center mb-3 mb-xl-0">
                        <div className={`icon icon-shape icon-md icon rounded me-4 me-sm-0`}>
                            <FontAwesomeIcon icon={returnVehicleIcon(vehicleType)} />
                        </div>
                    </Col>
                    <Col xs={12} xl={8} className="px-xl-0">
                        <div className="d-none d-sm-block">
                            <h5 className="mb-1">{vehicleNumber}</h5>
                        </div>
                        <small>{t("Height")}: {height}, {t("Length")}: {length}, {t("Breadth")}: {breadth}</small>
                        <div className="small mt-2">
                            <FontAwesomeIcon icon={faWeight} /> {weight},&nbsp;&nbsp;<FontAwesomeIcon icon={faCubes} /> {volume}
                            {/* <span className={`${percentageColor} fw-bold`}>
                  {percentage}%
                </span> Since last month */}
                        </div>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};
export default VehicleCard;