import {
    SET_VEHICLE_TYPES_IN_STORE,
    SET_ACTIVE_VEHICLE_ID,
    ADD_ORDERS_FOR_VEHICLE,
    MARK_TRIP_AS_CREATED,
    MARK_VEHICLE_AS_SELECTED
} from "./types"

export const setVehicleTypesInStore = (data) => {
    return {
        type: SET_VEHICLE_TYPES_IN_STORE,
        data
    }
}
export const setActiveVehicleId = (id) => {
    return {
        type: SET_ACTIVE_VEHICLE_ID,
        id
    }
}

export const addOrdersForVehicle = (orders, vehicleId) => {
    return {
        type: ADD_ORDERS_FOR_VEHICLE,
        data: {
            orders,
            vehicleId
        }
    }
}
export const markTripAsCreated = () => {
    return {
        type: MARK_TRIP_AS_CREATED
    }
}

export const markVehicleAsSelected = (data) => {
    return {
        type: MARK_VEHICLE_AS_SELECTED,
        data
    }
}