import React, { useEffect, useState } from 'react';
import {
  faArrowDown,
  faArrowRotateLeft,
  faArrowUp,
  faBroom,
  faClipboardCheck,
  faClipboardList,
  faClipboardQuestion,
  faClipboardUser,
  faMinus,
  faSliders,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Button, Form } from '@themesberg/react-bootstrap';
import { useTranslation } from 'react-i18next';
import EditableDropDown from '../../../components/common/EditableDropdown';
import BarGraph from '../../../components/common/BarGraph';
import { apiCall } from '../../../middlewares/api';
import { apiCallConsts } from '../../../constants/apiCallConsts';
import { useDispatch, useSelector } from 'react-redux';
import { updateSpinnerState } from '../../../actions/spinner';
import { updateToastInfo } from '../../../actions/settings';
import { returnWarehouseDropdownOptions } from '../../../utils/warehouse';
import { arrayToDropdownOptions } from '../../../utils/sms/arrayToDropdownOptions';
import { setStoresInStore } from '../../../actions/oms/stores';
import { useFetchStores } from '../custom-hooks/useFetchStores';

const Analytics = () => {
  const { t } = useTranslation();
  const zone = useSelector((state) => state?.user?.preferences?.zone || '');
  const ownStore = useSelector(
    (state) => state?.user?.preferences?.storeName || ''
  );
  const storeList = useSelector((state) => state?.stores?.storeList || []);
  const roles = useSelector((state) => state?.user?.preferences?.roles || []);
  const dispatch = useDispatch();
  const [stores, setStores] = useState(storeList);
  const [openFilter, setOpenFilter] = useState(false);
  const [type, setType] = useState(['month']);
  const [response, setResponse] = useState({});
  const [store, setStore] = useState([ownStore]);
  const [city, setCity] = useState([]);
  const [region, setRegion] = useState([zone]);
  const [selectedCard, setSelectedCard] = useState(null);
  const [flag, setFlag] = useState(false);

  const getStartAndEndBasedOnType = (type) => {
    const res = { startDate: new Date(), endDate: new Date() };
    const currentDay = new Date();

    if (type === 'week') {
      const firstDayOfWeek = new Date(
        currentDay.getTime() - 7 * 24 * 60 * 60 * 1000
      );
      res.startDate = firstDayOfWeek;
      res.endDate = new Date();
    } else if (type === 'month') {
      const startOfYear = new Date(currentDay.getFullYear(), 0, 1);
      res.startDate = startOfYear;
      res.endDate = new Date();
    } else {
      const lastYearStart = new Date(currentDay.getFullYear(), 0, 1);
      res.startDate = lastYearStart;
      res.endDate = new Date();
    }

    return res;
  };

  const getGraphConfig = () => {
    const keysToIgnore = [
      'totalOrderPerformance',
      'pendingOrderPerformance',
      'completedOrderPerformance',
      'returnOrderPerformance',
    ];
    const labels =
      Object.keys(response || {}).length > 0 && selectedCard
        ? Object.keys(response).slice(0, Object.keys(response).length - 1)
        : Object.keys(response['overAll']).filter(
            (key) => !keysToIgnore.includes(key)
          ) || [];
    const values =
      Object.values(response).length > 0 && selectedCard
        ? Object.values(response)
            .slice(0, Object.keys(response).length - 1)
            .map((each) => each[selectedCard])
        : Object.entries(response['overAll'])
            .filter(([key]) => !keysToIgnore.includes(key))
            .map(([, value]) => value);

    let config = {
      label: labels,
      dataset: [
        {
          label: 'No. of Orders',
          data: values,
          backgroundColor: 'rgba(200, 200, 200, 1)',
          borderColor: 'rgba(200, 200, 200, 1)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgba(255, 0, 0, 0.8)', // Darker red on hover
          barPercentage: 0.5, // Adjust space between bars
        },
      ],
      options: {
        responsive: true,
        scales: {
          x: {
            grid: {
              display: false, // Remove vertical grid lines
            },
            categoryPercentage: 0.5,
          },
          y: {
            beginAtZero: true,
            ticks: {
              stepSize: 100,
            },
            grid: {
              display: false,
              color: 'rgba(200, 200, 200, 0.3)', // Light horizontal grid lines
            },
          },
        },
        plugins: {
          legend: {
            display: true, // Hide the legend
          },
          tooltip: {
            backgroundColor: 'rgba(255, 0, 0, 0.7)', // Custom tooltip background
          },
        },
      },
    };
    return config;
  };

  //   const filterObj = [
  //     { label: 'TYPE', value: type[0], func: setType },
  //     {
  //       label: 'STORE',
  //       value: store[0],
  //       func: setStore,
  //     },
  //     { label: 'CITY', value: city[0], func: setCity },
  //     { label: 'REGION', value: region[0], func: setRegion },
  //   ];

  const baseCardStyle = {
    height: 'auto',
    minHeight: '150px',
    boxSizing: 'border-box',
    cursor: 'pointer',
    animation: 'dissolve 300ms ease-out',
  };

  const getCardStyle = (cardName) => {
    return selectedCard === cardName
      ? { ...baseCardStyle, border: '1px solid rgba(241, 49, 49, 1)' }
      : { ...baseCardStyle, border: '1px solid #E5E7EB' };
  };

  const handleCardClick = (e) => {
    const cardName = e.currentTarget.id;
    setSelectedCard((prevCard) => (prevCard === cardName ? null : cardName));
  };

  const handleReset = () => {
    setType(['month']);
    setStore([]);
    setCity([]);
    setRegion([]);
    setFlag((flag) => !flag);
  };

  const fetchData = async () => {
    let data = {
      startDate: getStartAndEndBasedOnType(type[0]).startDate,
      endDate: getStartAndEndBasedOnType(type[0]).endDate,
      type: type[0],
      storeCode: ownStore ? ownStore : store[0] === 'all' ? '' : store[0],
      city: city[0],
      region: region[0],
    };
    try {
      dispatch(updateSpinnerState(true));
      const response = await apiCall(
        apiCallConsts.GET_METHOD,
        'internal/dashboard',
        data
      );
      setResponse(response);
      dispatch(
        updateToastInfo({
          type: 'success',
          show: true,
          title: '',
          message: 'Data fetched successfully!',
        })
      );
      dispatch(updateSpinnerState(false));
    } catch (error) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          type: 'danger',
          show: true,
          title: 'failed!',
          message: error.message,
        })
      );
    }
  };

  //   const handleEmpty = async (fun) => {
  //     fun(() => '');
  //     await fetchData();
  //     setFlag((flag) => !flag);
  //   };
  useFetchStores();
  // useEffect(() => {
  //   const fetchStore = async () => {
  //     try {
  //       dispatch(updateSpinnerState(true));
  //       const data = await apiCall(
  //         apiCallConsts.GET_METHOD,
  //         `internal/store/all?region=${zone}`,
  //         {}
  //       );
  //       setStores(data || []);
  //       dispatch(setStoresInStore(data));
  //       dispatch(updateSpinnerState(false));
  //     } catch (error) {
  //       dispatch(updateSpinnerState(false));
  //       dispatch(
  //         updateToastInfo({
  //           show: true,
  //           type: 'danger',
  //           title: t('failed to fetch'),
  //           message: t(`${error.message}`),
  //         })
  //       );
  //     }
  //   };
  //   console.log(storeList);
  //   if (!roles.includes('inchargeOMS') && storeList.length === 0) {
  //     fetchStore();
  //   }
  // }, []);

  useEffect(() => {
    const fetch = async () => await fetchData();
    fetch();
  }, []);

  return (
    <>
      <h5 className="my-2">{t('Order Management System')}</h5>
      <div className="d-flex justify-content-between align-content-center text-center p-2 bg-white">
        <div className="fw-bold fs-5 mt-1">{t('Orders Overview')}</div>
        <Button
          variant="light"
          size="md"
          onClick={() => setOpenFilter(!openFilter)}
        >
          <FontAwesomeIcon icon={faSliders} />
          &nbsp;&nbsp;{t('Filter')}
        </Button>
      </div>

      <>
        <div
          className="table-settings mt-2 mb-4"
          style={{
            background: '#EAEDF2',
            maxHeight: openFilter ? '700px' : '0px',
            overflow: openFilter ? '' : 'hidden', // Ensure content is hidden when collapsed
            transition: 'max-height 0.7s ease-in-out', // Smooth transition when toggling
          }}
        >
          <div style={{ gap: 8, display: 'flex' }}>
            <Form.Group
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(3, 1fr)', // Three columns
                gap: '20px', // Gap between elements
                width: '100%',
                backgroundColor: 'white',
                padding: 20,
              }}
            >
              <div>
                <Form.Label>TYPE</Form.Label>
                <EditableDropDown
                  id={'Type'}
                  onChange={(e) => setType([e.value])}
                  style={{
                    height: 38,
                    width: '100%',
                  }}
                  value={type}
                  options={[
                    { label: 'Week', value: 'week' },
                    { label: 'Month', value: 'month' },
                    { label: 'Year', value: 'year' },
                  ]}
                  placeholder={'Choose the Type'}
                />
              </div>

              {!roles.includes('inchargeOMS') && (
                <>
                  <div>
                    <Form.Label>STORE</Form.Label>
                    <EditableDropDown
                      id={'Type'}
                      onChange={(e) => setStore([e.value])}
                      style={{
                        height: 38,
                        width: '100%',
                      }}
                      value={store}
                      options={
                        storeList?.length > 0
                          ? returnWarehouseDropdownOptions(storeList, true)
                          : []
                      }
                      placeholder={'Choose the store'}
                    />
                  </div>
                  <div>
                    <Form.Label>CITY</Form.Label>
                    <EditableDropDown
                      id={'City'}
                      onChange={(e) => setCity([e.value])}
                      style={{
                        height: 38,
                        width: '100%',
                      }}
                      value={city}
                      options={arrayToDropdownOptions([
                        ...new Set(stores?.map((store) => store?.city)),
                      ])}
                      placeholder={'Select City'}
                    />
                  </div>
                  <div>
                    <Form.Label>REGION</Form.Label>
                    <EditableDropDown
                      id={'Region'}
                      onChange={(e) => setRegion([e.value])}
                      style={{
                        height: 38,
                        width: '100%',
                      }}
                      value={region}
                      options={arrayToDropdownOptions([
                        ...new Set(stores?.map((store) => store?.zone)),
                      ])}
                      placeholder={'Select Region'}
                    />
                  </div>{' '}
                </>
              )}

              <div
                style={{
                  gridColumn: 'span 3', // Span across all columns
                  display: 'flex',
                  justifyContent: 'flex-end',
                  gap: '10px',
                }}
              >
                <Button
                  variant="white"
                  className="fa-export"
                  onClick={handleReset}
                  style={{
                    width: '150px',
                    height: '38px',
                    borderRadius: 0,
                    background: '#EAEDF2',
                  }}
                >
                  <FontAwesomeIcon icon={faArrowRotateLeft} />
                  &nbsp;&nbsp;{t('Reset')}
                </Button>

                <Button
                  variant="white"
                  className="fa-export"
                  onClick={fetchData}
                  style={{
                    width: '150px',
                    height: '38px',
                    borderRadius: 0,
                    border: '1px solid #262B40',
                  }}
                >
                  <FontAwesomeIcon icon={faSliders} />
                  &nbsp;&nbsp;{t('Apply Filter')}
                </Button>
              </div>
            </Form.Group>
          </div>
        </div>
        {/* {!openFilter &&
          (type[0] != '' ||
            store[0] != '' ||
            city[0] != '' ||
            region[0] != '') && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div
                className="gap-2"
                style={{
                  display: 'flex',
                  width: '100%',
                  background: '#F5F8FB',
                  padding: 20,
                }}
              >
                {filterObj.map((each) => {
                  if (each.value != '') {
                    return (
                      <div>
                        <Form.Label style={{ color: '#262B4099' }}>
                          {each.label}
                        </Form.Label>
                        <Badge
                          variant="light"
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: '188px',
                            height: '41px',
                            background: 'white',
                            borderRadius: '2px',
                            cursor: 'pointer',
                            color: '#262B40',
                            fontSize: '14px',
                            fontWeight: '400',
                            lineHeight: '16.41px',
                          }}
                        >
                          {each?.value || ''}
                          <span
                            onClick={() => handleEmpty(each.func)}
                            style={{
                              color: '#262B40',
                              fontSize: '14px',
                              fontWeight: '400',
                              lineHeight: '16.41px',
                            }}
                          >
                            &times;
                          </span>
                        </Badge>
                      </div>
                    );
                  }
                })}
              </div>
              <Button
                variant="primary"
                className="fa-export"
                onClick={handleReset}
                style={{
                  width: '150px',
                  height: '38px',
                  borderRadius: 0,
                }}
              >
                <FontAwesomeIcon icon={faBroom} />
                &nbsp;&nbsp;{t('Clear')}
              </Button>
            </div>
          )} */}
      </>
      <div className="d-flex justify-content-between gap-3">
        <div
          style={{
            width: '40%',
            display: 'grid',
            gridTemplateColumns: 'repeat(2, minMax(200px,1fr))',
            gridGap: '10px',
            boxSizing: 'border-box',
          }}
        >
          {[
            'totalOrders',
            'pendingOrders',
            'completedOrders',
            'returnOrders',
          ].map((orderType) => (
            <div
              key={orderType}
              className="d-flex flex-column justify-content-between bg-white p-3 card"
              style={getCardStyle(orderType)}
              id={orderType}
              onClick={(e) => {
                e.stopPropagation();
                handleCardClick(e);
              }}
            >
              <div className="d-flex justify-content-between">
                <div>
                  <p className="mb-1 fw-medium" style={{ fontSize: '1rem' }}>
                    {t(orderType.replace(/([A-Z])/g, ' $1').toUpperCase())}
                  </p>
                  <p className="fw-medium fs-4">
                    {t(
                      Object.keys(response).length > 0
                        ? response['overAll'][orderType]
                        : 0
                    )}
                  </p>
                </div>
                <div>
                  <FontAwesomeIcon
                    color="rgba(233, 30, 35, 1)"
                    icon={
                      orderType === 'totalOrders'
                        ? faClipboardList
                        : orderType === 'pendingOrders'
                        ? faClipboardQuestion
                        : orderType === 'completedOrders'
                        ? faClipboardCheck
                        : faClipboardUser
                    }
                    size="lg"
                  />
                </div>
              </div>
              <div style={{ fontSize: '0.75rem', fontWeight: '400' }}>
                <span
                  style={{
                    color: response['overAll']?.[`${orderType}Performance`]
                      ? response['overAll'][`${orderType}Performance`].includes(
                          '+'
                        )
                        ? 'rgba(46, 187, 143, 1)'
                        : 'rgba(233, 30, 35, 1)'
                      : 'rgba(0, 0, 0, 0.5)',
                  }}
                >
                  <FontAwesomeIcon
                    icon={
                      response['overAll']?.[`${orderType}Performance`]
                        ? response['overAll'][
                            `${orderType}Performance`
                          ].includes('+')
                          ? faArrowUp
                          : faArrowDown
                        : faMinus
                    }
                  />
                  &nbsp;
                  {response['overAll']?.[`${orderType}Performance`]
                    ? `${response['overAll']?.[`${orderType}Performance`]}%`
                    : `0%`}
                </span>
                &nbsp;&nbsp;{t(`compared to last ${type}`)}
              </div>
            </div>
          ))}
        </div>
        <div
          style={{
            flex: 1,
            height: '100%',
            width: '100%',
            padding: '10px',
            backgroundColor: 'white',
          }}
        >
          {Object.keys(response).length > 0 && (
            <BarGraph
              labels={getGraphConfig().label}
              dataset={getGraphConfig().dataset}
              options={getGraphConfig().options}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Analytics;
