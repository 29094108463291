import {
    SET_WMS_USERS_LIST_IN_STORE,
    SET_WMS_USER_LIST_AS_SELECTED
} from "../types";

export const setWMSUserListInStore = (data) => {
    return {
        type: SET_WMS_USERS_LIST_IN_STORE,
        data
    }
}
export const setWMSUserAsSelected = (data) => {
    return {
        type: SET_WMS_USER_LIST_AS_SELECTED,
        data
    }
}