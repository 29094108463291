import {
  RESET_STORE,
  SET_EXCEL_PRODUCT_UPLOAD_DATA_IN_STORE,
  SET_EXCEL_MISMATCHED_PRODUCT_UPLOAD_DATA_IN_STORE,
  SET_SHOW_MISMATCH_PRODUCTS_MODAL,
} from '../../actions/types';

const INITIAL_STATE = {
  uploadedData: [],
  mismatchedData: [],
  showModal: false,
};
const productsExcelUpload = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_EXCEL_PRODUCT_UPLOAD_DATA_IN_STORE: {
      return {
        ...state,
        uploadedData: action.data,
      };
    }
    case SET_EXCEL_MISMATCHED_PRODUCT_UPLOAD_DATA_IN_STORE: {
      return {
        ...state,
        mismatchedData: action.data,
      };
    }
    case SET_SHOW_MISMATCH_PRODUCTS_MODAL: {
      return {
        ...state,
        showModal: action.data,
      };
    }
    case RESET_STORE: {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
};

export default productsExcelUpload;
