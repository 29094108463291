import React, { useState } from 'react';
import { Button, Modal, Form, Row, Col } from '@themesberg/react-bootstrap';
import EditableDropDown from '../../../components/common/EditableDropdown';

const SwipeMachineModal = ({
  showModal,
  closeModal,
  swipeMachineTypeOptions,
  swipeTypeOptions,
  selectedId,
  handleSubmit,
}) => {
  const [swipeMachineType, setSwipeMachineType] = useState('');
  const [swipeType, setSwipeType] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [approvalCode, setApprovalCode] = useState('');

  const handleCancel = () => {
    // Reset form fields
    setSwipeMachineType('');
    setSwipeType('');
    setCardNumber('');
    setApprovalCode('');
    closeModal();
  };

  const handleClicked = async (data) => {
    try {
      await handleSubmit(data);
      setSwipeMachineType('');
      setSwipeType('');
      setCardNumber('');
      setApprovalCode('');
    } catch (error) {}
  };

  return (
    <Modal centered show={showModal} onHide={closeModal} size="lg">
      <Modal.Header closeButton>
        <Modal.Title style={{ fontSize: '1.5rem' }}>
          Swipe Machine Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row className="mb-4">
            {/* Swipe Machine Type Dropdown */}
            <Col md={6}>
              <Form.Group controlId="swipeMachineType">
                <div>
                  <Form.Label>Swipe Machine Type</Form.Label>
                  <EditableDropDown
                    id={'SwipeMachineType'}
                    onChange={(e) => setSwipeMachineType(e.value)}
                    style={{
                      height: 38,
                      width: '100%',
                      borderRadius: 0,
                    }}
                    value={swipeMachineType}
                    options={swipeMachineTypeOptions}
                    placeholder={'Choose the Current Status'}
                  />
                </div>
              </Form.Group>
            </Col>

            {/* Swipe Type Dropdown */}
            <Col md={6}>
              <Form.Group controlId="swipeType">
                <div>
                  <Form.Label>Swipe Type</Form.Label>
                  <EditableDropDown
                    id={'SwipeType'}
                    onChange={(e) => setSwipeType(e.value)}
                    style={{
                      height: 38,
                      width: '100%',
                      borderRadius: 0,
                    }}
                    value={swipeType}
                    options={swipeTypeOptions}
                    placeholder={'Choose the Current Status'}
                  />
                </div>
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-4">
            {/* Card Number Text Field */}
            <Col md={6}>
              <Form.Group controlId="cardNumber">
                <Form.Label>Card Number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter card number"
                  value={cardNumber}
                  onChange={(e) => setCardNumber(e.target.value)}
                  style={{
                    height: '50px',
                    fontSize: '1.1rem',
                    padding: '10px',
                  }}
                />
              </Form.Group>
            </Col>

            {/* Order Type Text Field */}
            <Col md={6}>
              <Form.Group controlId="approvalCode">
                <Form.Label>Approval Code</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Approval Code"
                  value={approvalCode}
                  onChange={(e) => setApprovalCode(e.target.value)}
                  style={{
                    height: '50px',
                    fontSize: '1.1rem',
                    padding: '10px',
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={handleCancel}
          style={{
            fontSize: '1.2rem',
            padding: '10px 30px',
          }}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            handleClicked({
              id: selectedId,
              cardNumber,
              swipeMachineType,
              swipeType,
              approvalCode,
            });
          }}
          style={{
            fontSize: '1.2rem',
            padding: '10px 30px',
          }}
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SwipeMachineModal;
