import Parse from 'parse';

export const registerVehicleEntry = (data) => {
  return Parse.Cloud.run('registerVehicleEntry', data);
};

export const getEntryRegisteredVehicles = (data) => {
  return Parse.Cloud.run('getEntryRegisteredVehicles', data);
};

export const registerVehicleExit = (data) => {
  return Parse.Cloud.run('registerVehicleExit', data);
};

export const getEntryByVehicleNumber = (data) => {
  return Parse.Cloud.run('getEntryByVehicleNumber', data);
};
