import { Button, Card, Col, Form, Row } from '@themesberg/react-bootstrap';
import React, { useEffect } from 'react';
import ToggleSwitch from '../common/ToggleSwitch';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from '../StyledComponents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faSync, faXmark } from '@fortawesome/free-solid-svg-icons';
import EditableDropDown from '../../../../components/common/EditableDropdown';
import { arrayToDropdownOptions } from '../../../../utils/sms/arrayToDropdownOptions';
import { convertProductsToDropdownOptions } from '../../../../utils/sms/convertProductsToDropdownOptions';

const AddProductDetails = (props) => {
  const {
    formInput,
    setFormInput,
    formProps,
    setFormProps,
    formErrors,
    productOptions,
    categoryOptions,
    fetchProducts,
    subCategoryOptions,
    setCategoryOptions,
  } = props;
  const { t } = useTranslation();

  const handleChange = (checked, field) => {
    setFormProps((prev) => ({
      ...prev,
      [field]: checked,
    }));
  };

  const handleInputChange = (e, field, targetIndex) => {
    let value = '';
    if (field === 'payout' || field === 'cappingQuantity') {
      value = e.target.value;
    } else {
      value = [e.value];
    }

    setFormInput((prev) => ({
      ...prev,
      products: prev.products?.map((item, index) => {
        if (index === targetIndex) {
          return {
            ...item,
            [field]: value,
          };
        }
        return item;
      }),
    }));
  };

  const handleDelete = (targetIndex) => {
    if (formInput.products.length === 1) {
      return;
    }
    setFormInput((prev) => ({
      ...prev,
      products: prev.products.filter((item, index) => index !== targetIndex),
    }));
  };

  const getUniqueProductSubCategories = (
    value,
    subCategory,
    targetSubCategory,
    key
  ) => {
    const filteredOptions = subCategoryOptions.reduce((acc, item) => {
      if (
        value.includes(item[subCategory]) &&
        !acc.includes(item[targetSubCategory])
      ) {
        item[targetSubCategory] && acc.push(item[targetSubCategory]);
      }
      return acc;
    }, []);
    setCategoryOptions((prev) => ({
      ...prev,
      [key]: arrayToDropdownOptions(filteredOptions),
    }));
    return filteredOptions;
  };

  const handleAddProduct = () => {
    setFormInput((prev) => {
      const lastProduct = prev?.products?.[prev?.products.length - 1];
      return {
        ...prev,
        products: [
          ...prev.products,
          {
            productName: [],
            cappingType:
              formProps.copyCappingType && lastProduct
                ? lastProduct.cappingType
                : '',
            valuationType:
              formProps.copyValuationType && lastProduct
                ? lastProduct.valuationType
                : '',
            payout:
              formProps.copyPayoutValue && lastProduct
                ? lastProduct.payout
                : '',
          },
        ],
      };
    });
  };

  return (
    <div>
      <Card className="p-2">
        <div className="d-flex flex-row">
          <div className="w-50  p-2">
            <Form.Group className="mb-2">
              <Form.Label>{t('Category*')}</Form.Label>
              <EditableDropDown
                id={'category'}
                onChange={(e) =>
                  setFormInput((prev) => ({
                    ...prev,
                    category: e.map(({ value }) => value),
                  }))
                }
                value={formInput['category']}
                options={categoryOptions.categoryOptions}
                placeholder={'Choose Category/Categories'}
                isInvalid={formErrors['category']}
                isMultiSelect={true}
                backgroundColor="#F0F0F0"
                optionColor="#FFFFFF"
              ></EditableDropDown>
              <ErrorMessage>{formErrors['category']}</ErrorMessage>
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label>{t('Sub Category-1')}</Form.Label>
              <EditableDropDown
                id={'subCategory'}
                onChange={(e) => {
                  const value = e.map(({ value }) => value);
                  const filteredOptions = getUniqueProductSubCategories(
                    value,
                    'productSubCategory1',
                    'productSubCategory2',
                    'subCategory2Options'
                  );
                  setFormInput((prev) => ({
                    ...prev,
                    subCategory: value,
                    subCategory2: prev.subCategory2?.filter((item) =>
                      filteredOptions.includes(item)
                    ),
                  }));
                }}
                value={formInput['subCategory']}
                options={categoryOptions.subCategory1Options}
                placeholder={'Choose Sub Category-1'}
                isInvalid={formErrors['subCategory']}
                isMultiSelect={true}
                backgroundColor="#F0F0F0"
                optionColor="#FFFFFF"
              ></EditableDropDown>
              <ErrorMessage>{formErrors['subCategory']}</ErrorMessage>
            </Form.Group>
          </div>
          <div className="w-50  p-2">
            <Form.Group className="mb-2">
              <Form.Label>{t('Sub Category-2')}</Form.Label>
              <EditableDropDown
                id={'subCategory2'}
                onChange={(e) => {
                  const value = e.map(({ value }) => value);
                  const filteredOptions = getUniqueProductSubCategories(
                    value,
                    'productSubCategory2',
                    'productSubCategory3',
                    'subCategory3Options'
                  );
                  setFormInput((prev) => ({
                    ...prev,
                    subCategory2: value,
                    subCategory3: prev.subCategory3?.filter((item) =>
                      filteredOptions.includes(item)
                    ),
                  }));
                }}
                value={formInput['subCategory2']}
                options={categoryOptions.subCategory2Options}
                placeholder={'Choose Sub Category-2'}
                isInvalid={formErrors['subCategory2']}
                isMultiSelect={true}
                backgroundColor="#F0F0F0"
                optionColor="#FFFFFF"
              ></EditableDropDown>
              <ErrorMessage>{formErrors['subCategory2']}</ErrorMessage>
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label>{t('Sub Category-3')}</Form.Label>
              <EditableDropDown
                id={'subCategory3'}
                onChange={(e) =>
                  setFormInput((prev) => ({
                    ...prev,
                    subCategory3: e.map(({ value }) => value),
                  }))
                }
                value={formInput['subCategory3']}
                options={categoryOptions.subCategory3Options}
                placeholder={'Choose Sub Category-3'}
                isInvalid={formErrors['subCategory3']}
                isMultiSelect={true}
                backgroundColor="#F0F0F0"
                optionColor="#FFFFFF"
              ></EditableDropDown>
              <ErrorMessage>{formErrors['subCategory3']}</ErrorMessage>
            </Form.Group>
          </div>
        </div>
        <div className="d-flex justify-content-end">
          <Button
            style={{
              backgroundColor: '#F0F0F0',
              border: 'none',
              color: 'black',
            }}
            onClick={fetchProducts}
          >
            <FontAwesomeIcon icon={faSync} /> &nbsp;&nbsp; Fetch Products
          </Button>
        </div>
      </Card>
      {formInput.type?.[0] === 'Sell-out' && (
        <div className="d-flex justify-content-end">
          <ToggleSwitch
            type={'secondary'}
            input={formProps.showCappingType}
            setInput={(checked) => handleChange(checked, 'showCappingType')}
            name={'Add Capping Type'}
          />
        </div>
      )}
      <Card className="p-2 mt-2">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center gap-3 p-2">
            <Form.Label>Payout Basis*</Form.Label>
            <Form.Group>
              <EditableDropDown
                id={'payoutBasis'}
                onChange={(e) =>
                  setFormInput((prev) => ({
                    ...prev,
                    payoutBasis: [e.value],
                  }))
                }
                value={formInput['payoutBasis']}
                isInvalid={formErrors['payoutBasis']}
                options={[
                  { label: 'DP', value: 'DP' },
                  { label: 'DP(Basic)', value: 'DP(Basic)' },
                  { label: 'Net of Margin', value: 'Net of Margin' },
                  { label: 'NLC(Basic)', value: 'NLC(Basic)' },
                ]}
                placeholder={'Choose Payout Basis'}
                backgroundColor="#F0F0F0"
              ></EditableDropDown>
              <ErrorMessage>{formErrors['payoutBasis']}</ErrorMessage>
            </Form.Group>
          </div>
          <div className="d-flex gap-3">
            {formProps.showCappingType && (
              <div className="d-flex align-items-center gap-3 p-2">
                <Form.Label className="mb-0">Copy Capping Type</Form.Label>
                <Form.Group>
                  <Form.Check
                    type="checkbox"
                    id={'copyCappingType'}
                    checked={formProps.copyCappingType}
                    onChange={(e) =>
                      handleChange(e.target.checked, 'copyCappingType')
                    }
                    className="custom-checkbox"
                  />
                </Form.Group>
              </div>
            )}

            <div className="d-flex align-items-center gap-3 p-2">
              <Form.Label className="mb-0">Copy Payout Value</Form.Label>
              <Form.Group>
                <Form.Check
                  type="checkbox"
                  id={'copyPayoutValue'}
                  checked={formProps.copyPayoutValue}
                  onChange={(e) =>
                    handleChange(e.target.checked, 'copyPayoutValue')
                  }
                  className="custom-checkbox"
                />
              </Form.Group>
            </div>
            <div className="d-flex align-items-center gap-3 p-2">
              <Form.Label className="mb-0">Copy Valuation Type</Form.Label>
              <Form.Group>
                <Form.Check
                  type="checkbox"
                  id={'copyValuationType'}
                  checked={formProps.copyValuationType}
                  onChange={(e) =>
                    handleChange(e.target.checked, 'copyValuationType')
                  }
                  className="custom-checkbox"
                />
              </Form.Group>
            </div>
          </div>
        </div>
      </Card>
      <Card className="p-2 mt-2">
        <Row className="p-2">
          <Col md={formProps.showCappingType ? 2 : 3}>Product*</Col>
          <Col md={formProps.showCappingType ? 2 : 3}>Valuation Type*</Col>
          <Col md={formProps.showCappingType ? 2 : 3}>
            Payout (% or Per Unit)*
          </Col>
          {formProps.showCappingType && formInput?.type?.[0] === 'Sell-out' && (
            <>
              <Col md={2}>Capping Type</Col>
              <Col md={2}>Amount/Quantity</Col>
            </>
          )}
        </Row>
        {formInput?.products?.map((item, index) => (
          <Row key={index} className="d-flex align-items-center mt-2 p-2">
            <Col md={formProps.showCappingType ? 2 : 3}>
              <Form.Group>
                <EditableDropDown
                  id={'productName'}
                  onChange={(e) => handleInputChange(e, 'productName', index)}
                  value={item.productName}
                  options={productOptions}
                  isInvalid={formErrors?.products?.[index]?.productName}
                  backgroundColor={'#F0F0F0'}
                ></EditableDropDown>
                <ErrorMessage>
                  {formErrors?.products?.[index]?.productName}
                </ErrorMessage>
              </Form.Group>
            </Col>
            <Col md={formProps.showCappingType ? 2 : 3}>
              <Form.Group>
                <EditableDropDown
                  id={'valuationType'}
                  onChange={(e) => handleInputChange(e, 'valuationType', index)}
                  value={item.valuationType}
                  options={[
                    { label: 'percentage', value: 'percentage' },
                    { label: 'value', value: 'value' },
                  ]}
                  isInvalid={formErrors?.products?.[index]?.valuationType}
                  backgroundColor={'#F0F0F0'}
                ></EditableDropDown>
                <ErrorMessage>
                  {formErrors?.products?.[index]?.valuationType}
                </ErrorMessage>
              </Form.Group>
            </Col>
            <Col md={formProps.showCappingType ? 2 : 3}>
              <Form.Group>
                <Form.Control
                  type="number"
                  value={item.payout}
                  onChange={(e) => handleInputChange(e, 'payout', index)}
                  style={{ backgroundColor: '#F0F0F0' }}
                  placeholder="Enter Payout"
                  isInvalid={formErrors?.products?.[index]?.payout}
                />
                <ErrorMessage>
                  {formErrors?.products?.[index]?.payout}
                </ErrorMessage>
              </Form.Group>
            </Col>
            {formProps.showCappingType &&
              formInput.type?.[0] === 'Sell-out' && (
                <>
                  <Col md={2}>
                    <Form.Group>
                      <EditableDropDown
                        id={'cappingType'}
                        onChange={(e) =>
                          handleInputChange(e, 'cappingType', index)
                        }
                        value={item.cappingType}
                        options={[
                          { label: 'Quantity', value: 'Quantity' },
                          { label: 'Revenue', value: 'Revenue' },
                        ]}
                        isInvalid={formErrors?.products?.[index]?.cappingType}
                        backgroundColor={'#F0F0F0'}
                        optionColor="#FFFFFF"
                      ></EditableDropDown>
                      <ErrorMessage>
                        {formErrors?.products?.[index]?.cappingType}
                      </ErrorMessage>
                    </Form.Group>
                  </Col>
                  <Col md={2}>
                    <Form.Group>
                      <Form.Control
                        type="number"
                        value={item.cappingQuantity}
                        onChange={(e) =>
                          handleInputChange(e, 'cappingQuantity', index)
                        }
                        style={{ backgroundColor: '#F0F0F0' }}
                        placeholder="Enter Payout"
                        isInvalid={
                          formErrors?.products?.[index]?.cappingQuantity
                        }
                      />
                      <ErrorMessage>
                        {formErrors?.products?.[index]?.cappingQuantity}
                      </ErrorMessage>
                    </Form.Group>
                  </Col>
                </>
              )}
            <Col style={{ height: '47.5px' }}>
              <Button
                style={{
                  backgroundColor: '#F0F0F0',
                  border: 'none',
                  height: '43.50px',
                }}
                onClick={() => handleDelete(index)}
              >
                <FontAwesomeIcon icon={faXmark} style={{ color: 'black' }} />
              </Button>
            </Col>
          </Row>
        ))}
        <div className="d-flex justify-content-end mb-4">
          <Button
            style={{
              backgroundColor: '#F0F0F0',
              border: 'none',
              color: 'black',
            }}
            onClick={handleAddProduct}
          >
            <FontAwesomeIcon icon={faAdd} /> &nbsp;&nbsp; Add Product
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default AddProductDetails;
