import { Button } from "@themesberg/react-bootstrap";
import React from "react";
import { useTranslation } from "react-i18next";

const DisabledUploadSheet = () => {
    const { t } = useTranslation();
    return (
        <div
            style={{
                height: 80
            }}
            className="flex-row dropzone h-100 rounded d-flex align-items-center justify-content-center mb-4"
        >
            <div className="dz-default dz-message text-center">
                <p className="dz-button mb-0">
                    {t("Drop File here")}
                </p>
            </div>&nbsp;&nbsp;
            <div className="dz-default dz-message text-center">
                <p className="dz-button mb-0">
                    {t("or")}
                </p>
            </div>&nbsp;&nbsp;

            <Button disabled variant="light" className="m-1">&nbsp;{t("Select Display Sheet")}</Button>
        </div>
    )
}
export default DisabledUploadSheet;