import Parse from "parse";

export const addNewAccountsUser = async (data) => {
  return Parse.Cloud.run("addAccountManager", data);
};
export const addNewBrandManagerUser = async (data) => {
  return Parse.Cloud.run("addBrandManager", data);
};
export const addNewBrandVendorUser = async (data) => {
  return Parse.Cloud.run("addBrandVendor", data);
};

export const updateUsers = async (data) => {
  return Parse.Cloud.run("updateUsers", data);
};

export const updateUserStatus = async (data) => {
  return Parse.Cloud.run("updateUserActiveState", data);
};
