import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { returnProductInfo } from '../../../../utils/sms/tableUtils';
import { getTableRowCheckBox } from '../../../../utils/table';
import { returnUserReadableDateTime } from '../../../../utils/datetime';
import { getTripBadgeStatus } from '../../../../utils/trips';
import { Badge } from '@themesberg/react-bootstrap';
import { returnOrderStatus } from '../../../../constants/orderStatus';

export const returnOrderColumns = (
  onRowSelect,
  navigate,
  bulkActionOptions,
  isAdmin,
  type
) => {
  const columns = [
    {
      dataField: 'productTitle',
      text: 'Product',
      formatter: (cell, row) => {
        return returnProductInfo([cell]);
      },
    },
    {
      dataField: 'orderNo',
      text: 'order No',
      sort: true,
    },
    {
      dataField: 'vanNo',
      text: 'vanno',
    },
    {
      dataField: 'quantity',
      text: 'Quantity',
    },
    {
      dataField: 'updatedAt',
      text: 'last update',
      sort: true,
      formatter: (cell, row) => {
        return returnUserReadableDateTime(cell);
      },
    },
    {
      dataField: 'view',
      text: 'View',
      formatter: (cell, row) => {
        return (
          <FontAwesomeIcon
            icon={faArrowRight}
            style={{ cursor: 'pointer' }}
            onClick={() => navigate(`/oms/orders/${row.objectId}`)}
          />
        );
      },
    },
  ];
  if (bulkActionOptions) {
    columns.splice(0, 0, {
      dataField: 'objectId',
      text: 'Select',
      formatter: (cell, row) => {
        return getTableRowCheckBox(onRowSelect, cell, row?.isSelected, false);
      },
    });
  }

  if (isAdmin) {
    columns.splice(2, 0, {
      dataField: 'storeCode',
      text: 'Store Name',
    });
  }
  if (
    type !== returnOrderStatus.REFUNDED &&
    type !== returnOrderStatus.REFUND_INITIATED
  ) {
    columns.splice(columns.length - 1, 0, {
      dataField: 'isReplacement',
      text: 'Request Type',
      formatter: (cell, row) => {
        const value =
          cell !== true
            ? row?.isRtoOrder
              ? 'rto return'
              : 'reverse'
            : 'replacement';
        const refunded = row?.isEarlyRefund && 'Refunded';
        return (
          <div className="d-flex flex-column gap-1">
            <Badge bg={getTripBadgeStatus(value)} className="badge-lg">
              {value}
            </Badge>
            {row?.isEarlyRefund && (
              <Badge bg={getTripBadgeStatus(refunded)} className="badge-lg">
                {row?.isEarlyRefund && 'Refunded'}
              </Badge>
            )}
          </div>
        );
      },
    });
  }
  return columns;
};

export const fileUploadForReturn = (column, rows) => {
  const JSonData = rows.map((each) => {
    const index = 0;
    const obj = {};
    each.forEach((element) => {
      obj[column[index]] = element;
    });
    return obj;
  });
};
