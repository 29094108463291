import React from 'react';
import { faChevronRight, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@themesberg/react-bootstrap';
import { returnUserReadableDate } from '../../../utils/datetime';
import { returnProductInfo } from '../../../utils/sms/tableUtils';
import StatusCard from './StatusCard';
import ActionButton from './components/ActionButton';
import { StyledSearchBar } from '../../trips/styles';
import { IBTStatuses } from './constants';

export const productsColumns = (handleXClick) => {
  const columns = [
    {
      dataField: 'vanNo',
      text: 'vanNo',
    },
    {
      dataField: 'productTitle',
      text: 'name',
    },
    {
      dataField: 'company',
      text: 'company',
    },
    {
      dataField: 'category',
      text: 'category',
    },
    {
      dataField: 'requestedQuantity',
      text: 'quantity',
    },
    {
      dataField: '',
      text: '',
      formatter: (cell, row) => {
        return (
          <Button
            onClick={(e) => {
              e.stopPropagation();
              handleXClick(row?.id);
            }}
            variant="white"
            className="d-flex justify-content-center align-content-center"
            style={{ height: '100%', margin: '0', padding: '0' }}
          >
            <FontAwesomeIcon icon={faXmark} size="xl" color="black" />
          </Button>
        );
      },
    },
  ];
  return columns;
};

export const receivedColumns = (
  handleShowMoreClick,
  statusMap,
  actionClicked
) => {
  const columns = [
    {
      dataField: 'requestingBranchName',
      text: 'Requesting Branch',
    },
    {
      dataField: 'createdAt',
      text: 'Request Date',
      formatter: (cell, row) => {
        return returnUserReadableDate(cell);
      },
    },
    {
      dataField: 'preferredDeliveryDate',
      text: 'Delivery Date',
      formatter: (cell, row) => {
        return returnUserReadableDate(cell);
      },
    },
    {
      dataField: 'products',
      text: 'Products',
      formatter: (cell, row) => {
        const allProducts = cell || [];

        // Always show the first product (if exists)
        const productsToShow =
          allProducts.length > 0 ? allProducts.slice(0, 1) : [];

        return (
          <>
            {productsToShow.map((product) => (
              <div className="d-block" key={product?.productTitle}>
                <span className="fw-bold">{product?.productTitle}</span>
                <br />
                <span className="fw-normal">{product?.company}</span>
                &nbsp;/&nbsp;
                <span className="fw-normal">{product?.category}</span>
                &nbsp;/&nbsp;
                <span className="fw-normal">
                  REQ Qty: {product?.requestedQuantity}
                </span>
                &nbsp;/&nbsp;
                <span className="fw-normal">
                  OUT Qty: {product?.receivedQuantity}
                </span>
                &nbsp;/&nbsp;
                <span className="fw-normal">{product?.vanNo}</span>
              </div>
            ))}
            <>
              {
                <Button
                  size="sm"
                  variant="light"
                  onClick={() => {
                    handleShowMoreClick(cell);
                    // console.log(`Showing modal for row: ${row?.objectId}`);
                  }}
                >
                  {allProducts.length === 1
                    ? 'View Product Details'
                    : `+ ${allProducts.length - 1} More Product(s)`}
                  &nbsp;&nbsp;
                  <FontAwesomeIcon icon={faChevronRight} />
                </Button>
              }
            </>
          </>
        );
      },
    },
    {
      dataField: 'requestingContactPerson',
      text: 'Requester Details',
      formatter: (cell, row) => {
        return (
          <div className="d-block">
            <div className="fw-bold">{row?.requestingContactPerson}</div>
            <div className="fw-bold">{row?.requestingContactNumber}</div>
          </div>
        );
      },
    },
    {
      dataField: 'totalQuantity',
      text: 'total Quantity',
    },
    {
      dataField: 'requesterRemarks',
      text: 'remarks',
    },
    {
      dataField: 'status',
      text: 'status',
      formatter: (cell, row) => {
        const status = statusMap[cell] || '';
        // console.log(status);

        return (
          <StatusCard
            status={cell}
            bgColor={status.bgColor}
            color={status.color}
            minWidth={status.minWidth}
            // height={status.height}
          />
        );
      },
    },
    {
      dataField: 'action',
      text: 'action',
      formatter: (cell, row) => {
        let options =
          row?.status === IBTStatuses.PENDING
            ? ['Accept', 'Partial Accept', 'Cancel']
            : ['Cancel', 'Assign Delivery Boy'];
        return (
          <>
            <ActionButton
              options={options}
              onActionClick={(option) => actionClicked(option, row?.objectId)}
            />
          </>
        );
      },
    },
  ];
  return columns;
};

export const infoModalColumns = (statusMap, isReceived = false) => {
  const columns = [
    {
      dataField: 'vanNo',
      text: 'vanno',
    },
    {
      dataField: 'productTitle',
      text: 'name',
      formatter: (cell, row) => {
        return returnProductInfo([cell]);
      },
    },
    {
      dataField: 'company',
      text: 'company',
    },
    {
      dataField: 'requestedQuantity',
      text: 'requested Quantity',
    },
    {
      dataField: 'receivedQuantity',
      text: isReceived ? 'Outgoing Quantity' : 'Incoming Quantity',
    },
    {
      dataField: 'status',
      text: 'subStatus',
      formatter: (cell, row) => {
        const status = statusMap[cell] || '';
        // console.log(status);

        return (
          <StatusCard
            status={cell}
            bgColor={status.bgColor}
            color={status.color}
            minWidth={status.minWidth}
            // height={status.height}
          />
        );
      },
    },
  ];
  return columns;
};

export const updateModalColumns = (isParital, setUpdatedProducts) => {
  const columns = [
    {
      dataField: 'vanNo',
      text: 'vanNo',
    },
    {
      dataField: 'productTitle',
      text: 'name',
    },
    {
      dataField: 'company',
      text: 'company',
    },
    {
      dataField: 'category',
      text: 'category',
    },
    {
      dataField: 'requestedQuantity',
      text: 'requested Quantity',
    },
    {
      dataField: 'receivedQuantity',
      text: 'Outgoing Quantity',
      formatter: (cell, row) => {
        // console.log(isParital);

        return isParital ? (
          <div>{row?.requestedQuantity}</div>
        ) : (
          <div>
            <StyledSearchBar
              width={'100px'}
              type="number"
              height={'25px'}
              placeholder={row?.receivedQuantity}
              onChange={(e) => {
                const { value } = e.target;
                setUpdatedProducts((prev) =>
                  prev.map((each) =>
                    each?.id === row?.id
                      ? { ...each, receivedQuantity: value }
                      : each
                  )
                );
              }}
            />
          </div>
        );
      },
    },
  ];
  return columns;
};

export const sentColumns = (handleShowMoreClick, statusMap, actionClicked) => {
  const columns = [
    {
      dataField: 'receivingBranchName',
      text: 'Fulfilling Branch',
    },
    {
      dataField: 'createdAt',
      text: 'Request Date',
      formatter: (cell, row) => {
        return returnUserReadableDate(cell);
      },
    },
    {
      dataField: 'preferredDeliveryDate',
      text: 'Delivery Date',
      formatter: (cell, row) => {
        return returnUserReadableDate(cell);
      },
    },
    {
      dataField: 'products',
      text: 'Products',
      formatter: (cell, row) => {
        const allProducts = cell || [];

        // Always show the first product (if exists)
        const productsToShow =
          allProducts.length > 0 ? allProducts.slice(0, 1) : [];

        return (
          <>
            {productsToShow.map((product) => (
              <div className="d-block" key={product?.productTitle}>
                <span className="fw-bold">{product?.productTitle}</span>
                <br />
                <span className="fw-normal">{product?.company}</span>
                &nbsp;/&nbsp;
                <span className="fw-normal">{product?.category}</span>
                &nbsp;/&nbsp;
                <span className="fw-normal">
                  REQ Qty: {product?.requestedQuantity}
                </span>
                &nbsp;/&nbsp;
                <span className="fw-normal">
                  INC Qty: {product?.receivedQuantity}
                </span>
                &nbsp;/&nbsp;
                <span className="fw-normal">{product?.vanNo}</span>
              </div>
            ))}
            <>
              {
                <Button
                  size="sm"
                  variant="light"
                  onClick={() => {
                    handleShowMoreClick(cell);
                    // console.log(`Showing modal for row: ${row?.objectId}`);
                  }}
                >
                  {allProducts.length === 1
                    ? 'View Product Details'
                    : `+ ${allProducts.length - 1} More Product(s)`}
                  &nbsp;&nbsp;
                  <FontAwesomeIcon icon={faChevronRight} />
                </Button>
              }
            </>
          </>
        );
      },
    },
    {
      dataField: 'senderContactPerson',
      text: 'Requester Details',
      formatter: (cell, row) => {
        return (
          <div className="d-block">
            <div className="fw-bold">{row?.senderContactPerson}</div>
            <div className="fw-bold">{row?.senderContactNumber}</div>
          </div>
        );
      },
    },
    {
      dataField: 'totalQuantity',
      text: 'total Quantity',
    },
    {
      dataField: 'senderRemarks',
      text: 'remarks',
    },
    {
      dataField: 'status',
      text: 'status',
      formatter: (cell, row) => {
        const status = statusMap[cell] || '';
        // console.log(status);

        return (
          <StatusCard
            status={cell}
            bgColor={status.bgColor}
            color={status.color}
            minWidth={status.minWidth}
            // height={status.height}
          />
        );
      },
    },
    {
      dataField: 'action',
      text: 'action',
      formatter: (cell, row) => {
        let actionNotNeeded = [
          IBTStatuses.INVOICED,
          IBTStatuses.DELIVERED,
          IBTStatuses.READY_FOR_PICKUP,
        ];
        let options = ['Cancel'];
        return (
          <>
            {actionNotNeeded.includes(row?.status) ? (
              <>{'-'}</>
            ) : (
              <ActionButton
                options={options}
                onActionClick={(option) => actionClicked(option, row?.objectId)}
              />
            )}
          </>
        );
      },
    },
  ];
  return columns;
};

export const assignedModalColumns = (statusMap, isReceived = false) => {
  const columns = [
    {
      dataField: 'driverName',
      text: 'Name',
    },
    {
      dataField: 'Mobile Number',
      text: 'phoneNumber',
    },
    {
      dataField: 'distance',
      text: 'Distance',
    },
    {
      dataField: 'assignedOrders',
      text: 'Assigned Orders',
    },
    {
      dataField: 'action',
      text: 'action',
      formatter: (cell, row) => {
        return <Button />;
      },
    },
  ];
  return columns;
};
