/* eslint-disable no-unused-expressions */
import { ADD_ORDERS_FOR_VEHICLE, MARK_ORDER_AS_SELECTED, MARK_TRIP_AS_CREATED, REMOVE_SELECTED_ORDERS_FROM_VEHICLE, RESET_STORE, SET_ACTIVE_VEHICLE_ID, SET_VEHICLE_TYPES_IN_STORE } from "../actions/types";

const INITIAL_STATE = {
    vehiclesList: {},
    activeVehicleId: null
};
export const VehicleReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_VEHICLE_TYPES_IN_STORE: {
            return {
                ...state,
                vehiclesList: action.data
            }
        }
        case SET_ACTIVE_VEHICLE_ID: {
            return {
                ...state,
                activeVehicleId: action.id
            }
        }
        case ADD_ORDERS_FOR_VEHICLE: {
            const {
                orders,
                vehicleId
            } = action.data;
            const vehicleList = state.vehiclesList;
            if (!vehicleList[vehicleId]?.orders) {
                vehicleList[vehicleId] = {
                    ...vehicleList[vehicleId],
                    orders: []
                }
            }
            vehicleList[vehicleId].orders = [...vehicleList[vehicleId].orders, ...JSON.parse(JSON.stringify(orders))];
            return {
                ...state,
                vehiclesList: vehicleList
            }
        }
        case MARK_TRIP_AS_CREATED: {
            const {
                vehiclesList,
                activeVehicleId
            } = state;
            const updatedVehicleList = {...vehiclesList};
            updatedVehicleList[activeVehicleId].tripCreated = true;
            return {
                ...state,
                vehiclesList: updatedVehicleList
            }
        }
        case MARK_ORDER_AS_SELECTED: {
            const {
                orderId,
                isSelected
            } = action.data;
            const {
                vehiclesList,
                activeVehicleId
            } = state;
            const updatedVehicleList = {...vehiclesList};
            updatedVehicleList[activeVehicleId]?.orders?.forEach((order) => {
                if (order?.objectId === orderId) {
                    order.isSelected = isSelected
                }
            });
            return {
                ...state,
                vehiclesList: updatedVehicleList
            }
        }
        case REMOVE_SELECTED_ORDERS_FROM_VEHICLE: {
            const {
                vehiclesList,
                activeVehicleId
            } = state;
            // const updatedVehicleList = {...vehiclesList};
            let ordersList = vehiclesList[activeVehicleId]?.orders;
            ordersList = ordersList.filter(order => !order.isSelected)
            return {
                ...state,
                vehiclesList: {
                    ...vehiclesList,
                    [activeVehicleId]: {
                        ...vehiclesList[activeVehicleId],
                        orders: ordersList
                    }
                }
            }
        }
        case RESET_STORE: {
            return INITIAL_STATE;
        }
        default: return state;
    }
};

export default VehicleReducer;