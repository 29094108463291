import React, { useState } from "react";
import Pagination from "react-bootstrap-table2-paginator";
import * as Paginator from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BreadCrumbComponent from "../../components/common/Breadcrumb";
import { Button, ButtonGroup, Card, Col, Row } from "@themesberg/react-bootstrap";
import Table from "react-bootstrap-table-next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { Routes } from "../../router/routes";
import { customSizePerPage, customTotal } from "../../components/common/parcelStatusPages";
import EmptyTable from "../../components/common/EmptyTableView";
import { faArrowRotateLeft, faCheckCircle, faHourglass, faRefresh } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { updateToastInfo } from "../../actions/settings";
import { updateSpinnerState } from "../../actions/spinner";
import { CounterWidget } from "../../components/Widgets";
import { returnRowBGColor } from "../../utils/parcels";
import ExcelDownloadReport from "../../components/common/excel/download";
import { returnDeliveryExecutiveTableColumns, returnPercentage } from "../analysis/analysisUtils";
import { returnPendingReportSummary } from "../../parse-functions/reports";
import DropdownComponent from "../../components/common/Dropdown";
import { returnZoneDropdownOptions } from "../../utils/zone";
import { returnWarehouseDropdownOptions } from "../../utils/warehouse";
import PendingOrderTableSummary from "./PendingOrderTableSummary";

const DeliveryAnalysisComponent = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [parcels, setParcels] = useState([]);
    const [totalParcels, setTotalParcels] = useState(0);
    const zonesInfo = useSelector((state) => state?.user?.config?.zones || {});
    const [selectedZone, setSelectedZone] = useState({});
    const otherWarehouses = useSelector((state) => state?.user?.otherWarehousesInZone || {});
    const [selectedWarehouse, setSelectedWarehouse] = useState("");
    const [parcelSummary, setParcelSummary] = useState({});
    const [APIResponse, setAPIResponse] = useState({});

    const breadCrumbItems = [{
        title: t("Executive Report"),
        link: Routes.ExecutiveReports.PendingDelivery.path
    }, {
        title: t("Pending Deliveries"),
        link: ""
    }];

    const handleParcelSearch = () => {
        if (!Object.keys(selectedZone)?.length) {
            dispatch(updateToastInfo({
                show: true,
                type: "danger",
                title: "Choose zone",
                message: "Please select a value from the dropdown"
            }))
            return;
        }
        getAvailableParcels();
    }
    const getAvailableParcels = async () => {
        dispatch(updateSpinnerState(true));
        try {
            const params = {
                zone: selectedZone?.id,
                storeName: selectedWarehouse?.value !== "all" ? selectedWarehouse?.value : ''
            }
            const response = await returnPendingReportSummary(params);
            let allParcels = [];
            // eslint-disable-next-line no-unused-expressions
            Object.keys(response?.data)?.forEach((storeName) => {
                // eslint-disable-next-line no-unused-expressions
                Object.keys(response?.data[storeName])?.forEach((category) => {
                    allParcels = allParcels.concat(response?.data[storeName][category]);
                });
            });
            setAPIResponse(response);
            setParcelSummary(response?.summary);
            setTotalParcels(allParcels?.length);
            setParcels(allParcels);
            dispatch(updateSpinnerState(false));
            dispatch(updateToastInfo({
                show: true,
                type: "success",
                title: t("Orders fetched"),
                message: t("You can check the orders for each type and status below")
            }))
        } catch (e) {
            dispatch(updateToastInfo({
                show: true,
                type: "danger",
                title: t("Fetching Orders failed"),
                message: t(e.message)
            }))
            dispatch(updateSpinnerState(false));
        }
    }

    const showBranchDetails = (branchName) => {
        let parcelsForStore = [];
        // eslint-disable-next-line no-unused-expressions
        Object.keys(APIResponse?.data?.[branchName])?.forEach(statusName => {
            parcelsForStore = parcelsForStore.concat(APIResponse?.data?.[branchName]?.[statusName]);
        });
        setParcels(parcelsForStore);
        const element = document.getElementById("parcelListTable");
        element.scrollIntoView({
            behavior: "smooth"
        });
    }
    const getWarehouseDropdownOptions = () => {
        let options = returnWarehouseDropdownOptions(otherWarehouses, true);
        if (selectedZone && Object.keys(selectedZone).length > 0) {
            options = options?.filter(option => option.data?.zone === selectedZone.id || option.value === "all");
        }
        return options;
    }
    return (
        <div>
            <ToolkitProvider
                exportCSV
                keyField="id"
                search={true}
                sort
                columns={returnDeliveryExecutiveTableColumns()}
                data={parcels}
                wrapperClasses="table-responsive"
            >
                {({ baseProps, searchProps, csvProps }) => (
                    <Paginator.PaginationProvider pagination={
                        Pagination({
                            custom: true,
                            showTotal: true,
                            alwaysShowAllBtns: true,
                            totalSize: totalParcels,
                            paginationTotalRenderer: customTotal,
                            sizePerPageRenderer: customSizePerPage
                        })
                    }>
                        {({ paginationProps, paginationTableProps }) => (
                            <>
                                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                                    <div className="d-block mb-4 mb-md-0">
                                        <BreadCrumbComponent
                                            items={breadCrumbItems}
                                        />
                                        <h4>{t("Pending Delivery Report")}</h4>
                                        <p className="mb-0">{t("This report highlights all the pending deliveries from all regions")}</p>
                                    </div>
                                    <div className="btn-toolbar mb-2 mb-md-0">
                                        <ButtonGroup>
                                            <Button
                                                onClick={() => {
                                                    handleParcelSearch();
                                                }}
                                                variant="outline-primary"
                                                size="sm">
                                                <FontAwesomeIcon icon={faRefresh} />&nbsp;&nbsp;{t("Refresh")}
                                            </Button>
                                            <ExcelDownloadReport
                                                data={Object.values(parcels) || []}
                                                fileName={"pending-deliveries"}
                                            />
                                        </ButtonGroup>
                                    </div>
                                </div>
                                <Row>
                                    <Col md={6}>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            marginBottom: 16
                                        }}>
                                            <DropdownComponent
                                                width={300}
                                                onChange={(value) => {
                                                    setSelectedZone(value);
                                                }}
                                                options={returnZoneDropdownOptions(zonesInfo)} />&nbsp;&nbsp;&nbsp;&nbsp;
                                            <DropdownComponent
                                                width={300}
                                                onChange={(option) => {
                                                    setSelectedWarehouse(option)
                                                }}
                                                options={getWarehouseDropdownOptions()}
                                                placeholder={t("Select Warehouse / Store Name")}
                                            />&nbsp;&nbsp;&nbsp;&nbsp;
                                            <Button
                                                size={'sm'}
                                                onClick={() => {
                                                    handleParcelSearch();
                                                }}
                                                variant="primary"
                                            >
                                                <FontAwesomeIcon icon={faRefresh} />&nbsp;&nbsp;{t("Fetch Data")}
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                                {(Object.keys(parcelSummary)?.length > 0) && (
                                    <Row>
                                        <Col xs={12} sm={6} xl={4} className="mb-4">
                                            <CounterWidget
                                                category="Pending"
                                                stage={"pending"}
                                                title={parcelSummary?.pending || 0}
                                                period={""}
                                                percentage={returnPercentage(parcelSummary.pending, totalParcels)}
                                                icon={faCheckCircle}
                                                iconColor="shape-secondary"
                                            />
                                        </Col>
                                        <Col xs={12} sm={6} xl={4} className="mb-4">
                                            <CounterWidget
                                                category="Failed"
                                                stage={"failed"}
                                                title={parcelSummary?.returnok || 0}
                                                period={""}
                                                percentage={returnPercentage(parcelSummary?.returnok, totalParcels)}
                                                icon={faHourglass}
                                                iconColor="shape-secondary"
                                            />
                                        </Col>
                                        <Col xs={12} sm={6} xl={4} className="mb-4">
                                            <CounterWidget
                                                category="Defective Collected"
                                                stage={"defectivepicked"}
                                                title={parcelSummary?.defectivepicked || 0}
                                                period={""}
                                                percentage={returnPercentage(parcelSummary?.defectivepicked, totalParcels)}
                                                icon={faArrowRotateLeft}
                                                iconColor="shape-secondary"
                                            />
                                        </Col>
                                    </Row>
                                )}
                                {/** Show the breakdown of individual store level information*/}
                                {(APIResponse?.data && Object.keys(APIResponse?.data)?.length > 0) && (
                                    <Card className="mb-4">
                                        <PendingOrderTableSummary branchData={APIResponse?.data} showBranchDetails={showBranchDetails} />
                                    </Card>
                                )}
                                <Card>
                                    <div className="table-responsive py-2">
                                        <Card.Header>
                                            <Row className="d-flex justify-content-between">
                                                <Col xs={8} md={6} className="d-flex py-1">
                                                    <div className="table-settings mt-2 mb-2">
                                                        <div style={{ gap: 8, display: "flex" }} />
                                                    </div>
                                                </Col>
                                                <Col xs={4} md={4} className="d-flex justify-content-md-end py-1">
                                                    <Search.SearchBar {...searchProps} placeholder={t("Search any value in table here")} />
                                                    <Search.ClearSearchButton {...searchProps} />
                                                </Col>
                                            </Row>
                                        </Card.Header>

                                        <Table
                                            id={"parcelListTable"}
                                            rowClasses={(row) => {
                                                const rowBGColor = returnRowBGColor(row?.dashboardStatus || row?.mobileStatus || row?.status);
                                                return `${rowBGColor} border-bottom`;
                                            }}
                                            noDataIndication={<EmptyTable />}
                                            {...baseProps}
                                            {...paginationTableProps}
                                            bodyClasses="border-0"
                                            classes="table-flush dataTable-table"
                                        />

                                        <Card.Footer>
                                            <Row>
                                                <Col xs={12} md={4} className="py-1">
                                                    <Paginator.SizePerPageDropdownStandalone {...paginationProps} paginationSize={5} sizePerPageList={[{ text: "5", value: 5 }]} />
                                                </Col>
                                                <Col xs={12} md={4} className="d-flex align-items-center py-1">
                                                    <Paginator.PaginationTotalStandalone {...paginationProps} />
                                                </Col>
                                                <Col xs={12} md={4} className="d-flex justify-content-md-end align-items-center mb-0 py-1">
                                                    <Paginator.PaginationListStandalone {...paginationProps} />
                                                </Col>
                                            </Row>
                                        </Card.Footer>
                                    </div>
                                </Card>
                            </>
                        )}
                    </Paginator.PaginationProvider>
                )}
            </ToolkitProvider>
        </div>
    )
}
export default DeliveryAnalysisComponent;