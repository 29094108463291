import React from "react";
import { Button, Modal } from "@themesberg/react-bootstrap";
import { generateRandomId } from "../../../../utils/string";
import { useTranslation } from "react-i18next";
import FormComponent from "../../../scheme-management/Components/FormComponent";
import { useDispatch } from "react-redux";

const EditStoreModal = (props) => {
  const { showModal, closeModal, handleConfirmClick, selectedWarehouseData } =
    props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    startTime,
    endTime,
    customerPickupAllowed,
    courrierPickupAllowed,
    deliveryAllowed,
    storeEnabled,
    onBoardedOn,
    latitude,
    longitude,
  } = selectedWarehouseData;

  const editData = {
    startTime,
    endTime,
    customerPickupAllowed,
    courrierPickupAllowed,
    deliveryAllowed,
    storeEnabled,
    onBoardedOn: [onBoardedOn],
    latitude,
    longitude,
  };

  const schema = [
    [
      {
        title: "StartTime",
        type: "time",
        id: "startTime",
      },
      {
        title: "EndTime",
        type: "time",
        id: "endTime",
      },
      {
        title: "Customer Pickup Allowed",
        type: "switch",
        id: "customerPickupAllowed",
        options: [
          {
            label: "yes",
            value: true,
          },
          {
            label: "no",
            value: false,
          },
        ],
      },

      {
        title: "Courrier Pickup Allowed",
        type: "switch",
        id: "courrierPickupAllowed",
        options: [
          {
            label: "yes",
            value: true,
          },
          {
            label: "no",
            value: false,
          },
        ],
      },
      {
        title: "Delivery allowed",
        type: "switch",
        id: "deliveryAllowed",
        options: [
          {
            label: "yes",
            value: true,
          },
          {
            label: "no",
            value: false,
          },
        ],
      },
      {
        title: "Store Enabled",
        type: "switch",
        id: "storeEnabled",
        options: [
          {
            label: "yes",
            value: true,
          },
          {
            label: "no",
            value: false,
          },
        ],
      },
    ],
    [
      {
        title: "OnBoarded On",
        type: "select",
        id: "onBoardedOn",
        options: [
          {
            label: "Wings",
            value: "wings",
          },
          {
            label: "WonderSoft",
            value: "wondersoft",
          },
        ],
      },
      {
        title: "Latitude",
        type: "number",
        id: "latitude",
        min: -90,
        max: 90,
      },
      {
        title: "Longitude",
        type: "number",
        id: "longitude",
        min: -180,
        max: 180,
      },
      {
        title: "submit",
        type: "button",
      },
    ],
  ];

  return (
    <>
      <Modal
        id={generateRandomId()}
        as={Modal.Dialog}
        centered
        show={showModal}
        onHide={closeModal}
        size="lg"
      >
        <Modal.Header>
          <Modal.Title className="h6">{t("Edit Store")}</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={closeModal} />
        </Modal.Header>
        <Modal.Body>
          <FormComponent
            schema={schema}
            savefunction={handleConfirmClick}
            editData={editData}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="link"
            className="text-gray ms-auto"
            onClick={closeModal}
          >
            {t("Cancel")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditStoreModal;
