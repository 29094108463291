import { faCubes, faFastForward, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Col, Row } from "@themesberg/react-bootstrap";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { updateToastInfo } from "../../actions/settings";
import { updateSpinnerState } from "../../actions/spinner";
import { markTripAsCreated } from "../../actions/vehicles";
import { saveTemporaryTrip } from "../../parse-functions/trips";
import DisabledUploadSheet from "./disabledUploadSheet";
import ScanInvoiceNumber from "./ScanInvoiceNumber";
import TemporaryTripDispatchModal from "./TemporaryTripDispatchModal";
import UploadDisplayTempoSheet from "./uploadDisplaySheet";

const VehicleAlgoResultCard = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [showTripModal, setShowTripModal] = useState(false);
    const warehouse = useSelector((state) => state.user.warehouse || {});
    const activeVehicleId = useSelector((state) => state.vehicles.activeVehicleId || "");
    const vehicle = useSelector((state) => state?.vehicles?.vehiclesList?.[activeVehicleId] || {});
    const [disableSaveButton, setDisableSaveButton] = useState(false);

    // const {
    //     orders = [],
    //     tripCreated = false
    // } = vehicle;
    const saveTripTemporary = () => {
        setShowTripModal(true);
    }
    const handleSaveTemporaryTemposheet = async (vehicleInfo) => {
        try {
            setDisableSaveButton(true);
            dispatch(updateSpinnerState(true));
            await saveTemporaryTrip({vehicle, vehicleInfo, warehouse});
            setShowTripModal(false);
            setDisableSaveButton(false);
            dispatch(markTripAsCreated());
            dispatch(updateToastInfo({
                show: true,
                type: 'success',
                title: 'Temporary Trip Created Successfully',
                message: 'Please Visit the trip page to take more action on this trip'
            }));
            dispatch(updateSpinnerState(false));
        } catch (err) {
            dispatch(updateToastInfo({
                show: true,
                type: 'danger',
                title: 'Temporary Trip Creation failed',
                message: 'Please try again or contact support'
            }))
            dispatch(updateSpinnerState(false));
        }
        
    }
    return (
        <Card border="light" className="shadow-sm mb-2">
            <Card.Body>
                <Row className="d-block d-flex align-items-start flex-row">
                    <Col className="d-flex align-items-center" md={12} sm={12} xl={12}>
                        <ScanInvoiceNumber />
                    </Col>
                    <Col className="d-flex align-items-center" md={6} sm={6} xl={6}>
                        <div className={`icon icon-shape icon-sm icon rounded me-4 me-sm-0`}>
                            <FontAwesomeIcon icon={faMapMarkerAlt} />
                        </div>
                        <h6 className="mb-0"> {t("Locations")} : {vehicle?.orders?.length || 0}</h6>
                    </Col>
                    <Col className="d-flex align-items-center" md={6} sm={6} xl={6}>
                        <div className={`icon icon-shape icon-sm icon rounded me-4 me-sm-0`}>
                            <FontAwesomeIcon icon={faCubes} />
                        </div>
                        <h6 className="mb-0"> {t("Orders Count")} : {vehicle?.orders?.length || 0}</h6>
                    </Col>
                    <Col className="mt-2">
                        {vehicle?.tripCreated ? (
                            <DisabledUploadSheet />
                        ) : (
                            <UploadDisplayTempoSheet tripCreated={vehicle?.tripCreated} />
                        )}
                        
                    </Col>
                </Row>
                {/* <Progress label="Volume Utilization" srOnly variant="secondary" size="lg" min={0} striped animated max={100} value={courierFlag ? 100 : Number(percentVolumeUtilized)} /> */}
                {/* <Progress label={"Weight Utilization"} variant="primary" size="lg" min={0} max={100} value={courierFlag ? 100 : Number(percentWeightUtilized)} /> */}
                <Row className="d-block d-flex align-items-center mt-4">
                    <Col className="d-block col d-flex justify-content-end">
                        <Button
                            onClick={saveTripTemporary}
                            variant={"primary"}
                            disabled={(vehicle.orders ? vehicle.orders?.length < 1 : true) || vehicle?.tripCreated}
                        >
                                <FontAwesomeIcon icon={faFastForward} />&nbsp;&nbsp;
                                {t("Save temporary")}
                        </Button>
                    </Col>
                </Row>
            </Card.Body>
            <TemporaryTripDispatchModal
                onSuccess={handleSaveTemporaryTemposheet}
                showModal={showTripModal}
                disableSaveButton={disableSaveButton}
                handleClose={setShowTripModal} />
        </Card>
    )
}
export default VehicleAlgoResultCard;