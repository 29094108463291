import React, { useState, useEffect } from 'react';
import {
  Card,
  Col,
  Form,
  Row,
  ButtonGroup,
  Button,
} from '@themesberg/react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Table from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';
import Pagination from 'react-bootstrap-table2-paginator';
import * as Paginator from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faDownload,
  faRefresh,
} from '@fortawesome/free-solid-svg-icons';
import { updateToastInfo } from '../../../actions/settings';
import BreadCrumbComponent from '../../../components/common/Breadcrumb';
import DateRangeSelector from '../../../components/common/DateRangeSelector';
import DropdownComponent from '../../../components/common/Dropdown';
import EmptyTable from '../EmptyTableView';
import { returnRowBGColor } from '../../../utils/parcels';
import ExcelDownloadReport from '../excel/download';
import FileUploadButton from '../fileUpload';
import { setPageNumberInStore } from '../../../actions/tablePage';

export const customTotal = (from, to, size) => (
  <div className="w-100 d-flex justify-content-center">
    Showing {from} to {to} of {size} entries
  </div>
);

export const customSizePerPage = (props) => {
  const { options, currentSizePerPage, onSizePerPageChange } = props;

  const onPageChange = (e) => {
    const page = e.target.value;
    onSizePerPageChange(page);
  };

  return (
    <Row>
      <Col xs="auto">
        <Form.Select
          value={currentSizePerPage}
          onChange={onPageChange}
          className="pe-5"
        >
          {options.map((o) => (
            <option key={o.page} value={o.page}>
              {o.text}
            </option>
          ))}
        </Form.Select>
      </Col>
      <Col xs="auto" className="d-flex align-items-center">
        entries per page
      </Col>
    </Row>
  );
};

const ParcelStatusPage = (props) => {
  const {
    pageTitle,
    breadCrumbItems,
    pageSubTitle,
    searchPlaceHolder,
    tableColumns = [],
    tableData,
    filterSubmit,
    handleRefresh,
    handleItemBulkUpdate,
    bulkActionOptions,
    enableBulkActions,
    hideBulkActions = false,
    showDateFilters = true,
    filtersValue,
    pageType,
    showFileUploadSection = false,
    onFileUpload,
    sampleFileUrl,
    showHeaders = true,
    addLocationForm,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [bulkActionSelectedOption, setBulkActionSelectedOption] =
    useState(null);
  const totalPages = Math.ceil((Object.keys(tableData)?.length || 0) / 10);
  const storedPage = useSelector((state) => state?.pageNumber?.[pageType] || 1);
  const [currentPage, setCurrentPage] = useState(
    storedPage > totalPages ? 1 : storedPage
  );
  const defaultCellFormatter = (cell) => {
    return cell || '-';
  };
  const formattedColumns = tableColumns.map((col) => ({
    ...col,
    formatter: col.formatter || defaultCellFormatter,
  }));

  const handlePageChange = (page) => {
    if (pageType) {
      dispatch(setPageNumberInStore({ id: pageType, page }));
    }
  };

  useEffect(() => {
    setCurrentPage(storedPage > totalPages ? 1 : storedPage);
  }, [storedPage, totalPages]);
  // useEffect(() => {
  //   setBulkActionSelectedOption(null); // Reset the bulk action option on component mount
  // }, [tableData]);

  const handleItemUpdate = () => {
    if (bulkActionSelectedOption) {
      handleItemBulkUpdate(bulkActionSelectedOption);
    } else {
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('Select an Action'),
          message: t('Please select an action from the dropdown'),
        })
      );
    }
  };

  const handleOnFileUpload = (fileContent) => {
    onFileUpload(fileContent);
  };

  return (
    <>
      <div>
        <ToolkitProvider
          exportCSV
          keyField="id"
          search={true}
          sort
          columns={formattedColumns}
          data={Object.values(tableData)}
          wrapperClasses="table-responsive"
        >
          {({ baseProps, searchProps, csvProps }) => (
            <Paginator.PaginationProvider
              pagination={Pagination({
                custom: true,
                showTotal: true,
                alwaysShowAllBtns: true,
                totalSize: Object.values(tableData)?.length,
                paginationTotalRenderer: customTotal,
                sizePerPageRenderer: customSizePerPage,
                onPageChange: handlePageChange,
                page: currentPage,
              })}
            >
              {({ paginationProps, paginationTableProps }) => (
                <>
                  {showHeaders && (
                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                      <div className="d-block mb-4 mb-md-0">
                        <BreadCrumbComponent items={breadCrumbItems} />
                        <h4>{pageTitle}</h4>
                        <p className="mb-0">{pageSubTitle}</p>
                      </div>
                      <div className="btn-toolbar mb-2 mb-md-0">
                        <ButtonGroup>
                          <Button
                            onClick={handleRefresh}
                            variant="outline-primary"
                            size="sm"
                          >
                            <FontAwesomeIcon icon={faRefresh} />
                            &nbsp;&nbsp;{t('Refresh')}
                          </Button>
                          <ExcelDownloadReport
                            data={Object.values(tableData) || []}
                            fileName={`${pageType}`}
                          />
                        </ButtonGroup>
                      </div>
                    </div>
                  )}
                  {showDateFilters && (
                    <div className="table-settings mt-2 mb-2">
                      <Row className="justify-content-start">
                        <Col xs={12} md={6}>
                          <DateRangeSelector
                            onSubmit={filterSubmit}
                            filtersValue={filtersValue}
                          />
                        </Col>
                      </Row>
                    </div>
                  )}
                  {showFileUploadSection && (
                    <Row className="d-flex justify-content-end">
                      <Col sm={6} className="d-flex justify-content-end">
                        {sampleFileUrl && (
                          <>
                            <Button
                              onClick={() => {
                                window.open(sampleFileUrl, '_blank');
                                dispatch(
                                  updateToastInfo({
                                    show: true,
                                    type: 'success',
                                    title: 'Sample File is downloaded',
                                    message:
                                      'Use this file to remove the data and add your own',
                                  })
                                );
                              }}
                              variant="primary-outline"
                            >
                              <FontAwesomeIcon icon={faDownload} />
                              &nbsp;&nbsp;Download Sample
                            </Button>
                            &nbsp;&nbsp;&nbsp;
                          </>
                        )}
                        <FileUploadButton
                          onFileUpload={handleOnFileUpload}
                          buttonText={'Upload Loader Sheet'}
                        />
                      </Col>
                    </Row>
                  )}
                  {addLocationForm && (
                    <Card className="mb-4 text-center">
                      <Card.Body>
                        <div className="d-flex justify-content-center">
                          <div>{addLocationForm}</div>
                        </div>
                      </Card.Body>
                    </Card>
                  )}
                  <Card>
                    <div className="table-responsive py-2">
                      <Card.Header>
                        <Row className="d-flex justify-content-between">
                          <Col xs={6} md={5} className="d-flex py-1">
                            {!hideBulkActions && (
                              <>
                                <Form.Group
                                  id="substatus"
                                  className="d-flex align-items-center flex-column"
                                >
                                  <DropdownComponent
                                    onChange={(option) => {
                                      setBulkActionSelectedOption(option.value);
                                    }}
                                    width={300}
                                    disabled={!enableBulkActions}
                                    options={bulkActionOptions}
                                    placeholder={t('Select Action')}
                                  />
                                </Form.Group>
                                <Button
                                  style={{
                                    height: 37,
                                  }}
                                  size="sm"
                                  onClick={handleItemUpdate}
                                  disabled={!enableBulkActions}
                                  className="ms-3 btn btn-secondary"
                                  variant="secondary"
                                >
                                  <FontAwesomeIcon icon={faCheck} />
                                  &nbsp;&nbsp;{t('Update')}
                                </Button>
                              </>
                            )}
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            className="d-flex justify-content-md-end py-1"
                          >
                            <Search.SearchBar
                              {...searchProps}
                              placeholder={
                                searchPlaceHolder ||
                                t('Search any value in table here')
                              }
                              onSearch={(value) => {
                                setCurrentPage(1);
                                searchProps.onSearch(value);
                              }}
                            />
                            <Search.ClearSearchButton {...searchProps} />
                          </Col>
                        </Row>
                      </Card.Header>
                      <Table
                        rowClasses={(row) => {
                          const rowBGColor = returnRowBGColor(
                            row?.dashboardStatus ||
                              row?.mobileStatus ||
                              row?.status
                          );
                          return `${rowBGColor} border-bottom`;
                        }}
                        noDataIndication={<EmptyTable />}
                        {...baseProps}
                        {...paginationTableProps}
                        bodyClasses="border-0"
                        classes="table-flush dataTable-table"
                        filter={filterFactory()}
                      />
                      <Card.Footer>
                        <Row>
                          <Col xs={12} md={4} className="py-1">
                            <Paginator.SizePerPageDropdownStandalone
                              {...paginationProps}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            className="d-flex align-items-center py-1"
                          >
                            <Paginator.PaginationTotalStandalone
                              {...paginationProps}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            className="d-flex justify-content-md-end align-items-center mb-0 py-1"
                          >
                            <Paginator.PaginationListStandalone
                              {...paginationProps}
                            />
                          </Col>
                        </Row>
                      </Card.Footer>
                    </div>
                  </Card>
                </>
              )}
            </Paginator.PaginationProvider>
          )}
        </ToolkitProvider>
      </div>
    </>
  );
};
export default ParcelStatusPage;
