import { RESET_STORE, UPDATE_TOAST_INFO } from "../actions/types";

const INITIAL_STATE = {
    toast: {}
};
const SettingsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case UPDATE_TOAST_INFO: {
            return {
                ...state,
                toast: action.data
            }
        }
        case RESET_STORE: {
            return INITIAL_STATE;
        }
        default: return state;
    }
};

export default SettingsReducer;