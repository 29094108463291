import Parse from "parse";

export const addNewWarehouse = async (data) => {
  return Parse.Cloud.run("addNewWarehouse", data);
};
export const getCurrentWarehouseInfo = async (data) => {
  return Parse.Cloud.run("getCurrentWarehouseInfo", data);
};
export const fetchWarehousesInZone = async (data) => {
  return Parse.Cloud.run("fetchWarehousesInZone", data);
};

export const editWarehouseInfo = async (data) => {
  return Parse.Cloud.run("editWarehouseInfo", data);
};
