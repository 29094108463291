import React, { useEffect } from 'react';
import { NovuProvider } from '@novu/notification-center';
import { useSelector } from 'react-redux';
import NotificationContent from '../../../../components/common/NotificationContent';
import { apiCall } from '../../../../middlewares/api';
import { apiCallConsts } from '../../../../constants/apiCallConsts';

function CustomNotification() {
  const userState = useSelector((state) => state?.user || {});
  const id = userState?.user?.objectId;
  const storeName = userState?.warehouse?.warehouseName || '';

  const handleNotificationClick = (message) => {
    if (message?.cta?.data?.url) {
      window.location.href = message.cta.data.url;
      // console.log(message);
    }
  };

  const updateSubscriberData = async () => {
    try {
      const res = await apiCall(
        apiCallConsts.PUT_METHOD,
        '/internal/updateSubscriber',
        { id, storeName }
      );
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (storeName) {
      updateSubscriberData();
    }
  }, [userState]);

  return (
    <>
      <NovuProvider
        subscriberId={id}
        applicationIdentifier={process.env.REACT_APP_NOVU_APP_ID}
      >
        <NotificationContent onNotificationClick={handleNotificationClick} />
      </NovuProvider>
    </>
  );
}

export default CustomNotification;
