import React, { useEffect, useState } from 'react';
import OrderListPage from '../../../components/common/OrderListPage';
import { orderTableColumns } from '../orders/utils';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import arrayToBreadCrumbs from '../../../utils/sms/arrayToBreadCrumbs';
import { updateSpinnerState } from '../../../actions/spinner';
import { apiCall } from '../../../middlewares/api';
import { apiCallConsts } from '../../../constants/apiCallConsts';
import { updateToastInfo } from '../../../actions/settings';
import { Routes } from '../../../router/routes';
import { returnEndOfDay, returnStartOfDay } from '../../../utils/datetime';
import { setFiltersInStore } from '../../../actions/filters';

const Completed = () => {
  const { t } = useTranslation();
  const pageType = 'Completed';
  const storeName = useSelector(
    (state) => state?.user?.preferences?.storeName || ''
  );
  const isAdmin = useSelector(
    (state) => state?.user?.preferences?.isAdmin || false
  );
  const filtersValue = useSelector((state) => state?.filters?.[pageType] || {});
  const [ordersData, setOrdersData] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const breadCrumbItems = arrayToBreadCrumbs([
    ['Customer Pickup', Routes.OMS.CustomerPickup.Pending.path],
    ['Completed'],
  ]);

  const messageDisplayer = (type, title, message) => {
    dispatch(
      updateToastInfo({
        show: true,
        type: type,
        title: t(title),
        message: t(message),
      })
    );
  };

  const handleFiltersSubmit = (startDate, endDate) => {
    dispatch(
      setFiltersInStore({
        pageType,
        data: {
          startDate: new Date(startDate),
          endDate: new Date(endDate),
        },
      })
    );
    fetchOrders(startDate, endDate);
  };

  const onRefreshClick = () => {
    const currentStartDate = filtersValue?.startDate
      ? new Date(filtersValue?.startDate)
      : returnStartOfDay(new Date());
    const currentEndDate = filtersValue?.endDate
      ? new Date(filtersValue?.endDate)
      : returnEndOfDay(new Date());
    fetchOrders(currentStartDate, currentEndDate);
  };

  const fetchOrders = async (startDate, endDate) => {
    try {
      dispatch(updateSpinnerState(true));
      const data = {
        storeCode: storeName,
        isCustomerPickup: true,
        status: pageType,
        startDate,
        endDate,
      };
      const res = await apiCall(
        apiCallConsts.GET_METHOD,
        apiCallConsts.ORDER_DATE_URL,
        data
      );
      setOrdersData(res);
      dispatch(updateSpinnerState(false));
      messageDisplayer('success', '', 'Orders fetched successfully');
    } catch (error) {
      dispatch(updateSpinnerState(false));
      messageDisplayer('danger', 'Failed to fetch Orders', error.message);
    }
  };

  return (
    <>
      <OrderListPage
        pageType={`${pageType} orders`}
        tableData={ordersData}
        tableColumns={orderTableColumns(null, navigate, null, isAdmin)}
        handleRefresh={onRefreshClick}
        showDateFilters={true}
        filterSubmit={handleFiltersSubmit}
        filtersValue={filtersValue}
        pageTitle={t(`${pageType} Orders`)}
        breadCrumbItems={breadCrumbItems}
        enableBulkActions={false}
        hideBulkActions={true}
      />
    </>
  );
};

export default Completed;
