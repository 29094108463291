import React from 'react';
import { getTripBadgeStatus } from '../../utils/trips';
import { returnUserReadableDateTime } from '../../utils/datetime';
import { Badge, Button } from '@themesberg/react-bootstrap';
import { getTableRowCheckBox } from '../../utils/table';

export const VouchersSentColumns = (
  onRowSelect,
  showItemsModal,
  isFinance = false
) => {
  const columns = [
    {
      dataField: 'objectId',
      text: 'Select',
      formatter: (cell, row) => {
        return getTableRowCheckBox(onRowSelect, cell, row?.isSelected, false);
      },
    },
    {
      dataField: 'voucherNumber',
      sort: true,
      text: 'Voucher No.',
      formatter: (cell, row) => {
        return (
          <div className="d-block">
            <div className="fw-bold">{cell}</div>
          </div>
        );
      },
    },
    {
      dataField: 'createdAt',
      text: 'Created Date',
      formatter: (cell, row) => {
        return returnUserReadableDateTime(cell);
      },
    },
    {
      dataField: 'tripsDetails',
      text: 'Trips',
      formatter: (cell, row) => {
        const allTrips = Object.values(row?.tripsDetails);
        const showMore = allTrips?.length > 1;
        const tripsToShow =
          allTrips?.length > 1 ? allTrips?.splice(0, 1) : allTrips;
        return (
          <>
            {tripsToShow?.map((trip) => {
              return (
                <div className="d-block">
                  <span className="fw-bold">{trip?.tripNumber}</span>
                  <br />
                  <span className="fw-normal">{trip.paymentType}</span>
                  &nbsp;/&nbsp;
                  <span className="fw-normal">{trip.amount}</span>
                </div>
              );
            })}
            <>
              {showMore && (
                <>
                  {`+ ${allTrips?.length} More Trip(s)`}&nbsp;&nbsp;
                  <Button
                    size="sm"
                    variant="outline-primary"
                    onClick={() => {
                      showItemsModal(row?.objectId);
                    }}
                  >
                    Show Details
                  </Button>
                </>
              )}
            </>
          </>
        );
      },
    },
    {
      dataField: 'totalAmount',
      text: 'total',
      formatter: (cell, row) => {
        return (
          <div className="d-block">
            <div className="fw-bold">{cell}</div>
          </div>
        );
      },
    },
    {
      dataField: 'status',
      text: 'Status',
      formatter: (cell, row) => {
        return (
          <div className="d-block">
            <div className="fw-bold">
              <span>
                &nbsp;&nbsp;
                <Badge
                  bg={getTripBadgeStatus(
                    row?.status === 'created' ? 'pending' : row?.status
                  )}
                  className="badge-lg"
                >
                  {cell}
                </Badge>
                &nbsp;&nbsp;
              </span>
            </div>
          </div>
        );
      },
    },
  ];

  if (isFinance) {
    columns.splice(1, 0, {
      dataField: 'createdByBranch',
      text: 'Created Branch',
      formatter: (cell, row) => {
        return <div className="d-block">{cell}</div>;
      },
    });
  }

  return columns;
};
