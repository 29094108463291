export const addDeliverySchema = [
  [
    {
      title: "Name",
      type: "text",
      id: "name",
      required: true,
    },

    {
      title: "Is Enabled",
      type: "switch",
      id: "isEnabled",
      options: [
        {
          label: "yes",
          value: true,
        },
        {
          label: "no",
          value: false,
        },
      ],
    },
    {
      title: "UserName",
      type: "text",
      id: "userName",
      required: true,
    },
    {
      title: "Password",
      type: "text",
      id: "password",
      required: true,
    },
    {
      title: "TokenGeneration",
      type: "textarea",
      rows: 3,
      id: "tokenGeneration",
      required: true,
    },
  ],
  [
    {
      title: "Tracking",
      type: "textarea",
      rows: 3,
      id: "tracking",
      required: true,
    },
    {
      title: "AwbGeneration",
      type: "textarea",
      rows: 3,
      id: "awbGeneration",
      required: true,
    },
    {
      title: "Ndr",
      type: "textarea",
      rows: 3,
      id: "ndr",
      required: true,
    },
    {
      title: "Serviceability",
      type: "textarea",
      rows: 3,
      id: "serviceability",
      required: true,
    },
    {
      title: "submit",
      type: "button",
    },
  ],
];

// function isJSON(str) {
//   try {
//     JSON.parse(str);
//     return true;
//   } catch (err) {
//     return false;
//   }
// }

// export function parseJSONInputs(formInput) {
//   let result = { ...formInput };
//   let reject = null;

//   for (let key in result) {
//     try {
//       if (isJSON(result[key])) {
//         let parsed = JSON.parse(result[key]);
//         result[key] = parsed;
//       } else {
//         continue;
//       }
//     } catch (error) {
//       reject = { [key]: `Excepted a JSON Type` };
//       break;
//     }
//     // }
//   }
//   return { result, reject };
// }

export const stringifyJSON = (data) => {
  let res = {};
  Object.keys(data).forEach((key) => {
    if (typeof data[key] === "object") {
      res[key] = JSON.stringify(data[key]);
    } else {
      res[key] = data[key];
    }
  });
  return res;
};
