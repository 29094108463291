import styled from "styled-components";

export const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5rem;
`;

export const RadioDiv = styled.div`
  display: "flex";
  flexdirection: "column";
`;

export const ErrorMessage = styled.div`
  type: invalid;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #fa5252;
`;
