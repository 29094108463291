import React from 'react';
import BreadCrumbComponent from '../../../components/common/Breadcrumb';
import arrayToBreadCrumbs from '../../../utils/sms/arrayToBreadCrumbs';
import { useTranslation } from 'react-i18next';
import AddSecurityGuard from '../add';

const AddSecurityGuardPage = () => {
  const { t } = useTranslation();
  const breadCrumbItems = arrayToBreadCrumbs([[t('Security')], [t('Add')]]);
  return (
    <>
      <div className="mb-4 d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-2">
        <div className="d-block mb-4 mb-md-0">
          <BreadCrumbComponent items={breadCrumbItems} />
          <h4>{t('Add New Security Guard')}</h4>
          <p className="mb-0">
            {t('Use this page to add a new Security Guard')}
          </p>
        </div>
      </div>
      <AddSecurityGuard />
    </>
  );
};

export default AddSecurityGuardPage;
