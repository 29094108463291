import React from "react";
import { getTableRowCheckBox } from "../../../utils/table";
import { returnCustomerAddressString } from "../../../utils/table";

export const storesListTableColumns = (onRowSelect) => {
  return [
    {
      dataField: "objectId",
      text: "Select",
      formatter: (cell, row) => {
        return getTableRowCheckBox(onRowSelect, cell, row?.isSelected, false);
      },
    },
    {
      dataField: "warehouseName",
      text: "Name",
      formatter: (cell, row) => {
        return (
          <div className="d-block">
            <div className="fw-bold">{cell}</div>
            <div className="fw-bold">{row?.shortCode}</div>
          </div>
        );
      },
    },
    {
      dataField: "zone",
      text: "Zone",
      formatter: (cell, row) => {
        return <div className="d-block">{cell}</div>;
      },
    },
    {
      dataField: "storeTimings",
      text: "Timings",
      formatter: (cell, row) => {
        return (
          <div className="d-block">
            <span className="fw-bold">{cell}</span>
            <div className="fw-normal">
              {row?.startTime ? row?.startTime + " - " + row?.endTime : "-"}
            </div>
          </div>
        );
      },
    },
    {
      dataField: "address",
      text: "Address",
      formatter: (cell, row) => {
        return returnCustomerAddressString(cell, row?.pincode);
      },
    },
    {
      dataField: "customerPickupAllowed",
      text: "CustomerPickup Allowed",
      formatter: (cell, row) => {
        return <div className="d-block text-center">{cell ? "YES" : "NO"}</div>;
      },
    },
    {
      dataField: "courrierPickupAllowed",
      text: "3PL",
      formatter: (cell, row) => {
        return <div className="d-block text-center">{cell ? "YES" : "NO"}</div>;
      },
    },
    {
      dataField: "deliveryAllowed",
      text: "Delivery Allowed",
      formatter: (cell, row) => {
        return <div className="d-block text-center">{cell ? "YES" : "NO"}</div>;
      },
    },
    {
      dataField: "storeEnabled",
      text: "Store Enabled",
      formatter: (cell, row) => {
        return <div className="d-block text-center">{cell ? "YES" : "NO"}</div>;
      },
    },
  ];
};
