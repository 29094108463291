import React, { useEffect, useState } from "react";
import { Button, Modal } from "@themesberg/react-bootstrap";
import { useTranslation } from "react-i18next";
import DropdownComponent from "../../../components/common/Dropdown";

const TargetChangeTeamLeadModal = (props) => {
    const {
        show,
        handleClose,
        teamLeadList = [],
        allocateTargetConfirm,
        category
    } = props;
    const { t } = useTranslation();
    const [selectedValue, setSelectedValue] = useState({});
    const [currentTeamLeadList, setCurrentTeamLeadList] = useState(teamLeadList);

    useEffect(() => {
        setCurrentTeamLeadList(props.teamLeadList)
    }, [props]);

    const teamLeadListItemsToShow = currentTeamLeadList?.map(option => ({
        value: option.id,
        label: option.name
    }))
    return (
        <Modal show={show} onHide={handleClose} size="md">
            <Modal.Header className="mx-auto">
                <p>{t("Choose Available Team Leads")}</p>
            </Modal.Header>
            <Modal.Body className="pt-4">
                <DropdownComponent
                    options={teamLeadListItemsToShow}
                    onChange={(value) => {
                        setSelectedValue(value);
                    }} />
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center pt-2 pb-2">
                <Button variant="primary" onClick={() => {
                    allocateTargetConfirm(selectedValue)
                }}>
                    {"Allocate"}
                </Button>
            </Modal.Footer>
        </Modal>
    )
};

export default TargetChangeTeamLeadModal;