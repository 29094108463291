import React, { useState } from "react";
import Datetime from "react-datetime";
import BreadCrumbComponent from "../../components/common/Breadcrumb";
import { Routes } from "../../router/routes";
import { useTranslation } from "react-i18next";
import { Alert, Button, Col, Form, InputGroup, Row } from "@themesberg/react-bootstrap";
import DropdownComponent from "../../components/common/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { fetchWarehousesInZone } from "../../parse-functions/warehouse";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faCalendarAlt, faInfo, faInfoCircle, faSave, faSearch } from "@fortawesome/free-solid-svg-icons";
import { updateSpinnerState } from "../../actions/spinner";
import { getProductDataByVANNo } from "../../parse-functions/products";
import { updateToastInfo } from "../../actions/settings";
import { addBranchBreakupRequisitionData } from "../../actions/requisitions";
import { generateRequisitionsInBulk } from "../../parse-functions/requisitions";
import { returnUserReadableDate } from "../../utils/datetime";

const BranchBreakdown = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [formErrors, setFormErrors] = useState({});
    const [disableInitialInfo, setDisableInitialInfo] = useState(false);
    const [reqFormData, setReqFormData] = useState({});
    const configData = useSelector((state) => state.user.config || {});
    const [warehousesInZone, setWarehousesInZone] = useState([]);
    const warehouse = useSelector((state) => state.user.warehouse || {});
    const [selectedProduct, setSelectedProduct] = useState({});
    const [branchQuantity, setBranchQuantity] = useState({});
    const branchBreakupDetails = useSelector((state) => state.requisitions?.branchBreakup || []);

    const onQuantityFormChange = (branchCode, quantity) => {
        setBranchQuantity(oldData => ({
            ...oldData,
            [branchCode]: quantity
        }))
    }
    const onFormInputChange = async (field, value) => {
        setReqFormData(oldData => ({
            ...oldData,
            [field]: value
        }))
        if (field === "zone") {
            dispatch(updateSpinnerState(true));
            // fetch the branches under this zone
            const allWarehousesInZone = await fetchWarehousesInZone({
                zone: value
            });
            const warehouseOptions = allWarehousesInZone?.warehouses?.map(warehouse => ({
                label: warehouse.warehouseCode,
                value: warehouse
            }));
            setWarehousesInZone(warehouseOptions)
            dispatch(updateSpinnerState(false));
        }
    }
    const breadCrumbItems = [{
        title: t("Requisitions"),
        link: Routes.Requisitions.BranchBreakdown.path
    }, {
        title: t("Branch Breakup"),
        link: ""
    }];
    const zoneDropDownOptions = () => {
        const {
            zones = {}
        } = configData;
        const returnVal = [];
        returnVal.push(<option>{t("Please select a zone")}</option>)
        Object.keys(zones).forEach((zoneName) => {
            returnVal.push(<option>{t(zoneName)}</option>)
        });
        return returnVal;
    };
    const fetchVANNo = async () => {
        try {
            dispatch(updateSpinnerState(true));
            const {
                product = {}
            } = await getProductDataByVANNo({
                vanNo: reqFormData.vanNo,
                checkCombi: false
            });
            dispatch(updateSpinnerState(false));
            if (Object.keys(product)?.length > 0) {
                // product found
                setSelectedProduct(product);
            } else {
                // product not found
                dispatch(updateToastInfo({
                    show: true,
                    type: 'danger',
                    title: t('Product Not found'),
                    message: t("No Product found with this VAN No. Please check the VAN No and try again")
                }))
            }
        } catch (e) {
            dispatch(updateToastInfo({
                show: true,
                type: 'danger',
                title: t('Error in fetching VAN no'),
                message: t("Please retry")
            }))
        }
    }
    const handleZoneAndBranchSubmit = () => {
        if (reqFormData?.zone && reqFormData?.createdByBranch && Object.keys(selectedProduct)?.length > 0) {
            setDisableInitialInfo(true);
        } else {
            dispatch(updateToastInfo({
                show: true,
                type: 'danger',
                title: t('Missing Info'),
                message: t("Please check if zone, branch and product are selected")
            }))
        }
    };
    const updateQuantityForAllBranches = () => {
        dispatch(addBranchBreakupRequisitionData({
            branchQuantity,
            reqFormData,
            selectedProduct
        }));
        setSelectedProduct({});
        setBranchQuantity({});
        setDisableInitialInfo(false);
    };
    const handleSaveBranchRequisition = async () => {
        try {
            if (branchBreakupDetails?.length > 0) {
                dispatch(updateSpinnerState(true));
                await generateRequisitionsInBulk({
                    data: branchBreakupDetails,
                    warehouse
                });
                dispatch(updateToastInfo({
                    show: true,
                    type: 'success',
                    title: t('Requisitions created successfully'),
                    message: t("All requisitions created successfully")
                }));
                dispatch(updateSpinnerState(false));
                setSelectedProduct({});
                setBranchQuantity({});
                setDisableInitialInfo(false);
                setReqFormData({});
            } else {
                dispatch(updateToastInfo({
                    show: true,
                    type: 'danger',
                    title: t('No Items found to generate requisition'),
                    message: t("Please consider adding some items and branches info")
                }))
            }
        } catch (e) {
            dispatch(updateSpinnerState(false));
            dispatch(updateToastInfo({
                show: true,
                type: 'danger',
                title: t('Error generating bulk requisitions'),
                message: t("Retry or please contact support if this issue persists")
            }))
        }
    }
    return (
        <div className="mt-1">
            <div className="mb-4 d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-2">
                <div className="d-block mb-4 mb-md-0">
                    <BreadCrumbComponent
                        items={breadCrumbItems}
                    />
                    <h4>{t("Create Branch breakup")}</h4>
                    <p className="mb-0">{t("Use this to create breakup for different branch for Requisitions")}</p>
                </div>
            </div>
            <Row className="mx-2">
                <Col md={4}>
                    <Alert variant="info">
                        Select Zone, Branch and VAN No
                    </Alert>
                    <Form.Group className="mb-3">
                        <Form.Label>{t("Zone")}</Form.Label>
                        <Form.Select
                            disabled={disableInitialInfo}
                            required
                            isInvalid={formErrors["zone"]?.length}
                            onChange={(event) => {
                                onFormInputChange("zone", event.target.value);
                            }}>
                            {zoneDropDownOptions()}
                        </Form.Select>
                        {formErrors["zone"]?.length && (
                            <Form.Control.Feedback type="invalid">{t("Please choose a zone")}</Form.Control.Feedback>
                        )}
                    </Form.Group>
                    <Form.Group className="mb-4">
                        <Form.Label className={formErrors["createdByBranch"]?.length > 0 ? "text-danger" : ""}>{t("Choose From Location")}</Form.Label>
                        <DropdownComponent
                            disabled={disableInitialInfo || warehousesInZone?.length === 0}
                            onChange={(option) => {
                                onFormInputChange("createdByBranch", option.value)
                            }}
                            options={warehousesInZone}
                            placeholder={t("Select From Location")}
                        />
                        {formErrors["createdByBranch"]?.length > 0 && (
                            <Form.Control.Feedback type="invalid">{formErrors["createdByBranch"]}</Form.Control.Feedback>
                        )}
                    </Form.Group>
                    <Form.Group className="mb-4">
                        <Form.Label className={formErrors?.["requisitionType"]?.length > 0 ? "text-danger" : ""}>{t("Choose Requisition Type")}</Form.Label>
                        <DropdownComponent
                            onChange={(option) => {
                                onFormInputChange("requisitionType", option.value)
                            }}
                            options={[{
                                label: "Display",
                                value: "Display"
                            }, {
                                label: "Stock",
                                value: "Stock"
                            }, {
                                label: "Delivery",
                                value: "Delivery"
                            }, {
                                label: "Replacement",
                                value: "Replacement"
                            }]}
                            placeholder={t("Select Requisition Type")}
                        />
                    </Form.Group>
                    {formErrors?.["requisitionType"]?.length > 0 && (
                        <Form.Control.Feedback type="invalid">{formErrors["requisitionType"]}</Form.Control.Feedback>
                    )}
                    <Form.Label>{t("Requisition Date")}</Form.Label>
                    <Datetime
                        timeFormat={false}
                        closeOnSelect={true}
                        onChange={(value) => {
                            onFormInputChange("requisitionDate", new Date(value));
                        }}
                        renderInput={(props, openCalendar) => (
                            <InputGroup>
                                <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} className="icon icon-xs" /></InputGroup.Text>
                                <Form.Control
                                    isInvalid={formErrors?.["requisitionDate"]?.length > 0}
                                    required
                                    type="text"
                                    value={returnUserReadableDate(reqFormData.requisitionDate || new Date())}
                                    placeholder="Select end: dd/mm/yyyy"
                                    onFocus={openCalendar} />
                                {formErrors?.["requisitionDate"]?.length > 0 && (
                                    <Form.Control.Feedback type="invalid">{formErrors["requisitionDate"]}</Form.Control.Feedback>
                                )}
                            </InputGroup>
                        )} />
                    <Form.Group className="mt-4 mb-4">
                        <Form.Label>{t("VAN No")}</Form.Label>
                        <Row>
                            <Col md={8}>
                                <Form.Control
                                    disabled={disableInitialInfo}
                                    isInvalid={formErrors["vanNo"]?.length > 0}
                                    required
                                    type="number"
                                    onKeyDown={(event) => {
                                        if (event.nativeEvent.code === "Enter") {
                                            fetchVANNo();
                                        }
                                    }}
                                    onChange={(event) => {
                                        onFormInputChange("vanNo", event.target.value);
                                    }}
                                    placeholder={t("VAN NO")} /></Col>
                            <Col md={4}>
                                <Button disabled={disableInitialInfo} variant="primary" onClick={fetchVANNo}>
                                    <FontAwesomeIcon icon={faSearch} />&nbsp;&nbsp;Search</Button>
                            </Col>
                        </Row>
                        {Object.keys(selectedProduct)?.length > 0 && (
                            <>
                                <Row className="mt-4">
                                    <Col md={4}>{t("Product Name:")}</Col>
                                    <Col>{selectedProduct?.productName}</Col>
                                </Row>
                                <Row>
                                    <Col md={4}>{t("Product Code:")}</Col>
                                    <Col>{selectedProduct?.productCode}</Col>
                                </Row>
                                <Row>
                                    <Col md={4}>{t("Product Category:")}</Col>
                                    <Col>{selectedProduct?.productCategory}</Col>
                                </Row>
                            </>
                        )}
                        {formErrors["vanNo"]?.length > 0 && (
                            <Form.Control.Feedback type="invalid">{formErrors["vanNo"]}</Form.Control.Feedback>
                        )}
                    </Form.Group>
                    <Button disabled={disableInitialInfo} className="w-100" variant="primary" onClick={handleZoneAndBranchSubmit}>
                        Add branch & quantity
                    </Button>
                </Col>
                <Col md={8}>
                    <Row>
                        <Alert variant="info">
                            <FontAwesomeIcon icon={faInfoCircle} />&nbsp;&nbsp;Add Quantity in front of each branch name
                        </Alert>
                    </Row>
                    {disableInitialInfo ? (
                        <Row>
                            {warehousesInZone?.map(warehouse => {
                                if (warehouse?.value?.warehouseCode !== reqFormData?.createdByBranch?.warehouseCode) {
                                    return (
                                        <Col md={6} className="mt-2">
                                            <Row>
                                                <Col md={6} className="d-flex justify-content-end align-items-center" style={{
                                                    fontSize: 14
                                                }}>{warehouse?.value?.warehouseCode}</Col>
                                                <Col md={6}>
                                                    <Form.Control
                                                        type="number"
                                                        onChange={(event) => {
                                                            onQuantityFormChange(warehouse?.value?.warehouseCode, event.target.value);
                                                        }}
                                                        placeholder={t("Quantity")} />
                                                </Col>
                                            </Row>
                                        </Col>
                                    )
                                }
                                return null
                            })}
                            <Button variant="primary" className="w-100 mt-3 mb-4" onClick={updateQuantityForAllBranches}>
                                <FontAwesomeIcon icon={faSave} />&nbsp;&nbsp;Update Quantity for all branches
                            </Button>
                        </Row>
                    ) : (
                        <>{(branchBreakupDetails?.length > 0) ? (
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "20%"
                            }}>
                                <Button variant="primary" onClick={handleSaveBranchRequisition}>
                                    <FontAwesomeIcon icon={faSave} />&nbsp;&nbsp;Submit Requisition
                                </Button>
                            </div>
                        ) : (
                            <div
                                className="d-flex justify-content-center align-items-center"
                                style={{
                                    height: "50vh"
                                }}>
                                <Alert variant="info">
                                    <FontAwesomeIcon icon={faInfoCircle} />&nbsp;&nbsp;Please choose options from the left and add some items to create requisition
                                </Alert>
                            </div>

                        )}
                        </>
                    )}
                </Col>
            </Row>

        </div>
    )
}
export default BranchBreakdown;