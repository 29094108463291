import React, { useEffect, useState } from 'react';
import OrderListPage from '../../../components/common/OrderListPage';
import { useDispatch } from 'react-redux';
import { updateToastInfo } from '../../../actions/settings';
import { updateSpinnerState } from '../../../actions/spinner';
import { apiCall } from '../../../middlewares/api';
import { apiCallConsts } from '../../../constants/apiCallConsts';
import { orderStatus } from '../../../constants/orderStatus';
import { manualAssignColumns, orderTableColumns } from '../orders/utils';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import arrayToBreadCrumbs from '../../../utils/sms/arrayToBreadCrumbs';
import { AssignAgentModal } from './AssignAgentModal';
import { arrayToDropdownOptions } from '../../../utils/sms/arrayToDropdownOptions';
import { getDeliveryAgentsRegionUsers } from '../../../parse-functions/oms/deliveryAgent';
import AgentAssignmentModal from './AsssignAgentModal';

const ManualAssign = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [ordersData, setOrdersData] = useState([]);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [deliveryAgents, setDeliveryAgents] = useState({});

  const breadCrumbItems = arrayToBreadCrumbs([
    [t('Delivery Agent')],
    [t('Manual Assign')],
  ]);

  const bulkActionOptions = [
    {
      label: 'Assign',
      value: 'manualAssign',
    },
  ];

  const messageDisplayer = (type, title, message) => {
    dispatch(
      updateToastInfo({
        show: true,
        type: type,
        title: t(title),
        message: t(message),
      })
    );
  };

  const onRowSelect = (selected, objectId) => {
    if (selected) {
      setSelectedOrders((prev) => {
        const selectedItem = ordersData.find(
          (item) => item.objectId === objectId
        );
        return [...prev, selectedItem];
      });
    } else {
      setSelectedOrders((prev) =>
        prev.filter((item) => item.objectId !== objectId)
      );
    }
    setOrdersData((prev) =>
      prev.map((item) =>
        item.objectId === objectId ? { ...item, isSelected: selected } : item
      )
    );
  };

  const handleSelectedRowsUpdate = async (actionId) => {
    fetchDeliveryAgents();
  };

  const handleModalOnConfirmClick = async (userName) => {
    const userId = userName.userId;
    const driverName = userName?.driverName;
    const contactNumber = userName?.contactNumber;
    const vehicleNumber = userName?.vehicleNumber;
    const data = {
      // invoiceNo: selectedOrders[0].invoiceNo,
      objectId: selectedOrders.map((each) => each?.objectId),
      deliveryUserId: userId,
      username: userName,
      status: orderStatus.readyForPickup,
      driverName,
      contactNumber,
      vehicleNumber,
    };
    try {
      dispatch(updateSpinnerState(true));
      const response = await apiCall(
        apiCallConsts.PUT_METHOD,
        `/internal/deliveryAgent`,
        data
      );
      await fetchOrders();
      dispatch(updateSpinnerState(false));
      messageDisplayer('success', '', 'Update Successful!');
      setSelectedOrders([]);
      setShowAssignModal(false);
    } catch (e) {
      dispatch(updateSpinnerState(false));
      messageDisplayer('danger', 'Cannot be updated!', e.message);
    }
  };

  const fetchOrders = async () => {
    try {
      dispatch(updateSpinnerState(true));
      const res = await apiCall(
        apiCallConsts.GET_METHOD,
        `/internal/deliveryAgent/assign`
      );
      setOrdersData(res);
      setSelectedOrders([]);
      dispatch(updateSpinnerState(false));
      messageDisplayer('success', '', 'Orders fetched successfully');
    } catch (error) {
      dispatch(updateSpinnerState(false));
      messageDisplayer('danger', 'Failed to fetch Orders', error.message);
    }
  };

  const fetchDeliveryAgents = async () => {
    try {
      dispatch(updateSpinnerState(true));
      const response = await getDeliveryAgentsRegionUsers(
        selectedOrders[0].region,
        selectedOrders[0].storeCode
      );
      if (response.length > 0) {
        setDeliveryAgents(response);
        setShowAssignModal(true);
      } else {
        messageDisplayer(
          'danger',
          '',
          'No agents are available for this region!'
        );
      }
      dispatch(updateSpinnerState(false));
    } catch (error) {
      dispatch(updateSpinnerState(false));
      messageDisplayer('danger', 'Failed to fetch Agents', error.message);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  useEffect(() => {
    if (showAssignModal) {
      fetchDeliveryAgents();
    }
  }, [showAssignModal]);

  return (
    <div>
      <OrderListPage
        pageType={`ManualAssign`}
        showDateFilters={false}
        tableData={ordersData}
        tableColumns={manualAssignColumns(onRowSelect, navigate)}
        handleRefresh={fetchOrders}
        pageTitle={t(`Manual Assign Orders`)}
        breadCrumbItems={breadCrumbItems}
        enableBulkActions={selectedOrders.length > 0}
        hideBulkActions={!bulkActionOptions}
        bulkActionOptions={bulkActionOptions}
        handleItemBulkUpdate={handleSelectedRowsUpdate}
      />
      {showAssignModal && deliveryAgents.length > 0 && (
        <AgentAssignmentModal
          showModal={showAssignModal}
          closeModal={() => setShowAssignModal(false)} // Close modal handler
          modalTitle="Assign an Agent" // Modal title
          agents={deliveryAgents} // Pass agents array
          onAssignAgent={handleModalOnConfirmClick}
          size="xl" // Handle agent assignment
        />
      )}
    </div>
  );
};

export default ManualAssign;
