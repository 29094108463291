import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Form, InputGroup, Modal, Row } from "@themesberg/react-bootstrap";
import Joi from "joi";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import Datetime from "react-datetime";
import { useTranslation } from "react-i18next";
import { returnUserReadableDate } from "../../utils/datetime";
import { generateRandomId } from "../../utils/string";
import { returnValidationErrorMessages } from "../../utils/validation";
import DropdownComponent from "./Dropdown";
import { getAdditionalParcelInInvoiceToReschedule } from "../../utils/parcels";

const defectivePickupRescheduleSchema = Joi.object({
    targetBranch: Joi.string().required(),
    date: Joi.date().required(),
    remarks: Joi.string().required(),
});

const replacementPendingRescheduleSchema = Joi.object({
    targetBranch: Joi.string().required(),
    date: Joi.date().required(),
    remarks: Joi.string().required(),
    newSerialNo: Joi.string().required()
});
const DefectivePickupRescheduleModal = (props) => {
    const {
        showModal,
        closeModal,
        onConfirm,
        selectedParcelId,
        pageStatus,
        targetDropdownOptions,
        disableTargetDropdown = true,
        getNewSerialNo = false
    } = props;
    const [formErrors, setFormErrors] = useState({});
    const pageParcels = useSelector((state) => state.parcels[pageStatus] || {});
    const selectedParcel = pageParcels[selectedParcelId] || {};
    const [data, setFormData] = useState({});
    const configData = useSelector((state) => state.user.config || {});
    const [extraParcelIds, setExtraParcelIds] = useState([]);
    const [extraParcelIdSerialNos, setExtraParcelIdSerialNos] = useState({});
    const [extraSerialNumberError, setExtraSerialNumberError] = useState({});

    const { t } = useTranslation();
    const handleConfirmClick = async () => {
        let schemaName = getNewSerialNo ? replacementPendingRescheduleSchema : defectivePickupRescheduleSchema;
        if (extraParcelIds?.length > 0 && getNewSerialNo) {
            let updatedSerialNumberError = {};
            // user has selected that the extra parcels are being rescheduled. We need to validate their serial no
            // eslint-disable-next-line no-unused-expressions
            extraParcelIds?.forEach(extraParcelId => {
                if (!extraParcelIdSerialNos[extraParcelId]) {
                    updatedSerialNumberError[extraParcelId] = "Please enter serial number";
                }
            });
            
            if (Object.keys(updatedSerialNumberError)?.length > 0) {
                setExtraSerialNumberError(updatedSerialNumberError);
                return;
            } else {
                setExtraSerialNumberError({});
            }
        }
        const { error: formErrors = {}, value: formValue } = schemaName.validate(data, {
            abortEarly: false
        });
        const {
            details = {}
        } = formErrors;
        if (details?.length > 0) {
            const validationErrorMessages = returnValidationErrorMessages(details);
            setFormErrors(validationErrorMessages);
        } else {
            // again check for the conditional newSerialNo
            if (getNewSerialNo && !formValue?.newSerialNo) {

            } else {
                onConfirm({ ...formValue, parcelObjectId: selectedParcelId, extraParcelIds, extraParcelIdSerialNos })
            }

        }
    };
    const additionalParcelToChoose = getAdditionalParcelInInvoiceToReschedule(configData?.productCategoryToCheckForReschedule || [], pageParcels, selectedParcel);
    console.log(additionalParcelToChoose);
    console.log(extraParcelIds);
    console.log(extraParcelIdSerialNos);
    return (
        <Modal id={generateRandomId()} size={getNewSerialNo ? "lg" : "sm"} as={Modal.Dialog} centered show={showModal} onHide={closeModal}>
            <Modal.Header>
                <Modal.Title className="h6">{t("Reschedule Parcel")}</Modal.Title>
                <Button variant="close" aria-label="Close" onClick={closeModal} />
            </Modal.Header>
            <Modal.Body>
                {(additionalParcelToChoose?.length > 0) && (
                    <Form.Group>
                        <Form.Label className={"mb-4"}>{t("Choose Extra Parcels to Schedule")}</Form.Label>
                        {additionalParcelToChoose?.map(extraParcel => {
                            return (
                                <Row>
                                    <Col>
                                        <Form.Check
                                            defaultValue={false}
                                            onChange={(event) => {
                                                if (event.target.checked) {
                                                    setExtraParcelIds(extraParcelIds.concat([extraParcel.objectId]))
                                                } else {
                                                    // remove it from the list
                                                    setExtraParcelIds(extraParcelIds.filter(parcelInfo => parcelInfo !== extraParcel.objectId))
                                                }
                                            }}
                                            label={`${extraParcel.product}. Serial No: ${extraParcel.serialNo}`}
                                            id={extraParcel.objectId}
                                            htmlFor={extraParcel.objectId} />
                                    </Col>
                                    {getNewSerialNo && (
                                        <Col>
                                            <Form.Label>{t("Updated Serial Number")}</Form.Label>
                                            <Form.Control
                                                disabled={extraParcelIds?.indexOf(extraParcel.objectId) < 0}
                                                isInvalid={extraSerialNumberError?.[extraParcel.objectId]?.length > 0}
                                                // required
                                                type="text"
                                                onChange={(event) => {
                                                    extraParcelIdSerialNos[extraParcel.objectId] = event.target.value;
                                                    setExtraParcelIdSerialNos(extraParcelIdSerialNos);
                                                    console.log(extraParcelIdSerialNos);
                                                }}
                                                placeholder={t("Updated Serial No")} />
                                            {extraSerialNumberError?.[extraParcel.objectId]?.length > 0 && (
                                                <Form.Control.Feedback type="invalid">{"Please Enter Serial Number"}</Form.Control.Feedback>
                                            )}
                                        </Col>
                                    )}
                                </Row>
                            )
                        })}
                    </Form.Group>
                )}
                <Form.Group className="mb-4">
                    <Form.Label className={formErrors["targetBranch"]?.length > 0 ? "text-danger" : ""}>{t("Choose Target Branch")}</Form.Label>
                    <DropdownComponent
                        disabled={disableTargetDropdown}
                        onChange={(option) => {
                            setFormData(oldData => ({
                                ...oldData,
                                targetBranch: option.value
                            }))
                        }}
                        options={targetDropdownOptions}
                        placeholder={t(selectedParcel?.returnScanWarehouse)}
                    />
                </Form.Group>
                {formErrors["targetBranch"]?.length > 0 && (
                    <Form.Control.Feedback type="invalid">{formErrors["targetBranch"]}</Form.Control.Feedback>
                )}
                <Form.Group className="mb-4">
                    <Form.Label>{t("Choose Delivery Date")}</Form.Label>
                    <Datetime
                        timeFormat={false}
                        closeOnSelect={true}
                        onChange={(value) => {
                            setFormData(oldData => ({
                                ...oldData,
                                date: new Date(value)
                            }))
                        }}
                        renderInput={(props, openCalendar) => (
                            <InputGroup>
                                <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} className="icon icon-xs" /></InputGroup.Text>
                                <Form.Control
                                    isInvalid={formErrors["date"]?.length > 0}
                                    required
                                    type="text"
                                    value={returnUserReadableDate(data.date)}
                                    placeholder="Select delivery date"
                                    onFocus={openCalendar} />
                                {formErrors["date"]?.length > 0 && (
                                    <Form.Control.Feedback type="invalid">{formErrors["date"]}</Form.Control.Feedback>
                                )}
                            </InputGroup>
                        )} />
                </Form.Group>
                {(getNewSerialNo) && (
                    <Form.Group className="mb-4">
                        <Form.Label>{t("New Serial Number")}</Form.Label>
                        <Form.Control
                            isInvalid={formErrors["newSerialNo"]?.length > 0}
                            required
                            type="text"
                            onChange={(event) => {
                                const newSerialNo = event.target.value;
                                setFormData(oldData => ({
                                    ...oldData,
                                    newSerialNo
                                }))
                            }}
                            placeholder={t("New Serial No")} />
                        {formErrors["newSerialNo"]?.length > 0 && (
                            <Form.Control.Feedback type="invalid">{formErrors["newSerialNo"]}</Form.Control.Feedback>
                        )}
                    </Form.Group>
                )}
                <Form.Group className="mb-4">
                    <Form.Label>{t("Reason")}</Form.Label>
                    <Form.Control
                        isInvalid={formErrors["remarks"]?.length > 0}
                        required
                        type="text"
                        defaultValue={data.remarks || selectedParcel?.remarks}
                        onChange={(event) => {
                            const remarks = event.target.value;
                            setFormData(oldData => ({
                                ...oldData,
                                remarks
                            }))
                        }}
                        placeholder={t("Reason to Reschedule")} />
                    {formErrors["remarks"]?.length > 0 && (
                        <Form.Control.Feedback type="invalid">{formErrors["remarks"]}</Form.Control.Feedback>
                    )}
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleConfirmClick}>
                    {t("Rechedule")}
                </Button>
                <Button variant="link" className="text-gray ms-auto" onClick={closeModal}>
                    {t("Cancel")}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
export default DefectivePickupRescheduleModal;