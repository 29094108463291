import React from "react";
import { Modal } from "@themesberg/react-bootstrap";
import ImageGallery from "react-image-gallery";

const PODImageSlider = (props) => {
    const {
        show,
        handleClose,
        images,
        description = ""
    } = props;
    const imgArray = images?.map((img) => ({ original: img, thumbnail: img, description }));
    return (
        <Modal show={show} onHide={handleClose} className="modal-tertiary pod-images-modal">
            <ImageGallery items={imgArray} />
        </Modal>
    )
}
export default PODImageSlider