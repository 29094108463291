import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import FormComponent from "../../scheme-management/Components/FormComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCancel, faCheck, faEdit } from "@fortawesome/free-solid-svg-icons";
import { Button, Row, Col } from "@themesberg/react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { apiCall } from "../../../middlewares/api";
import { useDispatch } from "react-redux";
import { updateSpinnerState } from "../../../actions/spinner";
import { updateToastInfo } from "../../../actions/settings";
import { stringifyJSON } from "./utils";
import { apiCallConsts } from "../../../constants/apiCallConsts";

const DetailsPage = () => {
  const [delivery, setDelivery] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [updateSchema, setUpdateSchema] = useState(false);
  const { t } = useTranslation();
  const { objectId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDelivery = async () => {
      try {
        dispatch(updateSpinnerState(true));
        const curr = await apiCall(
          apiCallConsts.GET_METHOD,
          `${apiCallConsts.DETAIL_3PL_URL}${objectId}`,
          {}
        );
        setDelivery(curr);
        setUpdateSchema(true);
        dispatch(updateSpinnerState(false));
      } catch (error) {
        dispatch(updateSpinnerState(false));
        setUpdateSchema(true);
        dispatch(
          updateToastInfo({
            show: true,
            type: "danger",
            title: t("Failed to fetch"),
            message: t(`${error.message}`),
          })
        );
      }
    };
    fetchDelivery();
  }, []);

  const schema = [
    [
      {
        title: "Name",
        type: "text",
        id: "name",
        disabled: true,
      },

      {
        title: "Is Enabled",
        type: "switch",
        id: "isEnabled",
        disabled: true,
        options: [
          {
            label: "yes",
            value: true,
          },
          {
            label: "no",
            value: false,
          },
        ],
      },
      {
        title: "UserName",
        type: "text",
        id: "userName",
        disabled: true,
      },
      {
        title: "Password",
        type: "text",
        id: "password",
        disabled: true,
      },
      {
        title: "TokenGeneration",
        type: "textarea",
        rows: 3,
        id: "tokenGeneration",
        disabled: true,
      },
    ],
    [
      {
        title: "Tracking",
        type: "textarea",
        rows: 3,
        id: "tracking",
        disabled: true,
      },
      {
        title: "AwbGeneration",
        type: "textarea",
        rows: 3,
        id: "awbGeneration",
        disabled: true,
      },
      {
        title: "Ndr",
        type: "textarea",
        rows: 3,
        id: "ndr",
        disabled: true,
      },
      {
        title: "Serviceability",
        type: "textarea",
        rows: 3,
        id: "serviceability",
        disabled: true,
      },
    ],
  ];

  if (isEdit) {
    schema.forEach((group) => {
      group.forEach((field) => {
        field.disabled = false;
      });
    });

    schema[1].push(
      {
        title: "Submit",
        type: "button",
      },
      {
        title: "Cancel",
        type: "button",
      }
    );
  }

  const handleSubmit = async (data) => {
    setIsEdit(false);
    console.log(data);
    try {
      dispatch(updateSpinnerState(true));
      const res = await apiCall(
        apiCallConsts.PUT_METHOD,
        apiCallConsts.UPDATE_3PL_URL,
        data
      );
      dispatch(updateSpinnerState(false));
      if (res.message) {
        dispatch(
          updateToastInfo({
            show: true,
            type: "success",
            title: t("delivery updated successfully"),
            message: `${res.message}`,
          })
        );
        const curr = await apiCall(
          apiCallConsts.GET_METHOD,
          `${apiCallConsts.DETAIL_3PL_URL}${objectId}`,
          {}
        );
        setDelivery(curr);
      }
    } catch (error) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: "danger",
          title: t("delivery updated failed"),
          message: `${error.message}`,
        })
      );
    }
  };

  const handleDefault = async () => {
    try {
      dispatch(updateSpinnerState(true));
      const res = await apiCall("put", "internal/delivery/default", {
        name: delivery?.name,
      });
      dispatch(
        updateToastInfo({
          show: true,
          type: "success",
          title: t("default successfully"),
          message: t(`${res.message}`),
        })
      );
      navigate("/oms/3pl/list");
      // if (res?.message) {
      //   const curr = await apiCall("get", `internal/delivery/${objectId}`, {});
      //   setDelivery(curr);
      // }
      dispatch(updateSpinnerState(false));
    } catch (error) {
      dispatch(updateSpinnerState(false));
      updateToastInfo({
        show: true,
        type: "danger",
        title: t("Failed to fetch"),
        message: t(`${error.message}`),
      });
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <h4>{t(delivery.name)}</h4>
        </div>
        <div className=" mb-0">
          {!isEdit && (
            <Button className="me-2" onClick={handleDefault}>
              <FontAwesomeIcon icon={faCheck} />
              {t(" Make Default")}
            </Button>
          )}

          {!isEdit && (
            <Button onClick={() => setIsEdit(true)}>
              <FontAwesomeIcon icon={faEdit} />
              {t(" Edit partner")}
            </Button>
          )}

          {isEdit && (
            <Button onClick={() => setIsEdit(false)}>
              <FontAwesomeIcon icon={faCancel} />
              {t(" Cancel")}
            </Button>
          )}
        </div>
      </div>

      <div>
        {updateSchema && (
          <FormComponent
            schema={schema}
            savefunction={handleSubmit}
            editData={stringifyJSON(delivery)}
          />
        )}
      </div>
    </>
  );
};

export default DetailsPage;
