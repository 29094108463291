import {
  RESET_STORE,
  SET_PRODUCT_BY_VANNO_IN_STORE,
  SET_PRODUCTSLIST_DROPDOWN_IN_STORE,
  SET_PRODUCTSMAP_IN_STORE,
} from '../../actions/types';

const INITIAL_STATE = {
  productsListDropdown: {},
  productsMap: {},
  subCategories: [],
  companies: [],
  categories: [],
  product: {},
};
const allProductReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_PRODUCTSLIST_DROPDOWN_IN_STORE: {
      return {
        ...state,
        productsListDropdown: action.data,
      };
    }
    case SET_PRODUCTSMAP_IN_STORE: {
      return {
        ...state,
        productsMap: action.data,
        companies: [
          ...new Set(
            Object.values(action.data).map((product) => product?.brand || '')
          ),
        ],
        categories: [
          ...new Set(
            Object.values(action.data).map((product) => product?.l3 || '')
          ),
        ],
        subCategories: [
          ...new Set(
            Object.values(action.data).map((product) => product?.l4 || '')
          ),
        ],
      };
    }
    case SET_PRODUCT_BY_VANNO_IN_STORE: {
      return {
        ...state,
        product: state.productsMap[action.data],
      };
    }
    case RESET_STORE: {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
};

export default allProductReducer;
