import React from 'react';
import { Accordion, Badge, Nav } from '@themesberg/react-bootstrap';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

const CollapsableNavItem = (props) => {
  const location = useLocation();
  const { t } = useTranslation();
  const { pathname } = location;
  const {
    eventKey,
    title,
    icon,
    badgeText,
    badgeBg = 'secondary',
    badgeColor = 'primary',
    children = null,
  } = props;
  const defaultKey = pathname.indexOf(eventKey) !== -1 ? eventKey : '';
  const classNames = badgeText
    ? 'd-flex justify-content-start align-items-center justify-content-between'
    : '';

  return (
    <Accordion as={Nav.Item} defaultActiveKey={defaultKey}>
      <Accordion.Item eventKey={eventKey}>
        <Accordion.Button
          as={Nav.Link}
          className="d-flex justify-content-between align-items-center"
        >
          <span>
            <span className="sidebar-icon">
              <FontAwesomeIcon icon={icon} />{' '}
            </span>
            <span className="sidebar-text">{t(title)}</span>
            {badgeText ? (
              <Badge
                pill
                bg={badgeBg}
                text={badgeColor}
                className="badge-md notification-count ms-2"
              >
                {t(badgeText)}
              </Badge>
            ) : null}
          </span>
        </Accordion.Button>
        <Accordion.Body className="multi-level">
          <Nav className="flex-column">{children}</Nav>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};
export default CollapsableNavItem;
