import React, { useState } from 'react';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import Pagination from 'react-bootstrap-table2-paginator';
import * as Paginator from 'react-bootstrap-table2-paginator';
import { faCheck, faPen, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import Table from 'react-bootstrap-table-next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Col, Form, Row } from '@themesberg/react-bootstrap';
import { StyledSearchBar } from '../../trips/styles';
import BreadCrumbComponent from '../../../components/common/Breadcrumb';
import EmptyTable from '../../../components/common/EmptyTableView';
import {
  customSizePerPage,
  customTotal,
} from '../../../components/common/parcelStatusPages';
import { returnRowBGColor } from '../../../utils/parcels';
import { useDispatch } from 'react-redux';
import { updateToastInfo } from '../../../actions/settings';
import DropdownComponent from '../../../components/common/Dropdown';

const EntryListPage = (props) => {
  const {
    title,
    data = [],
    columns = [],
    breadCrumbItems,
    handleEntryModal,
    hideBulkActions,
    enableBulkActions,
    bulkActionOptions,
    handleItemBulkUpdate,
  } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [bulkActionSelectedOption, setBulkActionSelectedOption] =
    useState(null);
  const handleItemUpdate = () => {
    if (bulkActionSelectedOption) {
      handleItemBulkUpdate(bulkActionSelectedOption);
    } else {
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('Select an Action'),
          message: t('Please select an action from the dropdown'),
        })
      );
    }
  };
  return (
    <>
      <ToolkitProvider
        keyField="id"
        sort
        search={true}
        data={data}
        columns={columns}
        wrapperClasses="table-responsive"
      >
        {({ baseProps, searchProps }) => (
          <Paginator.PaginationProvider
            pagination={Pagination({
              custom: true,
              showTotal: true,
              alwaysShowAllBtns: true,
              totalSize: data?.length,
              paginationTotalRenderer: customTotal,
              sizePerPageRenderer: customSizePerPage,
            })}
          >
            {({ paginationProps, paginationTableProps }) => (
              <>
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                  <div className="d-block mb-4 mb-md-0">
                    <BreadCrumbComponent items={breadCrumbItems} />
                    <h4>{t(title)}</h4>
                  </div>
                </div>
                <div className="d-flex justify-content-end m-3">
                  <Row>
                    <Button
                      size={'md'}
                      onClick={handleEntryModal}
                      variant="primary"
                    >
                      <FontAwesomeIcon icon={faPen} />
                      &nbsp;&nbsp; {t('Register Entry')}
                    </Button>
                  </Row>
                </div>
                <Card>
                  <div className="table-responsive py-2">
                    <Card.Header>
                      <Row className="d-flex justify-content-between">
                        <Col xs={8} md={6} className="d-flex py-1">
                          {!hideBulkActions && (
                            <>
                              <Form.Group
                                id="substatus"
                                className="d-flex align-items-center flex-column"
                              >
                                <DropdownComponent
                                  onChange={(option) => {
                                    setBulkActionSelectedOption(option.value);
                                  }}
                                  width={300}
                                  disabled={!enableBulkActions}
                                  options={bulkActionOptions}
                                  placeholder={t('Select Action')}
                                />
                              </Form.Group>
                              <Button
                                style={{
                                  height: 37,
                                }}
                                size="sm"
                                onClick={handleItemUpdate}
                                disabled={!enableBulkActions}
                                className="ms-3 btn btn-secondary"
                                variant="secondary"
                              >
                                <FontAwesomeIcon icon={faCheck} />
                                &nbsp;&nbsp;{t('Update')}
                              </Button>
                            </>
                          )}
                        </Col>
                        <Col
                          xs={4}
                          md={4}
                          className="d-flex justify-content-md-end py-1"
                        >
                          <Search.SearchBar
                            {...searchProps}
                            placeholder={t('Search any value in table here')}
                          />
                          <Search.ClearSearchButton {...searchProps} />
                        </Col>
                      </Row>
                    </Card.Header>

                    <Table
                      id={'inventoryTable'}
                      rowClasses={(row) => {
                        const rowBGColor = returnRowBGColor(
                          row?.dashboardStatus ||
                            row?.mobileStatus ||
                            row?.status
                        );
                        return `${rowBGColor} border-bottom`;
                      }}
                      noDataIndication={<EmptyTable />}
                      {...baseProps}
                      {...paginationTableProps}
                      bodyClasses="border-0"
                      classes="table-flush dataTable-table"
                    />

                    <Card.Footer>
                      <Row>
                        <Col xs={12} md={4} className="py-1">
                          <Paginator.SizePerPageDropdownStandalone
                            {...paginationProps}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          className="d-flex align-items-center py-1"
                        >
                          <Paginator.PaginationTotalStandalone
                            {...paginationProps}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          className="d-flex justify-content-md-end align-items-center mb-0 py-1"
                        >
                          <Paginator.PaginationListStandalone
                            {...paginationProps}
                          />
                        </Col>
                      </Row>
                    </Card.Footer>
                  </div>
                </Card>
              </>
            )}
          </Paginator.PaginationProvider>
        )}
      </ToolkitProvider>
    </>
  );
};

export default EntryListPage;
