import { Button, Form, Modal, InputGroup } from '@themesberg/react-bootstrap';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Datetime from 'react-datetime';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { generateRandomId } from '../../../../utils/string';
import { returnUserReadableDate } from '../../../../utils/datetime';

const ProductReleaseModal = (props) => {
  const { t } = useTranslation();
  const {
    showModal,
    closeModal,
    modalBody,
    modalTitle,
    onConfirm,
    ModalProps,
    setModalProps,
    allowOptionalValues = false,
  } = props;

  const isValidDate = (currentDate) => {
    const today = Datetime.moment();
    return currentDate.isAfter(today);
  };

  const isAnyInputInvalid = () => {
    return ModalProps.some((item) => {
      if (item.type === 'number') {
        return item.value < 0;
      }
      return false;
    });
  };

  return (
    <Modal
      id={generateRandomId()}
      as={Modal.Dialog}
      centered
      show={showModal}
      onHide={closeModal}
    >
      <Modal.Header>
        <Modal.Title className="h6">{t(modalTitle)}</Modal.Title>
        <Button variant="close" aria-label="Close" onClick={closeModal} />
      </Modal.Header>
      <Modal.Body>
        {t(modalBody)}
        <br />
        <br />
        {ModalProps?.map((item, index) => (
          <>
            {item?.type === 'date' && (
              <>
                {t(`${item.title} :`)}
                <Datetime
                  timeFormat={false}
                  closeOnSelect={true}
                  isValidDate={isValidDate}
                  onChange={(value) => {
                    setModalProps((prev) =>
                      prev.map((prevItem, i) =>
                        i === index
                          ? { ...prevItem, value: new Date(value) }
                          : prevItem
                      )
                    );
                  }}
                  renderInput={(props, openCalendar) => (
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon
                          icon={faCalendarAlt}
                          className="icon icon-xs"
                        />
                      </InputGroup.Text>
                      <Form.Control
                        required
                        type="text"
                        value={returnUserReadableDate(item.value)}
                        placeholder="Select date: dd/mm/yyyy"
                        onFocus={openCalendar}
                      />
                    </InputGroup>
                  )}
                />
              </>
            )}
            {item?.type === 'text' && (
              <Form.Group>
                {t(`${item.title} :`)}
                <Form.Control
                  id={generateRandomId()}
                  value={item.value}
                  onChange={(e) => {
                    const { value } = e.target;
                    setModalProps((prev) =>
                      prev.map((prevItem, i) =>
                        i === index ? { ...prevItem, value: value } : prevItem
                      )
                    );
                  }}
                  type={item.type}
                />
              </Form.Group>
            )}
            {item?.type === 'number' && (
              <Form.Group>
                {t(`${item.title} :`)}
                <Form.Control
                  id={generateRandomId()}
                  value={item.value}
                  isInvalid={item.value < 0}
                  onChange={(e) => {
                    const { value } = e.target;
                    setModalProps((prev) =>
                      prev.map((prevItem, i) =>
                        i === index ? { ...prevItem, value: value } : prevItem
                      )
                    );
                  }}
                  type={item.type}
                />
                <Form.Control.Feedback type="invalid">
                  {t('Value must be greater than 0')}
                </Form.Control.Feedback>
              </Form.Group>
            )}
          </>
        ))}
      </Modal.Body>

      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={onConfirm}
          disabled={
            allowOptionalValues
              ? false
              : !ModalProps.some((item) => item.value) || isAnyInputInvalid()
          }
        >
          {t('Confirm')}
        </Button>
        <Button
          variant="link"
          className="text-gray ms-auto"
          onClick={closeModal}
        >
          {t('Cancel')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default ProductReleaseModal;
