export const DefaultVehiclesList = [{
    vehicleType: 't407',
    noOfVehicles: 10,
    weight: 2800,
    volume: 394.887,
    length: 9.7,
    breadth: 5.9,
    height: 6.9,
},{
    vehicleType: 't709',
    noOfVehicles: 10,
    weight: 4500,
    volume: 714,
    length: 17,
    breadth: 7,
    height: 6,
}, {
    vehicleType: 't1109',
    noOfVehicles: 10,
    weight: 8000,
    volume: 1069,
    length: 19.3,
    breadth: 7.7,
    height: 7.3,
}];

export const vehicleOrdersColumnNames = [
    {label: "#", accessor: "selected"},
    {label: "Invoice No", accessor: "invoiceNo"},
    {label: "Customer Name", accessor: "CustomerName"},
    {label: "Pincode", accessor: "shippingPincode"},
    {label: "Date", accessor: "Date"},
    {label: "Product Category", accessor: "productCategory"},
    {label: "Serial No", accessor: "serialNo"},
    {label: "Contact No", accessor: "contactNo"},
    {label: "Product", accessor: "product"},
    {label: "BuyBack", accessor: "Buyback"}
]