import React, { useEffect, useState } from 'react';
import FormComponent from '../../scheme-management/Components/FormComponent';
import { AddDeliveryVendorSchema, addDeliveryAgentSchema } from './utils';
import BreadCrumbComponent from '../../../components/common/Breadcrumb';
import arrayToBreadCrumbs from '../../../utils/sms/arrayToBreadCrumbs';
import {
  addDeliveryVendor,
  addNewDeliveryAgent,
  getVendorAndZone,
} from '../../../parse-functions/oms/deliveryAgent';
import { useDispatch, useSelector } from 'react-redux';
import { updateSpinnerState } from '../../../actions/spinner';
import { updateToastInfo } from '../../../actions/settings';
import { useTranslation } from 'react-i18next';
import { arrayToDropdownOptions } from '../../../utils/sms/arrayToDropdownOptions';
import { Col, Nav, Row, Tab } from '@themesberg/react-bootstrap';

const AddDeliveryAgent = ({ editData, handleEditSave }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const configData = useSelector((state) => state.user.config || {});
  const [agentSchema, setAgentSchema] = useState(addDeliveryAgentSchema);
  const breadCrumbItems = arrayToBreadCrumbs([
    ['Users'],
    ['Add Delivery Agent'],
  ]);
  const handleAgentFormSave = async (formData, callback) => {
    try {
      dispatch(updateSpinnerState(true));
      if (editData) {
        handleEditSave(formData);
      } else {
        await addNewDeliveryAgent(formData);
      }
      dispatch(
        updateToastInfo({
          show: true,
          type: 'success',
          title: t('User Added'),
          message: editData
            ? t(`${formData.driverName} updated successfully`)
            : t(`${formData.driverName} added successfully`),
        })
      );
      callback(null);
    } catch (error) {
      const { message } = error;
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: editData ? t('User Edit failed') : t('User Add Failed'),
          message: editData
            ? t(`${formData.driverName} failed to edit`)
            : t(message),
        })
      );
      callback(message);
    }
    dispatch(updateSpinnerState(false));
  };

  const handleVendorFormSave = async (formData, callback) => {
    try {
      dispatch(updateSpinnerState(true));
      await addDeliveryVendor(formData);
      dispatch(
        updateToastInfo({
          show: true,
          type: 'success',
          title: t('Vendor Added'),
          message: t(`${formData.vendorName} added successfully`),
        })
      );
      callback(null);
    } catch (error) {
      const { message } = error;
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('Vendor Add Failed'),
          message: t(message),
        })
      );
      callback(message);
    }
    dispatch(updateSpinnerState(false));
  };
  useEffect(() => {
    const fetchOptions = async () => {
      const options = await getVendorAndZone();
      setAgentSchema((prev) =>
        prev.map((section) =>
          section.map((field) => {
            if (field.id === 'vendorName') {
              return {
                ...field,
                options: arrayToDropdownOptions(
                  options.vendors.map((item) => item.vendorName)
                ),
              };
            }
            if (field.id === 'startingZone') {
              return {
                ...field,
                options: arrayToDropdownOptions(
                  options.startingZones.map((item) => item.zoneName)
                ),
              };
            }

            return field;
          })
        )
      );
    };
    fetchOptions();
  }, []);

  useEffect(() => {
    if (configData.zones) {
      setAgentSchema((prev) =>
        prev?.map((section) =>
          section.map((field) => {
            if (field.id === 'region') {
              return {
                ...field,
                options: arrayToDropdownOptions(Object.keys(configData.zones)),
              };
            }
            return field;
          })
        )
      );
    }
  }, [configData]);
  return (
    <div>
      {editData && agentSchema ? (
        <FormComponent
          editData={editData}
          schema={agentSchema}
          savefunction={handleAgentFormSave}
        />
      ) : (
        <div>
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
            <div className="d-block mb-4 mb-md-0">
              <BreadCrumbComponent items={breadCrumbItems} />
              <h4>{'Add New Delivery Agent/Vendor'}</h4>
              <p className="mb-0">
                {'You can add a new Delivery Agent / Vendor here'}
              </p>
            </div>
          </div>
          {}
          <Tab.Container defaultActiveKey="agent">
            <Row>
              <Col lg={12}>
                <Nav className="nav-tabs">
                  <Nav.Item>
                    <Nav.Link eventKey="agent" className="mb-sm-3 mb-md-0">
                      {t('Add Delivery Agent')}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="vendor" className="mb-sm-3 mb-md-0">
                      {t('Add Vendor')}
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col lg={12}>
                <Tab.Content>
                  <Tab.Pane eventKey="agent" className="py-4">
                    {agentSchema && (
                      <FormComponent
                        schema={agentSchema}
                        savefunction={handleAgentFormSave}
                      />
                    )}
                  </Tab.Pane>
                  <Tab.Pane eventKey="vendor" className="py-4">
                    <FormComponent
                      schema={AddDeliveryVendorSchema}
                      savefunction={handleVendorFormSave}
                    />
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      )}
    </div>
  );
};

export default AddDeliveryAgent;
