import axios from 'axios';
import 'dotenv/config';

const BASEURL = process.env.REACT_APP_BASEURL;
const app_id = process.env.REACT_APP_x_application_id;
const rest_key = process.env.REACT_APP_masterkey;

const api = axios.create({
  baseURL: BASEURL,
  headers: {
    'Content-Type': 'application/json',
    'X-Parse-Application-Id': app_id,
    'X-REST-API-Key': rest_key,
  },
});

const getCustomErrorMessage = (error) => {
  return (
    error.response?.data?.message ||
    error.response?.data?.error ||
    error.message
  );
};

api.interceptors.request.use(
  (config) => {
    let user = JSON.parse(
      localStorage.getItem('Parse/routing-server/currentUser')
    );

    if (user?.sessionToken) {
      let token = user?.sessionToken.slice(2);
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response?.status === 401 &&
      getCustomErrorMessage(error) === 'Invalid Token'
    ) {
      localStorage.setItem('redirectionUrl', window.location.pathname);
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export const apiCall = async (method, url, body) => {
  // let user = JSON.parse(
  //   localStorage.getItem('Parse/routing-server/currentUser')
  // );

  // let token = user.sessionToken.slice(2);

  // api.defaults.headers.common['Authorization'] = 'Bearer ' + token;

  let response = {};
  try {
    if (method === 'get') {
      response = await api.get(url, { params: body });
    } else if (method === 'post') {
      response = await api.post(url, body);
    } else if (method === 'put') {
      response = await api.put(url, body);
    } else if (method === 'delete') {
      response = await api.delete(url, body);
    }

    return response?.data || {};
  } catch (error) {
    // console.log(error);
    const errorMessage = getCustomErrorMessage(error);
    throw new Error(errorMessage);
  }
};
