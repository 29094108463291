import { MARK_ORDER_AS_SELECTED, REMOVE_SELECTED_ORDERS_FROM_VEHICLE } from "./types"

export const markOrderAsSelected = (orderId, isSelected) => {
    return {
        type: MARK_ORDER_AS_SELECTED,
        data: {
            orderId,
            isSelected
        }
    }
}

export const removeSelectedOrdersFromVehicle = () => {
    return {
        type: REMOVE_SELECTED_ORDERS_FROM_VEHICLE
    }
}