import React from 'react';
import { textFilter } from 'react-bootstrap-table2-filter';
import {
  getTableRowCheckBox,
  returnParcelProductInformation,
} from '../../../utils/table';
import { returnUserReadableDate } from '../../../utils/datetime';
import { Badge } from '@themesberg/react-bootstrap';
import { getTripBadgeStatus } from '../../../utils/trips';

export const getWMSTargetTableColumns = (onRowSelect) => {
  return [
    {
      dataField: 'objectId',
      text: 'Select',
      formatter: (cell, row) => {
        return getTableRowCheckBox(onRowSelect, cell, row?.isSelected);
      },
    },
    {
      dataField: 'customerName',
      filter: textFilter(),
      text: 'Customer Name',
    },
    {
      dataField: 'customerCode',
      sort: true,
      filter: textFilter(),
      text: 'Customer Code',
      formatter: (cell, row) => {
        return (
          <div className="d-block">
            <span className="fw-bold">{cell}</span>
          </div>
        );
      },
    },
    {
      dataField: 'voucherNumber',
      text: 'Invoice No',
      filter: textFilter(),
      formatter: (cell, row) => {
        return (
          <div className="d-block">
            <span className="fw-bold">{cell}</span>
          </div>
        );
      },
    },
    {
      dataField: 'teamLeadName',
      text: 'TeamLead Name',
      filter: textFilter(),
      formatter: (cell, row) => {
        return (
          <div className="d-block">
            <div className="fw-bold">
              <span>{cell}</span>
            </div>
          </div>
        );
      },
    },
    {
      dataField: 'brand',
      text: 'Product Info',
      filter: textFilter(),
      formatter: (cell, row) => {
        return returnParcelProductInformation(row);
      },
    },
    {
      dataField: 'product',
      text: 'Product',
      hidden: true,
      filter: textFilter(),
      formatter: (cell, row) => {
        return returnParcelProductInformation(row);
      },
    },
    {
      dataField: 'productCode',
      text: 'Model NO',
      filter: textFilter(),
      formatter: (cell, row) => {
        return <div className="fw-bold">{cell ? cell : ''}</div>;
      },
    },
    {
      dataField: 'productCategory',
      text: 'Product Category',
      filter: textFilter(),
      formatter: (cell, row) => {
        return <div className="fw-bold">{cell ? cell : ''}</div>;
      },
    },
    {
      dataField: 'vanNumber',
      text: 'VAN Number',
      filter: textFilter(),
      formatter: (cell, row) => {
        return (
          <div className="d-block">
            <div className="fw-bold">
              <span>{cell}</span>
            </div>
          </div>
        );
      },
    },
    {
      dataField: 'serialNumber',
      text: 'Serial Number',
      filter: textFilter(),
      formatter: (cell, row) => {
        return (
          <div className="d-block">
            <div className="fw-bold">
              <span>{cell}</span>
            </div>
          </div>
        );
      },
    },
    {
      dataField: 'pendingSerialNo',
      text: 'Pending Serial Number',
      filter: textFilter(),
      formatter: (cell, row) => {
        return (
          <div className="d-block">
            {cell?.map((item) => {
              return (
                <div className="fw-bold">
                  <span>{item}</span>
                </div>
              );
            })}
          </div>
        );
      },
    },
    {
      dataField: 'pendingItemNo',
      text: 'Pending Item No',
      filter: textFilter(),
      formatter: (cell, row) => {
        return (
          <div className="d-block">
            <span className="fw-bold">{cell}</span>
          </div>
        );
      },
    },
    {
      dataField: 'deliveryDate',
      text: 'Delivery Date',
      filter: textFilter(),
      formatter: (cell, row) => {
        return returnUserReadableDate(cell);
      },
    },
    {
      dataField: 'status',
      text: 'Status',
      filter: textFilter(),
      formatter: (cell, row) => {
        return (
          <Badge bg={getTripBadgeStatus(cell)} className="badge-lg">
            {cell}
          </Badge>
        );
      },
    },
  ];
};

export const returnUpdatedWarehouseTargetInfo = (option, selectedItems) => {
  return selectedItems.map((itemId) => ({
    objectId: itemId,
    dataToChange: [
      {
        teamLeadId: option.value,
        teamLeadName: option.label,
        status: 'assigned',
      },
    ],
  }));
};
