import React, { useEffect, useState } from "react";
import { Card, Form, Table } from "@themesberg/react-bootstrap";
import { vehicleOrdersColumnNames } from "../../constants/vehicles";
import { useDispatch, useSelector } from "react-redux";
import { markOrderAsSelected } from "../../actions/orders";
import { useTranslation } from "react-i18next";
import OrdersManage from "./ordersManage";

const TableRow = (props) => {
    const dispatch = useDispatch();
    const { data } = props;
    const orderDate = data?.date?.iso || data.date;
    return (
        <tr>
            <td>
                <Form.Check
                    defaultValue={data.isSelected || false}
                    value={data.isSelected || false}
                    defaultChecked={data.isSelected || false}
                    onChange={(event) => {
                        dispatch(markOrderAsSelected(data.objectId, event.target.checked));
                    }}
                    label=""
                    id={data.objectId}
                    htmlFor={data.objectId} />
            </td>
            <td>{data.invoiceNo}</td>
            <td>{data.customerName}</td>
            <td>{data.shippingPincode}</td>
            <td>{orderDate ? new Date(orderDate).toLocaleDateString() : '-'}</td>
            <td>{data.productCategory}</td>
            <td>{data.serialNo}</td>
            <td>{data.shippingMobileNo || "-"}</td>
            <td>{data.product}</td>
            <td>{data.buybackProduct || "-"}</td>
        </tr>
    );
}

const VehicleOrdersTable = (props) => {
    const vehiclesState = useSelector((state) => state.vehicles || {});
    const { t } = useTranslation();
    const [vehicleOrders, setVehicleOrders] = useState([]);
    useEffect(() => {
        const {
            vehiclesList = {},
            activeVehicleId
        } = vehiclesState;
        if (vehiclesList[activeVehicleId]?.orders) {
            setVehicleOrders(vehiclesList[activeVehicleId]?.orders);
        } else {
            setVehicleOrders([]);
        }
    }, [vehiclesState, vehiclesState.vehiclesList]);
    return (
        <Card border="light" className="shadow-sm mb-4" style={{
            height: '100%', overflowY: 'scroll'
        }}>
            <OrdersManage />
            <Card.Body className="pb-0">
                <Table responsive className="table-centered table-nowrap rounded mb-0" >
                    <thead className="thead-light">
                        <tr>
                            {vehicleOrdersColumnNames?.map((colName) => <th className="border-0">{t(colName.label)}</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {vehicleOrders?.map((item, index) => {
                            return <TableRow key={`page-traffic-${index}`} data={item} index={index + 1} />
                            
                        })}
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    );
};
export default VehicleOrdersTable;