import React from 'react';
import { Button, Form, InputGroup, Modal } from '@themesberg/react-bootstrap';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { generateRandomId } from '../../../../utils/string';
import Datetime from 'react-datetime';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { returnUserReadableDate } from '../../../../utils/datetime';
import EditableDropDown from '../../../../components/common/EditableDropdown';
import RejectDropdown from '../../../../components/common/RejectDropdown';
// import { returnUserReadableDate } from '../../utils/datetime';
// import RejectDropdown from './RejectDropdown';
// import EditableDropDown from './EditableDropdown';

const RejectModal = ({
  icon,
  showModal,
  closeModal,
  modalTitle,
  modalSubTitle,
  confirmActionName,
  cancelActionName,
  confirmationModalProps,
  setConfirmationModalProps,
  handleSubmit,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      id={generateRandomId()}
      as={Modal.Dialog}
      centered
      show={showModal}
      onHide={closeModal}
      size="lg"
    >
      <Modal.Body>
        <div className="mx-5">
          <div className="d-flex flex-column justify-content-center align-items-center text-center gap-3">
            <div className="fs-4">
              {<FontAwesomeIcon size="lg" icon={icon} />}
            </div>
            <div className="fs-4">{t(modalTitle)}</div>
            {modalSubTitle && <div className="fs-5">{t(modalSubTitle)}</div>}
            <div style={{ width: '100%' }}>
              {confirmationModalProps &&
                confirmationModalProps?.map((item, index) => (
                  <>
                    {item?.type === 'date' && (
                      <>
                        {t(`${item.title} :`)}
                        <Datetime
                          timeFormat={false}
                          closeOnSelect={true}
                          onChange={(value) => {
                            setConfirmationModalProps((prev) =>
                              prev.map((prevItem, i) =>
                                i === index
                                  ? { ...prevItem, value: new Date(value) }
                                  : prevItem
                              )
                            );
                          }}
                          renderInput={(props, openCalendar) => (
                            <InputGroup>
                              <InputGroup.Text>
                                <FontAwesomeIcon
                                  icon={faCalendarAlt}
                                  className="icon icon-xs"
                                />
                              </InputGroup.Text>
                              <Form.Control
                                required
                                type="text"
                                value={returnUserReadableDate(item.value)}
                                placeholder="Select date: dd/mm/yyyy"
                                onFocus={openCalendar}
                              />
                            </InputGroup>
                          )}
                        />
                      </>
                    )}
                    {item?.type === 'textArea' && (
                      <Form.Group className="mb-2">
                        {t(`${item.title} :`)}
                        <Form.Control
                          id={generateRandomId()}
                          value={item.value}
                          onChange={(e) => {
                            const { value } = e.target;
                            setConfirmationModalProps((prev) =>
                              prev.map((prevItem, i) =>
                                i === index
                                  ? { ...prevItem, value: value }
                                  : prevItem
                              )
                            );
                          }}
                          rows={2}
                          as="textarea"
                        />
                      </Form.Group>
                    )}
                    {item?.type === 'text' && (
                      <Form.Group className="mb-2">
                        <div className="text-start">{t(`${item.title} :`)}</div>
                        <Form.Control
                          id={generateRandomId()}
                          value={item.value}
                          onChange={(e) => {
                            const { value } = e.target;
                            setConfirmationModalProps((prev) =>
                              prev.map((prevItem, i) =>
                                i === index
                                  ? { ...prevItem, value: value }
                                  : prevItem
                              )
                            );
                          }}
                          type={item.type}
                        />
                      </Form.Group>
                    )}
                    {item?.type === 'number' && (
                      <Form.Group className="mb-2">
                        <div className="text-start">{t(`${item.title} :`)}</div>
                        <Form.Control
                          id={generateRandomId()}
                          value={item.value}
                          onChange={(e) => {
                            const { value } = e.target;
                            setConfirmationModalProps((prev) =>
                              prev.map((prevItem, i) =>
                                i === index
                                  ? { ...prevItem, value: value }
                                  : prevItem
                              )
                            );
                          }}
                          type={item.type}
                        />
                      </Form.Group>
                    )}
                    {item?.type === 'dropdown' && (
                      <Form.Group className="mb-2">
                        <div className="text-start">
                          {' '}
                          {t(`${item.title} :`)}
                        </div>
                        <EditableDropDown
                          isMultiSelect={item.isMulti}
                          onChange={(option) => {
                            const { value } = option;
                            setConfirmationModalProps((prev) =>
                              prev.map((item, i) =>
                                i === index ? { ...item, value: value } : item
                              )
                            );
                          }}
                          value={item.value}
                          options={item.options}
                          placeholder="Select Value from below"
                        />
                      </Form.Group>
                    )}
                    {item?.type === 'rejectComponent' && (
                      <RejectDropdown
                        options={item.options}
                        setReason={(value) =>
                          setConfirmationModalProps((prev) =>
                            prev.map((prevItem, i) =>
                              i === index
                                ? { ...prevItem, value: value }
                                : prevItem
                            )
                          )
                        }
                        setRemarks={(value) =>
                          setConfirmationModalProps((prev) =>
                            prev.map((prevItem, i) =>
                              i === index
                                ? { ...prevItem, remarks: value }
                                : prevItem
                            )
                          )
                        }
                        reason={item.value}
                        remarks={item.remarks}
                        position={item.position}
                      />
                    )}
                  </>
                ))}
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-end">
        <div className="d-flex gap-3">
          <Button
            variant="light"
            size="md"
            // className="btn-export"
            onClick={closeModal}
          >
            <FontAwesomeIcon icon={faXmark} />
            &nbsp;&nbsp; {t(cancelActionName)}
          </Button>
          <Button
            variant="primary"
            size="md"
            className="btn btn-primary"
            onClick={handleSubmit}
          >
            <FontAwesomeIcon icon={faCheck} />
            &nbsp;&nbsp; {t(confirmActionName)}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default RejectModal;
