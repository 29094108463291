import React, { useEffect, useState } from 'react';
import TableOnly from '../../scheme-management/Components/IndividualScheme.js/TableOnly';
import { infoModalColumns, productsColums, receivedColumns } from './utils';
import { Badge, Button, Form } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowRotateLeft,
  faBan,
  faBroom,
  faRotateRight,
  faSliders,
} from '@fortawesome/free-solid-svg-icons';
import ExcelDownloadReport from '../../../components/common/excel/download';
import { useTranslation } from 'react-i18next';
import EditableDropDown from '../../../components/common/EditableDropdown';
import { useDispatch, useSelector } from 'react-redux';
import { updateSpinnerState } from '../../../actions/spinner';
import { updateToastInfo } from '../../../actions/settings';
import { apiCall } from '../../../middlewares/api';
import { apiCallConsts } from '../../../constants/apiCallConsts';
import InfoModal from '../inventory/components/InfoModal';
import AcceptModal from './components/AcceptModal';
import RejectModal from './components/RejectModal';
import { IBTdropDownStatus, IBTStatuses, statusMap } from './constants';
import { arrayToDropdownOptions } from '../../../utils/sms/arrayToDropdownOptions';

import { getDeliveryAgentsRegionUsers } from '../../../parse-functions/oms/deliveryAgent';
import AgentAssignmentModal from '../deliveryAgent/AsssignAgentModal';

const IBTReceived = () => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [vanNo, setVanNo] = useState('');
  const [status, setStatus] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [openFilter, setOpenFilter] = useState(false);
  const [isValidInput, setIsValidInput] = useState(true);
  const [infoModal, setInfoModal] = useState(false);
  const [infoData, setInfoData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [updateData, setUpdateData] = useState({});
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [rejectModal, setRejectModal] = useState(false);
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [deliveryAgents, setDeliveryAgents] = useState({});
  const zone = useSelector((state) => state?.user?.warehouse?.zone || '');
  const userName = useSelector((state) => state?.user?.user?.username || '');
  const [flag, setFlag] = useState(false);
  const [selectedAction, setSelectedAction] = useState('');
  const [confirmationModalProps, setConfirmationModalProps] = useState([
    {
      type: 'text',
      title: 'Remark',
      value: '',
    },
  ]);
  const storeCode = useSelector(
    (state) => state.user.preferences.storeName || ''
  );
  const dispatch = useDispatch();

  const filterObj = [
    { label: 'VAN NO', value: vanNo, func: setVanNo },

    {
      label: 'STATUS',
      value: status,
      func: setStatus,
    },
    { label: 'START DATE', value: startDate, func: setStartDate },
    { label: 'END DATE', value: endDate, func: setEndDate },
  ];

  const fetchData = async () => {
    try {
      dispatch(updateSpinnerState(true));
      const response = await apiCall(apiCallConsts.GET_METHOD, 'internal/ibt', {
        vanNo,
        startDate: startDate
          ? startDate
          : new Date().toISOString().split('T')[0],
        endDate: endDate ? endDate : new Date().toISOString().split('T')[0],
        status,
        storeCode,
        isReceived: true,
      });
      console.log(response);
      setData(response);
      dispatch(updateSpinnerState(false));
    } catch (error) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: 'failed',
          message: error.message,
        })
      );
    }
  };

  const updateStatus = async (data) => {
    try {
      console.log(JSON.stringify(data));

      dispatch(updateSpinnerState(true));
      const res = await apiCall(apiCallConsts.PUT_METHOD, 'internal/ibt', data);
      setSelectedAction('');
      setUpdateData({});
      await fetchData();
      dispatch(
        updateToastInfo({
          show: 'true',
          type: 'success',
          title: '',
          message: 'update successfully!',
        })
      );
      dispatch(updateSpinnerState(false));
    } catch (error) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: 'true',
          type: 'danger',
          title: 'failed',
          message: error.message,
        })
      );
    }
  };

  const handleShowMoreClick = (data) => {
    setInfoData(data);
    setInfoModal(true);
  };

  const actionClicked = (action, id) => {
    if (action === 'Partial Accept') {
      setIsReadOnly(false);
    }
    setSelectedAction(action);
    let selected = data?.find((each) => each.objectId === id);
    selected = {
      ...selected,
      products: selected?.products?.map((product) => ({
        ...product,
        receivedQuantity: product?.requestedQuantity,
      })),
    };
    setUpdateData(selected);
    if (action === 'Cancel') {
      return setRejectModal(true);
    }
    if (action === 'Assign Delivery Boy') {
      return setShowAssignModal(true);
    }
    setShowModal(true);
    // console.log(action, id);
  };
  // console.log(updateData);

  const handleSubmit = async (updatedProducts, contactDetails) => {
    console.log(updatedProducts, contactDetails);

    setShowModal(false);
    // setUpdateData((prev) => ({ ...prev, products: [...updatedProducts] }));
    const data = {
      id: updateData?.objectId,
      status:
        selectedAction === 'Partial Accept'
          ? IBTStatuses.PARTIALLY_ACCEPTED
          : IBTStatuses.ACCEPTED,
      senderRemarks: updateData?.remarks || '',
      products: updatedProducts,
      username: userName,
      senderContactPerson: contactDetails?.receivingContactPerson,
      senderContactNumber: contactDetails?.receivingContactNumber,
    };
    // console.log(data);

    await updateStatus(data);
  };

  const handleRejectSubmit = async () => {
    const data = {
      id: updateData?.objectId,
      status: IBTStatuses.CANCELLED,
      senderRemarks: confirmationModalProps[0]?.value || '',
      products: updateData?.products,
      username: userName,
    };
    await updateStatus(data);
    setRejectModal(false);
    setConfirmationModalProps([
      {
        type: 'text',
        title: 'Remark',
        value: '',
      },
    ]);
  };

  const handleReset = () => {
    setStartDate('');
    setEndDate('');
    setStatus([]);
    setVanNo('');
    setFlag((flag) => !flag);
  };

  useEffect(() => {
    const all = async () => {
      await fetchData();
    };
    all();
  }, [flag]);

  const fetchDeliveryAgents = async () => {
    try {
      dispatch(updateSpinnerState(true));
      const response = await getDeliveryAgentsRegionUsers(zone, storeCode);

      console.log(response);
      if (response.length >= 0) {
        setDeliveryAgents(response);
        setShowAssignModal(true);
      } else {
        dispatch(
          updateToastInfo({
            show: true,
            type: 'danger',
            title: '',
            message: 'No agents are available for this region!',
          })
        );
      }
      dispatch(updateSpinnerState(false));
    } catch (error) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: '',
          message: 'No agents are available for this region!',
        })
      );
    }
  };

  useEffect(() => {
    if (showAssignModal) {
      fetchDeliveryAgents();
    }
  }, [showAssignModal]);

  const handleModalOnConfirmClick = async (userName) => {
    const userId = userName?.userId;
    const driverName = userName?.driverName;
    const contactNumber = userName?.contactNumber;
    const vehicleNumber = userName?.vehicleNumber;
    const data = {
      id: updateData?.objectId,
      deliveryUserId: userId,
      username: userName,
      driverName,
      contactNumber,
      vehicleNumber,
    };
    try {
      dispatch(updateSpinnerState(true));
      const response = await apiCall(
        apiCallConsts.PUT_METHOD,
        `internal/ibt`,
        data
      );
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'success',
          title: '',
          message: 'Update Successful!',
        })
      );
      setShowAssignModal(false);
    } catch (e) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: 'Cannot be updated!',
          message: e.message,
        })
      );
    }
  };

  const handleEmpty = async (fun) => {
    fun(() => '');
    // await fetchData();
    setFlag((flag) => !flag);
  };

  const [selectedAgent, setSelectedAgent] = useState(null); // Track the selected agent

  // Sample agent data
  const agents = [
    {
      name: 'John Doe',
      contact: '76859374635',
      distance: 1.5,
      currentlyAssigned: 3,
      isRecommended: true,
    },
    {
      name: 'Jane Smith',
      contact: '76859374635',
      distance: 2.0,
      currentlyAssigned: 5,
    },
    {
      name: 'Sam Wilson',
      contact: '76859374635',
      distance: 2.2,
      currentlyAssigned: 4,
      isRecemonded: true,
    },
    {
      name: 'Chris Johnson',
      contact: '76859374635',
      distance: 3.5,
      currentlyAssigned: 8,
    },
  ];

  const handleAssignAgent = (agent) => {
    // Logic to assign agent (e.g., API call or state update)
    console.log('Assigned Agent:', agent);
    setSelectedAgent(agent); // Set the selected agent
  };

  return (
    <>
      <h4 className="mt-2">{t('Received')}</h4>
      <div className="d-flex justify-content-end gap-3 mb-3">
        <Button
          variant="white"
          size="md"
          className="btn-export"
          onClick={() => setOpenFilter(!openFilter)}
        >
          <FontAwesomeIcon icon={faSliders} />
          &nbsp;&nbsp;{t('Filter')}
        </Button>
        <Button
          variant="white"
          size="md"
          className="btn-export"
          onClick={fetchData}
        >
          <FontAwesomeIcon icon={faRotateRight} />
          &nbsp;&nbsp; {t('Refresh')}
        </Button>
        <ExcelDownloadReport data={data || []} fileName="ReceivedIBT" />
      </div>
      <>
        <div
          className="table-settings mt-2 mb-4"
          style={{
            background: '#EAEDF2',
            maxHeight: openFilter ? '700px' : '0px',
            overflow: openFilter ? '' : 'hidden', // Ensure content is hidden when collapsed
            transition: 'max-height 0.7s ease-in-out', // Smooth transition when toggling
          }}
        >
          <div style={{ gap: 8, display: 'flex' }}>
            <Form.Group
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(3, 1fr)', // Three columns
                gap: '20px', // Gap between elements
                width: '100%',
                backgroundColor: 'white',
                padding: 20,
              }}
            >
              <div>
                <Form.Label>VAN NO.</Form.Label>
                <Form.Control
                  style={{
                    height: 38,
                    width: '100%',
                  }}
                  isInvalid={!isValidInput}
                  required
                  type="text"
                  value={vanNo}
                  onChange={(event) => {
                    setVanNo(event.target.value);
                  }}
                  placeholder="Enter the VAN no."
                />
              </div>

              <div>
                <Form.Label>CURRENT STATUS</Form.Label>
                <EditableDropDown
                  id={'CurrentStatus'}
                  onChange={(e) => setStatus(e.value)}
                  style={{
                    height: 38,
                    width: '100%',
                  }}
                  value={status}
                  options={arrayToDropdownOptions(
                    Object.values(IBTdropDownStatus)
                  )}
                  placeholder={'Choose the Current Status'}
                />
              </div>

              <div>
                <Form.Label>START DATE</Form.Label>
                <Form.Control
                  style={{
                    height: 38,
                    width: '100%',
                  }}
                  isInvalid={!isValidInput}
                  required
                  type="date"
                  value={startDate}
                  defaultValue={null}
                  onChange={(event) => {
                    setIsValidInput(true);
                    setStartDate(event.target.value);
                  }}
                  placeholder="Start Date"
                />
              </div>

              <div>
                <Form.Label>END DATE</Form.Label>
                <Form.Control
                  style={{
                    height: 38,
                    width: '100%',
                  }}
                  isInvalid={!isValidInput}
                  required
                  value={endDate}
                  type="date"
                  // defaultValue={searchData}
                  onChange={(event) => {
                    setEndDate(event.target.value);
                  }}
                  placeholder="End Date"
                />
              </div>

              <div
                style={{
                  gridColumn: 'span 3', // Span across all columns
                  display: 'flex',
                  justifyContent: 'flex-end',
                  gap: '10px',
                }}
              >
                <Button
                  variant="white"
                  className="fa-export"
                  onClick={handleReset}
                  style={{
                    width: '150px',
                    height: '38px',
                    borderRadius: 0,
                    background: '#EAEDF2',
                  }}
                >
                  <FontAwesomeIcon icon={faArrowRotateLeft} />
                  &nbsp;&nbsp;{t('Reset')}
                </Button>

                <Button
                  variant="white"
                  className="fa-export"
                  onClick={fetchData}
                  style={{
                    width: '150px',
                    height: '38px',
                    borderRadius: 0,
                    border: '1px solid #262B40',
                  }}
                >
                  <FontAwesomeIcon icon={faSliders} />
                  &nbsp;&nbsp;{t('Apply Filter')}
                </Button>
              </div>
            </Form.Group>
          </div>
        </div>
        {!openFilter &&
          (vanNo != '' || startDate != '' || endDate != '' || status != '') && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div
                className="gap-2"
                style={{
                  display: 'flex',
                  width: '100%',
                  background: '#F5F8FB',
                  padding: 20,
                }}
              >
                {filterObj.map((each) => {
                  if (each.value != '') {
                    return (
                      <div>
                        <Form.Label style={{ color: '#262B4099' }}>
                          {each.label}
                        </Form.Label>
                        <Badge
                          variant="light"
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: '188px',
                            height: '41px',
                            background: 'white',
                            borderRadius: '2px',
                            cursor: 'pointer',
                            color: '#262B40',
                            fontSize: '14px',
                            fontWeight: '400',
                            lineHeight: '16.41px',
                          }}
                        >
                          {each.value}
                          <span
                            onClick={() => handleEmpty(each.func)}
                            style={{
                              color: '#262B40',
                              fontSize: '14px',
                              fontWeight: '400',
                              lineHeight: '16.41px',
                            }}
                          >
                            &times;
                          </span>
                        </Badge>
                      </div>
                    );
                  }
                })}
              </div>
              <Button
                variant="primary"
                className="fa-export"
                onClick={handleReset}
                style={{
                  width: '150px',
                  height: '38px',
                  borderRadius: 0,
                }}
              >
                <FontAwesomeIcon icon={faBroom} />
                &nbsp;&nbsp;{t('Clear')}
              </Button>
            </div>
          )}
      </>

      <TableOnly
        id="IBTReceived"
        data={data || []}
        columns={receivedColumns(handleShowMoreClick, statusMap, actionClicked)}
        isFooterRequired={true}
      />
      {infoModal && (
        <InfoModal
          showModal={infoModal}
          closeModal={() => setInfoModal(false)}
          modalTitile={'All Products Requested'}
          data={infoData}
          columns={infoModalColumns(statusMap, true)}
          size="xl"
        />
      )}

      {showModal && (
        <AcceptModal
          showModal={showModal}
          closeModal={() => {
            setShowModal(false);
            setIsReadOnly(true);
          }}
          modalTitle={'Confirm Acceptance of Transfer Request'}
          data={updateData}
          setData={setUpdateData}
          isParital={isReadOnly}
          handleSubmit={handleSubmit}
        />
      )}
      {rejectModal && (
        <RejectModal
          icon={faBan}
          showModal={rejectModal}
          closeModal={() => setRejectModal(false)}
          modalTitle={'Cancel Transfer Request'}
          modalSubTitle={
            'Are you sure you want to cancel this transfer request?'
          }
          confirmActionName={'confirm'}
          cancelActionName={'cancel'}
          confirmationModalProps={confirmationModalProps}
          setConfirmationModalProps={setConfirmationModalProps}
          handleSubmit={handleRejectSubmit}
        />
      )}

      {showAssignModal && deliveryAgents.length > 0 && (
        <AgentAssignmentModal
          showModal={showAssignModal}
          closeModal={() => setShowAssignModal(false)} // Close modal handler
          modalTitle="Assign an Agent" // Modal title
          agents={deliveryAgents} // Pass agents array
          onAssignAgent={handleModalOnConfirmClick}
          size="xl" // Handle agent assignment
        />
      )}
    </>
  );
};

export default IBTReceived;
