import React from 'react';
import OrderPage from './components/OrderPage';
import { orderStatus } from '../../../constants/orderStatus';

const OutForDelivery = () => {
  return (
    <div>
      <OrderPage type={orderStatus.outForDelivery} />
    </div>
  );
};

export default OutForDelivery;
