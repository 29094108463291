import React, { useState } from 'react';
import { Button, Form, Modal } from '@themesberg/react-bootstrap';
// import { generateRandomId } from '../../../utils/string';
import { faCheckCircle, faPlus } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TableOnly from '../../../scheme-management/Components/IndividualScheme.js/TableOnly';
import { styles } from '../../inventory/style';
import { StyledSearchBar } from '../../../trips/styles';
import { updateModalColumns } from '../utils';
import { returnUserReadableDate } from '../../../../utils/datetime';
import { generateRandomId } from '../../../../utils/string';
import Joi from 'joi';
import { returnValidationErrorMessages } from '../../../../utils/validation';

const createSchema = Joi.object({
  receivingContactPerson: Joi.string().required(),
  receivingContactNumber: Joi.string().length(10).required(),
});

const AcceptModal = ({
  showModal,
  closeModal,
  modalTitle,
  isParital,
  data,
  setData,
  handleSubmit,
}) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    receivingContactPerson: '',
    receivingContactNumber: '',
  });
  const [formErrors, setFormErrors] = useState({});
  const [updatedProducts, setUpdatedProducts] = useState(data?.products || []);
  //   console.log(data?.products);
  //   console.log(isParital);
  // console.log(updatedProducts);

  const onFormInputChange = (fieldName, value) => {
    setFormErrors((prev) => ({ ...prev, [fieldName]: null }));
    setFormData((oldFormData) => ({
      ...oldFormData,
      [fieldName]: value,
    }));
  };

  const handleSubmitClick = () => {
    const { error: formErrors = {}, value: formValue } = createSchema.validate(
      formData,
      {
        abortEarly: false,
      }
    );
    const { details = {} } = formErrors;
    if (details?.length > 0) {
      const validationErrorMessages = returnValidationErrorMessages(details);
      setFormErrors(validationErrorMessages);
    } else {
      setData((prev) => ({
        ...prev,
        products: [...updatedProducts],
      }));
      handleSubmit(updatedProducts, formData);
      setFormData({
        receivingContactPerson: '',
        receivingContactNumber: '',
      });
    }
  };

  return (
    <Modal
      id={generateRandomId()}
      as={Modal.Dialog}
      centered
      show={showModal}
      onHide={closeModal}
      size="xl"
    >
      <Modal.Header className="d-flex justify-content-center align-content-center">
        <div className="d-flex justify-content-center gap-2 align-content-center flex-column m-2">
          <div className="fs-4 d-flex  justify-content-center">
            <FontAwesomeIcon size="lg" icon={faCheckCircle} />
          </div>
          <div className="fs-4">{t(modalTitle)}</div>
        </div>
      </Modal.Header>
      <Modal.Body className="bg-light px-3">
        <div className="d-flex justify-content-evenly gap-3 pt-2 mb-2">
          <div style={styles.filters}>
            <p style={styles.filterTitle}>{t('Requesting Branch')}</p>
            <StyledSearchBar
              width={'100%'}
              heigth={'45px'}
              type={'text'}
              placeholder={data?.requestingBranchName || ''}
              disabled={true}
            />
          </div>
          <div style={styles.filters}>
            <p style={styles.filterTitle}>{t('Delivery Date')}</p>
            <StyledSearchBar
              width={'100%'}
              heigth={'45px'}
              type={'text'}
              placeholder={
                returnUserReadableDate(data?.preferredDeliveryDate) || ''
              }
              disabled={true}
            />
          </div>
        </div>
        <div className="d-flex justify-content-evenly gap-3 pt-2 mb-2">
          <Form.Group style={styles.filters}>
            <Form.Label style={styles.filterTitle}>
              {t('Contact Person')}
            </Form.Label>
            <Form.Control
              // style={styles.filterInputs}
              // className="bg-light"
              required
              value={formData?.receivingContactPerson || ''}
              isInvalid={formErrors?.['receivingContactPerson']?.length}
              type="text"
              onChange={(event) => {
                onFormInputChange('receivingContactPerson', event.target.value);
              }}
            />
            {formErrors?.['receivingContactPerson']?.length && (
              <Form.Control.Feedback type="invalid">
                {t('Please enter contact name.')}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group style={styles.filters}>
            <Form.Label style={styles.filterTitle}>
              {t('Contact Number')}
            </Form.Label>
            <Form.Control
              // style={styles.filterInputs}
              // className="bg-light"
              required
              value={formData?.receivingContactNumber || ''}
              isInvalid={formErrors?.['receivingContactNumber']?.length}
              type="number"
              onChange={(event) => {
                onFormInputChange('receivingContactNumber', event.target.value);
              }}
            />
            {formErrors?.['receivingContactNumber']?.length && (
              <Form.Control.Feedback type="invalid">
                {t('Please enter proper Number.')}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </div>
        <TableOnly
          id="AcceptModal"
          data={data?.products || []}
          columns={updateModalColumns(isParital, setUpdatedProducts)}
        />

        {!isParital && (
          <div className="bg-white">
            <div style={styles.filters} className="mt-2 p-3">
              <p style={styles.filterTitle}>{'Remarks'}</p>
              <StyledSearchBar
                width={'100%'}
                height={'45px'}
                type={'text'}
                placeholder={'please enter remarks'}
                onChange={(e) => {
                  const { value } = e.target;
                  setData((prev) => ({ ...prev, remarks: value }));
                }}
              />
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-end">
        <Button
          variant="link"
          className="text-gray ms-auto"
          onClick={closeModal}
        >
          {t('Cancel')}
        </Button>
        <Button
          variant="primary"
          onClick={handleSubmitClick}
          disabled={
            !isParital &&
            updatedProducts.some((products) => !products?.receivedQuantity)
          }
        >
          {t('Confirm')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AcceptModal;
