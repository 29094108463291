import React, { useEffect, useRef, useState } from "react";
import { Button } from "@themesberg/react-bootstrap";
import { useTranslation } from "react-i18next";
import { ExcelRenderer } from "react-excel-renderer";
import { useFileUpload } from "react-use-file-upload/dist/lib/useFileUpload";
import { useDispatch } from "react-redux";
import { updateToastInfo } from "../../../actions/settings";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";

const FileUploadButton = (props) => {
  const { buttonText = "Upload", onFileUpload } = props;
  const { t } = useTranslation();
  const inputRef = useRef();
  const dispatch = useDispatch();
  const [disableUpload, setDisableUpload] = useState(false);

  const { files, setFiles } = useFileUpload();

  useEffect(() => {
    try {
      if (files.length > 0) {
        setDisableUpload(true);
        // When adding more than once, we need to take the last uploaded file
        ExcelRenderer(files[files.length - 1], async (err, resp) => {
          if (err) {
            dispatch(
              updateToastInfo({
                show: true,
                type: "danger",
                title: "Upload Failed",
                message: "Upload & Read File failed. Contact Support",
              })
            );
          } else {
            onFileUpload(resp);
          }
        });
      }
    } catch (e) {
      dispatch(
        updateToastInfo({
          show: true,
          type: "danger",
          title: "File Upload Failed",
          message: "File upload failed. Contact Support",
        })
      );
    }
  }, [files]);

  return (
    <>
      <Button
        onClick={() => inputRef.current.click()}
        variant="primary"
        className="m-1"
      >
        <FontAwesomeIcon icon={faUpload} className="me-2" />
        &nbsp;{t(buttonText)}
      </Button>
      <input
        ref={inputRef}
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        tabindex="-1"
        type="file"
        className="form-control"
        onChange={(e) => {
          setFiles(e, "a");
          inputRef.current.value = null;
        }}
        style={{ display: "none" }}
      />
    </>
  );
};
export default FileUploadButton;
