import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { faDownload, faMobileAlt } from '@fortawesome/free-solid-svg-icons';
import QRCode from 'react-qr-code'; // Ensure you have this installed via npm
// import { Button, Modal } from 'bootstrap';
import { generateRandomId } from '../../utils/string';
import { Button, Modal } from '@themesberg/react-bootstrap';

const DownloadModal = ({ showModal, closeModal, downloadLink }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showQRCode, setShowQRCode] = useState(false);

  const handleDownloadNow = () => {
    window.open(downloadLink, '_blank'); // Redirects to the download link in a new tab
  };

  const handleDownloadMobile = () => {
    setShowQRCode(true); // Show the QR code modal
  };

  const handleCloseQRCode = () => {
    setShowQRCode(false); // Close the QR code modal
  };

  const handleClose = () => {
    closeModal(); // Close the main modal
  };

  return (
    <>
      <Modal
        id={generateRandomId()}
        as={Modal.Dialog}
        centered
        show={showModal}
        onHide={handleClose}
        size="lg"
      >
        <Modal.Body className="m-1 p-4">
          <div className="d-flex flex-column gap-3">
            <div className="d-flex justify-content-between align-content-center mb-4">
              <div style={{ marginLeft: '35%' }} className="fs-4">
                {t('Download Options')}
              </div>{' '}
              <Button
                variant="close"
                aria-label="Close"
                onClick={handleClose}
              />
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center gap-3">
              <div className="fs-5">
                {t('Please choose how you would like to download the App.')}
              </div>
              <div className="d-flex gap-5">
                <Button variant="primary" onClick={handleDownloadNow}>
                  <FontAwesomeIcon icon={faDownload} />
                  &nbsp;&nbsp;{t('Download Now')}
                </Button>
                <Button variant="secondary" onClick={handleDownloadMobile}>
                  <FontAwesomeIcon icon={faMobileAlt} />
                  &nbsp;&nbsp;{t('Download on Mobile')}
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* QR Code Modal */}

      <Modal
        id={generateRandomId()}
        centered
        show={showQRCode}
        onHide={handleCloseQRCode}
        size="md"
      >
        <Modal.Body className="m-2 p-4 d-flex flex-column align-items-center">
          <div className="text-center">
            <h4 className="mb-4">{t('Scan QR Code to Download')}</h4>
            <div
              style={{
                padding: '30px',
                animation: 'fadeIn 0.5s ease-in-out',
              }}
            >
              <QRCode value={downloadLink} size={250} />
            </div>
            <p className="mt-4 text-muted" style={{ fontSize: '1.1rem' }}>
              {t(
                'Scan this QR code with your mobile device to download the App.'
              )}
            </p>
            <Button
              variant="outline-secondary"
              onClick={handleCloseQRCode}
              className="mt-4"
              style={{ fontSize: '1rem', padding: '8px 24px' }}
            >
              {t('Close')}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DownloadModal;
