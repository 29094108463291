import React from "react";
import { Breadcrumb } from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Routes } from "../../router/routes";
import { useNavigate } from "react-router-dom";
import { faHome } from "@fortawesome/free-solid-svg-icons";

const BreadCrumbComponent = (props) => {
    const navigate = useNavigate();
    const {
        items
    } = props;
    return (
        <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item
                onClick={() => {
                    navigate("/");
                }}>
                <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            {items?.map((item, index) => (
                <Breadcrumb.Item
                    active={index === items?.length - 1}
                    onClick={() => {
                        if (item.link) {
                            navigate(item.link);
                        }
                    }}
                >{item?.title}</Breadcrumb.Item>
            ))}
        </Breadcrumb>
    )
}
export default BreadCrumbComponent;