import React, { useState } from "react";
import { Badge, Button, Col, Form, Modal, Row } from "@themesberg/react-bootstrap";
import { useTranslation } from "react-i18next";
import DropdownComponent from "../../components/common/Dropdown";
import { RequisitionStatusMap, requisitionApprovalBulkActionOptions, requisitionBulkActionOptions } from "./utils";
import { useDispatch } from "react-redux";
import { updateToastInfo } from "../../actions/settings";
import { getTripBadgeStatus } from "../../utils/trips";

const RequisitionUpdateModal = (props) => {
    const {
        showUpdateModal = false,
        handleCloseModal,
        askRequisitionTransferNo = false,
        addRemarkAndUpdateRequisition,
        requisitonItems,
        selectedStatus,
        readOnly = false,
        isApprovalMode = false
    } = props;
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({});
    const { t } = useTranslation();
    const [requisitionTransferNo, setRequisitionTransferNo] = useState(null);

    const handleChangeInput = (reqId, field, value) => {
        if (!formData[reqId]) {
            formData[reqId] = {};
        }
        formData[reqId][field] = value;
        setFormData(formData);
    }
    const handlAddRemarkAndUpdateRequisition = () => {
        if (askRequisitionTransferNo && !requisitionTransferNo) {
            dispatch(updateToastInfo({
                show: true,
                type: "danger",
                title: "Transfer Number missing",
                message: "Please add Transfer Number"
            }));
        } else {
            addRemarkAndUpdateRequisition({
                selectedStatus,
                formData,
                reqItems: requisitonItems.products,
                requisitionTransferNo
            })
        }

    };
    return (
        <Modal backdrop={"static"} dialogClassName="modal-90w" size="xl" show={showUpdateModal} onHide={handleCloseModal}>
            <Modal.Header>
                <Modal.Title className="h6">{t(readOnly ? "See Requisition Details" : "Update Quantity & Status")}</Modal.Title>
                <Button variant="close" aria-label="Close" onClick={handleCloseModal} />
            </Modal.Header>
            <Modal.Body>
                {(askRequisitionTransferNo) && (
                    <Form.Group className="mb-3 mt-4">
                        <Form.Label>{t("Transfer Number")}</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            onChange={(event) => {
                                setRequisitionTransferNo(event.target.value);
                            }} />
                    </Form.Group>
                )}
                <Form.Group className="mb-3 mt-4">
                    {requisitonItems?.products?.map((product) => {
                        const isRejected = product?.status === "rejected";
                        return (
                            <Row>
                                <Col>
                                    <Form.Label>{t("Product Name")}</Form.Label>
                                    <Form.Control
                                        required
                                        disabled
                                        defaultValue={product?.product?.productName}
                                        type="text" />
                                </Col>
                                <Col>
                                    <Form.Label>{t("Quantity")}</Form.Label>
                                    <Form.Control
                                        disabled={readOnly}
                                        required
                                        defaultValue={Number(product?.quantity)}
                                        type="number"
                                        onChange={(event) => {
                                            handleChangeInput(product?.reqId, "quantity", event.target.value)
                                        }}
                                    />
                                </Col>
                                <Col>
                                    <Form.Label>{t("Remarks")}</Form.Label>
                                    <Form.Control
                                        required
                                        disabled={readOnly}
                                        defaultValue={product?.remarks}
                                        type="text"
                                        onChange={(event) => {
                                            handleChangeInput(product?.reqId, "remarks", event.target.value)
                                        }} />
                                </Col>
                                <Col>
                                    <Form.Label>{t("Status")}</Form.Label>
                                    {readOnly ? (
                                        <div><Badge bg={getTripBadgeStatus(product?.status)} className="badge-lg">{RequisitionStatusMap[product.status]}</Badge></div>
                                    ) : (
                                        <DropdownComponent
                                            disabled={readOnly || isRejected}
                                            placeholder={isRejected ? RequisitionStatusMap["rejected"] : RequisitionStatusMap[selectedStatus]}
                                            onChange={(option) => {
                                                handleChangeInput(product?.reqId, "status", option.value)
                                            }}
                                            options={isApprovalMode ? requisitionApprovalBulkActionOptions : requisitionBulkActionOptions}
                                        />
                                    )}

                                </Col>
                            </Row>
                        )
                    })}
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                {!readOnly && (
                    <Button variant="secondary" onClick={handlAddRemarkAndUpdateRequisition}>
                        {t("Update Status")}
                    </Button>
                )}
                <Button variant="link" className="text-gray ms-auto" onClick={handleCloseModal}>
                    {t("Cancel")}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
export default RequisitionUpdateModal;