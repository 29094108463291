import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { updateToastInfo } from '../../../../actions/settings';
import { updateSpinnerState } from '../../../../actions/spinner';
import ParcelStatusPage from '../../../../components/common/parcelStatusPages';
import ConfirmationModal from '../../../../components/common/confirmationModal';
import arrayToBreadCrumbs from '../../../../utils/sms/arrayToBreadCrumbs';
import { schemeTableColumns } from '../../../../utils/sms/schemeTable';
import {
  editScheme,
  getAllSchemes,
  updateSchemesStatus,
} from '../../../../parse-functions/sms/scheme';
import SchemeEditModal from '../SchemeEditModal';
import SchemeInfoModal from './SchemeInfoModal';
import { infoTable } from '../../../../utils/sms/infoTable';
import { useNavigate } from 'react-router-dom';
import { convertSchemeDataToTableData } from '../../../../utils/sms/convertProductsToDropdownOptions';
import { setExcelProductUploadDataInStore } from '../../../../actions/sms/productsExcelUpload';
import { bulkAddSchemeColumns } from '../../../../utils/sms/BulkAddSchemeColumns';
import BulkAddSchemeModal from '../BulkAddSchemeModal';

const DraftSchemes = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userName = useSelector((state) => state.user.user.username || {});

  const isBrandManager = useSelector(
    (state) => state?.user?.preferences?.isBrandManager || false
  );

  const isAdmin = useSelector(
    (state) => state?.user?.preferences?.isAdmin || false
  );

  const isBrandVendor = useSelector(
    (state) => state?.user?.preferences?.isBrandVendor || false
  );

  const role = isAdmin
    ? 'isAdmin'
    : isBrandManager
    ? 'isBrandManager'
    : isBrandVendor
    ? 'isBrandVendor'
    : 'Unknown';

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedSchemes, setSelectedSchemes] = useState({});
  const [updateMode, setUpdateMode] = useState('');
  const [schemeData, setSchemeData] = useState([]);
  const [updatedSchemeData, setUpdatedSchemeData] = useState([]);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [infoData, setInfoData] = useState();
  const [confirmationModalProps, setConfirmationModalProps] = useState([]);
  const [showFormEditModal, setShowFormEditModal] = useState(false);
  const [editData, setEditData] = useState();
  const [showProductsModal, setShowProductsModal] = useState(false);
  const [id, setId] = useState();

  const breadCrumbItems = arrayToBreadCrumbs([[t('Schemes')], [t('Draft')]]);

  const fetchDraftSchemes = async () => {
    try {
      dispatch(updateSpinnerState(true));
      const response = await getAllSchemes({
        status: ['draft'],
        role: role,
      });
      setSchemeData(convertSchemeDataToTableData(response));
      setUpdatedSchemeData(response);
    } catch (error) {
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: 'Cannot fetch at the moment!',
          message: 'Please try again!',
        })
      );
    } finally {
      dispatch(updateSpinnerState(false));
    }
  };

  const onRefreshClick = () => {
    try {
      fetchDraftSchemes();
      setSelectedSchemes({});
      dispatch(
        updateToastInfo({
          show: true,
          type: 'success',
          title: 'Refreshed Schemes!',
          message: 'Schemes fetched successfully!',
        })
      );
    } catch (error) {
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: 'Error fetching Schemes!',
          message: error.message,
        })
      );
    }
  };

  const onRowSelect = (selected, objectId) => {
    setSelectedSchemes((prev) => {
      if (!selected) {
        const { [objectId]: _, ...newState } = prev;
        return newState;
      }

      return {
        ...prev,
        [objectId]: selected,
      };
    });
    setSchemeData((prev) =>
      prev.map((item) =>
        item.objectId === objectId ? { ...item, isSelected: selected } : item
      )
    );
  };

  const handleShowDeleteModal = () => {
    setShowDeleteModal(true);
  };

  const bulkActionOptions = isBrandVendor
    ? [
        {
          label: 'Edit',
          value: 'edit',
        },
        {
          label: 'View',
          value: 'view',
        },
      ]
    : [
        {
          label: 'View',
          value: 'view',
        },
        {
          label: 'Edit',
          value: 'edit',
        },
        {
          label: 'Accept',
          value: 'accept',
        },
        {
          label: 'Reject',
          value: 'reject',
        },
        {
          label: 'Negotiate',
          value: 'negotiate',
        },
      ];

  const handleSelectedRowsUpdate = (actionId) => {
    if (Object.keys(selectedSchemes).length > 1) {
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('Max 1 scheme to select'),
          message: t('Please select maximum of 1 scheme for editing'),
        })
      );
      return;
    } else {
      switch (actionId) {
        case 'accept': {
          setUpdateMode('Accept');
          handleShowDeleteModal();
          break;
        }
        case 'reject': {
          setUpdateMode('Reject');
          setConfirmationModalProps([
            {
              type: 'textArea',
              title: 'Remarks',
              value: '',
            },
          ]);
          handleShowDeleteModal();
          break;
        }
        case 'negotiate': {
          setUpdateMode('Negotiate');
          handleShowDeleteModal();
          break;
        }
        case 'edit': {
          let data = updatedSchemeData?.find(
            (scheme) => scheme.objectId === Object.keys(selectedSchemes)[0]
          );
          setEditData(data);
          dispatch(setExcelProductUploadDataInStore(data?.products));
          setShowFormEditModal(true);
          break;
        }
        case 'view': {
          navigate(`/scheme/${Object.keys(selectedSchemes)[0]}/details`);
          break;
        }
        default: {
          break;
        }
      }
    }
  };
  const handleOnConfirmClick = async (data) => {
    try {
      dispatch(updateSpinnerState(true));
      await updateSchemesStatus(data);
      await fetchDraftSchemes();
      setId();
      setSelectedSchemes({});
      setConfirmationModalProps([]);
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'success',
          title: `Action Successful`,
          message: '',
        })
      );
      setShowDeleteModal(false);
    } catch (e) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: 'Action cannot be done!',
          message: 'Please try again',
        })
      );
    }
  };
  const handleModalOnConfirmClick = () => {
    const selectedSchemeData = {
      schemeId: Object.keys(selectedSchemes),
      userName: userName,
      status:
        updateMode === 'Accept'
          ? 'ongoing'
          : updateMode === 'Reject'
          ? 'rejected'
          : 'draft',
      actionType: updateMode === 'Negotiate' ? 'negotiation' : '',
      reason: confirmationModalProps[0]?.value,
    };
    handleOnConfirmClick(selectedSchemeData);
  };

  const handleCloseFormEditModal = () => {
    setShowFormEditModal(false);
    setEditData(null);
    dispatch(setExcelProductUploadDataInStore([]));
  };

  const handleFormSave = async (data) => {
    dispatch(updateSpinnerState(true));
    await editScheme(data);
    fetchDraftSchemes();
    handleCloseFormEditModal();
    setSelectedSchemes({});
  };

  const handleInfoClick = (cell) => {
    setInfoData(cell);
    setShowInfoModal(true);
  };

  const handleMoreProductsClick = (id) => {
    setId(id);
    setShowProductsModal(true);
  };

  useEffect(() => {
    if (role !== 'Unknown') {
      fetchDraftSchemes();
    }
  }, [role]);
  return (
    <>
      <ParcelStatusPage
        pageType={'draftSchemes'}
        showDateFilters={false}
        tableData={schemeData || []}
        tableColumns={schemeTableColumns(
          onRowSelect,
          'draft',
          navigate,
          handleInfoClick,
          handleMoreProductsClick
        )}
        handleRefresh={onRefreshClick}
        pageTitle={t('Draft Schemes')}
        breadCrumbItems={breadCrumbItems}
        pageSubTitle={t('All the newly created schemes')}
        enableBulkActions={Object.keys(selectedSchemes).length > 0}
        bulkActionOptions={bulkActionOptions}
        handleItemBulkUpdate={handleSelectedRowsUpdate}
      />
      <ConfirmationModal
        showModal={showDeleteModal}
        onConfirm={handleModalOnConfirmClick}
        closeModal={() => {
          setShowDeleteModal(false);
        }}
        modalBody={t(`Are you sure you want to ${updateMode?.toLowerCase()}?`)}
        modalTitle={t(`${updateMode} Scheme(s)`)}
        confirmationModalProps={confirmationModalProps}
        setConfirmationModalProps={setConfirmationModalProps}
      />
      <SchemeEditModal
        editData={editData}
        show={showFormEditModal}
        handleClose={handleCloseFormEditModal}
        topTitle="Edit Scheme"
        handleSave={handleFormSave}
      />
      <SchemeInfoModal
        showModal={showInfoModal}
        closeModal={() => setShowInfoModal(false)}
        data={infoData}
        columns={infoTable()}
      />
      {id && (
        <BulkAddSchemeModal
          data={
            updatedSchemeData?.filter((item) => item.objectId === id)[0]
              ?.products || []
          }
          showModal={showProductsModal}
          closeModal={() => setShowProductsModal(false)}
          columns={bulkAddSchemeColumns()}
        />
      )}
    </>
  );
};
export default DraftSchemes;
