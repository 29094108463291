export const accountsUserSchema = [
  [
    {
      title: 'Accounts User Info',
      type: 'header',
    },
    {
      title: 'Name',
      id: 'name',
      type: 'text',
      required: true,
    },
    {
      title: 'Phone Number',
      id: 'phoneNumber',
      type: 'number',
      required: true,
    },
    {
      title: 'Email Id',
      id: 'emailId',
      type: 'email',
      required: true,
    },
  ],

  [
    {
      title: 'Login Info',
      type: 'header',
    },
    {
      title: 'UserName',
      id: 'userName',
      type: 'text',
      required: true,
    },
    {
      title: 'Password',
      id: 'password',
      type: 'text',
      required: true,
    },
    {
      title: 'Repeat Password',
      id: 'repeatPassword',
      type: 'text',
      required: true,
    },
    {
      title: 'Create user',
      type: 'button',
      width: '30%',
    },
  ],
];
