import { faHome, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Alert,
  Breadcrumb,
  Button,
  Col,
  Form,
  Row,
} from "@themesberg/react-bootstrap";
import Joi from "joi";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { updateToastInfo } from "../../actions/settings";
import { updateSpinnerState } from "../../actions/spinner";
import { returnValidationErrorMessages } from "../../utils/validation";
import { addNewIncharge } from "../../parse-functions/incharges";
import { applicationList } from "../../constants/applications";
import EditableDropDown from "../../components/common/EditableDropdown";
import {
  removeUnusedWarehousesFromList,
  returnWarehouseDropdownOptions,
} from "../../utils/warehouse";
import { setCurrentWarehouseInfoInStore } from "../../actions/user";
import { getCurrentWarehouseInfo } from "../../parse-functions/warehouse";

const addInchargeSchema = Joi.object({
  inchargeName: Joi.string().required(),
  phoneNumber: Joi.number().required().greater(1000000000).less(10000000000),
  emailId: Joi.string().email({ tlds: { allow: false } }),
  applications: Joi.array().min(1).required().items(Joi.string()),
  username: Joi.string().required(),
  password: Joi.string().required(),
  repeatPassword: Joi.ref("password"),
  storeName: Joi.string().required(),
  zone: Joi.string().required(),
});

const editInchargeSchema = Joi.object({
  inchargeName: Joi.string(),
  phoneNumber: Joi.number().greater(1000000000).less(10000000000),
  emailId: Joi.string().email({ tlds: { allow: false } }),
  storeName: Joi.string().required(),
  zone: Joi.string().allow(""),
}).options({
  allowUnknown: true,
});

const AddIncharge = (props) => {
  const emptyUser = {
    inchargeName: "",
    phoneNumber: "",
    emailId: "",
    applications: [],
    username: "",
    password: "",
    repeatPassword: "",
  };
  const { edit = false, user = emptyUser, onConfirm } = props;
  const { t } = useTranslation();
  const storeName = useSelector(
    (state) => state.user.preferences.storeName || ""
  );
  const warehouseZone = useSelector((state) => state.user.warehouse.zone || "");
  const dispatch = useDispatch();
  const configData = useSelector((state) => state.user.config || {});
  const [formErrors, setFormErrors] = useState({});
  const isHO = useSelector((state) => state?.user?.preferences?.isHO || false);
  const [formData, setFormData] = useState(user);
  const otherWarehouses = useSelector(
    (state) => state?.user?.otherWarehousesInZone || {}
  );
  const preferences = useSelector((state) => state?.user?.preferences || {});

  useEffect(() => {
    async function getWareHouseData() {
      const warehouseInfo = await getCurrentWarehouseInfo({
        warehouseName: preferences?.storeName,
        isSuperAdmin: true,
      });
      const finalWarehouseList = removeUnusedWarehousesFromList(
        warehouseInfo.otherWarehousesInZone
      );
      warehouseInfo.otherWarehousesInZone = finalWarehouseList;
      dispatch(setCurrentWarehouseInfoInStore(warehouseInfo));
    }
    if (!otherWarehouses || Object.keys(otherWarehouses)?.length < 1) {
      getWareHouseData();
    }
  }, []);
  const onFormInputChange = (fieldName, value) => {
    setFormData((oldFormData) => ({
      ...oldFormData,
      [fieldName]: value,
    }));
  };
  const handleFormSubmit = async () => {
    if (edit) {
      // eslint-disable-next-line no-unused-expressions
      formData.name === formData.inchargeName;
    }
    const validationSchemaName = edit ? editInchargeSchema : addInchargeSchema;
    const { error: formErrors = {}, value: formValue } =
      validationSchemaName.validate(formData, {
        abortEarly: false,
      });
    const { details = {} } = formErrors;
    if (details?.length > 0) {
      const validationErrorMessages = returnValidationErrorMessages(details);
      setFormErrors(validationErrorMessages);
    } else {
      if (edit) {
        onConfirm(formValue);
      } else {
        dispatch(updateSpinnerState(true));
        try {
          let config = configData.zones[formValue.zone];
          formValue.configData = config;
          formValue.isHO = isHO;
          await addNewIncharge(formValue);
          dispatch(
            updateToastInfo({
              show: true,
              type: "success",
              title: t("Incharge Added"),
              message: t(`${formData.inchargeName} added successfully`),
            })
          );
        } catch (error) {
          // inside error
          const { message, code } = error;
          setFormErrors(message);
          dispatch(
            updateToastInfo({
              show: true,
              type: "danger",
              title: t("Incharge Add Failed"),
              message: t(`${formData.inchargeName} failed to add`),
            })
          );
        }
        dispatch(updateSpinnerState(false));
      }
    }
  };
  return (
    <div className="mt-1">
      {!edit && (
        <div className="mb-4 d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-2">
          <div className="d-block mb-4 mb-md-0">
            <Breadcrumb
              className="d-none d-md-inline-block"
              listProps={{
                className: "breadcrumb-dark breadcrumb-transparent",
              }}
            >
              <Breadcrumb.Item>
                <FontAwesomeIcon icon={faHome} />
              </Breadcrumb.Item>
              <Breadcrumb.Item>{t("Incharge")}</Breadcrumb.Item>
              <Breadcrumb.Item active>{t("Add")}</Breadcrumb.Item>
            </Breadcrumb>
            <h4>{t("Add New Incharge")}</h4>
            <p className="mb-0">{t("Use this page to add a new incharge")}</p>
          </div>
        </div>
      )}

      <Row className="mt-3">
        <Col>
          <Alert variant="info">
            <FontAwesomeIcon icon={faHome} />
            &nbsp;&nbsp;{t("Incharge Info")}
          </Alert>
          <Form.Group className="mb-3 mt-4">
            <Form.Label>{t("Incharge Name")}</Form.Label>
            <Form.Control
              required
              value={formData.inchargeName || formData.name}
              isInvalid={formErrors["inchargeName"]?.length}
              type="text"
              onChange={(event) => {
                onFormInputChange("inchargeName", event.target.value);
              }}
            />
            {formErrors["inchargeName"]?.length && (
              <Form.Control.Feedback type="invalid">
                {t("Please enter Incharge name.")}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Phone Number</Form.Label>
            <Form.Control
              required
              value={formData.phoneNumber}
              isInvalid={formErrors["phoneNumber"]?.length}
              type="number"
              onChange={(event) => {
                onFormInputChange("phoneNumber", event.target.value);
              }}
            />
            {formErrors["phoneNumber"]?.length && (
              <Form.Control.Feedback type="invalid">
                {t("Please enter phonenumber.")}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Email Id</Form.Label>
            <Form.Control
              required
              value={formData.emailId}
              isInvalid={formErrors["emailId"]?.length}
              type="email"
              onChange={(event) => {
                onFormInputChange("emailId", event.target.value);
              }}
            />
            {formErrors["emailId"]?.length && (
              <Form.Control.Feedback type="invalid">
                {t(formErrors["emailId"])}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Select Application</Form.Label>
            <EditableDropDown
              isMultiSelect
              value={formData?.applications}
              onChange={(value) => {
                onFormInputChange(
                  "applications",
                  value?.map((item) => item.value)
                );
              }}
              options={applicationList?.map((appl) => ({
                value: appl.value,
                label: t(appl.label),
              }))}
              placeholder={t("Select Applications to give access")}
            />
            {formErrors["applications"]?.length > 0 && (
              <span
                type="invalid"
                style={{
                  color: "red",
                }}
              >
                {t(formErrors["applications"])}
              </span>
            )}
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>{t("Warehouse / Store Name")}</Form.Label>
            <EditableDropDown
              value={formData.storeName}
              onChange={(option) => {
                const matchingWarehouse = Object.values(
                  otherWarehouses
                )?.filter(
                  (warehouse) => warehouse?.warehouseCode === option.value
                );
                setFormData((oldData) => ({
                  ...oldData,
                  storeName: option.value,
                  zone: matchingWarehouse[0].zone,
                }));
              }}
              options={returnWarehouseDropdownOptions(otherWarehouses)}
              placeholder={t("Select Target / Delivery branch")}
            />
          </Form.Group>
          {!edit && (
            <Form.Group className="mb-3">
              <Form.Label>{t("Zone")}</Form.Label>
              <Form.Select disabled>
                {<option>{warehouseZone || formData.zone}</option>}
              </Form.Select>
              {formErrors["zone"]?.length && (
                <Form.Control.Feedback type="invalid">
                  {t("Please choose a zone")}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          )}
          {edit && (
            <div className="d-flex justify-content-between">
              <Button variant="primary" onClick={handleFormSubmit}>
                {t("Update User")}
              </Button>
              <Button className="mt-3" variant="outline">
                {t("Cancel")}
              </Button>
            </div>
          )}
        </Col>
        {!edit && (
          <Col>
            <Alert variant="info">
              <FontAwesomeIcon icon={faUserCircle} />
              &nbsp;&nbsp;{t("Login Info")}
            </Alert>
            <Form.Group className="mb-3 mt-4">
              <Form.Label>{t("UserName")}</Form.Label>
              <Form.Control
                required
                isInvalid={formErrors["username"]?.length}
                type="text"
                onChange={(event) => {
                  onFormInputChange("username", event.target.value);
                }}
              />
              {formErrors["username"]?.length && (
                <Form.Control.Feedback type="invalid">
                  {t("Please enter a username")}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>{t("Password")}</Form.Label>
              <Form.Control
                required
                isInvalid={formErrors["password"]?.length}
                type="text"
                onChange={(event) => {
                  onFormInputChange("password", event.target.value);
                }}
              />
              {formErrors["password"]?.length && (
                <Form.Control.Feedback type="invalid">
                  {t("Please enter a password")}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>{t("Repeat Password")}</Form.Label>
              <Form.Control
                required
                isInvalid={formErrors["repeatPassword"]?.length}
                type="text"
                onChange={(event) => {
                  onFormInputChange("repeatPassword", event.target.value);
                }}
              />
              {formErrors["repeatPassword"]?.length && (
                <Form.Control.Feedback type="invalid">
                  {t("Please enter the same password as above")}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Row className="d-flex justify-content-between mt-5">
              <Button
                style={{ width: "98%" }}
                className="mx-2"
                variant="primary"
                onClick={handleFormSubmit}
              >
                {t("Create User")}
              </Button>
              <Button className="mt-3" variant="outline">
                {t("Cancel")}
              </Button>
            </Row>
          </Col>
        )}
      </Row>
    </div>
  );
};
export default AddIncharge;
