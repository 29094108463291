import React from 'react';
import {
  getTableRowCheckBox,
  returnCustomerAddressString,
} from '../../../utils/table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@themesberg/react-bootstrap';

export const gstColumns = (onRowSelect, setSelectedButton) => {
  const columns = [
    {
      dataField: 'objectId',
      text: 'select',
      formatter: (cell, row) => {
        return getTableRowCheckBox(onRowSelect, cell, row?.isSelected, false);
      },
    },
    {
      dataField: 'gstNumber',
      text: 'number',
      formatter: (cell, row) => {
        return <div className="d-block">{cell}</div>;
      },
    },
    {
      dataField: 'legalName',
      text: 'name',
      formatter: (cell, row) => {
        return returnCustomerAddressString(cell, row?.pincode);
      },
    },
    {
      dataField: 'stateCode',
      text: 'state',
      formatter: (cell, row) => {
        return <div className="d-block">{cell}</div>;
      },
    },
    {
      dataField: 'status',
      text: 'status',
      formatter: (cell, row) => {
        // const status =
        // cell === 'Blacklisted' ||
        // row?.updateType === 'Partial Manual update'
        //   ? cell
        //   : 'success';
        return (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{
              width: 'fit-content',
              minWidth: '90px',
              height: '25px',
              padding: '10px',
              backgroundColor: cell !== 'Allowed' ? '#FDDDDD' : '#D4F7E8',
            }}
          >
            <div className="fw-bold">{cell}</div>
          </div>
        );
      },
    },
    {
      dataField: 'count',
      text: 'attempts',
      formatter: (cell, row) => {
        return <div className="d-block">{cell}</div>;
      },
    },
    {
      dataField: 'status',
      text: 'Action',
      formatter: (cell, row) => {
        return (
          <div className="d-flex gap-3">
            <Button
              style={{
                height: '40px',
                borderRadius: '3.99px',
                minWidth: '110px',
              }}
              onClick={() => {
                setSelectedButton(cell === 'Blacklisted' ? 'UnBlock' : 'Block');
                onRowSelect(true, row?.objectId);
              }}
              variant="gray-100"
              //   className="btn-export"
            >
              <FontAwesomeIcon icon={faBan} />
              &nbsp;&nbsp; {cell === 'Blacklisted' ? 'UnBlock' : 'Block'}
            </Button>
            <Button
              style={{
                height: '40px',
                borderRadius: '3.99px',
              }}
              onClick={() => {
                setSelectedButton('Delete');
                onRowSelect(true, row?.objectId);
              }}
              variant="primary"
              className="me-2"
            >
              <FontAwesomeIcon icon={faTrash} />
              &nbsp;&nbsp; {'Delete'}
            </Button>
          </div>
        );
      },
    },
  ];
  return columns;
};
