import React, { useEffect, useState } from 'react';
import { serviceablityColumns } from './utils';
import {
  customSizePerPage,
  customTotal,
} from '../../../components/common/parcelStatusPages';
import Pagination from 'react-bootstrap-table2-paginator';
import * as Paginator from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import {
  Button,
  ButtonGroup,
  Card,
  Col,
  Form,
  Row,
} from '@themesberg/react-bootstrap';
import Table from 'react-bootstrap-table-next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BreadCrumbComponent from '../../../components/common/Breadcrumb';
import arrayToBreadCrumbs from '../../../utils/sms/arrayToBreadCrumbs';
import { faFilter, faRefresh } from '@fortawesome/free-solid-svg-icons';
import ExcelDownloadReport from '../../../components/common/excel/download';
import DropdownComponent from '../../../components/common/Dropdown';
import EmptyTable from '../../../components/common/EmptyTableView';
import { StyledSearchBar } from '../../trips/styles';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { updateToastInfo } from '../../../actions/settings';
import { apiCall } from '../../../middlewares/api';
import { apiCallConsts } from '../../../constants/apiCallConsts';
import { updateSpinnerState } from '../../../actions/spinner';
import { arrayToDropdownOptions } from '../../../utils/sms/arrayToDropdownOptions';
import AsyncDropdown from '../../../components/AsyncDropdown';
import { useNavigate } from 'react-router-dom';
import { returnRowBGColor } from '../../../utils/parcels';
import { setProductListDropdownInStore } from '../../../actions/oms/allProducts';

const Serviceability = () => {
  const { t } = useTranslation();
  const breadCrubItems = arrayToBreadCrumbs([['serviceability']]);
  const [searchByFieldName, setSearchByFieldName] = useState('');
  const [data, setData] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [pincode, setPincode] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const productList = useSelector(
    (state) => state?.allProducts?.productsListDropdown || {}
  );
  const [isLoadingOptions, setIsLoadingOptions] = useState(false);
  const productsListAsOptions = arrayToDropdownOptions(
    Object.keys(productList)
  );

  const handleSubmit = () => {
    if (!searchByFieldName || !searchValue || !pincode) {
      dispatch(
        updateToastInfo({
          show: true,
          type: t('danger'),
          title: '',
          message: t('please enter all fields'),
        })
      );
      return;
    }

    if (
      (searchByFieldName === 'vanNo' && searchValue.length !== 6) ||
      pincode.length !== 6
    ) {
      dispatch(
        updateToastInfo({
          show: true,
          type: t('danger'),
          title: '',
          message: t('please enter correct vanNo and pincode'),
        })
      );
      return;
    }

    const key =
      searchByFieldName === 'productName' ? 'vanNo' : searchByFieldName;
    const value =
      searchByFieldName === 'productName'
        ? productList[searchValue]
        : searchValue;
    const queryparams = { [key]: value, pincode: pincode };
    handleApiCall(queryparams);
  };

  const handleApiCall = async (queryparams) => {
    try {
      dispatch(updateSpinnerState(true));
      const res = await apiCall(
        apiCallConsts.GET_METHOD,
        '/internal/serviceability',
        queryparams
      );
      const arr = ObjectToArrayConverter(res.data);
      setData(arr);
      dispatch(updateSpinnerState(false));
    } catch (error) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('Failed To Fetch data'),
          message: t(error.message),
        })
      );
    }
  };

  const ObjectToArrayConverter = (obj = {}) => {
    let arr = [];
    Object.keys(obj).forEach((key) => {
      arr.push({ ...obj[key], vanNo: key });
    });
    return arr;
  };

  useEffect(() => {
    const getProductsList = async () => {
      try {
        dispatch(updateSpinnerState(true));
        const products = await apiCall(
          apiCallConsts.GET_METHOD,
          '/internal/serviceability/products'
        );
        dispatch(setProductListDropdownInStore(products));
        dispatch(updateSpinnerState(false));
      } catch (error) {
        dispatch(updateSpinnerState(false));
        dispatch(
          updateToastInfo({
            show: true,
            type: 'danger',
            title: t('Failed Getting Products data'),
            message: t(error.message),
          })
        );
      }
    };
    if (Object.keys(productList).length === 0) {
      getProductsList();
    }
  }, []);

  const debounce = (func, delay = 500) => {
    let timeoutId;
    return (...args) => {
      return new Promise((resolve) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          resolve(func(...args));
        }, delay);
      });
    };
  };

  const fetchOptions = debounce(async (inputValue) => {
    setIsLoadingOptions(true);
    try {
      const filteredOptions = productsListAsOptions.filter((product) =>
        product.value.toLowerCase().includes(inputValue.toLowerCase())
      );
      return filteredOptions;
    } finally {
      setIsLoadingOptions(false);
    }
  });

  return (
    <ToolkitProvider
      exportCSV
      keyField="id"
      search={true}
      sort
      columns={serviceablityColumns(navigate, pincode)}
      data={data}
      wrapperClasses="table-responsive"
    >
      {({ baseProps, searchProps, csvProps }) => (
        <Paginator.PaginationProvider
          pagination={Pagination({
            custom: true,
            showTotal: true,
            alwaysShowAllBtns: true,
            totalSize: data?.length,
            paginationTotalRenderer: customTotal,
            sizePerPageRenderer: customSizePerPage,
          })}
        >
          {({ paginationProps, paginationTableProps }) => (
            <>
              <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-md-0">
                  <BreadCrumbComponent items={breadCrubItems} />
                  <h4>{t('Serviceablity')}</h4>
                </div>
                <div className="btn-toolbar mb-2 mb-md-0">
                  <ButtonGroup>
                    <Button
                      onClick={handleSubmit}
                      variant="outline-primary"
                      size="sm"
                    >
                      <FontAwesomeIcon icon={faRefresh} />
                      &nbsp;&nbsp;{t('Refresh')}
                    </Button>
                    <ExcelDownloadReport
                      data={data || []}
                      fileName={'download-data.csv'}
                    />
                  </ButtonGroup>
                </div>
              </div>
              <div className="table-settings mt-2 mb-4">
                <div style={{ gap: 10, display: 'flex' }}>
                  <DropdownComponent
                    onChange={(option) => {
                      setSearchByFieldName(option.value);
                    }}
                    defaultValue={searchByFieldName}
                    width={300}
                    disabled={false}
                    options={[
                      { label: 'VAN No', value: 'vanNo' },
                      { label: 'Product Name', value: 'productName' },
                      { label: 'Company Name', value: 'companyName' },
                    ]}
                    placeholder={t('Select field to Search by')}
                  />

                  {searchByFieldName === 'productName' ? (
                    <AsyncDropdown
                      onChange={(option) => setSearchValue(option.value)}
                      width={300}
                      disabled={false}
                      fetchOptions={fetchOptions}
                      placeholder={t('Select field to Search by')}
                      isLoading={isLoadingOptions}
                    />
                  ) : (
                    <StyledSearchBar
                      size="xs"
                      type={searchByFieldName === 'vanNo' ? 'number' : 'text'}
                      placeholder={'Please Enter value'}
                      onChange={(e) => setSearchValue(e.target.value)}
                    />
                  )}

                  <StyledSearchBar
                    size="xs"
                    type="number"
                    placeholder={'Please Enter pincode'}
                    onChange={(e) => setPincode(e.target.value)}
                  />

                  <Button
                    style={{
                      height: 38,
                    }}
                    variant="primary"
                    onClick={handleSubmit}
                  >
                    <FontAwesomeIcon icon={faFilter} />
                    &nbsp;&nbsp; {t('submit')}
                  </Button>
                </div>
              </div>
              <Card>
                <div className="table-responsive py-2">
                  <Card.Header
                    style={{
                      padding: '8px 12px',
                    }}
                  >
                    <Row className="d-flex justify-content-end">
                      <Col
                        xs={12}
                        md={4}
                        className="d-flex justify-content-md-end"
                      >
                        <Search.SearchBar
                          {...searchProps}
                          placeholder={t('Search any value in table here')}
                        />
                        <Search.ClearSearchButton {...searchProps} />
                      </Col>
                    </Row>
                  </Card.Header>
                  <Table
                    rowClasses={(row) => {
                      const rowBGColor = row?.isServiceable
                        ? ''
                        : 'bg-trip-deleted';
                      return `${rowBGColor} border-bottom`;
                    }}
                    noDataIndication={<EmptyTable />}
                    {...baseProps}
                    {...paginationTableProps}
                    bodyClasses="border-0"
                    classes="table-flush dataTable-table"
                  />

                  <Card.Footer>
                    <Row>
                      <Col xs={12} md={4} className="py-1">
                        <Paginator.SizePerPageDropdownStandalone
                          {...paginationProps}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        className="d-flex align-items-center py-1"
                      >
                        <Paginator.PaginationTotalStandalone
                          {...paginationProps}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        className="d-flex justify-content-md-end align-items-center mb-0 py-1"
                      >
                        <Paginator.PaginationListStandalone
                          {...paginationProps}
                        />
                      </Col>
                    </Row>
                  </Card.Footer>
                </div>
              </Card>
            </>
          )}
        </Paginator.PaginationProvider>
      )}
    </ToolkitProvider>
  );
};

export default Serviceability;
