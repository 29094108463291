import React, { useState } from 'react';
import Pagination from 'react-bootstrap-table2-paginator';
import * as Paginator from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BreadCrumbComponent from '../../components/common/Breadcrumb';
import {
  Button,
  ButtonGroup,
  Card,
  Col,
  Row,
} from '@themesberg/react-bootstrap';
import Table from 'react-bootstrap-table-next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { Routes } from '../../router/routes';
import {
  customSizePerPage,
  customTotal,
} from '../../components/common/parcelStatusPages';
import EmptyTable from '../../components/common/EmptyTableView';
import { faRefresh } from '@fortawesome/free-solid-svg-icons';
import ExcelDownloadReport from '../../components/common/excel/download';
import DateMonthSelector from '../../components/common/dateMonthSelector';
import { useDispatch, useSelector } from 'react-redux';
import { updateSpinnerState } from '../../actions/spinner';
import { getVehicleMonthlyAttendance } from '../../parse-functions/trips';
import { updateToastInfo } from '../../actions/settings';
import { vehicleAttendanceReportTableColumns } from '../../utils/vehicleAttendance';
import { getOrderedVehicleAttendance } from './utils';
import { setPageNumberInStore } from '../../actions/tablePage';

const VehicleAttendance = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const storeInfo = useSelector((state) => state.user.warehouse || {});
  const [allVehicleAttendance, setAllVehicleAttendance] = useState([]);
  const [headers, setHeaders] = useState([
    'vehicleNumber',
    'present',
    'absent',
  ]);
  const [dataToDownload, setDataToDownload] = useState([]);
  const isSuperAdmin = useSelector(
    (state) => state?.user?.preferences?.isSuperAdmin || false
  );
  const totalPages = Math.ceil((allVehicleAttendance?.length || 0) / 10);
  const storedPage = useSelector(
    (state) => state?.pageNumber?.['vehicleAttendance'] || 1
  );
  const [currentPage, setCurrentPage] = useState(
    storedPage > totalPages ? 1 : storedPage
  );
  const handlePageChange = (page) => {
    setCurrentPage(page);
    dispatch(setPageNumberInStore({ id: 'vehicleAttendance', page }));
  };

  const breadCrumbItems = [
    {
      title: t('Reports'),
      link: Routes.Reports.DailyDelivery.path,
    },
    {
      title: t('Vehicle Attendance'),
      link: '',
    },
  ];
  const getAllTrips = async (requestProps) => {
    dispatch(updateSpinnerState(true));
    try {
      const allVehicleAttendance = await getVehicleMonthlyAttendance(
        requestProps
      );
      const { vehicleAttendanceMap, headers } = allVehicleAttendance;
      setHeaders(headers);
      const vehicleAttendance = getOrderedVehicleAttendance(
        Object.values(vehicleAttendanceMap)
      );
      setAllVehicleAttendance(vehicleAttendance);
      setDataToDownload(vehicleAttendance);
      dispatch(
        updateToastInfo({
          show: true,
          type: 'success',
          title: t('Vehicle Attendance fetched'),
          message: t(''),
        })
      );
      dispatch(updateSpinnerState(false));
    } catch (e) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('Error in getting Vehicle Attendance Trips'),
          message: e.message,
        })
      );
    }
  };
  const handleRefreshData = () => {};
  const handleFilterSubmit = (startDate, endDate) => {
    const requestProps = {
      startDate: new Date(startDate),
      endDate: new Date(endDate),
      warehouseCode: storeInfo?.warehouseCode,
      isAttendance: true,
      isSuperAdmin,
    };
    getAllTrips(requestProps);
  };
  return (
    <ToolkitProvider
      exportCSV
      keyField="id"
      search={true}
      sort
      columns={vehicleAttendanceReportTableColumns(headers)}
      data={allVehicleAttendance}
      wrapperClasses="table-responsive"
    >
      {({ baseProps, searchProps, csvProps }) => (
        <Paginator.PaginationProvider
          pagination={Pagination({
            custom: true,
            showTotal: true,
            alwaysShowAllBtns: true,
            totalSize: allVehicleAttendance?.length,
            paginationTotalRenderer: customTotal,
            sizePerPageRenderer: customSizePerPage,
            onPageChange: handlePageChange,
            page: currentPage,
          })}
        >
          {({ paginationProps, paginationTableProps }) => (
            <>
              <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-md-0">
                  <BreadCrumbComponent items={breadCrumbItems} />
                  <h4>{t('Vehicle Attendance Report')}</h4>
                  <p className="mb-0">
                    {t(
                      'Attendance Module for your vehicles for the selected month'
                    )}
                  </p>
                </div>
                <div className="btn-toolbar mb-2 mb-md-0">
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <ButtonGroup>
                        <Button
                          onClick={() => {
                            handleRefreshData();
                          }}
                          variant="outline-primary"
                          size="sm"
                        >
                          <FontAwesomeIcon icon={faRefresh} />
                          &nbsp;&nbsp;{t('Refresh')}
                        </Button>
                        <ExcelDownloadReport
                          isAttendance
                          headers={headers}
                          data={dataToDownload || []}
                          fileName={'vehicle-attendance'}
                        />
                      </ButtonGroup>
                    </div>
                    <div>
                      <div
                        style={{
                          fontSize: 11,
                          paddingTop: 10,
                          fontStyle: 'italic',
                        }}
                      >
                        This data will refresh every time a trip is dispatched
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Card>
                <div className="table-responsive py-2">
                  <Card.Header
                    style={{
                      padding: '8px 12px',
                    }}
                  >
                    <Row className="d-flex justify-content-between">
                      <Col md={6} className="d-flex justify-content-start">
                        <DateMonthSelector onSubmit={handleFilterSubmit} />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        className="d-flex justify-content-end"
                      >
                        <Search.SearchBar
                          {...searchProps}
                          placeholder={t('Search any value in table here')}
                          onSearch={(value) => {
                            setCurrentPage(1);
                            searchProps.onSearch(value);
                          }}
                        />
                        <Search.ClearSearchButton {...searchProps} />
                      </Col>
                    </Row>
                  </Card.Header>
                  <Table
                    noDataIndication={<EmptyTable />}
                    {...baseProps}
                    {...paginationTableProps}
                    bodyClasses="border-0"
                    classes="table-flush dataTable-table"
                  />

                  <Card.Footer>
                    <Row>
                      <Col xs={12} md={4} className="py-1">
                        <Paginator.SizePerPageDropdownStandalone
                          {...paginationProps}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        className="d-flex align-items-center py-1"
                      >
                        <Paginator.PaginationTotalStandalone
                          {...paginationProps}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        className="d-flex justify-content-md-end align-items-center mb-0 py-1"
                      >
                        <Paginator.PaginationListStandalone
                          {...paginationProps}
                        />
                      </Col>
                    </Row>
                  </Card.Footer>
                </div>
              </Card>
            </>
          )}
        </Paginator.PaginationProvider>
      )}
    </ToolkitProvider>
  );
};
export default VehicleAttendance;
