export const validateProductFetch = (formInput, setFormErrors) => {
  let valid = true;
  const errors = {
    company: '',
    category: '',
  };
  if (formInput.company.length === 0) {
    errors['company'] = 'Cannot be Empty';
    valid = false;
  }
  if (formInput.category.length === 0) {
    errors['category'] = 'Cannot be Empty';
    valid = false;
  }
  setFormErrors((prev) => ({
    ...prev,
    company: errors.company,
    category: errors.category,
  }));
  return valid;
};

export const addSchemeValidate = (formInput, setFormErrors, formProps) => {
  const errors = { products: [] };
  let valid = true;

  const requiredFields = [
    { field: 'company', message: 'Cannot be Empty' },
    { field: 'category', message: 'Cannot be Empty' },
    { field: 'payoutBasis', message: 'Cannot be Empty' },
    { field: 'regions', message: 'Cannot be Empty' },
    { field: 'type', message: 'Cannot be Empty' },
    { field: 'schemeCircularDate', message: 'Cannot be Empty', isDate: true },
    { field: 'validFrom', message: 'Cannot be Empty', isDate: true },
    { field: 'validTill', message: 'Cannot be Empty', isDate: true },
  ];

  requiredFields.forEach(({ field, message }) => {
    if (!formInput[field] || formInput[field].length === 0) {
      errors[field] = message;
      valid = false;
    }
  });

  if (
    formInput.type?.[0] === 'Sell-in' &&
    (!formInput.sellIntype || formInput.sellIntype.length === 0)
  ) {
    errors.sellIntype = 'Cannot be Empty';
    valid = false;
  }

  if (
    formInput.validFrom &&
    formInput.validTill &&
    formInput.validFrom > formInput.validTill
  ) {
    errors.validFrom = 'Cannot be greater than Valid Till';
    valid = false;
  }

  formInput.products.forEach((item, index) => {
    const productErrors = {};
    const productFields = [
      { field: 'productName', message: 'Cannot be Empty' },
      { field: 'valuationType', message: 'Cannot be Empty' },
      { field: 'payout', message: 'Cannot be Empty' },
    ];

    productFields.forEach(({ field, message }) => {
      if (!item[field] || item[field].length === 0) {
        productErrors[field] = message;
        valid = false;
      }
    });

    if (Object.keys(productErrors).length > 0) {
      errors.products[index] = productErrors;
    }
  });

  setFormErrors(errors);
  return valid;
};

export const addProductValidate = (formInput, setFormErrors, type) => {
  const errors = {};
  let valid = true;

  if (type === 'addProduct') {
    if (!formInput.productName) {
      errors.productName = 'Cannot be empty';
      valid = false;
    }
    if (!formInput.vanNo) {
      errors.vanNo = 'Cannot be empty';
      valid = false;
    }
    if (!formInput.company) {
      errors.company = 'Cannot be empty';
      valid = false;
    }
    if (!formInput.category) {
      errors.category = 'Cannot be empty';
      valid = false;
    }
  }

  if (type === 'addCompany') {
    if (!formInput.newCompanyName) {
      errors.newCompanyName = 'Cannot be empty';
      valid = false;
    }
  }

  if (type === 'addCategory') {
    if (!formInput.companyName) {
      errors.companyName = 'Cannot be empty';
      valid = false;
    }
    if (!formInput.newCategoryName) {
      errors.newCategoryName = 'Cannot be empty';
      valid = false;
    }
  }

  setFormErrors(errors);
  return valid;
};
