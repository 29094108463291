/* eslint-disable no-unused-expressions */
import React from "react";
import { returnUserReadableDate } from "./datetime";

export const dailyDeliveryReportModesOptions = () => {
    return [{
        label: "Vehicle Level",
        value: "vehicle"
    }, {
        label: "Trip Level",
        value: "trip"
    }]
};

export const returnTripGenericInfo = (item, mode) => {
    const returnData = {
        branchName: item.warehouseCode,
        dispatchedAt: item?.dispatchedAt ? returnUserReadableDate(item?.dispatchedAt) : "-",
        vehicleNumber: item.vehicleNumber,
        vehicleModel: item?.dispatchData?.vehicleDetails?.vehicleType,
        vendorName: item?.dispatchData?.vehicleDetails?.vendorName,
        driverName: item?.dispatchData?.vehicleDetails?.driverName,
        driverContactNumber: item?.dispatchData?.vehicleDetails?.contactNumber,
        vehicleArea: item?.vehicleArea,
        loaderName1: item?.dispatchData?.loaderName1,
        loaderNumber1: item?.dispatchData?.loaderNumber1,
        loaderName2: item?.dispatchData?.loaderName2,
        loaderNumber2: item?.dispatchData?.loaderNumber2,
        loaderName3: item?.dispatchData?.loaderName3,
        loaderNumber3: item?.dispatchData?.loaderNumber3,
        loaderName4: item?.dispatchData?.loaderName4,
        loaderNumber4: item?.dispatchData?.loaderNumber4,
        loaderName5: item?.dispatchData?.loaderName5,
        loaderNumber5: item?.dispatchData?.loaderNumber5
    }
    if (mode === "trip") {
        returnData.tripNumber = item.tripNumber;
    }
    return returnData;
}
export const returnDailyDeliveryReportVehicleTableData = (data) => {
    const vehicleAnalyticsMap = {};
    data?.forEach((tripData) => {
        if (!vehicleAnalyticsMap[tripData.vehicleNumber]) {
            vehicleAnalyticsMap[tripData.vehicleNumber] = returnTripGenericInfo(tripData, "vehicle");
        }
        Object.keys(tripData.analytics).forEach((statusKey) => {
            if (vehicleAnalyticsMap[tripData.vehicleNumber][statusKey]) {
                vehicleAnalyticsMap[tripData.vehicleNumber][statusKey] += tripData.analytics[statusKey]
            } else {
                vehicleAnalyticsMap[tripData.vehicleNumber][statusKey] = tripData.analytics[statusKey]
            }
        });
    });
    return Object.values(vehicleAnalyticsMap);
};
export const returnDailyDeliveryReportTableData = (data) => {
    const analyticsData = [];
    data?.forEach((item) => {
        analyticsData.push({
            ...returnTripGenericInfo(item, "trip"),
            ...item.analytics
        })
    });
    return analyticsData;
}

export const dailyDeliveryReportTableColumns = () => {
    return ([
        {
            dataField: "vehicleNumber", sort: true, text: "Vehicle Number", formatter: (cell, row) => {
                return (
                    <div className="d-block">
                        <span className="fw-bold">{row?.vehicleNumber}</span>
                        <div className="fw-normal">{row?.dispatchData?.vehicleDetails?.vehicleType}</div>
                    </div>
                )
            }
        },
        {
            dataField: "tripNumber", sort: true, text: "Trip Number", formatter: (cell, row) => {
                return (
                    <div className="d-block">
                        <span className="fw-bold">{row?.tripNumber}</span>
                        <div className="fw-normal">{row?.dispatchedAt}</div>
                    </div>
                )
            }
        },
        {
            dataField: "vehicleArea", text: "Vehicle Area"
        },
        {
            dataField: "branchName", text: "Branch"
        },
        {
            dataField: "loaderName1", text: "Loader 1", formatter: (cell, row) => {
                return (
                    <div className="d-block">
                        <span className="fw-bold">{row?.loaderName1}</span>
                        <div className="fw-normal">{row?.loaderNumber1}</div>
                    </div>
                )
            }
        },
        {
            dataField: "loaderName2", text: "Loader 2", formatter: (cell, row) => {
                return (
                    <div className="d-block">
                        <div className="fw-normal">{row?.loaderName2}</div>
                        <div className="fw-bold">{row?.loaderNumber2}</div>
                    </div>
                )
            }
        },
        {
            dataField: "products", text: "Products"
        },
        {
            dataField: "pending", text: "Pending"
        },
        {
            dataField: "deliveryQuantity", text: "Delivery Quantity"
        },
        {
            dataField: "droppedathub", text: "Dropped At Hub"
        },
        {
            dataField: "branchDroppedAt", text: "Branches"
        },
        {
            dataField: "isDisplay", text: "Display"
        },
        {
            dataField: "delivered", text: "Delivered"
        },
        {
            dataField: "buybackpending", text: "BuyBack Pending"
        },
        {
            dataField: "buybackpicked", text: "BuyBack Picked"
        },
        {
            dataField: "actualBuyback", text: "actualBuyback"
        },
        {
            dataField: "defectivepending", text: "Defective Pending"
        },
        {
            dataField: "defectivepicked", text: "Defective Picked"
        },
        {
            dataField: "returnok", text: "Return OK"
        },
        {
            dataField: "returndefective", text: "Return Defective"
        }
    ])
};