export const applicationList = [
  { value: "dms", label: "Delivery Management" },
  // { value: 'pricing', label: 'Pricing Tracker' },
  { value: "wms", label: "Warehouse Management" },
  { value: "oms", label: "Order Management" },
  // { value: 'finance', label: 'Finance' },
  // { value: 'omnichannel', label: 'Omni Channel' },
  // { value: 'products', label: 'Product Management' }
  { value: "sms", label: "Scheme Management" },
];
export const applicationHelp = {
  dms: "Delivery Management",
  wms: "Warehouse Management",
  sms: "Scheme Management",
  oms: "Order Management",
};
