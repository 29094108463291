import React, { useEffect } from 'react';
import { Button, Modal } from '@themesberg/react-bootstrap';
import { useTranslation } from 'react-i18next';
import { generateRandomId } from '../../../../utils/string';
import FormComponent from '../../../scheme-management/Components/FormComponent';
import { useSelector } from 'react-redux';
import useGetCompanyAndCategory from '../../../scheme-management/custom-hooks/useGetCompanyAndCategory';
import { returnCategoriesDropdownList } from '../../../../utils/products';
import { useFetchOMSProducts } from '../../custom-hooks/useFetchOMSProducts';
import { arrayToDropdownOptions } from '../../../../utils/sms/arrayToDropdownOptions';

const CategoryUpdateModal = (props) => {
  const { showModal, closeModal, handleSubmit } = props;
  const { t } = useTranslation();
  const categories = useSelector(
    (state) => state?.allProducts?.categories || {}
  );

  const schema = [
    [
      {
        title: 'Category',
        isMultiSelect: true,
        id: 'categories',
        type: 'select',
        options: arrayToDropdownOptions(categories),
        required: true,
      },
      {
        title: 'Limit',
        type: 'number',
        id: 'categorySafetyStockLimit',
        required: true,
      },
      {
        title: 'submit',
        type: 'button',
      },
    ],
  ];

  useFetchOMSProducts();

  return (
    <>
      <Modal
        id={generateRandomId()}
        as={Modal.Dialog}
        centered
        show={showModal}
        onHide={closeModal}
        size="lg"
      >
        <Modal.Header>
          <Modal.Title className="h6">
            {t('Update Category Safety Stock')}
          </Modal.Title>
          <Button variant="close" aria-label="Close" onClick={closeModal} />
        </Modal.Header>
        <Modal.Body>
          <FormComponent schema={schema} savefunction={handleSubmit} />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="link"
            className="text-gray ms-auto"
            onClick={closeModal}
          >
            {t('Cancel')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CategoryUpdateModal;
