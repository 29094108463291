import React from 'react';
import OrderPage from './components/OrderPage';
import { orderStatus } from '../../../constants/orderStatus';

const ReadyForPickup = () => {
  const bulkActionOptions = [
    {
      label: 'Picked Up',
      value: 'pickedUp',
    },
    {
      label: 'Generate Manifest',
      value: 'Generate Manifest',
    },
  ];
  return (
    <div>
      <OrderPage
        type={orderStatus.readyForPickup}
        bulkActionOptions={bulkActionOptions}
      />
    </div>
  );
};

export default ReadyForPickup;
