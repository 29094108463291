import React from "react";
import { useTranslation } from "react-i18next";
import { generateRandomId } from "../../utils/string";
import { Button, Modal } from "@themesberg/react-bootstrap";
import AddVehicle from "./add";

const VehicleUpdateModal = (props) => {
    const {t} = useTranslation();
    const {
        showModal,
        closeModal,
        onConfirm,
        vehicle
    } = props;
    return (
        <Modal id={generateRandomId()} size="lg" show={showModal} onHide={closeModal}>
            <Modal.Header>
                <Modal.Title className="h6">{t(`Update ${vehicle?.vehicleNumber || "vehicle details"}`)}</Modal.Title>
                <Button variant="close" aria-label="Close" onClick={closeModal} />
            </Modal.Header>
            <Modal.Body>
                <AddVehicle edit vehicleInfo={vehicle} onVehicleEditConfirm={onConfirm} />
            </Modal.Body>
        </Modal>
    )
};
export default VehicleUpdateModal