import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { updateSpinnerState } from '../../../actions/spinner';
import { updateToastInfo } from '../../../actions/settings';
import BreadCrumbComponent from '../../../components/common/Breadcrumb';
import arrayToBreadCrumbs from '../../../utils/sms/arrayToBreadCrumbs';
import { regionTeamSchema } from './utils';
import FormComponent from '../../scheme-management/Components/FormComponent';
import { addNewRegionUser } from '../../../parse-functions/users';

const AddUser = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const zones = useSelector((state) => state?.user?.config?.zones || {});
  const breadCrumbItems = arrayToBreadCrumbs([['Users'], ['add']]);

  const zoneDropDownOptions = (zones = {}) => {
    const returnVal = [];
    Object.keys(zones).forEach((zone) => {
      returnVal.push({ label: zone, value: zone });
    });
    return returnVal;
  };

  let schema = regionTeamSchema;
  schema[0][4].options = zoneDropDownOptions(zones) || [];

  const handleSubmit = async (data, callback) => {
    try {
      dispatch(updateSpinnerState(true));
      await addNewRegionUser(data);
      dispatch(
        updateToastInfo({
          show: true,
          type: 'success',
          title: t('User Added'),
          message: t(`${data.name} added successfully`),
        })
      );
      callback(null);
    } catch (error) {
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: 'failed to add User!',
          message: `${error.message}`,
        })
      );
      callback(error.message);
    } finally {
      dispatch(updateSpinnerState(false));
    }
  };

  return (
    <>
      <div className="mt-1">
        <div className="mb-4 d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-2">
          <div className="d-block mb-4 mb-md-0">
            <BreadCrumbComponent items={breadCrumbItems} />
            <h4>{t('Add Region User ')}</h4>
          </div>
        </div>
        <FormComponent schema={schema} savefunction={handleSubmit} />
      </div>
    </>
  );
};

export default AddUser;
