import { faTruck, faTruckMoving } from "@fortawesome/free-solid-svg-icons";

export const returnDisplayVehiclesList = (vehiclesArray) => {
    const vehicles = {};
    vehiclesArray.forEach((vehicleTypeObject) => {
        const {
            vehicleType,
            noOfVehicles,
            weight,
            volume,
            length,
            breadth,
            height,
        } = vehicleTypeObject;
        for (let i = 0; i < noOfVehicles; i += 1) {
            vehicles[`vehicle-${vehicleType}-${i + 1}`] = {
                id: `vehicle-${vehicleType}-${i + 1}`,
                vehicleNumber: `${vehicleType}-${i + 1}`,
                vehicleType,
                weight,
                height,
                volume,
                length,
                breadth,
            }
        }
    })
    return vehicles;
}

export const returnVehicleIcon = (vehicleType) => {
    switch (vehicleType) {
        case 'TataAce': {
            return faTruck;
        }
        case 'Tata407': {
            return faTruckMoving;
        }
        case 't1109': {
            return faTruckMoving;
        }
        default: {
            return faTruck;
        }
    }
}

export const isValidVehicleNumber = (input, helpers) => {
    if (/(^[A-Z]{2}[0-9]{1,2}(?:[A-Z])?(?:[A-Z]*)?[0-9]{1,4}$)+/i.test(input) && (input.length >= 5 && input.length <= 10)) {
        return input
    } else if (/(^[A-Z]{2}[0-9]{1,3}(?:[A-Z])?(?:[A-Z]*)?[0-9]{1,4}$)+/i.test(input) && input?.startsWith("DL")){
        return input;
    } else {
        throw new Error("Vehicle Number format is not correct")
    }
    
}

export const returnVehicleTypesOptions = (options) => {
    return options?.map(item => ({
        label: item,
        value: item
    }))
}

export const returnVendorDropdownOptions = (options) => {
    return options?.map(item => ({
        label: item?.vendorName,
        value: item
    }))
}

export const returnVehicleDropdownOptions = (vehiclesMap, manualEntryVehicles) => {
    const vehicleOptions = Object.values(vehiclesMap)?.map(vehicle => ({
        label: vehicle.vehicleNumber,
        value: vehicle
    }));
    const manualOptions = manualEntryVehicles.map(vehicleType => ({
        label: vehicleType,
        value: vehicleType
    }))
    return [...vehicleOptions, ...manualOptions]
}