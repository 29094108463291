import {
    RESET_STORE,
    SET_REQUISITIONS_IN_STORE,
    SET_SELECTED_REQUISITION_IN_STORE,
    ADD_BRANCH_BREAKUP_REQUISITION_DATA_IN_STORE
} from "../actions/types";

const INITIAL_STATE = {
    selected: {},
    sent: {},
    received: {},
    branchBreakup: []
};
const requisitionsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_REQUISITIONS_IN_STORE: {
            const {
                pageType, data
            } = action.data;
            return {
                ...state,
                [pageType]: data
            }
        }
        case SET_SELECTED_REQUISITION_IN_STORE: {
            return {
                ...state,
                selected: action.data
            }
        }
        case ADD_BRANCH_BREAKUP_REQUISITION_DATA_IN_STORE: {
            const breakUpInfo = JSON.parse(JSON.stringify(state.branchBreakup));
            breakUpInfo.push(action.data);
            return {
                ...state,
                branchBreakup:breakUpInfo
            }
        }
        case RESET_STORE: {
            return INITIAL_STATE;
        }
        default: return state;
    }
};

export default requisitionsReducer;