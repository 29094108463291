import {
  SET_APPLICATION_CONFIG_IN_STORE,
  SET_USER_PREFERENCES_IN_STORE,
  SET_CURRENT_USER_IN_STATE,
  SET_CURRENT_WAREHOUSE_INFO_IN_STORE,
  SET_CURRENT_USER_INFO_IN_STATE,
  RESET_STORE,
  SET_CURRENT_USER_PERMISSIONS_IN_STORE,
} from '../actions/types';
import { convertArrayToObj } from '../utils/json';

const INITIAL_STATE = {
  user: {},
  config: {},
  preferences: {},
  warehouse: {},
  otherWarehousesInZone: {},
  userInfo: {},
  permissions: {},
};
const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_CURRENT_USER_IN_STATE: {
      return {
        ...state,
        user: JSON.parse(JSON.stringify(action.data)),
      };
    }
    case SET_APPLICATION_CONFIG_IN_STORE: {
      return {
        ...state,
        config: action.data,
      };
    }
    case SET_USER_PREFERENCES_IN_STORE: {
      return {
        ...state,
        preferences: action.data,
      };
    }
    case SET_CURRENT_WAREHOUSE_INFO_IN_STORE: {
      const { ownWarehouse, otherWarehousesInZone } = action.data;
      return {
        ...state,
        warehouse: ownWarehouse,
        otherWarehousesInZone: convertArrayToObj(
          otherWarehousesInZone,
          'objectId'
        ),
      };
    }
    case SET_CURRENT_USER_INFO_IN_STATE: {
      return {
        ...state,
        userInfo: action.data[0],
      };
    }
    case SET_CURRENT_USER_PERMISSIONS_IN_STORE: {
      return {
        ...state,
        permissions: action.data,
      };
    }
    case RESET_STORE: {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
};

export default userReducer;
