import { Button, Col, Form, Modal, Row } from "@themesberg/react-bootstrap";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import DropdownComponent from "../../components/common/Dropdown";
import { returnVehicleDropdownOptions } from "../../utils/vehicles";
import { useDispatch, useSelector } from "react-redux";
import { updateSpinnerState } from "../../actions/spinner";
import { updateToastInfo } from "../../actions/settings";
import { updateTemposheetDataForTrip } from "../../parse-functions/trips";
import { updateVehicleNumberForActiveTemporaryTrip } from "../../actions/trips";

const UpdateVehicleNumberModal = (props) => {
    const {
        showModal,
        handleCloseModal,
        getAllVehiclesFromSystem
    } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const selectedTrip = useSelector((state) => state.trips.selectedTrip || null);
    const vehiclesList = useSelector((state) => state?.drivers?.driversList || {});
    const [selectedVehicle, setSelectedVehicle] = useState({});
    const [disableDispatchButton, setDisableDispatchButton] = useState(true);
    const onConfirm = async () => {
        dispatch(updateSpinnerState(true));
        setDisableDispatchButton(true);
        try {
            await updateTemposheetDataForTrip({
                data: {
                    vehicleNumber: selectedVehicle.vehicleNumber
                },
                mode: "temporaryVehicleNumber",
                temposheetNumber: selectedTrip
            });
            dispatch(updateVehicleNumberForActiveTemporaryTrip({
                vehicleNumber: selectedVehicle.vehicleNumber
            }));
            dispatch(updateSpinnerState(false));
            dispatch(updateToastInfo({
                show: true,
                type: "success",
                title: "Vehicle Number Updated Successfully",
                message: "You can go ahead and scan the items now"
            }));
            handleCloseModal();
        } catch (e) {
            dispatch(updateSpinnerState(false));
            dispatch(updateToastInfo({
                show: true,
                type: "danger",
                title: "Error in Updating Vehicle Number",
                message: e.error || "Contact Support if issue persists"
            }))
        }
    }
    return (
        <Modal as={Modal.Dialog} size="lg" centered show={showModal} onHide={handleCloseModal}>
            <Modal.Header>
                <Modal.Title className="h6">{t("Update Vehicle Number")}</Modal.Title>
                <Button variant="close" aria-label="Close" onClick={handleCloseModal} />
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <Form.Label>{t("Select Vehicle Number")}</Form.Label>
                    </Col>
                    <Col>
                        <Form.Check
                            type="switch"
                            label="Get All vehicles"
                            id="vehicleZoneSwitch"
                            htmlFor="vehicleZoneSwitch"
                            onChange={(event) => {
                                if(event.target.checked) {
                                    getAllVehiclesFromSystem()
                                }
                            }}
                        />
                    </Col>
                </Row>

                <DropdownComponent
                    onChange={(option) => {
                        setSelectedVehicle(option.value);
                        if (option.value) {
                            setDisableDispatchButton(false);
                        }
                    }}
                    options={returnVehicleDropdownOptions(vehiclesList, [])}
                    placeholder={t("Please Select a Vehicle")}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button
                    disabled={disableDispatchButton}
                    variant="secondary"
                    onClick={onConfirm}>
                    {t("Update")}
                </Button>
                <Button variant="link" className="text-gray ms-auto" onClick={handleCloseModal}>
                    {t("Cancel")}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
export default UpdateVehicleNumberModal