import React from 'react';
import { Button, Modal } from '@themesberg/react-bootstrap';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { generateRandomId } from '../../../../utils/string';

const NewConfirmationModal = ({
  icon,
  showModal,
  closeModal,
  modalTitle,
  modalSubTitle,
  confirmActionName,
  cancelActionName,
  handleSubmit,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      id={generateRandomId()}
      as={Modal.Dialog}
      centered
      show={showModal}
      onHide={closeModal}
      size="lg"
    >
      <Modal.Body>
        <div className="mx-5">
          {/* <div className="d-flex justify-content-end">
            <Button variant="close" aria-label="Close" onClick={closeModal} />
          </div> */}
          <div className="d-flex flex-column justify-content-center align-items-center text-center gap-3">
            <div className="fs-4">
              {<FontAwesomeIcon size="lg" icon={icon} />}
            </div>
            <div className="fs-4">{t(modalTitle)}</div>
            {modalSubTitle && <div className="fs-5">{t(modalSubTitle)}</div>}
            <div>
              <Button
                variant="white"
                size="md"
                className="btn-export"
                onClick={closeModal}
              >
                <FontAwesomeIcon icon={faXmark} />
                &nbsp;&nbsp; {t(cancelActionName)}
              </Button>
              <Button
                variant="primary"
                size="md"
                className="btn btn-primary"
                onClick={handleSubmit}
              >
                <FontAwesomeIcon icon={faCheck} />
                &nbsp;&nbsp; {t(confirmActionName)}
              </Button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default NewConfirmationModal;
