const joi = require('joi');

let formSchema;

export default function validate(schema, formData) {
  let object = {};
  schema.forEach((element) => {
    element.forEach((field) => {
      let validateQuery = '';
      if (field.type === 'text' || field.type === 'textarea') {
        if (
          field.id === 'tokenGeneration' ||
          field.id === 'tracking' ||
          field.id === 'ndr' ||
          field.id === 'awbGeneration' ||
          field.id === 'serviceability'
        ) {
          validateQuery = joi.object();
        } else {
          validateQuery = joi.string();
        }
      } else if (field.type === 'number') {
        validateQuery = joi.number().empty();
      } else if (field.type === 'email') {
        validateQuery = joi.string().email({ tlds: { allow: false } });
      } else if (field.type === 'time') {
        validateQuery = joi.string().regex(/^([01]\d|2[0-3]):([0-5]\d)$/);
      } else if (field.type === 'switch') {
        validateQuery = joi.boolean();
      } else if (field.type === 'date') {
        validateQuery = joi.date();
      } else if (field.type === 'select' && field.required) {
        validateQuery = joi.array().items().min(1);
      } else if (field.type === 'select') {
        validateQuery = joi.array().items();
      } else if (field.type === 'radio') {
        validateQuery = joi.string();
      } else if (field.type === 'checkbox') {
        if (field.required) {
          validateQuery = joi.array().items(joi.boolean().invalid(false));
        } else {
          validateQuery = joi.array().items(joi.boolean());
        }
      } else if (field.title === 'Password') {
        validateQuery = joi.string().alphanum();
      }
      if (field.min) {
        validateQuery = validateQuery.min(field.min);
      }
      if (field.max) {
        validateQuery = validateQuery.max(field.max);
      }
      if (field.required) {
        validateQuery = validateQuery.required();
      } else {
        if (validateQuery) {
          validateQuery = validateQuery.allow(null);
          validateQuery = validateQuery.allow('');
        }
      }

      if (field.title === 'Valid Till') {
        object[field.id] = validateQuery.min(joi.ref('validFrom')).required();
      }

      if (field.title === 'Repeat Password') {
        object[field.id] = joi.string().valid(formData.password).required();
      }
      if (field.title === 'DOB') {
        object[field.id] = joi.date().required();
      }
      object[field.id] = validateQuery;
    });
  });

  formSchema = joi.object(object);
  const { error } = formSchema.validate(formData, {
    abortEarly: false,
    allowUnknown: true,
  });

  if (error) {
    let errorDetail = {};
    error.details.forEach((e) => {
      errorDetail[e.path[0]] = e.message;
      if (e.path[0] === 'repeatPassword') {
        errorDetail['repeatPassword'] =
          'Please enter the same password as above';
      }
    });
    return errorDetail;
  } else {
    return true;
  }
}
